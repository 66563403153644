<template>
  <div>
    <p class="label-20 averta-black mb-5">
      {{ typeSidebar == 'edit' ? 'Edit Client' : 'Buat Client Baru' }}
    </p>
    <div class="border-2 border-radius-20 p-5 bg-grey-15">
      <p class="label-20 averta-black">Informasi Perusahaan</p>
      <p>Silakan masukkan informasi perusahaan dibawah yang ingin didaftarkan</p>
      <div class="mt-5">
        <div class="flex-center-vertical mb-4">
          <div class="input-label">
            <p>Nama PT <span class="color-red">*</span></p>
          </div>
          <b-input
            v-model="formClient.company_name"
            placeholder="Masukkan nama PT"
            class="input-search-2 c-w-85 input-none"
          ></b-input>
        </div>
        <div class="flex-center-vertical mb-4">
          <div class="input-label">
            <p>Nama Brand <span class="color-red">*</span></p>
          </div>
          <b-input
            v-model="formClient.name"
            placeholder="Masukkan nama brand"
            class="input-search-2 c-w-85 input-none"
          ></b-input>
        </div>
        <div class="flex-center-vertical mb-4">
          <div class="input-label">
            <p>Logo <span class="color-red">*</span></p>
          </div>
          <div class="flex-center-vertical">
            <label for="input-file" class="flex-center-vertical">
              <div class="img-kanggox mr-3">
                <img :src="require('@/assets/img/kanggox.png')" alt="" />
              </div>
              <div v-if="formClient.logo" class="is-relative">
                <div class="preview-crop-img is-clickable">
                  <img :src="formClient.logo" alt="" />
                </div>
                <div class="edit-image">
                  <EditIcon />
                </div>
              </div>
              <div v-else class="flex-center-vertical is-clickable input-search-1 bg-grey-3">
                <UploadLightIcon />
                <p class="ml-1">Upload Foto</p>
              </div>
            </label>
            <input
              type="file"
              id="input-file"
              class="d-none"
              ref="clientImg"
              @change="uploadImageFile"
              hidden
              accept="image/png, image/jpeg"
            />
          </div>
        </div>

        <ModalCropImage
          :showModal="showModalCropImage"
          :previewImage="preview_crop"
          :closeModal="closeModalCropImage"
          @cropImage="cropImage($event)"
        />
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isObjectsSimilar } from '@/helpers'
import { UploadLightIcon, EditIcon } from '@/components/icons'
import ModalCropImage from '../client-components/ModalCropImage.vue'

export default {
  name: 'ContentAddEditClient',
  props: {
    show: {
      type: Boolean
    },
    close: {
      type: Function
    },
    changeTypeContent: {
      type: Function
    }
  },
  components: {
    UploadLightIcon,
    EditIcon,
    ModalCropImage
  },
  data () {
    return {
      typeSidebar: this.getTypeSidebar,
      showModalCropImage: false,
      initialFormCompare: null,

      preview_crop: null,
      formClient: {
        company_name: '',
        name: '',
        logo: '',
        imgFile: null
      }
    }
  },
  computed: {
    ...mapGetters({
      getPayloadClient: 'client/getPayloadClient',
      getPayloadDataFormClient: 'client/getPayloadDataFormClient',
      getTypeSidebar: 'client/getClientTypeSidebar',
      getShowSidebar: 'client/getClientShowSidebar'
    })
  },
  methods: {
    closeModalCropImage () {
      this.showModalCropImage = false
    },
    cropImage (result) {
      const { preview, file } = result

      this.formClient.logo = preview
      this.formClient.imgFile = file
      this.showModalCropImage = false
    },
    uploadImageFile (e) {
      e.preventDefault()
      if (e.target.files[0]) {
        this.preview_crop = URL.createObjectURL(e.target.files[0])
        this.showModalCropImage = true
        this.$refs.clientImg.value = ''
      }
    },
    initialForm (data) {
      // eslint-disable-next-line camelcase
      const { company_name = '', name, logo, imgFile, id } = data

      this.formClient = {
        company_name,
        name,
        logo,
        imgFile,
        id
      }

      // this.preview_image = logo
    },
    handleResetForm () {
      this.preview_crop = null
      this.formClient = {
        company_name: '',
        name: '',
        logo: '',
        imgFile: null
      }
    }
  },
  watch: {
    formClient: {
      handler (value) {
      // eslint-disable-next-line camelcase
        const { company_name, name, logo, imgFile, id } = value
        const body = {
          company_name,
          name,
          logo,
          imgFile,
          id
        }
        const isEmptyForm = Object.values(body).some(item => item === null || item === '')

        let isSimilar = false
        if (this.getTypeSidebar === 'edit') {
          isSimilar = isObjectsSimilar(this.initialFormCompare, value)
        }
        this.$store.commit('client/setPayloadClient', { dataForm: body, isEmptyForm: isEmptyForm || isSimilar })
      },
      deep: true
    },
    getTypeSidebar (value) {
      this.typeSidebar = value
    },
    getShowSidebar (value) {
      if (value) {
        this.initialForm(this.getPayloadDataFormClient)
        this.initialFormCompare = JSON.parse(JSON.stringify(this.getPayloadDataFormClient))
      } else {
        this.handleResetForm()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.input-label {
  width: 180px;
}

.img-kanggox, .preview-crop-img {
  height: 40px;
}

.img-kanggox img, .preview-crop-img img {
  height: 100%;
  object-fit: contain;
}

.edit-image {
  position: absolute;
  right: -15px;
  top: 20px;
}

</style>
