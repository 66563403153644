<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2390_138991"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="1"
      y="3"
      width="22"
      height="19"
    >
      <path
        d="M12 16.5V7.5"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 4.5H19V7.5H5V4.5Z"
        fill="white"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 16L7 12.5H16.987L20 16"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 16.5H6V20.5H2V16.5ZM10 16.5H14V20.5H10V16.5ZM18 16.5H22V20.5H18V16.5Z"
        fill="white"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </mask>
    <g mask="url(#mask0_2390_138991)">
      <path d="M0 0H24V24H0V0Z" :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DaftarClientLine',
  props: {
    size: {
      type: String,
      default: '19'
    },
    color: {
      type: String,
      default: '#E10009'
    }
  }
}
</script>
