<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2216_139517" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 13C11.45 13 11 12.55 11 12V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V12C13 12.55 12.55 13 12 13ZM11 15V17H13V15H11Z" fill="black" />
    </mask>
    <g mask="url(#mask0_2216_139517)">
      <rect width="24" height="24" fill="url(#paint0_linear_2216_139517)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2216_139517" x1="-9.78048e-08" y1="11.7818" x2="19.3471" y2="21.2697" gradientUnits="userSpaceOnUse">
        <stop :stop-color="color" />
        <stop offset="0.9999" :stop-color="color" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'AlertIcon',
  props: {
    size: {
      type: String,
      default: '24'
    },
    color: {
      type: String,
      default: '#D9272D'
    }
  }
}
</script>
