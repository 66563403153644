<template>
  <div class="wrap-material">
    <div v-if="data">
      <div class="columns mt-2 mb-5">
        <CardRedWave title="Tanggal Pengerjaan" :description="formatDate(data.card && data.card.date_working)" />
        <CardRedWave title="Estimasi Tanggal Selesai" :description="formatDate(data.card && data.card.estimate_date_finish)" />
        <CardRedWave title="Durasi Proyek" :description="`${data.card && data.card.duration} Hari`" />
        <CardRedWave title="Hari Pengerjaan" :description="`${data.card && data.card.work_days} Hari`" />
        <CardRedWave title="Total Keseluruhan Tukang" :description="`${data.card && data.card.total_worker} Tukang`" />
        <CardRedWave title="Durasi Persiapan Material" :description="`${data.card && data.card.duration_material_preparation} Hari`" :isBlack="true" />
      </div>

      <TabDetailProyek :data="data" :isPurchasing="true" />
      <div class="stroke-divider" />

      <TimelinePhase2
        :dataProps="data.timeline"
        :durationProps="data.card.duration"
        :dateWorkingProps="data.card"
        :projectDateProps="data.project_date"
        :isProject="true"
        :slotSkillsProps="data.slot_skills"
      />
      <!-- <NewTimeline :data="data.timeline" :duration="data.card.duration" :dateWorking="data.card" :projectDate="data.project_date"/> -->

      <div class="divider"></div>

      <div>
        <div class="mb-5">
          <p class="averta-bold label-18">Detail Penawaran</p>
          <p class="color-gray label-18">Informasi tukang dan material pada penawaran</p>
        </div>
        <NewDetailPenawaran
          :hideInformasiPenawaran="true"
          :hideTimeline="true"
        />
      </div>

    </div>
    <div v-else class="has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Informasi Proyek Kosong
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import CardRedWave from '@/components/card/CardRedWave.vue'
import { ProyekEmptyIcon } from '@/components/icons'
import TabDetailProyek from '../../../project/detail-proyek/components/TabDetailProyek.vue'
import NewTimeline from '@/components/project/NewTimeline.vue'
import NewDetailPenawaran from '@/components/project/NewDetailPenawaran.vue'
import TimelinePhase2 from '@/components/project/TimelinePhase2.vue'

export default {
  name: 'TabInformasiProyek',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  components: {
    CardRedWave,
    ProyekEmptyIcon,
    TabDetailProyek,
    // eslint-disable-next-line vue/no-unused-components
    NewTimeline,
    NewDetailPenawaran,
    TimelinePhase2
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return '-'
      }

      return moment(date).format('DD MMMM YYYY')
    }
  }
}
</script>

<style scoped>
.wrap-material {
  width: 100%;
  min-height: 40vh;
}

.stroke-divider {
  height: 8px;
  width: 100%;
  background: #E1E1E1;
  margin: 2em 0;
}

.divider {
  height: 1px;
  width: 100%;
  border-top: 1px dashed #E1E1E1;
  margin-bottom: 1.5em;
  margin-top: -1em;
}
</style>
