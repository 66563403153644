<template>
  <section class="is-title-bar is-flex is-align-items-center" :class="{'is-hidden-mobile': isMobile}">
    <DashboardIcon2 v-if="currentPath === '/' || currentPath === '/am/dashboard' || currentPath === '/sm/dashboard' || currentPath === '/purchasing/dashboard'" />
    <ProyekIcon v-if="currentPath === '/project' || currentPath === '/purchasing/project' || currentPath === '/am/project' || currentPath === '/sm/project'"/>
    <PengaturanIcon v-if="currentPath === '/am/setting'" />
    <ScheduleIcon v-if="currentPath === '/am/schedule'" />
    <LaporanIcon height="25" width="25" v-if="currentPath === '/am/report'" />
    <CatalogIcon v-if="currentPath === '/purchasing/catalog'" />
    <div class="ml-4 level">
      <div class="level-left">
        <div class="level-item">
          <ul>
            <li class="averta-bold is-size-4" v-for="(title,index) in titleStack" :key="index">{{ title }}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { DashboardIcon2, ProyekIcon, PengaturanIcon, ScheduleIcon, CatalogIcon, LaporanIcon } from '../components/icons'

export default {
  name: 'TitleBar',
  components: {
    DashboardIcon2,
    ProyekIcon,
    PengaturanIcon,
    ScheduleIcon,
    CatalogIcon,
    LaporanIcon
  },
  props: {
    titleStack: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    currentPath () {
      return this.$route.path
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  mounted () {
    // console.log(this.titleStack)
  }
}
</script>

<style lang="scss" scoped>
.is-title-bar svg {
  fill: #D9272D;
}
</style>
