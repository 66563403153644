<template>
    <div class="card p-4 is-flex is-justify-content-space-between is-align-items-center">
        <div>
            <div class="is-flex is-align-items-center mb-3">
                <img src="../../assets/img/penawaran-pending.png" alt="penawaran-pending" class="mr-3">
                <div>
                    <p class="averta-bold is-size-5">Menunggu Lampiran SIK & SPK Diupload</p>
                    <p class="desc is-hidden-mobile">Proyek hanya bisa berjalan setelah lampiran SIK & SPK telah diupload. Butuh bantuan?
                        <a href="#" class="averta-bold"><b-icon icon="whatsapp" custom-size="default" />Hubungi PIC Cabang by WA</a>
                    </p>
                </div>
            </div>
            <div class="desc-wrapper is-hidden-tablet">
                <p class="desc">Proyek hanya bisa berjalan setelah lampiran SIK & SPK telah diupload. Butuh bantuan?
                    <a href="#" class="averta-bold"><b-icon icon="whatsapp" custom-size="default" />Hubungi PIC Cabang by WA</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CardLampiranSIKSPK'
}
</script>

<style scoped>
.card {
    background: rgba(49, 133, 252, 0.1);
    border: 1px solid #3185FC;
    border-radius: 12px;
}

.desc-wrapper .desc {
    color: #4A4A4A;
}

</style>
