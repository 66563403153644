<template>
    <div class="card p-4 is-flex is-justify-content-space-between is-align-items-center">
        <div>
            <div class="is-flex is-align-items-center mb-3">
                <img src="../../assets/img/penawaran-pending.png" alt="penawaran-pending" class="mr-3">
                <div>
                    <p class="averta-bold is-size-5">SPK dan SIK telah diupload oleh client</p>
                    <p class="desc is-hidden-mobile">
                        Periksa surat - surat yang telah diupload sebelum anda melanjutkan proyek
                    </p>
                </div>
            </div>
            <div class="desc-wrapper is-hidden-tablet">
                <p class="desc">
                    Periksa surat - surat yang telah diupload sebelum anda melanjutkan proyek
                </p>
            </div>
        </div>
        <div>
            <a class="btn-download is-clickable mr-4" v-for="data in dataDocument" :key="data.id" :href="data.filename" target="_blank">
                Lihat {{ data.type }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CardUploadSIKSPK',
  props: ['dataDocument']
}
</script>

<style scoped>
.card {
    background: linear-gradient(90deg, #FFDDDD 0%, #FFEFDB 99.97%);
    border-radius: 12px;
}

.desc-wrapper .desc {
    color: #4A4A4A;
}

.btn-download {
    background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
    border-radius: 100px;
    color: white;
    font-family: 'Averta-Bold';
    padding: 12px 24px;
    border: none;
}

</style>
