<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_954_143162" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="3" width="20" height="14">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.99967 3.75C5.83301 3.75 2.27467 6.34167 0.833008 10C2.27467 13.6583 5.83301 16.25 9.99967 16.25C14.1663 16.25 17.7247 13.6583 19.1663 10C17.7247 6.34167 14.1663 3.75 9.99967 3.75ZM9.99967 14.1667C7.69967 14.1667 5.83301 12.3 5.83301 10C5.83301 7.7 7.69967 5.83333 9.99967 5.83333C12.2997 5.83333 14.1663 7.7 14.1663 10C14.1663 12.3 12.2997 14.1667 9.99967 14.1667ZM7.49967 10C7.49967 8.61667 8.61634 7.5 9.99967 7.5C11.383 7.5 12.4997 8.61667 12.4997 10C12.4997 11.3833 11.383 12.5 9.99967 12.5C8.61634 12.5 7.49967 11.3833 7.49967 10Z"
                fill="black" />
        </mask>
        <g mask="url(#mask0_954_143162)">
            <rect width="20" height="20" fill="url(#paint0_linear_954_143162)" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_954_143162" x1="-8.1504e-08" y1="9.81818" x2="16.1226" y2="17.7248"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#D9272D" />
                <stop offset="0.9999" stop-color="#EB4600" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>

export default {
  name: 'EyeIcon'
}
</script>
