<template>
  <b-modal :on-cancel="closeModal" v-model="isModalActive" :can-cancel="false">
    <div class="modal-template">
      <div class="modal-body w-100">
        <header class="is-flex is-align-items-center is-justify-content-space-between">
          <p class="averta-bold is-size-5">{{ renderTitle }}</p>
          <div class="is-clickable" @click="closeModal">
            <b-icon icon="close"></b-icon>
          </div>
        </header>
        <div class="mt-5">
          <div class="mx-5 mb-5 label-14">
            <div v-if="isTypeEdit" class="mb-3">
              <p class="text-red">Status Toko Material <span class="color-red">*</span></p>
              <div class="is-flex is-align-items-center">
                <p class="averta-bold">{{ isCheckActive ? 'Aktif' : 'Tidak Aktif' }}</p>
                <SwitchToggle :scale="0.7" :active="isCheckActive" @isCheck="($event) => handleIsCheckActive($event)" />
              </div>
            </div>

            <label class="text-red">Nama Toko Material <span class="color-red">*</span></label>
            <input v-model="name" class="input mt-2 mb-4 input-custom" type="text" placeholder="Masukan Nama Toko Material" :maxlength="100" />

            <label class="text-red">Alamat/Lokasi Toko <span class="is-italic">(Opsional)</span></label>
            <textarea v-model="address" rows="3" class="textarea mt-2 mb-4 input-custom" placeholder="Cth : Tokopedia, BSD, Jakarta Selatan, dll" :maxlength="200"></textarea>
          </div>
          <footer class="py-5 is-flex is-justify-content-flex-end footer-shadow">
            <b-button class="btn-disabled averta-bold px-5 is-flex is-justify-content-center mr-5" @click="isTypeEdit ? updateStore() : addStore()" :class="{ 'btn-active': !isFilledWithSpaces }">
              <div class="is-flex is-align-items-center">
                <CheckIcon class="mr-3" />
                <span>Simpan</span>
              </div>
            </b-button>
          </footer>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { CheckIcon } from '@/components/icons'
import SwitchToggle from '@/components/SwitchToggle.vue'

export default {
  name: 'ModalMaterials',
  components: {
    CheckIcon,
    SwitchToggle
  },
  props: {
    typeModal: {
      type: String,
      default: 'create-store'
    },
    showModal: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number
    },
    nameProps: {
      type: String,
      default: ''
    },
    addressProps: {
      type: String,
      default: ''
    },
    activeProps: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isModalActive: false,
      isCheckActive: false,
      name: '',
      address: ''
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    renderTitle () {
      if (this.typeModal === 'create-store') {
        return 'Tambah Toko Material'
      } else if (this.typeModal === 'update-store') {
        return 'Edit Toko Material'
      } else {
        return ''
      }
    },
    isTypeEdit () {
      if (this.typeModal === 'update-store') {
        return true
      }
      return false
    },
    isFilledWithSpaces () {
      // Remove leading and trailing spaces and check if the input contains only spaces
      return this.name.trim() === ''
    }
  },
  methods: {
    handleIsCheckActive (e) {
      this.isCheckActive = e
    },
    closeModal (event) {
      this.name = ''
      this.address = ''
      this.isCheckActive = false
      this.$emit('closeModal', event)
    },
    addStore () {
      if (!this.name) {
        return
      }
      if (this.name.length > 100) {
        return
      }

      const payload = {
        url: this.url,
        body: {
          name: this.name,
          address: this.address
        }
      }

      this.$store
        .dispatch('store/postDataStore', payload)
        .then((response) => {
          this.closeModal('success')
          this.name = ''
          this.address = ''
          this.$buefy.toast.open({
            message: 'Berhasil membuat Toko Material',
            type: 'is-success'
          })
        })
        .catch((error) => {
          console.log(error)
          this.$buefy.toast.open({
            message: error?.response?.data?.message || 'Gagal membuat Toko Material, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    updateStore () {
      if (!this.name) {
        return
      }
      if (this.name.length > 100) {
        return
      }
      this.$swal({
        imageUrl: require('@/assets/img/delete-area-component.png'),
        imageAlt: 'image update material',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Simpan Toko Material?</p>',
        html: '<p>Pastikan kamu sudah cek kembali toko ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            id: this.id,
            body: {
              name: this.name,
              address: !this.address ? '' : this.address,
              is_active: this.isCheckActive ? 1 : 0
            }
          }

          this.$store
            .dispatch('store/updateDataStore', payload)
            .then((response) => {
              this.closeModal('success')
              this.$buefy.toast.open({
                message: 'Toko Material berhasil diperbarui',
                type: 'is-success'
              })
              this.name = ''
              this.address = ''
            })
            .catch((error) => {
              console.log(error)
              this.$buefy.toast.open({
                message: error?.response?.data?.message || 'Gagal ubah Toko Material, silahkan coba lagi',
                type: 'is-danger'
              })
            })
        }
      })
    }
  },
  watch: {
    showModal (newVal) {
      this.isModalActive = newVal
      if (this.isTypeEdit) {
        this.name = this.nameProps
        this.address = this.addressProps
        this.isCheckActive = this.activeProps
      } else {
        this.name = ''
        this.address = ''
        this.isCheckActive = true
      }
    }
  }
}
</script>

<style scoped>
.modal-template {
  width: 35em;
  margin: auto;
}

.modal-body {
  overflow: hidden;
  background: white;
  border-radius: 10px;
  height: 100%;
}

.modal-template header {
  background-color: #911a1e;
  height: 5em;
  color: white;
  padding: 0 20px;
}

.btn-active {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  color: white;
}

.btn-disabled {
  background-color: #9a9a9a;
  border-radius: 100px;
  color: white;
}

.footer-shadow {
  box-shadow: 0px -3px 30px 0px #00000012;
}

.upload-img-wrapper {
  width: 100px;
  height: 100px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-custom {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}
</style>
