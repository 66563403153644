<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M10.3014 11.2033C10.132 11.0932 9.98591 10.9508 9.87153 10.7842C9.75715 10.6176 9.6767 10.4302 9.63477 10.2325C9.59284 10.0349 9.59025 9.83092 9.62716 9.63227C9.66406 9.43363 9.73973 9.24419 9.84985 9.07479L11.5275 6.49556C11.6359 6.32232 11.7776 6.1724 11.9445 6.05454C12.1114 5.93668 12.3001 5.85324 12.4996 5.8091C12.6991 5.76497 12.9054 5.76101 13.1065 5.79746C13.3075 5.83392 13.4993 5.91005 13.6706 6.02142C13.8419 6.1328 13.9893 6.27717 14.1042 6.44613C14.219 6.61509 14.2991 6.80524 14.3397 7.00548C14.3804 7.20572 14.3807 7.41205 14.3407 7.61242C14.3007 7.81279 14.2212 8.00318 14.1068 8.17248L12.4299 10.7525C12.2074 11.0945 11.8581 11.3341 11.459 11.4187C11.0598 11.5032 10.6434 11.4257 10.3014 11.2033ZM8.42293 11.3586C8.1647 10.8733 8.04127 10.3276 8.06549 9.7783C8.0897 9.22905 8.26068 8.69632 8.56062 8.23556L10.2375 5.65633C10.4579 5.31757 10.7427 5.02552 11.0759 4.79686C11.4091 4.5682 11.7841 4.4074 12.1794 4.32365C12.5747 4.2399 12.9827 4.23483 13.38 4.30874C13.7773 4.38265 14.1561 4.53409 14.4949 4.75441C14.8336 4.97472 15.1257 5.25961 15.3543 5.59279C15.583 5.92598 15.7438 6.30094 15.8275 6.69626C15.9113 7.09159 15.9163 7.49955 15.8424 7.89683C15.7685 8.29412 15.6171 8.67295 15.3968 9.01171L13.7199 11.5909C13.4281 12.04 13.0241 12.4051 12.548 12.6501C12.0719 12.8952 11.54 13.0118 11.005 12.9883C10.47 12.9649 9.95041 12.8022 9.49756 12.5164C9.04471 12.2305 8.67429 11.8315 8.42293 11.3586Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M6.49573 14.2329C6.32631 14.1228 6.18025 13.9804 6.06587 13.8139C5.95149 13.6473 5.87104 13.4599 5.82911 13.2622C5.78718 13.0646 5.78459 12.8606 5.82149 12.662C5.8584 12.4633 5.93407 12.2739 6.04419 12.1045L7.72188 9.52524C7.83021 9.352 7.97196 9.20207 8.13886 9.08421C8.30576 8.96635 8.49447 8.88292 8.69397 8.83878C8.89346 8.79465 9.09975 8.79069 9.30079 8.82714C9.50184 8.8636 9.6936 8.93973 9.8649 9.0511C10.0362 9.16247 10.1836 9.30685 10.2985 9.47581C10.4134 9.64477 10.4935 9.83492 10.5341 10.0352C10.5747 10.2354 10.575 10.4417 10.535 10.6421C10.495 10.8425 10.4155 11.0329 10.3011 11.2022L8.62419 13.7822C8.40171 14.1242 8.05248 14.3638 7.65331 14.4484C7.25415 14.5329 6.83776 14.4554 6.49573 14.2329ZM4.61727 14.3883C4.35904 13.9029 4.23561 13.3572 4.25982 12.808C4.28404 12.2587 4.45501 11.726 4.75496 11.2652L6.43188 8.68678C6.87673 8.00262 7.57514 7.5232 8.37347 7.35399C8.76876 7.2702 9.17669 7.26509 9.57395 7.33895C9.97122 7.41282 10.35 7.5642 10.6888 7.78447C11.0276 8.00474 11.3196 8.28957 11.5483 8.62271C11.777 8.95585 11.9378 9.33077 12.0216 9.72606C12.1054 10.1213 12.1105 10.5293 12.0366 10.9265C11.9628 11.3238 11.8114 11.7026 11.5911 12.0414L9.91419 14.6206C9.6224 15.0697 9.21847 15.4348 8.74233 15.6798C8.2662 15.9249 7.73434 16.0415 7.19935 16.018C6.66436 15.9945 6.14475 15.8319 5.6919 15.546C5.23904 15.2602 4.86863 14.8612 4.61727 14.3883Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M10 18.4615C14.6731 18.4615 18.4615 14.6731 18.4615 10C18.4615 5.32692 14.6731 1.53846 10 1.53846C5.32692 1.53846 1.53846 5.32692 1.53846 10C1.53846 14.6731 5.32692 18.4615 10 18.4615ZM10 20C15.5231 20 20 15.5231 20 10C20 4.47692 15.5231 0 10 0C4.47692 0 0 4.47692 0 10C0 15.5231 4.47692 20 10 20Z"
      :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'GroupingIcon',
  props: ['color']
}
</script>
