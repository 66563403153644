<template>
    <div class="card p-4">
        <div class="is-flex is-align-items-center mb-3">
            <img src="../../assets/img/penawaran-pending.png" alt="penawaran-pending" class="mr-3">
            <div>
                <p class="averta-bold is-size-5">Konsultan sedang membuat penawaran</p>
                <p class="desc is-hidden-mobile">Penawaran proyek akan segera dibuat oleh konsultan, butuh bantuan ?
                    <a href="#" class="averta-bold text-consultant">Hubungi Konsultan Terkait</a>
                </p>
            </div>
        </div>
        <div class="desc-wrapper is-hidden-tablet">
            <p class="desc">Penawaran proyek akan segera dibuat oleh konsultan, butuh bantuan ?
                <a href="#" class="averta-bold text-consultant">Hubungi Konsultan Terkait</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CardSurveySelesaiAM'
}
</script>

<style scoped>
.card {
    background: linear-gradient(90deg, #FFFFF7 0%, #FFF5EA 100%);
    border-radius: 12px;
}

.desc-wrapper .desc {
    color: #4A4A4A;
}

.text-consultant {
    color: #EB4600;
}
</style>
