<template>
  <div class="card card-wrapper-costum flex-1 p-4">
    <p class="label-20 averta-bold mb-4">Informasi Client</p>
    <div v-if="client.extra_customer_name || client.extra_customer_phone">
      <div class="border-bottom-dashed pb-3 mb-3 is-flex is-justify-content-space-between">
        <div class="flex-1 mr-3">
          <p class="label-14 color-gray">Nama PIC Outlet</p>
          <p class="averta-bold text-ellipsis-2-line">{{ client.pic_name }}</p>
        </div>
        <div class="flex-1">
          <p class="label-14 color-gray">Nomor Telepon PIC Outlet</p>
          <p class="averta-bold">{{ client.pic_phone }}</p>
        </div>
      </div>
      <div class="mb-3 is-flex is-justify-content-space-between">
        <div class="flex-1 mr-3">
          <p class="label-14 color-gray">Nama Customer</p>
          <p class="averta-bold text-ellipsis-2-line">{{ client.extra_customer_name || '-' }}</p>
        </div>
        <div class="flex-1">
          <p class="label-14 color-gray">No Telepon Customer</p>
          <p class="averta-bold">{{ client.extra_customer_phone || '-' }}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mb-3">
        <p class="label-14 color-gray">Nama PIC</p>
        <p class="averta-bold">{{ client.pic_name }}</p>
      </div>
      <div class="mb-3">
        <p class="label-14 color-gray">Telp PIC</p>
        <p class="averta-bold">{{ client.pic_phone }}</p>
      </div>
    </div>

    <div class="mb-3">
      <p class="label-14 color-gray">Alamat Pengerjaan</p>
      <p class="averta-bold color-red">{{ client.address }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardClientInformation',
  props: {
    client: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
