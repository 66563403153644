<template>
  <div class="px-0 dashboard-sm">
    <SnackbarRescheduleSurvey v-if="dataTotalReschedule > 0" :total="dataTotalReschedule" @buttonClick="showRescheduleSurvey" />
    <div class="is-flex is-align-items-center is-justify-content-space-between pt-4 px-5 mb-4" :style="{'margin-top': dataTotalReschedule > 0 ? '75px' : '0px'}">
      <title-bar :title-stack="titleStack" />
    </div>
    <b-tabs class="tab-content-wrapper">
      <b-tab-item>
        <template #header>
          <div>
            <span>Dashboard</span>
          </div>
        </template>
        <section class="container-tab-item">

          <div v-if="isLoadingKonfirmasi" class="px-5 mb-6">
            <CardSkeleton :totalCard="3" height="200px" />
          </div>
          <CardReviewOffering
            v-else-if="dataPenawaranWaiting.length > 0"
            :dataPenawaranWaiting="dataPenawaranWaiting"
          />

          <div v-if="isLoadingCard" class="px-5 my-6">
            <CardSkeleton :totalCard="4" />
          </div>
          <CardPerformaDashboard v-else :dataCard="dataPerforma" role="sm" />

          <div v-if="isLoadingBerlangsung" class="px-5 my-6">
            <CardSkeleton :totalCard="3" height="200px" />
          </div>
          <CardSurveryBerlangsungDashboard
            v-else-if="dataSurveyBerlangsung.length > 0"
            :dataSurveyBerlangsung="dataSurveyBerlangsung"
          />

          <div v-if="isLoadingCard" class="px-5 my-6">
            <CardSkeleton :totalCard="4" height="200px" />
          </div>
          <CardDashboard
            v-else
            :dataCard="dataPerforma"
            @changeDataDashboard="changeDataDashboard($event)"
          />
        </section>
      </b-tab-item>
    </b-tabs>

    <!-- Modal Reschedule Survey -->
    <ModalRescheduleSurvey :showModal="modalRescheduleSurvey" width="75vw" @closeModal="showRescheduleSurvey" @totalReschedule="totalReschedule($event)" :isDashboard="true" />
    <!-- Modal Reschedule Survey -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardSkeleton from '@/components/skeleton/CardSkeleton'
import TitleBar from '@/components/TitleBar'
import CardPerformaDashboard from '@/components/card/CardPerformaDashboard'
import CardSurveryBerlangsungDashboard from '@/components/card/CardSurveryBerlangsungDashboard'
import CardDashboard from '@/components/card/CardDashboard'
import CardReviewOffering from './card/CardReviewOffering'
import SnackbarRescheduleSurvey from '@/components/snackbar/SnackbarRescheduleSurvey.vue'
import ModalRescheduleSurvey from '@/components/modal/ModalRescheduleSurvey.vue'

export default {
  name: 'DashboardSM',
  components: {
    CardSkeleton,
    TitleBar,
    CardDashboard,
    CardPerformaDashboard,
    CardSurveryBerlangsungDashboard,
    CardReviewOffering,
    SnackbarRescheduleSurvey,
    ModalRescheduleSurvey
  },
  data () {
    return {
      isLoadingCard: true,
      isLoadingKonfirmasi: true,
      isLoadingBerlangsung: true,
      dataPerforma: null,
      dataSurveyBerlangsung: [],
      dataPenawaranWaiting: [],
      modalRescheduleSurvey: false,
      dataTotalReschedule: null
    }
  },
  computed: {
    titleStack () {
      return ['Dashboard']
    },
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    getDataPerforma (payload) {
      this.isLoadingCard = true
      this.$store
        .dispatch('dashboard/getCardDashboard', payload)
        .then((response) => {
          this.isLoadingCard = false
          this.dataPerforma = response.data.data
        })
        .catch((error) => {
          this.isLoadingCard = false
          console.log(error)
        })
    },
    getSurveyBerlangsung () {
      this.isLoadingBerlangsung = true
      const payload = {
        url: this.url,
        page: 1
      }

      this.$store
        .dispatch('project/getSurveyBerlangsungDashboardSM', payload)
        .then((response) => {
          this.isLoadingBerlangsung = false
          this.dataSurveyBerlangsung = response.data.data.list || []
        })
        .catch((error) => {
          this.isLoadingBerlangsung = false
          console.log(error)
        })
    },
    getListMenungguKonfirmasi () {
      this.isLoadingKonfirmasi = true
      const payload = {
        url: this.url,
        sort: 'desc',
        substatus: 'waiting-sm'
      }

      this.$store
        .dispatch('project/getListMenungguKonfirmasi', payload)
        .then(response => {
          this.isLoadingKonfirmasi = false
          this.dataPenawaranWaiting = response.data.data.list || []
        })
        .catch(error => {
          this.isLoadingKonfirmasi = false
          console.log(error)
        })
    },
    changeDataDashboard (data) {
      const payload = {
        ...data,
        url: this.url
      }
      this.getDataPerforma(payload)
    },
    showRescheduleSurvey () {
      this.modalRescheduleSurvey = !this.modalRescheduleSurvey
    },
    totalReschedule (data) {
      this.dataTotalReschedule = data
    }
  },
  mounted () {
    setTimeout(() => {
      this.isLoading = false
    }, 500)
    document.documentElement.classList.remove('has-aside-left')
    document.documentElement.classList.remove('has-navbar-fixed-top')
  },
  created () {
    const payload = { url: this.url }
    this.getDataPerforma(payload)
    this.getSurveyBerlangsung()
    this.getListMenungguKonfirmasi()
  }
}
</script>

<style scoped>
  .tab-content-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .tab-content-wrapper .b-tabs .tab-content {
    padding: 0 !important;
  }

  .b-tabs .tab-content {
    padding: 0 !important;
  }

  .dashboard-sm {
    width: 95%;
  }

  .container-tab-item {
    width: 103%;
    position: relative;
    left: -1em;
    top: -1em;
  }

  @media only screen and (max-width: 600px) {
    .container-tab-item {
      width: 108%;
    }
    .dashboard-sm {
      width: 100%;
    }
  }
</style>
