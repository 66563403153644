<template>
  <div
    :style="{
      height: `${heightColumnDailyProps}px`,
      top: `${heightColumnNavProps}px`
    }"
    class="weekly-nav sticky-left is-clickable"
    @click="handleAccordionProps(indexWeekProps)"
  >
    <div
      :style="{
        width: `${widthWeekAccordionProps}px`
      }"
      class="weekly-title flex-center-vertical color-white"
    >
      <div>
        <p>
          <span class="averta-bold">
            {{formatMonth({start: itemWeekProps.start_date, end: itemWeekProps.end_date})}} | Minggu Ke {{ itemWeekProps.week }}
          </span>
          <span class="averta-regular ml-3">
            {{formatDate(itemWeekProps.start_date)}} - {{formatDate(itemWeekProps.end_date)}}
          </span>
        </p>
      </div>
      <div class="flex-center is-relative">
        <p>{{indexAccordionWeekProps.includes(indexWeekProps) ? 'Buka' : 'Tutup'}} Daftar</p>
        <div
          class="collapse-wrapper flex-center"
          :class="{'close': indexAccordionWeekProps.includes(indexWeekProps)}"
        >
          <CollapseIcon color="#fff" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { formatDateHelper } from '@/helpers'
import { CollapseIcon } from '@/components/icons'

export default {
  name: 'AccordionWeeklyProgress',
  components: {
    CollapseIcon
  },
  props: {
    itemWeekProps: {
      type: Object
    },
    indexWeekProps: {
      type: Number
    },
    heightColumnDailyProps: {
      type: Number
    },
    heightColumnNavProps: {
      type: Number
    },
    widthWeekAccordionProps: {
      type: Number
    },
    indexAccordionWeekProps: {
      type: Array
    },
    handleAccordionProps: {
      type: Function
    }
  },
  methods: {
    formatDate (date, format) {
      if (!date) return ''
      return formatDateHelper({ date, format })
    },
    formatMonth (props = {}) {
      const { start, end } = props
      if (!start && !end) return '-'
      const formatStart = moment(start).format('MMMM YYYY')
      const formatEnd = moment(end).format('MMMM YYYY')

      if (formatStart === formatEnd) return formatStart

      return `${formatStart} - ${formatEnd}`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.weekly-nav {
  display: flex;
  align-items: center;
  background: $color-blue-3;
  border: 1px solid $color-grey-10;
  z-index: 3;
}

.weekly-title {
  position: sticky;
  left: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapse-wrapper {
  position: absolute;
  right: -22px;
  transition: 0.2s all ease-in-out;
  transform: rotate(180deg);
}

.collapse-wrapper.close {
  transform: rotate(0deg);
}
</style>
