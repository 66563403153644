<template>
  <div class="c-w-100">
    <div class="my-4" v-if="showTitle">
      <b-skeleton height="30px" width="300px" :animated="true"></b-skeleton>
    </div>
    <div class="columns">
      <div class="column" v-for="item, index in firstBox" :key="index">
        <b-skeleton height="110px" :animated="true"></b-skeleton>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-skeleton height="500px" :animated="true"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientSkeleton',
  props: {
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      firstBox: 3
    }
  }
}
</script>
