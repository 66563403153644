<template>
  <div class="card p-4">
    <div class="is-flex is-align-items-center mb-3">
      <img src="../../assets/img/survey_late.png" alt="survey-process" class="mr-3">
      <div>
        <p class="averta-bold is-size-5">Survey Terlambat</p>
        <p class="desc is-hidden-mobile">Anda terlambat melakukan kunjungan survey! Segera lakukan survey ke alamat terkait! Butuh bantuan?
          <a href="#" class="averta-bold has-text-black"><b-icon icon="whatsapp" custom-size="default" />Hubungi PIC Proyek</a> |
          <a href="#" class="averta-bold has-text-black"><b-icon icon="whatsapp" custom-size="default" />Hubungi PIC Cabang</a>
        </p>
      </div>
    </div>
    <div class="desc-wrapper is-hidden-tablet">
      <p class="desc">Anda terlambat melakukan kunjungan survey! Segera lakukan survey ke alamat terkait! Butuh bantuan?
        <a href="#" class="averta-bold has-text-black"><b-icon icon="whatsapp" custom-size="default" />Hubungi PIC Proyek</a> |
        <a href="#" class="averta-bold has-text-black"><b-icon icon="whatsapp" custom-size="default" />Hubungi PIC Cabang</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSurveyLate'
}
</script>

<style scoped>
.card {
  background: linear-gradient(90deg, #FFFDF7 0%, #FFEAEA 100%);
  border: 1.5px solid #D9272D;
  border-radius: 12px;
}

.desc-wrapper .desc {
  color: #4A4A4A;
  white-space: normal;
}

</style>
