<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b_367_91690)">
      <rect
        width="40"
        height="40"
        rx="20"
        fill="url(#paint0_linear_367_91690)"
        fill-opacity="0.1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.294 15.2911C25.294 18.2281 22.9391 20.5831 20 20.5831C17.0619 20.5831 14.706 18.2281 14.706 15.2911C14.706 12.354 17.0619 10 20 10C22.9391 10 25.294 12.354 25.294 15.2911ZM20 30C15.6624 30 12 29.295 12 26.575C12 23.8539 15.6854 23.1739 20 23.1739C24.3386 23.1739 28 23.8789 28 26.599C28 29.32 24.3146 30 20 30Z"
        fill="url(#paint1_linear_367_91690)"
      />
      <rect
        x="0.25"
        y="0.25"
        width="39.5"
        height="39.5"
        rx="19.75"
        stroke="url(#paint2_linear_367_91690)"
        stroke-width="0.5"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_367_91690"
        x="-200"
        y="-200"
        width="440"
        height="440"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="100" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_367_91690"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_367_91690"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_367_91690"
        x1="6.00406e-05"
        y1="-7.50005"
        x2="40.0001"
        y2="42"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" stop-opacity="0.6" />
        <stop offset="1" stop-color="#D92727" stop-opacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_367_91690"
        x1="12"
        y1="19.8182"
        x2="25.8658"
        y2="25.2581"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_367_91690"
        x1="-25.5"
        y1="-8"
        x2="56"
        y2="53"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EB4600" stop-opacity="0.1" />
        <stop offset="1" stop-color="#EB0000" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'AccountIcon'
}
</script>
