import axios from 'axios'

/* eslint-disable */
const state = () => ({});

const getters = {};

const actions = {
  getDataUsers({ commit, state }, payload) {
    let concatUrl = "";

    if (payload.search) {
      concatUrl = `&search=${payload.search}`;
    }

    return new Promise((resolve, reject) => {
      axios.get(
          `${payload.url}${process.env.VUE_APP_USERS}?${concatUrl &&
            `${concatUrl}`}&page=${payload.page || 1}&limit=10`
        ).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  getDetailUser({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(
          `${payload.url}${process.env.VUE_APP_USERS}/${payload.userId}/detail`
        ).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  deleteUser({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(
          `${payload.url}${process.env.VUE_APP_USERS}/${payload.userId}/delete`
        ).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  postPutAddDataUser({ commit, state }, payload) {
    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const {
      userId = null, 
      typeAPI,
      name, 
      email, 
      phone, 
      role, 
      is_active, 
      photo
    } = payload.body
    const data = new FormData()
    data.append('is_active', is_active)
    data.append('name', name)
    data.append('email', email)
    data.append('phone', phone)
    
    role.forEach((item) => {
      data.append('role_id[]', item)
    });
    if (photo) {
      data.append('photo', photo)
    }

    return new Promise((resolve, reject) => {
        if (typeAPI === 'edit') {
          axios.put(`${payload.url}${process.env.VUE_APP_USERS}/${userId}/update`, data, config).then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(error)
          })
        } else if (typeAPI === 'add') {
          axios.post(`${payload.url}${process.env.VUE_APP_USERS}`, data, config).then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(error)
          })
        }
    })
  },
  getDataRoles({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_ROLES}`
        ).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
