<template>
  <div class="wrapper">
    <div class="p-5 top-bar">
      <img
        src="../../assets/img/logo-kanggo.png"
        alt="logo-kanggo"
        class="logoKanggo"
      />
    </div>
    <div class="columns p-6 mt-5">
      <div class="column is-6 mx-6">
        <p class="averta-bold is-size-5">Halo!</p>
        <p class="mb-5">Silakan masukkan email kamu dan kita akan kirimkan link untuk ubah password-mu</p>
        <div>
          <div class="mb-5">
            <p class="mb-2">
              Email<span class="has-text-danger-dark ml-1">*</span>
            </p>
            <b-field>
              <b-input type="email" placeholder="name@email.com" v-model="email"></b-input>
            </b-field>
          </div>
          <div
            v-if="showNotif"
            class="is-flex notif p-3 mb-5 is-align-items-center"
          >
            <div class="card-attention has-text-centered">
              <span class="averta-bold">!</span>
            </div>
            <p>{{ messageFailedLogin }}</p>
          </div>
          <b-button
            class="btn-forgot-password mb-6"
            @click="forgotPassword()"
            >Kirim reset email</b-button
          >
          <div class="is-flex is-align-items-center is-clickable" @click="login()">
            <LoginIcon class="mr-3" />
            <p>Sudah punya akun? <span class="text-login">Login</span></p>
          </div>
        </div>
      </div>
      <div class="column has-text-centered">
        <img src="../../assets/img/image-password.png" alt="image-password" />
      </div>
    </div>
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="false"
    >
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { LoginIcon, SpinnerIcon } from '../../components/icons'
import { mapGetters } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import forgotPasswordImage from '../../assets/img/forgot-password.png'

export default defineComponent({
  name: 'ForgotPassword',
  components: {
    LoginIcon,
    SpinnerIcon
  },
  data () {
    return {
      email: null,
      showNotif: false,
      messageFailedLogin: '',
      isLoading: false,
      isFullPage: true
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    forgotPassword () {
      this.isLoading = true

      if (this.email === null || this.email === '') {
        this.isLoading = false
        this.showNotif = true
        this.messageFailedLogin = 'Silakan masukkan email terlebih dahulu'
        return false
      }

      const payload = {
        email: this.email,
        url: this.url
      }

      this.$store.dispatch('auth/forgotPassword', payload)
        .then(response => {
          this.isLoading = false
          this.$swal({
            imageUrl: forgotPasswordImage,
            imageAlt: 'forgot password image',
            width: '600px',
            padding: '10px 10px 30px 10px',
            title: '<p class="averta-bold label-22">Silakan cek email kamu</p>',
            html: `<p>Kita akan kirimkan link perubahan password ke email <span class="averta-bold">${this.email}</span></p>`,
            confirmButtonText: 'Kembali ke Home',
            confirmButtonColor: '#EB4600',
            customClass: {
              confirmButton: 'btn-first-login'
            }
          })
            .then(() => {
              this.$router.push('/login')
            })
        })
        .catch(error => {
          this.isLoading = false
          console.log(error.response)
          this.showNotif = true
          this.messageFailedLogin = error.response.data.message[0].message || error.response.data.message
          return false
        })
    },
    login () {
      this.$router.push('/login')
    }
  },
  mounted () {
    const rs = localStorage.getItem('webapp_shell_kanggo')
    const auth = JSON.parse(rs)

    if (auth && auth.token) {
      this.$store.commit('auth/setLoginStatus', true)
      this.$router.push({ path: '/' })
    }
  }
})
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 0;
  padding: 0;
  background: #FAFDFF;
  height: 100vh;
  width: 100vw;
}
.top-bar {
  background: white;
}
.logoKanggo {
  width: 12%;
}

.btn-forgot-password {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 90px;
  font-family: 'Averta-Bold';
  color: white;
  padding: 0px 26px;
  display: flex;
  justify-content: end;
}

.notif {
  background: #FFF1F1;
  border-radius: 20px;
}

.card-attention {
  width: 24px;
  height: 24px;
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 100px;
  color: white;
  margin: 0px 10px 0px 10px;
}
.text-login {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: 'Averta-Bold';
}
</style>
