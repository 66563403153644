<template>
  <div class="mb-4">
    <p class="averta-bold mb-2">Timeline Proyek</p>
    <div class="bg-grey is-relative has-background-white-ter is-flex is-justify-content-space-between py-2 px-3 mb-4" style="border-radius: 5px;">
      <p>
        Durasi Pengerjaan
      </p>
      <p><span class="averta-bold">{{ duration || '-' }}</span> Hari</p>
    </div>
    <img v-if="!data" :src="require('@/assets/img/timeline-lock.jpg')" alt="" class="lock-img">
    <div v-else class="wrapper-table mb-4">
      <table class="c-table">
        <tr>
          <td :colspan="duration + 1" class="has-text-centered py-4">
            Hari Kerja
          </td>
        </tr>
        <tr v-if="projectDate">
          <td class="averta-bold color-red">
            <div class="is-flex is-align-items-center is-clickable" v-if="seeCombine.on === true" @click="closeCombine()">
              <b-icon icon="close mr-3"></b-icon>
              <p>Tutup Kombinasi</p>
            </div>
          </td>
          <td v-for="(date, indexDate) in projectDate" class="has-text-centered" :key="`${indexDate}_date`">
            <p>{{ indexDate + 1 }}</p>
            <p class="white-space-nowrap text-date averta-bold">{{ date.date }}</p>
          </td>
        </tr>
        <tr v-else-if="duration">
          <td class="averta-bold color-red">
            <div class="is-flex is-align-items-center is-clickable" v-if="seeCombine.on === true" @click="closeCombine()">
              <b-icon icon="close mr-3"></b-icon>
              <p>Tutup Kombinasi</p>
            </div>
          </td>
          <td v-for="(schedule, indexSchedule) in loopDuration" class="has-text-centered schedule-td"
            :key="`${indexSchedule}_schedule`">
            {{ schedule }}
          </td>
        </tr>
        <tr v-for="(timeline, indexTimeline) in data" :key="`${indexTimeline}_timeline`" :style="{ border: seeCombine.on === true && seeCombine.color === timeline.group_color ? `2px solid ${seeCombine.color}` : '', background: seeCombine.on === true && seeCombine.color === timeline.group_color ? `${seeCombine.rgba}` : '' }">
          <td v-if="timeline.note_item_working" class="has-text-centered py-4 bg-td-grey" :colspan="duration + 1">
            <div class="has-text-left">
              <p class="text-grey">Item Pekerjaan</p>
              <p class="averta-bold">{{ timeline.note_item_working }}</p>
            </div>
          </td>
          <td
            v-else-if="timeline.skill_id == idKonsultanPengawas && timeline.schedule.length == 1 && timeline.schedule[0].selected === null"
            class="py-4" style="display: none;">
            <!-- <div class="is-flex is-align-items-center">
              <div class="is-flex is-align-items-center wrapper-tukang-table">
                <img
                  :src="timeline.skill_image"
                  :alt="timeline.skill_name"
                  class="skill-img"
                />
                <p>{{ timeline.skill_name }}</p>
              </div>
              <b-tooltip
                position="is-right"
                type="is-white"
                :triggers="['hover']"
                :auto-close="['escape', 'outside', 'inside']"
              >
                <template v-slot:content>
                  <div class="p-3 wrapper-tooltip-note">
                    <div class="is-flex mb-4">
                      <img src="" alt="gmbr" class="mr-3" />
                      <p class="averta-bold">Tukang 3D Wall Panel</p>
                    </div>
                    <div class="is-flex mb-5">
                      <div class="pr-4 wrapper-start-date">
                        <p class="text-grey">Tanggal Mulai</p>
                        <p class="averta-bold">15 Januari 2023</p>
                      </div>
                      <div class="pl-4">
                        <p class="text-grey">Tanggal Mulai</p>
                        <p class="averta-bold">15 Januari 2023</p>
                      </div>
                    </div>
                    <div class="mb-5">
                      <p class="text-grey">Catatan Proyek</p>
                      <p
                        class="averta-bold text-note-tukang"
                        style="white-space: normal"
                      >
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Qui voluptatem, odit minima consequuntur perspiciatis
                        voluptatibus? Similique vero aut minus ducimus.
                      </p>
                    </div>
                    <button class="btn-add-note is-clickable">
                      + Tambah Catatan
                    </button>
                  </div>
                </template>
                <PaperIcon />
              </b-tooltip>
            </div> -->
          </td>
          <td v-else :class="{ 'background-konsultan': indexTimeline === 0 }">
            <div class="is-flex is-align-items-center">
              <div class="is-flex is-align-items-center is-justify-content-space-between wrapper-tukang-table">
                <div class="is-flex is-align-items-center">
                  <img :src="timeline.skill_image" :alt="timeline.skill_name" class="skill-img" />
                  <p>{{ timeline.skill_name }}</p>
                </div>
                <div v-if="timeline.group_color && timeline.group_color !== null"
                  class="is-flex is-align-items-center is-clickable" @click="lihatKombinasi(timeline.group_color)">
                  <GroupingIcon :color="timeline.group_color" />
                </div>
              </div>
            </div>
          </td>
          <td
            v-if="timeline.skill_id == idKonsultanPengawas && timeline.schedule.length == 1 && timeline.schedule[0].selected === null"
            style="display: none;"></td>
          <td v-else v-for="(day, indexDay) in timeline.schedule" :key="`${indexDay}_day`" :colspan="day.colspan"
            :class="{ 'background-konsultan': indexTimeline === 0 }">
            <div v-if="day.selected == null || day.selected == 'Tersedia'"></div>
            <div v-else :class="{
              'wrapper-colspan-book': day.selected === 'Booking',
              'wrapper-colspan-schedule': day.selected !== 'Booking',
              'wrapper-colspan-half': day.selected === 'Half Day',
            }">
              <p class="has-text-centered white-space-nowrap">
                {{ day.selected }}
              </p>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PaperIcon, LockIcon, GroupingIcon } from '../icons'

export default {
  name: 'Timeline',
  props: ['duration', 'data', 'projectDate'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    PaperIcon,
    // eslint-disable-next-line vue/no-unused-components
    LockIcon,
    GroupingIcon
  },
  computed: {
    ...mapGetters({
      seeCombine: 'project/getSeeCombineGlobal'
    }),
    loopDuration () {
      const duration = []
      for (let i = 1; i <= this.duration; i++) {
        duration.push(i)
      }
      return duration
    }
  },
  data () {
    return {
      idKonsultanPengawas: process.env.VUE_APP_KONSULTAN_PENGAWAS_ID
    }
  },
  methods: {
    lihatKombinasi (currentColor) {
      this.seeCombine.on = true
      this.seeCombine.color = currentColor
      this.seeCombine.rgba = this.convertHex(currentColor)
      this.editGroup.modal = false
    },
    convertHex (color) {
      color = color.replace('#', '')
      const r = parseInt(color.substring(0, 2), 16)
      const g = parseInt(color.substring(2, 4), 16)
      const b = parseInt(color.substring(4, 6), 16)
      return `rgba(${r}, ${g}, ${b}, 0.14)`
    },
    closeCombine () {
      this.seeCombine.on = false
      this.seeCombine.color = null
      this.seeCombine.rgba = null
    }
  },
  created () {
    this.closeCombine()
  }
}
</script>

<style scoped>
.wrapper-table {
  overflow-y: auto;
}

.c-table tr,
td {
  border: 1px solid #C0C0C0;
}

.c-table td {
  padding: 10px;
}

.c-table tr:nth-child(2) td {
  padding: 10px 50px;
}

.schedule-td {
  padding: 12px 48px;
}

.bg-td-grey {
  background: #fafafa;
}

.text-grey {
  color: #868686;
}

.background-konsultan {
  background: rgba(49, 133, 252, 0.1);
}

.wrapper-tukang-table {
  width: 320px !important;
}

.skill-img {
  width: 34px;
  margin-right: 10px;
}

.wrapper-colspan-book {
  background: #3185fc;
  padding: 12px 4rem;
  border-radius: 24px;
  color: white;
}

.wrapper-colspan-half {
  background: #d96727 !important;
  padding: 12px 4rem;
  border-radius: 24px;
  color: white;
}

.wrapper-colspan-schedule {
  background: #d9272d;
  padding: 12px 4rem;
  border-radius: 24px;
  color: white;
}

.white-space-nowrap {
  white-space: nowrap;
}

.blur-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(20px);
  transform: scale(1.1);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lock-img {
  width: 100%;
  border-radius: 20px;
}

.text-date {
  color: #3185FC;
}
</style>
