<template>
    <div class="wrap-collapse mb-5">
        <b-collapse class="panel shadow-none" animation="slide" v-model="isOpen">
            <template #trigger>
                <div class="p-4 is-flex is-align-items-center is-justify-content-space-between">
                    <div class="is-flex is-align-items-center">
                        <UserRedIcon class="mr-2" />
                        <p class="averta-black label-16 mr-2">Daftar Keahlian</p>
                        <p class="label-16 grey-color mr-2"> - </p>
                        <p class="label-16 grey-color mr-4"> {{ dataSlot.total_skill }} Keahlian</p>
                        <ButtonApp v-if="!isMobile" :isSecondary="true" :isTransparent="true" :isBold="true" class="is-flex is-align-items-center">
                            <p class="mr-3">{{ isOpen ? 'Tutup Daftar Tukang' : 'Lihat Daftar Tukang' }}</p>
                            <ArrowFoward width="16" color="#E10009" :style="isOpen ? 'transform: rotate(90deg)' : ''" />
                        </ButtonApp>
                    </div>
                    <div class="is-flex is-align-items-center">
                        <ArrowFoward v-if="isMobile" color="#868686" class="mr-2" :style="isOpen ? 'transform: rotate(90deg)' : ''" />
                        <div v-else class="wrap-bubble is-flex">
                            <img v-if="dataSlot.list[0]" :src="dataSlot.list[0].skill_image" alt="bubble" class="image bubble-image" :style="`${ dataSlot.list.length === 1 ? 'margin: 0px 0px !important;' : '' }`">
                            <img v-if="dataSlot.list[1]" :src="dataSlot.list[1].skill_image" alt="bubble" class="image bubble-image">
                            <img v-if="dataSlot.list[2]" :src="dataSlot.list[2].skill_image" alt="bubble" class="image bubble-image">
                            <div v-if="dataSlot.total_skill > 3" class="bubble-image count is-flex is-justify-content-center is-align-items-center">
                                <p>+{{ dataSlot.list.length - 3 }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div class="panel-block is-flex is-align-items-center is-justify-content-start px-4 pt-2 pb-4">
                <div class="wrap-card is-flex is-flex-wrap-wrap is-flex-shrink-5"
                    :class="{ 'scroll-skill': dataSlot.total_skill > 10 || isMobile }">
                    <div v-for="skill in dataSlot.list" :key="skill.id"
                        class="card-skill is-flex is-align-items-center">
                        <img :src="skill.skill_image" :alt="skill.skill_name" class="image mr-2">
                        <div>
                            <p class="averta-black label-14 white-space-nowrap skill-name">{{ skill.skill_name }}</p>
                            <p class="label-14 grey-color">Jumlah : {{ skill.total_worker }} Tukang</p>
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import { UserRedIcon, ArrowFoward } from '@/components/icons'
import ButtonApp from '../button/ButtonApp.vue'

export default {
  name: 'CountSkill',
  components: {
    UserRedIcon,
    ArrowFoward,
    ButtonApp
  },
  props: {
    dataSlot: {
      type: Object,
      default: null
    }
  },
  computed: {
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      isOpen: false
    }
  }
}
</script>

<style scoped>
.grey-color {
    color: #868686 !important;
}

.wrap-collapse {
    background: #FAFAFA;
    border-radius: 24px;
    border: 1px solid #f0f0f0;
}

.card-skill {
    background: white;
    padding: 8px 12px;
    width: 240px;
    height: 53px;
    border-radius: 24px;
    border: 1px solid #E1E1E1;
    margin-right: 14px;
    margin-bottom: 16px;
}

.scroll-skill {
    overflow-x: auto !important;
    height: 220px;
}

.image {
    width: 34px;
    object-fit: contain;
}

.columns {
    margin: 0px !important;
}

.skill-name {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bubble-image {
  width: 40px;
  height: 40px;
  margin: 0px -8px;
  border-radius: 100px;
  overflow: hidden;
  border: 4px solid white;
}

.bubble-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.bubble-image.count {
    background: #575757;
    color: white;
    z-index: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1366px) {
  .card-skill {
    width: 209px;
  }
}

@media only screen and (max-width: 600px) {
    .wrap-card {
        width: 100%;
    }

    .card-skill {
        width: 100%;
        padding: 12px 16px;
        margin-right: 0px;
        margin-bottom: 12px;
    }

    .card-skill:is(:last-child) {
        margin-bottom: 18px !important;
    }
}
</style>
