<template>
  <div class="flex-center-vertical mb-5 c-w-100">
    <div class="wrap-client flex-1 flex-center-vertical is-justify-content-space-between">
      <div>
        <p class="averta-bold label-18">{{ detailClient && detailClient.name }}</p>
        <p class="color-grey">{{ detailClient && detailClient.company_name }}</p>
      </div>
      <div class="logo-client flex-center">
        <img
          v-if="detailClient && detailClient.logo"
          :src="detailClient && detailClient.logo"
          alt=""
        />
      </div>
    </div>
    <div
      v-if="detailBranch && detailBranch.name"
      class="wrap-client flex-1 ml-5 flex-center-vertical is-justify-content-space-between"
    >
      <div>
        <p class="color-grey">{{renderBranch}}</p>
        <p class="averta-bold label-18">{{ detailBranch && detailBranch.name }}</p>
      </div>
    </div>
    <div
      v-if="store && store.name"
      class="wrap-client flex-1 ml-5 flex-center-vertical is-justify-content-space-between"
    >
      <div>
        <p class="color-grey">{{renderStore}}</p>
        <p class="averta-bold label-18">{{ store && detailStore.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { titleBranch, titleStore } from '@/helpers'

export default {
  name: 'ClientAndBranchInfo',
  props: {
    detailClient: {
      type: Object,
      default: () => {
        return {
          name: '',
          company_name: '',
          logo: ''
        }
      }
    },
    detailBranch: {
      type: Object,
      default: () => {
        return {
          name: ''
        }
      }
    },
    detailStore: {
      type: Object,
      default: () => {
        return {
          name: ''
        }
      }
    }
  },
  data () {
    return {
      store: null
    }
  },
  computed: {
    ...mapGetters({
      getDetailClient: 'client/getDetailClient'
    }),
    SMRCode () {
      return this.getDetailClient && this.getDetailClient.code
    },
    renderBranch () {
      return titleBranch(this.SMRCode)
    },
    renderStore () {
      return titleStore(this.SMRCode)
    }
  },
  mounted () {
    this.store = this.detailStore
  },
  watch: {
    detailStore: {
      handler (val) {
        this.store = val
      },
      deep: true
    }
  }
}
</script>
