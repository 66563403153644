<template>
  <div class="c-w-100">
    <div class="my-4">
      <b-skeleton height="20px" width="300px" :animated="true"></b-skeleton>
      <b-skeleton height="20px" width="200px" :animated="true"></b-skeleton>
    </div>
    <div class="my-4">
      <b-skeleton height="100px" :animated="true"></b-skeleton>
    </div>
    <div class="my-4">
      <b-skeleton height="170px" :animated="true"></b-skeleton>
    </div>
    <div class="columns">
      <div class="column" v-for="item, index in firstBox" :key="index">
        <b-skeleton height="70px" :animated="true"></b-skeleton>
      </div>
    </div>
    <div>
      <b-skeleton height="400px" :animated="true"></b-skeleton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailSkeleton',
  data () {
    return {
      firstBox: 3
    }
  }
}
</script>
