<template>
  <div>
    <b-loading :is-full-page="true" v-model="isLoadingSubmit" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
    <b-modal v-model="showModal">
      <div class="flex-center">
        <div class="modal-tagihan-wrapper overflow-auto">
          <img :src="require('@/assets/img/forgot-password.png')" alt="" />
          <p class="label-16 averta-bold mb-1 mt-5">Tagihan Sudah Terbayar</p>
          <p class="has-text-centered mb-5">
            Periksa dan pastikan seluruh informasi pembayaran telah benar dan <br />sesuai
          </p>

          <div v-if="isLoadingInvoice" class="c-w-100">
            <b-skeleton width="100%" height="100px" :animated="true"></b-skeleton>
          </div>

          <div v-else-if="!isLoadingInvoice" class="form-modal-invoice bg-lightgray">
            <div class="border-bottom-dashed pb-4 mb-3">
              <p class="averta-bold label-14 mb-2">Pilih Tanggal Pembayaran</p>
              <b-datepicker
                v-model="selectedDatePaymentInvoice"
                :mobile-native="false"
                :min-date="minDatePaymentInvoice"
              >
                <template v-slot:trigger>
                  <div class="input-search-1 bg-white is-clickable px-3 flex-center-vertical">
                    <ScheduleIcon width="15" height="15" />
                    <p class="ml-2 label-14 averta-bold">{{ selectedDatePaymentInvoiceString }}</p>
                  </div>
                </template>
              </b-datepicker>
            </div>

            <div>
              <p class="averta-bold label-14 mb-2">Informasi Tagihan</p>
              <div class="border-radius-12 bg-white flex-center-vertical py-3">
                <div class="px-4 flex-1 border-right-2">
                  <p class="color-grey label-14 mb-1">ID/No. Invoice</p>
                  <p class="averta-bold label-14">ID{{ surveyId }}/{{ invoice }}</p>
                </div>
                <div class="px-4 flex-1">
                  <p class="color-grey label-14">Grand Total Tagihan</p>
                  <p class="color-orange-2 averta-bold">
                    {{ formatIdr(grandTotal) }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div v-if="isLoadingInvoice" class="c-w-100 mt-3">
            <b-skeleton width="100%" height="40px" :animated="true"></b-skeleton>
          </div>

          <div v-else-if="!isLoadingInvoice" class="flex-center c-w-100 mt-3">
            <ButtonApp :isSecondary="true" class="flex-1 mr-2" @click="handleCloseModalInvoice">
              <p class="py-2 averta-bold">Kembali</p>
            </ButtonApp>
            <ButtonApp class="flex-1" @click="handleSubmitFn">
              <p class="py-2 averta-bold">Sudah Terbayar</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { alertErrorMessage, formatCurrency } from '@/helpers'
import { ScheduleIcon, SpinnerIcon } from '@/components/icons'
import confirmImage from '@/assets/img/delete-area-component.png'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'ModalTagihan',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isLoadingInvoice: {
      type: Boolean,
      default: false
    },
    surveyId: {
      type: Number
    },
    projectId: {
      type: Number
    },
    invoice: {
      type: String
    },
    grandTotal: {
      type: Number
    },
    storeName: {
      type: String,
      default: ''
    },
    startDueDate: {
      type: String
    },
    refreshData: {
      type: Function
    },
    handleClose: {
      type: Function
    }
  },
  components: {
    ScheduleIcon,
    ButtonApp,
    SpinnerIcon
  },
  data () {
    return {
      // Invoice
      showModal: this.show,
      isLoadingSubmit: false,
      dataInvoice: null,
      selectedDatePaymentInvoice: null,
      tempDataProject: null,
      minDatePaymentInvoice: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    selectedDatePaymentInvoiceString () {
      return moment(this.selectedDatePaymentInvoice).format('DD/MM/YYYY')
    }
  },
  methods: {
    formatIdr (val) {
      return formatCurrency(val)
    },
    handleCloseModalInvoice () {
      this.showModal = false
      this.handleClose()
    },
    handleSubmitFn () {
      this.$swal({
        imageUrl: confirmImage,
        width: '400px',
        padding: '10px 10px 20px',
        title: '<p class="averta-bold label-18">Konfirmasi Tagihan Sudah Dibayar</p>',
        html: '<p class="averta-regular">Pastikan seluruh informasi tagihan <br/>dan pembayaran telah sesuai</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Cek Kembali',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component flex-1',
          cancelButton: 'btn-batal-detail-component flex-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoadingSubmit = true
          const payload = {
            url: this.url,
            project_id: this.projectId,
            paid_date: moment(this.selectedDatePaymentInvoice).format('YYYY-MM-DD')
          }
          this.$store
            .dispatch('project/putConfirmPayment', payload)
            .then(() => {
              this.isLoadingSubmit = false
              this.handleCloseModalInvoice()

              this.$buefy.toast.open({
                message: `<p class="averta-regular"><span class="is-italic">ID${this.surveyId}</span> ${this.storeName}<span class="averta-bold ml-4">Ditandai Sudah dibayar</span></p>`,
                type: 'is-black',
                duration: 3500
              })
              this.refreshData()
            }).catch((err) => {
              this.isLoadingSubmit = false
              alertErrorMessage(err)
            })
        }
      })
    }
  },
  watch: {
    show (val) {
      this.showModal = val
      if (val) {
        if (this.startDueDate) {
          this.selectedDatePaymentInvoice = new Date(this.startDueDate)
        } else {
          this.selectedDatePaymentInvoice = new Date(moment())
        }

        if (this.startDueDate) {
          const minDate = new Date(this.startDueDate)
          minDate.setDate(minDate.getDate() - 1)
          this.minDatePaymentInvoice = minDate
        } else {
          this.minDatePaymentInvoice = null
        }
      } else {
        this.selectedDatePaymentInvoice = null
      }
    }
  }
}
</script>
