<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C0 5.45 0.196 4.979 0.588 4.587C0.979333 4.19567 1.45 4 2 4H5V2C5 1.45 5.196 0.979333 5.588 0.588C5.97933 0.196 6.45 0 7 0H13C13.55 0 14.021 0.196 14.413 0.588C14.8043 0.979333 15 1.45 15 2V4H18C18.55 4 19.021 4.19567 19.413 4.587C19.8043 4.979 20 5.45 20 6V10H16V9C16 8.71667 15.904 8.479 15.712 8.287C15.5207 8.09567 15.2833 8 15 8C14.7167 8 14.4793 8.09567 14.288 8.287C14.096 8.479 14 8.71667 14 9V10H6V9C6 8.71667 5.90433 8.479 5.713 8.287C5.521 8.09567 5.28333 8 5 8C4.71667 8 4.479 8.09567 4.287 8.287C4.09567 8.479 4 8.71667 4 9V10H0V6ZM7 4H13V2H7V4ZM2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H4C4 11.2833 4.09567 11.5207 4.287 11.712C4.479 11.904 4.71667 12 5 12C5.28333 12 5.521 11.904 5.713 11.712C5.90433 11.5207 6 11.2833 6 11H14C14 11.2833 14.096 11.5207 14.288 11.712C14.4793 11.904 14.7167 12 15 12C15.2833 12 15.5207 11.904 15.712 11.712C15.904 11.5207 16 11.2833 16 11H20V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H2Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ProyekIcon'
}
</script>
