<template>
  <div class="card-deskripsi-proyek p-4" :class="{'my-4': isFromProject}">
    <p class="averta-bold label-20">Catatan Pribadi</p>
    <p class="label-14 mb-4 text-grey">Catatan ini hanya dapat dilihat oleh akun ini</p>
    <b-field>
      <b-input type="textarea" class="my-4" placeholder="Tulis catatan tentang proyek ini" v-model="personalNote" />
    </b-field>
    <p class="color-red mb-4" v-if="message">{{ message }}</p>
    <div class="is-flex is-justify-content-flex-end">
      <ButtonApp :isDisabled="isEmpty" :isBold="true" height="3em" @click="onSaveNote()">
        <p>{{isFromProject ? 'Ubah' : 'Simpan'}}</p>
      </ButtonApp>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import ButtonApp from '@/components/button/ButtonApp.vue'
import { alertErrorMessage, isEmptyAfterTrim } from '@/helpers'

export default {
  name: 'PersonalNote',
  props: {
    notes: {
      type: String
    },
    isFromProject: {
      type: Boolean,
      default: false
    },
    surveyId: {
      type: Number
    },
    loadData: {
      type: Function
    }
  },
  components: {
    ButtonApp
  },
  data () {
    return {
      message: null,
      personalNote: ''
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isEmpty () {
      return isEmptyAfterTrim(this.personalNote)
    }
  },
  methods: {
    formatLastUpdate (date) {
      if (!date) {
        return ''
      }

      return moment(date).format('DD/MM/YYYY - HH:mm')
    },
    onSaveNote () {
      if (this.isEmpty) return

      const payload = {
        url: this.url,
        survey_id: this.surveyId,
        notes: this.personalNote
      }

      this.$store
        .dispatch('project/updatePersonalNote', payload)
        .then(() => {
          this.$buefy.toast.open({
            message: '<p class="averta-regular">Berhasil update catatan</p>',
            type: 'is-success'
          })
          if (this.isFromProject) {
            this.loadData()
          }
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
    }
  },
  mounted () {
    this.personalNote = this.notes
  },
  watch: {
    notes (val) {
      this.personalNote = val
    }
  }
}
</script>

<style scoped>
.w-auto {
  width: auto !important;
}

.card-deskripsi-proyek {
  border: 0.7px solid #d8d8d8;
  border-radius: 12px;
  font-size: 14px;
}

.btn-catatan-proyek {
  background: #9a9a9a;
  border-radius: 100px;
  font-family: 'Averta-Bold';
  color: white;
  width: 100%;
  padding: 24px;
}

.is-from-project {
  width: auto !important;
  display: flex;
  align-self: flex-end;
}

.btn-catatan-proyek.active {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
}
</style>
