<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 20.5C4.48 20.5 0 16.03 0 10.5C0 4.98 4.48 0.5 10 0.5C15.53 0.5 20 4.98 20 10.5C20 16.03 15.53 20.5 10 20.5ZM13.19 14.21C13.31 14.28 13.44 14.32 13.58 14.32C13.83 14.32 14.08 14.19 14.22 13.95C14.43 13.6 14.32 13.14 13.96 12.92L10.4 10.8V6.18C10.4 5.76 10.06 5.43 9.65 5.43C9.24 5.43 8.9 5.76 8.9 6.18V11.23C8.9 11.49 9.04 11.73 9.27 11.87L13.19 14.21Z"
      :fill="color"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1702_19191"
        x1="-8.1504e-08"
        y1="10.3182"
        x2="16.1226"
        y2="18.2248"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop offset="0.9999" :stop-color="color" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ClockCircle',
  props: {
    color: {
      type: String,
      default: '#E10009'
    }
  }
}
</script>
