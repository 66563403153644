<template>
  <svg :width="width ? width : '16'" :height="height ? height : '18'" viewBox="0 0 16 18" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1902 12.0824C15.1254 12.0043 15.0617 11.9262 14.9992 11.8508C14.1398 10.8113 13.6199 10.184 13.6199 7.24141C13.6199 5.71797 13.2554 4.46797 12.5371 3.53047C12.0074 2.83789 11.2914 2.3125 10.3476 1.92422C10.3355 1.91746 10.3246 1.9086 10.3156 1.89805C9.97615 0.761328 9.04724 0 7.99959 0C6.95193 0 6.02342 0.761328 5.68396 1.89687C5.6749 1.90704 5.66421 1.91562 5.65232 1.92227C3.44998 2.82891 2.37967 4.56836 2.37967 7.24023C2.37967 10.184 1.86052 10.8113 1.00037 11.8496C0.937869 11.925 0.874197 12.0016 0.809353 12.0813C0.641853 12.2833 0.535728 12.529 0.503538 12.7894C0.471347 13.0499 0.514439 13.3141 0.627712 13.5508C0.868728 14.0586 1.3824 14.3738 1.96873 14.3738H14.0347C14.6183 14.3738 15.1285 14.059 15.3703 13.5535C15.4841 13.3168 15.5276 13.0523 15.4956 12.7916C15.4637 12.5309 15.3577 12.2848 15.1902 12.0824ZM7.99959 17.5C8.56405 17.4995 9.11787 17.3463 9.60231 17.0566C10.0867 16.7668 10.4837 16.3514 10.7512 15.8543C10.7637 15.8305 10.77 15.8038 10.7692 15.7769C10.7685 15.7499 10.7607 15.7236 10.7468 15.7006C10.7329 15.6775 10.7133 15.6584 10.6898 15.6452C10.6663 15.6319 10.6398 15.625 10.6129 15.625H5.38709C5.36011 15.6249 5.33356 15.6318 5.31003 15.645C5.2865 15.6582 5.2668 15.6773 5.25284 15.7004C5.23887 15.7235 5.23113 15.7498 5.23035 15.7768C5.22958 15.8037 5.2358 15.8304 5.24842 15.8543C5.51581 16.3513 5.91273 16.7667 6.39709 17.0565C6.88145 17.3462 7.43519 17.4995 7.99959 17.5Z"
      :fill="color ? color : '#252525'" />
  </svg>
</template>

<script>
export default {
  name: 'NotificationIcon',
  props: ['color', 'width', 'height']
}
</script>
