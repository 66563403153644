<template>
  <div>
    <div class="notification is-light border-radius-20">
      <!-- <p v-if="listStore && listStore.vendor.code !== 'SMRCN'" class="averta-bold is-size-5">
        Daftar {{isAdmin ? 'Admin' : 'PIC'}} Cabang {{dataPic.branch.name}}
      </p>
      <p v-if="listStore && listStore.vendor.code === 'SMRCN'" class="averta-bold is-size-5">
        Daftar Chief Engineering {{dataPic.branch.name}}
      </p> -->
      <p class="averta-bold is-size-5">Daftar {{renderTitle}} {{dataPic.branch.name}}</p>
    </div>

    <table v-if="dataChief.length > 0" class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <tr class="bg-dark-grey-5 has-text-white has-text-centered tr-wrapper">
        <td class="py-4" style="width: 25%;">
          <p class="averta-bold">NAMA</p>
        </td>
        <td class="py-4" style="width: 25%;">
          <p class="averta-bold">NOMOR HP</p>
        </td>
        <td class="py-4" style="width: 25%;">
          <p class="averta-bold">ALAMAT EMAIL</p>
        </td>
        <td class="py-4" style="width: 15%;">
          <p class="averta-bold">STATUS</p>
        </td>
        <td class="py-4" style="width: 15%;"></td>
      </tr>
      <tr v-for="pic in dataChief" :key="pic.phone" class="vertical-middle tr-wrapper">
        <td class="py-3">
          <div class="is-flex is-align-items-center mx-2">
            <img
              v-if="pic.image_url && pic.image_url !== '-'"
              :src="pic.image_url"
              class="image-pic mr-3 img-shadow-1"
            />
            <img
              v-else
              :src="require('@/assets/img/default-picture.jpg')"
              class="image-pic mr-3 img-shadow-1"
            />
            <p>{{ pic.name }}</p>
          </div>
        </td>
        <td class="py-3">
          <p class="has-text-danger averta-bold">{{ pic.phone }}</p>
        </td>
        <td class="py-3">
          <p>{{ pic.email }}</p>
        </td>
        <td class="py-3 px-4">
          <div class="badge-status-table flex-center" :class="`${statusTemp === 'Aktif' ? 'active' : 'nonactive'}`">
            <p class="averta-bold color-white py-1">{{ statusTemp }}</p>
          </div>
        </td>
        <td>
          <div class="flex-center">
            <div class="is-clickable mr-2 p-3" @click="handleOpenEditPIC(pic)">
              <EditIcon color="#000" />
            </div>
            <!-- <div class="is-clickable" @click="handleDeletePIC(pic)">
              <TrashIcon />
            </div> -->
          </div>
        </td>
      </tr>
    </table>

    <div v-else class="column c-w-100 has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Data Tidak Ditemukan
      </p>
    </div>

    <!-- Delete PIC -->
    <ModalConfirm
      :showModal="showModalDelete"
      imageProps="sunset.png"
      imageClass="mb-3"
      titleProps="Hapus PIC?"
      descProps="Pastikan kamu sudah lakukan pengecekkan sebelum menghapus data ini."
    >
      <template v-slot:button>
        <div class="is-flex">
          <ButtonApp @click="handleCancelDeletePIC" class="mr-3" :isSecondary="true" width="185px" height="52px">
            <p class="averta-bold px-4 py-1">Kembali</p>
          </ButtonApp>
          <ButtonApp @click="handleConfirmDeletePIC" width="185px" height="52px">
            <p class="averta-bold px-4 py-1">Hapus</p>
          </ButtonApp>
        </div>
      </template>
    </ModalConfirm>

    <SidebarEditPICBranch
      :type="type"
      :show="showSidebar"
      :close="handleCloseSidebar"
      @updateTable="refreshData({refresh: true})"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EditIcon, TrashIcon, ProyekEmptyIcon } from '@/components/icons'
// eslint-disable-next-line no-unused-vars
import { alertErrorMessage, isSMRC } from '@/helpers'
import ModalConfirm from '../ModalConfirm.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import SidebarEditPICBranch from '../branch-components/SidebarEditPICBranch.vue'
import { ADMIN_SMRC, PIC, BRANCH, BRANCH_SMRC, PIC_BRANCH_SMRC } from '@/helpers/constants'

export default {
  name: 'PICBranch',
  props: {
    type: {
      type: String, // untuk 'admin'
      default: null
    },
    dataPic: {
      type: Object
    },
    dataChief: {
      type: Array
    },
    listStore: {
      type: Object
    },
    refreshData: {
      type: Function
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TrashIcon,
    EditIcon,
    ProyekEmptyIcon,
    ModalConfirm,
    ButtonApp,
    SidebarEditPICBranch
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      getDetailClient: 'client/getDetailClient'
    }),
    isSMRC () {
      return isSMRC(this.getDetailClient.code)
    },
    isAdmin () {
      return this.type === 'admin'
    },
    renderTitle () {
      let temp
      if (this.isAdmin) {
        temp = `${ADMIN_SMRC} ${BRANCH_SMRC}`
      } else {
        if (this.isSMRC) {
          temp = `${PIC_BRANCH_SMRC} ${BRANCH_SMRC}`
        } else {
          temp = `${PIC} ${BRANCH}`
        }
      }
      return temp
    }
  },
  data () {
    return {
      statusTemp: 'Aktif',
      showModalDelete: false,
      showModalEdit: false,
      showSidebar: false,
      picDelete: null
    }
  },
  methods: {
    handleDeletePIC (pic) {
      this.picDelete = pic
      this.showModalDelete = true
    },
    handleCancelDeletePIC () {
      this.picDelete = null
      this.showModalDelete = false
    },
    handleConfirmDeletePIC () {
      this.showModalDelete = false

      const payload = {
        url: this.url,
        branch_id: this.$route.params.branchId,
        user_id: this.picDelete.id
      }

      this.$store.dispatch('client/deletePICBranch', payload)
        .then(() => {
          this.$buefy.toast.open({
            message: 'PIC berhasil di hapus',
            type: 'is-success'
          })
          this.refreshData({ refresh: true })
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
    },
    handleOpenEditPIC (data) {
      this.showSidebar = true
      this.$store.commit('client/setDetailPICBranch', data)
    },
    handleCloseSidebar () {
      this.showSidebar = false
    },
    handleEditPIC () {
      this.showModalEdit = true
    },
    handleCancelEditPIC () {
      this.showModalEdit = false
    },
    handleConfirmEditPIC () {
      this.showModalEdit = false
      // Refresh Data
      this.refreshData({ refresh: true })
    }
  },
  mounted () {
  }
}
</script>
