<template>
  <div class="status-material-wrapper">
    <div class="head-status">
      <p class="averta-bold label-16">Status Material</p>
      <div @click="handleModalMaterial" class="is-flex is-align-items-center is-justify-content-space-between">
        <p class="color-red label-14 averta-bold is-clickable">Cek Material</p>
        <ArrowRightIcon color="#d9272d" class="ml-2" />
      </div>
    </div>
    <div class="body-status">
      <div class="is-flex is-justify-content-space-between mb-2">
        <p>Total Qty Actual</p>
        <p class="averta-bold">{{ dataMaterial && formatThousand(dataMaterial.total_qty_actual) }}</p>
      </div>
      <div class="is-flex is-justify-content-space-between mb-2">
        <p>Total Qty Estimasi</p>
        <p class="averta-bold">{{ dataMaterial && formatThousand(dataMaterial.total_qty_estimation) }}</p>
      </div>
      <div class="is-flex is-justify-content-space-between mb-2">
        <p>Sisa Qty</p>
        <p class="averta-bold">{{ dataMaterial && formatThousand(dataMaterial.total_qty_remaining) }}</p>
      </div>
      <div class="status-pemesanan-wrapper">
        <div class="body-pemesanan">
          <p>Pemesanan</p>
          <p class="averta-bold">{{ dataMaterial && formatThousand(dataMaterial.total_qty_booked) }}</p>
        </div>
        <div class="body-pemesanan mx-3">
          <p>Pembelian</p>
          <p class="averta-bold">{{ dataMaterial && formatThousand(dataMaterial.total_qty_ordered) }}</p>
        </div>
        <div class="body-pemesanan">
          <p>Terkirim</p>
          <p class="averta-bold">{{ dataMaterial && formatThousand(dataMaterial.total_qty_delivered) }}</p>
        </div>
      </div>
    </div>

    <!-- Sidebar -->
    <b-sidebar type="is-light" :fullheight="true" :fullwidth="true" :overlay="true" :right="true" v-model="modalMaterial">
      <div class="p-5 bg-white averta-regular">
        <div style="width: 200px" class="is-flex is-align-items-center is-clickable mb-6 mt-3" @click="handleModalMaterial">
          <ArrowLeftIcon class="mr-3" />
          <p class="label-18 averta-bold">Kembali ke list</p>
        </div>

        <p class="averta-bold label-16">Daftar Material Proyek</p>
        <p class="label-16 color-gray">Material berikut adalah material yang harus dipenuhi oleh purchasing</p>
        <div class="my-4 mb-5 is-flex overflow-auto thin-scroll pb-sm-1">
          <b-button v-for="tab in dataTabs" :key="tab.id" class="mr-3 btn-tabs averta-regular" :class="{ 'is-active': selectedTab === tab.id }" @click="changeIsActive(tab.id)">
            {{ tab.title }}
          </b-button>
        </div>
        <TableMaterialOffered :dataOffered="dataOffered" v-if="selectedTab === 1" />
        <TableMaterialOrdered :dataOrdered="dataOrdered" v-if="selectedTab === 2" />
      </div>
    </b-sidebar>
    <!-- Sidebar -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency } from '@/helpers'
import { ArrowRightIcon, ArrowLeftIcon } from '@/components/icons'
import TableMaterialOffered from './tables/TableMaterialOffered.vue'
import TableMaterialOrdered from './tables/TableMaterialOrdered.vue'

export default {
  name: 'StatusMaterial',
  components: {
    ArrowRightIcon,
    ArrowLeftIcon,
    TableMaterialOffered,
    TableMaterialOrdered
  },
  props: {
    dataProps: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      dataMaterial: null,
      dataOffered: [],
      dataOrdered: [],
      modalMaterial: false,
      selectedTab: 1,
      dataTabs: [
        {
          id: 1,
          title: 'Material Dibutuhkan'
        },
        {
          id: 2,
          title: 'Material Telah Dipesan'
        }
      ]
    }
  },
  methods: {
    handleModalMaterial () {
      this.modalMaterial = !this.modalMaterial
    },
    changeIsActive (selected) {
      this.selectedTab = selected
    },
    getDetailStatusMaterial () {
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId
      }
      this.$store
        .dispatch('project/getDetailStatusMaterial', payload)
        .then((response) => {
          this.dataOffered = response.data.data.offered_material
          this.dataOrdered = response.data.data.ordered_material
        })
        .catch((error) => {
          console.log(error)
          this.$buefy.toast.open({
            message: error.data.response.message || 'Gagal memuat data detail status material',
            type: 'is-danger'
          })
        })
    },
    formatThousand (val) {
      if (!val && val !== 0 && val !== '0') {
        return '-'
      }
      return formatCurrency(val, true)
    }
  },
  mounted () {
    if (this.dataProps) {
      this.dataMaterial = this.dataProps
    }
  },
  created () {
    this.getDetailStatusMaterial()
  },
  watch: {
    dataProps (val) {
      if (val) {
        this.dataMaterial = val
      }
    }
  }
}
</script>

<style scoped>
.status-material-wrapper {
  border-radius: 12px;
  border: 1px solid #e1e1e1;
  overflow: hidden;
}

.head-status {
  padding: 16px;
  background: #ebf6f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body-status {
  padding: 16px;
}

.status-pemesanan-wrapper {
  border-top: 1px dashed #e1e1e1;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.body-pemesanan {
  border-radius: 12px;
  background: #fafafa;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
</style>
