<template>
  <div class="c-w-100 min-h-90vh">
    <div class="container is-fluid py-5 px-6">
      <NavbarUnitPrice />
      <FormItemWorking />
    </div>
    <div class="divider-bold"></div>
    <div class="container is-fluid py-5 px-6">
      <InputPriceItemWorking />
    </div>
  </div>
</template>

<script>
import NavbarUnitPrice from '../components/NavbarUnitPrice.vue'
import FormItemWorking from '../components/FormItemWorking.vue'
import InputPriceItemWorking from '../components/InputPriceItemWorking.vue'

export default {
  name: 'UnitPrice',
  components: {
    NavbarUnitPrice,
    FormItemWorking,
    InputPriceItemWorking
  },
  data () {
    return {
      disabled: true
    }
  }
}
</script>

<style scoped>
</style>
