<template>
  <div class="calendar-container">
    <div class="calendar-wrapper">
      <FullCalendar ref="fullCalendar" :options="calendarOptions" />
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/scale.css'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import FullCalendar from '@fullcalendar/vue'

export default {
  name: 'CalendarWeek',
  props: ['eventsWeek'],
  components: {
    FullCalendar
  },
  data () {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'timeGridWeek',
        headerToolbar: {
          end: 'prev today next'
        },
        eventDidMount: this.tooltipCalendar,
        events: this.eventsWeek,
        height: 'auto',
        contentHeight: 'auto',
        width: '100%'
      },
      statisUrl: process.env.VUE_APP_URL_SHELL_FE
    }
  },
  watch: {
    eventsWeek: function (val) {
      this.calendarOptions.events = val
    }
  },
  methods: {
    tooltipCalendar (info) {
      tippy(info.el, {
        content: `
        <div class="tooltip-wrapper">
          <div class="tooltip-header p-4 mb-3">
            <p class="averta-bold is-size-5 mb-2">#ID${info.event.id} | ${info.event.title}</p>
            <p>Cabang ${info.event.extendedProps.branch}</p>
          </div>
          <div class="tooltip-body mx-3 mb-5 p-4">
            <p class="tooltip-title-date">Tanggal & Waktu Survey</p>
            <p class="averta-bold">${info.event.extendedProps.date_detail} - <span class="tooltip-detail-hours">(${info.event.extendedProps.hours_detail})</span></p>
          </div>
          <div class="mx-3 wrapper-button">
            <a href="${this.statisUrl}project/survey/${info.event.id}/detail">
              <button class="btn-detail mb-5 p-4 is-clickable">
                <span class="averta-bold has-text-white is-size-6">Lihat Proyek</span>
              </button>
            </a>
          </div>
        </div>`,
        allowHTML: true,
        interactive: true,
        maxWidth: 600,
        theme: 'light',
        placement: 'right',
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom', 'left', 'top']
              }
            }
          ]
        },
        appendTo: this.$refs.fullCalendar.$el,
        zIndex: 1060
      })
    },
    detailSurvey (id) {
      this.$router.push(`project/survey/${id}/detail`)
    }
  },
  mounted () {
    this.tooltipCalendar()
  }
}
</script>

<style lang="css">
.calendar-container {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.calendar-wrapper {
  width: auto;
  height: 800px;
}

.tippy-box {
  border-radius: 20px;
}

.tippy-content {
  padding: 0;
  margin: 0;
}

.tooltip-wrapper {
  width: 30vw !important;
  border-radius: 20px;
}

.tooltip-header {
  background: #FAFAFA;
  border-radius: 20px 20px 0px 0px;
}

.tooltip-body {
  background: #FFF4F4;
  border-radius: 20px;
}

.tooltip-title-date {
  color: #3185FC;
}

.tooltip-detail-hours {
  color: #868686;
}

.btn-detail {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 100px;
  border: none;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .calendar-wrapper {
    width: 1200px;
  }
}
</style>
