<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.46583 5.73624C8.24276 6.03752 8.26838 6.46727 8.5502 6.74C8.8502 7.04 9.3402 7.04 9.6402 6.74L11.2302 5.14V9.28H12.7702V5.14L14.3602 6.74L14.4464 6.81438C14.7477 7.03752 15.1775 7.01273 15.4502 6.74C15.6002 6.59 15.6802 6.39 15.6802 6.19C15.6802 6 15.6002 5.8 15.4502 5.65L12.5402 2.73L12.4495 2.64848C12.3202 2.5512 12.1602 2.5 12.0002 2.5C11.7902 2.5 11.6002 2.58 11.4502 2.73L8.5402 5.65L8.46583 5.73624ZM6.23116 9.28512C3.87791 9.39627 2 11.3758 2 13.7875V18.7526L2.00484 18.9651C2.1141 21.3599 4.06029 23.2802 6.45 23.2802H17.56L17.7688 23.2753C20.1221 23.1641 22 21.1843 22 18.7628V13.8078L21.9951 13.5945C21.8853 11.1909 19.93 9.2802 17.55 9.2802H12.77V15.3849L12.7629 15.4922C12.7112 15.8776 12.385 16.1683 12 16.1683C11.57 16.1683 11.23 15.8224 11.23 15.3849V9.2802H6.44L6.23116 9.28512Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'UploadIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    size: {
      type: String,
      default: '24'
    }
  }
}
</script>
