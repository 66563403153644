<template>
  <div>
    <div class="card-status-confirmation mb-4" style="border-top-left: none; border-top-right: none" v-if="dataHeader && dataHeader.project_confirmation && dataHeader.project_confirmation.length">
      <div class="header-detail p-5 is-flex is-justify-content-space-between is-align-items-center flex-sm-column align-items-sm-start">
        <div>
          <p class="averta-bold">Status Konfirmasi Proyek Selesai</p>
          <p>
            <span class="has-text-danger averta-bold">{{ countingConfirmation(dataHeader.project_confirmation) }}</span> <span class="label-cs">Konfirmasi</span>
          </p>
        </div>
        <div class="is-flex is-align-items-center flex-sm-column align-items-sm-start c-w-sm-100 border-radius-10">
          <div v-for="item in dataHeader.project_confirmation" :key="item.id" class="wrapper-confirmation-item is-flex is-align-items-center c-w-sm-100 c-h-100 border-sm-none mt-sm-1">
            <CheckIcon v-if="item.created_at" color="#10BD41" class="mr-3" />
            <div v-else class="unchecked mr-3" />
            <div>
              <p class="averta-bold">{{ item.label }}</p>
              <p class="text-phone is-italic label-10" v-if="item.label === 'Tukang Kanggo' && item.created_at === null">Dalam pengerjaan tukang</p>
              <p class="text-phone is-italic label-10" v-if="item.label === 'PIC Toko' && item.created_at === null">Menunggu pengerjaan tukang selesai</p>
              <p class="text-phone is-italic label-10" v-if="item.label === 'PIC Cabang' && item.created_at === null">Menunggu pekerjaan selesai</p>
              <p class="text-phone is-italic label-10" v-if="item.label === 'PIC Outlet' && item.created_at === null">Menunggu pekerjaan selesai</p>
              <p class="text-confirmation label-10 averta-bold" v-if="item.label === 'Tukang Kanggo' && item.created_at !== null">Pekerjaan Selesai - {{ convertConfirmation(item.created_at) }}</p>
              <p class="text-confirmation label-10 averta-bold" v-if="item.label === 'PIC Toko' && item.created_at !== null">Sudah Konfirmasi Selesai - {{ convertConfirmation(item.created_at) }}</p>
              <p class="text-confirmation label-10 averta-bold" v-if="item.label === 'PIC Cabang' && item.created_at !== null">Sudah Konfirmasi Selesai - {{ convertConfirmation(item.created_at) }}</p>
              <p class="text-confirmation label-10 averta-bold" v-if="item.label === 'PIC Outlet' && item.created_at !== null">Sudah Konfirmasi Selesai - {{ convertConfirmation(item.created_at) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="p-5">
        <b-collapse class="has-background-white collapse-wrapper" animation="slide" aria-id="contentIdForA11y3" :open="false">
          <template #trigger="props">
            <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
              <div class="card-header-title is-block">
                <p>Detail Status Pekerjaan Tukang</p>
                <p>
                  <span class="has-text-danger">{{ confirmationWorker(dataHeader.status_worker) }}</span> <span class="has-text-grey">Konfirmasi</span>
                </p>
              </div>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
              </a>
            </div>
          </template>

          <div class="card-content">
            <div class="list-detail">
              <div v-for="(item, index) in dataHeader.status_worker" :key="item.label" class="is-flex is-align-items-center" :class="{ 'mb-5': index + 1 !== dataHeader.status_worker.length }">
                <CheckIcon v-if="item.checked" color="#10BD41" class="mr-3" />
                <div v-else class="unchecked mr-3" />
                <div>
                  <p class="averta-bold mb-0">{{ item.skill_name }}</p>
                  <p class="text-phone is-italic" v-if="!item.checked">Dalam pengerjaan tukang</p>
                  <p v-else class="text-confirmation averta-bold">Sudah Konfirmasi Selesai</p>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { CheckIcon } from '@/components/icons'

export default {
  name: 'StatusConfirmationDetail',
  props: {
    dataHeaderProps: {
      type: Object
    }
  },
  components: {
    CheckIcon
  },
  data () {
    return {
      dataHeader: null
    }
  },
  methods: {
    countingConfirmation (data) {
      let count = 0
      data.forEach((el) => {
        if (el.created_at !== null) {
          count = count + 1
        }
      })
      return count
    },
    convertConfirmation (date) {
      return moment(date).format('DD MMMM YYYY hh:mm:ss')
    },
    confirmationWorker (data) {
      let count = 0
      data.forEach((el) => {
        if (el.checked === true) {
          count = count + 1
        }
      })
      return count
    }
  },
  mounted () {
    this.dataHeader = this.dataHeaderProps
  },
  watch: {
    dataHeaderProps (val) {
      this.dataHeader = val
    }
  }
}
</script>

<style scoped>
.header-detail {
  background: linear-gradient(90deg, #ffdddd 0%, #ffefdb 99.97%);
  /* border-radius: 20px 20px 0px 0px; */
}

.unchecked {
  width: 24px;
  height: 24px;
  border: 1px solid #868686;
  border-radius: 100px;
}

.wrapper-confirmation-item {
  background: #ffffff;
  padding: 12px 15px;
}

.wrapper-confirmation-item:not(:last-child) {
  border-right: 2px solid #e1e1e1;
}

.collapse-wrapper {
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  background: #fff;
}

.card-status-confirmation {
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  overflow: hidden;
}

.list-detail {
  max-height: 20em;
  overflow: auto;
}

.card-header {
  border-bottom: 1px solid #f1f2f2;
}
</style>
