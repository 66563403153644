<template>
  <div class="component-loader-1" :class="{'is-black': isBlack}"></div>
</template>

<script>
export default {
  name: 'SpinnerLoading',
  props: {
    isBlack: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.component-loader-1 {
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.component-loader-1.is-black {
  border: 3px solid #000;
  border-bottom-color: transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
