<template>
  <div class="c-w-100 px-4 px-sm-0 min-height-project">
    <div v-if="dataProyek.submenu && dataProyek.submenu.length > 0" class="mt-2 mb-5 is-flex wrapper-button">
      <b-button v-for="tab in dataProyek.submenu" :key="tab.label" class="mr-3 btn-tabs"
        :class="{ 'is-active': substatus === tab.query }" @click="changeIsActive(tab.query)">{{ wordingHelper(tab.label) }}
        <span class="tag is-danger is-rounded ml-2"> {{ tab.count }}</span>
      </b-button>
    </div>
    <div v-if="dataProyek.list && dataProyek.list.length > 0" :class="{'is-loading-wrapper': isLoading}">
      <div v-for="data, index in dataProyek.list" :key="`${index}-list-proyek`">
        <div v-if="specificLoading && listPersist.surveyId === data.survey_id">
          <CardSpecificSkeleton />
        </div>
        <NewCardProject
          v-else
          :data="data"
          @showModalProyekDarurat="showProyekDarurat($event)"
          @lihatCatatanProyek="showCatatanProyek($event)"
          @showModalInvoiceTagihan="showInvoiceTagihan($event)"
          @showModalTanggalPenagihan="showTanggalPenagihan($event)"
          @showModalBeforeAfter="showSidebarBeforeAfter($event)"
          @detailProject="detailProyek($event)"
          @reloadList="reloadList"
        />
      </div>
      <div class="flex-center">
        <p v-if="isLoadingScroll">Loading</p>
        <div v-else-if="currentPage === totalPages" style="width: 4px; height: 4px; border-radius: 4px; background: gray;"></div>
      </div>
    </div>
    <DataNotFound v-else />

    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>

    <!-- Modal Proyek Darurat -->
    <ModalProyekDarurat :dataModalUrgent="projectUrgent" :surveyId="surveyId" :showModal="modalProyekDarurat" :width="isMobile ? '95vw' : '45vw'" @closeModal="showProyekDarurat" :loadData="reloadList" />
    <!-- Modal Proyek Darurat -->

    <!-- Modal History Catatan Proyek -->
    <ModalHistoryCatatanProyek :surveyId="surveyId" :showModal="modalHistoryCatatanProyek" width="95vw" @closeModal="showCatatanProyek" />
    <!-- Modal History Catatan Proyek -->

    <!-- Modal Invoice Tagihan -->
    <ModalInvoiceTagihan :data="dataInvoice" :showModal="modalInvoiceTagihan" :width="isMobile ? '95vw' : '40vw'" @closeModal="showInvoiceTagihan" @reloadList="reloadList" />
    <!-- Modal Invoice Tagihan -->

    <!-- Modal Tanggal Penagihan -->
    <ModalTanggalPenagihan :data="dataPenagihan" :showModal="modalTanggalPenagihan" :width="isMobile ? '95vw' : '40vw'" @closeModal="showTanggalPenagihan" @reloadList="reloadList" />
    <!-- Modal Tanggal Penagihan -->

    <!-- Sidebar Before After -->
    <SidebarBeforeAfter
      :showSidebar="sidebarBeforeAfter"
      :data="dataSidebarBeforeAfter"
      type="list"
      @closeSidebar="closeSidebarBeforeAfter"
    />
    <!-- Sidebar Before After -->

  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import DataNotFound from '@/components/DataNotFound'
import { SpinnerIcon } from '@/components/icons'
import { formatCurrency, getStatusClassName, wordingMenuHelper } from '@/helpers'
import NewCardProject from '../NewCardProject.vue'
import ModalProyekDarurat from '../../modal/ModalProyekDarurat.vue'
import ModalHistoryCatatanProyek from '../../modal/ModalHistoryCatatanProyek.vue'
import ModalInvoiceTagihan from '../../modal/ModalInvoiceTagihan.vue'
import ModalTanggalPenagihan from '../../modal/ModalTanggalPenagihan.vue'
import SidebarBeforeAfter from '@/components/SidebarBeforeAfter.vue'
import CardSpecificSkeleton from '@/components/skeleton/CardSpecificSkeleton.vue'

export default {
  name: 'ListProyek',
  props: [
    'type',
    'isPayment',
    'showPaymentDate',
    'selectedStatus',
    'selectedSubstatus',
    'activeTab',
    'currentTab',
    'selectedTab',
    'triggerAPIFromScroll',
    'triggerTop',
    'reloadData'
  ],
  components: {
    DataNotFound,
    SpinnerIcon,
    NewCardProject,
    ModalProyekDarurat,
    ModalHistoryCatatanProyek,
    ModalInvoiceTagihan,
    ModalTanggalPenagihan,
    SidebarBeforeAfter,
    CardSpecificSkeleton
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      subtab: 'list/getSelectedSubTab',
      listPersist: 'list/getPersistDataList',
      listFilter: 'project/getDataListFilter'
    }),
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    },
    renderSearchBy () {
      switch (this.searchBy) {
        case 'store':
          return 'Toko'
        case 'survey':
          return 'ID Survey'
        case 'offering':
          return 'ID Penawaran'
        case 'project':
          return 'ID Proyek'
        default:
          return 'Pilih Filter'
      }
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingScroll: false,
      initialActiveTab: null,
      specificLoading: false,
      seamlessLoading: false,

      dataProyek: {
        submenu: [],
        list: []
      },
      searchBy: 'store',
      search: null,
      sort: 'desc',
      substatus: null,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      limitPage: 5,
      vendor: null,

      modalProyekDarurat: false,
      modalHistoryCatatanProyek: false,
      modalInvoiceTagihan: false,
      modalTanggalPenagihan: false,

      sidebarBeforeAfter: false,
      dataSidebarBeforeAfter: null,
      dataTotalReschedule: 0,
      dataInvoice: null,
      dataPenagihan: null,
      projectUrgent: null,
      surveyId: null
    }
  },
  methods: {
    formatThousand (x) {
      return formatCurrency(x)
    },
    convertDatePembayaran (date) {
      if (!date) {
        return ''
      }
      return moment(date).format('DD MMMM YYYY - HH:mm')
    },
    detailProyek (data) {
      const currentRole = this.user.roles[0].name
      this.persistDataList({ pageDetail: true, surveyId: data.survey_id })

      switch (currentRole) {
        case 'Konsultan Survey':
          this.$router.push({ path: `/project/${data.project_id}/detail` })
          break
        case 'Site Manager':
          this.$router.push({ path: `/sm/project/${data.project_id}/detail` })
          break
        case 'Account Manager':
          this.$router.push({ path: `/am/project/${data.project_id}/detail` })
          break
        case 'Purchasing':
          this.$router.push({ path: `/purchasing/project/${data.project_id}/detail` })
          break
        default:
          break
      }
    },
    getListProyek (props = {}) {
      const {
        pages = 1,
        clearVendor = false,
        loadMore = false,
        dontLoading = false,
        seamlessLoading = false
      } = props

      if (clearVendor) {
        this.vendor = null
      }

      if (loadMore) {
        this.isLoadingScroll = true
      } else if (seamlessLoading) {
        this.seamlessLoading = true
      } else if (!dontLoading && !seamlessLoading) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        sort: this.sort,
        limit: this.limitPage,
        page: pages || 1,
        type: this.type,
        status: this.substatus
      }

      // filter by selected vendor
      if (this.vendor !== null) {
        payload.vendor = this.vendor
      }

      this.$store
        .dispatch('projectV2/getListProject', payload)
        .then((response) => {
          const res = response.data
          this.currentPage = res.current_page

          if (loadMore) {
            this.isLoadingScroll = false
            this.dataProyek.list = [...this.dataProyek.list, ...res.data.list]
          } else {
            this.dataProyek = res.data
            this.totalPages = res.total_page
            this.totalData = res.total_data
          }
          this.isLoading = false
          this.seamlessLoading = false

          // Store Data
          this.persistDataList()
        })
        .catch((error) => {
          this.isLoading = false
          this.isLoadingScroll = false
          this.seamlessLoading = false
          console.log(error)
        })
    },
    convertDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    changeIsActive (data) {
      this.substatus = data
      if (this.activeTab || this.activeTab >= 0) {
        this.$store.commit('list/setSelectedSubTab', { tab: parseInt(this.activeTab), subtab: data })
      }
      this.getListProyek()
    },
    countingConfirmation (data) {
      let count = 0
      data.forEach((el) => {
        if (el.created_at !== null) {
          count = count + 1
        }
      })
      return count
    },
    confirmationWorker (data) {
      let count = 0
      data.forEach((el) => {
        if (el.checked === true) {
          count = count + 1
        }
      })
      return count
    },
    convertConfirmation (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    showSidebarBeforeAfter (data) {
      this.sidebarBeforeAfter = true
      this.dataSidebarBeforeAfter = data
    },
    closeSidebarBeforeAfter () {
      this.sidebarBeforeAfter = false
      this.dataSidebarBeforeAfter = null
    },
    getClassName (status) {
      return getStatusClassName(status)
    },
    getActiveTab () {
      if (this.subtab && this.subtab.length > 0) {
        const lastMatchingObject = this.subtab.find(obj => obj.tab === this.activeTab)
        if (lastMatchingObject) {
          this.substatus = lastMatchingObject.subtab || this.selectedSubstatus
        } else {
          this.substatus = this.selectedSubstatus
        }
      } else {
        this.substatus = this.selectedSubstatus
      }

      const findDataProject = this.listPersist.data.find(item => item.currentTab === this.initialActiveTab)
      if (findDataProject) {
        this.restoreDataList(findDataProject)
      } else {
        this.getListProyek()
      }
    },
    showProyekDarurat (data) {
      if (data) {
        this.projectUrgent = data.projectUrgent
        this.surveyId = data.surveyId
      }
      this.modalProyekDarurat = !this.modalProyekDarurat
    },
    showCatatanProyek (data) {
      if (data) {
        this.surveyId = data
      }
      this.modalHistoryCatatanProyek = !this.modalHistoryCatatanProyek
    },
    showInvoiceTagihan (data) {
      if (data) {
        this.dataInvoice = data
      }
      this.modalInvoiceTagihan = !this.modalInvoiceTagihan
    },
    showTanggalPenagihan (data) {
      if (data) {
        this.dataPenagihan = data
      }
      this.modalTanggalPenagihan = !this.modalTanggalPenagihan
    },
    reloadList () {
      this.getListProyek()
      this.modalProyekDarurat = false
      this.modalHistoryCatatanProyek = false
      this.modalInvoiceTagihan = false
      this.modalTanggalPenagihan = false
    },
    wordingHelper (value) {
      return wordingMenuHelper(value)
    },
    persistDataList (props = {}) {
      const { pageDetail = false, surveyId } = props

      const store = {
        data: {
          currentTab: this.initialActiveTab,
          project: this.dataProyek,
          currentPage: this.currentPage,
          totalPage: this.totalPages,
          totalData: this.totalData,
          sort: this.sort,
          vendor: this.vendor
        }
      }
      if (pageDetail) {
        store.scrollPosition = window.scrollY || window.pageYOffset
        store.detailPage = true
        store.surveyId = surveyId
      }

      this.$store.commit('list/setPersistDataList', store)
    },
    restoreDataList (data) {
      const { project, currentPage, totalPage, totalData, sort, vendor } = data

      this.dataProyek = project
      this.currentPage = currentPage
      this.totalPages = totalPage
      this.totalData = totalData
      this.sort = sort
      this.vendor = vendor
      this.isLoading = false
      this.initialLoading = false

      setTimeout(() => {
        window.scrollTo(0, this.listPersist.scrollPosition)
      }, 50)

      if (this.initialActiveTab === this.selectedTab) {
        this.loadSpecificList()
      }
    },
    loadSpecificList () {
      this.specificLoading = true

      this.$store
        .dispatch('projectV2/getSpecificListProject', {
          url: this.url,
          surveyId: this.listPersist.surveyId
        })
        .then(response => {
          const res = response.data.data
          const findList = this.dataProyek.list.find(item => item.survey_id === res.survey_id)
          const indexSurveyId = this.dataProyek.list.findIndex(item => item.survey_id === res.survey_id)

          if (findList && indexSurveyId !== -1) {
            const isSame = JSON.stringify(findList.status) === JSON.stringify(res.status)

            if (isSame) {
              this.dataProyek.list[indexSurveyId] = res
            } else {
              // this.dataProyek.list.splice(indexSurveyId, 1)
              this.$emit('reloadData', { fromDetail: true })
            }

            setTimeout(() => {
              window.scrollTo(0, isSame ? this.listPersist.scrollPosition : 0)
              this.specificLoading = false
            }, 50)
          } else {
            this.specificLoading = false
          }
        })
        .catch((error) => {
          this.specificLoading = false
          console.log(error)
        })
    }
  },
  mounted () {
    this.getActiveTab()
  },
  created () {
    this.initialActiveTab = this.currentTab
  },
  watch: {
    search (val) {
      if (val === '') {
        this.getListProyek()
      }
    },
    listFilter (val) {
      if (val) {
        if (val.sort) {
          this.sort = val.sort
        } else {
          this.sort = 'desc'
        }
        if (val.vendor) {
          this.vendor = val.vendor
        } else {
          this.vendor = null
        }
        this.getListProyek()
      }
    },
    triggerAPIFromScroll () {
      if (this.initialActiveTab === this.selectedTab) {
        if (this.currentPage < this.totalPages && !this.isLoadingScroll) {
          this.currentPage++
          this.getListProyek({ pages: this.currentPage, loadMore: true })
        }
      }
    },
    selectedTab (val) {
      if (this.initialActiveTab === val) {
        this.getListProyek({ dontLoading: true })
      }
    },
    triggerTop (val) {
      if (this.initialActiveTab === this.selectedTab) {
        if (val === false && !this.seamlessLoading) {
          this.getListProyek({ seamlessLoading: true })
        }
      }
    }
  }
}
</script>

<style scoped>
.card-list {
  border-radius: 20px;
}

.header-detail {
  background: linear-gradient(90deg, #ffdddd 0%, #ffefdb 99.97%);
  border-radius: 20px 20px 0px 0px;
}

.id-survey {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.btn-lihat-detail {
  border: 1px solid #d9272d;
  border-radius: 20px;
  padding: 12px 24px;
  font-family: 'Averta-Bold';
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  cursor: pointer;
}

.wrapper-button {
  overflow-y: auto;
  padding-bottom: 10px;
}

.btn-tabs {
  border-radius: 20px;
  color: #9a9a9a;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
}

.wrapper-confirmation {
  border-top: 2px dashed #868686;
  background: #fafafa;
}

.wrapper-confirmation-item {
  background: #ffffff;
  padding: 12px 24px;
}

.wrapper-confirmation-item:not(:last-child) {
  border-right: 1px solid #e1e1e1;
}

.unchecked {
  width: 24px;
  height: 24px;
  border: 1px solid #868686;
  border-radius: 100px;
}

.image-upload {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 6px;
}

.card-estimasi {
  background: #535353;
  border-radius: 12px;
  color: white;
}

.card-material {
  background: white;
  margin: 0px 8px;
  border-radius: 0px 12px 12px 0px;
}

.border-left-red {
  border-left: 4px solid #891313;
}

.border-left-yellow {
  border-left: 4px solid #E8C616;
}

.border-left-green {
  border-left: 4px solid #2EDB12;
}

.wrap-logo {
  background: white;
  border-radius: 100px;
  padding: 6px 18px;
}

.wrap-logo img {
  height: 30px;
}

@media only screen and (max-width: 768px) {
  .filter-list {
    margin-top: 20px;
    display: flex;
    width: 100%;
  }

  .dropdown-list,
  .btn-dropdown {
    width: 100%;
  }
}
</style>
