<template>
  <div>
    <ModalTemplate :showModal="isModalActive" width="900px" @closeModal="closeModal">
      <template v-slot:headerModal>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="flex-center-vertical">
            <DocumentIcon color="#fff" />
            <p class="averta-bold label-18 ml-2">Riwayat Penggunaan Saldo</p>
          </div>
          <div class="is-clickable" @click="closeModal">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div class="wrap-body-modal p-5">
          <div v-if="isLoading">
            <b-loading :active="true" :is-full-page="true" :can-cancel="false">
              <SpinnerIcon />
            </b-loading>
          </div>
          <div v-else-if="!isLoading && dataModal.length > 0">
            <div class="wrap-table mb-5">
              <p class="averta-black label-20">Riwayat Penggunaan Saldo</p>
              <p class="color-grey mb-5">Berikut menampilkan daftar penggunaan saldo untuk SPK terkait pada setiap penawaran</p>
              <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                <tr class="bg-dark-grey-5 has-text-white has-text-centered">
                  <td class="py-4 averta-bold" style="width: 10%;"></td>
                  <td class="py-4 averta-bold" style="width: 25%;">
                    <p class="has-text-centered">ID</p>
                  </td>
                  <td class="py-4 averta-bold" style="width: 25%;">
                    <div class="flex-center is-flex-direction-column">
                      <p>TANGGAL</p>
                      <p>PENAWARAN DISETUJUI</p>
                    </div>
                  </td>
                  <td class="py-4 averta-bold" style="width: 25%;">
                    <p class="has-text-centered">JUMLAH SALDO DIGUNAKAN</p>
                  </td>
                  <td style="width: 10%;"></td>
                </tr>
                <tr
                  class="tr-wrapper averta-bold"
                  v-for="(item, index) in dataModal"
                  :key="`data-history-${index}`"
                >
                  <td class="py-3 has-text-centered">
                    <p>{{ index+1 }}</p>
                  </td>
                  <td class="py-3 has-text-centered">
                    <p>{{ item.survey_id }}</p>
                  </td>
                  <td class="py-3 has-text-centered">
                    <p>{{ formatDateHistory(item.offering_approved_date) }}</p>
                  </td>
                  <td class="py-3 has-text-centered">
                    <p>{{ formatThousand(item.used_balance) }}</p>
                  </td>
                  <td>
                    <div class="c-w-100 flex-center">
                      <div class="action-button" @click="handlePageDetail(item)">
                        <ArrowRightIcon color="red" size="13" />
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div
            v-if="!isLoading && dataModal.length < 1"
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
          >
            <ProyekEmptyIcon class="mb-3" />
            <p class="averta-bold color-black">Belum ada riwayat penggunaan saldo</p>
          </div>
        </div>
      </template>
    </ModalTemplate>
  </div>
</template>

<script>
import moment from 'moment'
import { alertErrorMessage, formatCurrency } from '@/helpers'
import { ProyekEmptyIcon, SpinnerIcon, ArrowRightIcon, DocumentIcon } from '@/components/icons'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'

export default {
  name: 'ModalHistorySPK',
  components: {
    ModalTemplate,
    ProyekEmptyIcon,
    SpinnerIcon,
    ArrowRightIcon,
    DocumentIcon
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    documentId: {
      type: [String, Number]
    }
  },
  data () {
    return {
      url: process.env.VUE_APP_SHELL_URL_API,
      isModalActive: false,
      isLoading: false,
      dataModal: []
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    formatDateHistory (value) {
      return moment(value).format('DD MMMM YYYY')
    },
    formatThousand (val) {
      return formatCurrency(val, true)
    },
    getDataModal () {
      this.isLoading = true

      const payload = {
        url: this.url,
        document_id: this.documentId
      }

      this.$store
        .dispatch('client/getHistorySPKSIK', payload)
        .then((res) => {
          this.dataModal = res.data.data
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    handlePageDetail (item) {
      let path
      if (item.project_id) {
        path = { path: `/am/project/${item.project_id}/detail` }
      } else {
        path = { path: `/am/project/survey/${item.survey_id}/detail` }
      }

      const routeData = this.$router.resolve(path)
      window.open(routeData.href, '_blank')
    }
  },
  watch: {
    showModal (val) {
      this.isModalActive = val
      if (val) {
        this.getDataModal()
      }
    }
  }
}
</script>

<style scoped>
.wrap-body-modal {
  height: 450px;
  overflow-y: auto;
  position: relative;
}

.wrap-table {
  overflow-x: auto;
}

.table td {
  vertical-align: middle;
}

.note-desc-wrapper {
  max-height: 5em;
  overflow: auto;
  width: 100%;
}

.action-button {
  width: 35px;
  height: 35px;
  border: 1px solid #e10009;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: white;
}
</style>
