<template>
  <div class="flex-center-vertical is-justify-content-space-between is-clickable" @click="handleClick">
    <div class="flex-center">
      <div class="icon-wrapper flex-center">
        <slot name="icon" />
      </div>
      <p class="averta-bold ml-2 mr-1">{{title}}</p>
    </div>
    <div class="flex-shrink-0 mt-2">
      <ChevronIcon color="#9A9A9A" />
      <OutletIcon color="red" />
    </div>
  </div>
</template>

<script>
import { ChevronIcon, OutletIcon } from '@/components/icons'

export default {
  name: 'ButtonDropdownItem',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  components: {
    ChevronIcon,
    OutletIcon
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.icon-wrapper {
  background: linear-gradient(141.06deg, rgba(217, 39, 45, 0.06) -10.37%, rgba(217, 39, 39, 0.04) 102.77%);
  border: 0.5px solid #EB46001A;
  height: 2.5em;
  width: 2.5em;
  border-radius: 100px;
  flex-shrink: 0;
}

</style>
