
<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2225_80833" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="5" y="2" width="10" height="16">
      <path
        d="M5.83171 17.5129C6.24005 17.9212 6.89838 17.9212 7.30671 17.5129L14.2317 10.5879C14.5567 10.2629 14.5567 9.73789 14.2317 9.41289L7.30671 2.48789C6.89838 2.07956 6.24005 2.07956 5.83171 2.48789C5.42338 2.89622 5.42338 3.55456 5.83171 3.96289L11.865 10.0046L5.82338 16.0462C5.42338 16.4462 5.42338 17.1129 5.83171 17.5129Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_2225_80833)">
      <rect width="20" height="20" :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ArrowFoward',
  props: {
    color: {
      type: String,
      default: '#D9272D'
    },
    size: {
      type: String,
      default: '20'
    }
  }
}
</script>
