<template>
  <div class="overflow-auto c-w-100 min-h-60vh mb-4">
    <div v-if="dataOrdered.length > 0">
      <div v-for="(itemOrder, indexOrder) in dataOrdered" :key="`index-parent-${indexOrder}`">
        <div class="title-ordered-wrapper mb-5 flex-sm-column align-items-sm-start" :class="{'my-5': indexOrder !== 0}">
          <div class="is-flex is-align-items-center flex-sm-column c-w-sm-100 align-items-sm-start mb-sm-1">
            <div class="pr-5 mr-5 border-right-1 border-radius-sm-none mb-sm-1" style="border-color: #E1E1E1 !important;">
              <p class="is-italic color-gray">Nama Toko</p>
              <p class="averta-bold">{{itemOrder.material_store_name}}</p>
            </div>
            <div>
              <p class="is-italic color-gray">Tanggal Pemesanan</p>
              <p class="averta-bold">{{formatDate(itemOrder.created_at)}}</p>
            </div>
          </div>
          <div v-if="isBS">
            <p class="averta-bold">Ongkir : <span :class="{'color-green': itemOrder.expedition_price, 'color-red is-italic': !itemOrder.expedition_price}">{{formatRupiah(itemOrder.expedition_price, true)}}</span></p>
          </div>
        </div>

        <div class="has-text-centered is-relative is-flex is-justify-content-space-bertween overflow-sm-auto thin-scroll">
          <!-- fixed table -->
          <div class="table-left-fixed">
            <table class="table is-narrow is-hoverable table-wrapper label-14 averta-regular" :class="{'adjust-width-table': !isBS}">
              <tr class="has-background-grey has-text-white has-text-centered averta-bold tr-head-wrapper">
                <td style="width: 5%">
                  <div>NO</div>
                </td>
                <td style="width: 25%">
                  <div class="px-3 is-justify-content-flex-start">MATERIAL</div>
                </td>
                <td style="width: 8%">
                  <div>UOM (SATUAN)</div>
                </td>
                <td v-if="isBS" style="width: 15%">
                  <div>HARGA/PCS</div>
                </td>
                <td style="width: 5%">
                  <div>QTY DIPESAN</div>
                </td>
                <td v-if="isBS" style="width: 12%" class="border-radius-sm-none">
                  <div>TOTAL</div>
                </td>

                <!-- ONLY FOR MOBILE -->
                <td style="width: 4%" class="is-hidden-tablet">
                  <div>PEMESANAN</div>
                </td>
                <td style="width: 4%" class="is-hidden-tablet">
                  <div>PEMBELIAN</div>
                </td>
                <td style="width: 4%" class="is-hidden-tablet">
                  <div>TERKIRIM</div>
                </td>
                <td class="is-hidden-tablet border-radius-last-top">
                  <div style="width: 20em">TUJUAN</div>
                </td>

              </tr>
              <tr v-for="(itemMaterial, indexMaterial) in itemOrder.materials" :key="`index-material-${indexMaterial}`" class="tr-wrapper label-14" :class="{ 'bg-lightgray': (indexMaterial + 1) % 2 === 0 }">
                <td>
                  <div class="py-3 has-text-centered">{{ indexMaterial + 1 }}</div>
                </td>
                <td>
                  <div class="is-flex is-align-items-center wrapper-material-name">
                    <div class="image-material">
                      <img class="is-clickable" :src="itemMaterial.picture" v-if="itemMaterial.picture" @click="handlePreviewImage(itemMaterial.picture)">
                      <img :src="require('@/assets/img/empty-img-2.png')" v-else>
                    </div>
                    <p class="material-name">{{ itemMaterial.name }}</p>
                  </div>
                </td>
                <td>
                  <div class="py-3 has-text-centered">{{ itemMaterial.uom }}</div>
                </td>
                <td v-if="isBS">
                  <div class="py-3 has-text-centered">{{ formatRupiah(itemMaterial.unit_price) }}</div>
                </td>
                <td>
                  <div class="py-3 has-text-centered">{{ itemMaterial.qty_booked }}</div>
                </td>
                <td v-if="isBS" class="border-radius-sm-none">
                  <div class="py-3 has-text-centered">{{ formatRupiah(itemMaterial.total_price) }}</div>
                </td>

                <!-- ONLY FOR MOBILE -->
                <td class="is-hidden-tablet">
                  <div v-if="itemMaterial.is_booked" class="py-3 has-text-centered">
                    <img :src="require('@/assets/img/checklist-1.png')" alt="">
                  </div>
                </td>
                <td class="is-hidden-tablet">
                  <div v-if="itemMaterial.is_ordered" class="py-3 has-text-centered">
                    <img :src="require('@/assets/img/checklist-1.png')" alt="">
                  </div>
                </td>
                <td class="is-hidden-tablet">
                  <div v-if="itemMaterial.is_delivered" class="py-3 has-text-centered">
                    <img :src="require('@/assets/img/checklist-1.png')" alt="">
                  </div>
                </td>
                <td class="is-hidden-tablet py-2 border-radius-last-bottom">
                  <div class="wrap-destination is-flex is-justify-content-space-between thin-scroll-dekstop thin-scroll" :class="{'is-align-items-center': !itemMaterial.destination, 'is-align-items-start': itemMaterial.destination}">
                    <p class="averta-bold white-space-pre-line" v-if="itemMaterial.destination">{{ itemMaterial.destination }}</p>
                    <p class="text-merah white-space-nowrap" v-else><i>Belum diatur</i></p>
                  </div>
                </td>

              </tr>
            </table>
          </div>
          <!-- fixed table -->

          <!-- table not fixed -->
          <div class="table-not-fixed thin-scroll-dekstop pb-1 is-hidden-mobile">
            <table class="table is-narrow is-hoverable table-freeze label-14 averta-regular">
                <tr class="has-background-grey has-text-white has-text-centered averta-bold tr-head-wrapper">
                  <td style="width: 10%" class="border-radius-0">
                    <div>PEMESANAN</div>
                  </td>
                  <td style="width: 10%">
                    <div>PEMBELIAN</div>
                  </td>
                  <td style="width: 17%">
                    <div>TERKIRIM</div>
                  </td>
                  <td style="width: 70%">
                    <div>TUJUAN</div>
                  </td>
                </tr>
                <tr v-for="(itemMaterial, indexMaterial) in itemOrder.materials" :key="`index-material-${indexMaterial}`" class="tr-wrapper tr-not-fixed label-14" :class="{ 'bg-lightgray': (indexMaterial + 1) % 2 === 0 }">
                  <td class="border-radius-0">
                    <div v-if="itemMaterial.is_booked" class="py-3 has-text-centered">
                      <img :src="require('@/assets/img/checklist-1.png')" alt="">
                    </div>
                  </td>
                  <td>
                    <div v-if="itemMaterial.is_ordered" class="py-3 has-text-centered">
                      <img :src="require('@/assets/img/checklist-1.png')" alt="">
                    </div>
                  </td>
                  <td>
                    <div v-if="itemMaterial.is_delivered" class="py-3 has-text-centered">
                      <img :src="require('@/assets/img/checklist-1.png')" alt="">
                    </div>
                  </td>
                  <td>
                    <div class="wrap-destination is-flex is-justify-content-space-between thin-scroll-dekstop thin-scroll" :class="{'is-align-items-center': !itemMaterial.destination, 'is-align-items-start': itemMaterial.destination}">
                      <p class="averta-bold white-space-pre-line" v-if="itemMaterial.destination">{{ itemMaterial.destination }}</p>
                      <p class="text-merah white-space-nowrap" v-else><i>Belum diatur</i></p>
                    </div>
                  </td>
                </tr>
            </table>
          </div>
          <!-- table not fixed -->

        </div>
      </div>

      <ModalPreviewImage :isActive="previewImage" :urlOneImage="imagePreview" @closeModal="closePreviewImage()" />
    </div>
    <div v-else class="column has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Material Kosong
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { formatCurrency } from '@/helpers'
import { ProyekEmptyIcon } from '@/components/icons'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'

export default {
  name: 'TableMaterialOffered',
  props: ['dataOrdered'],
  components: {
    ProyekEmptyIcon,
    ModalPreviewImage
  },
  data () {
    return {
      previewImage: false,
      imagePreview: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    isBS () {
      if (this.user.roles[0].name === 'Account Manager' || this.user.roles[0].name === 'Business Support') {
        return true
      } return false
    }
  },
  methods: {
    formatRupiah (value, isOngkir) {
      if (value) return formatCurrency(value)
      else {
        if (isOngkir) {
          return 'Belum Ditentukan'
        } else {
          return '-'
        }
      }
    },
    formatDate (value) {
      if (value) {
        return moment(value).format('DD MMMM YYYY HH:mm')
      }
    },
    handlePreviewImage (url) {
      if (url) {
        this.previewImage = true
        this.imagePreview = url
      }
    },
    closePreviewImage () {
      this.imagePreview = null
      this.previewImage = false
    }
  }
}
</script>

<style scoped>
.title-ordered-wrapper {
  background: #F0F0F0;
  padding: 12px 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-left-fixed {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
  border-right: 5px solid #C0C0C0;
  height: calc(100% - 5px);
}

.table-not-fixed {
  position: sticky;
  overflow-x: auto;
}

.table td {
  vertical-align: middle;
  padding: 0px 15px;
  height: 6em;
}

.table-not-fixed .table .tr-not-fixed td {
  vertical-align: middle;
  padding: 10px 15px;
  height: 6em;
}

.wrap-destination {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
}

.wrapper-material-name {
  height: 5em;
  overflow: auto;
}

.material-name {
  font-size: 14px;
  text-align: left;
  margin-left: 10px;
  width: 85%;
}

.image-material {
    width: 30px;
}

.image-material img {
  object-fit: contain;
}

.border-radius-last-top {
  border-radius: 0 !important;
}

.border-radius-last-bottom {
  border-radius: 0 !important;
}

.adjust-width-table {
  min-width: 930px !important;
}

@media only screen and (max-width: 600px) {
  .table-left-fixed {
    border-right: none;
  }

  .border-radius-last-top {
    border-top-right-radius: 10px !important;
  }

  .border-radius-last-bottom {
    border-bottom-right-radius: 10px !important;
  }
}

</style>
