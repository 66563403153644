<template>
  <div class="mb-4 wrap-timeline">
    <p class="averta-bold mb-2">Timeline Proyek</p>
    <div v-if="dateWorking" class="is-flex is-align-items-center mb-4 d-sm-block">
      <p>Tanggal Pengerjaan : </p>
      <p class="averta-bold ml-1">{{formatDate()}}</p>
    </div>
    <div v-else class="bg-grey is-relative has-background-white-ter is-flex is-justify-content-space-between py-2 px-3 mb-4">
        <p>Durasi Pengerjaan</p>
        <p><span class="averta-bold">{{ duration || '-' }}</span> Hari</p>
    </div>
    <img v-if="!data" :src="require('@/assets/img/timeline-lock.jpg')" alt="" class="lock-img">
    <div v-else class="wrapper-table mb-6">
      <table class="has-text-centered">
        <tr>
          <td :colspan="duration + 1">Hari Kerja</td>
        </tr>
        <tr>
          <td></td>
          <td v-for="day, indexDay in duration" :key="`${day}-day`">
            <p>{{ day }}</p>
            <p v-if="projectDate && projectDate.length > 0" class="white-space-nowrap has-text-blue text-date averta-bold">{{ projectDate[indexDay].date }}</p>
          </td>
        </tr>
        <tr v-for="timeline, index in data" :key="`${index}-timeline`">
          <td :colspan="duration + 1" class="has-text-left wrapper-item-pekerjaan" v-if="timeline.note_item_working">
            <p class="text-grey">Item Pekerjaan</p>
            <p class="averta-bold">{{ timeline.note_item_working }}</p>
          </td>
          <td v-else>
            <div class="is-flex is-align-items-center wrapper-skills">
              <img :src="timeline.skill_image || require('@/assets/img/empty-img-2.png')" :alt="timeline.skill_name" class="skill-img" />
              <p>{{ timeline.skill_name }}</p>
            </div>
          </td>
          <template v-for="schedule, indexSchedule in timeline.schedule">
            <td :colspan="schedule.colspan" v-if="schedule.selected && schedule.selected !== 'Tersedia'" :key="`${indexSchedule}-selected`">
              <p class="schedule"
                :class="{ 'background-sesi': schedule.selected === 'Pagi' || schedule.selected === 'Sore' || schedule.selected === 'Malam', 'background-booking': schedule.selected === 'Booking', 'background-fullday': schedule.selected === 'Full Day' }">
                {{ schedule.selected }}
              </p>
            </td>
            <template v-else>
              <template v-if="schedule.colspan">
                <td v-for="item in schedule.colspan" :key="`${item}-colspan`"></td>
              </template>
              <td v-else :key="indexSchedule"></td>
            </template>
          </template>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'NewTimeline',
  props: ['duration', 'data', 'projectDate', 'dateWorking'],
  methods: {
    formatDate () {
      const startDate = moment(this.dateWorking.date_working || this.dateWorking.working_date)
      const endDate = moment(this.dateWorking.estimate_date_finish || this.dateWorking.estimate_finish_project)

      let resDate = '-'

      if (startDate.isValid()) {
        resDate = moment(startDate).format('DD MMMM YYYY')
      }

      if (endDate.isValid()) {
        if (resDate !== '-') {
          resDate += ' - '
        }
        resDate += moment(endDate).format('DD MMMM YYYY')
      }

      return resDate
    }
  }
}

</script>

<style scoped>

.wrap-timeline {
  width: 100%;
}
.lock-img {
  width: 100%;
  border-radius: 20px;
}

.wrapper-table {
  overflow-x: auto !important;
}

.wrapper-item-pekerjaan {
  background: #fafafa;
}

.wrapper-skills {
  width: 320px !important;
}

.skill-img {
  width: 34px;
  margin-right: 10px;
}

.schedule {
  background: #FAFAFA;
  padding: 12px 24px;
  border-radius: 24px;
  color: white;
}

.background-booking {
  background: #3185fc;
}

.background-sesi {
  background: #d9272d;
}

.background-fullday {
  background: #d96727;
}

.text-date {
  color: #3185FC;
}

</style>

<style>

table {
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

tr {
  border: 1px solid #C0C0C0;
}

td {
  border: 1px solid #C0C0C0;
  padding: 10px 30px;
}

tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

</style>
