<template>
  <div>
    <div class="columns mt-2">
      <div class="column">
        <b-button @click="tambahOnClick()" type="is-success" icon-left="plus">Tambah Sesi</b-button>
        <b-button @click="massDelete()" icon-left="delete" type="is-danger" style="margin-left: 5px;" :disabled="!hasSelected" />
      </div>
    </div>
    <div class="p-3">
      <b-table
        :total="paginate.total"
        :per-page="paginate.perPage"
        :data="paginate.data"
        :loading="paginate.loading"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        paginated
        backend-pagination
        scrollable>
        <b-table-column field="id" label="#" v-slot="props">
          <b-checkbox v-model="props.row.isSelect" />
        </b-table-column>
        <b-table-column field="sesi" label="Nama Sesi" v-slot="props">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column field="start_hour" label="Jam Mulai - Jam Selesai" v-slot="props">
          {{ props.row.start_hour }} - {{ props.row.end_hour }}
        </b-table-column>
        <b-table-column field="price" label="Harga" v-slot="props">
          Rp.{{ props.row.price | numFormat}}
        </b-table-column>
        <!-- <b-table-column field="overtime_price" label="Harga Lembur/Jam" v-slot="props">
          {{ props.row.overtime_price | numFormat}}
        </b-table-column> -->
        <b-table-column field="act" label="Action" v-slot="props">
          <b-button icon-left="pencil" @click="edit(props.row)" type="is-success" />
        </b-table-column>
      </b-table>
    </div>
    <!-- MODAL ADD SESI -->
    <b-modal
      v-model="newIsShow"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <form action="">
        <div class="modal-card">
          <header class="modal-card-head">
            <p v-if="rowSelectedId === null" class="modal-card-title">Tambah Sesi</p>
            <p v-if="rowSelectedId !== null" class="modal-card-title">Edit : {{ this.name }}</p>
            <button type="button" class="delete" @click="newIsShow = false"/>
          </header>
          <section class="modal-card-body">
            <div class="field is-horizontal">
              <div class="field-label">
                <label class="label">Nama Sesi</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input" type="text" v-model="name" placeholder="e.g. Sesi Pagi Tukang" required>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Jam Sesi</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="has-text-left">
                    <label class="radio" v-for="ss in sesiHour" :key="ss.sesi_id">
                      <input type="radio" :value="ss" v-model="sesiIds" required>
                      ({{ ss.name }})
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Harga</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input" v-model="price" type="text" placeholder="e.g. 2xx.xxxx" required>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button :loading="isLoading" @click="saveSesi()" label="Simpan" type="is-success"/>
          </footer>
        </div>
      </form>
    </b-modal>
    <!-- \MODAL ADD SESI -->
  </div>
</template>

<script>
// import project from '../../api/project'
import moment from 'moment'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'PengaturanSesi',
  data () {
    return {
      search: null,
      newIsShow: false,
      isLoading: false,
      name: null,
      sesiIds: null,
      startHour: null,
      endHour: null,
      price: null,
      sesiSelected: null,
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 5,
        data: []
      },
      rowSelectedId: null,
      sesiHour: [
        {
          sesi_id: '0',
          name: 'Full Day',
          startHour: '08:00:00',
          endHour: '17:00:00'
        },
        {
          sesi_id: '1',
          name: 'Half Day Pagi',
          startHour: '08:00:00',
          endHour: '12:00:00'
        },
        {
          sesi_id: '2',
          name: 'Half Day Sore',
          startHour: '13:00:00',
          endHour: '17:00:00'
        },
        {
          sesi_id: '3',
          name: 'Night Sesi',
          startHour: '22:00:00',
          endHour: '07:00:00'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    hasSelected () {
      return this.paginate.data.find((o) => o.isSelect === true)
    }
  },
  components: {},
  watch: {
    search (newVal) {
      if (newVal === null || newVal === '') {
        this.loadData(1)
      }
    }
  },
  methods: {
    massDelete () {
      this.$buefy.dialog.confirm({
        message: 'Hapus sesi terpilih ?',
        onConfirm: () => {
          const sesiId = []
          this.paginate.data.forEach((row) => {
            if (row.isSelect) {
              sesiId.push(row.id)
            }
          })
          axios.post(this.url + '/api/v1/vendor/sesi/bulk_delete', { sesi_id: sesiId })
            .then((cb) => {
              this.loadData(1)
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Hapus sesi berhasil!...',
                position: 'is-bottom',
                type: 'is-success'
              })
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Error hapus sesi',
                position: 'is-bottom',
                type: 'is-danger'
              })
            })
        }
      })
    },
    tambahOnClick () {
      this.newIsShow = true
      this.rowSelectedId = null
      this.clearInput()
    },
    clearInput () {
      this.sesiIds = null
      this.name = null
      this.startHour = null
      this.endHour = null
      this.price = null
    },
    saveSesi () {
      if (this.name === null || this.sesiIds === null || this.price === null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Data Tidak Boleh Kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      const data = {
        name: this.name,
        start_hour: this.sesiIds.startHour,
        end_hour: this.sesiIds.endHour,
        price: this.price,
        sesi_id: this.sesiIds.sesi_id
      }
      this.isLoading = true
      let url = ''
      if (this.rowSelectedId === null) {
        url = this.url + '/api/v1/vendor/sesi'
        axios.post(url, data)
          .then(() => {
            this.isLoading = false
            this.loadData(1)
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Save sesi berhasil!...',
              position: 'is-bottom',
              type: 'is-info'
            })
            this.clearInput()
            this.newIsShow = false
          })
          .catch(() => {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Error save',
              position: 'is-bottom',
              type: 'is-danger'
            })
            this.isLoading = false
          })
      } else {
        url = this.url + '/api/v1/vendor/sesi/' + this.rowSelectedId + '/update'
        axios.put(url, data)
          .then(() => {
            this.isLoading = false
            this.loadData(1)
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Update sesi berhasil!...',
              position: 'is-bottom',
              type: 'is-info'
            })
            this.clearInput()
            this.newIsShow = false
          })
          .catch(() => {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Error save',
              position: 'is-bottom',
              type: 'is-danger'
            })
            this.isLoading = false
          })
      }
    },
    searchOnClick () {},
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    onPageChange (v) {
      this.loadData(v)
    },
    edit (row) {
      this.rowSelectedId = row.id
      this.name = row.name
      this.sesiIds = row.sesi_id
      this.startHour = row.start_hour
      this.endHour = row.end_hour
      this.price = row.price
      this.newIsShow = true
    },
    loadData (page) {
      this.paginate.loading = true
      this.paginate.page = page
      const url = this.url + '/api/v1/vendor/sesi?page=' + this.paginate.page + '&limit=' + this.paginate.perPage
      axios.get(url)
        .then((data) => {
          const xdata = []
          data.data.data.forEach((row) => {
            const x = JSON.parse(JSON.stringify(row))
            x.isSelect = false
            xdata.push(x)
          })
          this.paginate.data = xdata
          this.paginate.total = (data.data.total_page * this.paginate.perPage)
          this.paginate.page = data.data.current_page
          this.paginate.loading = false
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  created () {
    this.loadData(1)
  }
}
</script>

<style lang="scss" scoped>
  .project-btn {
    color: green;
    cursor: pointer;
  }
</style>
