<template>
  <div class="card spinner-wrapper">
    <div class="loadingio-spinner-wedges-gdts0xt1jnu">
      <div class="ldio-v4qiwrnm3j9">
        <div>
          <div>
            <div></div>
          </div>
          <div><div></div></div>
          <div><div></div></div>
          <div><div></div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpinnerIcon'
}
</script>

<style>
@keyframes ldio-v4qiwrnm3j9 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-v4qiwrnm3j9 > div > div {
  transform-origin: 100px 100px;
  animation: ldio-v4qiwrnm3j9 3.0303030303030303s linear infinite;
  opacity: 0.8;
}
.ldio-v4qiwrnm3j9 > div > div > div {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 70px;
  height: 70px;
  border-radius: 70px 0 0 0;
  transform-origin: 100px 100px;
}
.ldio-v4qiwrnm3j9 > div div:nth-child(1) {
  animation-duration: 0.7575757575757576s;
}
.ldio-v4qiwrnm3j9 > div div:nth-child(1) > div {
  background: #d9272d;
  transform: rotate(0deg);
}
.ldio-v4qiwrnm3j9 > div div:nth-child(2) {
  animation-duration: 1.0101010101010102s;
}
.ldio-v4qiwrnm3j9 > div div:nth-child(2) > div {
  background: #eb4600;
  transform: rotate(0deg);
}
.ldio-v4qiwrnm3j9 > div div:nth-child(3) {
  animation-duration: 1.5151515151515151s;
}
.ldio-v4qiwrnm3j9 > div div:nth-child(3) > div {
  background: #3185fc;
  transform: rotate(0deg);
}
.ldio-v4qiwrnm3j9 > div div:nth-child(4) {
  animation-duration: 3.0303030303030303s;
}
.ldio-v4qiwrnm3j9 > div div:nth-child(4) > div {
  background: #d9272d;
  transform: rotate(0deg);
}
.loadingio-spinner-wedges-gdts0xt1jnu {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-v4qiwrnm3j9 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-v4qiwrnm3j9 div {
  box-sizing: content-box;
}
.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
