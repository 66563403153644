<template>
  <div>
    <div v-if="isLoading">
      <SpinnerIcon />
    </div>
    <div v-else >
      <StatusMaterial :dataProps="dataMaterials.card_material" />
      <div class="card card-wrapper-costum flex-1 p-4 mt-4">
        <p class="label-20 averta-bold mb-3">Rincian Material</p>
        <div class="p-4 detail-material-wrapper" v-if="dataMaterials.materials.length > 0">
          <div v-for="item in dataMaterials.materials" :key="item.id" class="material-wrapper mb-2 mb-sm-2 p-3 is-flex flex-sm-column is-justify-content-space-between">
            <div class="is-flex flex-1 flex-shrink-0 mb-sm-2">
              <div class="material-photo-wrapper mr-3">
                <img :src="item.product_image ? item.product_image : require('@/assets/img/empty-img-2.png')" alt="" @click="item.product_image ? openModalImage(item.product_image) : null" :class="{ 'is-clickable': item.product_image }" />
              </div>
              <div class="c-w-100">
                <p class="averta-regular mb-1 fs-12 color-gray">Material</p>
                <p class="averta-bold">{{ item.product_name }}</p>
              </div>
            </div>
            <div class="flex-1 flex-shrink-0">
              <p class="averta-regular mb-1 fs-12 color-gray">Qty Material Estimasi</p>
              <p class="averta-bold">{{ item.total_offer }}</p>
            </div>
            <div class="flex-1 flex-shrink-0">
              <p class="averta-regular mb-1 fs-12 color-gray">Qty Aktual</p>
              <p class="averta-bold">{{ item.total_required }}</p>
            </div>
            <div class="flex-1 flex-shrink-0">
              <p class="averta-regular mb-1 fs-12 color-gray">UOM (Satuan)</p>
              <p class="averta-bold">{{ item.unit_name }}</p>
            </div>
          </div>
        </div>
        <div class="has-text-centered detail-material-wrapper" v-else>
          <ProyekEmptyIcon />
          <p class="averta-bold pb-5">Material kosong</p>
        </div>

        <ModalPreviewImage :isActive="modalImage" :dataImage="dataPreviewImage" title="" @closeModal="closeModalImage()" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// Assets
import { ProyekEmptyIcon, SpinnerIcon } from '@/components/icons'

// Helpers
import { alertErrorMessage } from '@/helpers'

// Components
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import StatusMaterial from './StatusMaterial.vue'

export default {
  name: 'RincianMaterial',
  components: {
    // Icons
    ProyekEmptyIcon,
    SpinnerIcon,

    // Component
    ModalPreviewImage,
    StatusMaterial
  },
  props: {
    proyekId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      modalImage: false,
      dataMaterials: [],
      dataPreviewImage: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    openModalImage (data) {
      this.dataPreviewImage.push(data)
      this.modalImage = true
    },
    closeModalImage () {
      this.dataPreviewImage = []
      this.modalImage = false
    },
    getDataMaterials () {
      this.isLoading = true
      const payload = {
        url: this.url,
        proyek_id: this.proyekId,
        category: 'detail',
        subcategory: 'material'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then((response) => {
          this.dataMaterials = response.data.data
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    }
  },
  mounted () {
    if (this.proyekId) {
      this.getDataMaterials()
    }
  },
  watch: {
    proyekId () {
      this.getDataMaterials()
    }
  }
}
</script>

<style scoped>

.detail-material-wrapper {
  background: #fafafa;
  border-radius: 20px;
}

.material-wrapper {
  background: #fff;
  border-radius: 20px;
}

.material-photo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 57px;
}

.material-photo-wrapper img {
  width: 100%;
}

</style>
