<template>
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.33317 15.5C1.87484 15.5 1.48234 15.3369 1.15567 15.0108C0.829005 14.6847 0.66595 14.2922 0.666505 13.8333V2.16667C0.666505 1.70833 0.829839 1.31583 1.15651 0.989168C1.48317 0.662501 1.87539 0.499446 2.33317 0.500001H15.6665C16.1248 0.500001 16.5173 0.663335 16.844 0.990001C17.1707 1.31667 17.3337 1.70889 17.3332 2.16667V13.8333C17.3332 14.2917 17.1698 14.6842 16.8432 15.0108C16.5165 15.3375 16.1243 15.5006 15.6665 15.5H2.33317ZM3.16651 12.1667H7.33317V10.5H3.16651V12.1667ZM11.1248 10.5L15.2498 6.375L14.0623 5.1875L11.1248 8.14583L9.93734 6.95833L8.77067 8.14583L11.1248 10.5ZM3.16651 8.83333H7.33317V7.16667H3.16651V8.83333ZM3.16651 5.5H7.33317V3.83333H3.16651V5.5Z"
      fill="#868686"
    />
  </svg>
</template>

<script>
export default {
  name: 'ListCheckIcon'
}
</script>
