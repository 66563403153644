<template>
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66634 17.25C2.16217 17.25 1.73073 17.0706 1.37201 16.7119C1.01267 16.3526 0.833008 15.9208 0.833008 15.4167V2.58333C0.833008 2.07917 1.01267 1.64742 1.37201 1.28808C1.73073 0.929361 2.16217 0.75 2.66634 0.75H17.333C17.8372 0.75 18.2689 0.929361 18.6283 1.28808C18.987 1.64742 19.1663 2.07917 19.1663 2.58333V15.4167C19.1663 15.9208 18.987 16.3526 18.6283 16.7119C18.2689 17.0706 17.8372 17.25 17.333 17.25H2.66634ZM3.58301 13.5833H8.16634V11.75H3.58301V13.5833ZM12.3372 11.75L16.8747 7.2125L15.5684 5.90625L12.3372 9.16042L11.0309 7.85417L9.74759 9.16042L12.3372 11.75ZM3.58301 9.91667H8.16634V8.08333H3.58301V9.91667ZM3.58301 6.25H8.16634V4.41667H3.58301V6.25Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'ListIcon',
  props: ['color']
}
</script>
