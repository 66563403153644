<template>
    <b-modal v-model="isModalActive" :width="widthModal" :can-cancel="false" class="modal-preview" :class="{'overflow-visible': overflowVisible}">
        <div class="content-modal">
            <div class="modal-header">
                <slot name="headerModal"></slot>
            </div>
            <div class="modal-body">
                <slot name="bodyModal"></slot>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
  name: 'ModalTemplate',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '75vw'
    },
    overflowVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false,
      widthModal: this.width
    }
  },
  watch: {
    showModal (newValue) {
      this.isModalActive = newValue
    },
    width (newValue) {
      this.widthModal = newValue
    }
  }
}
</script>

<style scoped>
.modal-preview {
    background-color: rgba(0, 0, 0, 0.2);
}

.content-modal {
    width: 100%;
}

.modal-header {
    background: #911a1e;
    padding: 24px;
    color: white;
    border-radius: 12px 12px 0px 0px;
}

.modal-body {
    background: white;
    border-radius: 0px 0px 12px 12px;
}

@media screen and (max-width: 768px) {
    .content-modal {
        position: fixed;
        bottom: 0;
        left: 0;
    }

    .modal-body {
      padding-bottom: 30px;
    }
}

</style>

<style>
  .overflow-visible .modal-content {
    overflow: visible !important;
  }
</style>
