<template>
  <TableSkeleton v-if="isLoading" class="pb-6 mb-6" />
  <div v-else>
    <div  class="min-h-60vh">
      <div class="is-flex is-justify-content-space-between mb-5 mt-3">
        <div class="wrapper-search">
          <b-field>
            <b-input placeholder="Cari Nama/ID Material" type="search" icon="magnify" custom-class="input-search-1 label-14" icon-clickable @icon-click="getDataMaterials(1)" @keyup.native.enter="getDataMaterials(1)" v-model="search"> </b-input>
          </b-field>
        </div>
        <div>
          <button @click="handleModalMaterial" class="btn-tambah averta-bold is-clickable">+ Tambah Material</button>
        </div>
      </div>
      <div v-if="dataMaterials.length > 0">
        <table class="table is-bordered is-narrow is-hoverable is-fullwidth label-14">
          <tr class="has-background-grey has-text-white has-text-centered">
            <td class="py-4 averta-bold" style="width: 5%">NO</td>
            <td class="py-4 averta-bold" style="width: 20%">ID MATERIAL</td>
            <td class="py-4 averta-bold" style="width: 25%">NAMA MATERIAL</td>
            <td class="py-4 averta-bold" style="width: 20%">STATUS</td>
            <td class="py-4" style="width: 8%"></td>
          </tr>
          <tr v-for="(material, indexMaterial) in dataMaterials" :key="material.id" class="tr-wrapper" :class="{ 'bg-lightgray': (indexMaterial + 1) % 2 === 0 }">
            <td>
              <div class="py-3 has-text-centered">
                {{ currentPage === 1 ? indexMaterial + 1 : indexMaterial + 1 === 10 ? `${currentPage}0` : `${currentPage - 1}${indexMaterial + 1}` }}
              </div>
            </td>
            <td>
              <div class="py-3 is-flex is-justify-content-space-between">
                <p>#ID{{ material.id }}</p>
                <span class="cursor-pointer" @click="handleCopyText(material.id)">
                  <CopasIcon />
                </span>
              </div>
            </td>
            <td>
              <div class="py-3 is-flex">
                <div class="img-material ml-1">
                  <img
                    alt=""
                    :src="material.picture ? material.picture : require('@/assets/img/empty-img-2.png')"
                    @click="handlePreviewImage(material.picture)"
                    :class="{ 'is-clickable': material.picture }"
                  />
                </div>
                <p class="averta-bold ml-2">{{ material.name }}</p>
              </div>
            </td>
            <td>
              <div class="py-3 status-wrapper averta-bold">
                <p :class="{ 'status-active': material.is_active === 1, 'non-active': material.is_active === 0 }">{{ material.is_active === 1 ? 'Aktif' : 'Tidak Aktif' }}</p>
              </div>
            </td>
            <td>
              <div class="py-3 is-flex is-justify-content-space-evenly is-align-items-center">
                <span @click="handleEditItem(material.id, material.name, material.is_active === 1, material.picture)" class="cursor-pointer">
                  <img :src="require('@/assets/img/icon-edit.png')" alt="" />
                </span>
                <span @click="handleDeleteItem(material.id)" class="cursor-pointer">
                  <img :src="require('@/assets/img/icon-delete.png')" alt="" />
                </span>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="column has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2 label-18">
        Material Tidak Ditemukan
      </p>
    </div>
    </div>

    <ModalMaterials
      :nameProps="nameProps"
      :activeProps="activeProps"
      :previewPhotoProps="previewURL"
      :typeModal="typeModal"
      :showModal="showModal"
      :id="modalId"
      @closeModal="($event) => handleCloseModalMaterial($event)"
    />

    <ModalPreviewImage
      :isActive="previewImage"
      :urlOneImage="imagePreview"
      @closeModal="closePreviewImage()"
    />

    <PaginationApp
      v-if="dataMaterials.length > 0"
      :total-pages="totalPages"
      :current-page="currentPage"
      :load-page="getDataMaterials"
      class="my-6"
      :limit-data="10"
      :total-data="totalData"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CopasIcon, ProyekEmptyIcon } from '@/components/icons'
import TableSkeleton from '@/components/skeleton/TableSkeleton'
import PaginationApp from '@/components/PaginationApp.vue'
import ModalMaterials from '../modal/ModalMaterials.vue'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'

export default {
  name: 'ListMaterials',
  components: {
    CopasIcon,
    ProyekEmptyIcon,
    TableSkeleton,
    PaginationApp,
    ModalMaterials,
    ModalPreviewImage
  },
  data () {
    return {
      showModal: false,
      dataMaterials: [],
      isLoading: false,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      search: null,
      typeModal: 'create-material',
      modalId: null,
      nameProps: '',
      activeProps: false,
      previewURL: '',
      imagePreview: null,
      previewImage: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    handleModalMaterial () {
      this.typeModal = 'create-material'
      this.showModal = !this.showModal
    },
    handleCloseModalMaterial (event) {
      if (event === 'success') {
        this.getDataMaterials()
      }
      this.showModal = false
    },
    handleDeleteItem (id) {
      this.$swal({
        imageUrl: require('@/assets/img/delete-area-component.png'),
        imageAlt: 'image delete material',
        width: '550px',
        padding: '0px 10px 40px 10px',
        title: '<p class="averta-bold label-22" style="margin-bottom: -10px">Hapus Material?</p>',
        html: '<p>Pastikan kamu sudah cek kembali material ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          // Change payload
          const payload = {
            url: this.url,
            id
          }
          this.$store
            .dispatch('product/deleteDataProduct', payload)
            .then((response) => {
              this.getDataMaterials()
            })
            .catch((error) => {
              this.isLoading = false
              console.log(error)
              if (error.response.data.message) {
                this.$swal({
                  imageUrl: require('@/assets/img/sunset.png'),
                  imageAlt: 'image cannot delete',
                  width: '600px',
                  padding: '10px 10px 30px 10px',
                  title: '<p class="averta-bold label-22">Material Tidak Dapat Dihapus</p>',
                  html: `<p>${error.response.data.message || 'Material ini telah digunakan pada proyek dan penawaran'}</p>`,
                  confirmButtonText: 'Tutup',
                  customClass: {
                    confirmButton: 'btn-hapus-detail-component'
                  }
                })
              } else {
                this.$buefy.toast.open({
                  message: error.response.data.message || 'Material Tidak Dapat Dihapus',
                  type: 'is-danger'
                })
              }
            })
        }
      })
    },
    handleEditItem (id, name, active, previewURL) {
      this.modalId = id
      this.typeModal = 'update-material'
      this.nameProps = name
      this.activeProps = active
      this.previewURL = previewURL
      this.showModal = !this.showModal
    },
    async handleCopyText (text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$buefy.toast.open({
          message: '<p class="averta-bold">Tersalin!</p>',
          type: 'is-success'
        })
      } catch ($e) {
        this.$buefy.toast.open({
          message: 'Gagal, Silahkan coba lagi',
          type: 'is-success'
        })
      }
    },
    getDataMaterials (page) {
      this.isLoading = true
      const payload = {
        url: this.url,
        page,
        search: this.search
      }
      this.$store
        .dispatch('product/getListProducts', payload)
        .then(response => {
          this.dataMaterials = response.data.data
          this.totalPages = response.data.total_page
          this.totalData = response.data.total_data
          this.currentPage = response.data.current_page
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: error.response.data.message || 'Gagal memuat material, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    handlePreviewImage (url) {
      if (url) {
        this.previewImage = true
        this.imagePreview = url
      }
    },
    closePreviewImage () {
      this.imagePreview = null
      this.previewImage = false
    }
  },
  created () {
    this.getDataMaterials()
  },
  watch: {
    search: function (val) {
      if (val === '') {
        this.getDataMaterials()
      }
    }
  }
}
</script>

<style scoped>
.wrapper-search {
  width: 20em;
}

.btn-tambah {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  border: none;
  border-radius: 100px;
  color: white;
  padding: 12px 28px;
}

.status-active {
  color: white;
  background: #f1d900;
  border-radius: 100px;
  padding: 4px 0px;
  width: auto;
}

.non-active {
  width: auto;
  color: white;
  background: #868686;
  border-radius: 100px;
  padding: 4px 0px;
}

.text-red {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.area-name-wrapper {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tr-wrapper td {
  border: 1px solid #c0c0c0;
}

.status-wrapper {
  display: flex;
  justify-content: center;
}

.status-wrapper p {
  width: 10em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  text-align: center;
}

.img-material {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-material img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
