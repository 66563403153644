<template>
  <svg :width="size" :height="size" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.9354 2.58198C21.4352 2.0686 20.6949 1.87734 20.0046 2.07866L3.908 6.75952C3.1797 6.96186 2.66349 7.54269 2.52443 8.28055C2.38237 9.0315 2.87858 9.98479 3.52684 10.3834L8.5599 13.4768C9.07611 13.7939 9.74238 13.7144 10.1696 13.2835L15.9329 7.4843C16.223 7.18231 16.7032 7.18231 16.9934 7.4843C17.2835 7.77623 17.2835 8.24935 16.9934 8.55134L11.22 14.3516C10.7918 14.7814 10.7118 15.4508 11.0269 15.9702L14.1022 21.0538C14.4623 21.6577 15.0826 22 15.7628 22C15.8429 22 15.9329 22 16.013 21.9899C16.7933 21.8893 17.4135 21.3558 17.6436 20.6008L22.4156 4.52479C22.6257 3.84028 22.4356 3.09537 21.9354 2.58198Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'SendIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    size: {
      type: String,
      default: '25'
    }
  }
}
</script>
