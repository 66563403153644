<template>
  <div>
    <table
      v-if="dataUser.length > 0"
      class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
    >
      <tr class="bg-dark-grey-5 has-text-white has-text-centered">
        <td style="width: 5%" class="py-4 vertical-align-midle averta-bold"><p>NO</p></td>
        <td style="width: 20%" class="py-4 vertical-align-midle averta-bold"><p>NAMA</p></td>
        <td style="width: 15%" class="py-4 vertical-align-midle averta-bold"><p>NOMOR HP</p></td>
        <td style="width: 15%" class="py-4 vertical-align-midle averta-bold">
          <p>ALAMAT EMAIL</p>
        </td>
        <td style="width: 10%" class="py-4 vertical-align-midle averta-bold">
          <p>TANGGAL REGISTER</p>
        </td>
        <td style="width: 10%" class="py-4 vertical-align-midle averta-bold"><p>ASSIGN BY</p></td>
        <td style="width: 10%" class="py-4 vertical-align-midle averta-bold"><p>STATUS</p></td>
        <td style="width: 10%" class="py-4 vertical-align-midle"></td>
      </tr>
      <tr v-for="(item, index) in dataUser" :key="index" class="tr-wrapper">
        <td class="py-3 vertical-align-midle">
          <p class="has-text-centered">{{ index + 1 }}</p>
        </td>
        <td class="py-3 vertical-align-midle">
          <div class="is-flex is-align-items-center">
            <img
              v-if="item.image_url && item.image_url !== '-'"
              :src="item.image_url"
              class="image-pic img-shadow-1 mr-3"
            />
            <img
              v-else
              :src="require('@/assets/img/default-picture.jpg')"
              class="image-pic img-shadow-1 mr-3"
            />
            <p>{{ item.name }}</p>
          </div>
        </td>
        <td class="py-3 vertical-align-midle">
          <p class="has-text-danger averta-bold">{{ item.phone }}</p>
        </td>
        <td class="py-3 vertical-align-midle">
          <p>{{ item.email }}</p>
        </td>
        <td class="py-3 vertical-align-midle">
          <p class="has-text-centered">{{ formatDate(item.created_at) }}</p>
        </td>
        <td class="py-3 vertical-align-midle">
          <p class="has-text-centered">{{ item.created_by || '-' }}</p>
        </td>
        <td class="py-3 vertical-align-midle">
          <div
            class="badge-status-table flex-center"
            :class="`${item.is_active === 1 ? 'active' : 'nonactive'}`"
          >
            <p class="averta-bold color-white py-1">
              {{ item.is_active === 1 ? 'Aktif' : 'Tidak Aktif' }}
            </p>
          </div>
        </td>
        <td class="py-3 vertical-align-midle">
          <div class="flex-center">
            <div class="is-clickable mr-2" @click="handleOpenEdit(item)">
              <EditIcon color="#000" />
            </div>
            <!-- <div class="is-clickable" @click="handleDelete(item)">
              <TrashIcon />
            </div> -->
          </div>
        </td>
      </tr>
    </table>
    <div v-else class="column c-w-100 has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">Data Tidak Ditemukan</p>
    </div>

    <!-- Delete PIC -->
    <ModalConfirm
      :showModal="showModalDelete"
      imageProps="sunset.png"
      imageClass="mb-3"
      titleProps="Hapus Koordinator Engineering??"
      descProps="Pastikan kamu sudah lakukan pengecekkan sebelum menghapus data ini."
    >
      <template v-slot:button>
        <div class="is-flex">
          <ButtonApp
            @click="handleCancelDelete"
            class="mr-3"
            :isSecondary="true"
            width="185px"
            height="52px"
          >
            <p class="averta-bold px-4 py-1">Kembali</p>
          </ButtonApp>
          <ButtonApp @click="handleConfirmDelete" width="185px" height="52px">
            <p class="averta-bold px-4 py-1">Hapus</p>
          </ButtonApp>
        </div>
      </template>
    </ModalConfirm>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EditIcon, ProyekEmptyIcon, TrashIcon } from '@/components/icons'
import { formatDateHelper } from '@/helpers'
import ModalConfirm from '../ModalConfirm.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'TabListKoordinatorEngineering',
  props: {
    dataUser: {
      type: Array
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TrashIcon,
    EditIcon,
    ProyekEmptyIcon,
    ModalConfirm,
    ButtonApp
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      statusTemp: 'Aktif',
      showModalDelete: false,
      showModalEdit: false
    }
  },
  methods: {
    formatDate (date) {
      return formatDateHelper({ date })
    },
    handleDelete (data) {
      this.showModalDelete = true
      this.$store.commit('client/setDetailPICBranch', data)
    },
    handleCancelDelete () {
      this.showModalDelete = false
    },
    handleConfirmDelete (data) {
      this.$emit('handleDeletePIC', data)
    },
    handleOpenEdit (data) {
      this.$emit('handleEditPIC', data)
    }
  }
}
</script>
