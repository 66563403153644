<template>
  <div class="c-w-100">
    <div class="is-flex">
      <div>
        <b-skeleton height="75vh" width="400px" :animated="true"></b-skeleton>
      </div>
      <div class="c-w-100 ml-5">
        <div class="mb-5">
          <b-skeleton height="50px" :animated="true"></b-skeleton>
        </div>
        <div class="mb-5">
          <b-skeleton height="100px" :animated="true"></b-skeleton>
        </div>
        <b-skeleton height="46.5vh" :animated="true"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckinSkeleton',
  data () {
    return {
      firstBox: 3
    }
  }
}
</script>

<style scoped>

</style>
