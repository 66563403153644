<template>
    <b-modal :on-cancel="closeModal" v-model="isModalActive" :can-cancel="false">
      <div class="modal-template">
        <div class="modal-body w-100">
          <header class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold is-size-5">{{renderTitle}}</p>
            <div class="is-clickable" @click="closeModal">
              <b-icon icon="close"></b-icon>
            </div>
          </header>
          <div class="wrap-content mt-5">
            <div class="mx-5 mb-5">
              <label class="averta-bold is-size-6">Nama Area</label>
              <input v-model="name" class="input mt-2 mb-3" type="text" placeholder="Isi disini" />

              <div v-if="isTypeEdit" class="is-flex is-align-items-center">
                <p class="averta-bold">{{isSwitchActive ? 'Aktif' : 'Tidak Aktif'}}</p>
                <SwitchToggle :scale="0.7" :active="isSwitchActive" @isCheck="$event => handleIsSwitchActive($event)" />
              </div>

              <div class="mt-3">
                <p class="averta-bold">Masukan Area Komponen ke dalam Area</p>
                <p>Pilih area komponen yang termasuk ke dalam area ini!</p>

                <div ref="scrollArea" @scroll="handleScroll" class="mt-3 p-3 checkbox-area-component">
                  <div class="is-flex mb-3 is-justify-content-space-between is-align-items-center">
                    <div class="is-flex">
                      <p class="averta-bold">Pilih Area Komponen </p>
                      <p class="has-text-grey-light is-italic ml-1">(pilih minimal 1)</p>
                    </div>
                    <p class="averta-bold text-red cursor-pointer" @click="handleAddComponent">+ Tambah Area Komponen</p>
                  </div>
                  <b-input class="mb-3" placeholder="Cari Area Komponen" type="search" icon="magnify" @input="handleSearch">
                  </b-input>
                  <div v-if="!isLoading">
                    <div v-for="(itemComponent, indexComponent) in dataComponent" :key="indexComponent" class="is-flex is-align-items-center mb-2 mx-4">
                      <input type="checkbox" class="checkbox-cs" :checked="isChecked(itemComponent.id, itemComponent.checked)" @change="e => handleCheckComponent(e, indexComponent, itemComponent.id)">
                      <p class="ml-2">{{itemComponent.name}}</p>
                    </div>
                  </div>
                  <p class="averta-bold text-red cursor-pointer mt-4 ml-4" @click="handleAddComponent">+ Tambah Area Komponen</p>
                </div>
              </div>
            </div>
            <footer class="py-5 is-flex is-justify-content-flex-end footer-shadow wrap-footer">
              <b-button class="btn-disabled averta-bold px-5 is-flex is-justify-content-center mr-5" @click="isTypeEdit ? updateArea() : addArea()"
                :class="{ 'btn-active': isBtnActive }">
                <div class="is-flex is-align-items-center">
                  <CheckIcon class="mr-3" />
                  <span>Simpan</span>
                </div>
              </b-button>
            </footer>
          </div>
        </div>
      </div>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { CheckIcon } from '@/components/icons'
import SwitchToggle from '@/components/SwitchToggle.vue'

export default {
  name: 'ModalArea',
  components: {
    CheckIcon,
    SwitchToggle
  },
  props: {
    type: {
      type: String,
      default: 'create-area'
    },
    showModal: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number
    }
  },
  data () {
    return {
      name: '',
      isModalActive: false,
      isLoading: true,
      isInitial: true,
      isBtnActive: false,
      isSwitchActive: true,
      isSaveCheckbox: false,
      dataComponent: [],
      currentPage: 1,
      totalPage: null,
      checkboxComponents: [],
      keyword: ''
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    renderTitle () {
      if (this.type === 'create-area') {
        return 'Tambah Area'
      } else if (this.type === 'update-area') {
        return 'Edit Area'
      } else {
        return this.type
      }
    },
    isTypeEdit () {
      if (this.type === 'update-area') {
        return true
      }
      return false
    },
    isTypeDetail () {
      if (this.type === 'detail-area') {
        return true
      }
      return false
    }
  },
  methods: {
    handleAddComponent () {
      this.$emit('showModalComponent', true)
    },
    handleIsSwitchActive (e) {
      this.isSwitchActive = e
    },
    isChecked (id, checked) {
      const findObj = this.checkboxComponents.find(item => item.id === id)

      if (findObj) return true
      else if (checked) return true
      return false
    },
    clearState () {
      this.totalPage = null
      this.currentPage = 1
      this.isInitial = true
    },
    closeModal () {
      this.clearState()
      this.checkboxComponents = []
      this.$emit('closeModal', true)
    },
    addArea () {
      if (!this.isBtnActive) {
        return
      }
      const newArr = []
      for (let i = 0; i < this.checkboxComponents.length; i++) {
        newArr.push(this.checkboxComponents[i].id)
      }
      const payload = {
        url: this.url,
        name: this.name,
        component_id: newArr
      }

      this.clearState()
      this.$store
        .dispatch('settings/postDataArea', payload)
        .then(() => {
          this.$emit('closeModal', 'success-add-area')
          this.$buefy.toast.open({
            message:
              '<p class="averta-regular">Berhasil membuat area</p>',
            type: 'is-success'
          })
        })
        .catch(error => {
          console.log(error)
          this.$buefy.toast.open({
            message:
              '<p class="averta-regular">Gagal membuat area, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    },
    updateArea () {
      if (!this.isBtnActive) {
        return
      }

      this.$swal({
        imageUrl: require('@/assets/img/delete-area-component.png'),
        imageAlt: 'image update area component',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Simpan Area?</p>',
        html: '<p>Pastikan kamu sudah cek kembali area ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const newArr = []
          for (let i = 0; i < this.checkboxComponents.length; i++) {
            newArr.push(this.checkboxComponents[i].id)
          }
          const payload = {
            url: this.url,
            id: this.id,
            type: 'area',
            body: {
              name: this.name,
              component_id: newArr,
              is_active: this.isSwitchActive ? 1 : 0
            }
          }

          this.clearState()
          this.$store
            .dispatch('settings/updateData', payload)
            .then(() => {
              this.$emit('closeModal', 'success-add-area')
              this.$buefy.toast.open({
                message:
              '<p class="averta-regular">Data area berhasil diperbarui</p>',
                type: 'is-success'
              })
            })
            .catch(error => {
              console.log(error)
              this.$buefy.toast.open({
                message:
              '<p class="averta-regular">Gagal ubah area, silahkan coba lagi</p>',
                type: 'is-danger'
              })
            })
        }
      })
    },
    handleSearch (keyword) {
      if (this.type === 'create-area') {
        this.getComponents(keyword)
      } else {
        this.getDetailArea(keyword)
      }
    },
    getDetailArea (keyword, event) {
      // this.isLoading = true

      if (event === 'success-area-props') {
        this.dataComponent = []
        this.currentPage = 1
        this.isInitial = true
        this.isSaveCheckbox = true
      }
      this.keyword = keyword || ''
      if (this.keyword) {
        this.currentPage = 1
      }

      const payload = {
        url: this.url,
        id: this.id,
        type: 'area',
        page: this.currentPage,
        limit: 10,
        search: keyword
      }

      this.$store
        .dispatch('settings/detailData', payload)
        .then(response => {
          const res = response.data.data
          const arr = []
          res.components.map(item => {
            if (item.checked === 1) {
              arr.push(item)
            }
          })
          this.checkboxComponents = this.mergeObjectsArrayWithoutDuplicates(this.checkboxComponents, arr)

          if (keyword || keyword === '') {
            this.dataComponent = []
            this.dataComponent = res.components
            this.isLoading = false
            this.isInitial = true
            return
          } else if (this.isInitial) {
            this.dataComponent = res.components
            this.name = res.name
            this.totalPage = response.data.total_page
            this.isSwitchActive = res.is_active === 1

            this.isLoading = false
            return
          }

          this.currentPage++

          this.dataComponent = this.dataComponent.concat(res.components)
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message:
            '<p class="averta-regular">Gagal memuat detail area, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    },
    getComponents (keyword, event) {
      // this.isLoading = true

      if (event === 'success-area-props') {
        this.dataComponent = []
        this.currentPage = 1
        this.isInitial = true
        this.isSaveCheckbox = true
      }
      this.keyword = keyword || ''
      if (this.keyword) {
        this.currentPage = 1
      }

      const payload = {
        url: this.url,
        page: this.currentPage,
        search: keyword
      }
      this.$store
        .dispatch('settings/getDataAreaComponents', payload)
        .then(response => {
          const res = response.data.data
          const arr = []
          res.map(item => {
            if (item.checked === 1) {
              arr.push(item)
            }
          })
          this.checkboxComponents = this.mergeObjectsArrayWithoutDuplicates(this.checkboxComponents, arr)

          if (keyword || keyword === '') {
            this.dataComponent = []
            this.dataComponent = res
            this.isLoading = false
            this.isInitial = true
            return
          } else if (this.isInitial) {
            if (!event || event !== 'success-area-props') {
              this.name = ''
            }
            this.dataComponent = res
            this.totalPage = response.data.total_page
            this.isLoading = false
            return
          }

          this.currentPage++
          this.dataComponent = this.dataComponent.concat(res)
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message:
            '<p class="averta-regular">Gagal memuat area komponen, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    },
    handleCheckComponent (e, index, id) {
      if (e.target.checked) {
        // push component to checkboxComponents
        const component = this.dataComponent[index]
        this.checkboxComponents.push(component)
      } else {
        // remove when not checked
        const objWithIdIndex = this.checkboxComponents.findIndex((obj) => obj.id === id)
        this.checkboxComponents.splice(objWithIdIndex, 1)
      }
    },
    handleScroll () {
      const scrollArea = this.$refs.scrollArea
      const bottomOfScrollArea = scrollArea.scrollHeight - scrollArea.clientHeight
      if (scrollArea.scrollTop >= bottomOfScrollArea && !this.keyword) {
        if (this.currentPage <= this.totalPage) {
          if (this.isInitial) {
            this.currentPage++
          }
          this.isInitial = false

          if (!this.isLoading) {
            if (this.type === 'create-area') {
              this.getComponents()
            } else {
              this.getDetailArea()
            }
          }
        }
      }
    },
    mergeObjectsArrayWithoutDuplicates (arr1, arr2) {
      const mergedArray = arr1.concat(arr2)

      const uniqueArray = mergedArray.filter((obj, index, self) => {
        const foundIndex = self.findIndex(
          item => JSON.stringify(item) === JSON.stringify(obj)
        )
        return index === foundIndex
      })

      return uniqueArray
    }
  },
  watch: {
    showModal (newVal) {
      if (newVal) {
        if (this.type === 'create-area') {
          this.getComponents()
        } else {
          this.getDetailArea()
        }
      }
      this.isModalActive = newVal
    },
    checkboxComponents (newVal) {
      if (newVal.length > 0) {
        if (this.name && this.name !== '') {
          this.isBtnActive = true
        } else {
          this.isBtnActive = false
        }
      } else {
        this.isBtnActive = false
      }
    },
    name (newVal) {
      if (newVal && newVal !== '') {
        if (this.checkboxComponents.length < 1) {
          this.isBtnActive = false
        } else {
          this.isBtnActive = true
        }
      } else {
        this.isBtnActive = false
      }
    }
  }
}
</script>

<style scoped>
.modal-template {
  width: 40em;
  margin: auto;
}

.modal-body {
  overflow: hidden;
  background: white;
  border-radius: 10px;
  height: 100%;
}

.modal-template header {
  background-color: #911a1e;
  height: 5em;
  color: white;
  padding: 0 20px;
}

.wrap-content {
  height: 75vh;
  overflow-y: auto;
  position: relative;
}

.btn-active {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  color: white;
}

.btn-disabled {
  background-color: #9a9a9a;
  border-radius: 100px;
  color: white;
}

.checkbox-area-component {
  background: #FAFAFA;
  border-radius: 12px;
  height: 14em;
  overflow: auto;
}

.checkbox-cs {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-red {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.wrap-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-shadow {
  box-shadow: 0px -3px 30px 0px #00000012;
}
</style>
