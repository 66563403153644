<template>
  <TableSkeleton v-if="isLoading" class="mb-6" />
  <div v-else>
    <div class="min-height-60">
      <div class="is-flex is-justify-content-space-between mb-5 mt-3">
        <div class="wrapper-search">
          <b-field>
            <b-input placeholder="Cari Nama Area Komponen"
                type="search"
                icon="magnify"
                icon-clickable
                @icon-click="getFilterDataAreaComponents(1)"
                @keyup.native.enter="getFilterDataAreaComponents(1)"
                v-model="search"
                >
            </b-input>
          </b-field>
        </div>
        <div>
          <button @click="handleModalComponent" class="btn-tambah averta-bold is-clickable">
            + Tambah Area Komponen
          </button>
        </div>
      </div>
      <div>
        <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <tr class="bg-dark-grey-5 has-text-white has-text-centered averta-bold">
            <td class="py-4" style="width: 6%">NO</td>
            <td class="py-4" style="width: 20%">ID AREA KOMPONEN</td>
            <td class="py-4" style="width: 35%">NAMA AREA KOMPONEN</td>
            <td class="py-4" style="width: 20%">STATUS</td>
            <td class="py-4" style="width: 10%"></td>
          </tr>
          <tr v-for="(itemDetail, indexDetail) in dataComponent" :key="itemDetail.id" class="tr-wrapper" :class="{'bg-lightgray': (indexDetail + 1) % 2 === 0}">
            <td>
              <div class="py-3 has-text-centered">
                {{currentPage === 1 ? indexDetail + 1 : indexDetail + 1 === 10 ? `${currentPage}0` : `${currentPage - 1}${indexDetail+1}`}}
              </div>
            </td>
            <td>
              <div class="py-3 is-flex is-justify-content-space-between averta-bold text-merah">
                <p>#ID{{itemDetail.id}}</p>
                <span class="cursor-pointer" @click="handleCopyText(itemDetail.id)">
                  <CopasIcon />
                </span>
              </div>
            </td>
            <td>
              <div class="py-3">
                <p>{{itemDetail.name}}</p>
              </div>
            </td>
            <td>
              <div class="py-3 has-text-centered status-wrapper">
                <p :class="{'status-active' : itemDetail.is_active === 1, 'non-active' : itemDetail.is_active === 0}">{{itemDetail.is_active === 1 ? 'Aktif' : 'Tidak Aktif'}}</p>
              </div>
            </td>
            <td>
              <div class="py-3 is-flex is-justify-content-space-evenly is-align-items-center">
                <span @click="handleEditItem(itemDetail.id, itemDetail.name, itemDetail.is_active === 1)" class="cursor-pointer">
                  <img :src="require('@/assets/img/icon-edit.png')" alt="">
                </span>
                <span @click="handleDeleteItem(itemDetail.id)" class="cursor-pointer">
                  <img :src="require('@/assets/img/icon-delete.png')" alt="">
                </span>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <ModalComponent :nameProps="nameProps" :activeProps="activeProps" :type="typeModalAreaComponent" :showModal="showModal" :id="modalId" @closeModal="$event => handleCloseModal($event)" />
    <PaginationApp
      :total-pages="totalPages"
      :current-page="currentPage"
      :load-page="search !== null ? getFilterDataAreaComponents : getDataAreaComponents"
      class="my-6"
      :limit-data="10"
      :total-data="totalData"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CopasIcon } from '@/components/icons'
import ModalComponent from '../components/ModalComponent.vue'
import TableSkeleton from '@/components/skeleton/TableSkeleton'
import PaginationApp from '@/components/PaginationApp.vue'

export default {
  name: 'DataAreaComponent',
  components: {
    CopasIcon,
    ModalComponent,
    TableSkeleton,
    PaginationApp
  },
  data () {
    return {
      showModal: false,
      dataComponent: [],
      isLoading: false,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      search: null,
      typeModalAreaComponent: 'create-area-component',
      modalId: null,
      nameProps: '',
      activeProps: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    handleModalComponent () {
      this.typeModalAreaComponent = 'create-area-component'
      this.showModal = !this.showModal
    },
    handleCloseModal (event) {
      if (event === 'success-add-component') {
        this.getDataAreaComponents(1)
      } else if (event === 'success-update-component') {
        this.getDataAreaComponents(this.currentPage)
      }
      this.showModal = false
    },
    handleDeleteItem (id) {
      this.$swal({
        imageUrl: require('@/assets/img/delete-area-component.png'),
        imageAlt: 'image delete component',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Hapus Area Komponen?</p>',
        html: '<p>Pastikan kamu sudah cek kembali area ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            id,
            type: 'component'
          }
          this.$store
            .dispatch('settings/deleteData', payload)
            .then(response => {
              this.getDataAreaComponents()
            })
            .catch(error => {
              this.isLoading = false
              console.log(error)
              if (error.response.data.message) {
                this.$swal({
                  imageUrl: require('@/assets/img/sunset.png'),
                  imageAlt: 'image cannot delete',
                  width: '600px',
                  padding: '10px 10px 30px 10px',
                  title: '<p class="averta-bold label-22">Area Komponen Tidak Dapat Dihapus</p>',
                  html: `<p>${error.response.data.message}</p>`,
                  confirmButtonText: 'Tutup',
                  customClass: {
                    confirmButton: 'btn-hapus-detail-component'
                  }
                })
              } else {
                this.$buefy.toast.open({
                  message: error.response.data.message ||
              '<p class="averta-regular">Gagal menghapus area komponen, silahkan coba lagi</p>',
                  type: 'is-danger'
                })
              }
            })
        }
      })
    },
    handleEditItem (id, name, active) {
      this.modalId = id
      this.nameProps = name
      this.activeProps = active
      this.typeModalAreaComponent = 'update-area-component'
      this.showModal = !this.showModal
    },
    async handleCopyText (text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$buefy.toast.open({
          message:
            '<p class="averta-bold">Tersalin!</p>',
          type: 'is-success'
        })
      } catch ($e) {
        this.$buefy.toast.open({
          message:
            '<p class="averta-regular">Gagal, Silahkan coba lagi</p>',
          type: 'is-success'
        })
      }
    },
    getDataAreaComponents (page) {
      this.isLoading = true
      const payload = {
        url: this.url,
        page: page || 1
      }
      this.$store
        .dispatch('settings/getDataAreaComponents', payload)
        .then(response => {
          this.dataComponent = response.data.data
          this.totalPages = response.data.total_page
          this.totalData = response.data.total_data
          this.currentPage = response.data.current_page
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message:
            '<p class="averta-regular">Gagal memuat area komponen, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    },
    getFilterDataAreaComponents (pages) {
      const payload = {
        url: this.url,
        search: this.search,
        page: pages
      }

      this.$store
        .dispatch('settings/getDataAreaComponents', payload)
        .then(response => {
          this.dataComponent = response.data.data
          this.totalPages = response.data.total_page
          this.totalData = response.data.total_data
          this.currentPage = response.data.current_page
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    this.getDataAreaComponents()
  },
  watch: {
    search: function (val) {
      if (val === '') {
        this.getDataAreaComponents()
      }
    }
  }
}
</script>

<style scoped>
.wrapper-search {
  width: 20em;
}

.btn-tambah {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border: none;
  border-radius: 100px;
  color: white;
  padding: 12px 28px;
}

.min-height-60 {
  min-height: 60vh;
}

.cursor-pointer {
  cursor: pointer;
}

.status-active {
  color: white;
  background: #F1D900;
  border-radius: 100px;
  padding: 4px 0px;
}

.non-active {
  color: white;
  background: #868686;
  border-radius: 100px;
  padding: 4px 0px;
}

.tr-wrapper td {
  border: 1px solid #C0C0C0;
}

.status-wrapper {
  display: flex;
  justify-content: center;
}

.status-wrapper p {
  width: 10em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  text-align: center;
}

</style>
