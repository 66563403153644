<template>
  <svg :width="size" :height="size" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Arrow - Down 2">
      <path id="Stroke 1" d="M13.3332 1.08337L7.49984 6.91671L1.6665 1.08337" stroke="url(#paint0_linear_1665_12747)"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_1665_12747" x1="1.6665" y1="3.94701" x2="7.61287" y2="9.77925"
        gradientUnits="userSpaceOnUse">
        <stop :stop-color="color" />
        <stop offset="0.9999" :stop-color="color" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CollapseIcon',
  props: {
    color: {
      type: String,
      default: '#EB4600'
    },
    size: {
      type: String,
      default: '15'
    }
  }
}
</script>
