<template>
  <ModalTemplate :showModal="isModalActive" :width="widthModal" @closeModal="closeModal">
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <p class="averta-bold label-18">Request Reschedule Terbaru</p>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>
    <template v-slot:bodyModal>
      <div class="wrap-body-modal p-5">
        <!-- Filter -->
        <div class="wrap-title-and-filter">
          <div class="mb-4">
            <p class="averta-bold label-16">Request Reschedule Terbaru</p>
            <p class="text-grey label-14">{{ totalData }} Request</p>
          </div>
          <div class="filter is-flex is-justify-content-space-between is-align-items-center">
            <div class="is-flex">
              <b-dropdown aria-role="list" v-model="searchBy">
                <template #trigger="{ active }">
                  <b-button :icon-right="active ? 'menu-up' : 'menu-down'">
                    <div class="is-flex is-align-items-center pr-6 search-by-wrapper">
                      <p>{{ searchBy ? searchBy === 'store' ? 'Toko' : searchBy === 'survey' ? 'ID Survey' : 'PIC' :
                        'Pilih Filter' }}
                      </p>
                    </div>
                  </b-button>
                </template>
                <b-dropdown-item aria-role="listitem" value="store" @click="getReschedule">Toko</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" value="pic" @click="getReschedule">PIC</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" value="survey" @click="getReschedule">ID
                  Survey</b-dropdown-item>
              </b-dropdown>
              <b-field class="c-w-sm-100">
                <b-input placeholder="Cari Berdasarkan Nama" type="search" icon="magnify" v-model="search"
                  @keyup.native.enter="getReschedule">
                </b-input>
              </b-field>
            </div>
            <div class="is-flex is-align-items-center vendor-wrapper">
              <p class="mr-3 white-space-nowrap">Client :</p>
              <InputAutocomplete header="Pilih Client" :isVendor="true" :isNew="true" :haveImg="true" imgKey="logo"
                :onSelectItem="getReschedule" :onClearSearch="getReschedule" />
            </div>
          </div>
        </div>
        <!-- Filter -->

        <div class="loading-wrapper" v-if="isLoading">
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
            <SpinnerIcon />
          </b-loading>
        </div>
        <!-- List -->
        <div v-else-if="!isLoading && dataReschedules.length > 0">
          <div class="wrap-list mb-5">
            <div class="wrap-item mb-3" v-for="dataReschedule, indexReschedule in dataReschedules"
              :key="`${indexReschedule}-reschedule`">
              <div class="is-flex is-justify-content-space-between mb-3 text-grey">
                <p>Request Reschedule</p>
                <p>{{ convertDate(dataReschedule.created_at) }}</p>
              </div>
              <div class="card has-background-light py-3 px-4 is-flex is-justify-content-space-between is-align-items-center">
                <div class="is-flex is-justify-content-space-between">
                  <div class="border-right pr-3">
                    <p class="text-grey mb-0">ID</p>
                    <div class="is-flex is-align-items-center">
                      <p class="mr-2 averta-bold text-merah">#{{ dataReschedule.id }}</p>
                      <div @click="handleCopy(dataReschedule.id)" class="is-clickable">
                        <CopasIcon />
                      </div>
                    </div>
                  </div>
                  <div class="border-right is-flex is-align-items-center px-3">
                    <img :src=dataReschedule.vendor_logo alt="logo" width="80" class="mr-4">
                    <p class="averta-black">{{ dataReschedule.vendor_name }}</p>
                  </div>
                  <div class="border-right px-3">
                    <p>Toko</p>
                    <p class="averta-bold">{{ dataReschedule.store_name }}</p>
                  </div>
                  <div class="px-3">
                    <p>Tanggal Survey</p>
                    <p class="averta-bold">{{ convertDate(dataReschedule.original_date, 'DD MMM YYYY') }} &#8594;
                      {{ convertDate(dataReschedule.new_date, 'DD MMM YYYY') }}</p>
                  </div>
                </div>
                <div @click="handleDetail(dataReschedule.id)">
                  <p class="text-merah averta-bold is-clickable">Lihat Detail</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Pagination -->
          <PaginationApp :total-pages="totalPages" :current-page="currentPage" :isNew="true" :load-page="getReschedule"
            :limit-data="limitPage" :total-data="totalData" />
          <!-- Pagination -->
        </div>
        <!-- List -->

        <!-- Data not Found -->
        <div v-else class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <ProyekEmptyIcon />
          <p class="averta-bold">Tidak ada request reschedule</p>
        </div>
        <!-- Data not Found -->
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import ModalTemplate from './ModalTemplate.vue'
import PaginationApp from '@/components/PaginationApp.vue'
import InputAutocomplete from '@/components/input/InputAutocomplete.vue'
import { CopasIcon, ProyekEmptyIcon, SpinnerIcon } from '@/components/icons'
import { handleCopyText } from '@/helpers'

export default {
  name: 'ModalRescheduleSurvey',
  components: { ModalTemplate, InputAutocomplete, CopasIcon, PaginationApp, ProyekEmptyIcon, SpinnerIcon },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    })
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '75vw'
    },
    isDashboard: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      isModalActive: false,
      widthModal: this.width,
      search: '',
      searchBy: '',
      vendor: null,
      dataReschedules: [],
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      limitPage: 5
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    convertDate (date, format) {
      if (!date) {
        return '-'
      }
      let initFormat = 'DD MMMM YYYY'
      if (format) {
        initFormat = format
      }
      return moment(date).format(initFormat)
    },
    getReschedule (props = {}) {
      const { pages = 1, dataVendor = null, clearVendor = false } = props

      if (clearVendor) {
        this.vendor = null
      }

      this.isLoading = true

      const payload = {
        url: this.url,
        search: this.search,
        search_by: this.searchBy,
        page: pages,
        limit: this.limitPage
      }

      if (dataVendor) {
        payload.vendor = dataVendor.name
        this.vendor = dataVendor.name
      } else if (this.vendor !== null) {
        payload.vendor = this.vendor
      }

      if (this.isDashboard) {
        this.$store
          .dispatch('dashboard/getNewsActivity', payload)
          .then(response => {
            this.dataReschedules = response.data.data
            this.currentPage = response.data.current_page
            this.totalPages = response.data.total_page
            this.totalData = response.data.total_data
            this.$emit('totalReschedule', response.data.total_data)
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
          })
      } else {
        this.$store
          .dispatch('project/getDataReschedule', payload)
          .then(response => {
            this.dataReschedules = response.data.data.list
            this.currentPage = response.data.current_page
            this.totalPages = response.data.total_page
            this.totalData = response.data.total_data
            this.$emit('totalReschedule', response.data.total_data)
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
          })
      }
    },
    handleCopy (text) {
      handleCopyText(text)
    },
    handleDetail (id) {
      let role
      if (this.user.roles[0].name === 'Account Manager') {
        role = 'am'
      } else {
        role = 'sm'
      }

      this.$router.push({ path: `/${role}/project/survey/${id}/detail` })
    }
  },
  created () {
    this.getReschedule()
  },
  watch: {
    showModal (newValue) {
      this.isModalActive = newValue
    },
    width (newValue) {
      this.widthModal = newValue
    },
    search (newValue) {
      if (newValue === '') {
        this.getReschedule()
      }
    }
  }
}
</script>

<style scoped>
.border-right {
  border-right: solid 1px #9A9A9A;
}

.wrap-title-and-filter {
  border-bottom: dashed 2px #E5E5E5;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.wrap-body-modal {
  height: 75vh;
  overflow-y: auto;
}

.wrap-list {
  min-height: 35vh;
}

.loading-wrapper {
  height: 60%;
  width: 100%;
  position: relative;
}
</style>
