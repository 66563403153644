<template>
    <div></div>
  </template>

<script>
import { useShepherd } from 'vue-shepherd'

export default {
  name: 'GuideApp',
  props: {
    steps: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tour: null
    }
  },

  methods: {
    createTour () {
      this.tour = useShepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: 'wrap-tour-guide',
          scrollTo: { behavior: 'smooth', block: 'center' }
        }
      })

      this.steps.forEach((step) => {
        this.tour.addStep(step)
      })

      this.tour.start()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.createTour()
    })
  }
}
</script>
