<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 0H7C7.55 0 8 0.45 8 1V7C8 7.55 7.55 8 7 8H1C0.45 8 0 7.55 0 7V1C0 0.45 0.45 0 1 0ZM11 0H17C17.55 0 18 0.45 18 1V7C18 7.55 17.55 8 17 8H11C10.45 8 10 7.55 10 7V1C10 0.45 10.45 0 11 0ZM1 10H7C7.55 10 8 10.45 8 11V17C8 17.55 7.55 18 7 18H1C0.45 18 0 17.55 0 17V11C0 10.45 0.45 10 1 10ZM14 10C13.45 10 13 10.45 13 11V13H11C10.45 13 10 13.45 10 14C10 14.55 10.45 15 11 15H13V17C13 17.55 13.45 18 14 18C14.55 18 15 17.55 15 17V15H17C17.55 15 18 14.55 18 14C18 13.45 17.55 13 17 13H15V11C15 10.45 14.55 10 14 10Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DashboardIcon'
}
</script>
