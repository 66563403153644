<template>
  <svg :width="size" :height="size" viewBox="0 0 24 25" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_12064_60430"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="4"
      width="20"
      height="17"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.9991 6.5H18.9991C19.5491 6.s5 19.9991 6.05 19.9991 5.5C19.9991 4.95 19.5491 4.5 18.9991 4.5H4.9991C4.4491 4.5 3.9991 4.95 3.9991 5.5C3.9991 6.05 4.4491 6.5 4.9991 6.5ZM20.1591 8.3C20.0691 7.84 19.6591 7.5 19.1791 7.5H4.8191C4.3391 7.5 3.9291 7.84 3.8391 8.3L2.8391 13.3C2.7191 13.92 3.1891 14.5 3.8191 14.5H3.9991V19.5C3.9991 20.05 4.4491 20.5 4.9991 20.5H12.9991C13.5491 20.5 13.9991 20.05 13.9991 19.5V14.5H17.9991V19.5C17.9991 20.05 18.4491 20.5 18.9991 20.5C19.5491 20.5 19.9991 20.05 19.9991 19.5V14.5H20.1791C20.8091 14.5 21.2791 13.92 21.1591 13.3L20.1591 8.3ZM5.9991 18.5H11.9991V14.5H5.9991V18.5Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_12064_60430)">
      <rect y="0.5" width="24" height="24" :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OutletIcon',
  props: {
    color: {
      type: String,
      default: '#D9272D'
    },
    size: {
      type: String,
      default: '20'
    }
  }
}
</script>
