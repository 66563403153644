<template>
  <svg
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66667 10.5H1.33333C1.09722 10.5 0.899445 10.42 0.74 10.26C0.58 10.1006 0.5 9.90278 0.5 9.66667C0.5 9.43056 0.58 9.23278 0.74 9.07333C0.899445 8.91333 1.09722 8.83333 1.33333 8.83333H4.66667C4.90278 8.83333 5.10083 8.91333 5.26083 9.07333C5.42028 9.23278 5.5 9.43056 5.5 9.66667C5.5 9.90278 5.42028 10.1006 5.26083 10.26C5.10083 10.42 4.90278 10.5 4.66667 10.5ZM14.6667 2.16667H1.33333C1.09722 2.16667 0.899445 2.08694 0.74 1.9275C0.58 1.7675 0.5 1.56944 0.5 1.33333C0.5 1.09722 0.58 0.899167 0.74 0.739167C0.899445 0.579722 1.09722 0.5 1.33333 0.5H14.6667C14.9028 0.5 15.1006 0.579722 15.26 0.739167C15.42 0.899167 15.5 1.09722 15.5 1.33333C15.5 1.56944 15.42 1.7675 15.26 1.9275C15.1006 2.08694 14.9028 2.16667 14.6667 2.16667ZM9.66667 6.33333H1.33333C1.09722 6.33333 0.899445 6.25333 0.74 6.09333C0.58 5.93389 0.5 5.73611 0.5 5.5C0.5 5.26389 0.58 5.06583 0.74 4.90583C0.899445 4.74639 1.09722 4.66667 1.33333 4.66667H9.66667C9.90278 4.66667 10.1008 4.74639 10.2608 4.90583C10.4203 5.06583 10.5 5.26389 10.5 5.5C10.5 5.73611 10.4203 5.93389 10.2608 6.09333C10.1008 6.25333 9.90278 6.33333 9.66667 6.33333Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'AscDscIcon'
}
</script>
