<template>
    <ModalTemplate :showModal="isModalActive" :width="widthModal" @closeModal="closeModal">
        <template v-slot:headerModal>
            <div class="is-flex is-justify-content-space-between is-align-items-center">
                <div class="is-flex is-justify-content-space-between is-align-items-center">
                    <CheckIcon class="mr-2" />
                    <p class="averta-bold label-18">Atur Tanggal Penagihan</p>
                </div>
                <div class="is-clickable" @click="closeModal">
                  <b-icon icon="close"></b-icon>
                </div>
            </div>
        </template>
        <template v-slot:bodyModal>
            <div class="wrap-body-modal">
              <div class="py-4 px-5">
                <p class="mb-3">Tanggal <span class="text-merah">*</span></p>
                <v-date-picker v-model="dataPenagihan.tanggalPenagihan" />
              </div>
              <div class="form-button is-flex is-justify-content-end is-align-items-center">
                  <b-button @click="saveDate">Simpan Tanggal Penagihan</b-button>
              </div>
            </div>
        </template>
    </ModalTemplate>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import ModalTemplate from './ModalTemplate.vue'
import { CheckIcon } from '@/components/icons'
import saveImage from '@/assets/img/delete-area-component.png'

export default {
  name: 'ModalTanggalPenagihan',
  components: { ModalTemplate, CheckIcon },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '75vw'
    },
    data: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      isModalActive: false,
      widthModal: this.width,
      isLoading: false,
      modeEdit: false,
      dataPenagihan: {
        tanggalPenagihan: null,
        projectId: null
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    saveDate () {
      const payload = {
        url: this.url,
        date: moment(this.dataPenagihan.tanggalPenagihan).format('YYYY-MM-DD'),
        project_id: this.dataPenagihan.projectId
      }

      if (this.dataPenagihan.tanggalPenagihan !== null) {
        this.$swal({
          imageUrl: saveImage,
          imageAlt: 'save data',
          width: '540px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Simpan Tanggal Penagihan</p>',
          html: '<p>Pastikan seluruh informasi telah benar dan sesuai</p>',
          showCancelButton: true,
          confirmButtonText: 'Simpan',
          cancelButtonText: 'Batalkan',
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch('project/updateTanggalPenagihan', payload)
              .then(response => {
                this.$buefy.toast.open({
                  message: '<p class="averta-regular">Berhasil simpan data</p>',
                  type: 'is-success'
                })
                this.$emit('reloadList')
              })
              .catch(error => {
                this.isLoading = false
                console.log(error)
                this.$buefy.toast.open({
                  message: '<p class="averta-regular">Gagal simpan data, silahkan coba lagi</p>',
                  type: 'is-danger'
                })
                this.$emit('reloadList')
              })
          }
        })
      }
    }
  },
  watch: {
    showModal (newValue) {
      this.isModalActive = newValue
    },
    width (newValue) {
      this.widthModal = newValue
    },
    data (newValue) {
      this.dataPenagihan = newValue
    }
  }
}
</script>

<style scoped>

.wrap-body-modal {
  height: 410px;
  position: relative;
  overflow-y: auto;
}

.form-button {
    padding: 12px;
    width: 100%;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
}

.form-button button {
    background: var(--Gradient-Red-Red-Button-Solid, linear-gradient(116deg, #D9272D 16.15%, #EB4600 83.25%));
    border-radius: 100px;
    color: white;
    font-family: 'Averta-Bold';
    padding: 24px;
}

@media screen and (max-width: 768px) {
  .wrap-body-modal {
    height: 60vh;
  }
}

</style>
