<template>
  <div class="bg-lightgray p-2 flex-center border-radius-20 flex-1 c-w-100">
    <div class="duration-wrapper">
      <p class="color-grey">{{ titleFrom }}</p>
      <p class="averta-bold">{{ from }}</p>
    </div>
    <div class="flex-center">
      <ArrowRightIconLong color="black" />
    </div>
    <div class="duration-wrapper">
      <p class="color-grey">{{ titleEnd }}</p>
      <p class="averta-bold">{{ end }}</p>
    </div>
  </div>
</template>

<script>
import { ArrowRightIconLong } from '@/components/icons'
export default {
  name: 'DurationStartEnd',
  props: {
    from: {
      type: String
    },
    end: {
      type: String
    },
    titleFrom: {
      type: String
    },
    titleEnd: {
      type: String
    }
  },
  components: {
    ArrowRightIconLong
  }
}
</script>

<style scoped>
.duration-wrapper {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
