<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 11.5V14.5C3.5 18.271 3.5 20.157 4.672 21.328C5.843 22.5 7.729 22.5 11.5 22.5H12.5C16.271 22.5 18.157 22.5 19.328 21.328C20.5 20.157 20.5 18.271 20.5 14.5V11.5"
      :stroke="color ? color : 'white'" stroke-width="1.5" />
    <path
      d="M9.50001 2.5H14.5L15.152 9.017C15.195 9.45677 15.1454 9.90065 15.0066 10.3201C14.8677 10.7396 14.6426 11.1254 14.3457 11.4526C14.0489 11.7799 13.6868 12.0415 13.2828 12.2204C12.8788 12.3994 12.4419 12.4919 12 12.4919C11.5582 12.4919 11.1212 12.3994 10.7172 12.2204C10.3132 12.0415 9.95114 11.7799 9.65428 11.4526C9.35741 11.1254 9.13231 10.7396 8.99346 10.3201C8.8546 9.90065 8.80505 9.45677 8.84801 9.017L9.50001 2.5Z"
      :stroke="color ? color : 'white'" stroke-width="1.5" />
    <path
      d="M3.33002 5.851C3.50802 4.961 3.59702 4.516 3.77802 4.155C3.96662 3.77904 4.23217 3.44694 4.55743 3.18025C4.88269 2.91356 5.26039 2.71826 5.66602 2.607C6.05602 2.5 6.51002 2.5 7.41802 2.5H9.50002L8.77502 9.745C8.73676 10.1596 8.61428 10.5621 8.41508 10.9277C8.21588 11.2934 7.94414 11.6145 7.61651 11.8715C7.28888 12.1285 6.91225 12.3159 6.50968 12.4223C6.1071 12.5287 5.68706 12.5518 5.27525 12.4901C4.86344 12.4285 4.46853 12.2835 4.11471 12.064C3.76089 11.8445 3.45559 11.5551 3.21751 11.2135C2.97943 10.8718 2.81357 10.4852 2.73009 10.0773C2.64662 9.66936 2.64727 9.24867 2.73202 8.841L3.33002 5.851ZM20.67 5.851C20.492 4.961 20.403 4.516 20.222 4.155C20.0334 3.77904 19.7679 3.44694 19.4426 3.18025C19.1174 2.91356 18.7397 2.71826 18.334 2.607C17.944 2.5 17.49 2.5 16.582 2.5H14.5L15.225 9.745C15.2633 10.1596 15.3858 10.5621 15.585 10.9277C15.7842 11.2934 16.0559 11.6145 16.3835 11.8715C16.7112 12.1285 17.0878 12.3159 17.4904 12.4223C17.8929 12.5287 18.313 12.5518 18.7248 12.4901C19.1366 12.4285 19.5315 12.2835 19.8853 12.064C20.2392 11.8445 20.5445 11.5551 20.7825 11.2135C21.0206 10.8718 21.1865 10.4852 21.27 10.0773C21.3534 9.66936 21.3528 9.24867 21.268 8.841L20.67 5.851Z"
      :stroke="color ? color : 'white'" stroke-width="1.5" />
    <path
      d="M9.5 22V19C9.5 18.065 9.5 17.598 9.701 17.25C9.83265 17.022 10.022 16.8326 10.25 16.701C10.598 16.5 11.065 16.5 12 16.5C12.935 16.5 13.402 16.5 13.75 16.701C13.978 16.8326 14.1674 17.022 14.299 17.25C14.5 17.598 14.5 18.065 14.5 19V22"
      :stroke="color ? color : 'white'" stroke-width="1.5" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: 'StoreIconLinear',
  props: ['color']
}
</script>
