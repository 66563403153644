<template>
    <div class="container is-fluid pt-3">
        <div class="is-flex is-align-items-center is-justify-content-space-between px-3 pt-5 mb-5">
            <title-bar :title-stack="titleStack" />
        </div>
        <b-tabs>
            <b-tab-item v-for="item in listMenu" :key="item.id">
                <template #header>
                    <b-icon icon="information"></b-icon>
                    <span>{{item.title}}</span>
                </template>
                <DataArea v-if="item.id === 1" />
                <DataAreaComponent v-if="item.id === 2" />
                <ListDetailComponent v-if="item.id === 3" />
                <JamSurveyClient v-if="item.id === 4" />
                <DataKota v-if="item.id === 5" />
                <DataUser v-if="item.id === 6" />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import DataArea from './views/DataArea.vue'
import DataAreaComponent from './views/DataAreaComponent.vue'
import ListDetailComponent from './views/DataDetailComponent.vue'
import JamSurveyClient from './views/JamSurveyClient.vue'
import DataKota from './views/DataKota.vue'
import DataUser from './views/DataUser.vue'

export default {
  name: 'DataComponents',
  components: {
    TitleBar,
    DataArea,
    DataAreaComponent,
    ListDetailComponent,
    JamSurveyClient,
    DataKota,
    DataUser
  },
  data () {
    return {
      listMenu: [
        {
          id: 1,
          title: 'Daftar Area'
        },
        {
          id: 2,
          title: 'Daftar Area Komponen'
        },
        {
          id: 3,
          title: 'Daftar Detail Komponen'
        },
        {
          id: 4,
          title: 'Jam Survey Client'
        },
        {
          id: 5,
          title: 'Kota & Jumlah Kunjungan'
        },
        {
          id: 6,
          title: 'Daftar User'
        }
      ]
    }
  },
  computed: {
    titleStack () {
      return ['Pengaturan']
    }
  }
}
</script>

<style scoped>

</style>
