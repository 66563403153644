<template>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="9" height="9" rx="4.5" fill="white" />
    <rect x="0.5" y="0.5" width="9" height="9" rx="4.5" :stroke="color" />
  </svg>
</template>

<script>
export default {
  name: 'CircleEmpty',
  props: {
    color: {
      type: String,
      default: '#9A9A9A'
    }
  }
}
</script>
