<template>
  <div>
    <h1>Table Unit Price</h1>
  </div>
</template>

<script>
export default {
  name: 'TableUnitPrice'
}
</script>

<style scoped>
</style>
