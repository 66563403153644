<template>
  <SidebarClientTemplate
    :show="showSidebar"
    :disabledSubmit="disabledSubmit"
    @closeSidebar="showModalConfirm"
    @submit="handleSubmit"
  >
    <template v-slot:bodySidebar>
      <b-loading
        :is-full-page="true"
        v-model="isLoadingSubmit"
        :can-cancel="false"
      >
        <SpinnerIcon />
      </b-loading>
      <div>
        <p class="averta-black label-20 mb-4">Silakan pilih sebelum isi form</p>
        <!-- Tab Tambah Cabang dan Tambah PIC Cabang -->
        <div
          class="is-flex is-justify-content-space-between is-align-items-center mb-4"
        >
          <button
            class="button-tab averta-bold label-16"
            :class="{ active: currentTab === 'branch' }"
            @click="changeTab('branch')"
          >
            Tambah {{renderTitleBranch}}
          </button>
          <button
            class="button-tab averta-bold label-16"
            :class="{ active: currentTab === 'pic' }"
            @click="changeTab('pic')"
          >
            Tambah {{renderTitlePICBranch}}
          </button>
        </div>

        <!-- Tab Tambah Cabang -->
        <div v-if="currentTab === 'branch'">
          <Branch />
        </div>

        <!-- Tab Tambah PIC Cabang -->
        <div v-if="currentTab === 'pic'">
          <PIC />
        </div>

        <!-- Tab Tambah PIC Cabang -->
        <!-- <div v-if="currentTab === 'admin' && isSMRC">
          <Admin />
        </div> -->
      </div>

      <!-- Modal Close Confirm -->
      <ModalConfirm
        :showModal="showModal"
        imageProps="sunset.png"
        :titleProps="titleProps"
        :descProps="descProps"
        imageClass="mb-3"
      >
        <template v-slot:button>
          <div class="is-flex is-align-items-center mt-4">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="cancelClose"
            >
              {{ moveTab ? "Batalkan" : "Cek Ulang" }}
            </ButtonApp>
            <ButtonApp
              :is-bold="true"
              width="185px"
              height="52px"
              @click="confirmModal"
            >
              {{ moveTab ? "Pindah Halaman" : "Batalkan" }}
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>

      <!-- Modal Submit Form PIC -->
      <ModalConfirm
        :showModal="showModalSubmit"
        imageProps="data-not-found.png"
        imageClass="mb-3"
        :titleProps="MODAL_TITLE_SUBMIT_FORM"
        :descProps="MODAL_DESC_SUBMIT_FORM"
      >
        <template v-if="MODAL_ADDITIONAL_SECTION_FORM" v-slot:additional>
          <ul class="wrap-desc mb-6">
            <li class="has-text-left">
              <p class="is-inline averta-bold label-14">
                {{ MODAL_ADDITIONAL_SECTION_FORM }}
              </p>
            </li>
          </ul>
        </template>
        <template v-slot:button>
          <div class="is-flex">
            <ButtonApp
              @click="handleCloseSubmit"
              width="185px"
              height="52px"
              class="mr-3"
              :isSecondary="true"
            >
              <p class="averta-bold px-4 py-1">Cek Lagi</p>
            </ButtonApp>
            <ButtonApp
              @click="handleCreateBranchAndPIC"
              width="185px"
              height="52px"
            >
              <p class="averta-bold px-4 py-1">
                {{ MODAL_BUTTON_SUBMIT_FORM }}
              </p>
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
    </template>
  </SidebarClientTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarClientTemplate from '@/components/sidebar/SidebarClientTemplate.vue'
import Branch from './branch-components/Branch.vue'
import PIC from './branch-components/PIC.vue'
// import Admin from './admin-components/Admin.vue'
import ModalConfirm from './ModalConfirm.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import { SpinnerIcon } from '@/components/icons'
import { alertErrorMessage, toastMessage, titleBranch, titlePICBranch, isSMRC } from '@/helpers'

export default {
  name: 'SidebarBranch',
  components: {
    SidebarClientTemplate,
    Branch,
    PIC,
    // Admin,
    ButtonApp,
    ModalConfirm,
    SpinnerIcon
  },
  props: {
    show: {
      type: Boolean
    },
    close: {
      type: Function
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      payloadAddBranch: 'client/getPayloadAddBranch',
      payloadAddPicBranch: 'client/getPayloadAddPicBranch',
      addBranchFromList: 'client/getAddBranchFromList',
      getDetailClient: 'client/getDetailClient',
      getBranchImgFile: 'client/getBranchImgFile'
    }),
    getCode () {
      const codeClient = this.payloadAddBranch.clientSelected.code
      const codeClient2 = this.getDetailClient

      if (codeClient) {
        return codeClient
      } else if (codeClient2) {
        return codeClient2.code
      }
      return ''
    },
    isSMRC () {
      return isSMRC(this.getCode)
    },
    titleProps () {
      if (this.currentTab === 'branch') {
        return this.propsModal.branch.title
      } else if (this.currentTab === 'pic') {
        return this.propsModal.pic.title
      }
      // else if (this.currentTab === 'admin') {
      //   return this.propsModal.admin.title
      // }
      return 'Batalkan Penambahan Form?'
    },
    descProps () {
      const temp = 'Jika data sudah diinput akan dihapus oleh sistem'
      if (this.closeSidebarBranch) {
        return this.propsModal.desc_close
      } else if (this.moveTab) {
        return temp + ` dan kamu akan masuk ke page tambah ${this.tabDestination === 'branch' ? this.renderTitleBranch : this.tabDestination === 'pic' ? this.renderTitlePICBranch : 'Admin'}`
      } else {
        return temp
      }
    },
    renderTitleBranch () {
      return titleBranch(this.getCode)
    },
    renderTitlePICBranch () {
      return titlePICBranch(this.getCode)
    }
  },
  data () {
    return {
      showSidebar: false,
      currentTab: 'branch',
      tabDestination: '',
      disabledSubmit: true,
      showModal: false,
      moveTab: false,
      closeSidebarBranch: false,
      propsModal: {},
      isLoadingSubmit: false,
      showModalSubmit: false,
      MODAL_TITLE_SUBMIT_FORM: '',
      MODAL_DESC_SUBMIT_FORM: '',
      MODAL_BUTTON_SUBMIT_FORM: '',
      MODAL_ADDITIONAL_SECTION_FORM: ''
    }
  },
  methods: {
    showModalConfirm (moveTab) {
      const isBranch = this.currentTab === 'branch'
      const isPic = this.currentTab === 'pic'
      // const isAdmin = this.currentTab === 'admin' // Check for admin tab

      const noClientSelectedBranch = Object.keys(this.payloadAddBranch.clientSelected).length === 0
      const noClientSelectedPic = Object.keys(this.payloadAddPicBranch.clientSelected).length === 0
      const noBranchName = this.payloadAddBranch.branchName === ''
      const noBranchSelectedPic = Object.keys(this.payloadAddPicBranch.branchSelected).length === 0

      // Example condition for admin tab (adjust based on your actual logic)
      // const noAdminSelected = Object.keys(this.payloadAddPicBranch.branchSelected).length === 0

      const shouldClearBranch =
    (isBranch && noClientSelectedBranch) ||
    (isPic && noClientSelectedPic) ||
    (this.addBranchFromList && isBranch && !noClientSelectedBranch && noBranchName) ||
    (this.addBranchFromList && isPic && !noClientSelectedPic && noBranchSelectedPic) // Add condition for admin tab
    // || (isAdmin && noAdminSelected)

      if (shouldClearBranch) {
        this.clearPayloadAddBranch()
        this.clearPayloadAddPicBranch()
        // Optional: Add this.clearPayloadAddAdmin() if needed for the admin tab
        this.moveTab = false
        this.closeSidebarBranch = false
        this.currentTab = 'branch' // Default to 'branch' if conditions are met
        this.showSidebar = false
        this.close()
        this.$store.commit('client/setAddBranchFromList', false)
      } else {
        // If moving tabs or closing the sidebar
        if (moveTab) {
          this.moveTab = moveTab
        } else {
          this.closeSidebarBranch = true
        }
        this.showModal = true
      }
    },
    cancelClose () {
      this.moveTab = false
      this.closeSidebarBranch = false
      this.showModal = false
    },
    clearPayloadAddBranch () {
      // Trigger for clear state in child component
      this.$store.commit('client/setClearDataPayloadBranch', true)

      const storeToVuex = {
        type: 'clearPayload',
        data: {
          branchName: '',
          dataPic: []
        }
      }

      // Jika tambah branch dari modal, maka hapus clientSelected
      // Jika tambah branch dari button tambah pada list cabang, maka jangan hapus clientSelected
      if (this.addBranchFromList === false) {
        storeToVuex.data.clientSelected = {}
      } else {
        storeToVuex.data.clientSelected = this.payloadAddBranch.clientSelected
      }

      // Reset state global vuex for payloadAddBranch
      this.$store.commit('client/setPayloadAddBranch', storeToVuex)

      // Change setClearDataPayloadBranch to false for 1 second after clearDataPayloadBranch
      setTimeout(() => {
        this.$store.commit('client/setClearDataPayloadBranch', false)
      }, 1000)
    },
    clearPayloadAddPicBranch () {
      // Trigger for clear state in child component
      this.$store.commit('client/setClearDataPayloadPicBranch', true)

      const storeToVuex = {
        type: 'clearPayload',
        data: {
          branchSelected: {},
          dataPic: []
        }
      }

      // Jika tambah branch dari modal, maka hapus clientSelected
      // Jika tambah branch dari button tambah pada list cabang, maka jangan hapus clientSelected
      if (this.addBranchFromList === false) {
        storeToVuex.data.clientSelected = {}
      } else {
        storeToVuex.data.clientSelected = this.payloadAddBranch.clientSelected
      }

      // Reset state global vuex for payloadAddPicBranch
      this.$store.commit('client/setPayloadAddPicBranch', storeToVuex)

      // Change setClearDataPayloadPicBranch to false for 1 second after action
      setTimeout(() => {
        this.$store.commit('client/setClearDataPayloadPicBranch', false)
      }, 1000)
    },
    confirmModal () {
      const isBranch = this.currentTab === 'branch'
      const isPic = this.currentTab === 'pic'
      // const isAdmin = this.currentTab === 'admin'

      // If the sidebar is being closed or the action is canceled
      if (this.closeSidebarBranch) {
        this.clearPayloadAddBranch()
        this.clearPayloadAddPicBranch()
        // Optionally, add this.clearPayloadAddAdmin() if needed
        this.moveTab = false
        this.closeSidebarBranch = false
        this.currentTab = 'branch' // Default to 'branch'
        this.showSidebar = false
        this.close()
        this.$store.commit('client/setAddBranchFromList', false)
      } else if (this.moveTab) {
        if (isBranch) {
          this.clearPayloadAddBranch()
        // } else if (isPic || isAdmin) {
        } else if (isPic) {
          this.clearPayloadAddPicBranch()
        }

        this.currentTab = this.tabDestination
        this.tabDestination = ''

        this.moveTab = false
        this.closeSidebarBranch = false
      }

      this.showModal = false
    },
    handleSubmit () {
      if (this.currentTab === 'branch') {
        this.MODAL_TITLE_SUBMIT_FORM =
          'Semua Data Yang Dimasukkan Sudah Benar?'
        this.MODAL_DESC_SUBMIT_FORM =
          'Pastikan kamu sudah melakukan pengecekkan sebelum dikirim'
        this.MODAL_BUTTON_SUBMIT_FORM = 'Buat Akun'
        this.MODAL_ADDITIONAL_SECTION_FORM =
          `${this.renderTitlePICBranch} akan menerima email untuk ubah password sebelum masuk ke dashboard B2B Kanggo`
      } else if (this.currentTab === 'pic') {
        this.MODAL_TITLE_SUBMIT_FORM =
          'Semua Data Yang Dimasukkan Sudah Benar?'
        this.MODAL_DESC_SUBMIT_FORM =
          'Pastikan kamu sudah melakukan pengecekkan sebelum dikirim'
        this.MODAL_BUTTON_SUBMIT_FORM = 'Buat Akun'
        this.MODAL_ADDITIONAL_SECTION_FORM =
          `${this.renderTitlePICBranch} akan menerima email untuk ubah password sebelum masuk ke dashboard B2B Kanggo`
      } else if (this.currentTab === 'admin') {
        this.MODAL_TITLE_SUBMIT_FORM =
          'Semua Data Yang Dimasukkan Sudah Benar?'
        this.MODAL_DESC_SUBMIT_FORM =
          'Pastikan kamu sudah melakukan pengecekkan sebelum dikirim'
        this.MODAL_BUTTON_SUBMIT_FORM = 'Buat Akun'
        this.MODAL_ADDITIONAL_SECTION_FORM =
          'Admin akan menerima email untuk ubah password sebelum masuk ke dashboard B2B Kanggo'
      }
      this.showModalSubmit = true
    },
    changeTab (tabName) {
      this.tabDestination = tabName

      const isBranch = this.currentTab === 'branch'
      const isPic = this.currentTab === 'pic'
      // const isAdmin = this.currentTab === 'admin' // New admin tab check

      const noClientSelectedBranch = Object.keys(this.payloadAddBranch.clientSelected).length === 0
      const noClientSelectedPic = Object.keys(this.payloadAddPicBranch.clientSelected).length === 0
      const noBranchName = this.payloadAddBranch.branchName === ''
      const noBranchSelectedPic = Object.keys(this.payloadAddPicBranch.branchSelected).length === 0

      // Example condition for admin tab, adjust based on your logic
      // const noAdminSelected = Object.keys(this.payloadAddPicBranch.branchSelected).length === 0

      if (isBranch && noClientSelectedBranch) {
        this.clearPayloadAddBranch()
        this.moveTab = false
        this.closeSidebarBranch = false
      } else if (isPic && noClientSelectedPic) {
        this.clearPayloadAddPicBranch()
        this.moveTab = false
        this.closeSidebarBranch = false
      } else if (this.addBranchFromList && isBranch && !noClientSelectedBranch && noBranchName) {
        this.moveTab = false
        this.closeSidebarBranch = false
      } else if (this.addBranchFromList && isPic && !noClientSelectedPic && noBranchSelectedPic) {
        this.moveTab = false
        this.closeSidebarBranch = false
        // } else if (isAdmin && noAdminSelected) {
        // Logic when no admin selected (example logic, adjust as needed)
        // this.clearPayloadAddAdmin()
        this.moveTab = false
        this.closeSidebarBranch = false
      } else {
        this.showModalConfirm(true)
        return
      }

      this.currentTab = tabName // Assign the new tab after conditions are checked
    },
    validationPayload (value, currentTab) {
      const data = value

      function checkClientSelected () {
        if (
          data.clientSelected === null ||
          typeof data.clientSelected !== 'object' ||
          Object.keys(data.clientSelected).length === 0
        ) {
          return true
        }
      }

      function checkBranch () {
        if (currentTab === 'branch') {
          if (data.branchName === '') {
            return true
          }
        } else if (currentTab === 'pic' || currentTab === 'admin') {
          if (
            data.branchSelected === null ||
            typeof data.branchSelected !== 'object' ||
            Object.keys(data.branchSelected).length === 0
          ) {
            return true
          }
        }
      }

      function checkDataPic () {
        let result = false
        if (data.dataPic.length > 0) {
          data.dataPic.forEach((item, index) => {
            if (item.name === '') {
              result = true
            } else if (item.email === '') {
              result = true
            } else if (item.phone === '') {
              result = true
            }
          })
        }

        return result
      }

      let checkBranchFile = false
      if (this.isSMRC && !this.getBranchImgFile) {
        checkBranchFile = true
      }

      if (checkClientSelected() || checkBranch() || checkDataPic() || checkBranchFile) {
        this.disabledSubmit = true
      } else {
        this.disabledSubmit = false
      }
    },
    handleCloseSubmit () {
      this.showModalSubmit = false
    },
    handleCreateBranchAndPIC () {
      if (this.currentTab === 'branch') {
        this.isLoadingSubmit = true
        this.showModalSubmit = false

        const payload = {
          url: this.url,
          data: {
            vendor_id: this.payloadAddBranch.clientSelected.id,
            branch_name: this.payloadAddBranch.branchName,
            users: this.payloadAddBranch.dataPic
          }
        }

        if (this.isSMRC && this.getBranchImgFile) {
          payload.data.logo = this.getBranchImgFile
          payload.data.branchSMRC = true
          payload.data.area_id = this.$route.query.area_id
        }

        this.$store
          .dispatch('client/createBranch', payload)
          .then(() => {
            this.isLoadingSubmit = false
            toastMessage(`${this.renderTitleBranch} Berhasil Di Buat`)
            this.clearPayloadAddBranch()
            this.close()
            this.$emit('refreshListBranch')
          })
          .catch(error => {
            this.isLoadingSubmit = false
            alertErrorMessage(error)
          })
      } else if (this.currentTab === 'pic') {
        this.isLoadingSubmit = true
        this.showModalSubmit = false

        const payload = {
          url: this.url,
          data: {
            vendor_id: this.payloadAddPicBranch.clientSelected.id,
            branch_id: this.payloadAddPicBranch.branchSelected.id,
            users: this.payloadAddPicBranch.dataPic
          }
        }

        this.$store
          .dispatch('client/createBranch', payload)
          .then(() => {
            this.isLoadingSubmit = false
            toastMessage(`Berhasil menambahkan ${this.renderTitlePICBranch}`)
            this.clearPayloadAddPicBranch()
            this.close()
            this.$emit('refreshListBranch')
          })
          .catch(error => {
            this.isLoadingSubmit = false
            alertErrorMessage(error)
          })
      } else if (this.currentTab === 'admin') {
        this.isLoadingSubmit = true
        this.showModalSubmit = false

        const payload = {
          url: this.url,
          data: {
            vendor_id: this.payloadAddPicBranch.clientSelected.id,
            branch_id: this.payloadAddPicBranch.branchSelected.id,
            users: this.payloadAddPicBranch.dataPic
          }
        }

        this.$store
          .dispatch('client/createAdmin', payload)
          .then(() => {
            this.isLoadingSubmit = false
            toastMessage('Berhasil menambahkan Admin')
            this.clearPayloadAddPicBranch()
            this.close()
            this.$emit('refreshListBranch')
          })
          .catch(error => {
            this.isLoadingSubmit = false
            alertErrorMessage(error)
          })
      }
    }
  },
  created () {
    this.propsModal = {
      branch: {
        image: 'diskon.png',
        title: `Batalkan Tambah ${this.renderTitleBranch}?`
      },
      pic: {
        image: 'diskon.png',
        title: `Batalkan Tambah ${this.renderTitlePICBranch}?`
      },
      admin: {
        title: 'Batalkan Tambah Admin?'
      },
      desc_close: 'Jika data sudah diinput akan dihapus oleh sistem'
    }
  },
  watch: {
    show (value) {
      this.showSidebar = value
    },
    payloadAddBranch: {
      handler (value) {
        this.validationPayload(value, this.currentTab)
      },
      deep: true
    },
    payloadAddPicBranch: {
      handler (value) {
        this.validationPayload(value, this.currentTab)
      },
      deep: true
    },
    getBranchImgFile () {
      this.validationPayload(this.payloadAddBranch, this.currentTab)
    }
  }
}
</script>

<style scoped>
.button-tab {
  background: white;
  border: 1px solid #e1e1e1;
  width: 100%;
  padding: 12px;
  border-radius: 100px;
  color: #9a9a9a;
  cursor: pointer;
}

.button-tab:first-child {
  margin-right: 10px;
}

.button-tab.active {
  background: #e10009;
  border: none;
  color: white;
}

.wrap-desc {
  background: #fafafa;
  border: 1px solid #e1e1e1;
  padding: 20px 45px;
  list-style-type: disc;
}
</style>
