<template>
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7507 3.08329V6.58329H10.584V3.08329M10.584 7.74996H11.7507V8.91663H10.584M5.33398 0.166626C5.02457 0.166626 4.72782 0.289542 4.50903 0.508335C4.29023 0.727127 4.16732 1.02387 4.16732 1.33329C4.16322 1.38961 4.16322 1.44614 4.16732 1.50246C2.48732 1.99829 1.25065 3.56163 1.25065 5.41663V8.91663L0.0839844 10.0833V10.6666H10.584V10.0833L9.41732 8.91663V5.41663C9.41732 3.56163 8.18065 1.99829 6.50065 1.50246C6.50475 1.44614 6.50475 1.38961 6.50065 1.33329C6.50065 1.02387 6.37774 0.727127 6.15894 0.508335C5.94015 0.289542 5.6434 0.166626 5.33398 0.166626ZM4.16732 11.25C4.16732 11.5594 4.29023 11.8561 4.50903 12.0749C4.72782 12.2937 5.02457 12.4166 5.33398 12.4166C5.6434 12.4166 5.94015 12.2937 6.15894 12.0749C6.37774 11.8561 6.50065 11.5594 6.50065 11.25H4.16732Z"
      fill="url(#paint0_linear_372_100853)" />
    <defs>
      <linearGradient id="paint0_linear_372_100853" x1="0.0839843" y1="6.18026" x2="9.66145" y2="10.6534"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'LoncengIcon'
}
</script>
