<template>
  <Skeleton v-if="!isLoading" class="p-6" />
  <div class="pt-6 px-0 profile-wrapper mx-5 mx-sm-0" v-else>
    <title-bar :title-stack="titleStack" class="mb-5 title" />
    <div class="wrapper-tabs mx-md-0">
      <b-tabs>
          <b-tab-item label="Informasi Pribadi">
              <InformasiPribadi class="mb-6" />
              <button class="btn-logout">
                <div class="is-flex is-justify-content-center is-align-items-center">
                  <LogoutIconMobile class="mr-3" />
                  <span class="averta-bold has-text-dark text-logout" @click="logout()">Logout</span>
                </div>
              </button>
          </b-tab-item>
          <b-tab-item label="Kata Sandi">
              <KataSandi />
          </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import Skeleton from '@/components/skeleton/Skeleton'
import { LogoutIconMobile } from '@/components/icons'
import InformasiPribadi from './InformasiPribadi.vue'
import KataSandi from './KataSandi.vue'

export default {
  name: 'ProfilePage',
  components: {
    TitleBar,
    Skeleton,
    InformasiPribadi,
    KataSandi,
    LogoutIconMobile
  },
  computed: {
    titleStack () {
      return ['Akun Profil']
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
      this.$store.dispatch('penawaran/resetState')
      this.$router.push({ name: 'Login' })
    }
  },
  mounted () {
    setTimeout(() => {
      this.isLoading = true
    }, 1000)
  }
}
</script>

<style scoped>

.btn-logout {
  display: none;
}

.wrapper-tabs {
  width: 100%;
}

.profile-wrapper {
  overflow: hidden !important;
  width: 95%;
}

@media only screen and (max-width: 768px) {
  .title {
    margin-left: 24px !important;
  }
  .wrapper-tabs {
    margin: 0px 28px !important;
  }
  .btn-logout {
    display: block;
    width: 84%;
    padding: 12px;
    background: white;
    border: 1px #D9272D solid;
    border-radius: 100px;
  }

  .text-logout {
    color: #D9272D !important;
  }

  .profile-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .btn-logout {
    width: 100% !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

</style>
