<template>
  <div class="wrap">
    <div class="mb-5">
      <p class="averta-black label-20">Informasi {{renderTitleBranch}}</p>
      <p>
        Masukkan {{renderTitlePICBranch}} dari brand yang sudah diinput.
      </p>
    </div>
    <div>
      <div
        v-if="
          currentTab === 'pic' &&
            (typeSection !== 'add' && typeSection !== 'edit')
        "
        class="is-flex is-justify-content-space-between mb-5"
      >
        <p class="label-14 mr-3 mt-2 white-space-nowrap">
          <span class="averta-bold">{{renderTitleBranch}} </span>
          <span class="text-merah">*</span>
        </p>
        <BranchAutoComplete />
      </div>
      <div
        class="mb-5"
        v-else-if="currentTab === 'branch' && (typeSection !== 'add' && typeSection !== 'edit')"
      >
        <div class="is-flex is-justify-content-space-between is-align-items-center ">
          <p class="label-14 mr-3 white-space-nowrap input-label flex-shrink-0">
            <span class="averta-bold ">Nama {{renderTitleBranch}} </span>
            <span class="text-merah">*</span>
          </p>
          <input
            type="text"
            class="input"
            :placeholder="`Masukkan nama ${renderTitleBranch}`"
            v-model="branchName"
            :disabled="disabledBranchName"
          />
        </div>
        <div v-if="isSMRC" class="flex-center-vertical mt-4">
          <div class="input-label mr-3">
            <p class="averta-bold">Logo <span class="color-red">*</span></p>
          </div>
          <div class="flex-center-vertical">
            <label for="branch-logo-file" class="flex-center-vertical">
              <div v-if="branchLogo" class="is-relative">
                <div class="preview-crop-img is-clickable">
                  <img :src="branchLogo" alt="" />
                </div>
                <div class="edit-image">
                  <EditIcon />
                </div>
              </div>
              <div v-else class="flex-center-vertical is-clickable input-search-1 bg-grey-3">
                <UploadLightIcon />
                <p class="ml-1">Upload Foto</p>
              </div>
            </label>
            <input
              type="file"
              id="branch-logo-file"
              class="d-none"
              ref="branchImg"
              @change="uploadImageFile"
              hidden
              accept="image/png, image/jpeg"
            />
          </div>
        </div>
      </div>

      <div class="wrap-form">
        <div v-for="(data, index) in dataPic" :key="index">
          <FormPicBranch
            :isDelete="index > 0"
            :dataProps="data"
            :index="index"
            @updatePic="updatePic($event)"
            @deletePic="deletePic($event)"
            :currentTab="currentTab"
            :typeSection="typeSection"
            :disabledInput="disabledInput"
          />
        </div>
        <AddPicBranchBanner v-if="typeSection !== 'edit'" @addPic="addPic" />
      </div>
    </div>

    <ModalCropImage
      :showModal="showModalCropImage"
      :previewImage="previewLogo"
      :closeModal="closeModalCropImage"
      @cropImage="cropImage($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddPicBranchBanner from './AddPicBranchBanner.vue'
import FormPicBranch from './FormPicBranch.vue'
import BranchAutoComplete from './BranchAutoComplete.vue'
import { titleBranch, titlePICBranch, isSMRC } from '@/helpers'
import { EditIcon, UploadLightIcon } from '@/components/icons'
import ModalCropImage from '../client-components/ModalCropImage.vue'

export default {
  name: 'BranchInfo',
  props: {
    currentTab: {
      type: String,
      default: null
    },
    typeSection: {
      type: String,
      default: null
    }
  },
  components: {
    FormPicBranch,
    AddPicBranchBanner,
    BranchAutoComplete,
    EditIcon,
    UploadLightIcon,
    ModalCropImage
  },
  computed: {
    ...mapGetters({
      detailPICBranch: 'client/getDetailPICBranch',
      payloadAddBranch: 'client/getPayloadAddBranch',
      payloadAddPicBranch: 'client/getPayloadAddPicBranch',
      clearDataPayloadBranch: 'client/getClearDataPayloadBranch',
      clearDataPayloadPicBranch: 'client/getClearDataPayloadPicBranch'
    }),
    getCode () {
      const temp = this.payloadAddBranch.clientSelected.code
      return temp || ''
    },
    renderTitleBranch () {
      return titleBranch(this.getCode)
    },
    renderTitlePICBranch () {
      return titlePICBranch(this.getCode)
    },
    isSMRC () {
      return isSMRC(this.getCode)
    }
  },
  data () {
    return {
      dataPic: [
        {
          name: '',
          email: '',
          phone: ''
        }
      ],
      branchName: '',
      branchLogo: '',
      branchImgFile: null,
      previewLogo: '',
      showModalCropImage: false,
      disabledInput: true,
      disabledBranchName: true
    }
  },
  methods: {
    addPic () {
      this.dataPic.push({
        name: '',
        email: '',
        phone: ''
      })
    },
    deletePic (index) {
      this.dataPic.splice(index, 1)
    },
    updatePic (data) {
      this.dataPic[data.index] = data.data
    },
    disabledBranchNameFunc (value) {
      if (
        Object.keys(value.clientSelected).length === 0 ||
        value.clientSelected === ''
      ) {
        this.disabledBranchName = true
      } else {
        this.disabledBranchName = false
      }
    },
    selectedPIC (data) {
      if (this.typeSection === 'edit' && data) {
        const { id = '', name = '', email = '', phone = '' } = data
        this.dataPic = [
          {
            id,
            name,
            email,
            phone
          }
        ]
      }
    },
    uploadImageFile (e) {
      e.preventDefault()
      if (e.target.files[0]) {
        this.previewLogo = URL.createObjectURL(e.target.files[0])
        this.showModalCropImage = true
        this.$refs.branchImg.value = ''
      }
    },
    closeModalCropImage () {
      this.showModalCropImage = false
    },
    cropImage (result) {
      const { preview, file } = result

      this.branchLogo = preview
      this.branchImgFile = file
      this.showModalCropImage = false
      this.$store.commit('client/setPayloadAddBranch', {
        type: 'branchLogo',
        branchImgFile: file
      })
    }
  },
  created () {
    const storeToVuex = {
      type: 'dataPic',
      dataPic: this.dataPic
    }

    if (this.currentTab === 'branch') {
      this.$store.commit('client/setPayloadAddBranch', storeToVuex)
    } else if (this.currentTab === 'pic') {
      this.$store.commit('client/setPayloadAddPicBranch', storeToVuex)
    }
    this.selectedPIC(this.detailPICBranch)
  },
  watch: {
    branchName: function (newValue) {
      if (newValue !== '') {
        this.disabledInput = false
      } else {
        this.disabledInput = true
      }

      const storeToVuex = {
        type: 'branchName',
        branchName: newValue
      }
      this.$store.commit('client/setPayloadAddBranch', storeToVuex)
    },
    dataPic: {
      handler (newValue) {
        const storeToVuex = {
          type: 'dataPic',
          dataPic: newValue
        }

        if (this.currentTab === 'branch') {
          this.$store.commit('client/setPayloadAddBranch', storeToVuex)
        } else if (this.currentTab === 'pic') {
          this.$store.commit('client/setPayloadAddPicBranch', storeToVuex)
        }
      },
      deep: true
    },
    payloadAddBranch: {
      handler (newValue) {
        this.branchName = newValue.branchName
        this.dataPic = newValue.dataPic
        this.disabledBranchNameFunc(newValue)
      },
      deep: true
    },
    payloadAddPicBranch: {
      handler (newValue) {
        if (Object.keys(newValue.branchSelected).length > 0) {
          this.disabledInput = false
        } else {
          if (!this.branchName) {
            this.disabledInput = true
          }
        }

        this.dataPic = newValue.dataPic
      },
      deep: true
    },
    clearDataPayloadBranch: function (newValue) {
      if (newValue === true) {
        this.dataPic = [
          {
            name: '',
            email: '',
            phone: ''
          }
        ]
        this.branchName = ''
        this.disabledInput = true

        this.branchName = ''
        this.branchLogo = ''
        this.branchImgFile = null
      }
    },
    clearDataPayloadPicBranch: function (newValue) {
      if (newValue === true) {
        this.dataPic = [
          {
            name: '',
            email: '',
            phone: ''
          }
        ]
        this.disabledInput = true
      }
    },
    detailPICBranch: {
      handler (newValue) {
        this.selectedPIC(newValue)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.wrap {
  padding: 40px;
  background: #f8f7fa;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
}

.wrap-form {
  background: white;
  border: 1px solid #e1e1e1;
  padding: 40px;
  border-radius: 20px;
  border-bottom: 2px solid #e1e1e1;
}

.input {
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  padding: 22px 12px;
}

.preview-crop-img {
  height: 70px;
}

.preview-crop-img img {
  height: 100%;
}

.edit-image {
  position: absolute;
  right: -20px;
  bottom: 0px;
}

.input-label {
  width: 180px;
}
</style>
