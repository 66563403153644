<template>
  <div class="request-schedule-wrapper">
    <div class="head flex-sm-direction-column">
      <div class="is-flex is-align-items-center align-items-sm-start mb-sm-1">
        <div class="icon-clock-wrapper">
          <img :src="require('@/assets/img/penawaran-pending.png')" alt="penawaran-pending" class="mr-3" />
        </div>
        <div>
          <p class="averta-bold label-20">Permintaan Perubahan Jadwal</p>
          <p class="label-16">Client meminta perubahan jadwal rencana kunjungan survey. Butuh Bantuan ? <span class="averta-bold color-red is-clickable">Hubungi PIC Toko</span></p>
        </div>
      </div>
      <div class="is-flex is-align-items-center c-w-sm-100">
        <div class="is-flex is-align-items-center c-w-sm-100 justify-content-sm-between">
          <button @click="handleRejectReschedule" class="btn-reject color-red mr-3 averta-bold is-clickable">Tolak Permintaan</button>
          <ButtonApp @click="handleUpdateReschedule" :is-bold="true">
            <p class="button-text">Ubah Jadwal Survey</p>
          </ButtonApp>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="mb-5">
        <p class="color-gray">Reschedule Ke</p>
        <p class="averta-bold">{{convertDate(dataReschedule.date)}}<span v-if="dataReschedule.start_time && dataReschedule.end_time" class="color-gray">{{' - ' + convertTime(dataReschedule.start_time, dataReschedule.end_time)}}</span></p>
      </div>
      <div>
        <p class="color-gray">Alasan Reschedule</p>
        <p class="averta-bold">{{dataReschedule.reason}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'CardRescheduleDetailSurvey',
  props: {
    dataReschedule: {
      type: Object,
      required: true
    },
    handleRejectReschedule: {
      type: Function,
      required: true
    },
    handleUpdateReschedule: {
      type: Function,
      required: true
    }
  },
  components: {
    ButtonApp
  },
  methods: {
    convertDate (date) {
      if (date) {
        return moment.utc(date).format('DD MMMM YYYY')
      } return '-'
    },
    convertTime (startTime, endTime) {
      if (startTime && endTime) {
        return `(${moment(startTime, 'HH:mm:ss').format('HH:mm')} - ${moment(endTime, 'HH:mm:ss').format('HH:mm')})`
      }
      return null
    }
  }
}
</script>

<style scoped>
.request-schedule-wrapper {
  width: 100%;
  border: 1px solid #D8D8D8;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 2em;
}

.request-schedule-wrapper .head {
  background: linear-gradient(90deg, #F8FFF7 0%, #ECFFEA 100%);
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.request-schedule-wrapper .body {
  background: #FAFDFF;
  padding: 1em;
}

.btn-reject {
  border: none;
  outline: none;
  padding: 1em 1.5em;
  border: 1px solid #D9272D;
  background: transparent;
  border-radius: 100px;
}

.button-text {
  width: 100%;
  padding: 0.6em 0;
  white-space: normal;
}

@media only screen and (max-width: 600px) {
  .icon-clock-wrapper {
    width: 10em;
  }

  .request-schedule-wrapper {
    width: 90%;
    margin: auto;
    margin-bottom: 2em;
  }
}
</style>
