<template>
  <div>
    <div class="my-4">
      <b-skeleton height="250px" :animated="true"></b-skeleton>
    </div>
    <div>
      <div v-for="item, index in totalCard" :key="index" class="mb-4">
        <b-skeleton height="50px" :animated="true"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalUserSkeleton',
  props: {
    showTitle: {
      type: Boolean,
      default: false
    },
    totalCard: {
      type: Number,
      default: 2
    }
  }
}
</script>
