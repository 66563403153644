<template>
    <div class="container is-fluid pt-3 min-h-90vh">
        <div class="is-flex is-align-items-center pt-5 mb-5">
            <title-bar :title-stack="titleStack" />
        </div>
        <b-tabs v-model="activeTab">
            <b-tab-item>
                <template #header>
                    <DrillingIcon :color="activeTab === 0 ? '#3185fc' : '#868686'" />
                    <span class="ml-2" :class="{'text-grey-4': activeTab !== 0}">Daftar Material</span>
                </template>
                <ListMaterials />
            </b-tab-item>
            <!-- <b-tab-item>
                <template #header>
                    <ListIcon :color="activeTab === 1 ? '#3185fc' : '#868686'" />
                    <span class="ml-2" :class="{'text-grey-4': activeTab !== 1}">Konfigurasi</span>
                </template>
            </b-tab-item> -->
            <b-tab-item>
                <template #header>
                    <!-- change to activeTab === 2 if Konfigurasi active -->
                    <StoreIcon :color="activeTab === 1 ? '#3185fc' : '#868686'" />
                    <span class="ml-2" :class="{'text-grey-4': activeTab !== 1}">Toko Material</span>
                </template>
                <ListStores />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import { DrillingIcon, StoreIcon } from '@/components/icons'
import ListMaterials from '../components/tables/ListMaterials.vue'
import ListStores from '../components/tables/ListStores.vue'

export default {
  name: 'CatalogPurchasing',
  components: {
    TitleBar,
    DrillingIcon,
    StoreIcon,
    ListMaterials,
    ListStores
  },
  computed: {
    titleStack () {
      return ['Katalog']
    }
  },
  data () {
    return {
      activeTab: 0
    }
  }
}
</script>

<style scoped>

</style>
