<template>
  <div class="my-5 specific-skeleton-card">
    <b-skeleton height="400px" width="100%" :animated="true"></b-skeleton>
  </div>
</template>

<script>
export default {
  name: 'CardSpecificSkeleton'
}
</script>

<style scoped>
.specific-skeleton-card {
  border-radius: 20px;
  overflow: hidden;
}
</style>
