import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Login from '../views/login/Index.vue'
import ProjectList from '../views/project/List.vue'
// import ProjectDetail from '../views/project/Detail.vue'
import ProjectInput from '../views/project/Input.vue'
import PembayaranList from '../views/pembayaran/List.vue'
import Product from '../views/product/Index.vue'
import Pengaturan from '../views/pengaturan/Index.vue'
import UserList from '../views/user/List.vue'
// import Laporan from '../views/laporan/Index.vue'
import UbahPassword from '../views/user/UbahPassword.vue'
// import ChangeDefaultPassword from '../views/user/ChangeDefaultPassword.vue'
import PendingTopUp from '../views/laporan/PendingTopUp.vue'
import ApprovalTopUp from '../views/top-up/ApprovalTopup.vue'
import BeforeAfterProject from '../views/project/BeforeAfter.vue'
// import Profile from '../views/Profile.vue'
import PengaturanProductDetail from '../views/pengaturan/ProductDetail.vue'
import ProjectInputTukangLangsung from '../views/project/InputTukangLangsung.vue'
import ListProject from '../views/v2/project/ListProject.vue'
import ForgotPassword from '../views/full-page/ForgotPassword.vue'
import ResetPassword from '../views/full-page/ResetPassword.vue'
import ReportPage from '../views/report/ReportPage.vue'
import ProfilePage from '../views/v2/profile/ProfilePage.vue'
import CheckInPemeriksaan from '../views/v2/project/CheckInPemeriksaan.vue'
import NewOffering from '../views/v2/project/NewOffering.vue'
import Notification from '../views/v2/notification/Notification.vue'
import routesAM from './RouterAM'
import routesSM from './RouterSM'
import routesPurchasing from './RouterPurchasing'
import DetailProyek from '../views/v2/project/detail-proyek/DetailProyek.vue'
import DetailSurvey from '../views/v2/project/detail-survey/views/DetailSurvey'
import SearchGlobal from '../views/v2/project/SearchGlobal.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      const rs = localStorage.getItem('webapp_shell_kanggo')
      const auth = JSON.parse(rs)
      if (auth.user.is_default_password === 1) {
        next({ name: 'ChangeDefaultPassword' })
      } else if (auth.user.is_default_password === 0 && auth.user.roles[0].name === 'Account Manager') {
        next({ name: 'AM Dashboard' })
      } else if (auth.user.is_default_password === 0 && auth.user.roles[0].name === 'Site Manager') {
        next({ name: 'SM Dashboard' })
      } else if (auth.user.is_default_password === 0 && auth.user.roles[0].name === 'Purchasing') {
        next({ name: 'Purchasing Dashboard' })
      } else if (!auth) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login
  // },
  {
    path: '/full-page',
    component: () => import(/* webpackChunkName: "full-page" */ '../views/FullPage.vue'),
    children: [
      {
        meta: {
          title: 'Login'
        },
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "full-page" */ '../views/full-page/Login.vue')
      },
      {
        meta: {
          title: 'Change Default Password'
        },
        path: '/change-default-password',
        name: 'ChangeDefaultPassword',
        component: () => import(/* webpackChunkName: "full-page" */ '../views/full-page/ChangeDefaultPassword.vue')
      },
      {
        meta: {
          title: 'Forgot Password'
        },
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
      },
      {
        meta: {
          title: 'Reset Password'
        },
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword
      }
    ]
  },
  {
    path: '/project',
    name: 'ListProject',
    component: ListProject
  },
  {
    path: '/project/survey/:surveyId/detail',
    name: 'DetailSurvey',
    component: DetailSurvey
  },
  {
    path: '/project/:proyekId/detail',
    name: 'DetailProject',
    component: DetailProyek
  },
  {
    path: '/project/:surveyId/checkin-pemeriksaan',
    name: 'CheckInPemeriksaan',
    component: CheckInPemeriksaan
  },
  {
    path: '/project/:surveyId/new-offering',
    name: 'NewOffering',
    component: NewOffering
  },
  {
    path: '/project/list',
    name: 'ProjectList',
    component: ProjectList
  },
  {
    path: '/project/detail/:projectId/:source',
    name: 'ProjectDetail',
    component: () => import(/* webpackChunkName: "ProjectDetail" */ '../views/project/Detail.vue')
  },
  {
    path: '/project/input',
    name: 'ProjectInput',
    component: ProjectInput
  },
  {
    path: '/project/input-tukang-langsung',
    name: 'ProjectInputTukangLangsung',
    component: ProjectInputTukangLangsung
  },
  {
    path: '/pembayaran/list',
    name: 'PembayaranList',
    component: PembayaranList
  },
  {
    path: '/user/list',
    name: 'UserList',
    component: UserList
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/laporan',
    name: 'Laporan',
    component: ReportPage
  },
  {
    path: '/ubah-password',
    name: 'UbahPassword',
    component: UbahPassword
  },
  {
    path: '/pengaturan',
    name: 'Pengaturan',
    component: Pengaturan
  },
  {
    path: '/pengaturan/product-detail/:productId',
    name: 'PengaturanProductDetail',
    component: PengaturanProductDetail
  },
  {
    path: '/pending-top-up',
    name: 'PendingTopUp',
    component: PendingTopUp
  },
  {
    path: '/approval-top-up',
    name: 'ApprovalTopUp',
    component: ApprovalTopUp
  },
  {
    path: '/project/before-after/:projectId',
    name: 'BeforeAfterProject',
    component: BeforeAfterProject
  },
  {
    path: '/notification',
    name: 'NotificationKonsultan',
    component: Notification
  },
  {
    path: '/search',
    name: 'SearchGlobalKonsultan',
    component: SearchGlobal
  },
  {
    meta: {
      title: 'Profile'
    },
    path: '/profile',
    name: 'profile',
    component: ProfilePage
  },
  {
    meta: {
      title: 'Penawaran'
    },
    path: '/penawaran',
    name: 'Penawaran',
    component: () => import(/* webpackChunkName: "Penawaran" */ '../views/penawaran/Index.vue')
  },
  {
    meta: {
      title: 'Form Penawaran'
    },
    path: '/penawaran/form-penawaran',
    name: 'Form Penawaran',
    component: () => import(/* webpackChunkName: "Form Penawaran" */ '../views/penawaran/FormOffer.vue')
  },
  {
    meta: {
      title: 'Detail Penawaran'
    },
    path: '/penawaran/detail/:idPenawaran/:source',
    name: 'Detail Penawaran',
    component: () => import(/* webpackChunkName: "Detail Penawaran" */ '../views/penawaran/Detail.vue')
  },
  {
    meta: {
      title: 'Update Penawaran'
    },
    path: '/penawaran/update/:idPenawaran',
    name: 'Update Penawaran',
    component: () => import(/* webpackChunkName: "Update Penawaran" */ '../views/penawaran/FormOffer.vue')
  },
  ...routesAM,
  ...routesSM,
  ...routesPurchasing
]

const router = new VueRouter({
  routes,
  linkActiveClass: 'active'
})
router.beforeEach((to, from, next) => {
  const publicPages = [
    '/login',
    '/forgot-password',
    '/reset-password'
  ]

  const rs = localStorage.getItem('webapp_shell_kanggo')
  const auth = JSON.parse(rs)
  const authRequired = !publicPages.includes(to.path)

  if (authRequired && !auth) {
    return next({ name: 'Login' })
  }

  return next()

  // if (to.name !== 'Login' && (!auth || !auth.token)) {
  //   next({ name: 'Login' })
  // } else {
  //   next()
  // }
})

export function redirectTo (routePath) {
  router.push(routePath)
}

export default router
