<template>
  <svg :width="size" :height="size" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1.37988" width="41" height="41" rx="20.5" stroke="url(#paint0_linear_2543_28838)" />
    <mask id="mask0_2543_28838" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="19" y="13" width="4" height="17">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 17.8799C22.1 17.8799 23 16.9799 23 15.8799C23 14.7799 22.1 13.8799 21 13.8799C19.9 13.8799 19 14.7799 19 15.8799C19 16.9799 19.9 17.8799 21 17.8799ZM21 19.8799C19.9 19.8799 19 20.7799 19 21.8799C19 22.9799 19.9 23.8799 21 23.8799C22.1 23.8799 23 22.9799 23 21.8799C23 20.7799 22.1 19.8799 21 19.8799ZM19 27.8799C19 26.7799 19.9 25.8799 21 25.8799C22.1 25.8799 23 26.7799 23 27.8799C23 28.9799 22.1 29.8799 21 29.8799C19.9 29.8799 19 28.9799 19 27.8799Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_2543_28838)">
      <rect x="9" y="9.87988" width="24" height="24" fill="url(#paint1_linear_2543_28838)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2543_28838" x1="-1.71158e-07" y1="21.4981" x2="33.8574" y2="38.1019" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D9272D" />
        <stop offset="1" stop-color="#EB4600" />
      </linearGradient>
      <linearGradient id="paint1_linear_2543_28838" x1="9" y1="21.6617" x2="28.3471" y2="31.1496" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'HamburgerDotIcon',
  props: {
    size: {
      type: String,
      default: '42'
    }
  }
}
</script>
