<template>
  <div class="calendar-container">
    <div class="calendar-wrapper-purchasing">
      <FullCalendar :options="calendarOptionsMonth">
        <template v-slot:eventContent="arg">
          <div v-if="arg.event.title === 'Lihat Semua'">
            <p class="text-merah has-text-left averta-bold p-1">Lihat Semua</p>
          </div>
          <div class="wrapper-date is-flex is-justify-content-space-between px-2 is-align-items-center" v-else>
            <div class="is-flex is-justify-content-space-between mr-6">
              <!-- <img src="https://storage.googleapis.com/kang-go.appspot.com/b2b-client/profile/1683198238911.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=nBiXVWx8GTIwnuqD76BjyuNEXgqS5t%2FvsWxp7XpmnfvCfhoSz2Tr6yp8on169UsNLnCmErXJ3DB0xe9hvaB4H9PkgrtqKNeJBHOAM9xHu9%2BX5sQzhPTSjJbA9iWlAk%2B1SFKIaKqlzObxkn%2F%2BI4hmhBrPYtVke2336PHzqf28AQTqf10Ntj1p5FV9IMziBquxtdlnkyrxX9QygpdwWmDv8qV%2FfpTtbXUMchSqIB8KGHC2%2BJ4InOHAJ3TzgHFBAj7IprAqSex3yADWiBjW0k2zi3tIW4NKuNZmMIL98u1yol6A2i5dvzA5fjY3FkQExI8Ge%2Fyvc5AhcMHdDnhq11YQbQ%3D%3D" alt="logo"> -->
              <p class="averta-bold">{{ arg.event.title }}</p>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <p class="mr-2 label-12">ID{{ arg.event.id }}</p>
              <ArrowRightIcon color="white" />
            </div>
          </div>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import { ArrowRightIcon } from '@/components/icons'

export default {
  name: 'CalendarPurchasing',
  props: ['calendarOptionsMonth'],
  components: {
    FullCalendar,
    ArrowRightIcon
  }
}
</script>

<style scoped>
.calendar-container {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.calendar-wrapper-purchasing {
  width: auto;
  height: auto;
}

.wrapper-date {
  padding: 4px 12px !important;
  overflow-x: auto;
}

.wrapper-date img {
  width: 40px;
  height: auto;
  margin-right: 18px;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .calendar-wrapper {
    width: 1200px;
  }
}
</style>
