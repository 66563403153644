<template>
  <div>
    <div class="is-flex bg-grey-11">
      <div
        v-for="(empty, index) in 18"
        :key="`empty-${index}`"
        :style="{ width: `${widthColumnProps}px`, height: `${heightColumnProps}px` }"
        :class="{ 'border-right-5': index === 1, 'bg-grey-3': index === 8}"
        class="column-status">
      </div>
    </div>
    <div v-if="!hideDaily" class="flex-center">
      <div
        v-for="(daily, index) in 18"
        :key="`daily-${index}`"
        :style="{ width: `${widthColumnProps}px`, height: `${heightColumnDailyProps}px` }"
        class="daily-column flex-center"
        :class="{ 'border-right-5': index === 1, 'bg-grey-2': index === 8 }">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyColumnProgress',
  props: {
    heightColumnProps: {
      type: Number
    },
    heightColumnDailyProps: {
      type: Number
    },
    widthColumnProps: {
      type: Number
    },
    hideDaily: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";
.column-status {
  border: 1px solid $color-grey-10;
  border-top-width: 0;
  border-left-width: 0;
}

.daily-column {
  border: 1px solid $color-grey-10;
  border-top-width: 0;
  border-left-width: 0;
  background: $color-light-blue-2;
}
</style>
