<template>
  <div
    v-if="renderAdditionalHeader()"
    class="additional-header"
    :class="{
      'bg-black-1': typeTooltip === 'can-reject' || typeTooltip === 'can-paid' || totalReject,
      'bg-green-4': typeTooltip === 'payment-success',
      'bg-dark-red-3': typeTooltip === 'rejected'
    }"
  >
    <!-- Penawaran Ditolak Sudah Konfirmasi, Terbayar -->
    <div v-if="typeTooltip === 'payment-success' || typeTooltip === 'rejected'" class="flex-center-vertical">
      <img :src="require(`@/assets/img/${typeTooltip === 'rejected' ? 'close-icon' : 'check-green'}.png`)" alt="" />

      <div class="ml-2">
        <p class="averta-bold color-white label-12 line-height-15">
          {{ formatTitleFlaging(typeTooltip) }}
        </p>
        <p class="color-white label-12 line-height-15 mt-1">
          Konfirmasi oleh <span class="averta-bold"> {{ confirmBy }} ({{ confirmRole }}) - {{ formatDate(confirmDate, 'DD/MM/YYYY') }}</span>
        </p>
      </div>
    </div>

    <!-- Penawaran Ditolak, Invoice Terkirim -->
    <div v-else-if="typeTooltip === 'can-reject' || typeTooltip === 'can-paid'" class="flex-center-vertical is-justify-content-space-between">
      <p class="averta-bold color-white label-12 line-height-15">{{ formatTitleFlaging(typeTooltip) }}</p>
      <ButtonApp :isSecondary="true" :isBorderNone="true">
        <div class="flex-center-vertical" @click="handleConfirmTooltipProps(typeTooltip, itemStoreProps, itemColumnProps)">
          <p class="averta-bold label-12 mr-2">{{ formatTitleFlaging(typeTooltip, true) }}</p>
          <ArrowRightIcon color="#E10009" size="13" />
        </div>
      </ButtonApp>
    </div>

    <!-- Reject Ke-N -->
    <div v-else-if="totalReject" class="flex-center-vertical is-justify-content-space-between">
      <div>
        <p class="averta-bold color-white label-12 line-height-15">Reject ke-{{ totalReject }}</p>
        <p class="color-white label-12 line-height-15 mt-1">Penawaran baru telah dikirimkan ke client</p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateHelper } from '@/helpers'
import { ArrowRightIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'AdditionalHeaderTooltipProgress',
  components: {
    ArrowRightIcon,
    ButtonApp
  },
  props: {
    indexColumnProps: {
      type: Number
    },
    dataTooltipFlagingProps: {
      type: Object
    },
    handleConfirmTooltipProps: {
      type: Function
    },
    itemStoreProps: {
      type: Object
    },
    itemColumnProps: {
      type: Object
    }
  },
  data () {
    return {
      typeTooltip: null,
      totalReject: null,
      confirmBy: null,
      confirmRole: null,
      confirmDate: null
    }
  },
  methods: {
    formatDate (date, format) {
      if (!date) return ''
      return formatDateHelper({ date, format })
    },
    formatTitleFlaging (type, isButton) {
      switch (type) {
        case 'rejected':
          return 'Penawaran Ditolak'
        case 'payment-success':
          return 'Tagihan Ditandai Sudah Dibayar'
        case 'can-reject':
          return isButton ? 'Penawaran Ditolak' : 'Penawaran Telah Ditolak?'
        case 'can-paid':
          return isButton ? 'Konfirmasi Terbayar' : 'Tagihan Sudah Dibayarkan?'

        default:
          return type
      }
    },
    renderAdditionalHeader () {
      const indexColumn = this.indexColumnProps

      if (this.typeTooltip) {
        switch (this.typeTooltip) {
          case 'payment-success':
            return indexColumn === 17 // Terbayar
          case 'can-paid':
            return indexColumn === 16 // Invoice Terkirim
          case 'can-reject':
          case 'rejected':
            return indexColumn === 8 // Penawaran Ditolak
          default:
            return false
        }
      } else if (this.totalReject && indexColumn === 8) {
        return true
      }

      return false
    },
    assignState () {
      if (this.dataTooltipFlagingProps) {
        const temp = this.dataTooltipFlagingProps
        this.typeTooltip = temp.type
        this.totalReject = temp.total_reject
        this.confirmBy = temp.confirm_by
        this.confirmRole = temp.confirm_role
        this.confirmDate = temp.confirm_date
      }
    }
  },
  mounted () {
    this.assignState()
  },
  watch: {
    dataTooltipFlagingProps () {
      this.assignState()
    }
  }
}
</script>

<style scoped>
.additional-header {
  min-height: 75px;
  padding: 13px;
  padding-bottom: 25px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin-bottom: -15px;
}
</style>
