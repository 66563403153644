<template>
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 17H2V4.35995C2.00003 4.04509 2.09914 3.73822 2.28328 3.48282C2.46743 3.22743 2.72727 3.03645 3.026 2.93695L11.026 0.270953C11.2515 0.19586 11.4915 0.175409 11.7264 0.211283C11.9614 0.247157 12.1844 0.338331 12.3772 0.477293C12.5699 0.616256 12.7269 0.79903 12.8352 1.01056C12.9435 1.22209 13 1.45632 13 1.69395V17H14V7.98995C14 7.91601 14.0165 7.84299 14.0482 7.77617C14.0798 7.70934 14.1259 7.65037 14.1831 7.60351C14.2403 7.55664 14.3072 7.52304 14.3789 7.50514C14.4507 7.48723 14.5255 7.48546 14.598 7.49995L16.794 7.93995C17.1339 8.00789 17.4398 8.19147 17.6597 8.45946C17.8795 8.72745 17.9998 9.06331 18 9.40995V17H19C19.2652 17 19.5196 17.1053 19.7071 17.2928C19.8946 17.4804 20 17.7347 20 18C20 18.2652 19.8946 18.5195 19.7071 18.7071C19.5196 18.8946 19.2652 19 19 19H1C0.734784 19 0.48043 18.8946 0.292893 18.7071C0.105357 18.5195 0 18.2652 0 18C0 17.7347 0.105357 17.4804 0.292893 17.2928C0.48043 17.1053 0.734784 17 1 17Z"
      fill="url(#paint0_linear_423_100263)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_423_100263"
        x1="-8.1504e-08"
        y1="9.42606"
        x2="15.7232"
        y2="17.6264"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TokoIcon'
}
</script>
