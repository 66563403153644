<template>
  <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="45" height="45" rx="22.5" fill="white" />
    <path
      d="M18.0275 33.773C20.2225 34.9255 21.32 35.5005 22.5 35.5005V23.0005L10.7975 16.8418C10.7805 16.8695 10.7638 16.8974 10.7475 16.9255C10 18.193 10 19.7718 10 22.928V23.0743C10 26.2293 10 27.808 10.7462 29.0755C11.4937 30.3443 12.8387 31.0505 15.5275 32.4618L18.0275 33.773Z"
      fill="#D9272D" />
    <path opacity="0.7"
      d="M29.4711 13.54L26.9711 12.2275C24.7774 11.0762 23.6799 10.5 22.4999 10.5C21.3186 10.5 20.2224 11.075 18.0274 12.2275L15.5274 13.54C12.8974 14.92 11.5524 15.625 10.7974 16.84L22.4999 23L34.2024 16.8412C33.4449 15.625 32.1024 14.92 29.4711 13.54Z"
      fill="#D9272D" />
    <path opacity="0.5"
      d="M34.2537 16.9255C34.237 16.8974 34.2199 16.8695 34.2025 16.8418L22.5 23.0005V35.5005C23.68 35.5005 24.7775 34.9255 26.9725 33.773L29.4725 32.4605C32.1612 31.0493 33.5062 30.3443 34.2537 29.0755C35 27.808 35 26.2293 35 23.0755V22.9293C35 19.773 35 18.193 34.2537 16.9255Z"
      fill="#D9272D" />
    <path
      d="M15.4037 13.6055L15.5287 13.5405L17.3949 12.5605L28.7699 18.8168L33.8012 16.303C33.9724 16.4968 34.1224 16.703 34.2537 16.9255C34.4412 17.243 34.5812 17.5805 34.6862 17.9568L29.6874 20.4555V24.2505C29.6874 24.4992 29.5887 24.7376 29.4129 24.9135C29.237 25.0893 28.9986 25.188 28.7499 25.188C28.5013 25.188 28.2628 25.0893 28.087 24.9135C27.9112 24.7376 27.8124 24.4992 27.8124 24.2505V21.393L23.4374 23.5805V35.3805C23.1311 35.4591 22.8162 35.4994 22.4999 35.5005C22.1899 35.5005 21.8837 35.4605 21.5624 35.3805V23.5805L10.3149 17.9555C10.4199 17.5805 10.5599 17.243 10.7474 16.9255C10.8776 16.7038 11.0291 16.4953 11.1999 16.303L22.4999 21.953L26.7324 19.8368L15.4037 13.6055Z"
      fill="#D9272D" />
  </svg>
</template>

<script>
export default {
  name: 'GroupMaterial'
}
</script>
