<template>
  <div class="overflow-auto c-w-100 min-h-60vh mb-4">
    <div v-if="dataOffered.length > 0">
      <table class="table is-narrow is-hoverable table-wrapper label-14 pb-3 averta-regular">
        <tr class="has-background-grey has-text-white has-text-centered averta-bold tr-head-wrapper">
          <td style="width: 5%">
            <div>NO</div>
          </td>
          <td style="width: 8%">
            <div>FOTO</div>
          </td>
          <td style="width: 40%">
            <div class="px-3 is-justify-content-flex-start">MATERIAL</div>
          </td>
          <td style="width: 8%">
            <div>UOM (SATUAN)</div>
          </td>
          <td style="width: 12%">
            <div>QTY</div>
          </td>
          <td style="width: 12%">
            <div>QTY DIPESAN</div>
          </td>
          <td style="width: 12%">
            <div>SISA MATERIAL</div>
          </td>
        </tr>
        <tr v-for="(itemOffer, indexOffer) in dataOffered" :key="itemOffer.id" class="tr-wrapper label-14" :class="{ 'bg-lightgray': (indexOffer + 1) % 2 === 0 }">
          <td>
            <div class="py-3 has-text-centered">{{ indexOffer + 1 }}</div>
          </td>
          <td>
            <div class="py-1 is-flex is-align-items-center is-justify-content-center">
              <div class="wrapper-img is-clickable" @click="handlePreviewImage(itemOffer.picture)">
                <img class="img-fill-all" :src="itemOffer.picture || require('@/assets/img/empty-img-2.png')" alt="" />
              </div>
            </div>
          </td>
          <td>
            <div class="p-3">{{ itemOffer.name }}</div>
          </td>
          <td>
            <div class="py-3 has-text-centered">{{ itemOffer.uom }}</div>
          </td>
          <td>
            <div class="py-3 has-text-centered">{{ formatThousand(itemOffer.qty) }}</div>
          </td>
          <td>
            <div class="py-3 has-text-centered">{{ formatThousand(itemOffer.qty_booked) }}</div>
          </td>
          <td>
            <div class="py-3 has-text-centered">{{ formatThousand(itemOffer.remaining_material) }}</div>
          </td>
        </tr>
      </table>

      <ModalPreviewImage :isActive="previewImage" :isOneImage="true" :urlOneImage="imagePreview" @closeModal="closePreviewImage()" />
    </div>
    <div v-else class="column has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Material Kosong
      </p>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers'
import { ProyekEmptyIcon } from '@/components/icons'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'

export default {
  name: 'TableMaterialOffered',
  props: ['dataOffered'],
  components: {
    ProyekEmptyIcon,
    ModalPreviewImage
  },
  data () {
    return {
      modalPreviewImageActive: false,
      previewImage: false,
      imagePreview: null
    }
  },
  methods: {
    handlePreviewImage (url) {
      if (url) {
        this.previewImage = true
        this.imagePreview = url
      }
    },
    closePreviewImage () {
      this.imagePreview = null
      this.previewImage = false
    },
    formatThousand (val) {
      if (!val && val !== 0 && val !== '0') {
        return '-'
      }
      return formatCurrency(val, true)
    }
  }
}
</script>
