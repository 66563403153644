<template>
  <svg :width="size" :height="size" viewBox="0 0 22 19" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_11645_130776"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="22"
      height="19"
    >
      <path
        d="M11 13.5V4.5"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 1.5H18V4.5H4V1.5Z"
        fill="white"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 13L6 9.5H15.987L19 13"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 13.5H5V17.5H1V13.5ZM9 13.5H13V17.5H9V13.5ZM17 13.5H21V17.5H17V13.5Z"
        fill="white"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </mask>
    <g mask="url(#mask0_11645_130776)">
      <path d="M-1 -3H23V21H-1V-3Z" fill="url(#paint0_linear_11645_130776)" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_11645_130776"
        x1="-1"
        y1="8.78182"
        x2="18.3471"
        y2="18.2697"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop offset="0.9999" :stop-color="color" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'BranchIcon',
  props: {
    color: {
      type: String,
      default: '#D9272D'
    },
    size: {
      type: String,
      default: '20'
    }
  }
}
</script>
