<template>
  <svg :width="size" :height="size" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.41667 11.1301L6.8125 9.38013L7.48917 8.70346L8.41667 9.63096L10.5108 7.53679L11.1875 8.35929M5.96667 11.1301H1.41667C1.10725 11.1301 0.810501 11.0072 0.591709 10.7884C0.372916 10.5696 0.25 10.2729 0.25 9.96346V1.79679C0.25 1.14929 0.769167 0.630127 1.41667 0.630127H9.58333C9.89275 0.630127 10.1895 0.753043 10.4083 0.971836C10.6271 1.19063 10.75 1.48737 10.75 1.79679V6.34679C10.2367 6.04929 9.63583 5.88013 9 5.88013L8.41667 5.92679V5.29679H2.58333V6.46346H7.06917C6.58652 6.78163 6.19046 7.21472 5.91657 7.7238C5.64268 8.23289 5.49954 8.80204 5.5 9.38013C5.5 10.016 5.66917 10.6168 5.96667 11.1301ZM5.5 7.63013H2.58333V8.79679H5.5M8.41667 2.96346H2.58333V4.13013H8.41667"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'ListChecklistIcon',
  props: {
    size: {
      type: String,
      default: '12'
    },
    color: {
      type: String,
      default: '#10BD41'
    }
  }
}
</script>
