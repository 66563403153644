<template>
  <div class="container">
    <div class="mb-5">
      <b-skeleton width="20%" :animated="true"></b-skeleton>
      <b-skeleton width="20%" :animated="true"></b-skeleton>
    </div>
    <div class="columns mb-4">
      <div class="column">
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
      </div>
      <div class="column">
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
      </div>
      <div class="column">
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
      </div>
      <div class="column">
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
      </div>
    </div>
    <div class="mb-5">
      <b-skeleton width="20%" :animated="true"></b-skeleton>
      <b-skeleton width="20%" :animated="true"></b-skeleton>
    </div>
    <div>
      <b-skeleton :animated="true"></b-skeleton>
      <b-skeleton :animated="true"></b-skeleton>
      <b-skeleton :animated="true"></b-skeleton>
      <b-skeleton :animated="true"></b-skeleton>
      <b-skeleton :animated="true"></b-skeleton>
      <b-skeleton :animated="true"></b-skeleton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Skeleton'
}
</script>
