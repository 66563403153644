<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.06659 8.19992L4.61659 6.74992C4.49436 6.6277 4.34436 6.56659 4.16659 6.56659C3.98881 6.56659 3.83325 6.63325 3.69992 6.76659C3.5777 6.88881 3.51659 7.04436 3.51659 7.23325C3.51659 7.42214 3.5777 7.5777 3.69992 7.69992L5.59992 9.59992C5.72214 9.72214 5.8777 9.78325 6.06659 9.78325C6.25547 9.78325 6.41103 9.72214 6.53325 9.59992L10.3166 5.81659C10.4388 5.69436 10.4999 5.54436 10.4999 5.36659C10.4999 5.18881 10.4333 5.03325 10.2999 4.89992C10.1777 4.7777 10.0221 4.71659 9.83325 4.71659C9.64436 4.71659 9.48881 4.7777 9.36659 4.89992L6.06659 8.19992ZM6.99992 13.6666C6.0777 13.6666 5.21103 13.4915 4.39992 13.1413C3.58881 12.7915 2.88325 12.3166 2.28325 11.7166C1.68325 11.1166 1.20836 10.411 0.858585 9.59992C0.508363 8.78881 0.333252 7.92214 0.333252 6.99992C0.333252 6.0777 0.508363 5.21103 0.858585 4.39992C1.20836 3.58881 1.68325 2.88325 2.28325 2.28325C2.88325 1.68325 3.58881 1.20814 4.39992 0.857919C5.21103 0.508141 6.0777 0.333252 6.99992 0.333252C7.92214 0.333252 8.78881 0.508141 9.59992 0.857919C10.411 1.20814 11.1166 1.68325 11.7166 2.28325C12.3166 2.88325 12.7915 3.58881 13.1413 4.39992C13.4915 5.21103 13.6666 6.0777 13.6666 6.99992C13.6666 7.92214 13.4915 8.78881 13.1413 9.59992C12.7915 10.411 12.3166 11.1166 11.7166 11.7166C11.1166 12.3166 10.411 12.7915 9.59992 13.1413C8.78881 13.4915 7.92214 13.6666 6.99992 13.6666Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'StatusProyek',
  props: {
    color: {
      type: String,
      default: '#10BD41'
    }
  }
}
</script>
