<template>
  <div>
    <p v-if="isLoading">Loading...</p>
    <section class="input-unit" v-if="!isLoading">
      <b-field>
        <b-autocomplete
          field="name"
          placeholder="Pilih Satuan"
          clearable
          open-on-focus
          v-model="unitName"
          :data="filteredData"
          :disabled="disabled"
          @focus="resetSearch"
          @select="(option) => handleSelectUnit(option)"
          @input="(e) => handleChangeInput(e)"
        ></b-autocomplete>
      </b-field>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'

export default {
  name: 'InputAutocompleteSatuan',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      unitName: '',
      search: '',
      dataUnit: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    filteredData () {
      return this.dataUnit.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) >= 0
        )
      })
    }
  },
  methods: {
    handleChangeInput (e) {
      this.search = e
    },
    handleSelectUnit (option) {
      this.$emit('selectedUnit', option || 'empty')
    },
    resetSearch () {
      this.search = ''
    },
    getDataUnit () {
      this.isLoading = true
      const payload = {
        url: this.url,
        type: 'volume'
      }
      this.$store
        .dispatch('project/getListUnit', payload)
        .then((response) => {
          this.isLoading = false
          this.dataUnit = response.data.data
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    }
  },
  created () {
    this.getDataUnit()
  }
}
</script>

<style lang="scss">
@import '@/scss/_variables.scss';
</style>

<style lang="scss">
@import '@/scss/_variables.scss';

.input-unit input {
  border-radius: 10px !important;
  border: 1px solid $color-grey-2 !important;
  font-size: 15px;
}
</style>
