<template>
  <div class="card p-4">
    <div class="is-flex is-align-items-center mb-3">
      <img src="../../assets/img/penawaran-pending.png" alt="penawaran-pending" class="mr-3">
      <div>
        <p class="averta-bold is-size-5">Menunggu keputusan penawaran dari client</p>
        <p class="desc is-hidden-mobile">
          Client akan review penawaran ini dan akan memberikan keputusan approved/reject. Butuh bantuan? <a href="#" class="averta-bold">Hubungi PIC by WA</a>
        </p>
      </div>
    </div>
    <div class="desc-wrapper is-hidden-tablet">
      <p class="desc">
        Client akan review penawaran ini dan akan memberikan keputusan approved/reject. Butuh bantuan? <a href="#" class="averta-bold">Hubungi PIC by WA</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSurveyProcess'
}
</script>

<style scoped>
.card {
  background: rgba(49, 133, 252, 0.1);
  border: 1px solid #3185FC;
  border-radius: 12px;
}

.desc-wrapper .desc {
  color: #4A4A4A;
}
</style>
