<template>
  <div class="c-w-100">
    <div class="is-flex is-justify-content-space-between">
      <div>
        <p class="averta-bold color-red">PT. Tenaga Kanggo Indonesia</p>
        <p class="label-12" style="width: 170px">
          Foresta Business Loft 1 No. 32, Jl. BSD Raya Utama, BSD City, Tangerang, Banten - 15339
        </p>
      </div>
      <div>
        <div class="flex-center-vertical mb-1">
          <PhoneIcon size="18" />
          <p class="label-12 ml-2">(021) 5011 2109</p>
        </div>
        <div class="flex-center-vertical mb-1">
          <WebIcon size="18" />
          <p class="label-12 ml-2">kanggo.id</p>
        </div>
        <div class="flex-center-vertical mb-1">
          <InstagramIcon size="18" />
          <p class="label-12 ml-2">@kanggo</p>
        </div>
      </div>
    </div>
    <p class="color-gray has-text-centered label-12">
      {{ pagePosition }}
    </p>
  </div>
</template>

<script>
import { PhoneIcon, InstagramIcon, WebIcon } from '@/components/icons'

export default {
  name: 'FooterSection',
  props: {
    pagePosition: {
      type: String
    }
  },
  components: {
    PhoneIcon,
    InstagramIcon,
    WebIcon
  }
}
</script>
