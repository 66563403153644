<template>
  <div>
    <p v-if="isLoading">Loading</p>
    <div v-else-if="data" class="columns is-variable is-2">
      <div class="column is-12">
        <div class="is-flex flex-sm-column">
          <div class="card card-wrapper-costum mr-4 mr-sm-0 mb-sm-2 mb-0 flex-1 p-4">
            <p class="text-title averta-bold">Detail Penawaran</p>
            <div class="mb-3">
              <p class="label-14 color-gray">ID</p>
              <p class="averta-bold">{{ data.survey_id }}</p>
            </div>
            <div class="mb-3">
              <p class="label-14 color-gray">Tanggal Pembuatan Penawaran</p>
              <p class="averta-bold">
                {{ formatDate(detailOffering && detailOffering.created_at) }}
              </p>
            </div>
            <div style="gap: 1rem" class="is-flex is-flex-direction-row">
              <div class="is-flex is-flex-direction-column">
                <p class="label-14 color-gray">PIC - No. Telp</p>
                <p class="averta-bold">
                  {{ detailOffering && isPurchasing ? detailOffering.pic_name : detailOffering.pic.name }} -
                  <span class="color-blue">{{ detailOffering && isPurchasing ? detailOffering.pic_phone : detailOffering.pic.phone }}</span>
                </p>
              </div>
              <div v-if="dataHeader && dataHeader.offering && dataHeader.offering.pic && dataHeader.offering.pic.extra_customer_name && dataHeader.offering.pic.extra_customer_phone" class="border-left-1">
              </div>
              <div v-if="dataHeader && dataHeader.offering && dataHeader.offering.pic && dataHeader.offering.pic.extra_customer_name && dataHeader.offering.pic.extra_customer_phone" class="is-flex is-flex-direction-column">
                <p class="color-gray">Informasi Customer</p>
                <p class="averta-bold">
                  {{ detailOffering && isPurchasing ? dataHeader.offering.pic.extra_customer_name : dataHeader.offering.pic.extra_customer_name }} -
                  <span class="color-blue">
                    {{ detailOffering && isPurchasing ? dataHeader.offering.pic.extra_customer_phone : dataHeader.offering.pic.extra_customer_phone }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="card card-wrapper-costum flex-1 p-4">
            <p class="text-title averta-bold">Detail Proyek</p>
            <div class="mb-3">
              <p class="label-14 color-gray">Alamat Pengerjaan</p>
              <p class="averta-bold text-red">{{ detailProject && detailProject.address }}</p>
            </div>
            <div class="mb-3">
              <p class="label-14 color-gray">Catatan Perbaikan</p>
              <p class="averta-bold">{{ detailProject && detailProject.note || '-' }}</p>
            </div>
            <div v-if="isPurchasing" class="mb-3">
              <p class="label-14 color-gray">Konsultan Pengawas</p>
              <p class="averta-bold">{{ detailProject && detailProject.consultan_name || '-' }} <span class="color-blue">{{detailProject && detailProject.consultan_phone}}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'TabDetailProyek',
  props: ['data', 'surveyId', 'isBS', 'isPurchasing'],
  data () {
    return {
      isLoading: true,
      detailOffering: null,
      detailProject: null,
      detailPenawaran: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      dataHeader: 'project/getHeaderProject'
    })
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return '-'
      }

      return moment(date).format('dddd, DD MMMM YYYY')
    },
    formatThousand (x) {
      if (!x && x !== 0) {
        return '-'
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    loadData () {
      this.isLoading = true
      if (this.data) {
        if (!this.isPurchasing) {
          this.detailPenawaran = this.data.preview_price
        }
        this.detailOffering = this.data.offering || this.data.detail_offering
        this.detailProject = this.data.project || this.data.detail_project
      }
      this.isLoading = false
    }
  },

  mounted () {
    this.loadData()
  },
  watch: {
    data () {
      this.loadData()
    }
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.color-blue {
  color: #3185fc;
}

.flex-1 {
  flex: 1;
}

.flex-half {
  flex: 0.5;
}

.label {
  color: #868686;
}

.text-red {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.strikethrough {
  text-decoration: line-through;
  color: #eb4600;
}

.text-title {
  font-size: 20px;
  margin-bottom: 1rem;
}

.detail-material-wrapper {
  background: #fafafa;
  border-radius: 20px;
  max-height: 30em;
  overflow-y: auto;
}

.material-wrapper {
  background: #fff;
  border-radius: 20px;
}

.fs-12 {
  font-size: 12px;
}

.btn-primary {
  background: linear-gradient(
    116.12deg,
    #d9272d 16.15%,
    #eb4600 83.24%
  ) !important;
  border-radius: 100px;
}

.btn-disabled {
  background: #9a9a9a;
  border-radius: 100px;
}

.material-photo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 57px;
}

.material-photo-wrapper img {
  width: 100%;
}

.header-modal {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}

.body-modal {
  background: white;
  padding: 12px 24px;
  border-radius: 0px 0px 12px 12px;
}

.icon-bag {
  width: 20px;
}

.wrapper-detail {
  background: #fafafa;
  border-radius: 20px;
}

.wrapper-pembulatan {
  background: linear-gradient(90deg, #fffff7 0%, #fff5ea 100%);
  border-radius: 8px;
}

.skill-image {
  width: 50px;
  object-fit: cover;
}

.material-image {
  width: 50px;
  object-fit: cover;
}

.divider {
  background: #E1E1E1;
  border: 1px solid #E1E1E1;
  margin-bottom: 1rem;
}

@media screen and (max-width: 600px) {
  .flex-sm-column {
    flex-direction: column;
  }

  .mb-sm-2 {
    margin-bottom: 1rem !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
</style>
