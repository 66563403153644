<template>
    <TableSkeleton v-if="isLoading" class="mb-6" />
    <div v-else>
      <div class="min-height-60">
        <div class="is-flex is-justify-content-space-between my-4">
          <div class="wrapper-search">
            <b-field>
              <b-input placeholder="Cari Nama Kota"
                  type="search"
                  icon="magnify"
                  icon-clickable
                  @icon-click="getDataKota(1)"
                  @keyup.native.enter="getDataKota(1)"
                  v-model="search"
                  >
              </b-input>
            </b-field>
          </div>
        </div>
        <div>
          <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
            <tr class="bg-dark-grey-5 has-text-white has-text-centered averta-bold">
              <td class="py-4" style="width: 5em">NO</td>
              <td class="py-4" style="width: 30%">ID KOTA</td>
              <td class="py-4" style="width: 30%">NAMA KOTA</td>
              <td class="py-4" style="width: 30%">JUMLAH KUNJUNGAN (PER HARI)</td>
              <td class="py-4" style="width: 5em"></td>
            </tr>
            <tr v-for="(itemDetail, indexDetail) in dataKota" :key="itemDetail.id" class="tr-wrapper" :class="{'bg-lightgray': (indexDetail + 1) % 2 === 0}">
              <td class="py-3 has-text-centered">
                {{currentPage === 1 ? indexDetail + 1 : indexDetail + 1 === 10 ? `${currentPage}0` : `${currentPage - 1}${indexDetail+1}`}}
              </td>
              <td>
                <div class="py-3 is-flex is-justify-content-space-between averta-bold text-merah">
                  <p>#ID{{itemDetail.id}}</p>
                  <span class="cursor-pointer" @click="handleCopyText(`#ID${itemDetail.id}`)">
                    <CopasIcon />
                  </span>
                </div>
              </td>
              <td>
                <div class="py-3 averta-bold label-14">
                  <p>{{itemDetail.name}}</p>
                </div>
              </td>
              <td>
                <div class="py-3 averta-bold label-14">
                  <p>{{itemDetail.max_visit}}</p>
                </div>
              </td>
              <td>
                <div class="py-3 averta-bold label-14 is-flex is-justify-content-space-evenly is-align-items-center">
                  <span @click="handleEditItem(itemDetail.id, itemDetail.max_visit)" class="cursor-pointer">
                    <img :src="require('@/assets/img/icon-edit.png')" alt="">
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <ModalKota :showModal="showModal" :id="modalId" :maxVisitProps="maxVisit" @closeModal="$event => handleCloseModalDetail($event)" />

      <PaginationApp
        :total-pages="totalPages"
        :current-page="currentPage"
        :load-page="getDataKota"
        class="my-6"
        :limit-data="10"
        :total-data="totalData"
      />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CopasIcon } from '@/components/icons'
import TableSkeleton from '@/components/skeleton/TableSkeleton'
import PaginationApp from '@/components/PaginationApp.vue'
import ModalKota from '../components/ModalKota.vue'

export default {
  name: 'ListDataKota',
  components: {
    CopasIcon,
    TableSkeleton,
    PaginationApp,
    ModalKota
  },
  data () {
    return {
      showModal: false,
      dataKota: [],
      isLoading: false,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      search: null,
      modalId: null,
      maxVisit: 0
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    handleCloseModal (event) {
      if (event === 'success-add-component') {
        this.getDataDetailComponents()
      }
      this.showModal = false
    },
    handleEditItem (id, maxVisit) {
      this.modalId = id
      this.maxVisit = maxVisit
      this.showModal = !this.showModal
    },
    async handleCopyText (text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$buefy.toast.open({
          message:
            '<p class="averta-bold">Tersalin!</p>',
          type: 'is-success'
        })
      } catch ($e) {
        this.$buefy.toast.open({
          message:
            '<p class="averta-regular">Gagal, Silahkan coba lagi</p>',
          type: 'is-success'
        })
      }
    },
    getDataKota (page) {
      this.isLoading = true
      const payload = {
        url: this.url,
        search: this.search,
        page: page || 1
      }
      this.$store
        .dispatch('settings/getDataKota', payload)
        .then(response => {
          this.dataKota = response.data.data
          this.totalPages = response.data.total_page
          this.totalData = response.data.total_data
          this.currentPage = response.data.current_page
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message:
              '<p class="averta-regular">Gagal memuat detail komponen, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    },
    handleCloseModalDetail (event) {
      if (event === 'success-update-jumlah-kunjungan') {
        this.getDataKota(1)
      }
      this.modalId = null
      this.showModal = false
    }
  },
  created () {
    this.getDataKota()
  },
  watch: {
    search: function (val) {
      if (val === '') {
        this.getDataKota()
      }
    }
  }
}
</script>

<style scoped>
.wrapper-search {
  width: 23%;
}

.btn-tambah {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border: none;
  border-radius: 100px;
  color: white;
  padding: 12px 28px;
}

.min-height-60 {
  min-height: 60vh;
}

.cursor-pointer {
  cursor: pointer;
}

.status-active {
  color: white;
  background: #F1D900;
  border-radius: 100px;
  padding: 4px 0px;
}

.non-active {
  color: white;
  background: #868686;
  border-radius: 100px;
  padding: 4px 0px;
}

.tr-wrapper td {
  border: 1px solid #C0C0C0;
}
</style>
