<template>
  <div class="px-5">
    <div>
        <p class="averta-bold label-20">Performa Hari Ini</p>
        <p>Terakhir Update: ~ {{ convertDate(dataCard.last_update) }}</p>
    </div>
    <div class="columns mt-3">
      <div v-if="checkRole" class="column card col-1 p-3 m-2">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="label-20 averta-bold">Kuota Terpenuhi</p>
          <p class="label-30 averta-bold my-2">{{ dataCard.daily.kuota }}</p>
        </div>
      </div>
      <div class="column card col-1 p-3 m-2">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="label-20 averta-bold">Survey Berlangsung</p>
          <p class="label-30 averta-bold my-2">{{ dataCard.daily.survey_in_progress }}</p>
        </div>
      </div>
      <div class="column card col-1 p-3 m-2">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="label-20 averta-bold">Survey Selesai</p>
          <p class="label-30 averta-bold my-2">{{ dataCard.daily.survey_complete }}</p>
        </div>
      </div>
      <div class="column card col-1 p-3 m-2">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="label-20 averta-bold">Penawaran Dibuat</p>
          <p class="label-30 averta-bold my-2">{{ dataCard.daily.offering_created }}</p>
        </div>
      </div>
      <div class="column card col-2 p-3 m-2">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="label-20 averta-bold">Target Tercapai</p>
          <div class="is-flex is-align-items-center">
            <p class="label-30 averta-bold my-2 mr-3">{{ dataCard.daily.target }}%</p>
            <UpIcon :class="{'flip-icon' : dataCard.daily.target <= 50 }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { UpIcon } from '../icons'

export default {
  name: 'CardPerformaDashboard',
  props: ['dataCard', 'role'],
  components: {
    UpIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    checkRole () {
      if (this.role === 'sm' || this.role === 'konsultan') {
        return false
      }
      return true
    }
  },
  data () {
    return {
      countData: null
    }
  },
  methods: {
    convertDate (date) {
      if (date === null) {
        return ''
      }
      return moment(date).format('DD MMMM YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  box-shadow: none;
}
.card-body-proyek {
  z-index: 1;
  position: relative;
}
.bottom-card {
  display: flex;
  justify-content: end;
  align-items: center;
  color: white;
  z-index: 1;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}
.col-1 {
  background: linear-gradient(90deg, #751ce6 0%, #552bff 100%);
  border-radius: 20px;
  color: white;
}
.col-2 {
  background: linear-gradient(116.12deg, #1ede31 16.15%, #72de1e 83.24%);
  border-radius: 20px;
  color: white;
}

.flip-icon {
  transform: rotate(-180deg);
}
</style>
