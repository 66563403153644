<template>
  <div v-if="initialLoading">
    <ListSkeleton :showTitle="false" />
  </div>
  <div v-else class="c-w-100 px-4 px-sm-0 min-height-project">
    <div class="mt-2 mb-5 pb-3 is-flex wrapper-button thin-scroll" v-if="dataSurvey.submenu && dataSurvey.submenu.length > 0">
      <div v-for="tab, index in dataSurvey.submenu" :key="`${index}-list-tab-survey`">
        <b-button v-if="tab.query !== 'reschedule'" class="mr-3 btn-tabs" :class="{ 'is-active': substatus === tab.query }" @click="changeIsActive(tab.query)">
          {{ wordingMenu(tab.label) }}
          <span class="tag is-danger is-rounded ml-2">{{ tab.count }}</span>
        </b-button>
      </div>
    </div>

    <div class="mb-5 columns w-cs-100">
      <div class="request-reschedule-wrapper flex-sm-column align-items-sm-start" v-if="user.roles[0].name === 'Account Manager' || user.roles[0].name === 'Site Manager'">
        <p class="averta-bold label-16 mb-sm-1">Perhatikan request reschedule survey</p>
        <b-tooltip v-if="user.roles[0].name === 'Account Manager' || user.roles[0].name === 'Site Manager'" :active="tooltipReschedule" position="is-bottom" type="is-white" always>
          <template v-slot:content>
            <div class="is-flex is-align-items-center is-justify-content-center py-3">
              <div class="card-attention is-flex is-align-items-center is-justify-content-center mr-3">
                <span class="averta-bold">!</span>
              </div>
              <div>
                <div class="is-flex is-justify-content-space-between">
                  <p class="averta-bold mb-3">Ada Request Reschedule!</p>
                  <button class="delete" @click="tooltipReschedule = false"></button>
                </div>
                <p>
                  Buka dan periksa sekarang request <br /> reschedule dari client dan berikan <br /> approval terkait.
                </p>
              </div>
            </div>
          </template>
          <button class="btn-reschedule c-w-sm-100" @click="showRescheduleSurvey">{{ dataTotalReschedule }} Request Reschedule Survey</button>
        </b-tooltip>
      </div>

    </div>
    <div v-if="dataSurvey.list && dataSurvey.list.length > 0" :class="{'is-loading-wrapper': isLoading}">
      <div v-for="data, index in dataSurvey.list" :key="`${index}-list-survey`">
        <div v-if="specificLoading && listPersist.surveyId === data.survey_id">
          <CardSpecificSkeleton />
        </div>
        <NewCardProject
          v-else
          :data="data"
          @showModalProyekDarurat="showProyekDarurat($event)"
          @lihatCatatanProyek="showCatatanProyek($event)"
          @detailProject="detailSurvey($event)"
          @reloadList="reloadList"
        />
      </div>
      <div class="flex-center">
        <p v-if="isLoadingScroll">Loading</p>
        <div v-else-if="currentPage === totalPages" style="width: 4px; height: 4px; border-radius: 4px; background: gray;"></div>
      </div>
    </div>
    <DataNotFound v-else />

    <!-- Modal Reschedule Survey -->
    <ModalRescheduleSurvey :showModal="modalRescheduleSurvey" width="75vw" @closeModal="showRescheduleSurvey" @totalReschedule="totalReschedule($event)" />
    <!-- Modal Reschedule Survey -->

    <!-- Modal Proyek Darurat -->
    <ModalProyekDarurat :dataModalUrgent="projectUrgent" :surveyId="surveyId" :showModal="modalProyekDarurat" :width="isMobile ? '95vw' : '45vw'" @closeModal="showProyekDarurat" :loadData="reloadList" />
    <!-- Modal Proyek Darurat -->

    <!-- Modal History Catatan Proyek -->
    <ModalHistoryCatatanProyek :surveyId="surveyId" :showModal="modalHistoryCatatanProyek" width="95vw" @closeModal="showCatatanProyek" />
    <!-- Modal History Catatan Proyek -->

    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import DataNotFound from '@/components/DataNotFound'
import { SpinnerIcon } from '@/components/icons'
import ListSkeleton from '@/components/skeleton/ListSkeleton'
import CardSpecificSkeleton from '@/components/skeleton/CardSpecificSkeleton.vue'
import { getStatusClassName, wordingMenuHelper } from '@/helpers'
import ModalRescheduleSurvey from '@/components/modal/ModalRescheduleSurvey.vue'
import NewCardProject from '../NewCardProject.vue'
import ModalProyekDarurat from '../../modal/ModalProyekDarurat.vue'
import ModalHistoryCatatanProyek from '../../modal/ModalHistoryCatatanProyek.vue'

export default {
  name: 'ListSurvey',
  props: [
    'isAM',
    'type',
    'selectedSubstatus',
    'activeTab',
    'currentTab',
    'selectedTab',
    'triggerAPIFromScroll',
    'triggerTop',
    'reloadData'
  ],
  components: {
    DataNotFound,
    SpinnerIcon,
    ListSkeleton,
    CardSpecificSkeleton,
    ModalRescheduleSurvey,
    NewCardProject,
    ModalProyekDarurat,
    ModalHistoryCatatanProyek
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      tab: 'list/getSelectedTabList',
      subtab: 'list/getSelectedSubTab',
      listPersist: 'list/getPersistDataList',
      listFilter: 'project/getDataListFilter'
    }),
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingScroll: false,
      initialLoading: true,
      initialActiveTab: null,
      specificLoading: false,
      seamlessLoading: false,

      dataSurvey: {
        submenu: [],
        list: []
      },
      searchBy: 'store',
      search: null,
      sort: 'desc',
      substatus: null,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      limitPage: 5,
      vendor: null,

      tooltipReschedule: false,
      modalRescheduleSurvey: false,
      dataTotalReschedule: 0,
      modalProyekDarurat: false,
      modalHistoryCatatanProyek: false,
      projectUrgent: null,
      surveyId: null
    }
  },
  methods: {
    getListSurvey (props = {}) {
      const {
        pages = 1,
        clearVendor = false,
        loadMore = false,
        dontLoading = false,
        seamlessLoading = false
      } = props

      if (clearVendor) {
        this.vendor = null
      }

      if (loadMore) {
        this.isLoadingScroll = true
      } else if (seamlessLoading) {
        this.seamlessLoading = true
      } else if (!dontLoading && !seamlessLoading) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        sort: this.sort,
        limit: this.limitPage,
        page: pages || 1,
        type: this.type
      }

      if (this.substatus !== null && this.substatus !== '') {
        payload.status = this.substatus
      }

      // filter by selected vendor
      if (this.vendor !== null) {
        payload.vendor = this.vendor
      }

      this.$store
        .dispatch('projectV2/getListProject', payload)
        .then(response => {
          const res = response.data
          this.currentPage = res.current_page

          if (this.initialLoading) {
            this.initialLoading = false
          }

          if (loadMore) {
            this.isLoadingScroll = false
            this.dataSurvey.list = [...this.dataSurvey.list, ...res.data.list]
          } else {
            this.dataSurvey = res.data
            this.totalPages = res.total_page
            this.totalData = res.total_data
          }
          this.isLoading = false
          this.seamlessLoading = false

          // Store Data
          this.persistDataList()
        })
        .catch(error => {
          this.isLoading = false
          this.initialLoading = false
          this.isLoadingScroll = false
          this.seamlessLoading = false
          console.log(error)
        })
    },
    changeIsActive (data) {
      if (this.dataTotalReschedule !== 0) {
        this.tooltipReschedule = true
      } else {
        this.tooltipReschedule = false
      }
      this.substatus = data
      if (this.activeTab || this.activeTab >= 0) {
        this.$store.commit('list/setSelectedSubTab', { tab: parseInt(this.activeTab), subtab: data })
      }

      this.getListSurvey()
    },
    detailSurvey (data) {
      const currentRole = this.user.roles[0].name
      this.persistDataList({ pageDetail: true, surveyId: data.survey_id })

      switch (currentRole) {
        case 'Site Manager':
          this.$router.push({ path: `/sm/project/survey/${data.survey_id}/detail` })
          break
        case 'Account Manager':
        case 'Business Support':
          this.$router.push({ path: `/am/project/survey/${data.survey_id}/detail` })
          break
        default:
          this.$router.push({ path: `/project/survey/${data.survey_id}/detail` })
          break
      }
    },
    convertDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    convertTime (startDate, endDate) {
      if (!startDate || !endDate) {
        return
      }
      const start = moment(startDate, 'HH:mm:ss').format('HH:mm')
      const end = moment(endDate, 'HH:mm:ss').format('HH:mm')
      const result = `(${start} - ${end})`
      return result
    },
    sendDetailAddress (data) {
      this.$emit('showDetailAddress', data)
    },
    renderPhoneNumber (phone) {
      if (phone) {
        if (phone.toString().substring(0, 2) === '62') {
          return `+${phone}`
        } else {
          return `+62${phone}`
        }
      } else {
        return ''
      }
    },
    getClassName (status) {
      return getStatusClassName(status)
    },
    getActiveTab () {
      if (this.subtab && this.subtab.length > 0) {
        const lastMatchingObject = this.subtab.find(obj => obj.tab === this.activeTab)
        if (lastMatchingObject) {
          this.substatus = lastMatchingObject.subtab || this.selectedSubstatus
        } else {
          this.substatus = this.selectedSubstatus
        }
      } else {
        this.substatus = this.selectedSubstatus
      }

      const findDataProject = this.listPersist.data.find(item => item.currentTab === this.initialActiveTab)
      if (findDataProject) {
        this.restoreDataList(findDataProject)
      } else {
        this.getListSurvey()
      }
    },
    showRescheduleSurvey () {
      if (this.dataTotalReschedule > 0) {
        this.modalRescheduleSurvey = !this.modalRescheduleSurvey
        this.tooltipReschedule = false
      }
    },
    totalReschedule (data) {
      this.dataTotalReschedule = data
      if (data > 0) {
        this.tooltipReschedule = true
      } else {
        this.tooltipReschedule = false
      }
    },
    wordingMenu (value) {
      return wordingMenuHelper(value)
    },
    showProyekDarurat (data) {
      if (data) {
        this.projectUrgent = data.projectUrgent
        this.surveyId = data.surveyId
      }
      this.modalProyekDarurat = !this.modalProyekDarurat
    },
    showCatatanProyek (data) {
      if (data) {
        this.surveyId = data
      }
      this.modalHistoryCatatanProyek = !this.modalHistoryCatatanProyek
    },
    reloadList () {
      this.getListSurvey()
      this.modalProyekDarurat = false
      this.modalHistoryCatatanProyek = false
    },
    persistDataList (props = {}) {
      const { pageDetail = false, surveyId } = props

      const store = {
        data: {
          currentTab: this.initialActiveTab,
          project: this.dataSurvey,
          currentPage: this.currentPage,
          totalPage: this.totalPages,
          totalData: this.totalData,
          sort: this.sort,
          vendor: this.vendor
        }
      }
      if (pageDetail) {
        store.scrollPosition = window.scrollY || window.pageYOffset
        store.detailPage = true
        store.surveyId = surveyId
      }

      this.$store.commit('list/setPersistDataList', store)
    },
    restoreDataList (data) {
      const { project, currentPage, totalPage, totalData, sort, vendor } = data

      this.dataSurvey = project
      this.currentPage = currentPage
      this.totalPages = totalPage
      this.totalData = totalData
      this.sort = sort
      this.vendor = vendor
      this.isLoading = false
      this.initialLoading = false

      setTimeout(() => {
        window.scrollTo(0, this.listPersist.scrollPosition)
      }, 50)

      if (this.initialActiveTab === this.selectedTab) {
        this.loadSpecificList()
      }
    },
    loadSpecificList () {
      this.specificLoading = true

      this.$store
        .dispatch('projectV2/getSpecificListProject', {
          url: this.url,
          surveyId: this.listPersist.surveyId
        })
        .then(response => {
          const res = response.data.data
          const findList = this.dataSurvey.list.find(item => item.survey_id === res.survey_id)
          const indexSurveyId = this.dataSurvey.list.findIndex(item => item.survey_id === res.survey_id)

          if (findList && indexSurveyId !== -1) {
            const isSame = JSON.stringify(findList.status) === JSON.stringify(res.status)

            if (isSame) {
              this.dataSurvey.list[indexSurveyId] = res
            } else {
              // this.dataSurvey.list.splice(indexSurveyId, 1)
              this.$emit('reloadData', { fromDetail: true })
            }

            setTimeout(() => {
              window.scrollTo(0, isSame ? this.listPersist.scrollPosition : 0)
              this.specificLoading = false
            }, 50)
          } else {
            this.specificLoading = false
          }
        })
        .catch((error) => {
          this.specificLoading = false
          console.log(error)
        })
    }
  },
  mounted () {
    this.getActiveTab()
  },
  created () {
    this.initialActiveTab = this.currentTab
  },
  watch: {
    search (val) {
      if (val === '') {
        this.getListSurvey()
      }
    },
    tab () {
      if (this.dataTotalReschedule !== 0) {
        this.tooltipReschedule = true
      } else {
        this.tooltipReschedule = false
      }
    },
    listFilter (val) {
      if (val) {
        if (val.sort) {
          this.sort = val.sort
        } else {
          this.sort = 'desc'
        }
        if (val.vendor) {
          this.vendor = val.vendor
        } else {
          this.vendor = null
        }
        this.getListSurvey()
      }
    },
    triggerAPIFromScroll () {
      if (this.initialActiveTab === this.selectedTab) {
        if (this.currentPage < this.totalPages && !this.isLoadingScroll) {
          this.currentPage++
          this.getListSurvey({ pages: this.currentPage, loadMore: true })
        }
      }
    },
    selectedTab (val) {
      if (this.initialActiveTab === val) {
        this.getListSurvey({ dontLoading: true })
      }
    },
    triggerTop (val) {
      if (this.initialActiveTab === this.selectedTab) {
        if (val === false && !this.seamlessLoading) {
          this.getListSurvey({ seamlessLoading: true })
        }
      }
    }
  }
}
</script>

<style scoped>
.tes-wrapper {
  width: 100%;
  background: salmon;
  display: flex;
  overflow: auto;
  padding-bottom: 20px;
}

.tes-wrapper .item {
  flex-shrink: 0;
  height: 5em;
  width: 10em;
  background: blue;
  margin: 10px;
}

.card-list {
  border-radius: 20px;
}

.header-detail {
  background: linear-gradient(90deg, #FFDDDD 0%, #FFEFDB 99.97%);
  border-radius: 20px 20px 0px 0px;
}

.id-survey {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.btn-lihat-detail {
  border: 1px solid #D9272D;
  border-radius: 20px;
  padding: 12px 24px;
  font-family: 'Averta-Bold';
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  cursor: pointer;
}

.btn-reschedule {
  border: none;
  border-radius: 20px;
  padding: 12px 24px;
  font-family: 'Averta-Bold';
  cursor: pointer;
  background: #D9272D;
  white-space: nowrap;
  margin-right: 18px;
  color: white;
}

.btn-tabs {
  border-radius: 20px;
  color: #9a9a9a;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
}

.wrapper-button {
  overflow: auto;
}

.wrapper-button.is-scroll {
  width: 90vw;
}

.wrap-logo {
  background: white;
  border-radius: 100px;
  padding: 6px 18px;
}

.wrap-logo img {
  height: 30px;
  object-fit: contain;
}

.card-attention {
  width: 24px;
  height: 24px;
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 100px;
  color: white;
  margin: 0px 10px 0px 10px;
  margin-bottom: 64px;
}

.request-reschedule-wrapper {
  display: flex;
  padding: 20px 12px;
  margin: 0.5em auto;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #F0F0F0;
  background: #FAFAFA;
  width: 98%;
}

@media only screen and (max-width: 768px) {
  .wrapper-button {
    width: 100% !important;
  }

  .wrapper-filter {
    display: block !important
  }

  .filter-list {
    display: flex;
    width: 100%;
  }

  .dropdown-list,
  .btn-dropdown {
    width: 100% !important;
  }

  .btn-dropdown div {
    padding-right: 180px !important;
  }

  .search-by-wrapper {
    padding-right: 0px !important;
  }
}

@media only screen and (max-width: 600px) {
  .vendor-wrapper {
    width: 100%;
  }
}
</style>
