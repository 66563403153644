<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.79199 13.4619C9.44699 13.4619 9.16699 13.1819 9.16699 12.8369V6.73108C9.16699 6.38608 9.44699 6.10608 9.79199 6.10608C10.137 6.10608 10.417 6.38608 10.417 6.73108V12.8369C10.417 13.1819 10.137 13.4619 9.79199 13.4619Z"
      fill="url(#paint0_linear_940_209816)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8472 10.4086H6.73633C6.39049 10.4086 6.11133 10.1286 6.11133 9.78357C6.11133 9.43857 6.39049 9.15857 6.73633 9.15857H12.8472C13.1922 9.15857 13.4722 9.43857 13.4722 9.78357C13.4722 10.1286 13.1922 10.4086 12.8472 10.4086Z"
      fill="url(#paint1_linear_940_209816)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.88683 2.08337C3.57683 2.08337 2.0835 3.66421 2.0835 6.11254V13.4709C2.0835 15.9192 3.57683 17.5 5.88683 17.5H13.6968C16.0077 17.5 17.5002 15.9192 17.5002 13.4709V6.11254C17.5002 3.66421 16.0077 2.08337 13.6968 2.08337H5.88683ZM13.6968 18.75H5.88683C2.86433 18.75 0.833496 16.6284 0.833496 13.4709V6.11254C0.833496 2.95504 2.86433 0.833374 5.88683 0.833374H13.6968C16.7193 0.833374 18.7502 2.95504 18.7502 6.11254V13.4709C18.7502 16.6284 16.7193 18.75 13.6968 18.75Z"
      fill="url(#paint2_linear_940_209816)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_940_209816"
        x1="9.16699"
        y1="9.71712"
        x2="10.4084"
        y2="9.82058"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_940_209816"
        x1="6.11133"
        y1="9.77221"
        x2="6.89947"
        y2="12.0482"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_940_209816"
        x1="0.833496"
        y1="9.62883"
        x2="15.2766"
        y2="16.7118"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'PlusWithBorder'
}
</script>
