<template>
  <div class="column card m-2 p-5 is-flex is-align-items-center is-justify-content-center" :class="{ 'card-red': !isBlack, 'card-black': isBlack }">
    <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
    <div class="body-card-wrapper has-text-centered">
      <p v-if="title" class="label-14 white-space-nowrap">{{ title }}</p>
      <p v-if="description" class="is-size-5 averta-bold white-space-nowrap">{{ description }}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardRedWave',
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    isBlack: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

.body-card-wrapper {
  position: relative;
  z-index: 1;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.card-red {
  background: linear-gradient(90deg, #891313 0%, #d92727 99.97%);
  border-radius: 20px;
  color: white;
}

.card-black {
  background: #2F2F2F;
  border-radius: 20px;
  color: white;
}

</style>
