<template>
  <div class="my-5 px-5">
    <div class="is-flex is-justify-content-space-between mb-6">
      <div>
        <p class="label-20 averta-bold">Kalender Proyek</p>
        <p>Pantau seluruh proyek berjalan</p>
      </div>
      <div>
        <b-dropdown aria-role="list" v-model="changeCalendarToMonth">
          <template #trigger="{ active }">
            <b-button :label="changeCalendarToMonth === 1 ? 'Mingguan' : 'Harian'"
              :icon-right="active ? 'menu-up' : 'menu-down'" />
          </template>

          <b-dropdown-item aria-role="listitem" :value="1">Mingguan</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" :value="0">Harian</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <!-- <div class="columns" v-if="calendarOptionsMonth.events.length === 0"> -->
        <div class="columns" v-if="dataCalendarWeekly.length < 1">
          <div class="column">
            <div class="columns is-centered">
              <div class="column has-text-centered">
                <ProyekEmptyIcon />
                <p class="averta-bold mt-2">
                  Kamu Belum Memiliki Proyek sedang berjalan
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="columns" v-else>
          <div class="column">
            <CalendarMonth :calendarOptionsMonth="calendarOptionsMonth" v-if="changeCalendarToMonth === 1" />
            <CalendarWeek :eventsWeek="dataCalendarDaily" v-if="changeCalendarToMonth === 0" />
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="modalDetail" :can-cancel="false">
      <div class="modal-wrapper">
        <div class="header-modal is-flex is-justify-content-space-between">
          <div class="is-flex">
            <DaftarSurvey class="mr-3" />
            <p class="averta-bold label-20">Daftar Survey</p>
          </div>
          <div class="is-clickable" @click="modalDetail = false">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
        <div class="body-modal" v-if="dataModal.length > 0">
          <div class="card my-3" v-for="data in dataModal" :key="data.id">
            <div class="header-survey p-5">
              <div class="is-flex is-align-items-center is-justify-content-space-between header-card-wrapper">
                <div class="is-flex mb-sm-2">
                  <LocationIcon class="mr-4" />
                  <div class="title-grey">
                    <p>ID Survery</p>
                    <div class="is-flex is-align-items-center text-id-survey">
                      <p class="mr-2 averta-bold">#ID{{ data.id }}</p>
                      <CopasIcon />
                    </div>
                  </div>
                </div>
                <div>
                  <span class="px-4 py-2 mr-3 status-survey mb-3">{{ data.status }}</span>
                  <span class="px-4 py-2 is-clickable detail-survey" @click="goToDetail(data.id)">Lihat Detail</span>
                </div>
              </div>
            </div>
            <div class="body-survey p-5">
              <div class="columns">
                <div class="column">
                  <p class="title-grey">Toko</p>
                  <p class="averta-bold">{{ data.store_name }}</p>
                </div>
                <div class="column is-7">
                  <p class="title-grey">Alamat</p>
                  <p class="averta-bold text-address">{{ data.store_name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import { ProyekEmptyIcon, DaftarSurvey, LocationIcon, CopasIcon } from './icons'
import CalendarMonth from '../components/calendar/CalendarMonth.vue'
import CalendarWeek from '../components/calendar/CalendarWeek.vue'
import moment from 'moment'

export default {
  name: 'CalendarDashboard',
  props: ['dataCalendarDaily', 'dataCalendarWeekly'],
  components: {
    ProyekEmptyIcon,
    DaftarSurvey,
    LocationIcon,
    CopasIcon,
    CalendarMonth,
    CalendarWeek
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      modalDetail: false,
      changeCalendarToMonth: 1,
      calendarOptionsMonth: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          end: 'prev today next'
        },
        eventClick: this.showEventDetail,
        events: this.dataCalendarWeekly,
        height: 'auto',
        contentHeight: 'auto',
        width: '100%'
      },
      eventsWeek: this.dataCalendarDaily,
      dataModal: []
    }
  },
  watch: {
    dataCalendarWeekly: function (val) {
      this.calendarOptionsMonth.events = val
    },
    dataCalendarDaily: function (val) {
      this.eventsWeek = val
    }
  },
  methods: {
    showEventDetail: function (info) {
      // alert(`${info.event.title}, ${moment(info.event.start).format('DD-MM-YYYY')} - ${moment(info.event.end).format('DD-MM-YYYY')}`)
      const payload = {
        url: this.url,
        start_date: moment(info.event.start).format('YYYY-MM-DD'),
        end_date: moment(info.event.end).format('YYYY-MM-DD')
      }
      this.$store
        .dispatch('dashboard/getDataDetailCalendar', payload)
        .then(response => {
          this.dataModal = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
      this.modalDetail = true
    },
    goToDetail (id) {
      this.$router.push(`/project/survey/${id}/detail`)
    }
  }
}
</script>

<style scoped>
.wrapper-date {
  background: #ffffff;
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 15px;
  border-left: 12px solid #d9272d;
  font-family: "Averta-Bold";
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  margin: 0px 6px;
}

.wrapper-date .wrapper-detail {
  color: #d9272d;
}

.wrapper-date .wrapper-detail svg {
  fill: #d9272d !important;
}

.header-modal {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}

.body-modal {
  background: white;
  padding: 12px 24px;
  border-radius: 0px 0px 12px 12px;
}

.header-survey {
  background: #FAFAFA;
}

.title-grey {
  color: #868686;
}

.text-id-survey {
  color: #d9272d;
}

.status-survey {
  background: #3185FC;
  border-radius: 20px;
  color: white;
  font-family: 'Averta-Bold';
}

.detail-survey {
  border: 1px solid #d9272d;
  border-radius: 20px;
  font-family: 'Averta-Bold';
  color: #d9272d;
}

.text-address {
  color: #d9272d;
}

@media only screen and (max-width: 600px) {
  .body-modal {
    padding-left: 0;
    padding-right: 0;
  }

  .header-card-wrapper {
    flex-direction: column;
    align-items: flex-start !important;
  }
}
</style>
