<template>
  <div>
    <ClientInfo
      currentTab="branch"
      class="mb-5"
      :typeSection="typeSection"
      :name="name"
      :companyName="companyName"
      :logo="logo"
    />
    <BranchInfo currentTab="branch" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BranchInfo from './BranchInfo.vue'
import ClientInfo from './ClientInfo.vue'

export default {
  name: 'Branch',
  components: {
    ClientInfo,
    BranchInfo
  },
  computed: {
    ...mapGetters({
      detailClient: 'client/getPayloadClientSelectedBranch'
    })
  },
  data () {
    return {
      isBlur: false,
      typeSection: '',
      name: '',
      companyName: '',
      logo: ''
    }
  },
  mounted () {
    const path = this.$route.path.split('/')
    const lastElement = path[path.length - 1]

    if (lastElement === 'branch') {
      this.typeSection = 'information'
      if (this.detailClient) {
        this.name = this.detailClient.name
        this.companyName = this.detailClient.company_name
        this.logo = this.detailClient.logo
      }
    }
  }
}
</script>

<style scoped>
.blur {
  filter: blur(3px);
  cursor: none;
}
</style>
