<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b_367_91712)">
      <rect
        width="40"
        height="40"
        rx="20"
        fill="url(#paint0_linear_367_91712)"
        fill-opacity="0.1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8953 19.23C17.4578 19.23 17.1119 19.57 17.1119 20C17.1119 20.42 17.4578 20.77 17.8953 20.77H24V25.55C24 28 21.9753 30 19.4724 30H14.5174C12.0247 30 10 28.01 10 25.56V14.45C10 11.99 12.0349 10 14.5276 10H19.4927C21.9753 10 24 11.99 24 14.44V19.23H17.8953ZM27.6302 16.5402L30.5502 19.4502C30.7002 19.6002 30.7802 19.7902 30.7802 20.0002C30.7802 20.2002 30.7002 20.4002 30.5502 20.5402L27.6302 23.4502C27.4802 23.6002 27.2802 23.6802 27.0902 23.6802C26.8902 23.6802 26.6902 23.6002 26.5402 23.4502C26.2402 23.1502 26.2402 22.6602 26.5402 22.3602L28.1402 20.7702H24.0002V19.2302H28.1402L26.5402 17.6402C26.2402 17.3402 26.2402 16.8502 26.5402 16.5502C26.8402 16.2402 27.3302 16.2402 27.6302 16.5402Z"
        fill="url(#paint1_linear_367_91712)"
      />
      <rect
        x="0.25"
        y="0.25"
        width="39.5"
        height="39.5"
        rx="19.75"
        stroke="url(#paint2_linear_367_91712)"
        stroke-width="0.5"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_367_91712"
        x="-200"
        y="-200"
        width="440"
        height="440"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="100" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_367_91712"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_367_91712"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_367_91712"
        x1="6.00406e-05"
        y1="-7.50005"
        x2="40.0001"
        y2="42"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" stop-opacity="0.6" />
        <stop offset="1" stop-color="#D92727" stop-opacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_367_91712"
        x1="10"
        y1="19.8182"
        x2="26.4971"
        y2="28.224"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_367_91712"
        x1="-25.5"
        y1="-8"
        x2="56"
        y2="53"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EB4600" stop-opacity="0.1" />
        <stop offset="1" stop-color="#EB0000" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'LogoutIcon'
}
</script>
