<template>
  <div>
    <div class="wrap-client">
      <div v-if="!hideBranchAutoComplete">
        <div class="mb-5">
          <p class="averta-black label-20">Informasi Cabang</p>
          <p>
            Masukkan PIC cabang dari brand yang sudah diinput. kamu dapat input
            lebih dari 1 PIC.
          </p>
        </div>
        <div class="is-flex is-justify-content-space-between mb-5">
          <p class="label-14 mr-3 mt-2 white-space-nowrap">
            <span class="averta-bold">Cabang </span>
            <span class="text-merah">*</span>
          </p>
          <BranchAutoComplete />
        </div>
      </div>

      <div :class="{'border-top-dashed pt-5': !hideBranchAutoComplete}">
        <StoreInformation :typeContent="typeContent" />
      </div>
    </div>
  </div>
</template>
<script>
import BranchAutoComplete from '../branch-components/BranchAutoComplete.vue'
import StoreInformation from './StoreInformation.vue'

export default {
  name: 'Store',
  props: {
    hideBranchAutoComplete: {
      type: Boolean,
      default: false
    },
    typeContent: {
      type: String,
      default: null
    }
  },
  components: {
    BranchAutoComplete,
    StoreInformation
  }
}
</script>
