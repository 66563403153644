<template>
    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_954_185423)">
            <path
                d="M18.9997 22.105H18.3472C17.4568 22.0728 16.5692 22.224 15.7397 22.5491C14.9101 22.8742 14.1562 23.3664 13.5247 23.995L13.3447 24.205V30.415H16.4047V26.89L16.8172 26.425L17.0047 26.2075C17.9812 25.2044 19.1968 24.4662 20.5372 24.0625C19.8659 23.5521 19.3366 22.8782 18.9997 22.105Z"
                fill="white" />
            <path
                d="M33.5052 23.9725C32.8738 23.3439 32.1199 22.8517 31.2903 22.5266C30.4607 22.2015 29.5732 22.0503 28.6827 22.0825C28.4096 22.0833 28.1368 22.0983 27.8652 22.1275C27.5218 22.8527 27.007 23.4833 26.3652 23.965C27.7962 24.3609 29.0925 25.1387 30.1152 26.215L30.3027 26.425L30.7077 26.89V30.4225H33.6627V24.1825L33.5052 23.9725Z"
                fill="white" />
            <path
                d="M18.3249 20.6425H18.5574C18.4494 19.7149 18.6121 18.7759 19.026 17.9388C19.4399 17.1017 20.0873 16.4023 20.8899 15.925C20.599 15.4805 20.1976 15.1192 19.725 14.8765C19.2525 14.6337 18.725 14.5179 18.1942 14.5402C17.6634 14.5626 17.1476 14.7224 16.6971 15.0041C16.2467 15.2857 15.8771 15.6795 15.6246 16.1469C15.372 16.6143 15.2452 17.1392 15.2566 17.6704C15.2679 18.2015 15.4169 18.7206 15.6891 19.1768C15.9614 19.633 16.3474 20.0107 16.8094 20.2729C17.2715 20.5351 17.7937 20.6728 18.3249 20.6725V20.6425Z"
                fill="white" />
            <path
                d="M23.4023 23.44C25.2538 23.44 26.7548 21.939 26.7548 20.0875C26.7548 18.236 25.2538 16.735 23.4023 16.735C21.5508 16.735 20.0498 18.236 20.0498 20.0875C20.0498 21.939 21.5508 23.44 23.4023 23.44Z"
                fill="white" />
            <path
                d="M23.5825 25.225C22.603 25.1855 21.6257 25.3445 20.7093 25.6925C19.7929 26.0405 18.9563 26.5703 18.25 27.25L18.0625 27.46V32.2075C18.0654 32.3622 18.0988 32.5147 18.1607 32.6564C18.2226 32.7982 18.3118 32.9264 18.4232 33.0336C18.5347 33.1409 18.6661 33.2251 18.8101 33.2816C18.9541 33.338 19.1079 33.3655 19.2625 33.3625H27.88C28.0346 33.3655 28.1884 33.338 28.3324 33.2816C28.4764 33.2251 28.6078 33.1409 28.7193 33.0336C28.8307 32.9264 28.9199 32.7982 28.9818 32.6564C29.0437 32.5147 29.0771 32.3622 29.08 32.2075V27.475L28.9 27.25C28.1982 26.5682 27.3646 26.0369 26.4502 25.6887C25.5359 25.3405 24.56 25.1827 23.5825 25.225Z"
                fill="white" />
            <path
                d="M28.3225 20.08C28.3316 20.2524 28.3316 20.4251 28.3225 20.5975C28.4665 20.6203 28.6118 20.6328 28.7575 20.635H28.9C29.5975 20.6095 30.2649 20.3447 30.79 19.885C29.7591 19.5503 28.8468 18.9249 28.1629 18.084C27.479 17.2431 27.0526 16.2225 26.935 15.145C26.6047 15.3733 26.322 15.6637 26.1025 16C26.7814 16.4432 27.3395 17.048 27.727 17.7601C28.1145 18.4722 28.3191 19.2693 28.3225 20.08Z"
                fill="white" />
            <path
                d="M32.5 18.25C34.5711 18.25 36.25 16.5711 36.25 14.5C36.25 12.4289 34.5711 10.75 32.5 10.75C30.4289 10.75 28.75 12.4289 28.75 14.5C28.75 16.5711 30.4289 18.25 32.5 18.25Z"
                fill="white" />
        </g>
        <rect x="0.5" y="0.5" width="46" height="46" rx="23" stroke="white" />
        <defs>
            <clipPath id="clip0_954_185423">
                <rect width="27" height="27" fill="white" transform="translate(10 10)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>

export default {
  name: 'GroupingSkill'
}
</script>
