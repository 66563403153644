<template>
  <div class="select-wrapper">
    <div
      class="wrap-selected is-flex is-align-items-center is-clickable"
      @click="showDropdownFunc"
      ref="selectClient"
      v-closable="{
        exclude: ['selectClient', 'searchClient'],
        handler: 'hideDropdown',
      }"
    >
      <div v-if="clientSelected !== ''">
        <p class="averta-bold">{{ clientSelected.name }}</p>
        <p class="text-grey">{{ clientSelected.company_name }}</p>
      </div>
      <p v-else>Pilih PT. / Brand</p>
      <img :src="require('@/assets/img/arrow-down-1.png')" class="arrow-autocomplete-1" :class="{'open': showDropdown}" style="top: 30px;" />
    </div>
    <div :style="{ display: showDropdown ? 'block' : 'none' }">
      <div class="form-input">
        <div ref="searchClient">
          <b-input
            class="input-search"
            placeholder="Cari"
            type="search"
            icon="magnify"
            @input="searchClientFunc"
            v-model="searchClient"
          />
        </div>
        <div class="list-client">
          <div
            class="client-item"
            :class="{ 'active': clientSelected === client }"
            v-for="(client, index) in listClientFilter"
            :key="index"
            @click="selectClientFunc(client)"
          >
            <p class="averta-bold label-14">{{ client.name }}</p>
            <p class="label-14">{{ client.company_name }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Close Confirm -->
    <ModalConfirm
        :showModal="showModal"
        imageProps="sunset.png"
        titleProps="Ganti Client?"
        descProps="Data yang sudah diinput akan dihapus oleh sistem"
      >
        <template v-slot:button>
          <div class="is-flex is-align-items-center">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="closeModal"
            >
              Batalkan
            </ButtonApp>
            <ButtonApp
              :is-bold="true"
              width="185px"
              height="52px"
              @click="confirmModal"
            >
              Lanjutkan
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
      <!-- Modal Close Confirm -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalConfirm from '../ModalConfirm.vue'

export default {
  name: 'ClientAutocomplete',
  components: {
    ModalConfirm,
    ButtonApp
  },
  props: {
    currentTab: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      payloadAddBranch: 'client/getPayloadAddBranch',
      payloadAddPicBranch: 'client/getPayloadAddPicBranch',
      clearDataPayloadBranch: 'client/getClearDataPayloadBranch',
      clearDataPayloadPicBranch: 'client/getClearDataPayloadPicBranch'
    })
  },
  data () {
    return {
      showDropdown: false,
      showModal: false,
      clientSelected: '',
      changeClientTemp: '',
      searchClient: '',
      listClient: [],
      listClientFilter: []
    }
  },
  methods: {
    getListVendor () {
      const payload = {
        url: this.url
      }

      this.$store
        .dispatch('client/getListVendor', payload)
        .then(response => {
          this.listClient = response.data.data.list
          this.listClientFilter = response.data.data.list
        })
        .catch(error => {
          alertErrorMessage(error)
        })
    },
    closeModal () {
      this.showModal = false
    },
    showDropdownFunc () {
      this.showDropdown = !this.showDropdown
    },
    hideDropdown () {
      this.showDropdown = false
    },
    selectClientFunc (client) {
      if (Object.keys(this.clientSelected).length > 0 && this.payloadAddBranch.branchName !== '') {
        if (this.clientSelected !== client) {
          this.changeClientTemp = client
          this.changeClient()
        }
      } else if (Object.keys(this.clientSelected).length > 0 && Object.keys(this.payloadAddPicBranch.branchSelected).length > 0) {
        if (this.clientSelected !== client) {
          this.changeClientTemp = client
          this.changeClient()
        }
      } else if (this.clientSelected === '' || (Object.keys(this.clientSelected).length > 0 && this.payloadAddBranch.branchName === '')) {
        const storeToVuex = {
          type: 'clientSelected',
          clientSelected: client
        }

        if (this.currentTab === 'branch') {
          this.$store.commit('client/setPayloadAddBranch', storeToVuex)
        } else if (this.currentTab === 'pic') {
          this.$store.commit('client/setPayloadAddPicBranch', storeToVuex)
        }

        this.clientSelected = client
      }
      this.showDropdown = false
      this.searchClient = ''
    },
    changeClient () {
      this.showModal = true
    },
    searchClientFunc () {
      const searchClient = this.listClient.filter((client) => {
        return client.name.toLowerCase().includes(this.searchClient.toLowerCase())
      })

      this.listClientFilter = searchClient
    },
    confirmModal () {
      const storeToVuex = {
        type: 'changeClient',
        data: {
          clientSelected: this.changeClientTemp,
          dataPic: [
            {
              name: '',
              email: '',
              phone: ''
            }
          ]
        }
      }

      if (this.currentTab === 'branch') {
        storeToVuex.data.branchName = ''
        this.$store.commit('client/setPayloadAddBranch', storeToVuex)
      } else if (this.currentTab === 'pic') {
        storeToVuex.data.branchSelected = {}
        storeToVuex.data.changeClient = true
        this.$store.commit('client/setPayloadAddPicBranch', storeToVuex)
      }

      this.clientSelected = this.changeClientTemp
      this.showModal = false
      this.showDropdown = false
    }
  },
  mounted () {
    this.getListVendor()
  },
  watch: {
    searchClient (newValue) {
      if (newValue === '') {
        this.listClientFilter = this.listClient
      }
    },
    clearDataPayloadBranch (newValue) {
      if (newValue === true) {
        this.clientSelected = ''
        this.searchClient = ''
        this.showDropdown = false
      }
    },
    clearDataPayloadPicBranch (newValue) {
      if (newValue === true) {
        this.clientSelected = ''
        this.searchClient = ''
        this.showDropdown = false
      }
    }
  }
}
</script>

<style scoped>
.select-wrapper {
  width: 100%;
  position: relative;
}

.wrap-selected {
  border: 1px solid #e1e1e1;
  background: white;
  border-radius: 8px;
  padding: 8px 12px;
  width: 100%;
  height: 70px;
}

.form-input {
  background: white;
  border-radius: 8px;
  padding: 8px 12px;
}

.list-client {
  max-height: 387px;
  overflow-y: auto;
}

.client-item {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
}

.client-item:first-child {
  margin-top: 10px;
}

.client-item:hover, .client-item.active {
  background: #fcecec;
}
</style>
