<template>
  <div v-if="confirmBy">
    <b-notification aria-close-label="Close notification" class="background-review"
      v-if="confirmBy.is_detail_offering_change === false && (confirmBy.review_by === 'sm' || confirmBy.review_by === 'am')">
      <p class="averta-bold">Sedang Direview {{ confirmByRole(confirmBy.review_by) }}</p>
      <p>Penawaran yang kamu buat sedang direview oleh {{ confirmByRole(confirmBy.review_by) }}. Mohon menunggu. Butuh
        bantuan?
        <span class="averta-bold is-clickable">Hubungi {{ confirmByRole(confirmBy.review_by) }}</span>
      </p>
    </b-notification>
    <b-notification aria-close-label="Close notification" class="background-change"
      v-if="confirmBy.is_detail_offering_change === true">
      <div>
        <p class="averta-bold">Detail Penawaran Mengalami Perubahan</p>
        <p>Penawaran telah diubah oleh {{ confirmByRole(confirmBy.review_by) }}. Mohon menunggu Butuh bantuan?
          <span class="averta-bold is-clickable">Hubungi {{ confirmByRole(confirmBy.review_by) }}</span>
        </p>
      </div>
    </b-notification>
    <b-notification aria-close-label="Close notification" class="background-approved"
      v-if="confirmBy.review_by === 'purchasing'">
      <div>
        <p class="averta-bold">Penawaran Telah Di-approved oleh Site Manager</p>
        <p>Penawaran telah diteruskan ke purchasing. Butuh bantuan?
          <span class="averta-bold is-clickable">Hubungi Site Manager</span>
        </p>
      </div>
    </b-notification>
  </div>
</template>

<script>
export default {
  name: 'CardReviewOffering',
  props: ['confirmBy'],
  methods: {
    confirmByRole (role) {
      if (role === 'sm') {
        return 'Site Manager'
      } else if (role === 'am') {
        return 'Business Support'
      } else if (role === 'purchasing') {
        return 'Purchasing'
      }
    }
  }
}
</script>

<style scoped>
.background-review {
  background: rgba(49, 133, 252, 0.1);
  border: 1px solid #3185FC;
  border-radius: 12px;
}

.background-change {
  background: linear-gradient(90deg, #FFDDDD 0%, #FFEFDB 99.97%) !important;
  border-radius: 12px !important;
}

.background-approved {
  background: linear-gradient(90deg, #10BD41 0%, #55F883 100%);
  border-radius: 12px;
  color: white;
}
</style>
