<template>
  <b-modal
    width="96vw"
    v-model="isModalActive"
    :can-cancel="false"
    :on-cancel="closeModal"
    class="modal-preview"
  >
    <div class="content px-5">
      <div class="is-flex is-justify-content-space-between is-absolute c-w-95">
        <p class="averta-bold has-text-white mb-0 z-index-5">{{ title }}</p>
        <div class="is-clickable z-index-5" @click="closeModal">
          <b-icon icon="close" type="is-white"></b-icon>
        </div>
      </div>
      <div
        v-if="urlOneImage && typeof urlOneImage === 'string'"
      >
        <!-- @click.native="zoomInFunc2" -->
        <div class="zoom-in-wrapper">
          <!-- <img :src="urlOneImage" /> -->
          <ZoomImage :imageSrc="urlOneImage"/>
        </div>
      </div>
      <div
        class="wrapper-carousel"
        v-else-if="image && image instanceof Array && image.length > 0"
      >
        <b-carousel
          v-model="currentSlide"
          :autoplay="false"
          indicator-custom
          :indicator="zoomIn ? false : true"
          :indicator-inside="false"
          :arrow="zoomIn ? false : true"
          icon-size="is-medium"
          @change="handleChangeCarousel($event)"
        >
          <b-carousel-item
            v-for="(item, index) in image"
            :key="`preview-image-${index}`"
            class="zoom-in-wrapper"
          >
            <!-- @click="zoomInFunc" -->
            <!-- <div class="preview-image" :class="{ 'zoom-in': zoomIn }">
              <img :src="item" />
            </div> -->
            <ZoomImage :imageSrc="item.image_url || item" :currentSlide="currentSlide" />
          </b-carousel-item>
          <template #indicators="props" v-if="!zoomIn">
            <figure class="al image p-0" :draggable="false">
              <img
                :draggable="false"
                :src="image[props.i].image_url || image[props.i]"
                :title="props.i"
                class="list-image"
              />
            </figure>
          </template>
        </b-carousel>
      </div>
    </div>
  </b-modal>
</template>

<script>
import ZoomImage from '../image/ZoomImage.vue'
export default {
  components: {
    ZoomImage
  },
  name: 'ModalPreviewImage',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    indexImg: {
      type: Number,
      default: 0
    },
    dataImage: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    urlOneImage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isModalActive: false,
      image: null,
      textTitle: '',
      zoomIn: false,
      currentSlide: 0
    }
  },
  watch: {
    isActive (newValue) {
      this.isModalActive = newValue
      this.currentSlide = this.indexImg
      if (!newValue) {
        this.currentSlide = 0
      }
    },
    dataImage (newValue) {
      if (newValue) {
        const filteredArr = newValue.filter(item =>
          item !== null && !(typeof item === 'object' && Object.keys(item).length === 0)
        )

        this.image = filteredArr
      }
    },
    title (newValue) {
      this.textTitle = newValue
    }
  },
  methods: {
    closeModal () {
      this.zoomIn = false
      this.$emit('closeModal')
    },
    zoomInFunc () {
      if (this.zoomIn === false) {
        this.zoomIn = true
      } else {
        this.zoomIn = false
      }
    },
    handleChangeCarousel (event) {
      this.zoomIn = false
      this.currentSlide = event
    }
  }
}
</script>

<style>
.is-active .al.image {
  filter: brightness(1) !important;
}

.al.image {
  filter: brightness(0.7) !important;
}
</style>

<style scoped>
.modal-preview {
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  width: 100%;
  overflow-y: hidden;
  height: 95vh;
}

.wrapper-carousel {
  /* padding: 12px 40px; */
  height: 100%;
  text-align: center;
}

.preview-image {
  border-radius: 10px;
  height: 75vh;
  margin-bottom: 10px;
  margin-top: -10px;
}

.preview-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: zoom-in;
  transition: width 0.5s, height 0.5s;
}

.preview-image.zoom-in {
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
}

.preview-image.zoom-in img {
  height: 150%;
  width: 200%;
  cursor: zoom-out;
  /* object-fit: fill; */
}

.zoom-in-wrapper {
  height: 100%;
}

.al {
  margin: 0px 1px;
  padding: 0;
}

.list-image {
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .wrapper-carousel {
    padding: 0px;
  }
  .preview-image {
    border-radius: 12px;
    height: 45vh;
    width: 100% !important;
  }

  .al.image img {
    max-width: 100px !important;
    width: 100px;
  }
}
</style>

<style>
.carousel .carousel-indicator.has-custom {
  justify-content: center !important;
}

.carousel .carousel-indicator.has-custom.is-small .indicator-item {
  flex: none !important;
}

.modal-preview .modal-content {
  overflow-y: hidden;
}

.wrapper-carousel .mdi {
  color: #252525;
}

.wrapper-carousel .icon {
  border-color: #252525;
  opacity: 0.5;
}

.wrapper-carousel .icon:hover {
  border-color: #252525;
}
</style>
