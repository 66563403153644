<template>
  <div class="is-relative">
      <!-- v-if="showDropdown" -->
    <div
      class="dropdown-lampiran"
    >
      <!-- Lihat Lampiran -->
      <div
        class="is-flex is-align-items-center is-justify-content-space-between is-clickable option-wrapper"
        @click="handlePreview"
      >
        <div class="is-flex is-align-items-center">
          <div
            class="wrap-icon is-flex is-align-items-center is-justify-content-center mr-3"
          >
            <EyeIcon />
          </div>
          <p class="label-14 averta-bold">Lihat Lampiran</p>
        </div>
        <div class="is-flex is-align-items-center">
          <img :src="require('@/assets/img/arrow-right-grey.png')" alt="">
        </div>
      </div>
      <!-- Lihat Lampiran -->

      <div class="divider"></div>

      <!-- Download Lampiran -->
      <div
        class="is-flex is-align-items-center is-justify-content-space-between is-clickable option-wrapper"
        @click="handleDownload"
      >
        <div class="is-flex is-align-items-center">
          <div
            class="wrap-icon is-flex is-align-items-center is-justify-content-center mr-3"
          >
            <DownloadIcon />
          </div>
          <p class="unduh label-14 averta-bold" target="_blank">
            Unduh Lampiran
          </p>
        </div>
        <div class="is-flex is-align-items-center">
          <img :src="require('@/assets/img/arrow-right-grey.png')" alt="">
        </div>
      </div>
      <!-- Download Lampiran -->

      <div class="divider"></div>

      <!-- Ubah Lampiran -->
      <div
        class="is-flex is-align-items-center is-justify-content-space-between is-clickable option-wrapper"
        @click="handleEdit"
      >
        <div class="is-flex is-align-items-center">
          <div
            class="wrap-icon is-flex is-align-items-center is-justify-content-center mr-3"
          >
            <EditIcon />
          </div>
          <p class="unduh label-14 averta-bold" target="_blank">
            Ubah Lampiran
          </p>
        </div>
        <div class="is-flex is-align-items-center">
          <img :src="require('@/assets/img/arrow-right-grey.png')" alt="">
        </div>
      </div>
      <!-- Ubah Lampiran -->

      <div>
        <div class="divider"></div>
        <!-- Riwayat -->
        <div
          class="is-flex is-align-items-center is-justify-content-space-between is-clickable option-wrapper"
          @click="handleHistory"
        >
          <div class="is-flex is-align-items-center">
            <div
              class="wrap-icon is-flex is-align-items-center is-justify-content-center mr-3"
            >
              <HistoryLineIcon />
            </div>
            <p class="unduh label-14 averta-bold" target="_blank">
              Lihat Riwayat Perubahan
            </p>
          </div>
          <div class="is-flex is-align-items-center">
            <img :src="require('@/assets/img/arrow-right-grey.png')" alt="">
          </div>
        </div>
        <!-- Riwayat -->
      </div>
    </div>

    <ModalEditAttachment
      :type="type"
      :data="dataAttachment"
      :surveyId="surveyId"
      :showModal="showModalEdit"
      :refreshData="refreshData"
      :getHistory="getHistory"
      @closeModal="closeModalEdit"
    />

    <ModalHistoryAttachment
      :dataHistory="dataHistory"
      @closeModal="closeModalHistory"
      :showModalHistory="showModalHistory"
    />

    <ModalPreviewImage
      :isActive="modalPreview"
      :dataImage="imagePreview"
      :title="titlePreview"
      @closeModal="closeModalPreviewImage()"
    />

    <b-loading v-model="checkLoading" :is-full-page="true" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import {
  EyeIcon,
  DownloadIcon,
  EditIcon,
  SpinnerIcon,
  HistoryLineIcon
} from '@/components/icons'
import { formatDateHelper } from '@/helpers'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import ModalEditAttachment from '@/components/modal/ModalEditAttachment'
import ModalHistoryAttachment from '@/components/modal/ModalHistoryAttachment.vue'

export default {
  name: 'DropdownAttachment',
  props: {
    surveyId: {
      type: [String, Number]
    },
    type: {
      type: String,
      required: true
    },
    showDropdown: {
      type: Boolean,
      required: true
    },
    dataAttachment: {
      type: Object,
      required: true
    },
    dataHistory: {
      type: Array,
      required: true
    },
    refreshData: {
      type: Function
    },
    getHistory: {
      type: Function
    }
  },
  components: {
    EyeIcon,
    DownloadIcon,
    EditIcon,
    SpinnerIcon,
    HistoryLineIcon,

    ModalPreviewImage,
    ModalEditAttachment,
    ModalHistoryAttachment
  },
  data () {
    return {
      isLoadingDownload: false,
      isLoadingHistory: false,

      showModalEdit: false,
      showModalHistory: false,
      modalPreview: false,
      imagePreview: null,
      titlePreview: null,

      data: null,
      attachments: {
        note: '',
        fileAttachment: null,
        previewAttachment: null
      }
    }
  },
  computed: {
    disabledButton () {
      return !this.attachments.fileAttachment
    },
    checkLoading () {
      return this.isLoadingDownload || this.isLoadingSubmit
    }
  },
  methods: {
    setState () {
      this.data = JSON.parse(JSON.stringify(this.dataAttachment))
    },
    closeModalEdit () {
      this.showModalEdit = false
    },
    closeModalPreviewImage () {
      this.modalPreview = false
    },
    closeModalHistory () {
      this.showModalHistory = false
    },
    handlePreview () {
      this.setState()
      this.imagePreview = [this.data.filename]
      this.titlePreview = `Lampiran ${this.data.type}`
      this.modalPreview = true
      this.$emit('closeDropdown')
    },
    handleDownload () {
      this.setState()
      this.isLoadingDownload = true
      const url = this.data.filename
      const name = this.data.name

      const sliceExtension = url.slice(url.lastIndexOf('/') + 1)
      const extension = sliceExtension.split('.')[1]
      const finalExtension = extension.split('?')[0]

      fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
          const linkURL = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = linkURL

          a.download = `${name}.${finalExtension.split('_')[0]}` // prevent jpg_[prefix backend]
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          this.isLoadingDownload = false
          this.$emit('closeDropdown')
        })
        .catch(() => {
          this.isLoadingDownload = false
          alert('Maaf ada masalah, silahkan coba lagi')
        })
    },
    handleEdit () {
      this.showModalEdit = true
      this.setState()
      this.$emit('closeDropdown')
    },
    handleHistory () {
      this.showModalHistory = true
      this.$emit('closeDropdown')
    },
    formatDate (date) {
      return formatDateHelper({ date })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.dropdown-lampiran {
  position: absolute;
  right: 25px;
  top: -10px;
  width: 240px;
  background: white;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  overflow: hidden;
}

.wrap-icon {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #F5F5F5;
  flex-shrink: 0;
}

.divider {
  border-top: 1px solid #E1E1E1;
}

.option-wrapper {
  padding: 10px 15px;
}

.option-wrapper:hover {
  background: $color-grey-8;
}

</style>
