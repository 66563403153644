<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_954_205102)">
      <mask
        id="mask0_954_205102"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path d="M0 0H20V20H0V0Z" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.3014 11.2033C10.132 11.0932 9.98591 10.9508 9.87153 10.7842C9.75715 10.6176 9.6767 10.4302 9.63477 10.2325C9.59284 10.0349 9.59025 9.83092 9.62716 9.63227C9.66406 9.43363 9.73973 9.24419 9.84985 9.07479L11.5275 6.49556C11.6359 6.32232 11.7776 6.1724 11.9445 6.05454C12.1114 5.93668 12.3001 5.85324 12.4996 5.8091C12.6991 5.76497 12.9054 5.76101 13.1065 5.79746C13.3075 5.83392 13.4993 5.91005 13.6706 6.02142C13.8419 6.1328 13.9893 6.27717 14.1042 6.44613C14.219 6.61509 14.2991 6.80524 14.3397 7.00548C14.3804 7.20572 14.3807 7.41205 14.3407 7.61242C14.3007 7.81279 14.2212 8.00318 14.1068 8.17248L12.4299 10.7525C12.2074 11.0945 11.8581 11.3341 11.459 11.4187C11.0598 11.5032 10.6434 11.4257 10.3014 11.2033ZM8.42293 11.3586C8.1647 10.8733 8.04127 10.3276 8.06549 9.7783C8.0897 9.22905 8.26068 8.69632 8.56062 8.23556L10.2375 5.65633C10.4579 5.31757 10.7427 5.02552 11.0759 4.79686C11.4091 4.5682 11.7841 4.4074 12.1794 4.32365C12.5747 4.2399 12.9827 4.23483 13.38 4.30874C13.7773 4.38265 14.1561 4.53409 14.4949 4.75441C14.8336 4.97472 15.1257 5.25961 15.3543 5.59279C15.583 5.92598 15.7438 6.30094 15.8275 6.69626C15.9113 7.09159 15.9163 7.49955 15.8424 7.89683C15.7685 8.29412 15.6171 8.67295 15.3968 9.01171L13.7199 11.5909C13.4281 12.04 13.0241 12.4051 12.548 12.6501C12.0719 12.8952 11.54 13.0118 11.005 12.9883C10.47 12.9649 9.95041 12.8022 9.49756 12.5164C9.04471 12.2305 8.67429 11.8315 8.42293 11.3586Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.49475 14.2334C6.32534 14.1233 6.17927 13.9809 6.06489 13.8144C5.95051 13.6478 5.87006 13.4604 5.82813 13.2627C5.7862 13.0651 5.78361 12.8611 5.82052 12.6624C5.85742 12.4638 5.93309 12.2744 6.04321 12.105L7.72091 9.52573C7.82923 9.35249 7.97099 9.20256 8.13789 9.0847C8.30479 8.96684 8.49349 8.88341 8.69299 8.83927C8.89249 8.79513 9.09877 8.79118 9.29982 8.82763C9.50086 8.86408 9.69263 8.94022 9.86392 9.05159C10.0352 9.16296 10.1826 9.30734 10.2975 9.4763C10.4124 9.64525 10.4925 9.8354 10.5331 10.0356C10.5737 10.2359 10.574 10.4422 10.534 10.6426C10.494 10.843 10.4145 11.0333 10.3001 11.2026L8.62321 13.7826C8.40073 14.1247 8.0515 14.3643 7.65234 14.4488C7.25318 14.5334 6.83678 14.4559 6.49475 14.2334ZM4.61629 14.3888C4.35806 13.9034 4.23463 13.3577 4.25885 12.8085C4.28306 12.2592 4.45404 11.7265 4.75398 11.2657L6.43091 8.68727C6.87576 8.00311 7.57417 7.52369 8.37249 7.35447C8.76779 7.27069 9.17571 7.26558 9.57297 7.33944C9.97024 7.4133 10.3491 7.56469 10.6878 7.78496C11.0266 8.00522 11.3186 8.29006 11.5473 8.6232C11.776 8.95633 11.9368 9.33125 12.0206 9.72655C12.1044 10.1218 12.1095 10.5298 12.0357 10.927C11.9618 11.3243 11.8104 11.7031 11.5901 12.0419L9.91321 14.6211C9.62142 15.0701 9.21749 15.4352 8.74136 15.6803C8.26522 15.9254 7.73337 16.042 7.19837 16.0185C6.66338 15.995 6.14377 15.8324 5.69092 15.5465C5.23807 15.2607 4.86765 14.8617 4.61629 14.3888Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_954_205102)">
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          :fill="color"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_954_205102">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'GroupingIconFilled',
  props: ['color']
}
</script>
