<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.625 12.75L13.875 16.5L11.25 13.875L12.375 12.75L13.875 14.25L16.5 11.625L17.625 12.75ZM4.5 1.5C3.6675 1.5 3 2.1675 3 3V15C3 15.8325 3.6675 16.5 4.5 16.5H10.3575C9.96 15.8175 9.75 15.0375 9.75 14.25C9.75 14.0025 9.7725 13.7475 9.81 13.5H4.5V12H10.3575C10.7025 11.4 11.1825 10.875 11.76 10.5H4.5V9H13.5V9.81C13.7475 9.7725 14.0025 9.75 14.25 9.75C14.505 9.75 14.7525 9.7725 15 9.81V6L10.5 1.5M9.75 2.625L13.875 6.75H9.75V2.625Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'DocumentCheckIcon'
}
</script>
