<template>
  <TableSkeleton v-if="isLoading" class="mb-6" />
  <div v-else>
    <div class="min-h-60vh">
      <div class="is-flex is-justify-content-space-between mb-5 mt-3">
        <div class="wrapper-search">
          <b-field>
            <b-input placeholder="Cari Nama Area"
                type="search"
                icon="magnify"
                icon-clickable
                @icon-click="getFilterDataArea(1)"
                @keyup.native.enter="getFilterDataArea(1)"
                v-model="search"
                >
            </b-input>
          </b-field>
        </div>
        <div>
          <button @click="handleModalComponent" class="btn-tambah averta-bold is-clickable">
            + Tambah Area
          </button>
        </div>
      </div>
      <div>
        <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
          <tr class="bg-dark-grey-5 has-text-white has-text-centered averta-bold">
            <td class="py-4" style="width: 5%">NO</td>
            <td class="py-4">ID AREA</td>
            <td class="py-4" style="width: 28%">NAMA AREA</td>
            <td class="py-4" style="width: 30%">AREA KOMPONEN</td>
            <td class="py-4" style="width: 20%">STATUS</td>
            <td class="py-4" style="width: 8%"></td>
          </tr>
          <tr v-for="(itemDetail, indexDetail) in dataComponent" :key="itemDetail.id" class="tr-wrapper" :class="{'bg-lightgray': (indexDetail + 1) % 2 === 0}">
            <td>
              <div class="py-3 has-text-centered">
                {{currentPage === 1 ? indexDetail + 1 : indexDetail + 1 === 10 ? `${currentPage}0` : `${currentPage - 1}${indexDetail+1}`}}
              </div>
            </td>
            <td>
              <div class="py-3 is-flex is-justify-content-space-between averta-bold text-merah">
                <p>#ID{{itemDetail.id}}</p>
                <span class="cursor-pointer" @click="handleCopyText(itemDetail.id)">
                  <CopasIcon />
                </span>
              </div>
            </td>
            <td>
              <div class="py-3">
                <p @click="handleDetail(itemDetail.id)" class="cursor-pointer text-red averta-bold">{{itemDetail.name}}</p>
              </div>
            </td>
            <td>
              <div class="my-3 area-name-wrapper">
                <span class="averta-bold" v-for="(component, index) in itemDetail.components" :key="index">
                  {{ component.name }}{{index +1 === itemDetail.components.length ? '' : ','}}
                </span>
              </div>
            </td>
            <td>
              <div class="py-3 status-wrapper">
                <p :class="{'status-active' : itemDetail.is_active === 1, 'non-active' : itemDetail.is_active === 0}">{{itemDetail.is_active === 1 ? 'Aktif' : 'Tidak Aktif'}}</p>
              </div>
            </td>
            <td>
              <div class="py-3 is-flex is-justify-content-space-evenly is-align-items-center">
                <span @click="handleEditItem(itemDetail.id)" class="cursor-pointer">
                  <img :src="require('@/assets/img/icon-edit.png')" alt="">
                </span>
                <span @click="handleDeleteItem(itemDetail.id)" class="cursor-pointer">
                  <img :src="require('@/assets/img/icon-delete.png')" alt="">
                </span>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <ModalArea :type="typeModalArea" :id="modalId" ref="formArea" :showModal="showModal" @closeModal="$event => handleCloseModalArea($event)" @showModalComponent="$event => handleShowModalComponent($event)" />
    <ModalComponent :showModal="showModalComponent" :isAreaProps="true" @closeModal="$event => handleCloseModalComponent($event)" />
    <ModalDetailArea :showModal="showModalDetail" :id="modalId" @closeModal="$event => handleCloseModalDetail($event)" />

    <PaginationApp
      :total-pages="totalPages"
      :current-page="currentPage"
      :load-page="search !== null ? getFilterDataArea : getDataArea"
      class="my-6"
      :limit-data="10"
      :total-data="totalData"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CopasIcon } from '@/components/icons'
import ModalArea from '../components/ModalArea.vue'
import ModalDetailArea from '../components/ModalDetailArea.vue'
import ModalComponent from '../components/ModalComponent.vue'
import TableSkeleton from '@/components/skeleton/TableSkeleton'
import PaginationApp from '@/components/PaginationApp.vue'

export default {
  name: 'DataArea',
  components: {
    CopasIcon,
    ModalArea,
    ModalDetailArea,
    ModalComponent,
    TableSkeleton,
    PaginationApp
  },
  data () {
    return {
      showModal: false,
      showModalDetail: false,
      showModalComponent: false,
      dataComponent: [],
      isLoading: false,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      search: null,
      typeModalArea: 'create-area',
      modalId: null,
      dataDetailArea: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    handleModalComponent () {
      this.typeModalArea = 'create-area'
      this.showModal = !this.showModal
    },
    handleCloseModalArea (event) {
      if (event === 'success-add-area') {
        this.getDataArea(1)
      }
      this.modalId = null
      this.showModalComponent = false
      this.showModal = false
    },
    handleCloseModalComponent (event) {
      if (event === 'success-add-component') {
        this.$refs.formArea.getDetailArea()
      } else if (event === 'success-area-props') {
        if (this.typeModalArea === 'create-area') {
          this.$refs.formArea.getComponents(null, event)
        } else {
          this.$refs.formArea.getDetailArea(null, event)
        }
      }
      this.showModalComponent = false
      this.showModal = true
    },
    handleCloseModalDetail (event) {
      this.modalId = null
      this.showModalDetail = false
    },
    handleShowModalComponent (event) {
      this.showModal = false
      this.showModalComponent = true
    },
    handleDeleteItem (id) {
      this.$swal({
        imageUrl: require('@/assets/img/delete-area-component.png'),
        imageAlt: 'image delete area component',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Hapus Area?</p>',
        html: '<p>Pastikan kamu sudah cek kembali area ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            id,
            type: 'area'
          }
          this.$store
            .dispatch('settings/deleteData', payload)
            .then(response => {
              this.getDataArea()
            })
            .catch(error => {
              this.isLoading = false
              console.log(error)
              if (error.response.data.message) {
                this.$swal({
                  imageUrl: require('@/assets/img/sunset.png'),
                  imageAlt: 'image cannot delete',
                  width: '600px',
                  padding: '10px 10px 30px 10px',
                  title: '<p class="averta-bold label-22">Area Tidak Dapat Dihapus</p>',
                  html: `<p>${error.response.data.message}</p>`,
                  confirmButtonText: 'Tutup',
                  customClass: {
                    confirmButton: 'btn-hapus-detail-component'
                  }
                })
              } else {
                this.$buefy.toast.open({
                  message: error.response.data.message ||
              '<p class="averta-regular">Gagal menghapus area, silahkan coba lagi</p>',
                  type: 'is-danger'
                })
              }
            })
        }
      })
    },
    handleEditItem (id) {
      this.modalId = id
      this.typeModalArea = 'update-area'
      this.showModal = !this.showModal
    },
    handleDetail (id) {
      this.modalId = id
      this.showModalDetail = !this.showModalDetail
    },
    async handleCopyText (text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$buefy.toast.open({
          message:
            '<p class="averta-bold">Tersalin!</p>',
          type: 'is-success'
        })
      } catch ($e) {
        this.$buefy.toast.open({
          message:
            '<p class="averta-regular">Gagal, Silahkan coba lagi</p>',
          type: 'is-success'
        })
      }
    },
    getDataArea (page) {
      this.isLoading = true
      const payload = {
        url: this.url,
        page
      }
      this.$store
        .dispatch('settings/getDataArea', payload)
        .then(response => {
          this.dataComponent = response.data.data
          this.totalPages = response.data.total_page
          this.totalData = response.data.total_data
          this.currentPage = response.data.current_page
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message:
            '<p class="averta-regular">Gagal memuat area, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    },
    getFilterDataArea (pages) {
      const payload = {
        url: this.url,
        search: this.search,
        page: pages
      }

      this.$store
        .dispatch('settings/getDataArea', payload)
        .then(response => {
          this.dataComponent = response.data.data
          this.totalPages = response.data.total_page
          this.totalData = response.data.total_data
          this.currentPage = response.data.current_page
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    this.getDataArea()
  },
  watch: {
    search: function (val) {
      if (val === '') {
        this.getDataArea()
      }
    }
  }
}
</script>

<style scoped>
.wrapper-search {
  width: 20em;
}

.btn-tambah {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border: none;
  border-radius: 100px;
  color: white;
  padding: 12px 28px;
}

.status-active {
  color: white;
  background: #F1D900;
  border-radius: 100px;
  padding: 4px 0px;
  width: auto;
}

.non-active {
  width: auto;
  color: white;
  background: #868686;
  border-radius: 100px;
  padding: 4px 0px;
}

.text-red {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.area-name-wrapper {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tr-wrapper td {
  border: 1px solid #C0C0C0;
}

.status-wrapper {
  display: flex;
  justify-content: center;
}

.status-wrapper p {
  width: 10em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  text-align: center;
}

</style>
