<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.3638 10.783H27.7052C32.1918 10.783 35.8332 14.533 35.8332 19.133V28.333C35.8332 32.933 32.1918 36.6663 27.7052 36.6663H12.2945C7.80785 36.6663 4.1665 32.933 4.1665 28.333V19.133C4.1665 14.533 7.80785 10.783 12.2945 10.783H12.6359C12.6684 8.78301 13.4324 6.91634 14.8142 5.51634C16.2122 4.09967 18.0003 3.38301 20.0161 3.33301C24.0476 3.33301 27.315 6.66634 27.3638 10.783ZM16.5211 7.29967C15.6107 8.23301 15.1068 9.46634 15.0743 10.783H24.9254C24.8766 8.04967 22.6983 5.83301 20.0161 5.83301C18.7644 5.83301 17.4639 6.34967 16.5211 7.29967ZM26.1771 17.1997C26.8599 17.1997 27.3963 16.633 27.3963 15.9497V14.0163C27.3963 13.333 26.8599 12.7663 26.1771 12.7663C25.5106 12.7663 24.9579 13.333 24.9579 14.0163V15.9497C24.9579 16.633 25.5106 17.1997 26.1771 17.1997ZM14.8955 15.9497C14.8955 16.633 14.359 17.1997 13.6763 17.1997C13.0098 17.1997 12.4571 16.633 12.4571 15.9497V14.0163C12.4571 13.333 13.0098 12.7663 13.6763 12.7663C14.359 12.7663 14.8955 13.333 14.8955 14.0163V15.9497Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'BagIcon'
}
</script>
