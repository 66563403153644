<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.1579 7.48807H5.3804C3.68457 7.48807 2.30957 8.86307 2.30957 10.5589L2.30957 14.6214C2.30957 16.3164 3.68457 17.6914 5.3804 17.6914H14.6554C16.3512 17.6914 17.7262 16.3164 17.7262 14.6214V10.5506C17.7262 8.85974 16.3554 7.48807 14.6646 7.48807H13.8787"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0182 1.82521V11.8594"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.58887 4.26562L10.018 1.82562L12.448 4.26562"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'UploadLightIcon',
  props: {
    color: {
      type: String,
      default: '#252525'
    },
    size: {
      type: String,
      default: '24'
    }
  }
}
</script>
