<template>
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M21.8332 11.3802C21.8332 14.6447 20.3893 17.5719 18.1052 19.5581C16.2032 21.2121 13.7184 22.2135 10.9998 22.2135C8.28123 22.2135 5.79652 21.2121 3.89443 19.5581C1.61042 17.5719 0.166504 14.6447 0.166504 11.3802C0.166504 5.39712 5.01675 0.546875 10.9998 0.546875C16.9829 0.546875 21.8332 5.39712 21.8332 11.3802ZM10.9999 18.9635C12.9029 18.9635 14.6422 18.2626 15.9736 17.1047C16.1269 16.9714 16.2748 16.8321 16.4169 16.6871C15.2459 14.7931 13.2567 13.5469 10.9999 13.5469C8.74298 13.5469 6.75382 14.7931 5.58284 16.6871C5.72493 16.8321 5.8728 16.9714 6.02608 17.1047C7.35754 18.2626 9.09684 18.9635 10.9999 18.9635ZM10.9998 11.3802C12.7948 11.3802 14.2498 9.92513 14.2498 8.13021C14.2498 6.33528 12.7948 4.88021 10.9998 4.88021C9.20491 4.88021 7.74984 6.33528 7.74984 8.13021C7.74984 9.92513 9.20491 11.3802 10.9998 11.3802Z"
            fill="#E10009" />
    </svg>
</template>

<script>
export default {
  name: 'UserRedIcon'
}
</script>
