<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25 10.729L4.25 7.729M4.25 7.729L7.25 4.729M4.25 7.729H14.75M11 10.729V11.479C11 12.0757 10.7629 12.648 10.341 13.07C9.91903 13.492 9.34674 13.729 8.75 13.729H3.5C2.90326 13.729 2.33097 13.492 1.90901 13.07C1.48705 12.648 1.25 12.0757 1.25 11.479V3.979C1.25 3.38227 1.48705 2.80997 1.90901 2.38801C2.33097 1.96606 2.90326 1.729 3.5 1.729H8.75C9.34674 1.729 9.91903 1.96606 10.341 2.38801C10.7629 2.80997 11 3.38227 11 3.979V4.729"
      stroke="#2F2F2F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'LoginIcon'
}
</script>
