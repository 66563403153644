/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'
import axios from 'axios'
import numeral from 'numeral'
import VCalendar from 'v-calendar'
import JsonExcel from 'vue-json-excel'
import Geocoder from '@pderas/vue2-geocoder'
import numFormat from 'vue-filter-number-format'
import * as VueGoogleMaps from 'vue2-google-maps'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import moment from 'moment'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import 'shepherd.js/dist/css/shepherd.css'
import 'vue-advanced-cropper/dist/style.css'

/* Router & Store */
import router from './router'
import store from './store'

/* Vue. Main component */
import App from './App.vue'

/* Default title tag */
const defaultDocumentTitle = 'Shell B2B Kanggo'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

axios.defaults.headers.common['API-KEY'] = `${process.env.VUE_APP_API_KEY}`
// axios.defaults.headers.Authorization = `${process.env.VUE_APP_HEADERS_AUTHORIZATION}`

store.commit('setup/setUrl', `${process.env.VUE_APP_SHELL_URL_API}`)

// set to indonesian time
moment.locale('id')

// const h = window.location.host
// if (h === 'shellb2b.devkanggo.com') {
//   store.commit('setup/setUrl', 'https://bsdb2b-api.devkanggo.com')
// }
// if (h.substring(0, 9) === 'localhost') {
//   store.commit('setup/setUrl', 'https://bsdb2b-api.devkanggo.com')
// }
// if (h === 'shellb2b.prodkanggo.com') {
//   store.commit('setup/setUrl', 'https://bsdb2b-api.prodkanggo.com')
// }

// if (h === '192.168.1.17:8080') {
//   store.commit('setup/setUrl', 'https://bsdb2b-api.devkanggo.com')
// }
let handleOutsideClick
Vue.directive('closable', {
  bind (el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation()
      const { handler, exclude } = binding.value

      let clickedOnExcludedEl = false

      // Iterate through each exclude ref, which is now dynamically scoped by index
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          let excludedEl = vnode.context.$refs[refName]

          // Handle if excludedEl is an array due to v-for
          if (Array.isArray(excludedEl)) {
            excludedEl = excludedEl[0] // Access the actual DOM element from the array
          }

          if (excludedEl && excludedEl.contains) {
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        }
      })

      // If click is outside and not on excluded elements, trigger the handler
      if (typeof handler === 'function') {
        if (!el.contains(e.target) && !clickedOnExcludedEl) {
          handler() // Directly call the handler function
        }
      } else if (typeof handler === 'string') {
        if (!el.contains(e.target) && !clickedOnExcludedEl) {
          vnode.context[handler]() // Call the handler method from context
        }
      }
    }

    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind () {
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
})

let handleOutsideClick2
Vue.directive('closable2', {
  bind (el, binding, vnode) {
    handleOutsideClick2 = (e) => {
      e.stopPropagation()
      const { handler, exclude } = binding.value
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName]
          if (excludedEl) {
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        }
      })
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]()
      }
    }
    document.addEventListener('click', handleOutsideClick2)
    document.addEventListener('touchstart', handleOutsideClick2)
  },

  unbind () {
    document.removeEventListener('click', handleOutsideClick2)
    document.removeEventListener('touchstart', handleOutsideClick2)
  }
})

Vue.directive('closable3', {
  bind (el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation()
      const { handler, exclude } = binding.value

      let clickedOnExcludedEl = false

      // Iterate through each exclude ref, which is now dynamically scoped by index
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          let excludedEl = vnode.context.$refs[refName]

          // Handle if excludedEl is an array due to v-for
          if (Array.isArray(excludedEl)) {
            excludedEl = excludedEl[0] // Access the actual DOM element from the array
          }

          if (excludedEl && excludedEl.contains) {
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        }
      })

      // If click is outside and not on excluded elements, trigger the handler
      if (typeof handler === 'function') {
        if (!el.contains(e.target) && !clickedOnExcludedEl) {
          handler() // Directly call the handler function
        }
      } else if (typeof handler === 'string') {
        if (!el.contains(e.target) && !clickedOnExcludedEl) {
          vnode.context[handler]() // Call the handler method from context
        }
      }
    }

    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind () {
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
})

Vue.use(Buefy)
Vue.use(JsonExcel)
Vue.component('downloadExcel', JsonExcel)
Vue.filter('numFormat', numFormat(numeral))
Vue.use(VCalendar)
Vue.use(Autocomplete)
Vue.use(VueGoogleMaps, {
  load: {
    key: `${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`,
    libraries: 'places'
  }
})
Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage: null, // e.g. 'en'
  defaultMode: 'lat-lng', // or 'lat-lng'
  googleMapsApiKey: `${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`
})
Vue.use(VueSweetalert2)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
