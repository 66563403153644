<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_2059_142756"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="2"
      width="18"
      height="21"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.99 2.71322L21 2.71312H20.99V2.71322ZM20.99 2.71322C20.8634 2.71572 20.7377 2.76569 20.65 2.86312L19.86 3.65312C19.66 3.85312 19.35 3.85312 19.15 3.65312L18.36 2.86312C18.16 2.66312 17.85 2.66312 17.65 2.86312L16.86 3.65312C16.66 3.85312 16.35 3.85312 16.15 3.65312L15.36 2.86312C15.16 2.66312 14.85 2.66312 14.65 2.86312L13.86 3.65312C13.66 3.85312 13.35 3.85312 13.15 3.65312L12.36 2.86312C12.16 2.66312 11.85 2.66312 11.65 2.86312L10.86 3.65312C10.66 3.85312 10.35 3.85312 10.15 3.65312L9.35 2.85313C9.15 2.65312 8.84 2.65312 8.64 2.85313L7.85 3.65312C7.65 3.85312 7.34 3.85312 7.14 3.65312L6.35 2.85313C6.15 2.65312 5.84 2.65312 5.64 2.85313L4.85 3.65312C4.65 3.85312 4.34 3.85312 4.14 3.65312L3.35 2.85313C3.26 2.76312 3.13 2.71312 3 2.71312V22.3031C3.13 22.3031 3.26 22.2531 3.35 22.1531L4.14 21.3631C4.34 21.1631 4.65 21.1631 4.85 21.3631L5.64 22.1531C5.84 22.3531 6.15 22.3531 6.35 22.1531L7.14 21.3631C7.34 21.1631 7.65 21.1631 7.85 21.3631L8.64 22.1531C8.84 22.3531 9.15 22.3531 9.35 22.1531L10.14 21.3631C10.34 21.1631 10.65 21.1631 10.85 21.3631L11.64 22.1531C11.84 22.3531 12.15 22.3531 12.35 22.1531L13.14 21.3631C13.34 21.1631 13.65 21.1631 13.85 21.3631L14.64 22.1531C14.84 22.3531 15.15 22.3531 15.35 22.1531L16.14 21.3631C16.34 21.1631 16.65 21.1631 16.85 21.3631L17.64 22.1531C17.84 22.3531 18.15 22.3531 18.35 22.1531L19.14 21.3631C19.34 21.1631 19.65 21.1631 19.85 21.3631L20.64 22.1531C20.74 22.2531 20.87 22.3031 20.99 22.3031V2.71322ZM7 17.5031H17C17.55 17.5031 18 17.0531 18 16.5031C18 15.9531 17.55 15.5031 17 15.5031H7C6.45 15.5031 6 15.9531 6 16.5031C6 17.0531 6.45 17.5031 7 17.5031ZM17 13.5031H7C6.45 13.5031 6 13.0531 6 12.5031C6 11.9531 6.45 11.5031 7 11.5031H17C17.55 11.5031 18 11.9531 18 12.5031C18 13.0531 17.55 13.5031 17 13.5031ZM7 9.50313H17C17.55 9.50313 18 9.05313 18 8.50313C18 7.95312 17.55 7.50313 17 7.50313H7C6.45 7.50313 6 7.95312 6 8.50313C6 9.05313 6.45 9.50313 7 9.50313Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_2059_142756)">
      <rect y="0.5" width="24" height="24" :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DocumentPappersIcon',
  props: {
    color: {
      type: String,
      default: '#E10009'
    }
  }
}
</script>
