<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99967 0.333496C10.6863 0.333496 13.6663 3.3135 13.6663 7.00016C13.6663 10.6868 10.6863 13.6668 6.99967 13.6668C3.31301 13.6668 0.333008 10.6868 0.333008 7.00016C0.333008 3.3135 3.31301 0.333496 6.99967 0.333496ZM9.39301 3.66683L6.99967 6.06016L4.60634 3.66683L3.66634 4.60683L6.05967 7.00016L3.66634 9.3935L4.60634 10.3335L6.99967 7.94016L9.39301 10.3335L10.333 9.3935L7.93967 7.00016L10.333 4.60683L9.39301 3.66683Z" fill="url(#paint0_linear_1001_137014)"/>
    <defs>
      <linearGradient id="paint0_linear_1001_137014" x1="0.333008" y1="6.87895" x2="11.0814" y2="12.15" gradientUnits="userSpaceOnUse">
      <stop :stop-color="color"/>
      <stop offset="0.9999" :stop-color="color"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'RejectStatus',
  props: {
    color: {
      type: String,
      default: '#D9272D'
    }
  }
}
</script>
