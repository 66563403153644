<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66667 24C1.93333 24 1.30533 23.7391 0.782667 23.2173C0.260889 22.6947 0 22.0667 0 21.3333V2.66667C0 1.93333 0.260889 1.30533 0.782667 0.782667C1.30533 0.260889 1.93333 0 2.66667 0H8C8.73333 0 9.36133 0.260889 9.884 0.782667C10.4058 1.30533 10.6667 1.93333 10.6667 2.66667V21.3333C10.6667 22.0667 10.4058 22.6947 9.884 23.2173C9.36133 23.7391 8.73333 24 8 24H2.66667ZM16 9.33333C15.2667 9.33333 14.6391 9.072 14.1173 8.54933C13.5947 8.02756 13.3333 7.4 13.3333 6.66667V2.66667C13.3333 1.93333 13.5947 1.30533 14.1173 0.782667C14.6391 0.260889 15.2667 0 16 0H21.3333C22.0667 0 22.6947 0.260889 23.2173 0.782667C23.7391 1.30533 24 1.93333 24 2.66667V6.66667C24 7.4 23.7391 8.02756 23.2173 8.54933C22.6947 9.072 22.0667 9.33333 21.3333 9.33333H16ZM16 24C15.2667 24 14.6391 23.7391 14.1173 23.2173C13.5947 22.6947 13.3333 22.0667 13.3333 21.3333V14.6667C13.3333 13.9333 13.5947 13.3053 14.1173 12.7827C14.6391 12.2609 15.2667 12 16 12H21.3333C22.0667 12 22.6947 12.2609 23.2173 12.7827C23.7391 13.3053 24 13.9333 24 14.6667V21.3333C24 22.0667 23.7391 22.6947 23.2173 23.2173C22.6947 23.7391 22.0667 24 21.3333 24H16Z"
      fill="url(#paint0_linear_237_97740)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_237_97740"
        x1="-9.78048e-08"
        y1="11.7818"
        x2="19.3471"
        y2="21.2697"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon'
}
</script>
