<template>
  <div v-if="isLoading" class="c-w-100 my-5 mx-5">
    <ListSkeleton />
  </div>
  <div v-else class="py-5 wrapper-project" id="listKonsultan">
    <InputSearchGlobal :title="titleStack" />

    <b-tabs v-model="activeTab">
      <b-tab-item v-for="countData in countDataTabs" :key="countData.label">
        <template #header>
          <b-icon icon="information"></b-icon>
          <span>
            {{ wordingHelper(countData.label) }} <b-tag rounded> {{ countData.count }} </b-tag>
          </span>
        </template>
        <ListSurvey
          v-if="countData.label == 'Survey Berlangsung'"
          type="survey-progress"
          selectedSubstatus="today"
          :activeTab="0"
          :currentTab="0"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
        <ListSurvey
          v-if="countData.label == 'Survey Terjadwal'"
          type="survey-scheduled"
          :currentTab="1"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
        <ListSurvey
          v-if="countData.label == 'Survey Selesai'"
          type="survey-complete"
          :currentTab="2"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
        <ListSurvey
          v-if="countData.label == 'Survey Dibatalkan'"
          type="survey-cancel"
          :currentTab="3"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
        <ListPenawaran
          v-if="countData.label == 'Proses Internal'"
          type="internal"
          selectedSubstatus="sm"
          :activeTab="4"
          :currentTab="4"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
        <ListPenawaran
          v-if="countData.label == 'Approval'"
          type="client"
          :currentTab="5"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
        <ListPenawaran
          v-if="countData.label == 'Penawaran Disetujui'"
          type="approved"
          :currentTab="6"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
        <ListPenawaran
          v-if="countData.label == 'Penawaran Ditolak'"
          type="rejected"
          :currentTab="7"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
        <ListProyek
          v-if="countData.label == 'Proyek'"
          type="project-active"
          selectedSubstatus="in-planning"
          :activeTab="8"
          :currentTab="8"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
        <ListProyek
          v-if="countData.label == 'Pembayaran'"
          type="project-complete"
          :currentTab="9"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
      </b-tab-item>
    </b-tabs>
    <b-modal v-model="modalDetailAddress" :can-cancel="false">
      <div class="modal-wrapper" v-if="detailAddress">
        <div class="header-modal is-flex is-justify-content-space-between">
          <p>Detail Alamat</p>
          <button type="button" class="delete" @click="modalDetailAddress = false" />
        </div>
        <div class="body-modal">
          <div class="my-5">
            <GmapMap class="googleMapsView" map-type-id="roadmap" :zoom="17" :center="detailAddress.current_place"
              style="width: 100%; height: 200px;">
              <GmapMarker :position="detailAddress.current_place">
              </GmapMarker>
            </GmapMap>
          </div>
          <div class="mb-5">
            {{ detailAddress.branch }}
          </div>
          <div class="mb-5">
            <p class="averta-bold">{{ detailAddress.name }}</p>
            <p>{{ detailAddress.address }}</p>
          </div>
          <div class="mb-5">
            <p class="averta-bold">Patokan/ Catatan Khusus</p>
            <p>{{ detailAddress.address_reference }}</p>
          </div>
          <div class="mb-5">
            <p class="averta-bold mb-3">Jenis Bangungan</p>
            <div class="type-building is-flex is-justify-content-center is-align-items-center">
              <div class="has-text-centered">
                <img class="mb-1" :src="detailAddress.building.url" />
                <p class="averta-bold">{{ detailAddress.building.type }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <div>
      <ButtonScrollTop :offsetWindowScroll="200" elementPoint="listKonsultan" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListSkeleton from '@/components/skeleton/ListSkeleton'
import { wordingMenuHelper } from '@/helpers'

import InputSearchGlobal from '@/components/project/list/InputSearchGlobal'
import ListSurvey from '@/components/project/list/ListSurvey.vue'
import ListPenawaran from '@/components/project/list/ListPenawaran.vue'
import ListProyek from '@/components/project/list/ListProyek.vue'
import ButtonScrollTop from '@/components/button/ButtonScrollTop.vue'

export default {
  name: 'ListProjectKonsultan',
  components: {
    ListSkeleton,
    ListSurvey,
    ListPenawaran,
    ListProyek,
    InputSearchGlobal,
    ButtonScrollTop
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      tab: 'list/getSelectedTabList',
      tabPersist: 'list/getPersistTabProject'
    }),
    titleStack () {
      return ['Proyek']
    }
  },
  data () {
    return {
      countDataTabs: null,
      detailAddress: null,
      modalDetailAddress: false,
      isLoading: true,
      activeTab: 0,
      triggerScroll: 0,
      triggerTop: false
    }
  },
  methods: {
    countTotalDataTabs (props) {
      this.isLoading = true
      if (props && props.fromDetail) {
        this.$store.commit('list/resetPersistTabProject')
        this.$store.commit('list/resetPersistDataList')
      }

      this.$store
        .dispatch('projectV2/getTabsProject', { url: this.url })
        .then(response => {
          this.countDataTabs = response.data.data
          this.isLoading = false
          this.persistDataTab()
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
        })
    },
    showModalAddress (data) {
      this.detailAddress = data
      this.modalDetailAddress = true
    },
    wordingHelper (value) {
      return wordingMenuHelper(value)
    },
    handleScroll () {
      const windowHeight = window.innerHeight
      const scrollY = window.scrollY || window.pageYOffset
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight
      )

      const offset = 250
      if (documentHeight - (scrollY + windowHeight) < offset) {
        this.triggerScroll += 1
      }

      if (scrollY >= 100) {
        this.triggerTop = true
      } else if (scrollY === 0) {
        this.triggerTop = false
      }
    },
    persistDataTab () {
      // Store Data Temporary
      const storeObj = {
        countDataTabs: this.countDataTabs,
        activeTab: this.activeTab
      }
      this.$store.commit('list/setPersistTabProject', storeObj)
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.activeTab = this.tab || 0
  },
  beforeRouteLeave (to, from, next) {
    const destinationPath = to.path
    const parts = destinationPath.split('/')
    const lastPart = parts.pop()

    if (lastPart !== 'detail') {
      this.$store.commit('list/resetPersistTabProject')
      this.$store.commit('list/resetPersistDataList')
    }
    next()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
    this.triggerScroll = 0
  },
  created () {
    if (this.tabPersist && Object.keys(this.tabPersist).length > 0) {
      // Restore Data Tab
      const { countDataTabs, activeTab } = this.tabPersist
      this.countDataTabs = countDataTabs
      this.activeTab = activeTab
      this.isLoading = false
    } else {
      this.countTotalDataTabs()
    }
  },
  watch: {
    activeTab (val) {
      this.$store.commit('list/setSelectedTabList', val)
      this.$store.commit('list/setPersistDataList', { activeTab: val })
      this.persistDataTab()
    }
  }
}
</script>

<style scoped>
.header-modal {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}

.body-modal {
  background: white;
  padding: 12px 24px;
  border-radius: 0px 0px 12px 12px;
}

.modal-wrapper {
  border-radius: 20px;
}

.type-building {
  background: #fafafa;
  width: 148.5px;
  height: 126px;
  border: 1px solid #e1e1e1;
  border-radius: 20px;
}

.type-building img {
  width: 40%;
}

.wrapper-project {
  min-height: 70vh;
  width: 95%;
  margin: auto;
}
</style>
