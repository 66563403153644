<template>
    <b-modal :on-cancel="closeModal" v-model="isModalActive" :can-cancel="false">
      <div class="modal-template">
        <div class="modal-body w-100">
          <header class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold is-size-5">Jumlah Kunjungan</p>
            <div class="is-clickable" @click="closeModal">
              <b-icon icon="close"></b-icon>
            </div>
          </header>
          <div class="mt-5">
            <div class="mx-5 mb-5">
              <div class="is-flex is-align-items-center">
                <label class="averta-bold is-size-6">Jumlah Kunjungan Survey</label>
                <label class="is-size-6 ml-1 has-text-grey">(Maksimal dalam satu hari)</label>
              </div>
              <input v-model="maxVisit" class="input mt-2 mb-3 no-arrows" type="number" placeholder="Isi disini" />
            </div>
            <footer class="py-5 is-flex is-justify-content-flex-end footer-shadow">
              <b-button class="btn-disabled averta-bold px-5 is-flex is-justify-content-center mr-5" @click="updateComponent"
                :class="{ 'btn-active': maxVisit > 0 }">
                <div class="is-flex is-align-items-center">
                  <CheckIcon class="mr-3" />
                  <span>Simpan</span>
                </div>
              </b-button>
            </footer>
          </div>
        </div>
      </div>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { CheckIcon } from '@/components/icons'

export default {
  name: 'ModalKota',
  components: {
    CheckIcon
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number
    },
    maxVisitProps: {
      type: Number
    }
  },
  data () {
    return {
      isModalActive: false,
      maxVisit: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    closeModal () {
      this.$emit('closeModal', true)
    },
    updateComponent () {
      if (!this.maxVisit) {
        return
      }

      this.$swal({
        imageUrl: require('@/assets/img/delete-area-component.png'),
        imageAlt: 'image update area component',
        width: '600px',
        padding: '10px 0 50px 0',
        title: '<p class="averta-bold label-22">Simpan Perubahan?</p>',
        html: '<p>Pastikan kamu sudah cek kembali sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            id: this.id,
            max_visit: this.maxVisit
          }

          this.$store
            .dispatch('settings/updateDataKota', payload)
            .then(response => {
              this.$emit('closeModal', 'success-update-jumlah-kunjungan')
              this.$buefy.toast.open({
                message:
              '<p class="averta-regular">Jumlah kunjungan berhasil diperbarui</p>',
                type: 'is-success'
              })
            })
            .catch(error => {
              console.log(error)
              this.$buefy.toast.open({
                message:
              '<p class="averta-regular">Gagal ubah jumlah kunjungan, silahkan coba lagi</p>',
                type: 'is-danger'
              })
            })
        }
      })
    }
  },
  watch: {
    showModal (newVal) {
      this.isModalActive = newVal
      if (newVal === true) {
        this.maxVisit = this.maxVisitProps
      }
    }
  }
}
</script>

<style scoped>
.modal-template {
  width: 35em;
  margin: auto;
}

.modal-body {
  overflow: hidden;
  background: white;
  border-radius: 10px;
  height: 100%;
}

.modal-template header {
  background-color: #911a1e;
  height: 5em;
  color: white;
  padding: 0 20px;
}

.btn-active {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  color: white;
}

.btn-disabled {
  background-color: #9a9a9a;
  border-radius: 100px;
  color: white;
}

.footer-shadow {
  box-shadow: 0px -3px 30px 0px #00000012;
}
</style>
