<template>
    <div class="select-wrapper">
      <div class="is-flex is-align-items-center is-justify-content-space-between input-data" @click="onOpen">
        <div class="is-flex is-align-items-center wrap-input">
          <PaperIcon color="black" />
          <input class="form-input is-clickable averta-regular"  ref="filterStatus" type="text" v-model="selectedStatus" readonly>
        </div>
        <ArrowDropdown :class="{'flip-icon': openSelectOption === true}" />
      </div>
      <div
        v-if="openSelectOption"
        v-closable2="{
          exclude: ['filterStatus'],
          handler: 'onClose'
        }"
        class="option-wrapper"
      >
        <div class="item-data-wrapper cursor-pointer" v-for="(item, index) in initListData" :key="`${index}-data`">
          <div class="item-data" :class="{'border-bottom-none': index + 1 === initListData.length}">
            <div class="header-wrapper is-flex is-align-items-center is-justify-content-space-between px-4 py-3">
              <div>
                <p class="label-14" :class="{'is-checked': item.checked}">{{item.name}}</p>
                <p class="label-12 averta-regular text-grey">{{item.subname}}</p>
              </div>
              <input type="checkbox" v-model="item.checked" class="is-clickable" @input="onChangeCheckbox(item)">
            </div>
          </div>
        </div>
        <div class="wrap-button is-flex is-justify-content-space-evenly py-4">
          <ButtonApp :isSecondary="true" :isBold="true" height="40px" width="130px" @click="clearStatus">Reset Filter</ButtonApp>
          <ButtonApp :isBold="true" height="40px" width="130px" @click="saveSelectedStatus">Terapkan ({{ selectedStatusCount }})</ButtonApp>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PaperIcon, ArrowDropdown } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'FilterStatusFollowUp',
  props: {
    onClearSearch: {
      type: Function
    }
  },
  components: {
    PaperIcon,
    ButtonApp,
    ArrowDropdown
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    selectedStatusCount () {
      let count = 0
      for (let i = 0; i < this.initListData.length; i++) {
        if (this.initListData[i].checked) {
          count++
        }
      }
      return count
    },
    selectedStatus () {
      let status = ''
      for (let i = 0; i < this.initListData.length; i++) {
        if (this.initListData[i].checked === true && this.initListData[i].name !== 'Semua Status') {
          if (i === this.initListData.length - 1) {
            status = status + '' + this.initListData[i].name
          } else {
            status = this.initListData[i].name + ', ' + status
          }
        }
      }
      return status === '' ? 'Pilih Status' : status
    }
  },
  data () {
    return {
      isLoading: false,
      openSelectOption: false,
      initListData: [
        {
          name: 'Semua Status',
          subname: '',
          value: 'Penawaran Ditinjau,Penawaran Disetujui,Penawaran Ditolak,Direncanakan,Proyek Aktif,Menunggu Pembayaran,Pembayaran Berhasil',
          checked: false
        },
        {
          name: 'Menunggu Konfirmasi Client',
          subname: 'Menunggu Approval',
          value: 'Penawaran Ditinjau',
          checked: false
        },
        {
          name: 'Penawaran Disetujui Client',
          subname: 'Menunggu SPK & SIK',
          value: 'Penawaran Disetujui',
          checked: false
        },
        {
          name: 'Penawaran Ditolak',
          subname: '',
          value: 'Penawaran Ditolak',
          checked: false
        },
        {
          name: 'Proyek Direncanakan',
          subname: 'In Planning',
          value: 'Direncanakan',
          checked: false
        },
        {
          name: 'Proyek Berjalan',
          subname: 'On Progress | Menunggu BAST',
          value: 'Proyek Aktif',
          checked: false
        },
        {
          name: 'Menunggu Pembayaran',
          subname: 'Menunggu Pembayaran',
          value: 'Menunggu Pembayaran',
          checked: false
        },
        {
          name: 'Pembayaran Berhasil',
          subname: '',
          value: 'Pembayaran Berhasil',
          checked: false
        }
      ]
    }
  },
  methods: {
    onChangeCheckbox (item) {
      item.checked = !item.checked

      if (item.name === 'Semua Status') {
        for (let i = 0; i < this.initListData.length; i++) {
          if (this.initListData[0].checked === true) {
            for (let i = 0; i < this.initListData.length; i++) {
              this.initListData[i].checked = true
            }
          } else {
            for (let i = 0; i < this.initListData.length; i++) {
              this.initListData[i].checked = false
            }
          }
        }
      } else {
        let count = 0
        for (let i = 0; i < this.initListData.length; i++) {
          if (this.initListData[i].name === 'Semua Status') {
            if (this.initListData[i].checked === true) {
              this.initListData[i].checked = false
            }
          }
          if (this.initListData[i].checked === true) {
            count++
          }
        }

        if (count >= this.initListData.length - 1) {
          this.initListData[0].checked = true
        }
      }
    },
    onOpen () {
      if (this.selectedStatus === 'Pilih Status' || this.selectedStatus === '') {
        this.selectedStatus = ''
      }
      this.openSelectOption = !this.openSelectOption
    },
    onClose () {
      this.$refs.filterStatus.blur()
      this.openSelectOption = false
    },
    clearStatus () {
      this.selectedStatus = 'Pilih Status'
      for (let i = 0; i < this.initListData.length; i++) {
        this.initListData[i].checked = false
      }
      this.$emit('onSubmit', '')
      this.onClose()
    },
    saveSelectedStatus () {
      let count = 0
      let select = ''
      for (let i = 0; i < this.initListData.length; i++) {
        if (this.initListData[i].checked === true) {
          count++
          if (i === this.initListData.length - 1) {
            select = select + '' + this.initListData[i].value
          } else {
            select = this.initListData[i].value + ',' + select
          }
        }
      }

      if (count >= this.initListData.length - 1) {
        select = this.initListData[0].value
      }

      this.$emit('onSubmit', select)
      this.onClose()
    }
  }
}
</script>

<style scoped>
.select-wrapper {
  position: relative;
  width: 100%;
}

.input-data {
  width: 100%;
  padding: 4px 12px;
  border: 1px solid #E1E1E1;
  border-radius: 5px;
}

.input-data input {
  padding: 8px;
  border: none;
}

.form-input {
  width: 100%;
}

.form-input:focus {
  outline: none;
}

.option-wrapper {
  position: absolute;
  top: 3em;
  right: 0;
  border-radius: 20px;
  width: 20em;
  max-height: 20em;
  overflow: auto;
  background: white;
  z-index: 5;
  box-shadow: 6px 7px 50px 0px #00000014;
}

.img-data-wrapper {
  width: 50px;
  height: 35px;
  display: flex;
  align-items: center ;
}

.img-data-wrapper img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.item-data-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.item-data {
  width: 90%;
  min-height: 2em;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #E1E1E1;
}

.header-wrapper {
  width: 100%;
}

.header-wrapper input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

.header-wrapper:hover{
  background: #3185FC1A;
  border-radius: 100px;
  font-family: 'Averta-Bold';
}

.header-wrapper .is-checked {
  font-family: 'Averta-Bold';
}

.wrap-button {
  position: sticky;
  bottom: 0;
  background: white;
  width: 100%;
}

.flip-icon {
  transform: rotate(180deg);
}

@media only screen and (max-width: 600px) {
  .wrap-input, .option-wrapper {
    width: 100% !important;
  }
}
</style>
