<template>
  <svg :width="size" :height="size" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_9543_359024"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="12"
      height="13"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.17188 7.33333C1.17188 4.11333 3.77938 1.5 6.99938 1.5C10.2252 1.5 12.8385 4.11333 12.8385 7.33333C12.8385 10.5533 10.2252 13.1667 6.99938 13.1667C3.77938 13.1667 1.17188 10.5533 1.17188 7.33333ZM7.58854 5C7.58854 4.67917 7.32604 4.41667 7.00521 4.41667C6.68438 4.41667 6.42188 4.67917 6.42188 5V7.33333C6.42188 7.65417 6.68438 7.91667 7.00521 7.91667C7.32604 7.91667 7.58854 7.65417 7.58854 7.33333V5ZM7.00521 12C4.42688 12 2.33854 9.91167 2.33854 7.33333C2.33854 4.755 4.42688 2.66667 7.00521 2.66667C9.58354 2.66667 11.6719 4.755 11.6719 7.33333C11.6719 9.91167 9.58354 12 7.00521 12ZM6.42188 9.08333V10.25H7.58854V9.08333H6.42188Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_9543_359024)">
      <rect y="0.328125" width="14" height="14" :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'InfoOutlineIcon',
  props: {
    color: {
      type: String,
      default: '#FF0000'
    },
    size: {
      type: String,
      default: '14'
    }
  }
}
</script>
