<template>
  <div>
    <div class="wrap-header is-flex is-align-items-center">
      <div class="wrap-back is-flex is-justify-content-center is-align-items-center" :class="{'report-progress mr-4': isFromProgress, 'mr-5': isFromFollowUp}" @click="goBack">
        <ArrowLeftIcon :size="16" color="#D92727" />
      </div>
      <div class="is-flex is-align-items-center is-justify-content-center">
        <IconMenuReport v-if="isFromFollowUp" width="32px" height="32px" class="mr-3" />
        <CountDownIcon v-else-if="isFromProgress" width="32px" height="32px" class="mr-3" />
        <div>
          <p class="averta-bold label-18">{{ title }}</p>
          <p class="text-grey">{{ desc }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowLeftIcon, IconMenuReport, CountDownIcon } from '@/components/icons'

export default {
  name: 'TitleHeader',
  props: {
    from: {
      type: String
    },
    title: {
      type: String
    },
    desc: {
      type: String
    }
  },
  components: {
    ArrowLeftIcon,
    IconMenuReport,
    CountDownIcon
  },
  computed: {
    isFromFollowUp () {
      if (this.from === 'follow-up') return true
      return false
    },
    isFromProgress () {
      if (this.from === 'report-progress') return true
      return false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ path: '/am/report' })
    }
  }
}
</script>

<style scoped>
.wrap-follow-up {
  width: 100%;
  min-height: 100vh;
}

.wrap-back {
  width: 38px;
  height: 38px;
  border-radius: 100px;
  background: #fc31311a;
  cursor: pointer;
}

.wrap-back.report-progress {
  width: 28px;
  height: 28px;
}
</style>
