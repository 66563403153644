import axios from 'axios'

const getDefaultState = () => {
  return {
    email: null,
    token: null,
    loginStatus: false,
    user: null,
    roles: null,
    rolePath: null,
    checkDefaultPassword: false,
    firstLogin: false
  }
}

const state = () => ({
  email: null,
  token: null,
  loginStatus: false,
  user: null,
  roles: null,
  rolePath: null,
  checkDefaultPassword: false,
  firstLogin: false
})

const getters = {
  isLogin: (state, getters, rootState) => {
    return state.loginStatus
  },
  token: (state, getters, rootState) => {
    return state.token
  },
  email: (state, getters, rootState) => {
    return state.email
  },
  user: (state, getters, rootState) => {
    return state.user
  },
  roles: (state, getters, rootState) => {
    return state.roles
  },
  rolePath: (state, getters, rootState) => {
    return state.rolePath
  },
  checkDefaultPassword: (state, getters, rootState) => {
    return state.checkDefaultPassword
  },
  firstLogin: (state, getters, rootState) => {
    return state.firstLogin
  }
}

const actions = {
  insertToken ({ commit, state }, token) {
    commit('setToken', token)
  },
  login ({ commit, state }, user) {
    localStorage.removeItem('webapp_shell_kanggo')
    return new Promise((resolve, reject) => {
      axios.post(`${user.url}${process.env.VUE_APP_AUTH_LOGIN}`, {
        email: user.email,
        password: user.password
      }, {
        headers: {
          'API-KEY': `${process.env.VUE_APP_API_KEY}`
        }
      }).then((response) => {
        const rs = {
          email: user.email,
          token: response.data.data.token,
          user: response.data.data
        }
        axios.defaults.headers.common.Authorization = response.data.data.token
        localStorage.setItem('webapp_shell_kanggo', JSON.stringify(rs))
        localStorage.setItem('reloaded', 0)
        localStorage.setItem('isSign', 1)
        commit('setLoginStatus', true)
        commit('setEmail', user.email)
        commit('setUser', response.data.data)
        if (response.data.data.is_default_password === 1) {
          commit('setCheckDefaultPassword', true)
          commit('setFirstLogin', true)
        }
        resolve(response)
      }).catch((error) => {
        localStorage.removeItem('webapp_shell_kanggo')
        commit('setLoginStatus', false)
        commit('setCheckDefaultPassword', false)
        commit('setFirstLogin', false)
        reject(error)
      })
    })
  },
  logout ({ commit, state }) {
    localStorage.removeItem('webapp_shell_kanggo')
    localStorage.removeItem('reloaded')
    commit('setLoginStatus', false)
    commit('resetState')
  },
  rolesUser ({ commit, state }, roleUsr) {
    setTimeout(function () {
      axios.get(roleUsr.url + '/api/v1/vendor/permission_role?role_id=' + roleUsr.role_id)
        .then((cb) => {
          commit('setRoles', cb.data.data[0].role_name)
          commit('setPath', cb.data.data[0].data_permision)
        })
        .catch((err) => {
          console.log(err)
        })
    }, 1000)
  },
  changePasswordDefault ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_CHANGE_PASSWORD_DEFAULT}`, {
        password: payload.password,
        password_confirm: payload.confirmPassword
      }).then((response) => {
        const dataLocalStorage = localStorage.getItem('webapp_shell_kanggo')
        let auth = JSON.parse(dataLocalStorage)
        auth = {
          ...auth,
          user: {
            ...auth.user,
            is_default_password: 0
          }
        }
        localStorage.setItem('webapp_shell_kanggo', JSON.stringify(auth))
        commit('setCheckDefaultPassword', false)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  forgotPassword ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_FORGOT_PASSWORD}`, {
        email: payload.email
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  resetPassword ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_RESET_PASSWORD}`, {
        token: payload.token,
        password: payload.password,
        password_confirm: payload.password_confirm
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  changePassword ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_CHANGE_PASSWORD}`, {
        password_old: payload.password_old,
        password: payload.password,
        password_confirm: payload.password_confirm
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getMyProfile ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROFILE}`
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateProfile ({ commit, state }, payload) {
    const data = new FormData()
    data.append('name', payload.name)
    data.append('email', payload.email)
    data.append('phone', payload.phone)
    if (payload.photo) {
      data.append('photo', payload.photo)
    }

    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_PROFILE}`, data, config
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  deleteProfilePictures ({ commit, state }, payload) {
    const data = new FormData()
    data.append('name', payload.name)
    data.append('email', payload.email)
    data.append('phone', payload.phone)
    data.append('delete_photo', true)

    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_PROFILE}`, data, config
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  switchRole ({ commit, state }, payload) {
    const user = JSON.parse(localStorage.getItem('webapp_shell_kanggo'))

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_PROFILE_SWITCH_ROLE}`, {
        role_id: payload.role_id
      }).then((response) => {
        localStorage.removeItem('webapp_shell_kanggo')

        const rs = {
          email: user.email,
          token: response.data.data.token,
          user: response.data.data
        }

        axios.defaults.headers.common.Authorization = response.data.data.token
        localStorage.setItem('webapp_shell_kanggo', JSON.stringify(rs))
        commit('setUser', response.data.data)

        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

const mutations = {
  setUser (state, user) {
    state.user = user
  },
  setToken (state, token) {
    state.token = token
  },
  setLoginStatus (state, status) {
    state.loginStatus = status
  },
  setEmail (state, email) {
    state.email = email
  },
  setRoles (state, roles) {
    state.roles = roles
  },
  setPath (state, rolePath) {
    state.rolePath = rolePath
  },
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setCheckDefaultPassword (state, checkDefaultPassword) {
    state.checkDefaultPassword = checkDefaultPassword
  },
  setFirstLogin (state, firstLogin) {
    state.firstLogin = firstLogin
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
