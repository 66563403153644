import { render, staticRenderFns } from "./InputPriceItemWorking.vue?vue&type=template&id=0ec37c67&scoped=true&"
import script from "./InputPriceItemWorking.vue?vue&type=script&lang=js&"
export * from "./InputPriceItemWorking.vue?vue&type=script&lang=js&"
import style0 from "./InputPriceItemWorking.vue?vue&type=style&index=0&id=0ec37c67&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec37c67",
  null
  
)

export default component.exports