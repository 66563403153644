<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.95833 6.33333H7.70833C7.875 6.33333 8.02083 6.27083 8.14583 6.14583C8.27083 6.02083 8.33333 5.875 8.33333 5.70833C8.33333 5.54167 8.27083 5.39583 8.14583 5.27083C8.02083 5.14583 7.875 5.08333 7.70833 5.08333H3.95833C3.79167 5.08333 3.64583 5.14583 3.52083 5.27083C3.39583 5.39583 3.33333 5.54167 3.33333 5.70833C3.33333 5.875 3.39583 6.02083 3.52083 6.14583C3.64583 6.27083 3.79167 6.33333 3.95833 6.33333ZM3.95833 4.25H7.70833C7.875 4.25 8.02083 4.1875 8.14583 4.0625C8.27083 3.9375 8.33333 3.79167 8.33333 3.625C8.33333 3.45833 8.27083 3.3125 8.14583 3.1875C8.02083 3.0625 7.875 3 7.70833 3H3.95833C3.79167 3 3.64583 3.0625 3.52083 3.1875C3.39583 3.3125 3.33333 3.45833 3.33333 3.625C3.33333 3.79167 3.39583 3.9375 3.52083 4.0625C3.64583 4.1875 3.79167 4.25 3.95833 4.25ZM12.5 7.16667V2.16667H13.3333C13.7917 2.16667 14.1842 2.33 14.5108 2.65667C14.8369 2.98278 15 3.375 15 3.83333H16.6667C16.9028 3.83333 17.1006 3.91306 17.26 4.0725C17.42 4.2325 17.5 4.43056 17.5 4.66667C17.5 4.90278 17.42 5.10056 17.26 5.26C17.1006 5.42 16.9028 5.5 16.6667 5.5H15C15 5.95833 14.8369 6.35055 14.5108 6.67667C14.1842 7.00333 13.7917 7.16667 13.3333 7.16667H12.5ZM8.33333 10.9167H3.33333V8.83333C2.41667 8.83333 1.63194 8.50694 0.979167 7.85417C0.326389 7.20139 0 6.41667 0 5.5V3.83333C0 2.91667 0.326389 2.13194 0.979167 1.47917C1.63194 0.826389 2.41667 0.5 3.33333 0.5H10C10.4583 0.5 10.8508 0.663055 11.1775 0.989167C11.5036 1.31583 11.6667 1.70833 11.6667 2.16667V7.16667C11.6667 7.625 11.5036 8.01722 11.1775 8.34333C10.8508 8.67 10.4583 8.83333 10 8.83333H8.33333V10.9167ZM2.91667 15.5C2.56944 15.5 2.27444 15.3783 2.03167 15.135C1.78833 14.8922 1.66667 14.5972 1.66667 14.25V13C1.66667 12.6528 1.78833 12.3578 2.03167 12.115C2.27444 11.8717 2.56944 11.75 2.91667 11.75H8.75C9.09722 11.75 9.39222 11.8717 9.635 12.115C9.87833 12.3578 10 12.6528 10 13V14.25C10 14.5972 9.87833 14.8922 9.635 15.135C9.39222 15.3783 9.09722 15.5 8.75 15.5H2.91667Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'DrillingIcon',
  props: ['color']
}
</script>
