<template>
<svg :width="size" :height="size" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="13.806" cy="8.66829" rx="8.33333" ry="8.41829" :fill="firtsColor"/>
      <rect x="1.37921" y="2.64483" width="18.2051" height="18.375" rx="9.10256" fill="url(#paint0_linear_14806_155324)"/>
      <rect x="1.37921" y="2.64483" width="18.2051" height="18.375" rx="9.10256" :stroke="secondColor" stroke-width="1.53846" stroke-linecap="round"/>
      <ellipse cx="10.4818" cy="11.8323" rx="8.33333" ry="8.41829" :fill="firtsColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.31641 9.94173C6.35584 9.43184 6.52673 9.04834 7.20591 8.66483L8.45033 7.95447V7.95882L10.1505 6.98698C10.3915 6.84752 10.6456 6.7778 10.8997 6.77344C11.1539 6.77344 11.408 6.84752 11.649 6.98698L13.3491 7.95882V7.95447L14.5936 8.66483C15.2684 9.04834 15.4436 9.43184 15.4831 9.94173C15.4831 11.1968 15.4831 12.452 15.4831 13.7071C15.4436 14.217 15.2727 14.6005 14.5936 14.984L13.3491 15.6943V15.69L11.649 16.6618C11.408 16.8013 11.1539 16.871 10.8997 16.8754C10.6456 16.8754 10.3915 16.8013 10.1505 16.6618L8.45033 15.69V15.6943L7.20591 14.984C6.53111 14.6005 6.35584 14.217 6.31641 13.7071C6.31641 12.452 6.31641 11.1968 6.31641 9.94173ZM10.9041 9.01783L8.45033 10.4386C8.45033 11.6283 8.45033 12.0249 8.45033 13.2146L10.9041 14.6353L13.3579 13.2146L10.9041 11.8288L13.3579 10.4429L10.9041 9.01783Z" :fill="secondColor"/>
      <defs>
      <linearGradient id="paint0_linear_14806_155324" x1="10.4818" y1="3.41406" x2="10.4818" y2="20.2506" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
      </defs>
</svg>

</template>

<script>
export default {
  name: 'AlertIcon',
  props: {
    size: {
      type: String,
      default: '24'
    },
    firtsColor: {
      type: String,
      default: '#D9272D'
    },
    secondColor: {
      type: String,
      default: '#F5F5F5'
    }
  }
}
</script>
