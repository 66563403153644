<template>
  <div v-if="isLoading" class="py-6 mb-6">
    <SpinnerIcon />
  </div>
  <div v-else class="body-wrapper mt-3">
    <p class="averta-bold">
      Jam survey yang akan digunakan surveyor untuk melakukan survey dan
      pengecekan proyek
    </p>
    <p>Pastikan informasi yang dimasukan benar dan sesuai</p>

    <div class="form-wrapper">
      <div class="mb-4 mt-4">
        <p>Jam Mulai Survey <span class="color-red">*</span></p>
        <div class="text-input-wrapper">
          <ClockCircle />
          <input
            type="time"
            class="averta-bold ml-2 no-clock-icon"
            v-model="jamSurvey"
          />
        </div>
      </div>
      <div class="mb-5">
        <p>Durasi Survey <span class="color-red">*</span></p>
        <div
          class="text-input-wrapper is-justify-content-space-between py-0 pr-0"
        >
          <input
            type="number"
            min="0"
            class="averta-bold no-arrows"
            v-model="duration"
          />
          <div class="title-jam">
            <p class="averta-bold">Jam</p>
          </div>
        </div>
        <div>
          <ErrorComponent
            v-if="duration > 24"
            title="Melebihi batas durasi maksimal"
            subtitle="Maksimal 24 Jam"
          />
        </div>
      </div>
      <b-button
        @click="handleSubmit"
        class="btn-disabled averta-bold px-5 is-flex is-justify-content-center is-align-items-center"
        :class="{ 'btn-active': isBtnActive }"
      >
        <span class="py-1">Simpan</span>
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ClockCircle, SpinnerIcon } from '@/components/icons'
import ErrorComponent from '@/components/ErrorComponent.vue'
import confirmOfferingImage from '@/assets/img/delete-area-component.png'

export default {
  name: 'JamSurveyClient',
  components: {
    ClockCircle,
    SpinnerIcon,
    ErrorComponent
  },
  data () {
    return {
      jamSurvey: '00:00',
      duration: 1,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isBtnActive () {
      if (this.duration && this.duration > 0 && this.jamSurvey) {
        return true
      }
      return false
    }
  },
  methods: {
    getDataJamSurvey () {
      this.isLoading = true
      const payload = {
        url: this.url
      }
      this.$store
        .dispatch('settings/getDataJamSurvey', payload)
        .then((response) => {
          const res = response.data.data
          this.jamSurvey = res.start_hour
          this.duration = res.duration
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: '<p class="averta-regular">Gagal memuat jam survey, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    },
    handleSubmit () {
      if (!this.isBtnActive) {
        return
      }
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '600px',
        padding: '10px 0 50px 0',
        title: '<p class="averta-bold label-22">Simpan Perubahan?</p>',
        html: '<p>Pastikan kamu sudah cek kembali area ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          cancelButton: 'btn-batal-detail-component',
          confirmButton: 'btn-hapus-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            duration: this.duration,
            start_hour: this.jamSurvey
          }
          this.$store
            .dispatch('settings/postJamSurvey', payload)
            .then(() => {
              this.getDataJamSurvey()
              this.loadingSubmit = false
            })
            .catch((error) => {
              this.loadingSubmit = false
              console.log(error)
              this.$buefy.toast.open({
                message: '<p class="averta-regular">Gagal ubah jam survey client, silahkan coba lagi</p>',
                type: 'is-danger'
              })
            })
        }
      }).catch(error => {
        console.log(error)
      })
    }
  },
  created () {
    this.getDataJamSurvey()
  }
}
</script>

<style scoped>
.body-wrapper {
  min-height: 55vh;
}
.form-wrapper {
  width: 25em;
}
.text-input-wrapper {
  border: 1px solid #e1e1e1;
  padding: 10px 0px;
  padding-left: 1em;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.text-input-wrapper input {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
}
.title-jam {
  border-left: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 8em;
  background: linear-gradient(0deg, #f0f0f0, #f0f0f0);
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-disabled {
  width: 100%;
  background-color: #9a9a9a;
  border-radius: 100px;
  color: white;
}
.btn-active {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  color: white;
}
.no-clock-icon::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
