<template>
    <img v-if="user.profile_picture" :src="user.profile_picture" :alt="user.name" class="img-profile">
    <img v-else src="../assets/img/default-picture.jpg" :alt="user.name" class="img-profile">
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserAvatar',
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
}
</script>

<style scoped>

.img-avatar {
  object-fit: cover;
  height: 50px;
  width: 30px;
  border-radius: 100px;
  margin-right: 12px;
}

</style>
