import { STORE_SMRC } from '@/helpers/constants'
export const ListDaftarStoreData = dataStore => {
  return [
    {
      image: 'wave-card.png',
      total: dataStore.vendor.code === 'SMRCN' ? 'Total ' + STORE_SMRC : 'Total Toko',

      listStore: dataStore.card.total_store,
      index: 0
    },
    {
      image: 'wave-card.png',
      total: dataStore.vendor.code === 'SMRCN' ? 'Total PIC' : 'Total PIC',
      listStore: dataStore.card.total_pic,
      index: 1
    }
  ]
}
