<template>
  <div class="zoom-container" :style="{ height: heightInit }">
    <div class="rotation-wrapper flex-center-vertical">
      <button @click="debouncedRotateImage('left')" class="is-clickable">
        <img :src="require('@/assets/img/rotate-left.png')" alt="" class="rotate-img left">
      </button>
      <button @click="debouncedRotateImage('right')" class="is-clickable">
        <img :src="require('@/assets/img/rotate-left.png')" alt="" class="rotate-img right">
      </button>
    </div>

    <!-- Dont Remove empty div -->
    <div>
      <div
        class="image-container"
        :class="{ 'cursor-zoom-out': zoomCalc === 4 }"
        @wheel="onScroll"
        @click="debouncedToogleZoom"
        :style="zoomStyleWrapper"
        @mousemove="updateMouseForZoom"
      >
        <img class="img-zoom-wrapper" :src="imageSrc" alt="Zoomable" :style="zoomStyleImg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZoomImage',
  props: {
    imageSrc: {
      type: String,
      required: true
    },
    currentSlide: {
      type: Number
    },
    indexImg: {
      type: Number
    }
  },
  data () {
    return {
      zoomLevel: 100,
      initialZoom: 100,
      minZoom: 100,
      maxZoom: 400,
      isZoomed: false,
      mouseX: 0,
      mouseY: 0,
      transformOrigin: 'center center',
      setTransform: 'center center',

      zoomCalc: 0,
      maxZoomCalc: 3,
      rotation: 0,
      heightInit: '86vh',

      isMoved: false,
      widthRect: 0,
      heightRect: 0,
      leftRect: 0,
      topRect: 0
    }
  },
  computed: {
    zoomScale () {
      return this.zoomLevel / 100
    },
    zoomStyleWrapper () {
      let temp

      if (this.rotation % 180 === 90) {
        temp = {
          height: 'auto',
          width: this.heightInit
        }
      } else {
        temp = {
          height: this.heightInit,
          width: 'auto'
        }
      }

      return {
        ...temp,
        transform: `rotate(${this.rotation}deg) translate3d(0,0,0)`,
        transition: 'transform 0.2s ease-in-out',
        transformOrigin: 'center center'
      }
    },
    zoomStyleImg () {
      return {
        transform: `scale(${this.zoomLevel / 100}) translate3d(0,0,0)`,
        transition: 'transform 0.2s ease-in-out',
        transformOrigin: this.transformOrigin
      }
    }
  },
  methods: {
    onScroll (event) {
      event.preventDefault()
      const delta = event.deltaY > 0 ? -3 : 3
      this.zoomLevel = Math.min(Math.max(this.zoomLevel + delta, this.minZoom), this.maxZoom)

      const rect = event.target.getBoundingClientRect()
      this.updateTransformOrigin(event, rect)

      if (this.zoomLevel === this.minZoom) {
        this.isZoomed = false
      }
    },
    toggleZoom (event) {
      if (!this.isZoomed) {
        this.zoomCalc++
      }
      if (this.isZoomed) {
        this.zoomLevel = this.initialZoom
        this.isZoomed = false
      } else {
        if (event) {
          const rect = event.target.getBoundingClientRect()
          this.updateTransformOrigin(event, rect)
        }
        this.zoomLevel += 80
      }
      if (this.zoomCalc === 4) {
        this.isZoomed = true
        this.zoomCalc = 0
      }
    },
    updateMouseForZoom (event) {
      const rect = event.target.getBoundingClientRect()
      this.mouseX = event.clientX - rect.left
      this.mouseY = event.clientY - rect.top

      let resOffsetX
      let resOffsetY

      // const effectiveRotation = this.rotation % 360
      // Normalize the rotation to a value between 0 and 360
      const effectiveRotation = ((this.rotation % 360) + 360) % 360

      if (effectiveRotation === 0) {
        resOffsetX = (this.mouseX / rect.width) * 100
        resOffsetY = (this.mouseY / rect.height) * 100
      } else {
        const offsetX = (event.clientX - rect.left) / rect.width
        const offsetY = (event.clientY - rect.top) / rect.height

        let adjustedX = offsetX
        let adjustedY = offsetY

        if (effectiveRotation === 90) {
          adjustedX = offsetY
          adjustedY = 1 - offsetX
        } else if (effectiveRotation === 180) {
          adjustedX = 1 - offsetX
          adjustedY = 1 - offsetY
        } else if (effectiveRotation === 270) {
          adjustedX = 1 - offsetY
          adjustedY = offsetX
        }

        this.mouseX = Math.min(Math.max(adjustedX * rect.width, 0), rect.width)
        this.mouseY = Math.min(Math.max(adjustedY * rect.height, 0), rect.height)

        resOffsetX = (this.mouseX / rect.width) * 100
        resOffsetY = (this.mouseY / rect.height) * 100
      }
      this.setTransform = `${resOffsetX}% ${resOffsetY}%`
      this.transformOrigin = this.setTransform
    },
    updateTransformOrigin () {
      // this.transformOrigin = this.setTransform
    },
    resetZoom () {
      this.zoomLevel = this.initialZoom
      this.transformOrigin = 'center center'
      this.isZoomed = false
    },
    debouncedToogleZoom: debounce(function (event) {
      this.toggleZoom(event)
    }, 100),
    rotateImage (type) {
      if (type === 'left') {
        this.rotation -= 90
      } else {
        this.rotation += 90
      }
      this.resetZoom()
    },
    debouncedRotateImage: debounce(function (type) {
      this.rotateImage(type)
    }, 100)
  },
  watch: {
    currentSlide () {
      this.resetZoom()
    }
  }
}

function debounce (func, wait) {
  let timeout
  return function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}
</script>

<style scoped>
.zoom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  position: relative;
}

.rotation-wrapper {
  position: absolute;
  top: 10px;
  left: 49%;
  z-index: 10;
  opacity: 0.5;
}

.rotation-wrapper:hover {
  opacity: 1;
}

.image-container {
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: zoom-in;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

.img-zoom-wrapper {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.rotate-img {
  width: 10px;
}

.rotate-img.right {
  transform: scaleX(-1);
}
</style>
