<template>
    <b-modal v-if="!isLoading" v-model="isModalActive" :can-cancel="false">
      <div class="modal-template">
        <div class="modal-body w-100">
          <header class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold is-size-5">{{dataDetailArea.name}}</p>
            <div class="is-clickable" @click="closeModal()">
              <b-icon icon="close"></b-icon>
            </div>
          </header>
          <div class="mt-5">
            <div class="mx-5 mb-5">
              <div class="header-wrapper">
                <p class="averta-bold">Nama Area</p>
                <p>{{dataDetailArea.name}}</p>

                <p class="averta-bold mt-2">ID Area</p>
                <div class="is-flex is-align-items-center">
                  <p class="text-red mr-1">#ID{{dataDetailArea.id}}</p>
                  <span class="cursor-pointer" @click="handleCopyText('#ID'+dataDetailArea.id)">
                    <CopasIcon />
                  </span>
                </div>
              </div>

              <div class="mt-3">
                <p class="averta-bold">Berikut Area Komponen Di Dalam Area Ini!</p>
                <p>Area di bawah ini dapat digunakan konsultan survey dalam melakukan pemeriksaan</p>

                <div class="mt-3 p-3 checkbox-area-component">
                  <div class="is-flex mb-2">
                    <p class="averta-bold">Area Komponen </p>
                  </div>
                  <div>
                    <p v-for="itemComponent in dataDetailArea.components" :key="itemComponent.id" class="mb-1">&#x2022; {{itemComponent.name}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { CopasIcon } from '@/components/icons'

export default {
  name: 'ModalArea',
  components: {
    CopasIcon
  },
  props: {
    id: {
      type: Number
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false,
      isLoading: true,
      dataDetailArea: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    async handleCopyText (text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$buefy.toast.open({
          message:
            '<p class="averta-bold">Tersalin!</p>',
          type: 'is-success'
        })
      } catch ($e) {
        this.$buefy.toast.open({
          message:
            '<p class="averta-regular">Gagal, Silahkan coba lagi</p>',
          type: 'is-success'
        })
      }
    },
    handleAddComponent () {
      this.$emit('showModalComponent', true)
    },
    closeModal () {
      this.name = ''
      this.$emit('closeModal', true)
    },
    getDetailArea () {
      this.isLoading = true
      const payload = {
        url: this.url,
        id: this.id,
        type: 'area',
        onlyView: true
      }
      this.$store
        .dispatch('settings/detailData', payload)
        .then(response => {
          this.dataDetailArea = response.data.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message:
            '<p class="averta-regular">Gagal memuat detail area, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    }
  },
  watch: {
    showModal (newVal) {
      if (newVal) {
        this.getDetailArea()
      }
      this.isModalActive = newVal
    }
  }
}
</script>

<style scoped>
.modal-template {
  width: 35em;
  margin: auto;
}

.modal-body {
  overflow: hidden;
  background: white;
  border-radius: 10px;
  height: 100%;
}

.modal-template header {
  background-color: #911a1e;
  height: 5em;
  color: white;
  padding: 0 20px;
}

.btn-active {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  color: white;
}

.btn-disabled {
  background-color: #9a9a9a;
  border-radius: 100px;
  color: white;
}

.checkbox-area-component {
  background: #FAFAFA;
  border-radius: 12px;
  height: 12em;
  overflow-y: auto;
}

.checkbox-cs {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-red {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.header-wrapper {
  padding: 12px;
  border: 1px solid #E1E1E1;
  border-radius: 12px;
}

</style>
