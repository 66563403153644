<template>
  <Skeleton v-if="!isLoading" class="py-6 mb-6" />
  <div class="container is-fluid pt-3" v-else>
    <!-- Filter & Title Bar Section -->
    <div
      class="is-flex is-align-items-center is-justify-content-space-between px-5 pt-6">
      <title-bar :title-stack="titleStack" />
      <div class="is-flex">
        <b-dropdown aria-role="list" class="mr-3">
          <template #trigger="{ active }">
            <b-button :icon-right="active ? 'menu-up' : 'menu-down'">
              <div class="is-flex is-align-items-center">
                <TokoIcon class="mr-3" />
                <p>Pilih Toko</p>
              </div>
            </b-button>
          </template>

          <b-dropdown-item aria-role="listitem">Action</b-dropdown-item>
          <b-dropdown-item aria-role="listitem">Another action</b-dropdown-item>
          <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item>
        </b-dropdown>
        <b-field class="mr-3">
          <b-datepicker placeholder="Pilih Tanggal" range icon="calendar-today">
            <b-button class="is-block mb-3 btn-tanggal">Hari ini</b-button>
            <b-button class="is-block mb-3 btn-tanggal">Kemarin</b-button>
            <b-button class="is-block mb-3 btn-tanggal">Minggu ini</b-button>
            <b-button class="is-block mb-3 btn-tanggal">Minggu lalu</b-button>
            <b-button class="is-block mb-3 btn-tanggal">Bulan ini</b-button>
            <b-button class="is-block mb-3 btn-tanggal">Bulan lalu</b-button>
            <b-button class="is-block mb-3 btn-tanggal">30 Hari terakhir</b-button>
          </b-datepicker>
        </b-field>
        <b-button class="btn-ekspor">
          <div class="is-flex is-align-items-center">
            <DownloadIcon class="mr-3" />
            <p>Ekspor</p>
          </div>
        </b-button>
      </div>
    </div>
    <!-- Filter & Title Bar Section -->

    <section class="section is-main-section" v-if="isLoading">
      <!-- Card Section -->
      <div class="columns has-text-centered mb-6">
        <div class="column card col-1 p-3 m-2">
          <img src="../../assets/img/wave-card.png" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Total Kunjungan Survey</p>
            <p class="is-size-2 averta-black my-2">
              {{ dataCardReport.survey.visit }}
            </p>
            <div class="sub-title">
              <p class="p-1 text-title-1">
                {{ dataCardReport.survey.total }} Survey Proyek
              </p>
            </div>
          </div>
        </div>
        <div class="column card col-2 p-3 m-2">
          <img src="../../assets/img/wave-card.png" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Total Penawaran Disetujui</p>
            <p class="is-size-2 averta-black my-2">
              {{ dataCardReport.offering.approved }}
            </p>
            <div class="sub-title">
              <p class="p-1 text-title-2">
                {{ dataCardReport.offering.total }} Penawaran
              </p>
            </div>
          </div>
        </div>
        <div class="column card col-3 p-3 m-2">
          <img src="../../assets/img/wave-card.png" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Persentase Penawaran Berhasil</p>
            <p class="is-size-2 averta-black my-2">
              {{ dataCardReport.offering.percentage }}%
            </p>
            <div class="sub-title">
              <p class="p-1 text-title-3">
                <span class="sub-text"
                  >+{{ dataCardReport.offering.this_month }} Penawaran</span
                >
                bulan ini
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Card Section -->

      <!-- List Laporan -->
      <div v-if="dataListReport.length > 0">
        <div class="card card-list" v-for="data in dataListReport" :key="data.id">
            <div class="header-detail p-5 is-flex is-justify-content-space-between">
            <div class="is-flex">
                <div>
                <p class="averta-bold">Toko</p>
                <p class="averta-bold id-survey">{{ data.store.name }}</p>
                </div>
                <div class="divider-vertical"></div>
                <div>
                <p class="averta-bold">No Penawaran</p>
                <p class="averta-bold id-survey">#{{ data.invoice }}</p>
                </div>
            </div>
            <div class="status-laporan">
                <span>{{ data.status }}</span>
            </div>
            </div>
            <div class="py-5 px-5">
            <div class="columns">
                <div class="column">
                <p>ID Survey</p>
                <p class="averta-bold">#ID{{ data.survey_id }}</p>
                </div>
                <div class="column is-6">
                <p>Alamat</p>
                <p class="averta-bold">
                    {{ data.store.address }}
                </p>
                </div>
                <div class="column is-flex is-justify-content-center">
                <div>
                    <p>Durasi Proyek</p>
                    <p class="averta-bold">{{ data.duration }} Hari</p>
                </div>
                </div>
                <div class="column is-flex is-justify-content-center">
                <div>
                    <p>Total Tukang</p>
                    <p class="averta-bold">{{ data.total_worker }}</p>
                </div>
                </div>
            </div>
            </div>
        </div>
      </div>
      <!-- List Laporan -->
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DownloadIcon, TokoIcon } from '../../components/icons'
import TitleBar from '@/components/TitleBar'
import Skeleton from '@/components/skeleton/Skeleton'

export default {
  name: 'ReportPage',
  components: {
    Skeleton,
    TitleBar,
    DownloadIcon,
    TokoIcon
    // CalendarFilter
  },
  computed: {
    titleStack () {
      return ['Laporan']
    },
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      isLoading: false,
      //   dataCardReport: null,
      //   dataListReport: null
      dataCardReport: {
        survey: {
          total: 0,
          visit: 0
        },
        offering: {
          total: 2,
          approved: 0,
          percentage: 0,
          this_month: 2
        }
      },
      dataListReport: [
        {
          id: 2,
          survey_id: 11,
          invoice: null,
          status: 'Penarawan Approved',
          duration: 3,
          store: {
            id: 3,
            name: 'Alfamart Pisa Grande',
            address:
              'Foresta Business Loft 1, Jl. BSD Raya Utama No.32, Pagedangan, Kec. Pagedangan, BSD City, Banten 15339'
          },
          total_worker: '?'
        }
      ]
    }
  },
  methods: {
    async getCardReport () {
      this.$store
        .dispatch('report/getCardReport', { url: this.url })
        .then(response => {
          this.dataCardReport = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getListReport () {
      this.$store
        .dispatch('report/getListReport', { url: this.url })
        .then(response => {
          this.dataListReport = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    // this.getCardReport()
    // this.getListReport()
  },
  mounted () {
    setTimeout(() => {
      this.isLoading = true
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
.btn-ekspor {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 90px;
  color: white;
  font-family: "Averta-Bold";
}

.btn-ekspor svg {
  fill: #ffffff;
}

.card {
  box-shadow: none;
}
.card-body-proyek {
  z-index: 1;
  position: relative;
}
.bottom-card {
  display: flex;
  justify-content: end;
  align-items: center;
  color: white;
  z-index: 1;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}
.col-1 {
  background: linear-gradient(90deg, #2779d9 0%, #d26cf6 100%);
  border-radius: 20px;
  color: white;
}
.col-2 {
  background: linear-gradient(90deg, #2bd927 0%, #6cf6e5 100%);
  border-radius: 20px;
  color: white;
}
.col-3 {
  background: linear-gradient(90deg, #d9272d 0%, #f6bf6c 100%);
  border-radius: 20px;
  color: white;
}
.sub-title {
  background: #ffffff;
  width: 55%;
  margin: auto;
  border-radius: 20px;
}
.text-title-1 {
  color: #2b79d9;
  font-family: "Averta-Bold";
}
.text-title-2 {
  color: #12a910;
  font-family: "Averta-Bold";
}
.text-title-3 {
  color: #868686;
}

.sub-text {
  color: #10bd41;
  font-family: "Averta-Bold";
}

.card-list {
  border-radius: 20px;
}
.header-detail {
  background: linear-gradient(90deg, #ffdddd 0%, #ffefdb 99.97%);
  border-radius: 20px 20px 0px 0px;
}

.id-survey {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.status-laporan {
  width: 150px;
  height: 31px;
  background: #f1d900;
  border-radius: 100px;
  font-family: "Averta-Bold";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.btn-lihat-detail {
  border: 1px solid #d9272d;
  border-radius: 20px;
  padding: 12px 24px;
  font-family: "Averta-Bold";
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  cursor: pointer;
}
.divider-vertical {
  border-left: 2px solid #868686;
  margin: 0px 28px;
}

.btn-tanggal {
    width: 100%;
    border-radius: 20px;
    border: 1px #868686 solid;
}
.btn-tanggal:hover {
    width: 100%;
    border-radius: 20px;
    border: 1px #D9272D solid;
    background: #FFF3F3;
    color: #D9272D;
    font-family: 'Averta-Bold';
}
</style>
