<template>
    <ModalTemplate :showModal="isModalActive" :width="widthModal" @closeModal="closeModal">
        <template v-slot:headerModal>
            <div class="is-flex is-justify-content-space-between is-align-items-center">
                <div class="is-flex is-justify-content-space-between is-align-items-center">
                    <CheckIcon class="mr-2" />
                    <p class="averta-bold label-18">Atur Invoice Tagihan</p>
                </div>
                <div class="is-clickable" @click="closeModal">
                  <b-icon icon="close"></b-icon>
                </div>
            </div>
        </template>
        <template v-slot:bodyModal>
            <div class="wrap-body-modal">
              <div class="px-5 py-3">
                <p class="mb-2 form-control">Nomor Invoice <span class="text-merah">*</span></p>
                <div class="wrap-form mb-5 is-flex is-align-items-center is-justify-content-space-between">
                    <!-- <p class="pr-3 averta-bold border-right">#INV</p> -->
                    <input type="text" class="form-invoice" placeholder="masukan nomor invoice" v-model="dataInvoice.noInvoice">
                </div>
              </div>
              <div class="form-button is-flex is-justify-content-end is-align-items-center"
                  :class="{ 'active': dataInvoice.noInvoice && dataInvoice.noInvoice.length > 0 }">
                  <b-button @click="saveInvoice">Simpan Invoice</b-button>
              </div>
            </div>
        </template>
    </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'
import { CheckIcon } from '@/components/icons'
import saveImage from '@/assets/img/delete-area-component.png'
import ModalTemplate from './ModalTemplate.vue'

export default {
  name: 'ModalInvoiceTagihan',
  components: { ModalTemplate, CheckIcon },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '75vw'
    },
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      isModalActive: false,
      widthModal: this.width,
      isLoading: false,
      modeEdit: false,
      dataInvoice: {
        noInvoice: '',
        projectId: null
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
      this.dataInvoice = {
        noInvoice: '',
        projectId: null
      }
    },
    saveInvoice () {
      const payload = {
        url: this.url,
        invoice: this.dataInvoice.noInvoice,
        project_id: this.dataInvoice.projectId
      }

      if (this.dataInvoice.noInvoice && this.dataInvoice.noInvoice.length > 0) {
        this.$swal({
          imageUrl: saveImage,
          imageAlt: 'save data',
          width: '540px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Simpan Nomor Invoice</p>',
          html: '<p class="averta-regular">Pastikan seluruh informasi telah benar dan sesuai</p>',
          showCancelButton: true,
          confirmButtonText: 'Simpan',
          cancelButtonText: 'Batalkan',
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch('project/updateInvoice', payload)
              .then(() => {
                this.$buefy.toast.open({
                  duration: 3000,
                  message: '<div class="flex-center is-flex-direction-column"><p class="averta-regular">No. Invoice berhasil disimpan</p</div>'
                })
                this.$emit('reloadList')
              })
              .catch(error => {
                this.isLoading = false
                alertErrorMessage(error)
                this.$emit('reloadList')
              })
          }
        })
      }
    }
  },
  watch: {
    showModal (newValue) {
      this.isModalActive = newValue
    },
    width (newValue) {
      this.widthModal = newValue
    },
    data (newValue) {
      const tempInvoice = newValue.noInvoice

      if (tempInvoice && tempInvoice.startsWith('#INV')) {
        newValue.noInvoice = tempInvoice.slice(4)
      }

      this.dataInvoice = newValue
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.border-right {
    border-right: 2px solid #E5E5E5;
}

.wrap-body-modal {
    position: relative;
    height: 210px;
}

.wrap-form {
    border: 2px solid #E5E5E5;
    padding: 8px 14px;
    border-radius: 14px;
}

.form-invoice {
    border: none;
    width: 100%;
    padding: 4px;
    font-size: 16px;
}

.form-invoice:focus {
    outline: none;
}

.form-button {
    padding: 12px;
    width: 100%;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
}

.form-button button {
    background: #9A9A9A;
    border-radius: 100px;
    color: white;
    font-family: 'Averta-Bold';
    padding: 24px;
}

.form-button.active button {
  background: $color-red-1;
}

@media screen and (max-width: 768px) {
  .wrap-body-modal {
    height: 35vh;
  }
}

</style>
