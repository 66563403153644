<template>
  <div>
    <div class="my-4">
      <b-skeleton height="20px" width="300px" :animated="true"></b-skeleton>
      <b-skeleton height="20px" width="200px" :animated="true"></b-skeleton>
    </div>
    <div class="columns">
      <div class="column" v-for="item, index in totalCard" :key="index">
        <b-skeleton :height="height" :animated="true"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSkeleton',
  props: {
    showTitle: {
      type: Boolean,
      default: false
    },
    totalCard: {
      type: Number,
      default: 3
    },
    height: {
      type: String,
      default: '100px'
    }
  }
}
</script>
