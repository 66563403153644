<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_5559_57356" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="3" width="16" height="14">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M15.8333 5.00065H4.16667C3.70833 5.00065 3.33333 4.62565 3.33333 4.16732C3.33333 3.70898 3.70833 3.33398 4.16667 3.33398H15.8333C16.2917 3.33398 16.6667 3.70898 16.6667 4.16732C16.6667 4.62565 16.2917 5.00065 15.8333 5.00065ZM16.8 6.50065C16.725 6.11732 16.3833 5.83398 15.9833 5.83398H4.01667C3.61667 5.83398 3.275 6.11732 3.2 6.50065L2.5 10.0007V10.834C2.5 11.2923 2.875 11.6673 3.33333 11.6673V15.834C3.33333 16.2923 3.70833 16.6673 4.16667 16.6673H10.8333C11.2917 16.6673 11.6667 16.2923 11.6667 15.834V11.6673H15V15.834C15 16.2923 15.375 16.6673 15.8333 16.6673C16.2917 16.6673 16.6667 16.2923 16.6667 15.834V11.6673C17.125 11.6673 17.5 11.2923 17.5 10.834V10.0007L16.8 6.50065ZM10 15.0007H5V11.6673H10V15.0007Z"
                fill="black" />
        </mask>
        <g mask="url(#mask0_5559_57356)">
            <rect width="20" height="20" fill="#252525" />
        </g>
    </svg>
</template>

<script>
export default {
  name: 'VendorFilter'
}
</script>
