<template>
  <div v-if="isLoading" class="loading-wrapper">
    <SpinnerIcon />
  </div>
  <div v-else-if="jadwalPekerjaan.length < 1" class="columns is-centered">
    <div class="column has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">Laporan Harian tidak ditemukan</p>
    </div>
  </div>
  <div v-else>
    <div class="group-date-wrapper mb-4" v-if="jadwalPekerjaan.length > 0">
      <div class="is-flex is-justify-content-space-between averta-bold mb-4">
        <p>Pilih Tanggal Pekerjaan</p>
        <p>{{ jadwalMonth }}</p>
      </div>
      <div class="is-relative">
        <img
          v-if="isScrolledJadwal.isScroll"
          @click="handleClickArrow('prev')"
          class="left-arrow"
          :class="{ 'show cursor-pointer': isScrolledJadwal.showLeftImg }"
          :src="require('@/assets/img/arrow-short.png')"
          alt=""
        />
        <div class="is-flex overflow-auto scroll-smooth" ref="contentJadwal" @scroll="checkScrollJadwal">
          <div
            class="parent-date-wrapper mb-3"
            :class="{ 'mr-3': indexDate + 1 === jadwalPekerjaan.length, 'ml-3': indexDate + 1 === 1 }"
            v-for="(itemDate, indexDate) in jadwalPekerjaan"
            :key="`${indexDate}_date_working`"
          >
            <div
              class="date-wrapper cursor-pointer"
              :class="{ active: selectedDate === itemDate.completeDate }"
              @click="handleSelectedDate(itemDate.completeDate)"
            >
              <p class="averta-bold">{{ itemDate.date }}</p>
            </div>
            <p class="white-space-nowrap" :class="{ 'has-text-weight-bold': selectedDate === itemDate.completeDate }">{{ itemDate.day }}</p>
            <p v-if="showExtend(itemDate.completeDate)" class="color-grey" :class="{ 'averta-bold color-blue-1': selectedDate === itemDate.completeDate }"><i>Extend</i></p>
          </div>
        </div>
        <img v-if="isScrolledJadwal.isScroll" @click="handleClickArrow('next')" class="right-arrow" :class="{ 'show cursor-pointer': isScrolledJadwal.showRightImg }" :src="require('@/assets/img/arrow-short.png')" alt="" />
      </div>
    </div>
    <div v-if="loadingDate" class="loading-wrapper">
      <SpinnerIcon />
    </div>
    <div v-else-if="!loadingDate && dataDialyReport.item_components.length > 0">
      <div class="body-wrapper mb-4" v-for="(itemWorking, indexWorking) in dataDialyReport.item_components" :key="`${indexWorking}_item_working`">
        <div class="header-detail is-flex is-justify-content-space-between is-align-items-center p-5 flex-sm-column align-items-sm-start p-sm-2" :id="`item_component_id_${itemWorking.item_component_id}`">
          <div class="mb-sm-1">
            <p>Item Pekerjaan</p>
            <p class="averta-bold mb-2">{{ itemWorking.item_component_name }}</p>
            <div v-if="itemWorking.item_component_images && itemWorking.item_component_images.length > 0" class="is-flex">
              <div v-for="(itemPicture, indexPicture) in itemWorking.item_component_images" :key="indexPicture" @click="openModalPreviewImage({ multipleImage: itemWorking.item_component_images, index: indexPicture })" class="wrapper-preview-image cursor-pointer is-relative mr-3">
                <img :src="itemPicture" alt="" />
              </div>
            </div>
          </div>
          <div v-if="isAssignWorker">
            <ButtonApp :isBold="true" :width="isMobile ? '150px' : '180px'" :height="isMobile ? '30px' : '45px'" @click="addSkill(itemWorking.item_component_id)">Tambah Keahlian</ButtonApp>
          </div>
        </div>

        <div v-if="!itemWorking.skills" class="columns is-centered">
          <div class="column has-text-centered">
            <ProyekEmptyIcon />
            <p class="averta-bold mt-2">Detail Kehadiran tidak ditemukan</p>
          </div>
        </div>
        <div v-else>
          <div class="p-4">
            <p class="averta-bold mb-sm-2" :class="{ 'mb-5': itemWorking.skills.length > 0 }">Daftar Absen Proyek</p>
            <div v-if="itemWorking.skills.length > 0">
              <div v-for="(itemAttendance, indexAttendance) in itemWorking.skills" :key="indexAttendance" class="detail-wrapper mb-4">
                <div class="is-flex mb-4 flex-sm-column">
                  <div class="is-flex border-right-2 pr-5 skills-wrapper border-sm-none">
                    <div class="jenis-tukang-img mr-3">
                      <img v-if="itemAttendance.workers.length > 0" :src="itemAttendance.skill_image" alt="tukang" />
                      <img v-else :src="require('@/assets/img/empty-img.png')" alt="empty" />
                    </div>
                    <div>
                      <p class="color-gray label-12">Jenis Keahlian</p>
                      <p class="averta-bold">{{ itemAttendance.skill_name }}</p>
                    </div>
                  </div>
                  <div class="pl-5 px-sm-0 border-sm-top-dashed pt-sm-1 mt-sm-2 flex-sm justify-content-sm-between align-items-sm-center">
                    <p class="color-gray label-12">Sesi Pengerjaan</p>
                    <div class="session-wrapper" :class="`${sessionWorker(itemAttendance.sesi, true)}`">
                      <p class="averta-bold color-white label-sm-10">{{ sessionWorker(itemAttendance.sesi) }}</p>
                    </div>
                  </div>
                </div>

                <div v-for="(itemTukang, indexTukang) in itemAttendance.workers" :key="indexTukang + indexAttendance">
                  <div v-if="itemTukang.worker_id" class="tukang-wrapper mb-4 mx-sm-0 p-sm-2" :id="`job_id_${itemTukang.job_id}_component_id_${itemWorking.item_component_id}`">
                    <div class="is-flex is-align-items-center is-justify-content-space-between flex-sm-column">
                      <div class="is-flex is-align-items-center flex-sm-column align-items-sm-start c-w-sm-100">
                        <div class="is-flex is-align-items-center border-right-2 border-sm-none pr-3 item-worker-wrapper mb-sm-2 justify-content-sm-between c-w-sm-100 p-sm-0">
                          <div class="is-flex is-align-items-center">
                            <div class="tukang-img mr-3">
                              <img
                                v-if="itemTukang.worker_image && itemTukang.worker_image !== '-'"
                                :src="itemTukang.worker_image"
                                @click="openModalPreviewImage({ singleImage: itemTukang.worker_image })"
                                alt="worker_img"
                                class="is-clickable"
                              />
                              <img v-else :src="require('@/assets/img/default-picture.jpg')" alt="default_pic" />
                            </div>
                            <div>
                              <p class="averta-bold">{{ itemTukang.worker_name }}</p>
                              <p class="label-cs">{{ !itemTukang.worker_id || itemTukang.worker_id === 0 ? '' : `ID${itemTukang.worker_id}` }}</p>
                            </div>
                          </div>

                          <div v-if="isAssignWorker && isMobile" class="is-flex is-align-items-center is-relative ml-5">
                            <div class="is-clickable" @click="handleDropdownWorker(indexWorking + 1, indexAttendance + 1, indexTukang + 1)">
                              <HamburgerDotIcon :size="isMobile ? '35' : '42'" />
                            </div>
                            <!-- Dropdown MOBILE -->
                            <div class="dropdown-worker" :class="`dropdown-worker-${indexWorking + 1}-${indexAttendance + 1}-${indexTukang + 1} last-no mobile`">
                              <div
                                class="item-menu-dropdown"
                                @click="handleChangeWorker({
                                  indexNoteItem: indexWorking + 1,
                                  indexSkill: indexAttendance + 1,
                                  indexWorker: indexTukang + 1,
                                  jobId: itemTukang.job_id,
                                  skillId: itemAttendance.skill_id,
                                  dataTukang: itemTukang
                                })"
                              >
                                <div class="is-flex">
                                  <EditIcon color="#000" size="20" />
                                  <p class="color-gray ml-1">Ganti Tukang</p>
                                </div>
                                <ArrowFoward />
                              </div>
                              <div
                                class="item-menu-dropdown"
                                v-if="statusProject === 'Proyek Aktif' || statusProject === 'active'"
                                @click="handleEditStatusAttendance({
                                  indexNoteItem: indexWorking + 1,
                                  indexWorker: indexTukang + 1,
                                  indexSkill: indexAttendance + 1,
                                  data: {
                                    itemTukang,
                                    itemAttendance
                                  }
                                })"
                              >
                                <div class="is-flex">
                                  <EditSquare size="20" />
                                  <p class="color-gray ml-1">Ubah Status Absen & Foto Before-After</p>
                                </div>
                                <ArrowFoward />
                              </div>
                              <div
                                class="item-menu-dropdown"
                                @click="handleDeleteSlotSkill({
                                  indexNoteItem: indexWorking + 1,
                                  indexSkill: indexAttendance + 1,
                                  indexWorker: indexTukang + 1,
                                  jobId: itemTukang.job_id,
                                  dataTukang: itemTukang,
                                  isLastWorker: itemAttendance.workers.length === 1
                                })"
                              >
                                <div class="is-flex">
                                  <CloseSquare color="#000" colorGradient="#000" size="23" />
                                  <p class="color-gray ml-1">Hapus Slot Keahlian</p>
                                </div>
                                <ArrowFoward />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="is-flex c-w-100 mb-sm-2">
                          <div class="px-5 border-right-2 px-sm-0 pr-sm-2 flex-sm-1">
                            <p class="label-cs mb-1">Status</p>
                            <div class="status-wrapper px-3 is-inline-block" :class="{ 'bg-green': itemTukang.attendance_status, 'bg-maroon': !itemTukang.attendance_status }">
                              <p class="has-text-centered">{{ itemTukang.attendance_status ? 'Hadir' : 'Tidak Hadir' }}</p>
                            </div>
                          </div>
                          <div class="pl-5 px-sm-0 pl-sm-2 flex-sm-1">
                            <p class="label-cs">Jam Kehadiran</p>
                            <p class="averta-bold">{{ formatAttendance(itemTukang.attendance_date) }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="is-flex w-sm-100 is-align-items-center justify-content-sm-between flex-sm-column">
                        <div class="is-flex is-align-items-center c-w-sm-100 overflow-sm-auto thin-scroll">
                          <div class="before-after-wrapper mr-5 mr-sm-0 pr-sm-2">
                            <div class="is-flex is-justify-content-space-between mb-1">
                              <p class="averta-reguler label-14 color-black-primary">Sebelum</p>
                              <p v-if="itemTukang.image_photo_before.length > 0" class="averta-reguler label-12 color-red cursor-pointer" @click="handleShowSidebar({ worker: itemTukang, noteItem: itemWorking.item_component_name })">Lihat</p>
                            </div>
                            <div v-if="itemTukang.image_photo_before.length > 0" class="is-flex">
                              <div
                                v-for="(itemBefore, indexBefore) in itemTukang.image_photo_before.slice(0, 3)"
                                :key="indexBefore + indexTukang"
                              >
                                <div v-if="itemBefore && Object.keys(itemBefore).length > 0" class="before-after-img mr-2">
                                  <img
                                    class="cursor-pointer"
                                    :src="itemBefore.image_url || itemBefore"
                                    @click="openModalPreviewImage({ multipleImage: itemTukang.image_photo_before, index: indexBefore })"
                                    alt="img_before"
                                  />
                                </div>
                              </div>
                            </div>
                            <img v-else :src="require('@/assets/img/empty-img.png')" alt="empty" />
                          </div>
                          <div class="before-after-wrapper pr-sm-2">
                            <div class="is-flex is-justify-content-space-between mb-1">
                              <p class="averta-reguler label-14 color-black-primary">Sesudah</p>
                              <p v-if="itemTukang.image_photo_after.length > 0" class="averta-reguler label-12 color-red cursor-pointer" @click="handleShowSidebar({ worker: itemTukang, noteItem: itemWorking.item_component_name })">Lihat</p>
                            </div>
                            <div
                              v-if="itemTukang.image_photo_after.length > 0"
                              class="is-flex"
                            >
                              <div
                                v-for="(itemAfter, indexAfter) in itemTukang.image_photo_after.slice(0, 3)"
                                :key="indexAfter + indexTukang"
                              >
                                <div
                                  v-if="itemAfter && Object.keys(itemAfter).length > 0"
                                  class="before-after-img mr-2"
                                >
                                  <img
                                    class="cursor-pointer"
                                    :src="itemAfter.image_url || itemAfter"
                                    @click="openModalPreviewImage({ multipleImage: itemTukang.image_photo_after, index: indexAfter })"
                                    alt="img_after"
                                  />
                                </div>
                              </div>
                            </div>
                            <img v-else :src="require('@/assets/img/empty-img.png')" alt="empty" />
                          </div>
                        </div>
                        <div v-if="isAssignWorker && !isMobile" class="is-flex is-align-items-center is-relative ml-5">
                          <div class="is-clickable" @click="handleDropdownWorker(indexWorking + 1, indexAttendance + 1, indexTukang + 1)">
                            <HamburgerDotIcon />
                          </div>
                          <!-- Dropdown DEKSTOP -->
                          <div class="dropdown-worker" :class="`dropdown-worker-${indexWorking + 1}-${indexAttendance + 1}-${indexTukang + 1} last-${indexTukang + 1 === itemAttendance.workers.length ? 'child' : 'no'}`">
                            <div
                              class="item-menu-dropdown"
                              @click="handleChangeWorker({
                                indexNoteItem: indexWorking + 1,
                                indexSkill: indexAttendance + 1,
                                indexWorker: indexTukang + 1,
                                jobId: itemTukang.job_id,
                                skillId: itemAttendance.skill_id,
                                dataTukang: itemTukang,
                                componentId: itemWorking.item_component_id
                              })"
                            >
                              <div class="is-flex">
                                <EditIcon color="#000" size="20" />
                                <p class="color-gray ml-1">Ganti Tukang</p>
                              </div>
                              <ArrowFoward />
                            </div>
                            <div
                              class="item-menu-dropdown"
                              v-if="statusProject === 'Proyek Aktif' || statusProject === 'active'"
                              @click="handleEditStatusAttendance({
                                indexNoteItem: indexWorking + 1,
                                indexWorker: indexTukang + 1,
                                indexSkill: indexAttendance + 1,
                                data: {
                                  itemTukang,
                                  itemAttendance
                                },
                                jobId : itemTukang.job_id,
                                componentId: itemWorking.item_component_id
                              })"
                            >
                              <div class="is-flex">
                                <EditSquare size="20" />
                                <p class="color-gray ml-1">Ubah Status Absen & Foto Before-After</p>
                              </div>
                              <ArrowFoward />
                            </div>
                            <div
                              class="item-menu-dropdown"
                              @click="handleDeleteSlotSkill({
                                indexNoteItem: indexWorking + 1,
                                indexSkill: indexAttendance + 1,
                                indexWorker: indexTukang + 1,
                                jobId: itemTukang.job_id,
                                dataTukang: itemTukang,
                                isLastWorker: itemAttendance.workers.length === 1
                              })"
                            >
                              <div class="is-flex">
                                <CloseSquare color="#000" colorGradient="#000" size="23" />
                                <p class="color-gray ml-1">Hapus Slot Keahlian</p>
                              </div>
                              <ArrowFoward />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else class="tukang-wrapper is-flex is-align-items-center is-justify-content-space-between mb-4 mx-sm-0">
                    <div class="is-flex is-align-items-center">
                      <div class="tukang-img mr-3 mr-sm-1">
                        <img :src="require('@/assets/img/profile-icon-black.png')" alt="" />
                      </div>
                      <p class="averta-bold is-italic color-gray label-sm-12">Belum Di Assign</p>
                    </div>
                    <div class="is-flex is-align-items-center">
                      <ButtonApp
                        v-if="isAssignWorker"
                        :isTransparent="true"
                        :isSecondary="true"
                        @click="handleAssignTukang({
                          jobId: itemTukang.job_id,
                          skillId: itemAttendance.skill_id,
                          skillName: itemAttendance.skill_name,
                          componentId: itemWorking.item_component_id
                        })"
                      >
                        <p class="color-red averta-regular py-1 label-sm-10 py-sm-0">Assign Tukang</p>
                      </ButtonApp>
                      <div
                        v-if="isAssignWorker"
                        class="ml-3 is-clickable"
                        @click="handleDeleteSlotSkill({
                          jobId: itemTukang.job_id,
                          onlyDelete: true,
                          isLastWorker: itemAttendance.workers.length === 1
                        })"
                      >
                        <TrashIcon />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Skills Divider -->
                <div v-if="indexAttendance + 1 !== itemWorking.skills.length" class="divider-skills"></div>
              </div>
            </div>
            <div v-else class="column has-text-centered mb-5">
              <ProyekEmptyIcon />
              <p class="averta-bold">Tidak ada kehadiran tukang jagoan</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="column has-text-centered mb-5">
      <ProyekEmptyIcon />
      <p class="averta-bold">Tidak ada kehadiran tukang jagoan</p>
    </div>

    <ModalPreviewImage
      :isActive="modalPreviewImageActive"
      :dataImage="imagePreviewCarousel"
      :urlOneImage="urlImagePreview"
      :indexImg="indexModalPreview"
      @closeModal="closeModalPreviewImage"
    />

    <!-- Modal Add Skill -->
    <ModalAddSkill :showModal="modalAddSkill" :selectedDate="selectedDate" :componentId="componentId" width="45vw" @closeModal="addSkill" @reloadPage="reloadData($event)" />
    <!-- Modal Add Skill -->

    <!-- Modal Assign Tukang -->
    <ModalAssignTukang
      :showModal="modalAssignTukang"
      :jobId="jobId"
      :dataWorkerProps="dataTukang"
      :skillId="skillId"
      :skillNameProps="skillName"
      :selectedDate="selectedDate"
      :loadData="reloadData"
      @closeModal="handleAssignTukang"
    />
    <!-- Modal Assign Tukang -->

    <!-- Modal Ganti Status Absen -->
    <ModalChangeAttandance
      :dataWorkerAbsent="dataWorkerAbsent"
      :showModal="modalChangeAttandance"
      width="720px"
      @closeModal="handleChangeAttandance"
      @reloadPage="reloadData"
    />
    <!-- END Modal Ganti Status Absen -->

    <SidebarBeforeAfter
      :showSidebar="sidebarBeforeAfter"
      :data="selectedDataWorker"
      type="detail"
      @closeSidebar="handleShowSidebar"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

// Assets
import { ArrowFoward, EditIcon, EditSquare, ProyekEmptyIcon, SpinnerIcon, HamburgerDotIcon, CloseSquare, TrashIcon } from '@/components/icons'

// Helpers
import { alertErrorMessage, formatSessionWorker } from '@/helpers'

// Components
import ButtonApp from '@/components/button/ButtonApp'

// Modals
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import ModalAddSkill from './assign-worker/ModalAddSkill.vue'
import ModalAssignTukang from './assign-worker/ModalAssignTukang.vue'
import SidebarBeforeAfter from '@/components/SidebarBeforeAfter.vue'

// Image
import saveImage from '@/assets/img/delete-area-component.png'

export default {
  name: 'TabLaporanHarian',
  props: {
    statusProject: {
      type: String
    }
  },
  components: {
    // Icons,
    ArrowFoward,
    EditIcon,
    EditSquare,
    ProyekEmptyIcon,
    SpinnerIcon,
    HamburgerDotIcon,
    CloseSquare,
    TrashIcon,

    // Components
    ButtonApp,

    // Modal
    ModalPreviewImage,
    ModalAddSkill,
    ModalAssignTukang,
    ModalChangeAttandance: () => import('./assign-worker/ModalChangeAttandance.vue'),
    SidebarBeforeAfter
  },
  data () {
    return {
      isLoading: true,

      // List Card Laporan Harian
      dataDialyReport: null,
      jadwalPekerjaan: [],
      jadwalMonth: '',
      selectedDate: null,
      modalAddSkill: false,

      // Tanggal Pengerjaan
      isFirst: false,
      isScrolledJadwal: {
        isScroll: false,
        showLeftImg: false,
        showRightImg: false
      },
      loadingDate: true,

      // Preview Image Multiple & Single
      imagePreviewCarousel: [],
      modalPreviewImageActive: false,
      urlImagePreview: null,
      sidebarBeforeAfter: false,
      indexModalPreview: 0,

      // Assign Tukang & Ganti Tukang
      isAssignWorker: false,
      isOpenDropdownMenu: false,
      indexDropdownToBeHide: null,
      modalAssignTukang: false,
      jobId: null,
      skillId: null,
      skillName: null,
      selectedDataWorker: null,
      dataTukang: null,

      // Ganti Status Absen
      modalChangeAttandance: false,
      dataWorkerAbsent: null,

      // Add Skill
      componentId: null,

      // Extend date
      extendDates: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      scrollElement: 'project/getScrollElement'
    }),
    isRole () {
      let result
      switch (this.user.roles[0].name) {
        case 'Account Manager':
          result = true
          break
        case 'Site Manager':
          result = true
          break
        case 'Business Support':
          result = true
          break
        default:
          result = false
          break
      }

      return result
    },
    isBS () {
      if (this.user.roles[0].name === 'Account Manager' || this.user.roles[0].name === 'Business Support') {
        return true
      }
      return false
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  methods: {
    formatAttendance (time) {
      if (!time) return '-'
      return moment(time).format('HH:mm')
    },
    convertDate (date) {
      if (!date || date === '-') {
        return '-'
      }

      return moment(date).format('DD-MM-YYYY')
    },
    sessionWorker (status, isForBackground) {
      return formatSessionWorker({ status, isForBackground })
    },
    handleClickArrow (direction) {
      if (direction === 'next') {
        this.$refs.contentJadwal.scrollBy(200, 0)
      } else {
        this.$refs.contentJadwal.scrollBy(-200, 0)
      }
    },
    closeModalPreviewImage () {
      this.imagePreviewCarousel = null
      this.modalPreviewImageActive = false
      this.urlImagePreview = null
    },
    openModalPreviewImage (props = {}) {
      const { multipleImage, singleImage } = props

      this.modalPreviewImageActive = true

      if (singleImage) {
        this.urlImagePreview = singleImage
        this.imagePreviewCarousel = []
      } else {
        this.imagePreviewCarousel = multipleImage
        this.urlImagePreview = null
        this.indexModalPreview = props.index || 0
      }
    },
    getJadwalPekerjaan () {
      if (!this.isLoading) {
        moment.locale('id')
        const arr = []
        const defaultFormat = 'YYYY-MM-DD'
        const startDate = moment(this.dataDialyReport.start_date, defaultFormat)
        const endDate = moment(this.dataDialyReport.end_date, defaultFormat)

        const currentDate = moment().format(defaultFormat)
        const formatDate = startDate.clone()

        const startFormat = startDate.format('MMMM')
        const endFormat = endDate.format('MMMM YYYY')

        if (startDate.format('MM') === endDate.format('MM')) {
          this.jadwalMonth = `${startFormat} ${startDate.format('YYYY')}`
        } else {
          this.jadwalMonth = `${startFormat} ${startDate.format('YYYY') !== endDate.format('YYYY') ? startDate.format('YYYY') : ''} - ${endFormat}`
        }

        while (formatDate.isSameOrBefore(endDate)) {
          const completeDate = formatDate.format(defaultFormat)
          const date = formatDate.format('DD')
          let day = formatDate.format('ddd')

          if (currentDate === completeDate) {
            day = 'Hari ini'
          }

          const tempDate = {
            completeDate,
            date,
            day
          }

          arr.push(tempDate)
          // Increment the current date by one day
          formatDate.add(1, 'days')
        }
        this.jadwalPekerjaan = arr

        // check if content jadwal pekerjaan have scroll
        this.checkScrollJadwal()
      }
    },
    getLaporanHarian (selectedDate, isRefresh, scrollToRight) {
      if (this.isLoading && !isRefresh) {
        this.isLoading = true
      }

      if (!isRefresh) {
        this.loadingDate = true
      }

      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        selected_date: selectedDate,
        path_name: 'daily-attendance'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then((response) => {
          const res = response.data.data

          this.extendDates = res.extend_dates

          this.$store.commit('project/setAlertAssignTukang', res.is_slot_full)

          const startDate = moment(res.start_date)
          const endDate = moment(res.end_date)
          const dateToCheck = moment(selectedDate)

          if (!this.isFirst) {
            if (dateToCheck.isBetween(startDate, endDate, null, '[]')) {
              this.selectedDate = moment(res.selected_date).format('YYYY-MM-DD')
            } else {
              this.selectedDate = moment(res.start_date).format('YYYY-MM-DD')
              this.getLaporanHarian(this.selectedDate)
            }
            this.isFirst = true
          }

          this.dataDialyReport = res
          this.isLoading = false
          this.loadingDate = false
          if (this.scrollElement && this.scrollElement.scroll && this.scrollElement.selectedDate) {
            const tempSelected = this.scrollElement.selectedDate

            this.selectedDate = tempSelected
            this.getLaporanHarian(tempSelected, false, true)

            this.$store.commit('project/setScrollElement', null)
          }

          if (scrollToRight && this.$refs.contentJadwal) {
            if (this.$refs.contentJadwal) {
              this.$refs.contentJadwal.scrollBy(document.body.scrollWidth, 0)
              setTimeout(() => {
                this.$refs.contentJadwal.scrollBy(document.body.scrollWidth, 0)
              }, 200)
            }
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.loadingDate = false
          alertErrorMessage(error)
        })
    },
    reloadData () {
      this.getLaporanHarian(this.selectedDate, true)
      this.$emit('reloadPageProyek', { componentId: this.componentId, jobId: this.jobId })
    },
    handleSelectedDate (date) {
      this.selectedDate = date
      this.getLaporanHarian(date)
    },
    checkScrollJadwal () {
      const div = this.$refs.contentJadwal
      const showOffset = 15

      if (div) {
        this.isScrolledJadwal.isScroll = div.scrollWidth > div.clientWidth

        if (div.scrollLeft < showOffset) {
          this.isScrolledJadwal.showRightImg = true
          this.isScrolledJadwal.showLeftImg = false
        } else if (Math.floor(div.scrollLeft) + div.clientWidth >= div.scrollWidth - showOffset) {
          this.isScrolledJadwal.showRightImg = false
          this.isScrolledJadwal.showLeftImg = true
        } else if (div.scrollLeft > showOffset) {
          this.isScrolledJadwal.showRightImg = true
          this.isScrolledJadwal.showLeftImg = true
        }
      } else {
        this.isScrolledJadwal.isScroll = false
      }
    },
    addSkill (idComponent) {
      if (idComponent) {
        this.componentId = idComponent
      }

      this.modalAddSkill = !this.modalAddSkill
    },
    handleDropdownWorker (indexNoteItem, indexSkill, indexWorker) {
      const groupIndex = `${indexNoteItem}-${indexSkill}-${indexWorker}`

      this.isOpenDropdownMenu = true
      const dropdownWorker = document.querySelector(`.dropdown-worker-${groupIndex}`)
      dropdownWorker.classList.toggle('show')

      if (this.indexDropdownToBeHide === null) {
        this.indexDropdownToBeHide = groupIndex
      } else {
        const hideDropdown = document.querySelector(`.dropdown-worker-${this.indexDropdownToBeHide}`)

        if (groupIndex !== this.indexDropdownToBeHide) {
          hideDropdown.classList.remove('show')
        }

        this.indexDropdownToBeHide = groupIndex
      }
    },
    hideDropdown (indexNoteItem, indexSkill, indexWorker) {
      const groupIndex = `${indexNoteItem}-${indexSkill}-${indexWorker}`

      const hideDropdown = document.querySelector(`.dropdown-worker-${groupIndex}`)
      hideDropdown.classList.remove('show')
    },

    // Assign Tukang
    handleAssignTukang (props = {}) {
      const { jobId, skillId, skillName, componentId } = props
      this.jobId = jobId
      this.skillId = skillId
      this.skillName = skillName
      this.componentId = componentId

      this.modalAssignTukang = !this.modalAssignTukang

      if (!this.modalAssignTukang) {
        // this.jobId = null
        this.skillId = null
        this.skillName = null
        this.dataTukang = null
      }
    },

    // Ganti Status Absen
    handleChangeAttandance () {
      this.modalChangeAttandance = !this.modalChangeAttandance
    },

    // Ganti Tukang
    handleChangeWorker (props = {}) {
      const { indexNoteItem, indexSkill, indexWorker, jobId, skillId, dataTukang, componentId } = props
      this.hideDropdown(indexNoteItem, indexSkill, indexWorker)
      if (dataTukang.attendance_status === true && dataTukang.image_photo_before.length > 0 && dataTukang.image_photo_after.length > 0) {
        this.$swal({
          imageUrl: saveImage,
          imageAlt: 'save data',
          width: '500px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Tukang Jagoan tidak dapat diganti</p>',
          html: `
            <div>
              <p class="mb-3 label-14">Tukang jagoan telah melengkapi seluruh catatan kehadiran</p>
              <p class="averta-bold py-4 px-2 has-background-info-light color-blue-1 border-radius-10">Ubah status atau hapus salah satu foto sebelum atau sesudah untuk mengganti tukang jagoan</p>
            </div>
          `,
          confirmButtonText: 'Mengerti',
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
          }
        })
      } else {
        this.jobId = jobId
        this.skillId = skillId
        this.componentId = componentId
        this.modalAssignTukang = !this.modalAssignTukang
        this.dataTukang = dataTukang
      }
    },

    // Ubah Status Absen
    handleEditStatusAttendance (props = {}) {
      const { indexNoteItem, indexSkill, indexWorker, data, jobId, componentId } = props
      this.hideDropdown(indexNoteItem, indexSkill, indexWorker)

      const filterData = {
        job_id: data.itemTukang.job_id,
        attendance_status: data.itemTukang.attendance_status,
        attendance_date: data.itemTukang.attendance_date,
        worker_id: data.itemTukang.worker_id,
        worker_name: data.itemTukang.worker_name,
        worker_image: data.itemTukang.worker_image,
        skill_id: data.itemAttendance.skill_id,
        skill_name: data.itemAttendance.skill_name,
        skill_image: data.itemAttendance.skill_image,
        image_photo_before: data.itemTukang.image_photo_before,
        image_photo_after: data.itemTukang.image_photo_after,
        selected_data: this.selectedDate
      }
      this.dataWorkerAbsent = filterData

      // For Auto Scroll when updated data
      this.componentId = componentId
      this.jobId = jobId

      this.modalChangeAttandance = !this.modalChangeAttandance
    },

    // Hapus Slot Keahlian
    handleDeleteSlotSkill (props = {}) {
      const { indexNoteItem, indexSkill, indexWorker, jobId, dataTukang, isLastWorker, onlyDelete } = props
      if (!onlyDelete) {
        this.hideDropdown(indexNoteItem, indexSkill, indexWorker)
      }

      if (dataTukang && (dataTukang.attendance_status === true && dataTukang.image_photo_before.length > 0 && dataTukang.image_photo_after.length > 0)) {
        this.$swal({
          imageUrl: saveImage,
          imageAlt: 'save data',
          width: '500px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Slot keahlian tidak dapat dihapus</p>',
          html: `
            <div>
              <p class="mb-3 label-14">Tukang jagoan pada slot tersebut telah melengkapi seluruh catatan kehadiran</p>
              <p class="averta-bold py-4 px-2 has-background-info-light color-blue-1 border-radius-10">Ubah status atau hapus salah satu foto sebelum atau sesudah untuk menghapus slot kehadiran</p>
            </div>
          `,
          confirmButtonText: 'Mengerti',
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            // Do nothing
          }
        })
      } else {
        this.$swal({
          imageUrl: saveImage,
          imageAlt: 'save data',
          width: '540px',
          padding: '10px 10px 30px 10px',
          title: `<p class="averta-bold label-22">Hapus ${isLastWorker ? 'Slot & Keahlian Ini' : 'Slot Keahlian'}</p>`,
          html: `<p>${isLastWorker ? 'Jenis keahlian akan dihapus apabila tidak memiliki slot keahlian' : 'Pastikan kamu sudah cek kembali seluruh informasi telah benar dan sesuai'}</p>`,
          showCancelButton: true,
          confirmButtonText: `Hapus ${isLastWorker ? 'Slot & Keahlian Ini' : 'Slot Keahlian'}`,
          cancelButtonText: 'Batalkan',
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            const payload = {
              url: this.url,
              job_id: jobId
            }

            this.$store
              .dispatch('projectV2/deleteSlot', payload)
              .then((response) => {
                this.isLoading = false
                this.reloadData(this.selectedDate)
                this.$buefy.toast.open({
                  message: 'Berhasil menghapus slot keahlian',
                  type: 'is-success'
                })

                // Reset Component ID and Job ID for Auto Scroll (for Action Assign/Change Worker, Update Absent, Add Skill)
                // but in delete slot not auto scroll
                this.componentId = null
                this.jobId = null
              })
              .catch((error) => {
                this.isLoading = false
                this.$buefy.toast.open({
                  message: 'Gagal menghapus slot keahlian',
                  type: 'is-success'
                })
                console.log(error)
              })
          }
        })
      }
    },

    // Sidebar before-after
    handleShowSidebar (props = {}) {
      const { worker, noteItem } = props
      this.sidebarBeforeAfter = !this.sidebarBeforeAfter

      if (worker) {
        this.selectedDataWorker = {
          ...worker,
          note_item: noteItem
        }
      } else {
        this.selectedDataWorker = null
      }
    },

    showExtend (date) {
      if (this.extendDates.includes(date)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    dataDialyReport (val) {
      if (this.jadwalPekerjaan.length < 1) {
        this.getJadwalPekerjaan()
      }
    }
  },
  mounted () {
    this.isAssignWorker = this.isRole && ['Direncanakan', 'Proyek In-Planning', 'Proyek Aktif', 'in-planning', 'active'].includes(this.statusProject)
  },
  created () {
    this.getLaporanHarian(moment().format('YYYY-MM-DD'))
  }
}
</script>

<style scoped>
.body-wrapper {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  overflow: hidden;
}

.detail-wrapper {
  border-radius: 20px;
}

.label-cs {
  font-size: 14px;
  color: #868686;
}

.flex-1 {
  flex: 1;
}

.jenis-tukang-img,
.before-after-img,
.tukang-img {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
}

.before-after-img,
.tukang-img {
  width: 35px !important;
  height: 35px !important;
  border-radius: 35px;
}

.tukang-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.before-after-img {
  border-radius: 5px !important;
}

.jenis-tukang-img img,
.before-after-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tukang-wrapper {
  background: #f5f5f5;
  padding: 20px;
  margin-left: 3.3em;
  border-radius: 20px;
}

.status-wrapper {
  background: #9a9a9a;
  border-radius: 100px;
  color: #fff;
}

.bg-green {
  background: #10bd41 !important;
}

.bg-maroon {
  background: #911a1e !important;
}

.text-red {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.before-after-wrapper {
  min-width: 10em;
}

.modal-wrapper {
  overflow-x: hidden;
}

.header-modal {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}

.body-modal {
  background: white;
  border-radius: 0px 0px 12px 12px;
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.before-after {
  width: 380px;
  height: 380px;
  border-radius: 24px;
}

.header-detail {
  background: linear-gradient(90deg, #ffdddd 0%, #ffefdb 99.97%);
  border-radius: 12px 12px 0px 0px;
}

.group-date-wrapper {
  padding: 1em;
  border: 1px solid #e1e1e1;
  background: #fafafa;
  border-radius: 20px;
}

.parent-date-wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  margin-right: 5em;
}

.date-wrapper {
  width: 35px;
  height: 35px;
  border-radius: calc(35px / 2);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.5s;
}

.date-wrapper.active {
  color: white;
  background: linear-gradient(90deg, #891313 0%, #d92727 99.97%);
}

.right-arrow {
  position: absolute;
  z-index: 5;
  right: -2.5em;
  top: 1em;
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: rotate(180deg);
  opacity: 0;
  transition: all 0.15s ease-in-out;
}

.left-arrow {
  position: absolute;
  z-index: 5;
  left: -2.5em;
  top: 1em;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
}

.right-arrow.show,
.left-arrow.show {
  opacity: 1;
}

.loading-wrapper .card {
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.session-wrapper {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 2px;
  margin-top: 2px;
}

.skills-wrapper {
  width: 15em;
}

.item-worker-wrapper {
  width: 20em;
}

.dropdown-worker {
  position: absolute;
  right: 50px;
  top: 0;
  width: 340px;
  background: white;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 6px 24px 0px rgba(37, 37, 37, 0.1);
  opacity: 0;
  z-index: -1;
}

.dropdown-worker.mobile {
  right: -40px !important;
  top: 45px !important;
}

.dropdown-worker.last-child {
  top: auto !important;
  bottom: 0;
}

.dropdown-worker.show {
  opacity: 1;
  z-index: 1;
}

.item-menu-dropdown {
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.item-menu-dropdown:hover {
  background: #fafafa;
}

.item-menu-dropdown:last-child {
  border: none;
}

.divider-skills {
  border: 1px dashed #e1e1e1;
  margin: 1.7em 0;
}

@media only screen and (max-width: 600px) {
  .flex-sm-column {
    flex-direction: column;
  }

  .align-self-sm-start {
    align-self: flex-start;
  }

  .w-sm-100 {
    width: 100%;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mt-sm-2 {
    margin-top: 1rem;
  }
}
</style>
