<template>
  <div
    v-if="firstLoad"
    class="button-scroll-top is-clickable"
    :class="{
      'show-button-animation': showButtonAnimation,
      'hide-button-animation': !showButtonAnimation
    }"
    :style="styleProps"
    @click="onClickButton"
  >
    <p v-if="titleProps" class="averta-bold color-white">{{titleProps}}</p>
    <div :style="styleIconProps" class="flex-center">
      <img
        :src="require('@/assets/img/arrow-upward.png')"
        alt=""
        :style="{
          transform: isMobile ? 'scale(0.8)' : 'scale(1.3)'
        }"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonhandleClick',
  props: {
    offsetWindowScroll: {
      type: Number,
      default: 500
    },
    addMoreScroll: {
      type: Number,
      default: 0
    },
    elementPoint: {
      type: String
    },
    titleProps: {
      type: String
    },
    styleProps: {
      type: Object
    },
    styleIconProps: {
      type: Object
    }
  },
  data () {
    return {
      firstLoad: false,
      showButtonAnimation: false
    }
  },
  computed: {
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  methods: {
    showButtonScrollUp () {
      if ((window.scrollY + this.addMoreScroll) >= this.offsetWindowScroll) {
        this.showButtonAnimation = true
        this.firstLoad = true
      } else {
        this.showButtonAnimation = false
      }
    },
    onClickButton () {
      const element = document.getElementById(this.elementPoint)
      if (element) {
        const headerOffset = 180
        const elementPosition = element.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.scrollY - headerOffset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.showButtonScrollUp)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.showButtonScrollUp)
  }
}
</script>
