<template>
  <div>
    <ModalTemplate :showModal="showModalNote" width="1050px" @closeModal="closeModal">
      <template v-slot:headerModal>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="flex-center-vertical">
            <p class="averta-bold label-18 ml-3">Pilih Item Pekerjaan</p>
          </div>
          <div class="is-clickable" @click="closeModal">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div class="wrap-body-modal">
          <div class="px-5 pt-5 pb-3">
            <b-input
              placeholder="Cari Item Pekerjaan"
              @input="debouncedSearchItemWorking"
              v-model="searchItemWorking"
            ></b-input>
            <div class="content-list-item">
              <div v-if="isLoadingItem" class="is-relative wrap-loading-item">
                <b-loading :is-full-page="false" :active="true" :can-cancel="false">
                  <SpinnerIcon />
                </b-loading>
              </div>
              <div v-else-if="!isLoadingItem && listItemWorking.length > 0" class="body">
                <div
                  v-for="(item, index) in listItemWorking"
                  :key="`item-${index}`"
                  class="content-item-working pl-3"
                >
                  <div class="py-4 mr-2">
                    <input
                      :id="`radio-${index}`"
                      :value="item.id"
                      type="radio"
                      v-model="selectedItemWorkingId"
                    />
                  </div>
                  <label :for="`radio-${index}`" @click="selectedItemWorkingId = item.id" class="py-3 pr-3 is-clickable c-w-100">
                    <div class="text-ellipsis-3">
                      <p class="color-grey mb-1">ID: {{ item.id }}</p>
                      <p class="averta-bold">{{ item.note }}</p>
                    </div>
                  </label>
                </div>
              </div>
              <div
                v-else-if="listItemWorking.length < 1"
                class="is-flex is-align-items-center is-justify-content-center is-flex-direction-column"
              >
                <ProyekEmptyIcon />
                <p class="averta-bold">Tidak Ada Item Pekerjaan</p>
              </div>
            </div>
          </div>
          <div class="footer-modal">
            <ButtonApp
              height="45px"
              width="160px"
              :isDisabled="disabledButton"
              @click="handleSubmit"
            >
              <div class="flex-center">
                <p class="averta-bold">Simpan</p>
              </div>
            </ButtonApp>
          </div>
        </div>
      </template>
    </ModalTemplate>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce, alertErrorMessage } from '@/helpers'
import { ProyekEmptyIcon, SpinnerIcon } from '@/components/icons'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'ModalItemWorking',
  components: {
    ProyekEmptyIcon,
    SpinnerIcon,
    ModalTemplate,
    ButtonApp
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    editId: {
      type: [String, Number],
      default: null
    },
    disabledId: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      getDataPilihTukang: 'project/getDataPilihTukang'
    }),
    disabledButton () {
      return !this.selectedItemWorkingId || this.disabledId.includes(this.selectedItemWorkingId)
    }
  },
  data () {
    return {
      searchItemWorking: '',
      listItemWorking: [],
      initListItemWorking: [],
      selectedItemWorkingId: null,
      isLoadingItem: false,
      showModalNote: false
    }
  },
  methods: {
    closeModal () {
      this.showModalNote = false
      this.$emit('closeModal')
    },
    filterListItemWorking () {
      this.listItemWorking = this.initListItemWorking.filter((item) => {
        return item.note_item_working.toLowerCase().includes(this.searchItemWorking.toLowerCase())
      })
    },
    debouncedSearchItemWorking: debounce(function () {
      this.filterListItemWorking()
    }, 300),
    getListItemWorking () {
      if (this.initListItemWorking.length > 0) return

      this.isLoadingItem = true
      const payload = {
        url: this.url,
        vendor_id: this.getDataPilihTukang.vendor.id
      }
      this.$store
        .dispatch('project/getListItemWorkingUnitPrice', payload)
        .then(response => {
          const res = response.data.data

          this.initListItemWorking = JSON.parse(JSON.stringify(res))
          this.listItemWorking = res

          this.isLoadingItem = false
        })
        .catch(error => {
          this.isLoadingItem = false
          alertErrorMessage(error)
        })
    },
    handleSubmit () {
      const getData = this.initListItemWorking.find((item) => item.id === this.selectedItemWorkingId)
      this.$emit('submit', getData)
    }
  },
  watch: {
    showModal (val) {
      this.showModalNote = val
      if (val) {
        // OPEN MODAL
        this.getListItemWorking()
      } else {
        // CLOSE MODAL
        this.selectedItemWorkingId = null
      }
      this.selectedItemWorkingId = this.editId || null
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.footer-modal {
  box-shadow: 0px -3px 30px 0px #00000012;
  padding: 20px 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: flex-end;
}

.content-list-item {
  height: 21em;
  overflow: auto;
  border-top: 1px dashed $color-grey-2;
  margin-top: 1.5em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.content-list-item .body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.content-list-item .content-item-working {
  border: 1px solid $color-grey-2;
  background: white;
  border-radius: 12px;
  display: flex;
}

.content-item-working input[type=radio] {
  width: 1.4em;
  height: 1.4em;
}

.wrap-loading-item {
  height: 20em;
  width: 100%;
}

.text-ellipsis-3 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
