<template>
  <div>
    <b-modal v-model="showModalReschedule" :can-cancel="false" :on-cancel="closeModalReschedule">
      <div class="modal-schedule-template">
        <div class="modal-body w-100">
          <header class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold label-18">Sesuaikan Jadwal</p>
            <div class="is-clickable" @click="closeModalReschedule">
              <b-icon icon="close"></b-icon>
            </div>
          </header>
          <div class="modal-date-wrapper">
            <p class="averta-bold">Pilih satu tanggal</p>
            <div class="is-flex is-justify-content-center mt-5 mb-6">
              <v-date-picker v-model="calendarReschedule" mode="date" @dayclick="onDayClick" />
            </div>
            <ButtonApp :is-bold="true" width="100%" @click="handleSubmitReschedule">
              <div class="is-flex is-align-items-center is-justify-content-center">
                <p class="py-2 mr-2">Lanjutkan</p>
                <ArrowRightIcon color="white" />
              </div>
            </ButtonApp>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="showModalConfirmReschedule" :can-cancel="false">
      <div class="modal-confirm-template">
        <div class="is-flex is-justify-content-center">
          <img :src="require('@/assets/img/delete-area-component.png')" alt="" />
        </div>
        <p class="averta-bold label-20 my-5">Ubah Jadwal Survey?</p>
        <p class="desc-modal mb-5">Pastikan seluruh informasi yang sudah disesuaikan telah tepat dan benar.</p>
        <div class="is-flex is-flex-direction-column is-align-items-center tgl-submit-wrapper py-3 mb-5">
          <p class="label-10 color-blue-1">Tanggal Survey</p>
          <p class="averta-bold label-12">{{ convertDate(surveyDateFrom) }} menjadi {{ convertDate(calendarReschedule) }}</p>
        </div>
        <div class="is-flex is-align-items-center c-w-100">
          <button @click="handleCancelConfirm" class="btn-reject color-red mr-3 averta-bold is-clickable flex-1">Cek Kembali</button>
          <div class="flex-1">
            <ButtonApp :is-bold="true" width="100%" @click="handleConfirmReschedule">
              <p class="py-2">Proses</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="showModalSuccess" :can-cancel="true" :on-cancel="handleCloseSuccessConfirm">
      <div class="modal-confirm-template">
        <div class="is-flex is-justify-content-center">
          <img :src="require('@/assets/img/survey-selesai.png')" width="200" alt="" />
        </div>
        <p class="averta-bold label-20 my-5">Jadwal Berhasil Diubah</p>
        <p class="desc-modal mb-5">Rencana kunjungan survey telah disesuaikan dengan jadwal baru</p>
        <ButtonApp :is-bold="true" width="100%" @click="handleCloseSuccessConfirm">
          <p class="py-2 mr-2">Tutup</p>
        </ButtonApp>
      </div>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoadingSpinner" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import moment from 'moment'
import { ArrowRightIcon, SpinnerIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
import { alertErrorMessage } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'ModalCalendarReschedule',
  props: {
    showModalRescheduleProps: {
      type: Boolean,
      required: true
    },
    surveyDateTo: {
      type: String
    },
    surveyDateFrom: {
      type: String
    },
    pathAPIProps: {
      type: String
    },
    isFromBS: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ArrowRightIcon,
    ButtonApp,
    SpinnerIcon
  },
  data () {
    return {
      isLoadingSpinner: false,
      showModalReschedule: false,
      showModalConfirmReschedule: false,
      showModalSuccess: false,
      calendarReschedule: this.surveyDateTo // passing props
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    closeModalReschedule () {
      this.showModalReschedule = false
      this.$emit('handleCloseModalReschedule', { isOpenModal: false })
    },
    convertDate (date) {
      if (!date) return '-'
      return moment.utc(date).format('DD MMMM YYYY')
    },
    handleSubmitReschedule () {
      if ((this.calendarReschedule === this.surveyDateTo) && this.isFromBS) {
        return alertErrorMessage('Jadwal survey tidak boleh sama')
      }
      this.closeModalReschedule()
      this.showModalConfirmReschedule = true
    },
    handleConfirmReschedule () {
      this.isLoadingSpinner = true
      const payload = {
        url: this.url,
        path: this.pathAPIProps,
        data: {
          survey_id: this.$route.params.surveyId,
          status: 'approve',
          date: this.calendarReschedule
        }
      }

      this.$store
        .dispatch('project/postReschedule', payload)
        .then(() => {
          this.isLoadingSpinner = false
          this.showModalConfirmReschedule = false
          this.showModalSuccess = true
          this.calendarReschedule = null
        })
        .catch((error) => {
          alertErrorMessage(error)
          this.isLoadingSpinner = false
        })
    },
    handleCancelConfirm () {
      this.showModalConfirmReschedule = false
      this.showModalReschedule = true
      this.$emit('handleCloseModalReschedule', { isOpenModal: true })
    },
    handleCloseSuccessConfirm () {
      this.showModalSuccess = false
      this.activeTab = 2
      this.$emit('handleCloseModalReschedule', { isOpenModal: false, isFinish: true })
    },
    onDayClick (day) {
      this.calendarReschedule = day.id
    }
  },
  watch: {
    showModalRescheduleProps (val) {
      this.showModalReschedule = val
      if (val === true) {
        this.calendarReschedule = this.surveyDateTo
      }
    },
    calendarRescheduleProps (val) {
      this.calendarReschedule = val
    }
  }
}
</script>

<style scoped>
.btn-reject {
  border: none;
  outline: none;
  padding: 1em 0em;
  border: 1px solid #D9272D;
  background: transparent;
  border-radius: 100px;
}

.modal-schedule-template {
  width: 25em;
  margin: auto;
}

.modal-body {
  overflow: hidden;
  background: white;
  border-radius: 10px;
  height: 100%;
}

.modal-schedule-template header {
  background-color: #911a1e;
  height: 5em;
  color: white;
  padding: 0 20px;
}

.modal-date-wrapper {
  background: white;
  padding: 1em 1.5em;
  position: relative;
}

.modal-confirm-template {
  width: 30em;
  margin: auto;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 2em;
  align-items: center;
}

.desc-modal {
  width: 80%;
  text-align: center;
}

.tgl-submit-wrapper {
  background: #FAFAFA;
  border-radius: 20px;
  width: 100%;
}
</style>
