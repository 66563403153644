<template>
  <div class="px-5">
    <div class="is-flex is-align-items-center is-justify-content-space-between">
      <p class="averta-bold label-20">Performa Bulanan</p>
        <b-dropdown aria-role="list" v-model="selectedMonth">
          <template #trigger="{ active }">
              <b-button
                  :label="selectedMonth ? selectedMonth.month : monthNow"
                  :icon-right="active ? 'menu-up' : 'menu-down'" />
          </template>

          <b-dropdown-item aria-role="listitem" v-for="data in listMonth" :key="data.value" :value="data" @click="performaBulanan()">
            {{ data.month }}
          </b-dropdown-item>
        </b-dropdown>
    </div>
    <div class="columns mt-3">
      <div class="column card col-2 p-3 m-2">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="label-20 averta-bold">Survey Selesai</p>
          <p class="label-50 averta-bold my-2">{{ dataCard.monthly.survey_complete }}</p>
          <hr />
          <a class="bottom-card" @click="goToProject()">
            <span class="mr-2 averta-bold">Selengkapnya</span> <ArrowRightIcon />
          </a>
        </div>
      </div>
      <div class="column card col-3 p-3 m-2">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="label-20 averta-bold">Penawaran Ditinjau Client</p>
          <p class="label-50 averta-bold my-2">{{ dataCard.monthly.offering_pending }}</p>
          <hr />
          <a class="bottom-card" @click="goToProject()">
            <span class="mr-2 averta-bold">Selengkapnya</span> <ArrowRightIcon />
          </a>
        </div>
      </div>
      <div class="column card col-4 p-3 m-2">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="label-20 averta-bold">Penawaran Disetujui Client</p>
          <p class="label-50 averta-bold my-2">{{ dataCard.monthly.offering_approved }}</p>
          <hr />
          <a class="bottom-card" @click="goToProject()">
            <span class="mr-2 averta-bold">Selengkapnya</span> <ArrowRightIcon />
          </a>
        </div>
      </div>
      <div class="column card col-5 p-3 m-2">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="label-20 averta-bold">Penawaran Ditolak</p>
          <p class="label-50 averta-bold my-2">{{ dataCard.monthly.offering_rejected }}</p>
          <hr />
          <a class="bottom-card" @click="goToProject()">
            <span class="mr-2 averta-bold">Selengkapnya</span> <ArrowRightIcon />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import ArrowRightIcon from '../icons/ArrowRightIcon.vue'

export default {
  name: 'CardDashboard',
  props: ['dataCard'],
  components: {
    ArrowRightIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    })
  },
  data () {
    return {
      countData: null,
      listMonth: [],
      selectedMonth: null,
      monthNow: moment().format('MMMM YYYY')
    }
  },
  methods: {
    generateMonth () {
      for (let month = 0; month <= 11; month++) {
        const temp = {
          month: moment().month(month).format('MMMM YYYY'),
          value: moment().month(month).format('YYYY-MM')
        }
        // console.log(temp)
        this.listMonth.push(temp)
      }
    },
    performaBulanan () {
      const data = {
        start_date: moment(this.selectedMonth.value).startOf('month').format('YYYY-MM-DD'),
        end_date: moment(this.selectedMonth.value).endOf('month').format('YYYY-MM-DD')
      }

      this.$emit('changeDataDashboard', data)
    },
    goToProject () {
      const roleName = this.user.roles[0].name
      if (roleName === 'Site Manager') {
        this.$router.push('/sm/project')
      } else {
        this.$router.push('/project')
      }
    }
  },
  created () {
    this.generateMonth()
  }
}
</script>

<style lang="scss" scoped>

.card {
    box-shadow: none;
}
.card-body-proyek {
  z-index: 1;
  position: relative;
}
.bottom-card {
  display: flex;
  justify-content: end;
  align-items: center;
  color: white;
  z-index: 1;
}

.bottom-card svg {
  fill: white;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}
.col-1 {
  background: linear-gradient(90deg, #3185FC 0%, #31B3FC 100%);
  border-radius: 20px;
  color: white;
}
.col-2 {
  background: linear-gradient(90deg, #751CE6 0%, #552BFF 100%);
  border-radius: 20px;
  color: white;
}
.col-3 {
  background: linear-gradient(90deg, #FFAF24 0%, #FFC224 100%);
  border-radius: 20px;
  color: white;
}
.col-4 {
  background: linear-gradient(90deg, #10BD41 0%, #55F883 100%);
  border-radius: 20px;
  color: white;
}
.col-5 {
  background: linear-gradient(90deg, #EB4600 0%, #FF8C5B 100%);
  border-radius: 20px;
  color: white;
}
</style>
