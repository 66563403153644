<template>
  <div>
      <div class="card p-4 is-flex is-justify-content-space-between is-align-items-center flex-sm-column align-items-sm-start" :class="{'rejected': badge === 'rejected', 'border-12':newMaterial.length < 1}">
          <div class="is-flex is-align-items-center c-w-100">
              <div class="is-flex is-align-items-center mb-3 mr-2 mb-sm-0">
                  <img :src="require('@/assets/img/penawaran-pending.png')" alt="penawaran-pending" class="mr-3">
              </div>
              <div class="is-flex flex-sm-column c-w-100 is-justify-content-space-between">
                  <div class="desc-wrapper">
                      <p class="averta-bold is-size-5">{{renderText.title}}</p>
                      <p v-if="badge === 'rejected'" class="desc is-hidden-mobile color-white label-16">{{renderText.desc}}</p>
                      <p v-else class="desc label-16 is-hidden-mobile">{{renderText.desc}} Butuh bantuan?
                          <a href="#" class="averta-bold has-text-dark white-space-nowrap" :class="{'color-white': badge === 'rejected'}"><b-icon icon="whatsapp" custom-size="default" />Site Manager</a>
                          <a href="#" class="averta-bold has-text-dark" :class="{'color-white': badge === 'rejected'}"><b-icon icon="whatsapp" custom-size="default" />Konsultan</a>
                      </p>
                  </div>
                  <div v-if="badge === 'rejected'" class="cursor-pointer m-1">
                      <b-button class="averta-bold btn-lengkapi light label-14" rounded @click.prevent="handlePenawaranReject">
                          <span class="color-red">Lihat Penawaran Reject</span>
                      </b-button>
                  </div>
                  <div v-if="can_complete_offering" class="cursor-pointer m-1">
                      <b-button class="averta-bold btn-lengkapi label-14 color-white" rounded @click.prevent="showCompletedOffer">
                          + Lengkapi Penawaran
                      </b-button>
                  </div>
              </div>
          </div>
          <div class="desc-wrapper is-hidden-tablet">
              <p class="desc label-16" :class="{'color-white': badge === 'rejected'}">{{renderText.desc}} Butuh bantuan?
                  <a href="#" class="averta-bold has-text-dark white-space-nowrap" :class="{'color-white': badge === 'rejected'}"><b-icon icon="whatsapp" custom-size="default" />Site Manager</a>
                  <a href="#" class="averta-bold has-text-dark" :class="{'color-white': badge === 'rejected'}"><b-icon icon="whatsapp" custom-size="default" />Konsultan</a>
              </p>
          </div>
      </div>
      <div v-if="newMaterial.length > 0" class="wrap-collapse" ref="wrapCollapse">
        <b-collapse animation="slide" :open="false">
            <template #trigger="props">
                <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                    <div class="card-header-title is-block">
                        <p class="averta-black label-14">{{newMaterial.length}} Material Baru</p>
                        <p class="has-text-grey averta-reguler label-12">Terdapat material baru yang belum tersimpan untuk penawaran ini</p>
                    </div>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'menu-up' : 'menu-down'">
                        </b-icon>
                    </a>
                </div>
            </template>

            <div class="card-content">
                <div class="content-collapse columns" v-for="material, indexMaterial in newMaterial" :key="`${indexMaterial}-new-material`">
                  <div class="column is-4">
                    <p class="has-text-grey">Nama Material</p>
                    <p class="averta-bold">{{ material.name }} <span v-if="material.is_custom === 0" class="averta-regular text-merah ml-2"><i>Material Custom</i></span></p>
                  </div>
                  <div class="column is-3">
                    <p class="has-text-grey">Jumlah Dibutuhkan</p>
                    <p class="averta-bold">{{ material.total_required }}</p>
                  </div>
                  <div class="column is-3">
                    <p class="has-text-grey">Jumlah Dipenawaran</p>
                    <p class="averta-bold">{{ material.total_offer }}</p>
                  </div>
                  <div class="column is-2 is-flex is-justify-content-center" v-if="material.is_confirmed === 0">
                    <div class="wrap-button is-flex is-justify-content-center" @click="handleConfirmMaterial(material)">
                      <p class="averta-bold">Konfirmasi Material</p>
                    </div>
                  </div>
                  <div class="column is-2 is-flex is-justify-content-center" v-else>
                    <img :src="require('@/assets/img/confirm-material.svg')" />
                  </div>
                </div>
            </div>
        </b-collapse>
      </div>

      <GuideApp v-if="isNewMaterial" :steps="tourSteps"/>
  </div>
</template>

<script>
import GuideApp from '@/components/GuideApp'

export default {
  name: 'CardStickyNotif',
  emits: ['submit'],
  props: ['badge', 'can_complete_offering', 'newMaterial'],
  components: {
    GuideApp
  },
  computed: {
    renderText () {
      switch (this.badge) {
        case 'waiting-purchasing':
          return { title: 'Lengkapi Durasi Preparasi Material', desc: 'Material akan termasuk ke dalam penawaran yang akan dikirimkan ke client.' }
        case 'waiting-am':
          return { title: 'Menunggu Approval Business Support', desc: 'Business Support akan mereview dan melengkapi penawaran untuk dikirimkan ke client.' }
        case 'pending-client':
          return { title: 'Menunggu Approval Client', desc: 'Client akan mereview dan melakukan approval untuk penawaran ini.' }
        case 'waiting-project':
          return { title: 'Menunggu Order Proyek Baru Dibuat', desc: 'Business Support akan membuat order proyek untuk penawaran ini.' }
        case 'rejected':
          return { title: 'Penawaran Rejected', desc: 'Ada update penawaran dari Konsultan Survey. Silahkan periksa!', rejected: true }

        default:
          return { title: this.badge, desc: '' }
      }
    },
    isNewMaterial () {
      let validation = false

      this.newMaterial.forEach((item, index) => {
        if (item.is_confirmed === 0) {
          validation = true
        }
      })

      return validation
    }
  },
  data () {
    return {
      tourSteps: [
        {
          text: `
            <div class="has-text-centered">
              <p class="averta-black label-18 mb-2">Ada Material Baru</p>
              <p class="averta-regular">Konfirmasi material baru untuk melanjutkan penawaran</p>
            </div>
          `,
          attachTo: {
            element: '.wrap-collapse',
            on: 'bottom'
          },
          buttons: [
            {
              action: function () {
                return this.hide()
              },
              text: 'Tutup',
              classes: 'button-close-guide'
            }
          ],
          id: 1
        }
      ]
    }
  },
  methods: {
    showCompletedOffer () {
      this.$emit('submit')
    },
    handlePenawaranReject () {
      this.$store.commit('project/setActiveTab', { route: 'purchasing', activeTab: 1, subActiveTab: 'rejected' })
      this.$router.push('/purchasing/project')
    },
    handleConfirmMaterial (data) {
      this.$emit('openModalConfirmMaterial', data)
    }
  }
}
</script>

<style scoped>
.card {
    background: linear-gradient(90deg, #FFDDDD 0%, #FFEFDB 99.97%);
    border-radius: 12px 12px 0px 0px;
    margin-bottom: 0px;
}

.border-12 {
  border-radius: 12px !important;
}

.card.rejected {
    background: linear-gradient(90deg, #891313 0%, #D92727 99.97%) !important;
    color: white !important;
}

.desc-wrapper .desc {
    color: #4A4A4A;
}

.wrap-collapse {
  background: white;
  border: 1px solid #E1E1E1;
  border-radius: 0px 0px 12px 12px;
}

.content-collapse {
  background: white;
  border: 1px solid #E1E1E1;
  padding: 2px;
  border-radius: 12px;
}

.content-collapse:not(:last-child) {
  margin-bottom: 28px;
}

.wrap-button {
  background: #D9272D;
  padding: 10px 10px;
  border: 1px solid var(--gradient-red-red-primary-button, #D9272D);
  color: white;
  border-radius: 24px;
  cursor: pointer;
}

</style>
