import axios from 'axios'

/* eslint-disable */
const state = () => ({
  list: null
})

const getters = {
  getProducts: (state, getters, rootState) => {
    return state.list
  }
}

const actions = {
  getListProducts ({ commit, state }, payload) {
    let payloadUrl = `${process.env.VUE_APP_DATA_PRODUCTS}?search=${payload.search || ''}`

    if (payload.offering) {
      payloadUrl += `&offering=true&survey_id=${payload.survey_id}`
    } else {
      payloadUrl += `&page=${payload.page || 1}&limit=${payload.limit || 10}`
    }

    let url = `${payload.url}${payloadUrl}`
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postDataProduct ({ commit, state }, payload) {
    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const data = new FormData()
    data.append('name', payload.name)
    if (payload.picture) {
      data.append('product_image', payload.picture)
    }
    if (payload.offering) {
      data.append('survey_id', payload.survey_id)
    }
    
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_DATA_PRODUCTS}${payload.offering ? '/request' : ''}`, data, config).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateDataProduct ({ commit, state }, payload) {
    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const data = new FormData()
    data.append('name', payload.name)
    data.append('is_active', payload.is_active)
    if (payload.picture) {
      data.append('product_image', payload.picture)
    }
    
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_DATA_PRODUCTS}/${payload.id}/update`, data, config).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  confirmRequestMaterial ({ commit, state }, payload) {
    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const data = new FormData()
    data.append('name', payload.name)
    if (payload.picture) {
      data.append('product_image', payload.picture)
    }
    
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_DATA_PRODUCTS}/${payload.id}/confirm`, data, config).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  deleteDataProduct ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${payload.url}${process.env.VUE_APP_DATA_PRODUCTS}/${payload.id}/delete`).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
}

const mutations = {
  setDataProducts (state, val) {
    state.list = val
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
