<template>
  <div v-if="dataHistoryReschedule.length === 0" style="min-height: 50vh">
    <div class="columns is-centered">
      <div class="column has-text-centered">
        <ProyekEmptyIcon />
        <p class="averta-bold">Tidak Ada Riwayat Reschedule</p>
      </div>
    </div>
  </div>
  <div v-else class="wrap-history">
    <div class="card p-4" v-for="dataHistory, indexHistory in dataHistoryReschedule" :key="`${indexHistory}-history`">
      <div class="wrap-header is-flex is-align-items-center is-justify-content-space-between pb-4">
        <div class="is-flex is-align-items-center">
          <img :src="require('@/assets/img/calendar.png')" alt="calendar" class="mr-3" />
          <div>
            <p class="has-text-info">Jadwal Ulang Survey</p>
            <p>Permintaan Jadwal Ulang Survey <span class="averta-bold label-16">{{convertDate(dataHistory.actually_date || dataHistory.date)}}</span></p>
            <p>Diperbarui dari <span class="averta-bold text-merah">{{convertDate(dataHistory.original_date)}}</span></p>
          </div>
        </div>
        <div :class="`wrap-status has-text-white px-4 py-2 ${statusReschedule(dataHistory.status)}`">
          <p class="averta-bold">{{statusReschedule(dataHistory.status, true)}}</p>
        </div>
      </div>
      <div class="pt-4">
        <div :class="{'mb-5': dataHistory.date || dataHistory.reason}" v-if="dataHistory.created_by_name">
          <p class="text-grey">Oleh</p>
          <p class="averta-bold label-14">{{dataHistory.created_by_name}} {{dataHistory.created_by ? `#${dataHistory.created_by}` : ''}} <span class="is-italic averta-bold label-12 color-gray" v-if="dataHistory.created_by_role">{{dataHistory.created_by_role}}</span></p>
        </div>
        <div :class="{'mb-5': dataHistory.reason}" v-if="dataHistory.date && dataHistory.status !== 'Ditolak' && dataHistory.status !== 'Disetujui'">
          <p class="text-grey">Permintaan Jadwal Ulang Survey</p>
          <p class="averta-bold label-14">{{convertDate(dataHistory.actually_date || dataHistory.date)}}</p>
        </div>
        <div v-if="dataHistory.reason">
          <p class="text-grey">Alasan Jadwal Ulang Survey</p>
          <p class="averta-bold label-14">{{dataHistory.reason}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ProyekEmptyIcon } from '@/components/icons'

export default {
  name: 'TabDetailHistoryReschedule',
  props: {
    dataHistoryReschedule: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    ProyekEmptyIcon
  },
  methods: {
    convertDate (date) {
      if (!date) {
        return ''
      }
      return moment(date).format('dddd, DD MMMM YYYY (HH:mm)')
    },
    statusReschedule (val, name) {
      switch (val) {
        case 'approve':
          return name ? 'Disetujui' : 'bg-green-2'
        case 'reject':
          return name ? 'Ditolak' : 'bg-red'
        case 'pending':
          return name ? 'Dalam Proses' : 'bg-dark-yellow'
        case 'cancel':
          return name ? 'Dibatalkan' : 'bg-dark-grey-4'
        default:
          return name || 'bg-dark-grey-4'
      }
    }
  }
}
</script>

<style scoped>
.wrap-history {
  margin-bottom: 20px;
}

.card {
  background: #fafdff;
  border: 2px solid #e5e5e5;
  border-radius: 12px;
}

.wrap-header {
  border-bottom: 2px dashed #e5e5e5;
}

.wrap-status {
  border-radius: 24px;
}
</style>
