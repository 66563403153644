<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.5 11.5L9 9M10.6667 7.33333L11.5 6.5M8.16667 4L8.5525 3.55333C9.334 2.77194 10.3939 2.333 11.499 2.33308C12.6042 2.33316 13.664 2.77225 14.4454 3.55375C15.2268 4.33525 15.6657 5.39516 15.6657 6.50029C15.6656 7.60543 15.2265 8.66527 14.445 9.44667L14 9.83333M1.5 1.5L16.5 16.5M9.83333 14L9.5025 14.445C8.71187 15.2268 7.64482 15.6653 6.53292 15.6653C5.42101 15.6653 4.35396 15.2268 3.56333 14.445C3.17363 14.0597 2.86425 13.6008 2.65309 13.0951C2.44194 12.5894 2.33322 12.0468 2.33322 11.4987C2.33322 10.9507 2.44194 10.4081 2.65309 9.90238C2.86425 9.39665 3.17363 8.93783 3.56333 8.5525L4 8.16667"
            stroke="url(#paint0_linear_954_143166)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <defs>
            <linearGradient id="paint0_linear_954_143166" x1="1.5" y1="8.86364" x2="13.5919" y2="14.7936"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#D9272D" />
                <stop offset="0.9999" stop-color="#EB4600" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>

export default {
  name: 'UngroupIcon'
}
</script>
