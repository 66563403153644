<template>
  <TableSkeleton v-if="isLoading || isLoadingRoles" class="mb-6" />
  <div v-else>
    <div class="min-h-60vh">
      <div class="is-flex is-justify-content-space-between mb-5 mt-3">
        <div class="wrapper-search">
          <b-field>
            <b-input
              placeholder="Cari Nama"
              type="search"
              icon="magnify"
              icon-clickable
              @icon-click="getDataUsers(1)"
              @keyup.native.enter="getDataUsers(1)"
              v-model="search"
            >
            </b-input>
          </b-field>
        </div>
        <div>
          <button @click="handleModalUser" class="btn-tambah averta-bold is-clickable">
            + Tambah User
          </button>
        </div>
      </div>
      <div v-if="dataUsers.length > 0">
        <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
          <tr class="bg-dark-grey-5 has-text-white has-text-centered averta-bold">
            <td class="py-4" style="width: 5%">NO</td>
            <td class="py-4" style="width: 15%">NAMA LENGKAP</td>
            <td class="py-4" style="width: 15%">EMAIL</td>
            <td class="py-4" style="width: 15%">NO. HANDPHONE/WHATSAPP</td>
            <td class="py-4" style="width: 15%">ROLE</td>
            <td class="py-4" style="width: 10%">STATUS</td>
            <td class="py-4" style="width: 5%"></td>
          </tr>
          <tr
            v-for="(item, index) in dataUsers"
            :key="item.id"
            class="tr-wrapper"
            :class="{ 'bg-lightgray': (index + 1) % 2 === 0 }"
          >
            <td>
              <div class="py-3 has-text-centered">
                {{
                  currentPage === 1
                    ? index + 1
                    : index + 1 === 10
                    ? `${currentPage}0`
                    : `${currentPage - 1}${index + 1}`
                }}
              </div>
            </td>
            <td>
              <div class="py-3 is-flex is-justify-content-space-between averta-bold">
                <p>{{ item.name }}</p>
              </div>
            </td>
            <td>
              <div class="py-3 is-flex is-justify-content-space-between averta-bold">
                <p>{{ item.email }}</p>
              </div>
            </td>
            <td>
              <div class="py-3 is-flex is-justify-content-space-between averta-bold">
                <p>{{ item.phone }}</p>
              </div>
            </td>
            <td>
              <div class="py-3 is-flex is-justify-content-space-between averta-bold">
                <p>{{ roleTitle(item.role) }}</p>
              </div>
            </td>
            <td>
              <div class="py-3 status-wrapper">
                <p
                  :class="{
                    'status-active': item.is_active === 1,
                    'non-active': item.is_active === 0
                  }"
                >
                  {{ item.is_active === 1 ? 'Aktif' : 'Tidak Aktif' }}
                </p>
              </div>
            </td>
            <td>
              <div class="py-3 is-flex is-justify-content-space-evenly is-align-items-center">
                <span @click="handleEditItem(item.id)" class="cursor-pointer">
                  <img :src="require('@/assets/img/icon-edit.png')" alt="" />
                </span>
                <span @click="handleConfirmDelete(item.id, item.name)" class="cursor-pointer">
                  <img :src="require('@/assets/img/icon-delete.png')" alt="" />
                </span>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="column c-w-100 has-text-centered">
        <ProyekEmptyIcon />
        <p class="averta-bold mt-2">
          Data Tidak Ditemukan
        </p>
      </div>
    </div>

    <PaginationApp
      class="my-6"
      :isNew="true"
      :total-pages="totalPages"
      :current-page="currentPage"
      :load-page="getDataUsers"
      :limit-data="10"
      :total-data="totalData"
    />

    <ModalUser
      :modalType="modalType"
      :userId="userId"
      :showModal="showModal"
      :roles="dataRoles"
      @closeModal="closeModalUser($event)"
    />

    <ModalConfirm
      :showModal="showModalDelete"
      imageClass="mb-0"
      imageProps="sunset.png"
      :titleProps="`Hapus ${userName}?`"
      descProps="Pastikan kamu sudah lakukan pengecekkan sebelum menghapus data ini."
    >
      <template v-slot:button>
        <div class="is-flex is-justify-content-space-between c-w-100">
          <ButtonApp
            width="185px"
            height="52px"
            @click="handleConfirmDelete"
            class="mr-3 flex-1"
            :isSecondary="true"
          >
            <p class="averta-bold">Cek Kembali</p>
          </ButtonApp>
          <ButtonApp
            width="185px"
            height="52px"
            @click="handleDeleteItem"
            class="flex-1"
            :isDisabled="isLoadingDelete"
          >
            <p class="averta-bold">Hapus User</p>
          </ButtonApp>
        </div>
      </template>
    </ModalConfirm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ProyekEmptyIcon } from '@/components/icons'
import { alertErrorMessage, formatRoleTitle } from '@/helpers'
import TableSkeleton from '@/components/skeleton/TableSkeleton'
import PaginationApp from '@/components/PaginationApp.vue'
import ModalUser from '../components/ModalUser.vue'
import ModalConfirm from '../../vendor/components/ModalConfirm.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'DataArea',
  components: {
    ProyekEmptyIcon,
    TableSkeleton,
    PaginationApp,
    ModalUser,
    ModalConfirm,
    ButtonApp
  },
  data () {
    return {
      isLoading: false,
      isLoadingRoles: false,
      isLoadingDelete: false,
      dataUsers: [],
      dataRoles: [],
      showModal: false,
      showModalDelete: false,
      modalType: '',
      userId: null,
      userName: null,

      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      search: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    roleTitle (string) {
      return formatRoleTitle(string)
    },
    closeModalUser (event) {
      this.showModal = false
      if (event && event.refreshData) {
        if (this.modalType === 'add') {
          this.getDataUsers()
        } else {
          this.getDataUsers({ pages: this.currentPage })
        }
      }
    },
    handleEditItem (id) {
      this.userId = id
      this.modalType = 'edit'
      this.showModal = !this.showModal
    },
    handleConfirmDelete (id, name) {
      this.userId = id || null
      this.userName = name || null
      this.showModalDelete = !this.showModalDelete
      this.isLoadingDelete = false
    },
    handleDeleteItem () {
      this.isLoadingDelete = true
      const payload = {
        url: this.url,
        userId: this.userId
      }
      this.$store
        .dispatch('user/deleteUser', payload)
        .then(() => {
          this.getDataUsers()
          this.$buefy.toast.open({
            message: `<p class="averta-regular">${this.userName} Berhasil Dihapus</p>`,
            type: 'is-success'
          })
          this.handleConfirmDelete()
        })
        .catch((error) => {
          this.isLoadingDelete = false
          alertErrorMessage(error)
        })
    },
    handleModalUser () {
      this.showModal = !this.showModal
      this.modalType = 'add'
      this.dataRoles = this.dataRoles.map(obj => ({
        ...obj,
        value: false
      }))
    },
    getDataUsers (props = {}) {
      const { pages = 1 } = props
      this.isLoading = true
      const payload = {
        url: this.url,
        page: pages
      }

      if (this.search) {
        payload.search = this.search
      }

      this.$store
        .dispatch('user/getDataUsers', payload)
        .then((response) => {
          const res = response.data
          this.dataUsers = res.data
          this.totalPages = res.total_page
          this.totalData = res.total_data
          this.currentPage = res.current_page
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getDataRoles () {
      this.isLoadingRoles = true
      this.$store
        .dispatch('user/getDataRoles', { url: this.url })
        .then((response) => {
          const res = response.data.data
          this.dataRoles = res.map(obj => ({
            ...obj,
            name: obj.name === 'Account Manager' ? 'Business Support' : obj.name,
            value: false
          }))
          this.isLoadingRoles = false
        })
        .catch((error) => {
          this.isLoadingRoles = false
          alertErrorMessage(error)
        })
    }
  },
  created () {
    this.getDataUsers()
    this.getDataRoles()
  },
  watch: {
    search: function (val) {
      if (val === '') {
        this.getDataUsers()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';
.wrapper-search {
  width: 20em;
}

.btn-tambah {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  border: none;
  border-radius: 100px;
  color: white;
  padding: 12px 28px;
}

.status-active {
  color: white;
  background: $color-green-2;
  border-radius: 100px;
  padding: 4px 0px;
  width: auto;
}

.non-active {
  width: auto;
  color: white;
  background: $color-dark-grey-2;
  border-radius: 100px;
  padding: 4px 0px;
}

.text-red {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.area-name-wrapper {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tr-wrapper td {
  border: 1px solid #c0c0c0;
}

.status-wrapper {
  display: flex;
  justify-content: center;
}

.status-wrapper p {
  width: 10em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  text-align: center;
}
</style>
