<template>
  <div class="wrap">
    <div class="mb-5">
      <p class="averta-black label-20">Informasi {{title}}</p>
      <p>
        Masukkan {{title}} dari brand yang sudah diinput. {{typeSection === 'add' ? `Kamu dapat input
        lebih dari 1 ${title}.` : ''}}
      </p>
    </div>
    <div>
      <div class="wrap-form">
        <div v-for="(data, index) in dataPic" :key="index">
          <FormPropertyManager
            :isDelete="index > 0"
            :dataProps="data"
            :index="index"
            @updatePropertyManager="updatePropertyManager($event)"
            @deletePropertyManager="deletePropertyManager($event)"
            :typeSection="typeSection"
            :disabledInput="disabledInput"
          />
        </div>
        <AddPicBranchBanner
          v-if="typeSection !== 'edit'"
          @addPic="addPic"
          widthButton="160px"
          :title="`Tambah ${title} lainnya?`"
          :desc="`Kamu dapat menambahkan lebih dari 1 ${title}`"
          :titleButton="`+ ${title}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PROPERTY_MANAGER_SMRC } from '@/helpers/constants'

import FormPropertyManager from './FormPropertyManager.vue'
import AddPicBranchBanner from '../branch-components/AddPicBranchBanner.vue'

export default {
  name: 'PropertyManagerInfo',
  props: {
    currentTab: {
      type: String,
      default: null
    },
    typeSection: {
      type: String,
      default: null
    }
  },
  components: {
    FormPropertyManager,
    AddPicBranchBanner
  },
  computed: {
    ...mapGetters({
      detailPICBranch: 'client/getDetailPICBranch',
      payloadAddPicBranch: 'client/getPayloadAddPicBranch',
      clearDataPayloadPicBranch: 'client/getClearDataPayloadPicBranch'
    })
  },
  data () {
    return {
      title: PROPERTY_MANAGER_SMRC,
      dataPic: [
        {
          name: '',
          email: '',
          phone: ''
        }
      ],
      branchName: '',
      disabledInput: true,
      disabledBranchName: true
    }
  },
  methods: {
    addPic () {
      this.dataPic.push({
        name: '',
        email: '',
        phone: ''
      })
    },
    deletePropertyManager (index) {
      this.dataPic.splice(index, 1)
    },
    updatePropertyManager (data) {
      this.dataPic[data.index] = data.data
    },
    disabledBranchNameFunc (value) {
      if (
        Object.keys(value.clientSelected).length === 0 ||
        value.clientSelected === ''
      ) {
        this.disabledBranchName = true
      } else {
        this.disabledBranchName = false
      }
    },
    selectedPIC (data) {
      if (this.typeSection === 'edit' && data) {
        const { id = '', name = '', email = '', phone = '' } = data
        this.dataPic = [
          {
            id,
            name,
            email,
            phone
          }
        ]
      }
    }
  },
  created () {
    const storeToVuex = {
      type: 'dataPic',
      dataPic: this.dataPic
    }

    if (this.currentTab === 'pic') {
      this.$store.commit('client/setPayloadAddPicBranch', storeToVuex)
    }
    this.selectedPIC(this.detailPICBranch)
  },
  watch: {
    dataPic: {
      handler (newValue) {
        const storeToVuex = {
          type: 'dataPic',
          dataPic: newValue
        }

        if (this.currentTab === 'pic') {
          this.$store.commit('client/setPayloadAddPicBranch', storeToVuex)
        }
      },
      deep: true
    },
    payloadAddPicBranch: {
      handler (newValue) {
        // Harusnya area selected juga
        if (Object.keys(newValue.clientSelected).length > 0) {
          this.disabledInput = false
        } else {
          this.disabledInput = true
        }

        this.dataPic = newValue.dataPic
      },
      deep: true
    },
    clearDataPayloadPicBranch: function (newValue) {
      if (newValue === true) {
        this.dataPic = [
          {
            name: '',
            email: '',
            phone: ''
          }
        ]
        this.disabledInput = true
      }
    },
    detailPICBranch: {
      handler (newValue) {
        this.selectedPIC(newValue)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.wrap {
  padding: 40px;
  background: #f8f7fa;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
}

.wrap-form {
  background: white;
  border: 1px solid #e1e1e1;
  padding: 40px;
  border-radius: 20px;
  border-bottom: 2px solid #e1e1e1;
}

.input {
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  padding: 22px 12px;
}
</style>
