<template>
  <div class="columns mt-5 mt-md-0 is-variable is-2 w-100 mx-sm-0">
    <div class="wrap-left column is-9 mb-4 mb-sm-0 pb-sm-0">

      <!-- Start Deskripsi Proyek & Informasi Client -->
      <div class="columns is-variable is-2 mb-1">

        <!-- CLIENT FOR MOBILE ONLY -->
        <div class="client-wrapper is-hidden-desktop is-hidden-tablet px-4 py-3 mb-4">
          <p>Client : </p>
          <div class="wrap-logo">
            <img :src="dataSurvey.vendor.logo" :alt="dataSurvey.vendor.name">
          </div>
        </div>
        <!-- CLIENT FOR MOBILE ONLY -->

        <!-- Deskripsi Proyek -->
        <div class="column px-md-0 pt-sm-0 pb-sm-1">
          <div class="card-deskripsi-proyek p-4">
            <p class="averta-bold label-20 mb-5">Deskripsi Proyek</p>
            <div class="mb-5">
              <p class="sub-title">Pesanan</p>
              <p class="averta-bold">{{ dataSurvey.project.type }}</p>
            </div>

            <div class="mb-5">
              <p class="sub-title">Pesanan Dibuat</p>
              <p class="averta-bold">{{ dataSurvey.project.client_name }}</p>
            </div>
            <div>
              <p class="sub-title">Deskripsi Proyek</p>
              <p class="averta-bold white-space-preline">
                {{ dataSurvey.project.description || '-' }}
              </p>
            </div>
          </div>
        </div>
        <!-- Deskripsi Proyek -->

        <!-- Informasi Client -->
        <div class="column px-md-0 pt-sm-1">
          <CardClientInformation :client="dataSurvey.client" />
        </div>
        <!-- Informasi Client -->
      </div>
      <!-- End Deskripsi Proyek & Informasi Client -->

      <!-- Foto Masalah -->
      <div v-if="dataSurvey.survey_images.length > 0" class="card-deskripsi-proyek px-4 pt-4 mb-4 card-sm-fix-margin">
        <p class="averta-bold label-20 mb-4">Foto Masalah</p>
        <div class="is-flex img-problem-wrapper">
          <div class="img-problem cursor-pointer" v-for="(photo, indexPhoto) in dataSurvey.survey_images" :key="`${indexPhoto}-index-photo`">
            <img :src="photo" alt="foto masalah" @click="modalPreviewImage(dataSurvey.survey_images)">
          </div>
        </div>
      </div>
      <!-- End Foto Masalah -->

      <!-- Start Detail Survey -->
      <div class="card-deskripsi-proyek p-4 mb-4 card-sm-fix-margin mb-sm-1">
        <p class="averta-bold label-20 mb-4">Detail Survey</p>
        <div class="wrapper-tanggal-survey is-flex is-justify-content-space-between mb-3">
          <div class="is-flex is-align-items-center is-justify-content-space-between flex-sm-column align-items-sm-start">
            <div class="is-flex is-align-items-center">
              <CalendarIcon class="mr-3" />
              <div class="is-flex is-flex-direction-column">
                <p class="filename-text" style="font-size: 12px;">Tanggal & Waktu Survey</p>
                <div class="is-flex is-align-items-center" v-if="dataSurvey.survey_schedule || dataSurvey.detail">
                  <p class="averta-bold white-space-nowrap">{{ convertDate(dataSurvey.survey_schedule ? dataSurvey.survey_schedule.start_date : dataSurvey.detail.date) }}</p>
                  <p class="color-gray ml-1">{{ convertTime(dataSurvey.survey_schedule ? dataSurvey.survey_schedule.start_time : dataSurvey.detail.date) }}</p>
                </div>
                <div v-else>
                  <p class="averta-bold">-</p>
                </div>
              </div>
            </div>
            <div class="wrapper-skill">
              <div class="is-flex is-align-items-center averta-bold is-justify-content-end">
                <img v-if="dataSurvey.detail.skill_image" :src="dataSurvey.detail.skill_image" alt="skill_image" class="skill-image mr-3">
                <p>{{ dataSurvey.detail.skill_name ? dataSurvey.detail.skill_name : '-' }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper-tukang mb-3">
          <div class="is-flex is-justify-content-space-between is-align-items-center wrapper-detail-tukang flex-sm-column align-items-sm-start">
            <div class="is-flex is-align-items-center">
              <div v-if="dataSurvey.detail.worker_image && dataSurvey.detail.worker_image !== '-'" class="mr-3 img-photo-tukang">
                <img :src="dataSurvey.detail.worker_image" alt="worker_image" class="w-100">
              </div>
              <div>
                <p class="averta-bold">{{ dataSurvey.detail.worker_name }}</p>
                <p>{{ dataSurvey.detail.worker_id ? '# ID-' + dataSurvey.detail.worker_id : '-'}}</p>
              </div>
            </div>
            <div class="is-flex wrapper-absen">
              <p>Absen Masuk : <span style="padding-right: 2px" :class="{'averta-bold': isValidDate(dataSurvey.detail.attendance_start_date), 'color-red is-italic': !isValidDate(dataSurvey.detail.attendance_start_date)}">{{ absen(dataSurvey.detail.attendance_start_date)}}</span></p>
              <p class="mx-3 is-hidden-mobile">|</p>
              <p>Absen Keluar : <span style="padding-right: 2px" :class="{'averta-bold': isValidDate(dataSurvey.detail.attendance_end_date), 'color-red is-italic': !isValidDate(dataSurvey.detail.attendance_end_date)}">{{ dataSurvey.detail.attendance_end_date ? absen(dataSurvey.detail.attendance_end_date) : 'Belum Absen' }}</span></p>
            </div>
          </div>
          <!-- <hr v-if="dataSurvey.detail.note || dataSurvey.detail.images.length > 0"> -->
          <div v-if="dataSurvey.detail.note" class="mb-3 note-konsultan">
            <p class="averta-bold">Catatan dari konsultan</p>
            <p class="white-space-preline">{{ dataSurvey.detail.note !== 'null' && dataSurvey.detail.note ? dataSurvey.detail.note : 'Belum ada catatan' }}</p>
          </div>
        </div>

        <p v-if="dataSurvey.detail.images.length > 0" class="averta-bold mb-3 mt-4">Foto Survey</p>
        <div v-if="dataSurvey.detail.images.length > 0" class="wrapper-file is-flex is-align-items-center">
          <div v-for="dataImage, indexImage in dataSurvey.detail.images" :key="dataImage.id" class="wrap-item-image flex-shrink-0 word-wrap-break">
            <img :src="dataImage.filename" alt="foto_survey" class="image-survey mb-2 is-clickable" @click="modalPreviewImage(dataSurvey.detail.images, 'photoSurvey', indexImage)">
            <b-tooltip :position="indexImage > 4 ? indexImage + 1 >= dataSurvey.detail.images.length - 3 ? 'is-left' : 'is-right' : 'is-right'" type="is-dark" :triggers="['hover']" :auto-close="true">
              <template v-slot:content>
                <div style="max-width: 300px;" class="word-wrap-break">
                  <p class="white-space-normal">{{ extractFileName(dataImage.name) }}</p>
                </div>
              </template>
              <p class="averta-bold color-blue-1 image-name white-space-normal">{{ extractFileName(dataImage.name) }}</p>
            </b-tooltip>
          </div>
        </div>
      </div>
      <!-- End Detail Survey -->

      <!-- Start Riwayat Pemeriksaan -->
      <div class="card-deskripsi-proyek p-3 card-sm-fix-margin" v-if="dataSurvey.checking_history.length > 0">
        <p class="averta-bold label-20 mb-4">Riwayat Pemeriksaan</p>

        <div class="is-flex wrapper-button">
          <b-button class="mr-3 btn-tabs mb-4" v-for="checkHistory in dataSurvey.checking_history"
            :key="checkHistory.areaName" :class="{ 'is-active averta-bold': areaName === checkHistory.area_name }"
            @click="changeIsActive(checkHistory.area_name)">{{ checkHistory.area_name }}</b-button>
        </div>

        <div>
          <div v-for="dataArea in dataSurvey.checking_history" :key="dataArea.areaName">
            <b-tabs v-if="areaName === dataArea.area_name">
              <b-tab-item v-for="dataComponents in dataArea.components" :label="dataComponents.component_name"
                :key="dataComponents.component_name">
                <p class="averta-bold mb-3">Daftar Komponen Yang Membutuhkan Perbaikan</p>
                <div v-for="dataDetailComponent in dataComponents.detail_components"
                  :key="dataDetailComponent.detail_component">
                  <div class="columns">
                    <div class="column">
                      <p class="has-text-grey-light">Nama Detail Komponen</p>
                      <p class="averta-bold">{{ dataDetailComponent.detail_component }}</p>
                    </div>
                    <div class="column">
                      <p class="has-text-grey-light">Catatan</p>
                      <p class="averta-bold">{{ dataDetailComponent.note || '-' }}</p>
                    </div>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>
      <!-- End Riwayat Pemeriksaan -->
    </div>

    <div class="wrap-right column card-sm-fix-margin">
      <!-- Berkas Lampiran -->
      <BerkasLampiran
        :dataProps="dataSurvey.sik_spk"
        :idSurvey="dataSurvey.id"
        :isSMRC="isSMRC"
        @refreshData="loadDataSurvey"
      />
      <!-- Berkas Lampiran -->

      <!-- Status Proyek -->
      <StatusProyekComponent :dataSurvey="dataSurvey" title="Status Proyek" />
      <!-- End Status Proyek -->

      <!-- Status Proyek -->
      <StatusProyekComponent :dataSurvey="dataSurvey" title="Penawaran Dalam Proses Internal" :isConfirmOffering="true" />
      <!-- End Status Proyek -->
    </div>

    <!-- Modal Preview Image -->
    <ModalPreviewImage :indexImg="indexImg" :isActive="modalPreviewImageActive" :dataImage="imagePreviewCarousel" :title="titlePreviewCarousel" @closeModal="closeModalPreviewImage()" />
    <!-- Modal Preview Image -->
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { CalendarIcon } from '@/components/icons'
import { formatCurrency, isSMRC } from '@/helpers'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import StatusProyekComponent from './detail/StatusProyekComponent'
import BerkasLampiran from './detail/BerkasLampiran'
import CardClientInformation from '@/components/card/CardClientInformation'

export default {
  name: 'TabDetailPenawaran',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      dataSurvey: 'project/getDataDetailSurvey'
    }),
    isSMRC () {
      return isSMRC(this.dataSurvey.vendor.code || '')
    }
  },
  props: ['role', 'status', 'loadDataSurvey'],
  components: {
    CalendarIcon,
    ModalPreviewImage,
    StatusProyekComponent,
    BerkasLampiran,
    CardClientInformation
  },
  data () {
    return {
      areaName: null,
      modalPreviewImageActive: false,
      imagePreviewCarousel: null,
      titlePreviewCarousel: '',
      dataProjectNote: null,
      dataPersonalNote: null,
      indexImg: 0
    }
  },
  methods: {
    modalPreviewImage (data, type, index) {
      this.indexImg = index || 0
      if (type === 'photoSurvey') {
        this.imagePreviewCarousel = data.map((item) => {
          return item.filename
        })
        this.titlePreviewCarousel = 'Foto Survey'
      } else {
        this.imagePreviewCarousel = data
        this.titlePreviewCarousel = 'Foto Masalah'
      }
      this.modalPreviewImageActive = true
    },
    closeModalPreviewImage () {
      this.modalPreviewImageActive = false
      this.imagePreviewCarousel = null
    },
    formatThousand (x) {
      return formatCurrency(x)
    },
    convertDate (date, dateSurvey) {
      if (date) {
        return moment.utc(date).format('dddd, DD MMMM YYYY')
      } else if (dateSurvey) {
        return moment.utc(dateSurvey).format('dddd, DD MMMM YYYY')
      }
    },
    convertTime (startTime, endTime) {
      if (!startTime) {
        return ''
      } else if (!endTime) {
        return ''
      }
      return `(${moment(startTime, 'HH:mm:ss').format('HH:mm')} - ${moment(endTime, 'HH:mm:ss').format('HH:mm')})`
    },
    isValidDate (date) {
      if (moment(date).isValid() && date) {
        return true
      } return false
    },
    absen (date) {
      const varDate = moment(date)
      if (varDate.isValid() && date) {
        return moment.utc(date).format('HH:mm')
      }
      return 'Belum Absen'
    },
    changeIsActive (data) {
      this.areaName = data
    },
    extractFileName (fileName) {
      if (!fileName) {
        return '-'
      }

      const parts = fileName.split('.')

      const name = parts[0]

      const lastIndex = parts.length - 1
      const format = parts[lastIndex]

      return `${name}.${format}`
    }
  },
  created () {
    this.areaName = this.dataSurvey.checking_history.length > 0 ? this.dataSurvey.checking_history[0].area_name : null
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.w-auto {
  width: auto !important;
}

.sub-title {
  color: #868686;
}

.card-deskripsi-proyek {
  border: 0.7px solid #D8D8D8;
  border-radius: 12px;
  font-size: 14px;
}

.address {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.skill-image {
  width: 30px;
}

.text-absen {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.wrapper-file {
  background: #F6F6F6;
  padding: 12px;
  border-radius: 12px;
  height: 14em;
  overflow-x: auto;
}

.wrap-item-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin: 0px 12px;
  height: 140px;
}

.image-survey {
  width: 91px;
  height: 91px;
  object-fit: cover;
  border-radius: 8px;
}

.image-name {
  width: 91px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.filename-text {
  color: #3185FC;
}

.filesize-text {
  color: #9A9A9A;
}

.btn-tabs {
  border-radius: 20px;
  color: #9A9A9A;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185FC;
  border: 1px solid #3185FC;
}

.divider {
  border: 2px solid red;
}

.wrapper-tukang {
  background: #F8F8F8;
  padding: 14px;
  border-radius: 10px;
}

.img-photo-tukang {
  width: 36px;
  height: 36px;
}
.img-photo-tukang img {
  border-radius: 100px;
  object-fit: cover;
  width: 36px;
  height: 36px;
}

.note-konsultan {
  margin-top: 1rem;
  padding-top: 0.8rem;
  border-top: 2px dashed #9A9A9A;
  max-height: 120px;
  overflow: auto;
}

.total-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #f1f2f2;
}

.img-problem-wrapper {
  overflow-x: auto;
  width: 100%;
  padding-bottom: 1em;
}

.img-problem {
  width: 7em;
  height: 7em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 1em;
  flex: none;
}

.img-problem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper-button {
  overflow-x: auto;
}

.wrapper-tanggal-survey {
  flex-direction: column;
}

.client-wrapper {
  background: #FAFAFA;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-top: -27px;
}

.wrap-logo {
  background: white;
  padding: 10px;
  border-radius: 100px;
  border: 1px solid #F0F0F0;
}

.wrap-logo img {
  height: 20px;
  object-fit: contain;
}

.wrap-left {
  height: 100vh;
  overflow-y: hidden;
  scrollbar-gutter: stable both-edges;
}

.wrap-left:hover {
  overflow-y: auto;
}

.wrap-right {
  height: 100vh;
  overflow-y: hidden;
  scrollbar-gutter: stable both-edges;
}

.wrap-right:hover {
  overflow-y: auto;
}

@media only screen and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper-tukang {
    display: block !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .wrapper-detail-tukang {
    display: block !important;
  }

  .wrapper-tanggal-survey {
    display: block !important;
  }

  .wrapper-skill div {
    justify-content: start !important;
    margin-top: 16px;
  }

  .wrapper-absen {
    display: block !important;
    margin-top: 16px;
  }

  .wrapper-button {
    overflow-y: auto;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .wrap-left {
    height: auto;
    overflow: visible;
  }
  .wrap-right {
    height: auto;
    overflow: visible;
  }
}
</style>
