import axios from 'axios'

const getDefaultState = () => {
  return {
    penawaran: null,
    detail: null,
    status: null
  }
}

const getDefaultStateList = () => {
  return {
    offerApproved: null,
    offerRejected: null,
    offerPending: null
  }
}

const state = () => ({
  penawaran: null,
  offerApproved: null,
  offerRejected: null,
  offerPending: null,
  status: null,
  detail: null
})

const getters = {
  getNewPenawaran: (state) => {
    return state.penawaran
  },
  getListApproved: state => {
    return state.offerApproved
  },
  getListRejected: (state) => {
    return state.offerRejected
  },
  getListPending: (state) => {
    return state.offerPending
  },
  getDetailPenawaran: (state) => {
    return state.detail
  },
  getStatusPenawaran: (state) => {
    return state.status
  }
}

const actions = {
  setNewPenawaran ({ commit, state }, offer) {
    commit('newPenawaran', offer)
  },
  resetState ({ commit }) {
    commit('resetState')
  },
  resetStateList ({ commit }) {
    commit('resetStateList')
  },
  offerListApproved ({ commit, state }, res) {
    axios.get(`${res.url}/api/v1/vendor/offers?status=1`)
      .then((response) => {
        if (response.data.code === 1) {
          commit('setOfferApproved', response.data.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  offerListRejected ({ commit }, value) {
    axios.get(`${value.url}/api/v1/vendor/offers?status=2`)
      .then((response) => {
        if (response.data.code === 1) {
          commit('setOfferRejected', response.data.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  offerListPending ({ commit }, value) {
    axios.get(`${value.url}/api/v1/vendor/offers?status=0`)
      .then((response) => {
        if (response.data.code === 1) {
          commit('setOfferPending', response.data.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  detailOffer ({ commit }, value) {
    axios.get(`${value.url}/api/v1/vendor/offers/${value.id}/detail`)
      .then((response) => {
        if (response.data.code === 1) {
          commit('setPenawaranDetail', response.data.data)
          commit('setPenawaranStatus', response.data.data.status)
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  aktivasiOffer ({ commit }, value) {
    return new Promise((resolve, reject) => {
      axios.post(`${value.url}/api/v1/vendor/offers/activation`, {
        offer_id: value.offer_id,
        start_date: value.start_date,
        end_date: value.end_date,
        project_estimate_completed: value.estimateCompleted
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

const mutations = {
  newPenawaran (state) {
    state.penawaran = {
      address: '',
      fee_building: 0,
      biaya_parkir: 0,
      customer_name: '',
      email: '',
      project_name: '',
      description: '',
      duration: 0,
      start_date: null,
      end_date: null,
      jenis_bangunan: '',
      building_id: '',
      latitude: -6.175381728927934,
      longitude: 106.8269489488614,
      user_id: 0,
      transaction_id: '',
      customer_id: null,
      is_pph: 0,
      client_id: null,
      cell_phone: '',
      workers: [],
      materials: [],
      workerDetails: [],
      materialDetails: []
    }
  },

  setDescription (state, val) {
    state.penawaran.description = val
  },
  setAddress (state, val) {
    state.penawaran.address = val.address
    state.penawaran.longitude = val.lng
    state.penawaran.latitude = val.lat
    state.penawaran.jenis_bangunan = val.jenis_bangunan
    state.penawaran.building_id = val.building_id
  },
  setIdCustomer (state, val) {
    state.penawaran.customer_id = val
  },
  setInfo (state, info) {
    state.penawaran.user_id = info.user_id
    state.penawaran.transaction_id = info.transaction_id
  },
  setInfoDate (state, info) {
    state.penawaran.duration = info.duration
    // state.penawaran.start_date = info.start_date
    // state.penawaran.end_date = info.end_date
  },
  setAmount (state, total) {
    state.penawaran.fee_building = total.fee_building
  },
  setCustomer (state, customer) {
    state.penawaran.customer_name = customer.name
    state.penawaran.cell_phone = customer.cell_phone
  },
  clearAddress (state) {
    state.penawaran.address = ''
    state.penawaran.longitude = ''
    state.penawaran.latitude = ''
    state.penawaran.jenis_bangunan = ''
    state.penawaran.building_id = ''
  },
  setBiayaParkir (state, biayaParkir) {
    state.penawaran.biaya_parkir = biayaParkir
  },
  setIsPph (state, isPph) {
    state.penawaran.is_pph = isPph
  },
  setIsClient (state, isClientId) {
    state.penawaran.client_id = isClientId
  },
  setProjectName (state, isProjectName) {
    state.penawaran.project_name = isProjectName
  },
  setWorkers (state, val) {
    state.penawaran.workers.push(val)
  },
  deleteSetWorkers (state, val) {
    state.penawaran.workers.splice(val, 1)
  },
  setMaterials (state, val) {
    state.penawaran.materials.push(val)
  },
  deleteSetMaterials (state, val) {
    state.penawaran.materials.splice(val, 1)
  },
  setDetailWorkers (state, val) {
    const array = val
    let data = {}
    for (let i = 0; i < array.length; i++) {
      if (array[i]) {
        data = array[i]
      }
    }
    const result = { ...data }
    state.penawaran.workerDetails.push(result)
  },
  clearSetDetailWorkers (state, val) {
    state.penawaran.workerDetails.splice(val, 1)
  },
  setDetailMaterial (state, val) {
    const array = val
    let data = {}
    for (let i = 0; i < array.length; i++) {
      if (array[i]) {
        data = array[i]
      }
    }
    const result = { ...data }
    state.penawaran.materialDetails.push(result)
  },
  deleteSetDetailMaterial (state, val) {
    state.penawaran.materialDetails.splice(val, 1)
  },
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  resetStateList (state) {
    Object.assign(state, getDefaultStateList())
  },
  setOfferApproved (state, value) {
    state.offerApproved = value
  },
  setOfferRejected (state, value) {
    state.offerRejected = value
  },
  setOfferPending (state, value) {
    state.offerPending = value
  },
  setPenawaranDetail (state, value) {
    state.detail = value
  },
  setPenawaranStatus (state, value) {
    state.status = value
  },
  setUpdatePenawaran (state, value) {
    state.penawaran.client_id = value.additional_properties.client_id
    state.penawaran.project_name = value.additional_properties.project_name
    state.penawaran.customer_name = value.additional_properties.customer_name
    state.penawaran.cell_phone = value.additional_properties.cell_phone
    state.penawaran.address = value.additional_properties.address
    state.penawaran.longitude = value.additional_properties.longitude
    state.penawaran.latitude = value.additional_properties.latitude
    state.penawaran.jenis_bangunan = value.additional_properties.jenis_bangunan
    state.penawaran.building_id = value.additional_properties.building_id
    state.penawaran.description = value.additional_properties.description
    state.penawaran.customer_id = value.additional_properties.customer_id
    state.penawaran.user_id = value.additional_properties.user_id
    state.penawaran.workerDetails.push(...value.worker_details)
    state.penawaran.materialDetails.push(...value.material_details)
    state.penawaran.duration = value.duration
    state.penawaran.biaya_parkir = value.parking_fee
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
