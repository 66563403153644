<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_2890_154301)">
      <rect width="40" height="40" rx="20" fill="url(#paint0_linear_2890_154301)" fill-opacity="0.1" />
      <mask id="mask0_2890_154301" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="9" y="11" width="22"
        height="19">
        <path d="M20 24.5V15.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13 12.5H27V15.5H13V12.5Z" fill="white" stroke="white" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
        <path d="M12 24L15 20.5H24.987L28 24" stroke="white" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
        <path d="M10 24.5H14V28.5H10V24.5ZM18 24.5H22V28.5H18V24.5ZM26 24.5H30V28.5H26V24.5Z" fill="white" stroke="white"
          stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </mask>
      <g mask="url(#mask0_2890_154301)">
        <path d="M8 8H32V32H8V8Z" fill="url(#paint1_linear_2890_154301)" />
      </g>
      <rect x="0.25" y="0.25" width="39.5" height="39.5" rx="19.75" stroke="url(#paint2_linear_2890_154301)"
        stroke-width="0.5" />
    </g>
    <defs>
      <filter id="filter0_b_2890_154301" x="-200" y="-200" width="440" height="440" filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="100" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2890_154301" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2890_154301" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_2890_154301" x1="6.00406e-05" y1="-7.50005" x2="40.0001" y2="42"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#D9272D" stop-opacity="0.6" />
        <stop offset="1" stop-color="#D92727" stop-opacity="0.4" />
      </linearGradient>
      <linearGradient id="paint1_linear_2890_154301" x1="8" y1="19.7818" x2="27.3471" y2="29.2697"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
      <linearGradient id="paint2_linear_2890_154301" x1="-25.5" y1="-8" x2="56" y2="53" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EB4600" stop-opacity="0.1" />
        <stop offset="1" stop-color="#EB0000" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'DaftarClient'
}
</script>
