<template>
  <svg
    :width="width || '20'"
    :height="height || '18'"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 0H2C0.897 0 0 0.897 0 2V16C0 17.103 0.897 18 2 18H18C19.103 18 20 17.103 20 16V2C20 0.897 19.103 0 18 0ZM17 4V6H12V4H17ZM12 8H17V10H12V8ZM2 16V2H9V16H2Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'CatalogIcon',
  props: ['color', 'width', 'height']
}
</script>
