<template>
  <b-field>
    <b-tag
      class="averta-bold font-custom"
      :class="handleGetStatusClass(tagTitle)"
      :type="tagType"
      rounded
      style="color: #ffffff">
      {{ wordingHelper(tagTitle) }} <i v-if="tagTitle === 'Menunggu Konfirmasi'" class="mdi mdi-information"></i>
    </b-tag>
  </b-field>
</template>

<script>
import { getStatusClassName, wordingMenuHelper } from '@/helpers'

export default {
  name: 'TagStatus',
  props: {
    statusTag: {
      type: String
    },
    tooltips: {
      type: String
    }
  },
  computed: {
    tagType () {
      const valueTabs = this.statusTag
      let result
      switch (valueTabs) {
        case 'waiting-approval':
          result = 'is-success'
          break
        case 'waiting-confirmation':
          result = 'is-warning'
          break
        case 'Menunggu Konfirmasi':
          result = 'is-warning'
          break
        case 'Penawaran Ditolak':
          result = 'is-danger'
          break
        case 'Penawaran Disetujui':
          result = 'is-success'
          break
        case 'Proyek Aktif':
          result = 'is-info'
          break
        case 'Proyek Lunas':
          result = 'is-blank'
          break
        default:
          result = 'is-warning'
      }
      return result
    },
    tagTitle () {
      const valueTabs = this.statusTag
      let result
      switch (valueTabs) {
        case 'waiting-approval':
          result = 'Waiting Approval'
          break
        case 'Menunggu Konfirmasi':
          result = 'Menunggu Konfirmasi'
          break
        case 'Penawaran Ditolak':
          result = 'Penawaran Ditolak'
          break
        case 'Penawaran Disetujui':
          result = 'Penawaran Disetujui'
          break
        case 'Proyek Aktif':
          result = 'Proyek Aktif'
          break
        case 'Proyek Lunas':
          result = 'Proyek Lunas'
          break
        default:
          result = this.statusTag
      }
      return result
    }
  },
  components: {},
  data () {
    return {}
  },
  methods: {
    handleGetStatusClass (status) {
      return getStatusClassName(status)
    },
    wordingHelper (value) {
      return wordingMenuHelper(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.font-custom {
  font-size: 14px;
  padding: 18px 24px;
}
.is-danger {
  background: #b52126;
}
.is-info {
  background: #3185fc;
}
.is-primary {
  background: #751ce5;
}
.is-warning {
  background: #f1d900;
}
.is-waiting {
  background: #810707;
}
.is-success {
  background: #007628;
}
.is-blank {
  background: #868686;
}
</style>
