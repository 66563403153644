<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 3.375C7.5 2.339 8.34 1.5 9.375 1.5H9.75C10.7446 1.5 11.6984 1.89509 12.4017 2.59835C13.1049 3.30161 13.5 4.25544 13.5 5.25V7.125C13.5 8.161 14.34 9 15.375 9H17.25C18.2446 9 19.1984 9.39509 19.9017 10.0983C20.6049 10.8016 21 11.7554 21 12.75V16.125C21 17.16 20.16 18 19.125 18H9.375C8.87772 18 8.40081 17.8025 8.04917 17.4508C7.69754 17.0992 7.5 16.6223 7.5 16.125V3.375Z" fill="#E84107"/>
    <path d="M15 5.24992C15.0018 3.98846 14.5475 2.76888 13.721 1.81592C15.3943 2.25588 16.9207 3.13239 18.1441 4.3558C19.3675 5.57922 20.244 7.10563 20.684 8.77892C19.731 7.9524 18.5115 7.49817 17.25 7.49992H15.375C15.2755 7.49992 15.1802 7.46041 15.1098 7.39008C15.0395 7.31976 15 7.22437 15 7.12492V5.24992ZM4.875 5.99992H6V16.1249C6 17.02 6.35558 17.8785 6.98851 18.5114C7.62145 19.1443 8.47989 19.4999 9.375 19.4999H16.5V20.6249C16.5 21.6599 15.66 22.4999 14.625 22.4999H4.875C4.37772 22.4999 3.90081 22.3024 3.54917 21.9507C3.19754 21.5991 3 21.1222 3 20.6249V7.87492C3 6.83892 3.84 5.99992 4.875 5.99992Z" fill="#E84107"/>
  </svg>
</template>

<script>
export default {
  name: 'CopasIcon'
}
</script>
