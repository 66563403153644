<template>
  <div v-if="dataDetailProyek && Object.keys(dataDetailProyek).length > 0">
    <div v-if="dataDetailProyek" class="columns">
      <div class="column card card-red p-3 m-2 is-flex is-align-items-center is-justify-content-center">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="body-card-wrapper has-text-centered">
          <p class="is-size-6">Tanggal Pengerjaan</p>
          <p class="is-size-5 averta-bold mt-2">{{formatDate(dataDetailProyek.card && dataDetailProyek.card.working_date)}}</p>
        </div>
      </div>
      <div class="column card card-red p-3 m-2 is-flex is-align-items-center is-justify-content-center">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="body-card-wrapper has-text-centered">
          <p class="is-size-6">Estimasi Tanggal Selesai</p>
          <p
            class="is-size-5 averta-bold mt-2"
          >{{formatDate(dataDetailProyek.card && dataDetailProyek.card.estimate_finish_project)}}</p>
        </div>
      </div>
      <div class="column card card-red p-3 m-2 is-flex is-align-items-center is-justify-content-center">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="body-card-wrapper has-text-centered">
          <p class="is-size-6">Durasi Proyek</p>
          <p class="is-size-5 averta-bold mt-2">{{ dataDetailProyek.card && dataDetailProyek.card.duration }} Hari</p>
        </div>
      </div>
      <div class="column card card-red p-3 m-2 is-flex is-align-items-center is-justify-content-center">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="body-card-wrapper has-text-centered">
          <p class="is-size-6">Hari Pengerjaan</p>
          <p class="is-size-5 averta-bold mt-2">{{ dataDetailProyek.card && dataDetailProyek.card.work_days }} Hari</p>
        </div>
      </div>
      <div class="column card card-red p-3 m-2 is-flex is-align-items-center is-justify-content-center">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="body-card-wrapper has-text-centered">
          <p class="is-size-6">Total Keseluruhan Tukang</p>
          <p class="is-size-5 averta-bold mt-2">{{ dataDetailProyek.card && dataDetailProyek.card.total_worker }} Tukang</p>
        </div>
      </div>
      <div class="column card card-black p-3 m-2 is-flex is-align-items-center is-justify-content-center">
        <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
        <div class="body-card-wrapper has-text-centered">
          <p class="is-size-6">Durasi Persiapan Material</p>
          <p class="is-size-5 averta-bold mt-2">{{ dataDetailProyek.card && dataDetailProyek.card.duration_material_preparation }} Hari</p>
        </div>
      </div>
      <!-- <div
        v-if="dataDetailProyek.card && dataDetailProyek.card.material_info"
        class="column card p-3 m-2"
        :class="{'card-gray': dataDetailProyek.card?.material_info?.status === 'process', 'card-green': dataDetailProyek.card?.material_info?.status === 'Sudah Terkirim'}"
      >
        <div class="body-card-wrapper has-text-centered">
          <TimeCircleIcon v-if="dataDetailProyek.card?.material_info?.status === 'process'" />
          <CheckIcon
            v-else-if="dataDetailProyek.card?.material_info?.status === 'Sudah Terkirim'"
            color="#fff"
          />
          <p class="is-size-6 averta-bold">Total Material Terkirim</p>
          <p class="is-size-6 mt-2">
            <span
              class="averta-bold"
            >{{dataDetailProyek.card?.material_info?.total === '?' ? '-' : dataDetailProyek.card?.material_info?.total}}</span> Material
            <span class="is-italic">({{dataDetailProyek.card?.material_info?.status}})</span>
          </p>
        </div>
      </div> -->
    </div>

    <TimelinePhase2
      :dataProps="dataDetailProyek.timeline"
      :durationProps="duration"
      :dateWorkingProps="dataDetailProyek.card"
      :projectDateProps="dataDetailProyek.project_date"
      :isProject="true"
      :slotSkillsProps="dataDetailProyek.slot_skills"
    />
    <!-- <NewTimeline :duration="duration" :data="timeline" :dateWorking="dataDetailProyek.card" :projectDate="dataDetailProyek.project_date" /> -->

    <p v-if="!dataDetailProyek && isLoading" class="is-italic">Empty</p>
    <div v-else class="divider"></div>

  </div>
  <div v-else class="columns is-centered">
    <div class="column has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Detail Proyek tidak ditemukan
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import { ProyekEmptyIcon, TimeCircleIcon, CheckIcon } from '@/components/icons'
import NewTimeline from '@/components/project/NewTimeline'
import TimelinePhase2 from '@/components/project/TimelinePhase2.vue'

export default {
  props: [
    'surveyId',
    'isBS',
    'dataDetailProyek',
    'isLoading',
    'changeIsActiveArea',
    'selectedAreaId',
    'projectDate',
    'duration'
  ],
  name: 'BTabDetailProyek',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    NewTimeline,
    ProyekEmptyIcon,
    // eslint-disable-next-line vue/no-unused-components
    TimeCircleIcon,
    // eslint-disable-next-line vue/no-unused-components
    CheckIcon,
    TimelinePhase2
  },
  methods: {
    getDataDetailAll () {
      this.$emit('getDataDetailAll')
    },
    formatDate (date) {
      if (!date) {
        return '-'
      }

      return moment(date).format('DD MMMM YYYY')
    }
  }
}
</script>

<style scoped>
.btn-tabs, .btn-tabs-area {
  height: auto;
  border-radius: 100px;
  color: #9a9a9a;
  border: 1px solid #e1e1e1;
}

.btn-tabs.is-active {
  background: #FFF5F5;
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  border: 1px solid #D9272D;
}

.btn-tabs-area.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
}

.card-red {
  background: linear-gradient(90deg, #891313 0%, #D92727 99.97%);
  border-radius: 20px;
  color: white;
}

.card-black {
  background: #2F2F2F;
  border-radius: 20px;
  color: white;
}

.card-green {
  border-radius: 20px;
  color: white;
  background: #10BD41;
}

.card-gray {
  border-radius: 20px;
  color: white;
  background: #535353;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.body-card-wrapper {
  position: relative;
  z-index: 1;
}

.divider {
  height: 1px;
  width: 100%;
  border-top: 1px dashed #E1E1E1;
  margin-bottom: 1em;
  margin-top: -1em;
}

</style>
