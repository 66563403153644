<template>
  <div class="wrap">
    <div class="mb-5">
      <p class="averta-black label-20">Informasi Admin</p>
      <p>
        Masukkan admin dari brand yang sudah diinput. kamu dapat input
        lebih dari 1 admin.
      </p>
    </div>
    <div>
      <div
        v-if="currentTab === 'pic' && (typeSection !== 'add' && typeSection !== 'edit')"
        class="is-flex is-justify-content-space-between mb-5"
      >
        <p class="label-14 mr-3 mt-2 white-space-nowrap">
          <span class="averta-bold">Cabang </span>
          <span class="text-merah">*</span>
        </p>
        <BranchAutoComplete />
      </div>
      <div
        class="is-flex is-justify-content-space-between is-align-items-center mb-5"
        v-else-if="
          currentTab === 'branch' &&
            (typeSection !== 'add' && typeSection !== 'edit')
        "
      >
        <p class="label-14 mr-3 white-space-nowrap">
          <span class="averta-bold ">Nama Cabang </span>
          <span class="text-merah">*</span>
        </p>
        <input
          type="text"
          class="input"
          placeholder="Masukkan nama cabang"
          v-model="branchName"
          :disabled="disabledBranchName"
        />
      </div>

      <div class="wrap-form">
        <div v-for="(data, index) in dataPic" :key="index">
          <FormAdmin
            :isDelete="index > 0"
            :dataProps="data"
            :index="index"
            @updateAdmin="updateAdmin($event)"
            @deleteAdmin="deleteAdmin($event)"
            :typeSection="typeSection"
            :disabledInput="disabledInput"
          />
        </div>
        <AddPicBranchBanner
          v-if="typeSection !== 'edit'"
          @addPic="addPic"
          title="Tambah Admin lainnya di building ini?"
          desc="Kamu dapat menambahkan lebih dari 1 Admin dalam satu building"
          titleButton="+ Admin"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormAdmin from './FormAdmin.vue'
import AddPicBranchBanner from '../branch-components/AddPicBranchBanner.vue'
import BranchAutoComplete from '../branch-components/BranchAutoComplete.vue'

export default {
  name: 'BranchInfo',
  props: {
    currentTab: {
      type: String,
      default: null
    },
    typeSection: {
      type: String,
      default: null
    }
  },
  components: {
    FormAdmin,
    AddPicBranchBanner,
    BranchAutoComplete
  },
  computed: {
    ...mapGetters({
      detailPICBranch: 'client/getDetailPICBranch',
      payloadAddBranch: 'client/getPayloadAddBranch',
      payloadAddPicBranch: 'client/getPayloadAddPicBranch',
      clearDataPayloadBranch: 'client/getClearDataPayloadBranch',
      clearDataPayloadPicBranch: 'client/getClearDataPayloadPicBranch'
    })
  },
  data () {
    return {
      dataPic: [
        {
          name: '',
          email: '',
          phone: ''
        }
      ],
      branchName: '',
      disabledInput: true,
      disabledBranchName: true
    }
  },
  methods: {
    addPic () {
      this.dataPic.push({
        name: '',
        email: '',
        phone: ''
      })
    },
    deleteAdmin (index) {
      this.dataPic.splice(index, 1)
    },
    updateAdmin (data) {
      this.dataPic[data.index] = data.data
    },
    disabledBranchNameFunc (value) {
      if (
        Object.keys(value.clientSelected).length === 0 ||
        value.clientSelected === ''
      ) {
        this.disabledBranchName = true
      } else {
        this.disabledBranchName = false
      }
    },
    selectedPIC (data) {
      if (this.typeSection === 'edit' && data) {
        const { id = '', name = '', email = '', phone = '' } = data
        this.dataPic = [
          {
            id,
            name,
            email,
            phone
          }
        ]
      }
    }
  },
  created () {
    const storeToVuex = {
      type: 'dataPic',
      dataPic: this.dataPic
    }

    if (this.currentTab === 'branch') {
      this.$store.commit('client/setPayloadAddBranch', storeToVuex)
    } else if (this.currentTab === 'pic' || this.currentTab === 'admin') {
      this.$store.commit('client/setPayloadAddPicBranch', storeToVuex)
    }
    this.selectedPIC(this.detailPICBranch)
  },
  watch: {
    branchName: function (newValue) {
      if (newValue !== '') {
        this.disabledInput = false
      } else {
        this.disabledInput = true
      }

      const storeToVuex = {
        type: 'branchName',
        branchName: newValue
      }
      this.$store.commit('client/setPayloadAddBranch', storeToVuex)
    },
    dataPic: {
      handler (newValue) {
        const storeToVuex = {
          type: 'dataPic',
          dataPic: newValue
        }

        if (this.currentTab === 'branch') {
          this.$store.commit('client/setPayloadAddBranch', storeToVuex)
        } else if (this.currentTab === 'pic' || this.currentTab === 'admin') {
          this.$store.commit('client/setPayloadAddPicBranch', storeToVuex)
        }
      },
      deep: true
    },
    payloadAddBranch: {
      handler (newValue) {
        this.branchName = newValue.branchName
        this.dataPic = newValue.dataPic
        this.disabledBranchNameFunc(newValue)
      },
      deep: true
    },
    payloadAddPicBranch: {
      handler (newValue) {
        if (Object.keys(newValue.branchSelected).length > 0) {
          this.disabledInput = false
        } else {
          this.disabledInput = true
        }

        this.dataPic = newValue.dataPic
      },
      deep: true
    },
    clearDataPayloadBranch: function (newValue) {
      if (newValue === true) {
        this.dataPic = [
          {
            name: '',
            email: '',
            phone: ''
          }
        ]
        this.branchName = ''
        this.disabledInput = true
      }
    },
    clearDataPayloadPicBranch: function (newValue) {
      if (newValue === true) {
        this.dataPic = [
          {
            name: '',
            email: '',
            phone: ''
          }
        ]
        this.disabledInput = true
      }
    },
    detailPICBranch: {
      handler (newValue) {
        this.selectedPIC(newValue)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.wrap {
  padding: 40px;
  background: #f8f7fa;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
}

.wrap-form {
  background: white;
  border: 1px solid #e1e1e1;
  padding: 40px;
  border-radius: 20px;
  border-bottom: 2px solid #e1e1e1;
}

.input {
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  padding: 22px 12px;
}
</style>
