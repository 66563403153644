<template>
  <svg :width="size" :height="size" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.37635 4.75241C5.80548 6.18114 6.12969 4.52825 7.03963 5.43755C7.91687 6.31456 8.42107 6.49026 7.30961 7.60142C7.17039 7.7133 6.28583 9.05937 3.1772 5.95161C0.0681782 2.84345 1.41347 1.95799 1.52539 1.81881C2.63954 0.704578 2.81222 1.21171 3.68947 2.08871C4.59941 2.9984 2.94721 3.32367 4.37635 4.75241Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'PhoneIcon',
  props: {
    color: {
      type: String,
      default: '#E10009'
    },
    size: {
      type: String,
      default: '10'
    }
  }
}
</script>
