<template>
  <div
    class="wrap-banner is-flex is-justify-content-space-between is-align-items-center"
  >
    <div>
      <p class="averta-black label-14 mb-1">{{renderTitle}}</p>
      <p class="label-14 mb-3">{{renderDesc}}</p>
      <ButtonApp :is-bold="true" :width="widthButton" height="33px" @click="addPic">
        {{ renderTitleButton }}
      </ButtonApp>
    </div>
    <img
      src="@/assets/img/add-pic-banner.png"
      alt="arrow-right"
      class="arrow-right"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonApp from '@/components/button/ButtonApp.vue'
import { titleBranch, titlePICBranch } from '@/helpers'

export default {
  name: 'AddPicBranchBanner',
  components: {
    ButtonApp
  },
  props: {
    title: {
      type: String
    },
    desc: {
      type: String
    },
    titleButton: {
      type: String
    },
    widthButton: {
      type: String,
      default: '160px'
    }
  },
  computed: {
    ...mapGetters({
      payloadAddBranch: 'client/getPayloadAddBranch',
      getDetailClient: 'client/getDetailClient'
    }),
    getCode () {
      const codeClient = this.payloadAddBranch.clientSelected.code
      const codeClient2 = this.getDetailClient

      if (codeClient) {
        return codeClient
      } else if (codeClient2) {
        return codeClient2.code
      }
      return ''
    },
    renderTitle () {
      return this.title || `Tambah ${this.renderTitlePICBranch} lainnya di ${this.renderTitleBranch} ini?`
    },
    renderDesc () {
      return this.desc || `Kamu dapat menambahkan lebih dari 1 ${this.renderTitlePICBranch} dalam satu ${this.renderTitleBranch}`
    },
    renderTitleButton () {
      return this.titleButton || `+ ${this.renderTitlePICBranch}`
    },
    renderTitleBranch () {
      return titleBranch(this.getCode)
    },
    renderTitlePICBranch () {
      return titlePICBranch(this.getCode)
    }
  },
  methods: {
    addPic () {
      this.$emit('addPic')
    }
  }
}
</script>

<style scoped>
.wrap-banner {
  background: linear-gradient(to right, #ffdedf, #fcdeff);
  padding: 20px;
  border-radius: 20px;
}
</style>
