import axios from 'axios'

const state = () => ({
  cardDashboard: null,
  totalUnread: 0,
  heightNavbar: null
})

const getters = {
  cardDashboard: (state, getters, rootState) => {
    return state.cardDashboard
  },
  totalNotifUnread: (state, getters, rootState) => {
    return state.totalUnread
  },
  getNavbarHeight: (state, getters, rootState) => {
    return state.heightNavbar
  }
}

const actions = {
  getCardDashboard ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_CARD_DASHBOARD}${payload.start_date ? '?start_date=' + payload.start_date + '&end_date=' + payload.end_date : ''}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataCalendarWeekly ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_CALENDAR}${payload.start_date ? '?start_date=' + payload.start_date + '&end_date=' + payload.end_date + '&filter=weekly' : '?filter=weekly'}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataCalendarDaily ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_CALENDAR}${payload.start_date ? '?start_date=' + payload.start_date + '&end_date=' + payload.end_date + '&filter=daily' : '?filter=daily'}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataDetailCalendar ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_CALENDAR}/detail?start_date=${payload.start_date}&end_date=${payload.end_date}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getPreviewNotif ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_NOTIF}/preview`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListNotif ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_NOTIF}?page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  readNotif ({ commit, state }, payload) {
    let concatUrl = 'read'

    if (payload.preview) {
      concatUrl = 'preview/read'
    }

    if (payload.id) {
      concatUrl += `/${payload.id}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_NOTIF}/${concatUrl}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getCalendarPurchasing ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_CALENDAR}${payload.start_date ? '?start_date=' + payload.start_date + '&end_date=' + payload.end_date : ''}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getNewsActivity ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_CARD_ACTIVITY}?page=${payload.page}&limit=${payload.limit}${concatUrl}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setCardDashboard (state, cardDashboard) {
    state.cardDashboard = cardDashboard
  },
  setNotifUnread (state, val) {
    state.totalUnread = val
  },
  setHeightNavbar (state, val) {
    state.heightNavbar = val
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
