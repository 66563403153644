<template>
  <div class="flex-center-vertical is-justify-content-space-between">
    <TitleHeader from="report-progress" title="Report Progress" desc="Ringkasan progress setiap proyek dan survey" />
    <div class="flex-center-vertical">

      <!-- FILTER CLIENT -->
      <div>
        <p v-if="!isLittleScroll" class="label-12 mb-1">Client</p>
        <InputAutocomplete
          :isStore="true"
          :isNew="true"
          :haveImg="true"
          :onSelectItem="handleSelectStore"
          :onClearSearch="handleSelectStore"
          header="Pilih Client"
          imgKey="logo"
          class="mr-2"
        />
      </div>

      <!-- FILTER DATE -->
      <div class="ml-3 is-relative">
        <p v-if="!isLittleScroll" class="label-12 mb-1">Filter Tanggal</p>
        <!-- INPUT DATE -->
        <div
          ref="refDateFilter"
          class="date-input-wrapper is-clickable px-3 flex-center-vertical is-relative"
        >
          <div class="flex-center c-h-100" @click="openDateFilter">
            <ScheduleIcon width="15" height="15" />
            <p class="ml-2 color-black-primary">{{renderDateFilter}}</p>
          </div>

          <div v-if="showCloseDate" class="close-wrapper ml-2" @click="closeDateFilter({isReset: true})">
            <XMarkIcon size="10" color="#000" />
          </div>

        </div>

        <div
          v-if="isOpenDate"
          v-closable2="{
            exclude: ['refDateFilter'],
            handler: 'closeDateFilter'
          }"
          class="date-report-progress-wrapper bg-white pt-4 pr-4 pb-4"
        >
          <div class="v-calendar-wrapper p-2">
            <v-date-picker
              is-range
              is-expanded
              v-model="selectedDate"
              :from-page="fromPage"
              color="red"
              @input="handleDateChange"
            />
          </div>

          <div class="predefine-wrapper pl-3">
            <div class="c-w-100">
              <div
                v-for="item, index in listPredefine"
                :key="index"
                class="btn-predefine is-clickable flex-center py-2 mb-3"
                :class="{'active': selectedPredefine === item.value}"
                @click="handleClickPredefine(item.value)"
              >
                <p class="label-14" :class="{'averta-bold color-red': selectedPredefine === item.value}">{{item.title}}</p>
              </div>
              <div class="flex-center mt-4">
                <ButtonApp :isSecondary="true" @click="closeDateFilter({isReset: true})" class="flex-1 mr-3">
                  <p class="averta-bold py-2">Batalkan</p>
                </ButtonApp>
                <ButtonApp class="flex-1" @click="onFilterDate">
                  <p class="averta-bold py-2">Pilih</p>
                </ButtonApp>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ScheduleIcon, XMarkIcon } from '@/components/icons'

import TitleHeader from './TitleHeader'
import InputAutocomplete from '@/components/input/InputAutocomplete.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'HeaderProgress',
  components: {
    ScheduleIcon,
    XMarkIcon,
    TitleHeader,
    InputAutocomplete,
    ButtonApp
  },
  props: {
    loadAPI: {
      type: Function
    },
    isLittleScrollProps: {
      type: Boolean
    }
  },
  data () {
    return {
      isOpenDate: false,
      renderDateFilter: 'Tanggal Mulai - Selesai',
      selectedDate: null, // { start: date, end: date }
      selectedPredefine: null,
      isLittleScroll: false,
      listPredefine: [
        {
          title: 'Hari ini',
          value: 'today'
        },
        {
          title: 'Kemarin',
          value: 'yesterday'
        },
        {
          title: 'Minggu ini',
          value: 'this-week'
        },
        {
          title: 'Minggu lalu',
          value: 'last-week'
        },
        {
          title: 'Bulan ini',
          value: 'this-month'
        },
        {
          title: 'Bulan lalu',
          value: 'last-month'
        },
        {
          title: '30 Hari Terakhir',
          value: 'last-30-day'
        }
      ],
      fromPage: {
        year: parseInt(moment(new Date()).format('YYYY')),
        month: parseInt(moment(new Date()).format('M'))
      },
      fromPageTemp: null
    }
  },
  computed: {
    showCloseDate () {
      if (this.renderDateFilter === 'Tanggal Mulai - Selesai' || !this.renderDateFilter) {
        return false
      }
      return true
    }
  },
  methods: {
    openDateFilter () {
      this.isOpenDate = !this.isOpenDate
      const start = (this.selectedDate && this.selectedDate.start) || null
      this.fromPage = this.fromPageTemp || {
        year: parseInt(moment(start || new Date()).format('YYYY')),
        month: parseInt(moment(start || new Date()).format('M'))
      }
    },
    closeDateFilter (props = {}) {
      const { isReset = false } = props
      this.isOpenDate = false

      if (isReset) {
        this.renderDateFilter = 'Tanggal Mulai - Selesai'
        this.selectedDate = null
        this.selectedPredefine = null
        this.loadAPI({ date: this.selectedDate })
      }
    },
    onFilterDate () {
      if (!this.selectedDate) return

      switch (this.selectedPredefine) {
        case 'today':
        case 'yesterday':
          this.renderDateFilter = moment(this.selectedDate.start).format('DD MMM YYYY')
          break
        default:
          this.renderDateFilter = `${moment(this.selectedDate.start).format('DD MMM YYYY')} - ${moment(this.selectedDate.end).format('DD MMM YYYY')}`
          break
      }

      this.isOpenDate = false
      this.loadAPI({ date: this.selectedDate })
    },
    handleClickPredefine (value) {
      const initDate = new Date()

      let start = initDate
      let end = initDate

      switch (value) {
        case 'today':
          // No changes needed for today
          break
        case 'yesterday':
          start = moment(initDate).subtract(1, 'day')
          end = moment(initDate).subtract(1, 'day')
          break
        case 'this-week':
          start = moment(initDate).startOf('week')
          end = moment(initDate).endOf('week')
          break
        case 'last-week':
          start = moment(initDate).subtract(1, 'week').startOf('week')
          end = moment(initDate).subtract(1, 'week').endOf('week')
          break
        case 'this-month':
          start = moment(initDate).startOf('month')
          end = moment(initDate).endOf('month')
          break
        case 'last-month':
          start = moment(initDate).subtract(1, 'month').startOf('month')
          end = moment(initDate).subtract(1, 'month').endOf('month')
          break
        case 'last-30-day':
          start = moment(initDate).subtract(29, 'days')
          end = moment(initDate)
          break
        default:
          break
      }

      const resultStart = new Date(start)
      const resultEnd = new Date(end)

      this.fromPageTemp = null
      this.fromPage = {
        year: parseInt(moment(resultStart).format('YYYY')),
        month: parseInt(moment(resultStart).format('M'))
      }

      this.selectedDate = { start: resultStart, end: resultEnd }
      this.selectedPredefine = value
    },
    handleDateChange (range) {
      const resultStart = new Date(range.start)

      this.fromPageTemp = {
        year: parseInt(moment(resultStart).format('YYYY')),
        month: parseInt(moment(resultStart).format('M'))
      }

      this.selectedDate = range
      this.selectedPredefine = null
    },
    handleSelectStore (props = {}) {
      const { dataVendor } = props
      this.loadAPI({ store: (dataVendor && dataVendor.store_name) || null })
    }
  },
  mounted () {
    this.isLittleScroll = this.isLittleScrollProps
  },
  watch: {
    isLittleScrollProps (val) {
      this.isLittleScroll = val
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.date-input-wrapper {
  border: 1px solid $color-grey-2;
  border-radius: 5px;
  overflow: hidden;
  min-width: 14em;
  height: 2.65em;
}

.date-report-progress-wrapper {
  position: absolute;
  right: 0;
  top: 4.5em;
  z-index: 5;
  display: flex;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  border-radius: 8px;
}

.predefine-wrapper {
  width: 15em;
  position: relative;
  z-index: 1;
  background: $color-white;
  display: flex;
  justify-content: center;
  border-left: 1px solid $color-grey-2;
}

.btn-predefine {
  border-radius: 100px;
  border: 1px solid $color-grey-2;
  background: $color-white;
  transition: 0.05s all ease-in-out;
}

.btn-predefine.active {
  border: 1px solid $color-red-1;
  background: $color-pink-2;
}

.btn-tanggal {
  width: 100%;
  border-radius: 20px;
  border: 1px $color-dark-grey-2 solid;
}

.btn-tanggal:hover {
  width: 100%;
  border-radius: 20px;
  border: 1px $color-red-1 solid;
  background: $color-grey-12;
  color: $color-red-1;
  font-family: 'Averta-Bold';
}

.close-wrapper {
  position: relative;
  right: 0;
}

.v-calendar-wrapper {
  width: 25em;
}
</style>

<style>
.v-calendar-wrapper .vc-container {
  border: none !important;
  font-family: 'Averta' !important;
}

.v-calendar-wrapper .vc-grid-cell {
  margin-bottom: 10px !important;
}

.v-calendar-wrapper .vc-day-content {
  height: 35px !important;
  width: 35px !important;
  font-size: 18px !important;
}

.v-calendar-wrapper .vc-weekday {
  font-size: 18px !important;
}

.v-calendar-wrapper .vc-highlight.vc-highlight-base-start {
  height: 100% !important;
}

.v-calendar-wrapper .vc-highlight.vc-highlight-base-end {
  height: 100% !important;
}

.v-calendar-wrapper .vc-highlight.vc-highlight-base-middle {
  height: 100% !important;
  width: 100% !important;
}

.v-calendar-wrapper .vc-highlight {
  width: 38px !important;
  height: 38px !important;
}
</style>
