<template>
  <div class="card p-4">
    <div class="is-flex is-align-items-center mb-3">
      <img src="./../../../../assets/img/waktunya-survey.png" alt="survey-process" class="mr-3" />
      <div>
        <p class="averta-bold">Review Penawaran Baru Dari Konsultan</p>
        <p class="desc">
          Review penawaran yang telah dibuat oleh konsultan sebelum ke client
          <a href="#" class="averta-bold has-text-dark"
            ><b-icon icon="whatsapp" custom-size="default" /><u>Hubungi Konsultan Survey</u></a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardWaitingConfirmation'
}
</script>

  <style scoped>
.card {
  background: rgba(49, 252, 106, 0.1);
  border: 1.5px solid #26b14d;
  border-radius: 12px;
}

.desc-wrapper .desc {
  color: #4a4a4a;
}
</style>
