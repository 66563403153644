<template>
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5003 26.8335C6.14033 26.8335 0.166992 20.8735 0.166992 13.5001C0.166992 6.14014 6.14033 0.166809 13.5003 0.166809C20.8737 0.166809 26.8337 6.14014 26.8337 13.5001C26.8337 20.8735 20.8737 26.8335 13.5003 26.8335ZM17.7537 18.4468C17.9137 18.5401 18.087 18.5935 18.2737 18.5935C18.607 18.5935 18.9403 18.4201 19.127 18.1001C19.407 17.6335 19.2603 17.0201 18.7803 16.7268L14.0337 13.9001V7.74014C14.0337 7.18014 13.5803 6.74014 13.0337 6.74014C12.487 6.74014 12.0337 7.18014 12.0337 7.74014V14.4735C12.0337 14.8201 12.2203 15.1401 12.527 15.3268L17.7537 18.4468Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'TimeCircleIcon'
}
</script>
