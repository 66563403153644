<template>
  <ModalTemplate :showModal="showModal" :width="widthModal" @closeModal="closeModal">
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <div class="flex-center-vertical">
          <PersonIcon color="#fff" />
          <p class="averta-bold label-18 ml-2">Tambah PIC Toko</p>
        </div>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>

    <template v-slot:bodyModal>
      <div>
        <div class="body-modal">
          <div class="flex-center is-justify-content-space-between mb-4">
            <p class="flex-1">Nama Lengkap <span class="has-text-danger">*</span></p>
            <b-input
              class="input-search-2 input-none c-w-55"
              placeholder="Masukkan Nama Lengkap"
              v-model="formPIC.name"
              :disabled="isLoading"
            ></b-input>
          </div>
          <div class="flex-center is-justify-content-space-between mb-4">
            <p class="flex-1">Email <span class="has-text-danger">*</span></p>
            <b-input
              class="input-search-2 input-none c-w-55"
              v-model="formPIC.email"
              placeholder="Masukkan Email"
              :disabled="isLoading"
            ></b-input>
          </div>
          <div class="flex-center is-justify-content-space-between mb-4">
            <p class="flex-1">No. Handphone / WhatsApp <span class="has-text-danger">*</span></p>
            <b-input
              class="input-search-2 input-none c-w-55"
              v-model="formPIC.phone"
              placeholder="+62 Masukan No. Handphone"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type="number"
              maxlength="14"
              :disabled="isLoading"
            ></b-input>
          </div>
        </div>
        <div class="footer-modal">
          <ButtonApp
            @click="handleCreatePIC"
            :isDisabled="isEmptyFormPIC || isLoading"
          >
            <div class="flex-center pl-1 pr-3">
              <CheckIcon color="#fff" />
              <p class="averta-bold py-2 ml-2">Simpan</p>
            </div>
          </ButtonApp>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage, toastMessage } from '@/helpers'
import { PersonIcon, CheckIcon } from '@/components/icons'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'ModalCreatePIC',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    dataHeader: {
      type: Object
    }
  },
  components: {
    CheckIcon,
    PersonIcon,
    ButtonApp,
    ModalTemplate
  },
  data () {
    return {
      isLoading: false,
      widthModal: '500px',
      formPIC: {
        name: '',
        email: '',
        phone: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isEmptyFormPIC () {
      const hasEmptyOrNull = Object.values(this.formPIC).some(value => !value)
      return hasEmptyOrNull
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
      this.formPIC = {
        name: '',
        email: '',
        phone: ''
      }
    },
    handleCreatePIC () {
      this.isLoading = true
      const payload = {
        url: this.url,
        body: {
          ...this.formPIC,
          branch_ids: [this.dataHeader.store.id]
        }
      }

      this.$store
        .dispatch('project/createPICComplaint', payload)
        .then(() => {
          toastMessage('Success Create PIC')
          this.isLoading = false
          this.closeModal()
        }).catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    }
  }
}
</script>

<style scoped>
.body-modal {
  height: 250px;
  padding: 20px 25px;
}

.footer-modal {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px -3px 30px 0px #00000012;
}
</style>
