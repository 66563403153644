<template>
  <DetailSkeleton v-if="isLoading || globalLoading || isLoadingDetail || isLoadingHeader" class="p-6" />
  <div v-else class="body-wrapper">
    <div v-if="checkIsEmpty" class="not-found-wrapper container my-fluid-container container-detail pt-5 pt-sm-1 pb-6">
      <div style="width: 150px;" class="is-flex is-align-items-center is-clickable mb-5 mt-3" @click="goBack()">
        <ArrowLeftIcon class="mr-3" />
        <p class="averta-bold is-size-5">Kembali ke list</p>
      </div>
      <div class="columns is-centered">
        <div class="column has-text-centered">
          <ProyekEmptyIcon />
          <p class="averta-bold mt-2">
            Detail Proyek tidak ditemukan
          </p>
        </div>
      </div>
    </div>

    <div v-else>
      <!-- STICKY HEADER -->
      <div class="sticky-header project is-block" :style="{ top: navbarPosition}">
        <div class="flex-center-vertical is-justify-content-space-between c-w-100 c-h-100">
          <div class="is-flex is-align-items-center ml-sm-1">
            <div class="is-flex is-align-items-center is-clickable" @click="goBack">
              <ArrowLeftIcon class="mr-3" />
            </div>
            <div>
              <div class="is-flex is-align-items-center flex-sm-column align-items-sm-start">
                <span class="averta-bold label-16 label-sm-14 mr-4">{{ conditionalIDProject }} | {{ dataHeader.store.name || dataHeader.name }} {{ dataHeader.store.unit_name ? `- ${dataHeader.store.unit_name}` : '' }}</span>
                <div class="is-flex is-align-items-center">
                  <div class="px-2 py-1 averta-bold" :class="`${statusSurveyClassName}`">
                    <span class="label-sm-10">{{ wordingHelper(dataHeader.status.status_name) }}</span>
                  </div>
                  <div class="is-flex is-align-items-center">
                    <div v-if="dataHeader.is_reschedule === 1" class="wrap-tag-reschedule-extends is-flex is-align-items-center is-justify-content-center">
                      <span>Reschedule</span>
                    </div>
                    <div v-if="dataHeader.is_extend === 1" class="wrap-tag-reschedule-extends is-flex is-align-items-center is-justify-content-center">
                      <span>Extend</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="is-hidden-mobile">
            <div class="is-flex is-flex-direction-column is-align-items-flex-end align-items-sm-start">
              <div class="is-flex is-align-items-center">
                <div v-if="isBS">
                  <ButtonApp
                    height="2.5em"
                    :is-bold="true"
                    :isSecondary="true"
                    @click="isSMRC ? handleModalPenawaranSMRC(true) : handleModalPenawaranClient(true)"
                  >
                    <p>Lihat Penawaran Client</p>
                  </ButtonApp>
                </div>
                <ButtonApp v-if="checkInvoice" height="2.5em" :is-bold="true" @click="handleDownloadInvoice" class="ml-4">
                  <div class="is-flex is-align-items-center">
                    <DocumentCheckIcon />
                    <p class="has-text-white averta-bold ml-2">Lihat Invoice</p>
                  </div>
                </ButtonApp>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertAssignTukang
        v-if="isAssignWorker"
        :show="showAlertAssignWorker"
        :isSticky="true"
        :isPinned="isPinned"
        :navbarPosition="navbarPosition"
        :isMobile="isMobile"
      />
      <CardGrandTotalProject
        v-if="isBS && isPinned && isWaitingPayment"
        @openModal="handleShowModalInvoice"
        :dataHeaderProps="dataHeader"
        :isSticky="true"
        :isPinned="isPinned"
        :navbarPosition="navbarPosition"
        :isMobile="isMobile"
      />

      <!-- ALert Assign Worker -->
      <AlertAssignTukang v-if="isAssignWorker" :show="showAlertAssignWorker" />
      <!-- ALert Assign Worker -->

      <div id="containerDetail" class="container my-fluid-container pt-3 pt-sm-1 pb-2 container-detail">
        <div
          class="is-flex is-align-items-center is-justify-content-space-between mb-5 mt-3 px-lg-6 flex-sm-column align-items-sm-start is-hidden-mobile">
          <div class="is-flex is-align-items-center is-clickable" @click="() => goBack()">
            <ArrowLeftIcon class="mr-3" />
            <p class="averta-bold is-size-5 white-space-nowrap">Kembali ke list</p>
          </div>
          <div class="is-flex is-align-items-center">
            <RedirectClient :vendor="dataHeader.vendor" />
          </div>
        </div>

        <div v-if="dataHeader"
          class="is-flex is-align-items-center is-justify-content-space-between mb-3 mt-4 c-w-100 px-lg-6 mt-sm-0 flex-sm-column">
          <div class="is-flex align-items-sm-center c-w-sm-100">
            <div class="is-hidden-desktop is-hidden-tablet" @click="goBack">
              <ArrowLeftIcon class="mr-3" />
            </div>
            <div class="overflow-auto thin-scroll">
              <div class="is-flex is-align-items-center flex-sm-column align-items-sm-start">
                <span class="averta-bold text-title mr-4">{{ conditionalIDProject }} | {{ dataHeader.store.name || dataHeader.name }} {{ dataHeader.store.unit_name ? `- ${dataHeader.store.unit_name}` : '' }}</span>
                <div class="is-flex is-align-items-center">
                  <div class="px-2 py-1 averta-bold" :class="`${statusSurveyClassName}`">
                    <span>{{ wordingHelper(dataHeader.status.status_name) }}</span>
                  </div>
                  <div class="is-flex is-align-items-center">
                    <div v-if="dataHeader.is_reschedule === 1" class="wrap-tag-reschedule-extends is-flex is-align-items-center is-justify-content-center">
                      <span>Reschedule</span>
                    </div>
                    <div v-if="dataHeader.is_extend === 1" class="wrap-tag-reschedule-extends is-flex is-align-items-center is-justify-content-center">
                      <span>Extend</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="is-flex">
                <!-- Tag Total Complaint -->
                <div v-if="dataHeader.project_complain && dataHeader.project_complain.type === 'parent' && dataHeader.project_complain.total_project_complain > 0" class="tag-complaint is-flex is-align-items-center is-justify-content-center">
                  <p class="averta-bold label-12 white-space-nowrap">
                    {{ isBS || isSM ? dataHeader.project_complain.total_project_complain : '' }} Komplain
                  </p>
                </div>
                <!-- Tag Total Complaint -->
                <!-- Tag Redirect -->
                <div
                  v-if="dataHeader.project_complain && dataHeader.project_complain.type === 'child'"
                  class="tag-redirect is-flex is-align-items-center is-justify-content-center"
                  :class="{'is-clickable': isBS || isSM, 'is-disabled': !isBS && !isSM}"
                  @click="redirectToProjectParent"
                >
                  <TagRedirect class="mr-3" :color="!isBS && !isSM && '#868686'"/>
                  <p class="mr-3">
                    <span class="averta-bold label-16 text-grey">ID Terkait : </span>
                    <span
                      class="averta-bold label-16"
                      :class="{
                        'text-grey' : !isBS && !isSM,
                        'text-merah' : isBS || isSM
                      }"
                    >
                      {{ dataHeader.project_complain.survey_parent_id }}
                    </span>
                  </p>
                  <ArrowRightIcon color="#E10009" width="13" height="13" v-if="isBS || isSM" />
                </div>
                <!-- Tag Redirect -->
              </div>
              <div class="is-flex is-align-items-center">
                <p class="white-space-nowrap">
                  Tanggal Pembuatan Penawaran :
                  <span class="averta-bold">{{ formatDate(dataHeader.created_at) }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="is-flex is-flex-direction-column is-align-items-flex-end align-items-sm-start c-w-sm-100 mt-sm-2">
            <div v-if="checkInvoice" class="is-flex is-align-items-center mb-1">
              <p class="color-red label-14 mr-1 mb-1 averta-bold">{{ statusProject }}</p>
              <InfoIcon />
            </div>
            <div class="is-flex is-align-items-center c-w-sm-100 overflow-auto thin-scroll">
              <div v-if="isBS">
                <ButtonApp
                  height="3em"
                  :is-bold="true"
                  :isSecondary="true"
                  @click="isSMRC ? handleModalPenawaranSMRC(true) : handleModalPenawaranClient(true)"
                >
                  <p>Lihat Penawaran Client</p>
                </ButtonApp>
              </div>
              <ButtonApp v-if="checkInvoice" height="3em" :is-bold="true" @click="handleDownloadInvoice"
                class="ml-4 c-w-sm-100 is-flex is-align-items-center is-justify-content-center">
                <div class="is-flex is-align-items-center">
                  <DocumentCheckIcon />
                  <p class="has-text-white averta-bold ml-2">Lihat Invoice</p>
                </div>
              </ButtonApp>
            </div>
          </div>
        </div>

        <SurveyProjectID
          :dataHeaderProps="dataHeader"
          @onModalProyekDarurat="handleProyekDarurat($event)"
        />
        <CardGrandTotalProject
          v-if="isBS && (isWaitingPayment || isSuccessPayment)"
          @openModal="handleShowModalInvoice"
          :dataHeaderProps="dataHeader"
          class="my-5"
        />
        <StatusConfirmationDetail :dataHeaderProps="dataHeader" />

        <div id="pointOffering">
          <!-- Detail Penawaran & Proyek -->
          <TabDetailProyek :surveyId="dataHeader && dataHeader.survey_id" :data="dataHeader" :isBS="isBS" />
        </div>

      </div>

      <div class="divider my-4" />

      <div class="container my-fluid-container py-2 container-detail">
        <div class="is-flex mb-5 overflow-auto thin-scroll pb-sm-1 mb-sm-0" id="tabs-scroll-top">
          <div v-for="(item, index) in tabs" :key="item.tab_id">
            <b-button v-if="isKonsultan || dataHeader.project_complain.type === 'child' ? item.tab_name === 'Komplain' ? false : true : true" class="mr-3 btn-tabs mr-sm-1"
              :class="{ 'is-active': selectedTabId === item.tab_id }" @click="changeIsActiveTab(item, index)">
              <div class="white-space-normal is-flex flex-direction is-position-relative">
                <p class="white-space-md-nowrap averta-regular" :class="{ 'averta-bold': selectedTabId === item.tab_id }">{{ item.tab_name }}</p>
              </div>
            </b-button>
          </div>
        </div>

        <!-- Detail Proyek AM -->
        <div v-if="selectedTabId === 1 && isBS" class="b-tab-wrapper">
          <b-tabs>
            <b-tab-item>
              <template #header>
                <div class="is-flex is-align-items-center">
                  <span>Timeline & Kehadiran</span>
                </div>
              </template>
              <BTabDetailProyek
                :dataDetailProyek="dataDetailProyek"
                :surveyId="dataHeader && dataHeader.survey_id"
                :isLoading="isLoading"
                :isBS="isBS"
                :changeIsActiveArea="changeIsActiveArea"
                :selectedAreaId="selectedAreaId"
                :projectDate="dataDetailProyek && dataDetailProyek.project_date"
                :duration="dataDetailProyek && dataDetailProyek.card.duration"
              />
              <AttendanceProyek
                :statusProject="dataHeader.status.status_name"
                :dataAttendance="dataAttendance"
                :isLoading="isLoading"
                @reloadPageProyek="reloadPageProyek($event)"
              />
            </b-tab-item>
            <b-tab-item>
              <template #header>
                <div class="is-flex is-align-items-center">
                  <span>Daftar Material & Tukang</span>
                </div>
              </template>
              <MaterialAndWorker
                :isLoading="isLoading"
                :isBS="isBS"
                :offeringId="dataHeader.offering_id"
                :showButtonAnimation="showButtonAnimation"
              />
            </b-tab-item>
            <b-tab-item>
              <template #header>
                <div class="is-flex is-align-items-center">
                  <span>Berkas Lampiran</span>
                </div>
              </template>
              <TabBerkasLampiran :surveyId="dataHeader.survey_id" />
            </b-tab-item>
          </b-tabs>
        </div>

        <!-- Detail Proyek Konsultan & SM -->
        <div v-else-if="selectedTabId === 1 && !isBS" class="b-tab-wrapper">
          <b-tabs>
            <b-tab-item>
              <template #header>
                <div class="is-flex is-align-items-center">
                  <span>Timeline & Kehadiran</span>
                </div>
              </template>
              <BTabDetailProyek
                :dataDetailProyek="dataDetailProyek"
                :surveyId="dataHeader && dataHeader.survey_id"
                :isLoading="isLoading"
                :isBS="isBS"
                :changeIsActiveArea="changeIsActiveArea"
                :selectedAreaId="selectedAreaId"
                :projectDate="dataDetailProyek && dataDetailProyek.project_date"
                :duration="dataDetailProyek && dataDetailProyek.card.duration"
              />
              <AttendanceProyek
                :statusProject="dataHeader.status.status_name"
                :dataAttendance="dataAttendance"
                :isLoading="isLoading"
              />
            </b-tab-item>
            <b-tab-item>
              <template #header>
                <div class="is-flex is-align-items-center">
                  <span>Rincian Proyek</span>
                </div>
              </template>
              <NewDetailPenawaran
                v-if="!isRincianProyekEmpty"
                :offeringId="dataHeader.offering_id"
                :hideInformasiPenawaran="true"
                :hideTimeline="true"
                :showButtonAnimation="showButtonAnimation"
                class="px-5 wrap-detail-penawaran"
              />
              <div class="has-text-centered detail-material-wrapper" v-else>
                <ProyekEmptyIcon />
                <p class="averta-bold pb-5">Rincian Proyek Kosong</p>
              </div>
            </b-tab-item>
            <b-tab-item>
              <template #header>
                <div class="is-flex is-align-items-center">
                  <span>Daftar Material</span>
                </div>
              </template>
              <RincianMaterial :proyekId="$route.params.proyekId" />
            </b-tab-item>
            <b-tab-item v-if="isSM">
              <template #header>
                <div class="is-flex is-align-items-center">
                  <span>Berkas Lampiran</span>
                </div>
              </template>
              <TabBerkasLampiran :surveyId="dataHeader.survey_id" />
            </b-tab-item>
          </b-tabs>
        </div>

        <!-- Riwayat Proyek -->
        <b-tabs v-if="selectedTabId === 2 && !isLoading">
          <b-tab-item>
            <template #header>
              <div class="is-flex is-align-items-center">
                <span>Detail Survey Proyek</span>
              </div>
            </template>
            <TabHistorySurvey :surveyId="dataHeader && dataHeader.survey_id" :data="dataHistorySurvey" />
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <div class="is-flex is-align-items-center">
                <span>Penawaran Rejected</span>
              </div>
            </template>
            <TabDetailPenawaranRejected />
          </b-tab-item>
          <b-tab-item label="Riwayat Reschedule">
            <TabDetailHistoryReschedule :dataHistoryReschedule="dataHistoryReschedule" />
          </b-tab-item>
        </b-tabs>

        <!-- Komplain -->
        <div v-if="selectedTabId === 3 && !isLoading">
          <b-tabs v-model="selectedTabComplain">
            <b-tab-item label="Daftar Komplain Proyek">
              <p class="label-20 averta-bold mb-5">Daftar Komplain Proyek</p>
              <div v-if="dataComplain && dataComplain.length < 1">
                <div class="has-text-centered">
                  <ProyekEmptyIcon />
                  <p class="averta-bold">
                    Tidak Ada Komplain
                  </p>
                </div>
              </div>
              <div v-else class="card-complain mb-3" v-for="item in dataComplain" :key="item.created_at">
                <div class="section-up-complain">
                  <div>
                    <p class="label-14">Tanggal Komplain</p>
                    <p class="averta-bold">{{ formatDate(item.created_at) }}</p>
                  </div>
                  <div class="complain-status">
                    <p>{{ item.status }}</p>
                  </div>
                </div>
                <div class="section-down-complain">
                  <p class="color-grey">Catatan</p>
                  <p class="averta-bold">{{ item.complaint || item.note || '-' }}</p>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item label="Daftar Survey Komplain">
                <TabSurveyComplaint
                  :dataSurveyComplaint="dataSurveyComplaint"
                  :canCreateComplaint="dataHeader.project_complain.can_create_project_complain"
                  @createComplaint="onCreateComplain"
                />
            </b-tab-item>
          </b-tabs>
        </div>

        <!-- Catatan Proyek -->
        <div v-if="selectedTabId === 4 && !isLoading">
          <ProjectNote
            :dataProjectNoteProps="dataProjectNote"
            :surveyId="dataHeader.survey_id"
            :isFromProject="true"
            :loadData="getDataProjectNote"
          />
          <PersonalNote
            :notes="dataProjectNote.personal_note"
            :isFromProject="true"
            :loadData="getDataProjectNote"
            :surveyId="dataHeader.survey_id"
          />
        </div>

        <!-- Admin Tools -->
        <div v-if="selectedTabId === 5 && !isLoading">
          <AdminTools :duration="dataDetailProyek && dataDetailProyek.card.duration" />
        </div>

      </div>

      <!-- Sidebar Penawaran Client BS -->
      <div v-if="dataHeader.offering_id">
        <b-sidebar
          type="is-light"
          :fullheight="true"
          :fullwidth="true"
          :overlay="true"
          :right="true"
          v-model="modalPenawaranClient"
        >
          <PenawaranClient
            :show="modalPenawaranClient"
            :selectedTipeOffering="selectedTipeOffering"
            :offeringId="dataHeader.offering_id"
            :dataHeader="dataHeader"
            :dateWorking="dataDetailProyek && dataDetailProyek.card"
            @closeModal="handleModalPenawaranClient(false)"
          />
        </b-sidebar>
      </div>
      <!-- Sidebar Penawaran Client BS -->

      <!-- Modal Lhat Penawaran -->
      <div v-if="dataHeader.offering_id">
        <b-modal v-model="modalPenawaranSMRC">
          <div class="modal-penawaran-smrc">
            <div class="header-modal">
              <h5>Lihat Penawaran</h5>
              <div  class="clickable" @click="handleModalPenawaranSMRC(false)">
                <XMarkIcon />
              </div>
            </div>
            <div class="body-modal">
              <p class="mb-5">Pilih salah satu format penawaran untuk ditampilkan!</p>
              <div @click="tipeOffering('price-actual')" class="notification clickable flex-center is-flex-direction-column is-light border-radius-20 border-2 c-w-100">
                <div class="is-flex is-justify-content-space-between is-align-items-center c-w-100">
                 <div style="gap: 1rem" class="is-flex c-w-85">
                   <img src="@/assets/img/ActualPriceIcon.svg" alt="ActualPriceIcon" />
                   <div class="is-flex is-flex-direction-column is-justify-content-center">
                    <h5 class="averta-bold">Harga Dasar</h5>
                    <p class="averta-regular">Tampilan ini adalah detail penawaran yang disajikan oleh Kanggo untuk Client </p>
                   </div>
                 </div>
                 <div class="is-flex is-justify-content-flex-end clickable">
                    <ArrowRightIcon />
                 </div>
                </div>
              </div>

              <div @click="tipeOffering('price-tenant')" class="notification clickable flex-center is-flex-direction-column is-light border-radius-20 border-2 c-w-100">
                <div class="is-flex is-justify-content-space-between is-align-items-center c-w-100">
                 <div style="gap: 1rem" class="is-flex c-w-85">
                   <img src="@/assets/img/TetantPriceIcon.svg" alt="ActualPriceIcon" />
                   <div class="is-flex is-flex-direction-column is-justify-content-center">
                    <h5 class="averta-bold">Harga Tenant</h5>
                    <p class="averta-regular">Tampilan ini adalah detail penawaran yang disajikan Client Kanggo untuk Customer/Tenant</p>
                   </div>
                 </div>
                 <div class="is-flex is-justify-content-flex-end clickable">
                    <ArrowRightIcon />
                 </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
      <!-- Modal Lhat Penawaran -->

      <ModalProyekDarurat
        :dataModalUrgent="dataHeader.project_urgent_id"
        :surveyId="dataHeader.survey_id"
        :showModal="modalProyekDarurat"
        :loadData="getDataProyekHeader"
        :width="isMobile ? '95vw' : '45vw'"
        @closeModal="handleProyekDarurat(false)"
      />

      <!-- Button Scroll Top -->
      <div v-if="firstLoad" class="button-scroll-top is-clickable"
        :class="{ 'show-button-animation': showButtonAnimation, 'hide-button-animation': !showButtonAnimation }"
        @click="scrollTop">
        <b-icon icon="arrow-up" :size="isMobile ? 'is-small' : 'is-medium'"></b-icon>
      </div>
      <!-- Button Scroll Top -->

      <ModalTagihan
        :show="showModalInvoice"
        :surveyId="dataHeader.survey_id"
        :projectId="dataHeader.id"
        :invoice="dataHeader.price.invoice"
        :grandTotal="dataHeader.price.grand_total"
        :refreshData="getDataDetailAM"
        :handleClose="handleCloseModalInvoice"
        :isLoadingInvoice="false"
        :startDueDate="dataHeader.price.start_due_date"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

// Assets
import { ArrowLeftIcon, ProyekEmptyIcon, InfoIcon, DocumentCheckIcon, TagRedirect, ArrowRightIcon, XMarkIcon } from '@/components/icons'

// Helper
import { wordingMenuHelper, alertErrorMessage, scrollToElementHelper, isSMRC } from '@/helpers'

// Components
import DetailSkeleton from '@/components/skeleton/DetailSkeleton'
import RedirectClient from '@/components/RedirectClient'
import PenawaranClient from '@/views/v2/am/project/PenawaranClient'
import ButtonApp from '@/components/button/ButtonApp'
import SurveyProjectID from '@/components/project/detail/SurveyProjectID'
import ModalProyekDarurat from '@/components/modal/ModalProyekDarurat'
import TabDetailProyek from './components/TabDetailProyek'
import AlertAssignTukang from './components/assign-worker/AlertAssignTukang'
import StatusConfirmationDetail from './components/StatusConfirmationDetail.vue'
import CardGrandTotalProject from '@/components/card/CardGrandTotalProject.vue'
import ModalTagihan from '@/components/modal/ModalTagihan.vue'
// import ModalTemplate from '@/components/modal/ModalTemplate.vue'

// Detail Proyek (Timeline, Daftar Material & Tukang / Rincian Proyek, Daftar Material, Berkas Lampiran)
import BTabDetailProyek from './b-tabs/BTabDetailProyek'
import MaterialAndWorker from './components/MaterialAndWorker'
import AttendanceProyek from './components/AttendanceProyek'
import NewDetailPenawaran from '@/components/project/NewDetailPenawaran'
import RincianMaterial from './components/RincianMaterial'
import TabBerkasLampiran from './components/TabBerkasLampiran'

// Riwayat Survey (Detail Survey Proyek, Penawaran Rejected, Riwayat Reschedule)
import TabHistorySurvey from './components/TabHistorySurvey'
import TabDetailPenawaranRejected from '@/components/project/TabDetailPenawaranRejected'
import TabDetailHistoryReschedule from '@/components/project/TabDetailHistoryReschedule'

// Catatan Proyek
import ProjectNote from '@/components/project/detail/ProjectNote'
import PersonalNote from '@/components/project/detail/PersonalNote'

// Admin Tools
import AdminTools from './components/AdminTools'

// Survey Complaint
import TabSurveyComplaint from './b-tabs/TabSurveyComplaint'

export default {
  name: 'DetailProyek',
  components: {
    // Icons
    DetailSkeleton,
    ArrowLeftIcon,
    ProyekEmptyIcon,
    InfoIcon,
    DocumentCheckIcon,
    TagRedirect,
    ArrowRightIcon,
    XMarkIcon,

    // Components
    ModalProyekDarurat,
    RedirectClient,
    PenawaranClient,
    ButtonApp,
    SurveyProjectID,
    TabDetailProyek,
    AlertAssignTukang,
    StatusConfirmationDetail,
    CardGrandTotalProject,
    ModalTagihan,
    // ModalTemplate,

    // Detail Proyek
    BTabDetailProyek,
    MaterialAndWorker,
    AttendanceProyek,
    NewDetailPenawaran,
    RincianMaterial,
    TabBerkasLampiran,

    // Riwayat Survey
    TabHistorySurvey,
    TabDetailPenawaranRejected,
    TabDetailHistoryReschedule,

    // Catatan Proyek
    ProjectNote,
    PersonalNote,

    // Admin Tools
    AdminTools,

    // Survey Complaint
    TabSurveyComplaint
  },
  data () {
    return {
      showAlertAssignWorker: false,
      showModalInvoice: false,
      isAssignWorker: false,
      isLoading: true,
      isLoadingHeader: true,
      isLoadingDetail: true,
      isRincianProyekEmpty: false,
      globalLoading: true,
      selectedTabId: '',
      selectedAreaId: '',
      selectedTabComplain: 0,
      tabs: [],
      areas: [],
      navbarPosition: null,
      isPinned: false,

      dataHeader: null,
      dataDetailProyek: null,
      dataHistorySurvey: null,
      dataHistoryReschedule: [], // do not change this empty array
      dataHistoryOffering: null,
      dataComplain: null,
      dataAttendance: null,
      dataMaterials: [],
      dataPreviewImage: [],
      dataProjectNote: null,
      dataPersonalNote: null,

      modalPenawaranClient: false,
      modalProyekDarurat: false,
      modalPenawaranSMRC: false,
      selectedTipeOffering: null,

      showButtonAnimation: false,
      firstLoad: false,

      dataSurveyComplaint: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      alertAssign: 'project/getAlertAssignTukang',
      heightNavbar: 'dashboard/getNavbarHeight',
      scrollElement: 'project/getScrollElement',
      getRefreshData: 'projectV2/getRefreshData'
    }),
    checkIsEmpty () {
      if ((this.dataHeader && Object.keys(this.dataHeader).length > 0) || (this.dataDetailProyek && Object.keys(this.dataDetailProyek).length > 0)) return false
      return true
    },
    currentPath () {
      const path = this.$route.path.split('/')
      const firstElement = path[1]

      return firstElement
    },
    isRole () {
      let result
      switch (this.user.roles[0].name) {
        case 'Account Manager':
          result = true
          break
        case 'Site Manager':
          result = true
          break
        case 'Business Support':
          result = true
          break
        default:
          result = false
          break
      }

      return result
    },
    isBS () {
      if (this.user.roles[0].name === 'Account Manager' || this.user.roles[0].name === 'Business Support') {
        return true
      } return false
    },
    isSM () {
      if (this.user.roles[0].name === 'Site Manager') {
        return true
      } return false
    },
    isKonsultan () {
      if (this.user.roles[0].name === 'Konsultan Survey') {
        return true
      } return false
    },
    isSMRC () {
      return isSMRC(this.dataHeader.vendor.code || '')
    },
    checkInvoice () {
      if (this.isBS) {
        const status = this.dataHeader.status
        if (status !== 'Proyek Aktif' && status !== 'In-Planning' && status !== 'Direncanakan') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    },
    isWaitingPayment () {
      // eslint-disable-next-line camelcase
      const statusName = this.dataHeader?.status.status_name
      return statusName === 'payment-waiting' || statusName === 'Menunggu Pembayaran' || statusName === 'payment-pending'
    },
    isSuccessPayment () {
      // eslint-disable-next-line camelcase
      const statusName = this.dataHeader?.status.status_name
      return statusName === 'payment-success' || statusName === 'Pembayaran Berhasil'
    },
    statusSurveyClassName () {
      if (this.dataHeader && Object.keys(this.dataHeader).length > 0) {
        switch (this.dataHeader.status.status_name) {
          case 'Direncanakan':
          case 'in-planning':
            return 'status-survey-yellow'

          case 'Proyek Aktif':
          case 'active':
            return 'status-survey-blue'

          case 'Proyek Lunas':
            return 'status-survey-grey'

          case 'Pembayaran Berhasil':
          case 'payment-success':
            return 'status-survey-green'

          case 'Pembayaran Gagal':
          case 'payment-failed':
            return 'status-survey-purple'

          case 'Menunggu Pembayaran':
          case 'payment-waiting':
          case 'payment-pending':
            return 'status-survey-dark-red'

          default:
            return 'status-survey-grey'
        }
      } else {
        return ''
      }
    },
    statusProject () {
      if (this.dataHeader && Object.keys(this.dataHeader).length > 0) {
        switch (this.dataHeader.status.status_name) {
          case 'Proyek Lunas':
          case 'Pembayaran Berhasil':
          case 'payment-success':
            return 'Pembayaran Lunas'

          default:
            return 'Pembayaran Belum Lunas'
        }
      } else {
        return '-'
      }
    },
    conditionalIDProject () {
      if (this.dataHeader.project_complain && this.dataHeader.project_complain.survey_complain_code) {
        return this.dataHeader.project_complain.survey_complain_code
      } else {
        return `${this.dataHeader.survey_id}`
      }
    }
  },
  methods: {
    // Proyek Darurat ID
    handleProyekDarurat (props = {}) {
      const { value } = props
      this.modalProyekDarurat = value
    },
    goBack () {
      const queryParams = this.$route.query

      if (queryParams && queryParams.fromSearch) {
        return this.$router.go(-1)
      }

      if (this.currentPath === 'am') {
        this.$router.push('/am/project')
      } else if (this.currentPath === 'sm') {
        this.$router.push('/sm/project')
      } else {
        this.$router.push('/project')
      }
    },
    formatDate (date) {
      if (!date) {
        return '-'
      }

      return moment(date).format('dddd, DD MMMM YYYY')
    },
    changeIsActiveTab (props, index) {
      this.selectedTabId = props.tab_id
    },
    changeIsActiveArea (props, index) {
      this.selectedAreaId = props.area_id
    },
    getDataProyekHeader (props = {}) {
      const { isRefresh } = props

      if (!isRefresh) {
        this.isLoadingHeader = true
      }

      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId
      }
      this.$store
        .dispatch('projectV2/getProjectHeader', payload)
        .then(response => {
          this.dataHeader = response.data.data
          this.isLoadingHeader = false
          // for displaying alert assign worker
          this.isAssignWorker = this.isRole && ['Direncanakan', 'Proyek In-Planning', 'Proyek Aktif', 'in-planning', 'active'].includes(this.dataHeader.status.status_name)
          this.$store.commit('project/setHeaderProject', this.dataHeader)
        })
        .catch(error => {
          this.isLoadingHeader = false
          alertErrorMessage(error)
        })
    },
    getTimelineProyek (props = {}) {
      const { isRefresh } = props

      if (!isRefresh) {
        this.isLoadingDetail = true
      }

      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'detail',
        subcategory: 'timeline'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then(response => {
          this.dataDetailProyek = response.data.data
          this.$store.commit('projectV2/setDataTimelineProject', this.dataDetailProyek)
          // if (Object.keys(this.dataDetailProyek).length > 0) {
          //   this.selectedAreaId = this.dataDetailProyek.area_component[0].area_id
          // }
          this.isLoadingDetail = false

          if (this.$route.query.redirect) {
            this.selectedTabId = 3
            this.selectedTabComplain = 1
            setTimeout(() => {
              this.scrollToElement('#pointOffering')

              // Create a new query object without the 'redirect' parameter
              const updatedQuery = { ...this.$route.query }
              delete updatedQuery.redirect

              // Use this.$router.push to update the route with the new query object
              this.$router.push({
                path: this.$route.path,
                query: updatedQuery
              })
            }, 500)
          }
        })
        .catch(error => {
          this.isLoadingDetail = false
          alertErrorMessage(error)
        })
    },
    getHistorySurvey () { // REUSABLE
      this.isLoading = true
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'history',
        subcategory: 'survey'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then(response => {
          this.dataHistorySurvey = response.data.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getHistoryReschedule () { // REUSABLE
      this.isLoading = true
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'history',
        subcategory: 'reschedule'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then(response => {
          this.dataHistoryReschedule = response.data.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getComplain () { // REUSABLE
      this.isLoading = true
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'complain'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then(response => {
          this.dataComplain = response.data.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getSurveyComplaint () { // REUSABLE
      this.isLoading = true
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'survey-complain'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then(response => {
          this.dataSurveyComplaint = response.data.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getAttendance () { // REUSABLE
      // hide kehadiran on konsultan survey
      if (this.isKonsultan) {
        return
      }
      this.isLoading = true
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'detail',
        subcategory: 'attendance'
      }
      this.$store
        .dispatch('project/getDetailProyek', payload)
        .then(response => {
          this.dataAttendance = response.data.data && response.data.data.attendance
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getMaterialWorker (props = {}) {
      const { isRefresh, dataScroll } = props

      if (!isRefresh) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'detail',
        subcategory: 'material-worker'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then(response => {
          const res = response.data.data

          this.$store.commit('project/setDataDetailPenawaran', {
            area_component: res && res.area_component,
            timeline: null,
            duration: null,
            status: res && res.status,
            dataStatusMaterial: res.card_material
          })

          if (isRefresh) {
            this.scrollTo(dataScroll)
          }
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getDetailPenawaranRejected () {
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'history',
        subcategory: 'reject'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then((response) => {
          if (response.data.data.length > 0) {
            this.$store.commit('project/setDataDetailPenawaranRejected', response.data.data)
          }
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
    },
    getRincianProyek () {
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'detail',
        subcategory: 'detail'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then((response) => {
          const res = response.data.data
          this.$store.commit('project/setDataDetailPenawaran', {
            area_component: res,
            timeline: null,
            duration: null
          })
          if (res && res.list && res.list.length < 1) {
            this.isRincianProyekEmpty = true
          }
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
    },
    getDataProjectNote () { // REUSABLE
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'note'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then((response) => {
          this.dataProjectNote = response.data.data
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
    },
    getDataAdminTools () { // REUSABLE
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'admin-tools'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then((response) => {
          this.$store.commit('projectV2/setAdminTools', response.data.data)
        })
        .catch((error) => {
          this.$store.commit('projectV2/setAdminTools')
          alertErrorMessage(error)
        })
    },
    handleModalPenawaranClient (value) {
      this.modalPenawaranClient = value
    },
    handleModalPenawaranSMRC (value) {
      this.modalPenawaranSMRC = value
    },
    tipeOffering (type) {
      this.selectedTipeOffering = type
      this.handleModalPenawaranClient(true)
      this.handleModalPenawaranSMRC(false)
    },
    handleScroll () {
      const stickyHeader = document.querySelector('.sticky-header')
      const limitScroll = this.isMobile ? 130 : 200
      if (stickyHeader) {
        if (window.scrollY > limitScroll) {
          stickyHeader.classList.add('is-pinned')
          setTimeout(() => {
            stickyHeader.classList.add('transition')
          }, 1)
          if (this.isMobile) {
            stickyHeader.classList.add('mobile')
          }
          this.isPinned = true
        } else {
          stickyHeader.classList.remove('is-pinned')
          stickyHeader.classList.remove('transition')
          this.isPinned = false

          if (this.isMobile) {
            stickyHeader.classList.remove('mobile')
          }
        }
      }
    },
    handleDownloadInvoice () {
      const routeData = this.$router.resolve({ path: `/am/print-invoice-project/${this.$route.params.proyekId}` })
      window.open(routeData.href, '_blank')
    },
    async getDataDetailAM () {
      this.globalLoading = true
      await Promise.all([
        this.getDataProyekHeader(),
        this.getTimelineProyek(),
        this.getHistorySurvey(),
        this.getHistoryReschedule(),
        this.getComplain(),
        this.getSurveyComplaint(),
        this.getAttendance(),
        this.getMaterialWorker(),
        this.getDetailPenawaranRejected(),
        this.getDataProjectNote(),
        this.getDataAdminTools()
      ])
      this.globalLoading = false
    },
    async getDataDetailKonsultanSM () {
      this.globalLoading = true
      await Promise.all([
        this.getDataProyekHeader(),
        this.getTimelineProyek(),
        this.getHistorySurvey(),
        this.getHistoryReschedule(),
        this.getComplain(),
        this.getSurveyComplaint(),
        this.getAttendance(),
        this.getRincianProyek(),
        this.getDataProjectNote()
      ])
      this.globalLoading = false
    },
    wordingHelper (value) {
      return wordingMenuHelper(value)
    },
    scrollTop () {
      const element = document.getElementById('tabs-scroll-top')
      if (element) {
        const headerOffset = 180
        const elementPosition = element.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.scrollY - headerOffset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
      }
    },
    getContainerDetail () {
      const containerDetail = document.querySelector('#containerDetail')
      if (containerDetail) {
        if ((window.scrollY + 200) >= containerDetail.clientHeight) {
          this.showButtonAnimation = true
          this.firstLoad = true
        } else {
          this.showButtonAnimation = false
        }
      }
    },
    adjustStickyPosition () {
      setTimeout(() => {
        if (this.heightNavbar) {
          this.navbarPosition = this.heightNavbar + 'px'
        }
      }, 600)
    },
    async reloadPageProyek (data) {
      this.getTimelineProyek({ isRefresh: true })
      this.getDataProyekHeader({ isRefresh: true })
      this.getMaterialWorker({ isRefresh: true, dataScroll: data })
      this.getDataAdminTools()
    },
    scrollTo (data) {
      let elementId = ''
      let headerOffset = 180

      if (data.jobId) {
        elementId = `job_id_${data.jobId}_component_id_${data.componentId}`
        headerOffset = 200
      } else {
        elementId = `item_component_id_${data.componentId}`
      }

      const element = document.getElementById(elementId)
      if (element) {
        const elementPosition = element.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.scrollY - headerOffset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
      }
    },
    handleShowModalInvoice () {
      this.showModalInvoice = !this.showModalInvoice
    },
    handleCloseModalInvoice () {
      this.showModalInvoice = false
    },
    scrollToElement (el) {
      scrollToElementHelper(el)
    },
    onCreateComplain () {
      this.$router.push({ path: `/am/project/survey-complaint/${this.dataHeader.id}` })
    },
    redirectToProjectParent () {
      if (this.isBS) {
        const routeData = this.$router.resolve({ path: `/am/project/${this.dataHeader.project_complain.project_parent_id}/detail` })
        window.open(routeData.href, '_blank')
      } else if (this.isSM) {
        const routeData = this.$router.resolve({ path: `/sm/project/${this.dataHeader.project_complain.project_parent_id}/detail` })
        window.open(routeData.href, '_blank')
      }
    }
  },
  created () {
    window.scrollTo(0, 0)
    // set false for download offering ( OfferingProject ), from DetailProyek
    this.$store.commit('project/setPreviewDownloadOffering', false)
  },
  watch: {
    alertAssign (val) {
      // alertAssign from is_slot_full ( getLaporanHarian ), false = show, true = hide
      this.showAlertAssignWorker = !val
    },
    globalLoading (val) {
      if (val === false) {
        this.getContainerDetail()
      }
    },
    heightNavbar (val) {
      if (val) {
        this.adjustStickyPosition()
      }
    },
    scrollElement (val) {
      if (val && val.scroll) {
        const { selectedTab, element } = val
        this.selectedTabId = selectedTab
        setTimeout(() => {
          this.scrollToElement(element)
        }, 500)
      }
    },
    getRefreshData (val) {
      if (val && val === 'BS') {
        this.getDataProyekHeader({ isRefresh: true })
        this.getTimelineProyek({ isRefresh: true })
        this.getDataAdminTools()
        this.$store.commit('projectV2/setRefreshData')
      }
    }
  },
  mounted () {
    this.showAlertAssignWorker = !this.alertAssign
    window.addEventListener('scroll', this.handleScroll)
    this.selectedTabId = 1
    if (this.isBS) {
      this.getDataDetailAM()
      this.tabs = [
        {
          tab_id: 1,
          tab_name: 'Detail Proyek'
        },
        {
          tab_id: 2,
          tab_name: 'Riwayat Survey'
        },
        {
          tab_id: 3,
          tab_name: 'Komplain'
        },
        {
          tab_id: 4,
          tab_name: 'Catatan Proyek'
        },
        {
          tab_id: 5,
          tab_name: 'Admin Tools'
        }
      ]
    } else {
      this.getDataDetailKonsultanSM()
      this.tabs = [
        {
          tab_id: 1,
          tab_name: 'Detail Proyek'
        },
        {
          tab_id: 2,
          tab_name: 'Riwayat Survey'
        },
        {
          tab_id: 3,
          tab_name: 'Komplain'
        },
        {
          tab_id: 4,
          tab_name: 'Catatan Proyek'
        }
      ]
    }

    this.adjustStickyPosition()
    window.addEventListener('scroll', this.getContainerDetail)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('scroll', this.getContainerDetail)
  }
}
</script>

<style scoped>
.body-wrapper {
  width: 100%;
  min-height: 90vh;
}

.not-found-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.text-title {
  font-size: 26px;
}

.divider {
  width: 100%;
  height: 10px;
  background: #F2F2F2;
}

.card-complain {
  padding: 20px;
  border: 1px solid #C4C4C4;
  border-radius: 20px;
}

.section-up-complain {
  border-bottom: 1px dashed #9A9A9A;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.section-down-complain {
  padding-top: 30px;
}

.tag-complaint {
  width: 90px;
  height: 30px;
  padding: 2px 8px;
  border-radius: 100px;
  background: #E10009;
  color: white;
}

.complain-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  background: #3185FC;
  border-radius: 100px;
  color: white;
  font-size: 14px;
}

.btn-tabs {
  height: auto;
  border-radius: 100px;
  color: #9a9a9a;
  border: 1px solid #e1e1e1;
}

.btn-tabs.is-active {
  background: #FFF5F5;
  border: 1px solid #D9272D !important;
  color: #D9272D;
}

.detail-material-wrapper {
  background: #fafafa;
  border-radius: 20px;
}

.button-scroll-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background: var(--gradient-red-red-primary-button, linear-gradient(116deg, #D9272D 16.15%, #EB4600 83.24%));
  padding: 20px;
  color: white;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  opacity: 0.6 !important;
  width: 70px;
  height: 70px;
}

.button-scroll-top:hover {
  opacity: 1 !important;
}

.wrap-tag-reschedule-extends {
  background: #E1EEFF;
  border: 1px solid #3185FC;
  padding: 3px 10px;
  border-radius: 100px;
  margin-left: 8px;
}

.wrap-tag-reschedule-extends span {
  color: #3185FC;
  font-size: 14px;
  font-style: italic;
}

.tag-redirect {
  background: #FAFAFA;
  width: max-content;
  height: 36px;
  border-radius: 100px;
  border: 1px solid #E1E1E1;
  padding: 8px 12px;
}

.tag-redirect.is-disabled {
  cursor: not-allowed;
}

@media screen and (max-width: 1024px) {
  .body-wrapper {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .text-title {
    font-size: 16px;
  }

  .button-scroll-top {
    width: 50px;
    height: 50px;
    bottom: 30px;
    right: 20px;
  }
}
.modal-penawaran-smrc{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 757px;
  max-width: 757px;
  height: 450px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  border-radius: 20px;
}

.header-modal{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  background-color: #B40007;
}

.header-modal > h5{
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
}

.body-modal{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  align-items: flex-start;
  margin: 0 auto;
  padding: 2rem;
  gap: 0rem;
  background-color: #FFFFFF;
}

.clickable{
  cursor: pointer !important;
}

</style>

<style>
.b-tab-wrapper .b-tabs .tab-content {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
</style>
