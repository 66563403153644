<template>
    <div class="not-fill-error-component" :class="{'bg-red-transparent': isError, 'bg-green-transparent': isSuccess}">
    <AlertIcon :color="isSuccess ? '#10BD41' : '#D9272D'" />
    <div class="is-flex is-align-items-center">
      <p class="averta-bold fs-12 ml-1" :class="{'color-red': isError, 'color-green': isSuccess}">
        {{title}}
      </p>
      <i v-if="subtitle" class="ml-1 fs-12" :class="{'color-red': isError, 'color-green': isSuccess}">({{subtitle}})</i>
    </div>
    </div>
</template>

<script>
import { AlertIcon } from '@/components/icons'
export default {
  name: 'ErrorComponent',
  props: {
    title: {
      type: String,
      default: 'Belum Diisi'
    },
    subtitle: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'error' // error | success
    }
  },
  components: {
    AlertIcon
  },
  computed: {
    isError () {
      return this.type === 'error'
    },
    isSuccess () {
      return this.type === 'success'
    }
  }
}
</script>

<style scoped>
.color-red {
  color: #d9272d !important;
}

.bg-red-transparent {
  background: rgba(255, 0, 0, 0.1);
}

.bg-green-transparent {
  background: #E7F8EC;
}

.not-fill-error-component {
  border-radius: 8px;
  padding: 4px 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
}

.fs-12 {
  font-size: 12px;
}

.w-92 {
  width: 92% !important;
}
</style>
