<template>
  <div class="is-flex input-search-2 input-none bg-white">
    <div class="flex-center p-2 bg-grey-2">
      <p class="averta-black color-grey">Rp</p>
    </div>
    <input
      type="text"
      class="p-2 c-w-100 has-text-right"
      v-model="input"
      @input="formatPrice"
      placeholder="0"
    />
  </div>
</template>

<script>
export default {
  name: 'InputPrice',
  props: {
    value: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      input: ''
    }
  },
  methods: {
    formatPrice (e) {
      const value = e.target.value
      if (value !== null) {
        const temp = value.toString().replace(/\D/g, '')
        this.input = Number(temp).toLocaleString('id-ID')
      } else {
        this.input = ''
      }
      this.$emit('change', value)
    }
  },
  watch: {
    value (val) {
      this.input = val
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';
</style>
