<template>
  <svg :width="size" :height="size" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.81366 14.1899L13.1357 4.7212C13.5336 4.21059 13.6751 3.62025 13.5424 3.01916C13.4275 2.47271 13.0914 1.95314 12.5874 1.55899L11.3582 0.582553C10.2882 -0.268467 8.96177 -0.178886 8.20127 0.797548L7.37887 1.86446C7.27276 1.99793 7.29929 2.19501 7.43193 2.30251C7.43193 2.30251 9.51003 3.96872 9.55424 4.00455C9.69573 4.13892 9.80185 4.31808 9.82838 4.53308C9.87259 4.95411 9.58077 5.34827 9.14747 5.40201C8.94408 5.42889 8.74953 5.36618 8.60805 5.24973L6.42383 3.51185C6.31771 3.43213 6.15854 3.44915 6.07011 3.55664L0.879282 10.2752C0.543249 10.6963 0.42829 11.2427 0.543249 11.7712L1.20647 14.6468C1.24184 14.7991 1.37449 14.9066 1.53366 14.9066L4.45185 14.8707C4.98242 14.8618 5.47763 14.6199 5.81366 14.1899ZM9.89974 13.2944H14.6581C15.1224 13.2944 15.5 13.6769 15.5 14.1472C15.5 14.6184 15.1224 15 14.6581 15H9.89974C9.43548 15 9.05789 14.6184 9.05789 14.1472C9.05789 13.6769 9.43548 13.2944 9.89974 13.2944Z"
      :fill="color ? color : 'url(#paint0_linear_1286_27499)'"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1286_27499"
        x1="0.5"
        y1="7.36364"
        x2="12.5919"
        y2="13.2936"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop offset="0.9999" :stop-color="colorGradient" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'EditIcon',
  props: {
    color: {
      type: String,
      default: '#D9272D'
    },
    colorGradient: {
      type: String,
      default: '#EB4600'
    },
    size: {
      type: String,
      default: '16'
    }
  }
}
</script>
