<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1173_75921" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M4.16667 15C4.16667 15.4583 4.54167 15.8333 5 15.8333H15C15.4583 15.8333 15.8333 15.4583 15.8333 15V10.8333C15.8333 10.375 16.2083 10 16.6667 10C17.125 10 17.5 10.375 17.5 10.8333V15.8333C17.5 16.75 16.75 17.5 15.8333 17.5H4.16667C3.25 17.5 2.5 16.75 2.5 15.8333V4.16667C2.5 3.25 3.24167 2.5 4.16667 2.5H9.16667C9.625 2.5 10 2.875 10 3.33333C10 3.79167 9.625 4.16667 9.16667 4.16667H5C4.54167 4.16667 4.16667 4.54167 4.16667 5V15ZM12.5 4.16667C12.0417 4.16667 11.6667 3.79167 11.6667 3.33333C11.6667 2.875 12.0417 2.5 12.5 2.5H16.6667C17.125 2.5 17.5 2.875 17.5 3.33333V7.5C17.5 7.95833 17.125 8.33333 16.6667 8.33333C16.2083 8.33333 15.8333 7.95833 15.8333 7.5V5.34167L8.225 12.95C7.9 13.275 7.375 13.275 7.05 12.95C6.725 12.625 6.725 12.1 7.05 11.775L14.6583 4.16667H12.5Z"
        fill="black" />
    </mask>
    <g mask="url(#mask0_1173_75921)">
      <rect width="20" height="20" fill="url(#paint0_linear_1173_75921)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_1173_75921" x1="-8.1504e-08" y1="9.81818" x2="16.1226" y2="17.7248"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'RedirectIcon'
}
</script>
