<template>
  <div>
    <div class="mb-3 flex-center-vertical is-justify-content-space-between">
      <div>
        <p class="has-text-grey">Item Pekerjaan</p>
        <p class="averta-bold note-item-working-wrapper">{{ dataItem.note_item_working }}</p>
      </div>
      <ButtonApp :isSecondary="true" :isTransparent="true" @click="handleOpenModal">
        <div class="flex-center px-2 py-1">
          <p class="averta-bold mr-2">Referensi</p>
          <ArrowRightIconLong color="red" />
        </div>
      </ButtonApp>
    </div>
    <div class="is-flex c-w-100 mb-5">
      <div class="mr-3 flex-1">
        <p class="color-grey label-12 mb-2">Harga Material</p>
        <div class="input-unit-price flex-center-vertical is-justify-content-space-between">
          <p>Rp</p>
          <input
            type="text"
            v-model.number="materialPrice"
            class="input-none averta-bold ml-2 has-text-right"
            @input="e => formatUnitPrice(e, 'material')"
          />
        </div>
      </div>
      <div class="mr-3 flex-1">
        <p class="color-grey label-12 mb-2">Harga Jasa</p>
        <div class="input-unit-price flex-center-vertical is-justify-content-space-between">
          <p>Rp</p>
          <input
            type="text"
            class="input-none averta-bold ml-2 has-text-right"
            v-model.number="workerPrice"
            @input="e => formatUnitPrice(e, 'worker')"
          />
        </div>
      </div>
      <div class="mr-3 flex-1">
        <p class="color-grey label-12 mb-2">Harga Satuan</p>
        <div class="input-unit-price flex-center-vertical is-justify-content-space-between">
          <p>Rp</p>
          <input
            type="text"
            class="input-none averta-bold ml-2 has-text-right"
            v-model.number="unitPrice"
            @input="e => formatUnitPrice(e, 'unit')"
          />
        </div>
      </div>
      <div class="mr-3 flex-1">
        <p class="color-grey label-12 mb-2">Volume Area Pengerjaan</p>
        <div
          class="input-unit-price disabled flex-center-vertical is-justify-content-space-between"
        >
          <input
            type="text"
            class="input-none averta-bold ml-2"
            :value="dataItem.volume"
            disabled
          />
        </div>
      </div>
      <div class="mr-3 flex-1">
        <p class="color-grey label-12 mb-2">Satuan</p>
        <div
          class="input-unit-price disabled flex-center-vertical is-justify-content-space-between"
        >
          <input
            type="text"
            class="input-none averta-bold ml-2"
            :value="dataItem.unit_name"
            disabled
          />
        </div>
      </div>
      <div class="mr-3 flex-1">
        <p class="color-grey label-12 mb-2 white-space-nowrap">Total Harga Item Pekerjaan</p>
        <div class="input-unit-price flex-center-vertical is-justify-content-space-between">
          <p>Rp</p>
          <input
            type="text"
            class="input-none averta-bold ml-2 has-text-right"
            v-model.number="totalPrice"
            @input="e => formatUnitPrice(e, 'total')"
          />
        </div>
      </div>
    </div>

    <ModalTemplate :showModal="showModal" width="1200px" @closeModal="closeModal">
      <template v-slot:headerModal>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="flex-center-vertical">
            <img src="@/assets/icons/store-2-used.svg" alt="">
            <p class="averta-bold label-18 ml-3">Rincian Harga Satuan Pemasangan pipa di atas plafond dekat kasir</p>
          </div>
          <div class="is-clickable" @click="closeModal">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div class="wrap-body-modal">
          <TableUnitPrice />
        </div>
      </template>
    </ModalTemplate>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers'
import { ArrowRightIconLong } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import TableUnitPrice from '@/components/table/TableUnitPrice.vue'

export default {
  name: 'FormUnitPrice',
  props: {
    dataItem: {
      type: Object,
      default: null
    }
  },
  components: {
    ButtonApp,
    ArrowRightIconLong,
    ModalTemplate,
    TableUnitPrice
  },
  data () {
    return {
      showModal: false,
      materialPrice: 0,
      workerPrice: 0,
      unitPrice: 0,
      totalPrice: 0
    }
  },
  methods: {
    formatThousand (value) {
      return formatCurrency(value, true) === '-' ? 0 : formatCurrency(value, true)
    },
    removeComma (value) {
      const convertedNumber =
        typeof value === 'string'
          ? parseFloat(value.replace(/\./g, '').replace(',', '.'))
          : value

      return convertedNumber
    },
    formatUnitPrice (e, type) {
      const value = e.target.value
      const temp = value.toString().replace(/\D/g, '')

      switch (type) {
        case 'material':
          this.materialPrice = this.formatThousand(temp)
          this.updateFromMaterial()
          break

        case 'worker':
          this.workerPrice = this.formatThousand(temp)
          this.updateFromJasa()
          break

        case 'unit':
          this.unitPrice = this.formatThousand(temp)
          this.updateFromSatuan()
          break

        case 'total':
          this.totalPrice = this.formatThousand(temp)
          this.updateFromTotal()
          break

        default:
          break
      }
    },
    roundToTwo (value) {
      return Math.round(value * 100) / 100
    },
    calculateHargaSatuan () {
      this.unitPrice = this.formatThousand(this.removeComma(this.materialPrice) + this.removeComma(this.workerPrice))
    },
    calculateTotalHarga () {
      this.totalPrice = this.formatThousand(this.removeComma(this.unitPrice) * this.dataItem.volume)
    },
    distributeHargaSatuan () {
      const materialRemove = this.removeComma(this.materialPrice)
      const workerRemove = this.removeComma(this.workerPrice)
      const unitRemove = this.removeComma(this.unitPrice)

      const totalCurrent = materialRemove + workerRemove

      if (totalCurrent > 0 && materialRemove !== workerRemove) {
        const difference = unitRemove - totalCurrent

        if (difference > 0) {
          // If unitPrice increased, adjust the smaller value
          if (materialRemove <= workerRemove) {
            this.materialPrice = this.formatThousand(
              Math.max(materialRemove + difference, 0)
            )
          } else {
            this.workerPrice = this.formatThousand(
              Math.max(workerRemove + difference, 0)
            )
          }
        } else if (difference < 0) {
          // If unitPrice decreased, adjust the larger value
          if (materialRemove >= workerRemove) {
            this.materialPrice = this.formatThousand(
              Math.max(materialRemove + difference, 0) // Prevent negative values
            )
          } else {
            this.workerPrice = this.formatThousand(
              Math.max(workerRemove + difference, 0) // Prevent negative values
            )
          }
        }
      } else {
        // Default split if no ratio exists
        const halfUnit = Math.max(unitRemove / 2, 0) // Ensure no negative split
        this.materialPrice = this.formatThousand(Math.floor(halfUnit))
        this.workerPrice = this.formatThousand(Math.ceil(halfUnit))
      }
    },
    updateFromMaterial () {
      this.calculateHargaSatuan()
      this.calculateTotalHarga()
    },
    updateFromJasa () {
      this.calculateHargaSatuan()
      this.calculateTotalHarga()
    },
    updateFromSatuan () {
      this.distributeHargaSatuan()
      this.calculateTotalHarga()
    },
    updateFromTotal () {
      if (this.dataItem.volume > 0) {
        this.unitPrice = this.formatThousand(this.removeComma(this.totalPrice) / this.dataItem.volume)
        this.distributeHargaSatuan()
      }
    },
    updateTotal () {
      this.calculateTotalHarga()
    },
    closeModal () {
      this.showModal = false
    },
    handleOpenModal () {
      this.showModal = !this.showModal
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.input-unit-price {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px 10px;
  border: 1px solid $color-grey-2 !important;
  flex: 1;
}

.input-unit-price input,
.input-unit-price input:focus {
  border: none !important;
  outline: none !important;
  width: 100%;
}

.input-unit-price.disabled,
.input-unit-price.disabled input {
  background: $color-grey-3;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}

.wrap-body-modal {
  background: white;
  padding: 10px;
  height: 500px;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
</style>
