<template>
    <div class="is-flex is-align-items-center is-justify-content-space-center wrapper-parrent">
      <p class="mr-3 client">Client :</p>
      <div class="is-flex is-align-items-center p-3 wrapper-vendor">
        <div class="wrap-logo mr-3">
          <img :src="vendor.logo" :alt="vendor.name">
        </div>
        <p class="averta-bold mr-3">{{ vendor.name }}</p>
        <div v-if="user.roles[0].name === 'Account Manager'" class="is-clickable" @click="detailClient(vendor.id)">
          <RedirectIcon />
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { RedirectIcon } from './icons'

export default {
  name: 'RedirectClient',
  props: ['vendor'],
  components: {
    RedirectIcon
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  methods: {
    detailClient (clientId) {
      this.$router.push(`/am/vendors/${clientId}/detail-branch`)
    }
  }
}
</script>

<style scoped>
.wrapper-vendor {
  background: #FAFAFA;
  border: 1px solid #F0F0F0;
  border-radius: 14px;
  width: auto;
}

.wrap-logo {
  height: 20px;
  object-fit: contain;
}

.wrap-logo img {
  height: 100%;
}

@media screen and (max-width: 600px) {
  .wrapper-parrent {
    display: block !important;
    margin-top: 12px;
  }

  .wrapper-vendor {
    display: block !important;
  }
  .wrapper-vendor img {
    margin-bottom: 0px;
  }

  .client {
    margin-bottom: 5px;
  }

}

</style>
