<template>
  <div class="loading-wrapper" v-if="isLoading">
    <b-loading :is-full-page="true" :active="true" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>

  <div class="c-w-100 bg-white" v-else >

    <CardPriceTypeOffering v-if="isSMRC" :isPriceActual="isPriceActual" />

    <div class="p-5 px-6 bg-white px-sm-1" :class=" {'mt-5': isSMRC}">
      <div class="is-flex is-justify-content-space-between mb-5">
      <div class="is-flex is-align-items-center is-clickable mb-5 mt-3" @click="goBack()">
        <ArrowLeftIcon class="mr-3" />
        <p class="averta-bold">Kembali</p>
      </div>
      <ButtonApp @click="previewOffering" :is-bold="true" height="3em">
        <div class="is-flex is-align-items-center pr-2">
          Preview Unduh Penawaran
          <img src="@/assets/img/download.png" class="ml-2" />
        </div>
      </ButtonApp>
    </div>

    <div ref="document" class="px-3 averta-regular" v-if="dataResult">
      <div>
        <p class="averta-black label-16">Durasi Pengerjaan Proyek</p>
        <div class="is-flex is-align-items-center mb-4">
          <p class="text-grey">Tanggal Pengerjaan : </p>
          <p class="averta-bold text-grey ml-1">{{formatDateDurasi()}}</p>
        </div>
      </div>

      <CardDurationProject
        :storeName="dataResult.store_name"
        :unitName="dataResult.unit_name"
        :isSMRC="isSMRC"
        :surveyId="dataResult.survey_id"
        :externalId="dataResult.external_id"
        :duration="dataResult.duration"
      />

      <CardItemPreviewOfferingVue
        v-if="isPriceTenant"
        :dataOffering="dataOfferingTenant"
        :selectedTipeOffering="selectedTipeOffering"
        @openModalImage="openModalImage($event)"
      />
      <CardItemPreviewOfferingVue
        v-else
        :dataOffering="dataOffering"
        :selectedTipeOffering="selectedTipeOffering"
        @openModalImage="openModalImage($event)"
      />
    </div>

    <ModalPreviewImage :isActive="modalPreviewImage" :dataImage="dataModalImage" title="" @closeModal="closeModalImage()" />
  </div>

  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { formatCurrency, Terbilang, alertErrorMessage, isSMRC } from '@/helpers'
import { ArrowLeftIcon, SpinnerIcon } from '@/components/icons'

import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import CardItemPreviewOfferingVue from '@/components/card/CardItemPreviewOffering.vue'
import CardPriceTypeOffering from '@/components/card/CardPriceTypeOffering.vue'
import CardDurationProject from '@/components/card/CardDurationProject.vue'

export default {
  name: 'PenawaranClient',
  props: {
    show: {
      type: Boolean
    },
    offeringId: {
      type: Number
    },
    dateWorking: {
      type: Object
    },
    selectedTipeOffering: {
      type: String,
      default: 'price-actual'
    },
    dataHeader: {
      type: Object
    }
  },
  components: {
    SpinnerIcon,
    ArrowLeftIcon,
    ButtonApp,
    ModalPreviewImage,
    CardItemPreviewOfferingVue,
    CardPriceTypeOffering,
    CardDurationProject
  },
  data () {
    return {
      isLoading: false,
      dataOffering: null,
      dataOfferingTenant: null,
      dataModalImage: [],
      modalPreviewImage: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      isPreviewDownload: 'project/getPreviewDownloadOffering'
    }),
    isSMRC () {
      return isSMRC(this.dataHeader.vendor.code || '')
    },
    isPriceActual () {
      return this.selectedTipeOffering === 'price-actual' || !this.selectedTipeOffering
    },
    isPriceTenant () {
      return this.selectedTipeOffering === 'price-tenant'
    },
    dataResult () {
      if (this.isPriceTenant) {
        return this.dataOfferingTenant
      } else {
        return this.dataOffering
      }
    }
  },
  methods: {
    goBack () {
      this.$emit('closeModal', true)
    },
    getDataDetailClient () {
      this.isLoading = true
      const payload = {
        url: this.url,
        offering_id: this.offeringId
      }

      if (this.isPriceTenant) {
        payload.tenant = true
      }

      this.$store
        .dispatch('project/getDataDetailClient', payload)
        .then(response => {
          const res = response.data.data

          const updateDataOffering = (dataOffering) => {
            let numbering = 0
            dataOffering.item_components.forEach(area => {
              area.components.forEach(subArea => {
                subArea.item_components.forEach(component => {
                  numbering++
                  component.numbering = numbering
                })
              })
            })
            this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
              dataOffering
            })
          }

          if (this.isPriceActual) {
            this.dataOffering = res
            updateDataOffering(this.dataOffering)
          } else {
            this.dataOfferingTenant = res
            updateDataOffering(this.dataOfferingTenant)
          }

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    formatIdr (value, hideCurrency) {
      return formatCurrency(value, hideCurrency)
    },
    previewOffering () {
      let path
      const query = {
        preview: false
      }
      if (this.isPriceActual) {
        path = `/am/offering-project/${this.offeringId}`
        if (this.isSMRC) query.actual = true
      } else if (this.isPriceTenant) {
        path = `/am/offering-tenant/${this.offeringId}`
      } else {
        console.error('Tipe offering tidak dikenali.')
        return
      }

      const routeData = this.$router.resolve({
        path,
        query
      })
      window.open(routeData.href, '_blank')
    },
    closeModalImage () {
      this.dataModalImage = []
      this.modalPreviewImage = false
    },
    openModalImage (data) {
      const surveyImages = data.map(item => item.picture_url)
      this.dataModalImage = surveyImages
      this.modalPreviewImage = true
    },
    formatDateDurasi () {
      if (this.dateWorking && Object.keys(this.dateWorking).length > 0) {
        const startDate = moment(this.dateWorking.date_working || this.dateWorking.working_date)
        const endDate = moment(this.dateWorking.estimate_date_finish || this.dateWorking.estimate_finish_project)

        let resDate = '-'

        if (startDate.isValid()) {
          resDate = moment(startDate).format('DD MMMM YYYY')
        }

        if (endDate.isValid()) {
          if (resDate !== '-') {
            resDate += ' - '
          }
          resDate += moment(endDate).format('DD MMMM YYYY')
        }

        return resDate
      }
      return '-'
    },
    formatSpellOut (value) {
      return Terbilang(value)
    },
    checkData () {
      if (!this.dataOffering) {
        this.getDataDetailClient()
      } else if (!this.dataOfferingTenant && this.selectedTipeOffering === 'price-tenant') {
        this.getDataDetailClient()
      }
    }
  },
  mounted () {
  },
  watch: {
    show (val) {
      if (val) {
        this.checkData()
      }
    }
  }
}
</script>

<style scoped>
.mt-5{
  margin-top: 5rem !important;
}

.loading-wrapper {
  width: 100%;
  height: 90vh;
}
</style>
