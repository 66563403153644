<template>
    <div class="wrap-material">
        <div v-if="data.length > 0">
          <!-- Daftar Material Process -->
          <div class="is-flex is-justify-content-space-between is-align-items-center my-5">
            <div>
              <p class="averta-black">Daftar Material Sudah Diproses</p>
              <p>Material berikut adalah material yang sudah di proses</p>
            </div>
            <!-- <button class="btn-tambah averta-bold label-14 is-clickable">Tambah Biaya</button> -->
          </div>

          <div v-for="storeMaterial, indexStore in data" :key="`${indexStore}-store`" class="wrap-store">
              <div class="mb-4 has-background-light p-3 is-flex is-align-items-center is-justify-content-space-between">
                  <div class="is-flex is-align-items-center">
                      <div class="mr-6">
                          <p class="has-text-grey label-14"><i>Nama Toko</i></p>
                          <div class="is-flex is-align-items-center">
                            <p class="label-16 averta-bold">{{ storeMaterial.material_store_name }}</p>
                            <div @click="showHideModal(storeMaterial.material_store_id, storeMaterial.material_store_name, storeMaterial.expedition_name, storeMaterial.expedition_price, storeMaterial.purchase_id)">
                              <EditIcon class="ml-2 is-clickable" />
                            </div>
                          </div>
                      </div>
                      <div>
                          <p class="has-text-grey label-14"><i>Tanggal Pemesanan</i></p>
                          <p class="label-16 averta-bold">{{ convertDate(storeMaterial.created_at) }}</p>
                      </div>
                  </div>
                  <div>
                      <p class="averta-bold" v-if="storeMaterial.expedition_name === null && storeMaterial.expedition_price === null">Ongkir: <span class="text-merah"><i><u>Belum Ditentukan</u></i></span></p>
                      <div class="is-flex is-align-items-center" v-else>
                        <p class="averta-bold">Ongkir: <span class="averta-bold">{{ storeMaterial.expedition_name }} |</span> <span class="has-text-success averta-black">{{ formatThousand(storeMaterial.expedition_price) }}</span></p>
                        <div @click="showHideModal(storeMaterial.material_store_id, storeMaterial.material_store_name, storeMaterial.expedition_name, storeMaterial.expedition_price, storeMaterial.purchase_id)">
                          <EditIcon class="ml-2 is-clickable" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="has-text-centered is-relative is-flex is-justify-content-space-bertween">
                <!-- Table Fixed -->
                <div class="table-left-fixed">
                  <table class="table">
                    <tr class="has-background-grey has-text-white averta-bold label-14 tr-header">
                        <td>NO</td>
                        <td>MATERIAL</td>
                        <td>UOM (SATUAN)</td>
                        <td>HARGA/PCS</td>
                        <td>QTY DIPESAN</td>
                        <td class="border-radius-0">TOTAL</td>
                    </tr>
                    <tr v-for="material, indexMaterial in storeMaterial.materials" :key="`${indexMaterial}-material-process-fixed`" class="label-12">
                        <td>
                          <div>{{ indexMaterial + 1 }}</div>
                        </td>
                        <td>
                          <div class="is-flex is-align-items-center">
                            <div class="image-material">
                                <img :src="material.picture" v-if="material.picture" @click="handlePreviewImage(material.picture)" class="is-clickable">
                                <img :src="require('@/assets/img/empty-img-2.png')" v-else>
                            </div>
                            <p class="material-name">{{ material.name }}</p>
                          </div>
                        </td>
                        <td>
                          <div>{{ material.uom }}</div>
                        </td>
                        <td class="averta-black has-text-left">
                          <div>{{ formatThousand(material.unit_price) }}</div>
                        </td>
                        <td class="averta-black">
                          <div>{{ material.qty_booked }}</div>
                        </td>
                        <td class="averta-bold border-radius-0 has-text-left">
                          <div>{{ formatThousand(material.total_price) }}</div>
                        </td>
                    </tr>
                  </table>
                </div>
                <!-- Table Fixed -->

                <!-- Table not Fixed -->
                <div class="table-not-fixed thin-scroll-dekstop pb-1">
                  <table class="table">
                      <tr class="has-background-grey has-text-white averta-bold label-14 tr-header">
                          <td class="border-radius-0 p-0">
                            <div class="td-width">PEMESANAN</div>
                          </td>
                          <td class="p-0">
                            <div class="td-width">PEMBELIAN</div>
                          </td>
                          <td class="p-0">
                            <div class="td-width">TERKIRIM</div>
                          </td>
                          <td>
                            <div>TUJUAN</div>
                          </td>
                          <td>
                            <div>
                              ACTION
                            </div>
                          </td>
                      </tr>
                      <tr v-for="material, indexMaterial in storeMaterial.materials" :key="`${indexMaterial}-material-process`" class="label-12 tr-not-fixed">
                          <td class="border-radius-0 checklist-wrapper">
                              <input type="checkbox" class="checkbox" :checked="material.is_booked" disabled />
                          </td>
                          <td class="checklist-wrapper">
                              <input type="checkbox" class="checkbox" :checked="material.is_ordered" @change="event => statusMaterial(event, material, 'ordered')" :disabled="(material.is_delivered || mode.deliver || (mode.order && material.is_ordered)) && 'disabled'"/>
                          </td>
                          <td class="checklist-wrapper">
                              <input type="checkbox" class="checkbox" :checked="material.is_delivered" @change="event => statusMaterial(event, material, 'delivered')" :disabled="(!material.is_ordered || mode.order) && 'disabled'" />
                          </td>
                          <td>
                            <div class="is-flex is-justify-content-space-between wrap-destination thin-scroll-dekstop" :class="{'is-align-items-center': !material.destination, 'is-align-items-start': material.destination}">
                              <p class="averta-bold label-12 mr-4 pr-4 white-space-pre-line" style="width: 200px" v-if="material.destination"> {{ material.destination }} </p>
                              <p class="text-merah white-space-nowrap mr-4 is-clickable" @click="() => showUbahTujuan(material.destination, material.material_order_id)" v-else><i>Belum diatur</i></p>
                              <div class="is-clickable wrap-edit" @click="() => showUbahTujuan(material.destination, material.material_order_id)"><EditIcon /></div>
                            </div>
                          </td>
                          <td>
                            <div @click="(!material.is_ordered && !selectMaterial.length && !mode.order && !mode.deliver) && removeMaterial(material.material_order_id)">
                              <ActionIcon
                                :color="(material.is_ordered || selectMaterial.length) && '#C0C0C0'"
                                :class="{'is-clickable': !selectMaterial.length}"
                              />
                            </div>
                          </td>
                      </tr>
                  </table>
                </div>
                <!-- Table not Fixed -->
              </div>
          </div>
        </div>
        <div v-else class="column c-w-100 has-text-centered">
          <ProyekEmptyIcon />
          <p class="averta-bold mt-2">
            Material Diproses Kosong
          </p>
        </div>
        <!-- Daftar Material Process -->

        <!-- Floating button bottom -->
        <div class="wrap-floating-bottom is-flex is-justify-content-space-between is-align-items-center" v-if="selectMaterial.length > 0">
            <div class="is-flex is-justify-content-space-between is-align-items-center">
                <GroupMaterial class="mr-3"/>
                <div class="has-text-white">
                    <p class="label-12">Jumlah Material dipilih</p>
                    <p class="label-16 averta-black">{{ selectMaterial.length }} Material</p>
                </div>
            </div>
            <div @click="updateStatus()">
              <button class="btn-lanjutkan averta-bold label-14 is-clickable">
                  <p v-if="mode.order">Konfirmasi Pembelian</p>
                  <p v-else>Konfirmasi Terkirim</p>
              </button>
            </div>
        </div>
        <!-- Floating button bottom -->

        <!-- Modal Edit Pemesanan -->
        <b-modal :can-cancel="false" v-model="modalEditPemesanan" width="40vw">
          <div class="content-modal">
            <div class="modal-header is-flex is-justify-content-space-between is-align-items-center">
              <div class="is-flex is-align-items-center">
                <EditIcon color="white" class="mr-3" />
                <p class="averta-bold has-text-white label-20">Ubah Informasi Pemesanan</p>
              </div>
              <div class="is-clickable" @click="showHideModal()">
                <b-icon icon="close"></b-icon>
              </div>
            </div>
            <div class="modal-body p-5">
              <div class="mb-5">
                <p class="has-text-grey mb-1">Toko Material</p>
                <div class="dropdown-wrapper">
                  <input class="input-data averta-bold label-14 text-merah" ref="inputDataToko" type="text" placeholder="Pilih Toko Material" v-model="searchToko" @input="handleSearch" @focus="onOpen">
                  <p class="text-merah mt-1" v-if="validationToko"><i>Data toko tidak valid</i></p>
                  <div v-if="openSelectOption" v-closable="{ exclude: ['inputDataToko'], handler: 'onClose'}" class="option-wrapper">
                    <div v-for="toko, indexToko in listFilterToko" :key="`${indexToko}-toko`" @click="handleSelectItem(toko)" class="wrap-item-store is-clickable my-1 is-flex is-justify-content-space-between is-align-items-center">
                      <div>
                        <p class="averta-bold label-12">{{ toko.name }}</p>
                        <p class="has-text-grey label-12">{{ toko.address ? toko.address : '-' }}</p>
                      </div>
                      <PlusGradient />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-5">
                <p class="has-text-grey mb-1">Masukkan Ekspedisi (<i>bisa diinput terpisah</i>)</p>
                <input class="input-data label-14" placeholder="Masukkan Ekspedisi" type="text" v-model="expeditionName"/>
              </div>
              <div>
                <p class="has-text-grey mb-1">Masukkan Ongkos Kirim (<i>bisa diinput terpisah</i>)</p>
                <input class="input-data label-14" placeholder="Masukkan Ongkos Kirim" type="text" v-model="expeditionPrice" @input="formatPrice()" />
              </div>
              <div class="is-flex is-justify-content-end wrap-btn-simpan">
                <div class="btn-simpan is-clickable" @click="updateOrder()">
                  <CheckIcon class="mr-2" />
                  <p class="averta-bold">Simpan</p>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <!-- Modal Edit Pemesanan -->

        <!-- Modal Ubah Tujuan -->
        <ModalUbahTujuan :materialOrderId="materialOrderId" :data="dataTujuan" :showModal="modalUbahTujuan" width="40vw" @closeModal="showUbahTujuan" @reloadData="reloadData" @isLoading="isLoadingChange($event)"/>
        <!-- Modal Ubah Tujuan -->

        <!-- Modal Preview Image -->
        <ModalPreviewImage :isActive="previewImage" :urlOneImage="imagePreview" @closeModal="closePreviewImage()" />
        <!-- Modal Preview Image -->

        <!-- Loading -->
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
          <SpinnerIcon />
        </b-loading>
        <!-- Loading -->
    </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { formatCurrency } from '@/helpers'
import { GroupMaterial, EditIcon, ActionIcon, SpinnerIcon, PlusGradient, CheckIcon, ProyekEmptyIcon } from '@/components/icons'
import removeMaterialImage from '@/assets/img/delete-area-component.png'
import ModalUbahTujuan from '@/components/modal/ModalUbahTujuan'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'

export default {
  name: 'TabMaterialProcess',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  components: {
    GroupMaterial,
    EditIcon,
    ActionIcon,
    SpinnerIcon,
    ProyekEmptyIcon,
    PlusGradient,
    CheckIcon,
    ModalUbahTujuan,
    ModalPreviewImage
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      isLoading: false,
      selectMaterial: [],
      mode: {
        order: false,
        deliver: false
      },
      modalEditPemesanan: false,
      dataSelectedToko: null,
      expeditionName: null,
      expeditionPrice: null,
      listToko: null,
      searchToko: null,
      listFilterToko: null,
      openSelectOption: false,
      purchaseId: null,
      validationToko: false,
      modalUbahTujuan: false,
      dataTujuan: '',
      materialOrderId: null,
      previewImage: false,
      imagePreview: null
    }
  },
  methods: {
    formatThousand (amount) {
      return formatCurrency(amount)
    },
    sliceMaterial (data) {
      let index = -1

      for (let i = 0; i < this.selectMaterial.length; i++) {
        if (this.selectMaterial[i].id === data.id) {
          index = i
          break
        }
      }

      if (index !== -1) {
        this.selectMaterial.splice(index, 1)
      }
    },
    statusMaterial (event, data, type) {
      // Check Type Ordered
      if (type === 'ordered') {
        // Check status checked
        if (data.is_ordered === false) {
          this.mode.order = true
          // Check checked element
          if (event.target.checked === true) {
            this.selectMaterial.push(data)
          } else {
            this.sliceMaterial(data)
          }
        } else {
          this.unCheckStatus(data, type, event)
        }
      }

      // Check Type Delivered
      if (type === 'delivered') {
        // Check status checked
        if (data.is_delivered === false) {
          this.mode.deliver = true
          // Check checked element
          if (event.target.checked === true) {
            this.selectMaterial.push(data)
          } else {
            this.sliceMaterial(data)
          }
        } else {
          this.unCheckStatus(data, type, event)
        }
      }
    },
    convertDate (value) {
      return moment(value).format('DD MMMM YYYY')
    },
    async updateStatus () {
      this.isLoading = true
      const payload = {
        url: this.url
      }

      const data = {
        material_order_ids: []
      }

      await this.selectMaterial.forEach((item, index) => {
        data.material_order_ids.push(item.material_order_id)
      })

      if (this.mode.order) {
        data.status = 'ordered'
      }

      if (this.mode.deliver) {
        data.status = 'delivered'
      }

      payload.data = data

      this.$store
        .dispatch('project/updateStatusMaterial', payload)
        .then((response) => {
          this.selectMaterial = []
          this.mode.order = false
          this.$emit('getMaterialProcess')
          this.isLoading = false
          this.$buefy.toast.open({
            message: 'Berhasil merubah status material',
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal merubah status material, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    removeMaterial (materialId) {
      this.$swal({
        imageUrl: removeMaterialImage,
        imageHeight: 250,
        imageAlt: 'order-material',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-18">Hapus Material</p>',
        html: '<p class="label-14 averta-regular">Pastikan seluruh informasi material yang diinginkan telah sesuai.</p>',
        showCancelButton: true,
        confirmButtonText: 'Hapus Material',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true

          const payload = {
            url: this.url,
            project_id: this.$route.params.projectId,
            material_id: materialId
          }

          this.$store
            .dispatch('project/deleteMaterialOrder', payload)
            .then((response) => {
              this.$emit('getDaftarMaterial')
              this.$emit('getMaterialProcess')
              this.isLoading = false
              this.$buefy.toast.open({
                message: 'Berhasil hapus material',
                type: 'is-success'
              })
            })
            .catch((error) => {
              this.isLoading = false
              console.log(error)
              this.$buefy.toast.open({
                message: 'Gagal hapus material, silahkan coba lagi',
                type: 'is-danger'
              })
            })
        }
      })
    },
    unCheckStatus (data, type, event) {
      const payload = {
        url: this.url
      }

      this.$swal({
        imageUrl: removeMaterialImage,
        imageHeight: 250,
        imageAlt: 'order-material',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: `<p class="averta-bold label-18">${type === 'ordered' ? 'Hapus Status Pembelian Material?' : 'Hapus Status Terkirim Material?'}</p>`,
        html: '<p class="label-14 averta-regular">Pastikan seluruh informasi material yang diinginkan telah sesuai.</p>',
        showCancelButton: true,
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoading = true

          const dataPayload = {
            status: type === 'ordered' ? 'booked' : 'ordered',
            material_order_ids: [data.material_order_id]
          }

          payload.data = dataPayload

          this.$store
            .dispatch('project/updateStatusMaterial', payload)
            .then((response) => {
              this.$emit('getMaterialProcess')
              this.isLoading = false
              this.$buefy.toast.open({
                message: 'Berhasil merubah status material',
                type: 'is-success'
              })
            })
            .catch((error) => {
              this.isLoading = false
              console.log(error)
              this.$buefy.toast.open({
                message: 'Gagal merubah status material, silahkan coba lagi',
                type: 'is-danger'
              })
            })
        } else {
          event.target.checked = true
        }
      })
    },
    getListStore (search) {
      this.isLoading = true
      const payload = {
        url: this.url
      }

      if (search) {
        payload.search = search
      }

      this.$store
        .dispatch('store/getListStoreModal', payload)
        .then((response) => {
          this.listToko = response.data.data
          this.listFilterToko = response.data.data
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal memuat data daftar toko material, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    showHideModal (storeId, storeName, expeditionName, expeditionPrice, purchaseId) {
      this.modalEditPemesanan = !this.modalEditPemesanan
      if (this.modalEditPemesanan) {
        if (storeId) {
          this.dataSelectedToko = {
            id: storeId,
            name: storeName
          }
          this.searchToko = storeName
        }

        if (expeditionName) {
          this.expeditionName = expeditionName
        }

        if (expeditionPrice) {
          this.expeditionPrice = expeditionPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }

        if (purchaseId) {
          this.purchaseId = purchaseId
        }

        this.getListStore()
      } else {
        this.dataSelectedToko = null
        this.searchToko = null
        this.expeditionName = null
        this.expeditionPrice = null
        this.purchaseId = null
        this.validationToko = null
      }
    },
    handleSearch (e) {
      const value = e.target.value

      const filterData = this.listToko.filter(item =>
        item.name.toLowerCase().includes(value.toLowerCase())
      )

      this.listFilterToko = filterData
    },
    onOpen () {
      if (this.searchToko === 'Pilih Toko' || this.searchToko === '') {
        this.searchToko = ''
        this.listFilterToko = this.listToko
      }
      this.openSelectOption = true
    },
    onClose () {
      this.$refs.inputDataToko.blur()
      this.openSelectOption = false
    },
    handleSelectItem (data) {
      this.searchToko = data.name
      this.dataSelectedToko = data
      this.onClose()
    },
    formatPrice () {
      // Menghapus karakter selain angka
      const rawPrice = this.expeditionPrice.replace(/[^0-9]/g, '')

      // Menambahkan koma setiap tiga digit
      this.expeditionPrice = rawPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    updateOrder () {
      const filterData = this.listToko.filter(item =>
        item.name.toLowerCase() === this.searchToko.toLowerCase()
      )

      if (filterData.length === 0) {
        this.validationToko = true
      } else {
        this.validationToko = false
      }

      if (!this.validationToko) {
        this.isLoading = true
        const payload = {
          url: this.url,
          project_id: this.$route.params.projectId,
          data: {
            purchase_id: this.purchaseId,
            expedition_name: this.expeditionName,
            expedition_price: +this.expeditionPrice.replace(/[^0-9]/g, ''),
            material_store_id: this.dataSelectedToko.id
          }
        }

        this.$store
          .dispatch('project/updatePurchase', payload)
          .then((response) => {
            this.$emit('getMaterialProcess')
            this.modalEditPemesanan = false
            this.dataSelectedToko = null
            this.searchToko = null
            this.expeditionName = null
            this.expeditionPrice = null
            this.purchaseId = null
            this.isLoading = false
            this.$buefy.toast.open({
              message: 'Berhasil update data',
              type: 'is-success'
            })
          })
          .catch((error) => {
            this.isLoading = false
            console.log(error)
            this.$buefy.toast.open({
              message: 'Gagal update data, silahkan coba lagi',
              type: 'is-danger'
            })
          })
      }
    },
    showUbahTujuan (data, materialOrderId) {
      if (data) {
        this.dataTujuan = data
        this.materialOrderId = materialOrderId
      } else {
        this.dataTujuan = ''
        this.materialOrderId = materialOrderId
      }
      this.modalUbahTujuan = !this.modalUbahTujuan
    },
    isLoadingChange (data) {
      this.isLoading = data
    },
    reloadData () {
      this.$emit('getMaterialProcess')
      this.modalUbahTujuan = false
      this.dataTujuan = ''
      this.materialOrderId = null
    },
    handlePreviewImage (url) {
      if (url) {
        this.previewImage = true
        this.imagePreview = url
      }
    },
    closePreviewImage () {
      this.imagePreview = null
      this.previewImage = false
    }
  },
  watch: {
    selectMaterial (newVal) {
      if (newVal.length === 0) {
        this.mode.order = false
        this.mode.deliver = false
      }
    }
  }
}
</script>

<style scoped>

.wrap-material {
    margin-bottom: 100px;
}

.btn-tambah {
    background: var(--gradient-red-red-primary-button, linear-gradient(116deg, #D9272D 16.15%, #EB4600 83.24%));
    padding: 12px 32px;
    border-radius: 20px;
    color: white;
    border: none;
}

.wrap-store {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 8px solid #F0F0F0;
}

.wrap-store:last-child {
    border-bottom: none;
}

.checkbox {
    width: 18px;
    height: 18px;
}

.image-material {
    width: 40px;
}

.image-material img {
  object-fit: contain;
}

.material-name {
  font-size: 12px;
  width: 20em;
  text-align: left;
  margin-left: 10px;
}

.btn-lanjutkan {
    border: none;
    background: white;
    padding: 12px 32px;
    border-radius: 20px;
    color: #EB4600;
}

.wrap-floating-bottom {
    background: var(--red-shade-primary, #911A1E);
    padding: 20px;
    border-radius: 18px;
    position: fixed;
    bottom: 60px;
    left: 110px;
    width: 90%;
    z-index: 3;
}

.table-left-fixed {
  position: sticky;
  left: 0;
  top: 3px;
  z-index: 1;
  background-color: white;
  border-right: 5px solid #C0C0C0;
  height: calc(100% - 5px);
}

.table-not-fixed {
  position: sticky;
  overflow-x: auto;
}

.table tr, td {
  border: 1px solid #C0C0C0;
  border-collapse: collapse;
}

.table td {
  vertical-align: middle;
  padding: 0px 15px;
  height: 6em;
}
.table-not-fixed .table .tr-not-fixed td {
  vertical-align: middle;
  padding: 0px 15px;
  height: 6em;
}

.content-modal {
  width: 100%;
}

.modal-header {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}

.modal-body {
  background: white;
  border-radius: 0px 0px 12px 12px;
  height: 450px;
  position: relative;
}

.dropdown-wrapper {
  position: relative;
}

.input-data {
  width: 100%;
  height: 3em;
  padding: 10px 16px;
  border: 1px solid #C1C7D3;
  border-radius: 10px;
  outline: none;
}

.option-wrapper {
  position: absolute;
  top: 40px;
  right: 0;
  width: 100%;
  max-height: 17em;
  overflow: auto;
  background: white;
  z-index: 1;
  box-shadow: 6px 7px 50px 0px #00000014;
  padding: 4px 12px;
}

.wrap-item-store {
  padding: 12px;
}
.wrap-item-store:hover {
  background: #d2d2d21c;
  border-radius: 12px;
}

.wrap-btn-simpan {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 70px;
  box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.1);
  justify-content: end;
  align-items: center;
}

.btn-simpan {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 90px;
  color: white;
  width: 120px;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.wrap-destination {
  height: 51.5px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  width: 25em;
}

.wrap-edit {
  position: sticky;
  top: 15px;
  right: 4px;
}

.tr-header td {
  height: 4em;
}

.checklist-wrapper {
  width: 9em;
  padding: 0 !important;
}

.td-width {
  width: 8em;
}

</style>
