<template>
    <b-modal :on-cancel="closeModal" v-model="isModalActive">
      <div class="modal-template">
        <div class="modal-body w-100">
          <header class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold is-size-5">{{renderTitle}}</p>
            <div class="is-clickable" @click="closeModal()">
              <b-icon icon="close"></b-icon>
            </div>
          </header>
          <div class="mt-5">
            <div class="mx-5 mb-5">
              <label class="averta-bold is-size-6">Nama Detail Komponen</label>
              <input v-model="name" class="input mt-2 mb-3" type="text" placeholder="Isi disini" />

              <div v-if="isTypeEdit" class="is-flex is-align-items-center">
                <p class="averta-bold">{{isCheckActive ? 'Aktif' : 'Tidak Aktif'}}</p>
                <SwitchToggle :scale="0.7" :active="isCheckActive" @isCheck="$event => handleIsCheckActive($event)" />
              </div>
            </div>
            <footer class="py-5 is-flex is-justify-content-flex-end footer-shadow">
              <b-button class="btn-disabled averta-bold px-5 is-flex is-justify-content-center mr-5" @click=" isTypeEdit ? updateComponent() : addComponent()"
                :class="{ 'btn-active': name !== '' }">
                <div class="is-flex is-align-items-center">
                  <CheckIcon class="mr-3" />
                  <span>Simpan</span>
                </div>
              </b-button>
            </footer>
          </div>
        </div>
      </div>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { CheckIcon } from '@/components/icons'
import SwitchToggle from '@/components/SwitchToggle.vue'

export default {
  name: 'ModalDetailComponent',
  components: {
    CheckIcon,
    SwitchToggle
  },
  props: {
    type: {
      type: String,
      default: 'create-detail'
    },
    showModal: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number
    },
    nameProps: {
      type: String
    },
    activeProps: {
      type: Boolean
    }
  },
  data () {
    return {
      isModalActive: false,
      isCheckActive: true,
      name: ''
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    renderTitle () {
      if (this.type === 'create-detail-component') {
        return 'Tambah Detail Komponen'
      } else if (this.type === 'update-detail-component') {
        return 'Edit Detail Komponen'
      } else {
        return '-'
      }
    },
    isTypeEdit () {
      if (this.type === 'update-detail-component') {
        return true
      }
      return false
    }
  },
  methods: {
    handleIsCheckActive (e) {
      this.isCheckActive = e
    },
    closeModal () {
      this.$emit('closeModal', true)
    },
    addComponent () {
      if (!this.name) {
        return
      }
      const payload = {
        url: this.url,
        name: this.name
      }

      this.$store
        .dispatch('settings/postDataDetailComponents', payload)
        .then(response => {
          this.$emit('closeModal', 'success-add-component')
          this.$buefy.toast.open({
            message:
              '<p class="averta-regular">Berhasil membuat detail komponen</p>',
            type: 'is-success'
          })
        })
        .catch(error => {
          console.log(error)
          this.$buefy.toast.open({
            message:
              '<p class="averta-regular">Gagal membuat detail komponen, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    },
    updateComponent () {
      if (!this.name) {
        return
      }

      this.$swal({
        imageUrl: require('@/assets/img/delete-area-component.png'),
        imageAlt: 'image update area component',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Simpan Area?</p>',
        html: '<p>Pastikan kamu sudah cek kembali area ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            id: this.id,
            type: 'detail-component',
            body: {
              name: this.name,
              is_active: this.isCheckActive ? 1 : 0
            }
          }

          this.$store
            .dispatch('settings/updateData', payload)
            .then(response => {
              this.$emit('closeModal', 'success-add-component')
              this.$buefy.toast.open({
                message:
              '<p class="averta-regular">Data detail komponen berhasil diperbarui</p>',
                type: 'is-success'
              })
            })
            .catch(error => {
              console.log(error)
              this.$buefy.toast.open({
                message:
              '<p class="averta-regular">Gagal ubah detail komponen, silahkan coba lagi</p>',
                type: 'is-danger'
              })
            })
        }
      })
    }
  },
  watch: {
    showModal (newVal) {
      this.isModalActive = newVal
      if (this.isTypeEdit) {
        this.name = this.nameProps
        this.isCheckActive = this.activeProps
      } else {
        this.name = ''
        this.isCheckActive = true
      }
    }
  }
}
</script>

<style scoped>
.modal-template {
  width: 35em;
  margin: auto;
}

.modal-body {
  overflow: hidden;
  background: white;
  border-radius: 10px;
  height: 100%;
}

.modal-template header {
  background-color: #911a1e;
  height: 5em;
  color: white;
  padding: 0 20px;
}

.btn-active {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  color: white;
}

.btn-disabled {
  background-color: #9a9a9a;
  border-radius: 100px;
  color: white;
}

.footer-shadow {
  box-shadow: 0px -3px 30px 0px #00000012;
}
</style>
