<template>
  <div class="pt-3 px-0 container is-fluid">
    <div class="is-flex is-align-items-center is-justify-content-space-between pt-4 px-5 mb-4 is-hidden-mobile">
      <title-bar :title-stack="titleStack" />
    </div>
    <section>
      <div v-if="isLoadingCard" class="px-5 my-6">
        <CardSkeleton :totalCard="4" />
      </div>
      <CardPerformaDashboard v-else :dataCard="dataCard" role="konsultan" />

      <div v-if="isLoadingBerlangsung" class="px-5 mb-6">
        <CardSkeleton :totalCard="4" height="200px" />
      </div>
      <CardSurveryBerlangsungDashboard v-else-if="dataSurveyBerlangsung.length > 0" :dataSurveyBerlangsung="dataSurveyBerlangsung" />
      <hr />

      <div v-if="isLoadingCard" class="px-5 mb-6">
        <CardSkeleton :totalCard="3" height="200px" />
      </div>
      <CardDashboard v-else :dataCard="dataCard" @changeDataDashboard="changeDataDashboard($event)" />
      <hr />

      <div v-if="isLoadingWeekly || isLoadingDaily" class="px-5 mb-6">
        <CardSkeleton :totalCard="1" height="300px" />
      </div>
      <CalendarDashboard v-else :dataCalendarDaily="dataCalendarDaily" :dataCalendarWeekly="dataCalendarWeekly" />
    </section>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import TitleBar from '@/components/TitleBar'
import CardDashboard from '@/components/card/CardDashboard'
import CardSurveryBerlangsungDashboard from '@/components/card/CardSurveryBerlangsungDashboard'
import CardPerformaDashboard from '@/components/card/CardPerformaDashboard'
import CalendarDashboard from '@/components/CalendarDashboard'
import CardSkeleton from '@/components/skeleton/CardSkeleton'
import firsLoginImage from '../assets/img/firs-login.png'

export default {
  name: 'Home',
  components: {
    CardDashboard,
    CalendarDashboard,
    CardSkeleton,
    TitleBar,
    CardPerformaDashboard,
    CardSurveryBerlangsungDashboard
  },
  data () {
    return {
      isLoadingCard: false,
      isLoadingBerlangsung: false,
      isLoadingWeekly: false,
      isLoadingDaily: false,
      dataCard: null,
      dataSurveyBerlangsung: [],
      dataCalendarWeekly: null,
      dataCalendarDaily: null
    }
  },
  computed: {
    titleStack () {
      return [
        'Dashboard'
      ]
    },
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath',
      isLogin: 'auth/isLogin',
      email: 'auth/email',
      user: 'auth/user',
      firstLogin: 'auth/firstLogin',
      checkDefaultPassword: 'auth/checkDefaultPassword'
    })
  },
  methods: {
    getDataCard (payload) {
      this.isLoadingCard = true
      this.$store
        .dispatch('dashboard/getCardDashboard', payload)
        .then(response => {
          this.dataCard = response.data.data
          this.isLoadingCard = false
        })
        .catch(error => {
          this.isLoadingCard = false
          console.log(error)
        })
    },
    getSurveyBerlangsung () {
      this.isLoadingBerlangsung = true
      const payload = {
        url: this.url,
        page: 1
      }

      this.$store
        .dispatch('project/getSurveyBerlangsungDashboard', payload)
        .then(response => {
          this.dataSurveyBerlangsung = response.data.data.list
          this.isLoadingBerlangsung = false
        })
        .catch(error => {
          this.isLoadingBerlangsung = false
          console.log(error)
        })
    },
    getDataCalendarWeekly (payload) {
      this.isLoadingWeekly = true
      this.$store
        .dispatch('dashboard/getDataCalendarWeekly', payload)
        .then(response => {
          this.dataCalendarWeekly = response.data.data
          this.isLoadingWeekly = false
        })
        .catch(error => {
          this.isLoadingWeekly = false
          console.log(error)
        })
    },
    getDataCalendarDaily (payload) {
      this.isLoadingDaily = true
      this.$store
        .dispatch('dashboard/getDataCalendarDaily', payload)
        .then(response => {
          this.dataCalendarDaily = response.data.data
          this.isLoadingDaily = false
        })
        .catch(error => {
          this.isLoadingDaily = false
          console.log(error)
        })
    },
    changeDataDashboard (data) {
      const payload = {
        ...data,
        url: this.url
      }

      this.getDataCard(payload)
      this.getDataCalendarWeekly(payload)
      this.getDataCalendarDaily(payload)
    }
  },
  mounted () {
    const rs = localStorage.getItem('isSign')

    const v = JSON.parse(rs)
    if (v === 1) {
      this.$buefy.snackbar.open({
        message: `<p class="averta-regular">Welcome back ${this.user.name}</p>`,
        queue: false
      })
      localStorage.removeItem('isSign')
    } else {
      localStorage.removeItem('isSign')
    }

    if (this.firstLogin) {
      this.$swal({
        imageUrl: firsLoginImage,
        imageAlt: 'first login image',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: `<p class="averta-bold label-22">Hallo! ${this.user.name}</p>`,
        html: '<p>Selamat datang di Dashboard Konsultan</p>',
        confirmButtonText: 'Mulai',
        confirmButtonColor: '#EB4600',
        customClass: {
          confirmButton: 'btn-first-login'
        }
      }).then(() => {
        this.$store.commit('auth/setFirstLogin', false)
      })
    }

    document.documentElement.classList.remove('has-aside-left')
    document.documentElement.classList.remove('has-navbar-fixed-top')
  },
  created () {
    const payload = { url: this.url }
    this.getDataCard(payload)
    this.getDataCalendarWeekly({
      ...payload,
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD')
    })
    this.getDataCalendarDaily(payload)
    this.getSurveyBerlangsung()
  }
}
</script>

<style lang="scss" scoped></style>
