<template>
  <div>
    <table v-if="data.length > 0" class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <tr class="bg-dark-grey-5 has-text-white has-text-centered tr-wrapper">
        <td  class="py-4" style="width: 2%;">
          <p class="averta-bold">N0.</p>
        </td>
        <td class="py-4" style="width: 25%;">
          <p class="averta-bold">NO. UNIT</p>
        </td>
      </tr>
      <tr v-for="(noUnit, index) in data" :key="index" class="vertical-middle tr-wrapper">
        <td class="py-3 vertical-align-midle">
          <p class="has-text-centered">{{ index + 1 }}</p>
        </td>
        <td class="py-3">
          <div class="is-flex is-align-items-center mx-2 averta-bold">
            <p>{{ noUnit.name }}</p>
          </div>
        </td>
      </tr>
    </table>

    <div v-else class="column c-w-100 has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Data Tidak Ditemukan
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ProyekEmptyIcon } from '@/components/icons'

export default {
  name: 'TabListNoUnitStore',
  props: {
    type: {
      type: String,
      default: null
    },
    data: {
      type: Array
    },
    refreshData: {
      type: Function
    }
  },
  components: {
    ProyekEmptyIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      statusTemp: 'Aktif'
    }
  },
  methods: {

  }
}
</script>
