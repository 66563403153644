<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_12771_167886"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="3"
      width="22"
      height="18"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.1431 12.0035C4.1431 6.95349 8.3131 2.86349 13.4031 3.00349C18.0931 3.13349 22.0131 7.05349 22.1431 11.7435C22.2831 16.8335 18.1931 21.0035 13.1431 21.0035C11.0531 21.0035 9.1431 20.2935 7.6231 19.0935C7.1531 18.7335 7.1231 18.0235 7.5431 17.6035C7.9031 17.2435 8.4631 17.2135 8.8631 17.5235C10.0431 18.4535 11.5331 19.0035 13.1431 19.0035C17.0431 19.0035 20.1931 15.8135 20.1431 11.9035C20.0931 8.18349 16.9631 5.05349 13.2431 5.00349C9.3231 4.95349 6.1431 8.10349 6.1431 12.0035H7.9331C8.3831 12.0035 8.6031 12.5435 8.2931 12.8535L5.5031 15.6535C5.3031 15.8535 4.9931 15.8535 4.7931 15.6535L2.0031 12.8535C1.6831 12.5435 1.9031 12.0035 2.3531 12.0035H4.1431ZM12.1431 8.75349C12.1431 8.34349 12.4831 8.00349 12.8931 8.00349C13.3031 8.00349 13.6431 8.34349 13.6431 8.74349V12.1435L16.5231 13.8535C16.8731 14.0635 16.9931 14.5235 16.7831 14.8835C16.5731 15.2335 16.1131 15.3535 15.7531 15.1435L12.6331 13.2935C12.3331 13.1135 12.1431 12.7835 12.1431 12.4335V8.75349Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_12771_167886)">
      <rect width="24" height="24" :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'HistoryLineIcon',
  props: {
    color: {
      type: String,
      default: '#E10009'
    },
    size: {
      type: String,
      default: '24'
    }
  }
}
</script>
