<template>
  <div class="is-flex is-justify-content-center is-align-items-center has-text-centered">
    <div class="width-card">
      <img src="../assets/img/data-not-found.png" alt="data-not-found">
      <p class="averta-bold my-5">Data Tidak Ditemukan</p>
      <p>Data yang kamu cari tidak ditemukan. Periksa kembali kata kunci pencarian yang digunakan.</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DataNotFound'
}
</script>

<style lang="scss" scoped>
.width-card {
  width: 30%;
}

@media only screen and (max-width: 600px) {
  .width-card {
    width: 80%;
  }
}

</style>
