export const ListDaftarBranchData = dataBranch => {
  return [
    {
      image: 'wave-card.png',
      total: dataBranch.code === 'SMRCN' ? 'Total Unit' : 'Total Cabang',
      listBranch: dataBranch.card.total_branch,
      index: 0
    },
    {
      image: 'wave-card.png',
      total: dataBranch.code === 'SMRCN' ? 'Total Building' : 'Total Toko',
      listBranch: dataBranch.card.total_store,
      index: 1
    },
    {
      image: 'wave-card.png',
      total: dataBranch.code === 'SMRCN' ? 'Total PIC' : 'Total PIC',
      listBranch: dataBranch.card.total_pic,
      index: 2
    }
  ]
}
