<template>
  <div v-if="show" class="alert-assign is-align-items-center px-3 is-flex" :class="{ 'sticky py-1': isSticky, 'py-2': !isSticky, 'is-pinned': isPinned}">
    <img :src="require('@/assets/img/info-circle.png')" alt="" class="mr-2" :class="{'ml-6': isPinned && !isMobile}">
    <p class="averta-bold text-merah label-sm-12" :class="{'label-14': this.isPinned, 'label-16': !this.isPinned}">Assign tukang jagoan yang akan bekerja pada proyek ini</p>
  </div>
</template>

<script>

export default {
  name: 'AlertAssignTukang',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isSticky: {
      type: Boolean,
      default: false
    },
    isPinned: {
      type: Boolean,
      default: false
    },
    navbarPosition: {
      type: String
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isPinned (val) {
      if (val) {
        const stickyAlert = document.querySelector('.alert-assign')
        if (stickyAlert) {
          stickyAlert.style.setProperty('top', `calc(${this.navbarPosition || '0px'} + 65px - 0.5px)`) // 65px = height sticky-header
          stickyAlert.style.setProperty('margin-left', this.isMobile ? '-20px' : '-2px')
          stickyAlert.style.setProperty('width', this.isMobile ? '111%' : '100%')
        }
      }
    }
  }
}
</script>

<style scoped>
.alert-assign {
  position: relative;
  background: var(--Gradient-Red-Red-01, linear-gradient(90deg, #fdd 0%, #ffefdb 99.97%));
}

.alert-assign svg {
  width: 20px;
  height: 20px;
}

.alert-assign.sticky {
  display: none !important;
}

.alert-assign.sticky.is-pinned {
  position: sticky;
  display: flex !important;
}

@media screen and (max-width: 600px) {
  .alert-assign {
    margin-top: -11px;
  }
}
</style>
