<template>
  <div>
    <p class="averta-black label-18 mb-4">Detail Informasi Item pekerjaan</p>

    <div class="bg-lightgray border-radius-20 overflow-visible p-4">
      <p class="averta-black label-18 mb-2">Rincian Harga Penawaran</p>
      <div class="input-wrapper-grid">
        <div>
          <p class="mb-2">Harga Jasa <span class="color-grey averta-reguler-italic">(Opsional)</span></p>
          <InputPrice :value="servicePrice" @change="$event => handleChangeInput($event, 'service_price')" />
        </div>
        <div>
          <p class="mb-2">Harga Material <span class="color-grey averta-reguler-italic">(Opsional)</span></p>
          <InputPrice :value="materialPrice" @change="$event => handleChangeInput($event, 'material_price')" />
        </div>
        <div>
          <p class="mb-2">Harga Satuan <span class="color-red">*</span></p>
          <InputPrice :value="unitPrice" @change="$event => handleChangeInput($event, 'unit_price')" />
        </div>
        <div>
          <p class="mb-2">Satuan <span class="color-red">*</span></p>
          <InputAutocompleteSatuan @selectedUnit="$event => handleSelectUnit($event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputPrice from '@/components/input/InputPrice.vue'
import InputAutocompleteSatuan from '@/components/input/InputAutocompleteSatuan.vue'

export default {
  name: 'InputPriceItemWorking',
  components: {
    InputPrice,
    InputAutocompleteSatuan
  },
  data () {
    return {
      servicePrice: null,
      materialPrice: null,
      unitPrice: null
    }
  },
  computed: {
    ...mapGetters({
      reset: 'project/getResetUnitPrice'
    })
  },
  methods: {
    handleChangeInput (value, type) {
      let number = 0
      if (typeof value === 'string') {
        number = parseFloat(value.replace(/\./g, ''))
      } else if (typeof value === 'number') {
        number = value
      }
      this.$store.commit('project/setUnitPrice', { [type]: number || 'empty' })
    },
    handleSelectUnit (option) {
      this.$store.commit('project/setUnitPrice', { unit: option })
    }
  },
  watch: {
    reset (val) {
      if (val) {
        this.servicePrice = ''
        this.materialPrice = ''
        this.unitPrice = ''
      }
    }
  }
}
</script>

<style scoped>
.input-wrapper-grid {
  display: grid;
  column-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}
</style>
