<template>
  <div>
    <ButtonApp
      :isDisabled="adminTools && !adminTools.can_extend"
      :isSecondary="true"
      :isTransparent="true"
      @click="openModal"
    >
      <div>
        <p class="averta-bold py-1">+ Tambah</p>
      </div>
    </ButtonApp>

    <ModalTemplate
      :showModal="modalExtend"
      :width="widthModal"
      @closeModal="closeModal"
    >
      <template v-slot:headerModal>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="flex-center">
            <CalendarIcon color="#fff" />
            <p class="averta-bold label-18 ml-2">Extend - Tambah Durasi Pengerjaan</p>
          </div>
          <div class="is-clickable" @click="closeModal">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div class="bg-white wrap-body-modal">
          <div class="p-4 form-body-modal">
            <p class="averta-bold mb-2">
              Tentukan Durasi Tambahan Untuk Proyek <span class="color-red">*</span>
            </p>
            <div class="is-relative c-w-55">
              <input
                v-model="addDuration"
                type="number"
                class="input averta-bold label-14 input-search-1 bg-white"
                placeholder="0"
                min="1"
              />
              <p class="hari-wrapper color-gray">Hari</p>
            </div>
          </div>
          <div class="footer-modal p-4">
            <div class="mr-5 c-w-100">
              <DurationStartEnd
                titleFrom="Durasi Awal"
                titleEnd="Durasi Saat Ini"
                :from="`${detailProject.card.duration} Hari`"
                :end="`${resDuration} Hari`"
              />
            </div>
            <ButtonApp @click="handleSubmit" :isDisabled="addDuration < 1 || !addDuration">
              <div class="flex-center py-3 px-5">
                <p class="color-white mr-4 averta-bold">Proses</p>
                <ArrowRightIconLong />
              </div>
            </ButtonApp>
          </div>
        </div>
      </template>
    </ModalTemplate>

    <!-- Modal Confirm Extend -->
    <ConfirmExtendReschedule
      typeModal="extend"
      :duration="addDuration"
      :showModal="modalConfirm"
      :endDateProject="detailProject && detailProject.card.estimate_finish_project"
      @closeModal="closeModalConfirm"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency } from '@/helpers'
import { ArrowRightIconLong, CalendarIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import DurationStartEnd from '../DurationStartEnd.vue'
import ConfirmExtendReschedule from './ConfirmExtendReschedule.vue'

export default {
  name: 'ExtendProyek',
  components: {
    ArrowRightIconLong,
    CalendarIcon,
    ButtonApp,
    ModalTemplate,
    DurationStartEnd,
    ConfirmExtendReschedule
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      adminTools: 'projectV2/getAdminTools',
      detailProject: 'projectV2/getDataTimelineProject'
    }),
    resDuration () {
      return formatCurrency(this.detailProject.card.duration + Number(this.addDuration), true)
    }
  },
  data () {
    return {
      widthModal: '600px',
      modalExtend: false,
      modalConfirm: false,
      addDuration: null
    }
  },
  methods: {
    formatThousand (val) {
      return formatCurrency(val, true)
    },
    openModal () {
      if (this.adminTools && this.adminTools.can_extend) {
        this.modalExtend = !this.modalExtend
      }
    },
    closeModal () {
      this.modalExtend = false
      this.addDuration = null
    },
    handleSubmit () {
      this.modalConfirm = !this.modalConfirm
    },
    closeModalConfirm (props) {
      this.modalConfirm = false

      if (props && props.type === 'close-all') {
        this.closeModal()
      }
    }
  }
}
</script>

<style scoped>
.form-body-modal {
  min-height: 200px;
}

.wrap-body-modal {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.footer-modal {
  box-shadow: 0px -3px 30px 0px #00000012;
  display: flex;
  align-items: center;
}

.hari-wrapper {
  position: absolute;
  right: 10px;
  top: 8px;
}
</style>
