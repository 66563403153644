<template>
  <div class="mt-6">
    <title-bar :title-stack="titleStack"/>
    <section class="mt-3 p-5">
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item" v-if="!roleAccess('ADMIN')">
            <button :disabled="menuAccess('/project/input')" @click="btnTambahProyekTukangLangsungOnClick()" class="button is-small is-link is-outlined">
              <span class="icon is-small">
                <b-icon icon="plus" />
              </span>
              <span>Pesan Tukang Langsung</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- TABS MENU -->
    <div class="columns mt-5">
      <div class="column">
        <div class="tabs is-centered is-boxed">
          <ul>
            <li :class="{'is-active' : activeTab === 3}">
              <a @click="selectTab(3)">
                <span>Mencari Mitra</span>
              </a>
            </li>
            <li :class="{'is-active' : activeTab === 1}">
              <a @click="selectTab(1)">
                <span>Dalam Rencana</span>
              </a>
            </li>
            <li :class="{'is-active' : activeTab === 0}">
              <a @click="selectTab(0)">
                <span>Proyek Aktif</span>
              </a>
            </li>
            <li :class="{'is-active' : activeTab === 2}">
              <a @click="selectTab(2)">
                <span>Arsip</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /TABS MENU -->

    <div v-show="activeTab===3" class="mt-3">
      <card-component class="has-table has-mobile-sort-spaced">
        <project-table-mitra/>
      </card-component>
    </div>
    <div v-show="activeTab===0" class="mt-3">
      <card-component class="has-table has-mobile-sort-spaced">
        <project-table-active/>
      </card-component>
    </div>
    <div v-show="activeTab===1">
      <card-component class="has-table has-mobile-sort-spaced">
        <project-table-plan/>
      </card-component>
    </div>
    <div v-show="activeTab===2">
      <card-component class="has-table has-mobile-sort-spaced">
        <project-table-arsip/>
      </card-component>
    </div>
    <!-- <div v-show="activeTab===3">
      <belum-bayar />
    </div> -->
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import guard from '../../routerGuard'
import TitleBar from '@/components/TitleBar'
// import BelumBayar from './BelumBayar.vue'
import CardComponent from '@/components/CardComponent'
import ProjectTableActive from '../../components/ProjectTableActive.vue'
import ProjectTableMitra from '../../components/ProjectTableMitra.vue'
import ProjectTablePlan from '../../components/ProjectTablePlan.vue'
import ProjectTableArsip from '../../components/ProjectTableArsip.vue'

export default {
  name: 'ProjectList',
  components: {
    TitleBar,
    CardComponent,
    ProjectTableActive,
    ProjectTableMitra,
    ProjectTablePlan,
    ProjectTableArsip
    // BelumBayar
  },
  watch: {
    search (newVal) {
      if (newVal === null || newVal === '') {
        this.searchDataProyekAktif = []
        this.searchDataDalamRencana = []
        this.searchDataArsip = []
      }
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath'
    }),
    titleStack () {
      return [
        // 'Admin',
        'Proyek'
      ]
    }
  },
  methods: {
    roleAccess (role) {
      if (this.roles === null) { return true }

      let roleCheck = false
      if (this.roles !== role) {
        roleCheck = true
      }
      return roleCheck
    },
    menuAccess (path) {
      if (this.roles === null) {
        return true
      }
      let check = true
      this.rolePath.forEach((row) => {
        if (row.controller_path === path) {
          check = false
        }
      })
      return check
    },
    selectTab (val) {
      this.activeTab = val
    },
    searchOnClick () {
      if (this.search === null || this.search === '') {
        return
      }
      axios.get(this.url + '/api/v1/vendor/project_search?search=' + this.search)
        .then((cb) => {
          if (cb.data.data.length === 0) {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Tidak ada data yang ditemukan!...',
              position: 'is-bottom',
              type: 'is-danger'
            })
            return
          }
          if (this.activeTab === 0) {
            this.searchDataProyekAktif = cb.data.data
          }
          if (this.activeTab === 1) {
            this.searchDataDalamRencana = cb.data.data
          }
          if (this.activeTab === 2) {
            this.searchDataArsip = cb.data.data
          }
        })
    },
    btnTambahOnClick () {
      this.$router.push('/project/input')
    },
    btnTambahProyekTukangLangsungOnClick () {
      this.$router.push('/project/input-tukang-langsung')
    },
    onPageChange (v) {
      console.log(v)
    }
  },
  data () {
    return {
      search: null,
      searchDataDalamRencana: [],
      searchDataProyekAktif: [],
      searchDataArsip: [],
      activeTab: 3,
      cabang: [
        { id: 1, name: 'Mitra 10 Aeon Mall' },
        { id: 2, name: 'Mitra 10 Mall Serpong' },
        { id: 3, name: 'Mitra 10 BTC Tangerang Selatan' }
      ]
    }
  },
  created () {
    guard.check(this.$router, this.$router.currentRoute.path)
  }
}
</script>

<style lang="scss" scoped>
.dash-container {
  padding: 20px;
}
.card-kanggo {
  background-color: white;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
  table {
    tr {
      td {
        width: auto;
        border-bottom: solid thin rgb(240, 240, 240);
        height: 45px;
        padding: 10px 15px 5px 15px;
      }
    }
  }
}
.card-paginate {
  display: flex;
  font-size: 14px;
}
.tab-label {
  width: 150px;
  text-align: center;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
  &.active {
    border-bottom: solid thin red;
    background: linear-gradient(to right, #FF0000,#FF1A1A, #FF3333, #ffffff);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-weight: bold;
  }
}
</style>
