<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_4569_106565" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="5" y="5" width="22" height="22">
      <path
        d="M17.3331 25.0527V10.1594L23.8398 16.666C24.3598 17.186 25.2131 17.186 25.7331 16.666C26.2531 16.146 26.2531 15.306 25.7331 14.786L16.9465 5.99936C16.6974 5.74969 16.3592 5.60938 16.0065 5.60938C15.6538 5.60938 15.3156 5.74969 15.0665 5.99936L6.26646 14.7727C5.74646 15.2927 5.74646 16.1327 6.26646 16.6527C6.78646 17.1727 7.62646 17.1727 8.14646 16.6527L14.6665 10.1594V25.0527C14.6665 25.786 15.2665 26.386 15.9998 26.386C16.7331 26.386 17.3331 25.786 17.3331 25.0527Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_4569_106565)">
      <rect width="32" height="32" fill="white" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ArrowUpwardLong',
  props: {
    size: {
      type: String,
      default: '32'
    },
    color: {
      type: String,
      default: '#808080'
    }
  }
}
</script>
