<template>
  <div class="wrapper">
    <div class="p-5 top-bar">
      <img
        src="../../assets/img/logo-kanggo.png"
        alt="logo-kanggo"
        class="logoKanggo"
      />
    </div>
    <div class="columns p-6 mt-5">
      <div class="column is-6 mx-6">
        <p class="averta-bold is-size-5">Halo!</p>
        <p class="mb-5">Silakan masukkan password baru kamu</p>
        <div>
          <div class="mb-5">
            <p class="mb-2">Password<span class="has-text-danger-dark ml-1">*</span></p>
            <b-field>
              <b-input
                  type="password"
                  password-reveal
                  v-model="password"
              >
              </b-input>
            </b-field>
          </div>
          <div class="mb-5">
            <p class="mb-2">Konfirmasi Password<span class="has-text-danger-dark ml-1">*</span></p>
            <b-field>
              <b-input
                  type="password"
                  password-reveal
                  v-model="confirmPassword"
              >
              </b-input>
            </b-field>
          </div>
          <div
            v-if="showNotif"
            class="is-flex notif p-3 mb-5 is-align-items-center"
          >
            <div class="card-attention has-text-centered">
              <span class="averta-bold">!</span>
            </div>
            <p>{{ messageFailedLogin }}</p>
          </div>
          <b-button
            class="btn-forgot-password mb-6"
            @click="resetPassword()"
            >Proses Perubahan</b-button
          >
        </div>
      </div>
      <div class="column has-text-centered">
        <img src="../../assets/img/image-password.png" alt="image-password" />
      </div>
    </div>
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="false"
    >
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import resetPasswordImage from '../../assets/img/reset-password.png'
import { SpinnerIcon } from '../../components/icons'

export default defineComponent({
  name: 'ResetPassword',
  data () {
    return {
      password: null,
      confirmPassword: null,
      showNotif: false,
      messageFailedLogin: '',
      isLoading: false,
      isFullPage: true
    }
  },
  components: {
    SpinnerIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    resetPassword () {
      this.isLoading = true

      if (this.password === null || this.password === '' || this.confirmPassword === null || this.confirmPassword === '') {
        this.isLoading = false
        this.showNotif = true
        this.messageFailedLogin = 'Silakan masukkan password dan konfirmasi password terlebih dahulu'
        return false
      }

      if (this.password !== this.confirmPassword) {
        this.isLoading = false
        this.showNotif = true
        this.messageFailedLogin = 'Password dan konfirmasi password tidak sama'
        return false
      }

      const payload = {
        url: this.url,
        token: this.$route.query.token,
        password: this.password,
        password_confirm: this.confirmPassword
      }

      this.$store.dispatch('auth/resetPassword', payload)
        .then(response => {
          this.isLoading = false
          this.$swal({
            imageUrl: resetPasswordImage,
            imageAlt: 'forgot password image',
            width: '600px',
            padding: '10px 10px 30px 10px',
            title: '<p class="averta-bold label-22">Password berhasil diubah</p>',
            html: '<p>Silakan masuk kembali untuk login ke Shell Dashboard</p>',
            confirmButtonText: 'Login',
            confirmButtonColor: '#EB4600',
            customClass: {
              confirmButton: 'btn-first-login'
            }
          })
            .then(() => {
              this.$router.push('/login')
            })
        })
        .catch(error => {
          this.isLoading = false
          console.log(error.response)
          this.showNotif = true
          this.messageFailedLogin = error.response.data.message[0].message || error.response.data.message
          return false
        })
    }
  },
  mounted () {
    const rs = localStorage.getItem('webapp_shell_kanggo')
    const auth = JSON.parse(rs)

    if (auth && auth.token) {
      this.$store.commit('auth/setLoginStatus', true)
      this.$router.push({ path: '/' })
    }

    if (!this.$route.query.token) {
      this.$buefy.toast.open({
        message: '<p class="averta-regular">Maaf, anda tidak mempunyai akses pada halaman ini</p>',
        type: 'is-warning'
      })
      this.$router.push({ path: '/login' })
    }
  }
})
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 0;
  padding: 0;
  background: #FAFDFF;
  height: 100vh;
  width: 100vw;
}
.top-bar {
  background: white;
}
.logoKanggo {
  width: 12%;
}

.btn-forgot-password{
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 90px;
  font-family: 'Averta-Bold';
  color: white;
  padding: 0px 26px;
  display: flex;
  justify-content: end;
}

.notif {
  background: #FFF1F1;
  border-radius: 20px;
}

.card-attention {
  width: 24px;
  height: 24px;
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 100px;
  color: white;
  margin: 0px 10px 0px 10px;
}
.text-login {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: 'Averta-Bold';
}
</style>
