<template>
  <div class="btn-red-secondary" @click="handleClick">
    <p class="averta-bold">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'ButtonRedSecondary',
  props: {
    title: {
      type: String,
      required: true
    },
    onClick: {
      type: Function
    }
  },
  methods: {
    handleClick () {
      if (this.onClick) {
        this.onClick()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.btn-red-secondary {
  border: 1px solid $color-red-1;
  border-radius: 100px;
  color: $color-red-1;
  padding: 8px 20px 8px 20px;
  display: inline-block;
  cursor: pointer;
}
</style>
