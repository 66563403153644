<template>
  <svg :width="size" :height="size" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1937_41126" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="4" y="7" width="22" height="16">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.42736 10.2137C4.76924 10.2137 4.23077 9.67521 4.23077 9.01709C4.23077 8.35897 4.76924 7.82051 5.42736 7.82051H24.5727C25.2308 7.82051 25.7692 8.35897 25.7692 9.01709C25.7692 9.67521 25.2308 10.2137 24.5727 10.2137H5.42736ZM5.42738 16.1966H24.5727C25.2308 16.1966 25.7693 15.6581 25.7693 15C25.7693 14.3419 25.2308 13.8034 24.5727 13.8034H5.42738C4.76926 13.8034 4.2308 14.3419 4.2308 15C4.2308 15.6581 4.76926 16.1966 5.42738 16.1966ZM5.42738 22.1795H24.5727C25.2308 22.1795 25.7693 21.641 25.7693 20.9829C25.7693 20.3248 25.2308 19.7863 24.5727 19.7863H5.42738C4.76926 19.7863 4.2308 20.3248 4.2308 20.9829C4.2308 21.641 4.76926 22.1795 5.42738 22.1795Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_1937_41126)">
      <rect x="0.641052" y="0.641026" :width="size" :height="size" :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'HamburgerMenu',
  props: {
    size: {
      type: String,
      default: '30'
    },
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>
