<template>
  <ModalTemplate
    :showModal="isModalActive"
    width="840px"
    @closeModal="closeModal"
  >
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <div class="flex-center-vertical">
          <DaftarClientLine color="#fff" size="24" />
          <p class="averta-bold label-18 ml-3">Pilih Salah Satu Untuk Menambahkan</p>
        </div>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>
    <template v-slot:bodyModal>
      <div class="body-add-client">
        <div class="padding-body">
          <p class="mb-5">Silakan pilih salah satu menu untuk penambahan client, PIC Cabang & PIC Toko.</p>

          <div class="is-flex is-justify-content-space-between">
            <div
              v-for="(item, index) in listMenu"
              :key="`menu-${index}`"
              class="menu-wrapper flex-center-vertical is-flex-direction-column input-search-2 border-radius-20 bg-lightgray is-clickable"
              :class="{'active': selectedMenu === item.id}"
              @click="handleSelectMenu(item.id)"
            >
              <div class="menu-img mb-3">
                <img :src="require(`@/assets/img/${item.img}`)" alt="">
              </div>
              <p class="averta-black mb-2">{{item.title}}</p>
              <p style="width: 210px;" class="has-text-centered color-gray" v-html="item.desc"></p>
            </div>
          </div>
        </div>

        <div class="is-flex is-justify-content-flex-end select-wrapper mt-3">
          <div class="flex-center-vertical px-5">
            <ButtonApp height="46px" width="162px" :isDisabled="!selectedMenu" @click="handleConfirmMenu">
              <p class="averta-bold py-1 px-5">Pilih</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { DaftarClientLine } from '@/components/icons'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'ModalAddClient',
  components: {
    DaftarClientLine,
    ModalTemplate,
    ButtonApp
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    surveyId: {
      type: Number
    },
    refreshData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false,
      isLoading: false,
      dataModal: null,
      selectedMenu: null,
      listMenu: [
        {
          id: 'client',
          title: '+ Client Baru',
          desc: 'Menambah client baru',
          img: 'client.png'
        },
        {
          id: 'branch',
          title: '+ Cabang',
          desc: 'Penambahan Cabang & PIC cabang (<span class="averta-bold">Chief</span> di beberapa client) baru dengan PT/Brand yang sudah ada',
          img: 'branch.png'
        },
        {
          id: 'store',
          title: '+ Toko',
          desc: 'Penambahan outlet & PIC outlet (<span class="averta-bold">PIC Koordinator</span> di beberapa client) baru dengan cabang yang sudah ada',
          img: 'store.png'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    handleSelectMenu (id) {
      this.selectedMenu = id
    },
    handleConfirmMenu () {
      this.$emit('selectMenu', { id: this.selectedMenu })
    }
  },
  watch: {
    showModal (value) {
      this.isModalActive = value
      this.selectedMenu = null
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.body-add-client {
  background: white;
  border-radius: 0px 0px 12px 12px;
}

.padding-body {
  padding: 20px 35px;
}

.menu-wrapper {
  width: 235px;
  height: 240px;
  padding: 20px;
  transition: all ease-in-out 0.1s;
}

.menu-wrapper:hover, .menu-wrapper.active {
  border-color: #911A1E !important;
  background: #FFF1F1 !important;
}

.menu-img {
  height: 70px;
}

.menu-img img {
  height: 100%;
  object-fit: contain;
}

.select-wrapper {
  height: 70px;
  box-shadow: 0px -3px 30px 0px #00000012;
}
</style>
