<template>
  <nav
    :class="[
      'navbarTipeOffering',
      isPriceActual ? 'background-color-actual-price' : 'background-color-tenant-price',
      'is-flex',
      'is-flex-direction-row',
      'is-justify-content-flex-start',
      'c-w-100',
      'px-6',
      'mb-4'
    ]"
    :style="styleProps"
  >
    <img src="@/assets/img/ActualPriceIcon.svg" alt="ActualPriceIcon" class="navs-images" />
    <div class="is-flex is-flex-direction-column is-justify-content-center">
      <h5 class="averta-bold">
        {{ isPriceActual ? 'Harga Dasar' : 'Harga Tenant' }}
      </h5>
      <p class="averta-regular">
        Tampilan ini adalah detail penawaran yang disajikan
        {{ isPriceActual ? ' oleh Kanggo untuk Client' : ' Client Kanggo untuk Customer/Tenant' }}
      </p>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'CardPriceTypeOffering',
  props: {
    isPriceActual: {
      type: Boolean,
      default: true
    },
    styleProps: {
      type: String
    }
  }
}
</script>

<style scoped>
.navbarTipeOffering {
  position: fixed;
  height: auto;
  padding: 1rem 0;
  gap: 1rem;
}

.background-color-actual-price {
  background: linear-gradient(90deg, #f8fff7 0%, #ecffea 100%);
}

.background-color-tenant-price {
  background: #e1eeff;
}

.navs-images {
  width: auto;
  height: 40px;
}
</style>
