<template>
  <div class="pt-5 px-0 container is-fluid">
    <div class="is-flex is-justify-content-space-between pt-4 mb-4 px-5">
      <title-bar :title-stack="titleStack" />
      <div v-if="!isLoadingCard" class="is-flex">
        <div class="mr-6">
          <p class="has-text-grey-light">Tanggal Hari ini</p>
          <p class="averta-bold">{{ dateNow }}</p>
        </div>
        <div>
          <b-dropdown aria-role="list" v-model="selectedMonth" class="is-flex is-align-items-center">
            <template #trigger="{ active }">
              <b-button :label="selectedMonth ? selectedMonth.month : monthNow"
                :icon-right="active ? 'menu-up' : 'menu-down'" />
            </template>
            <b-dropdown-item aria-role="listitem" v-for="data in listMonth" :key="data.value" :value="data"
              @click="performaBulanan()">
              {{ data.month }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div v-if="showNotif">
      <NotificationOfferRejected />
    </div>

    <div v-if="isLoadingCard" class="px-5 my-6">
      <CardSkeleton :totalCard="4" />
    </div>
    <CardSummary v-else :summary-data="dataCard" />

    <div v-if="isLoadingCardOffer" class="card-penawaran-wrapper px-5 pb-5 pt-2 mb-6">
      <CardSkeleton :totalCard="3" height="200px" />
    </div>
    <div v-else-if="offerData" class="card-penawaran-wrapper p-5">
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <div>
          <p class="averta-bold is-size-4">Selesaikan Penawaran</p>
          <p>Segera lengkapi penawaran untuk melanjutkan proses</p>
        </div>
        <p class="has-text-danger is-clickable" @click="() => this.$router.push('/purchasing/project')">Lihat Semua</p>
      </div>
      <CardPenawaran :offer-data="offerData" />
    </div>

    <div v-if="isLoadingCalendar" class="px-5 mb-6">
      <CardSkeleton :totalCard="1" height="300px" />
    </div>
    <div v-else class="p-5">
      <div class="is-flex is-justify-content-space-between mb-4">
        <div>
          <p class="label-20 averta-bold">Kalender Proyek</p>
          <p class="label-14">Pantau kesediaan material pada seluruh proyek berjalan</p>
        </div>
      </div>
      <CalendarPurchasing :calendarOptionsMonth="calendarOptionsMonth" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import TitleBar from '@/components/TitleBar'
import CardSkeleton from '@/components/skeleton/CardSkeleton'
import CalendarPurchasing from './../components/calendar/CalendarPurchasing.vue'
import CardSummary from './../components/CardSummary.vue'
import CardPenawaran from '../components/CardOffer.vue'
import NotificationOfferRejected from '../components/NotificationOfferRejected.vue'

export default {
  name: 'DashboardPurchasing',
  components: {
    TitleBar,
    CardSummary,
    CardPenawaran,
    NotificationOfferRejected,
    CardSkeleton,
    CalendarPurchasing
  },
  computed: {
    titleStack () {
      return ['Dashboard']
    },
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      isLoadingCard: false,
      isLoadingCardOffer: false,
      isLoadingCalendar: false,
      dateNow: moment().format('LL'),
      monthNow: moment().format('MMMM YYYY'),
      selectedMonth: null,
      listMonth: [],
      dataCard: null,
      showNotif: false,
      offerData: null,
      status: 'waiting-confirmation',
      sort: 'desc',
      substatus: 'purchasing',
      calendarOptionsMonth: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          end: 'prev today next'
        },
        eventClick: this.projectDetail,
        events: [],
        height: 'auto',
        contentHeight: 'auto',
        width: '100%'
      }
    }
  },
  methods: {
    showAllOffer () {
      this.$router.push({ path: '/purchasing/project' })
    },
    generateMonth () {
      for (let month = 0; month <= 11; month++) {
        const temp = {
          month: moment().month(month).format('MMMM YYYY'),
          value: moment().month(month).format('YYYY-MM')
        }
        this.listMonth.push(temp)
      }
    },
    performaBulanan () {
      const data = {
        start_date: moment(this.selectedMonth.value).startOf('month').format('YYYY-MM-DD'),
        end_date: moment(this.selectedMonth.value).endOf('month').format('YYYY-MM-DD')
      }

      const payload = {
        ...data,
        url: this.url
      }

      this.getDataCard(payload)
      this.getCalendarPurchasing(payload)
    },
    getDataCard (payload) {
      this.isLoadingCard = true
      this.$store
        .dispatch('dashboard/getCardDashboard', payload)
        .then(response => {
          this.isLoadingCard = false
          if (response.data.code === 1) {
            this.dataCard = response.data.data
            this.showNotif = response.data.data.is_reject.new_reject
          }
        })
        .catch(error => {
          this.isLoadingCard = false
          console.log(error)
        })
    },
    getCalendarPurchasing (payload) {
      this.isLoadingCalendar = true
      this.$store
        .dispatch('dashboard/getCalendarPurchasing', payload)
        .then(response => {
          this.isLoadingCalendar = false
          if (response.data.code === 1) {
            this.calendarOptionsMonth.events = response.data.data
          }
        })
        .catch(error => {
          this.isLoadingCalendar = false
          console.log(error)
        })
    },
    getDataCardOffer () {
      this.isLoadingCardOffer = true
      const payload = {
        url: this.url,
        sort: this.sort,
        substatus: this.substatus,
        status: this.status
      }

      this.$store
        .dispatch('project/getListMenungguKonfirmasiPurchasing', payload)
        .then(response => {
          this.isLoadingCardOffer = false
          if (response.data.code === 1) {
            this.offerData = response.data.data.list
          }
        })
        .catch(error => {
          this.isLoadingCardOffer = false
          console.log(error)
        })
    },
    projectDetail: function (info) {
      this.$router.push(`/purchasing/project/${info.event.id}/detail`)
    }
  },
  created () {
    const payload = {
      url: this.url,
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD')
    }
    this.generateMonth()
    this.getDataCardOffer()
    this.getDataCard(payload)
    this.getCalendarPurchasing(payload)
  },
  mounted () {
    document.documentElement.classList.remove('has-aside-left')
    document.documentElement.classList.remove('has-navbar-fixed-top')
  }
}
</script>

<style lang="scss" scoped>
.card-penawaran-wrapper {
  background: #FAFAFA;
}
</style>
