<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6666 4.99996H15.6666L20.0533 0.599964C20.1773 0.474993 20.3247 0.375801 20.4872 0.308109C20.6497 0.240417 20.824 0.205566 21 0.205566C21.176 0.205566 21.3503 0.240417 21.5127 0.308109C21.6752 0.375801 21.8227 0.474993 21.9466 0.599964L25.3866 4.0533C25.635 4.30311 25.7744 4.64105 25.7744 4.9933C25.7744 5.34554 25.635 5.68348 25.3866 5.9333L22.3333 8.99996H11.6666V11.6666C11.6666 12.0203 11.5262 12.3594 11.2761 12.6094C11.0261 12.8595 10.6869 13 10.3333 13C9.97969 13 9.64055 12.8595 9.3905 12.6094C9.14045 12.3594 8.99998 12.0203 8.99998 11.6666V7.66663C8.99998 6.95939 9.28093 6.28111 9.78102 5.78101C10.2811 5.28092 10.9594 4.99996 11.6666 4.99996ZM3.66664 11.6666V17L0.61331 20.0533C0.364975 20.3031 0.225586 20.6411 0.225586 20.9933C0.225586 21.3455 0.364975 21.6835 0.61331 21.9333L4.05331 25.3866C4.17726 25.5116 4.32473 25.6108 4.48721 25.6785C4.64969 25.7462 4.82396 25.781 4.99998 25.781C5.17599 25.781 5.35027 25.7462 5.51275 25.6785C5.67522 25.6108 5.82269 25.5116 5.94664 25.3866L11.6666 19.6666H17C17.3536 19.6666 17.6927 19.5262 17.9428 19.2761C18.1928 19.0261 18.3333 18.6869 18.3333 18.3333V17H19.6666C20.0203 17 20.3594 16.8595 20.6095 16.6094C20.8595 16.3594 21 16.0203 21 15.6666V14.3333H22.3333C22.6869 14.3333 23.0261 14.1928 23.2761 13.9428C23.5262 13.6927 23.6666 13.3536 23.6666 13V11.6666H14.3333V13C14.3333 13.7072 14.0524 14.3855 13.5523 14.8856C13.0522 15.3857 12.3739 15.6666 11.6666 15.6666H8.99998C8.29273 15.6666 7.61446 15.3857 7.11436 14.8856C6.61426 14.3855 6.33331 13.7072 6.33331 13V8.99996L3.66664 11.6666Z"
      fill="url(#paint0_linear_237_85969)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_237_85969"
        x1="0.225586"
        y1="12.7608"
        x2="20.8295"
        y2="22.8545"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="1" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ProyekDashboard'
}
</script>
