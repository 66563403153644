<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.67 1.99902H16.34C19.73 1.99902 22 4.37902 22 7.91902V16.09C22 19.62 19.73 21.999 16.34 21.999H7.67C4.28 21.999 2 19.62 2 16.09V7.91902C2 4.37902 4.28 1.99902 7.67 1.99902ZM15.01 14.999C15.35 14.66 15.35 14.11 15.01 13.77L13.23 11.99L15.01 10.209C15.35 9.87002 15.35 9.31002 15.01 8.97002C14.67 8.62902 14.12 8.62902 13.77 8.97002L12 10.749L10.22 8.97002C9.87 8.62902 9.32 8.62902 8.98 8.97002C8.64 9.31002 8.64 9.87002 8.98 10.209L10.76 11.99L8.98 13.76C8.64 14.11 8.64 14.66 8.98 14.999C9.15 15.169 9.38 15.26 9.6 15.26C9.83 15.26 10.05 15.169 10.22 14.999L12 13.23L13.78 14.999C13.95 15.18 14.17 15.26 14.39 15.26C14.62 15.26 14.84 15.169 15.01 14.999Z"
      fill="url(#paint0_linear_254_16536)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_254_16536"
        x1="2"
        y1="11.8172"
        x2="18.1226"
        y2="19.7238"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="colorGradient" />
        <stop offset="0.9999" :stop-color="color" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CloseSquare',
  props: {
    color: {
      type: String,
      default: '#EB4600'
    },
    colorGradient: {
      type: String,
      default: '#D9272D'
    },
    size: {
      type: String,
      default: '24'
    }
  }
}
</script>
