<template>
  <svg :width="width || '18'" :height="height || '20'" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M5.92574 14.3892H11.3119C11.7178 14.3892 12.0545 14.0492 12.0545 13.6392C12.0545 13.2292 11.7178 12.8992 11.3119 12.8992H5.92574C5.5198 12.8992 5.18317 13.2292 5.18317 13.6392C5.18317 14.0492 5.5198 14.3892 5.92574 14.3892ZM9.27228 7.89921H5.92574C5.5198 7.89921 5.18317 8.23921 5.18317 8.64921C5.18317 9.05921 5.5198 9.38921 5.92574 9.38921H9.27228C9.67822 9.38921 10.0149 9.05921 10.0149 8.64921C10.0149 8.23921 9.67822 7.89921 9.27228 7.89921ZM16.3381 7.02482C16.5708 7.02213 16.8242 7.01921 17.0545 7.01921C17.302 7.01921 17.5 7.21921 17.5 7.46921V15.5092C17.5 17.9892 15.5099 19.9992 13.0545 19.9992H5.17327C2.59901 19.9992 0.5 17.8892 0.5 15.2892V4.50921C0.5 2.02921 2.5 -0.000793457 4.96535 -0.000793457H10.2525C10.5099 -0.000793457 10.7079 0.209207 10.7079 0.459207V3.67921C10.7079 5.50921 12.203 7.00921 14.0149 7.01921C14.4381 7.01921 14.8112 7.02237 15.1377 7.02514C15.3917 7.02729 15.6175 7.02921 15.8168 7.02921C15.9578 7.02921 16.1405 7.0271 16.3381 7.02482ZM16.6111 5.56521C15.7972 5.56821 14.8378 5.56521 14.1477 5.55821C13.0527 5.55821 12.1507 4.64721 12.1507 3.54121V0.905207C12.1507 0.474207 12.6685 0.260207 12.9646 0.571207C13.5004 1.13397 14.2368 1.90755 14.9699 2.67758C15.7009 3.44553 16.4286 4.20995 16.9507 4.75821C17.2398 5.06121 17.0279 5.56421 16.6111 5.56521Z"
      :fill="color ? color: '#3185FC'" />
  </svg>
</template>

<script>
export default {
  name: 'PaperIcon',
  props: {
    color: String,
    width: String,
    height: String
  }
}
</script>
