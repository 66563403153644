<template>
  <div class="is-inline-block">
    <a @click="handleClickArrowBottom" href="#" class="button-qioncaskl">
      <img :src="require('@/assets/icons/used-ms-excel.svg')" alt="" width="27px">
      <ul class="averta-bold label-18">
        <li>Download Material</li>
        <li>Mendownload....</li>
        <li>Download Berhasil</li>
      </ul>
      <div>
        <svg viewBox="0 0 24 24"></svg>
      </div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import gsap from 'gsap'
import { Elastic } from 'gsap/all'

export default {
  name: 'ButtonDownloadMaterial',
  props: {
    onClick: {
      type: Function
    }
  },
  data () {
    return {
      svgPath: {
        y: null,
        smoothing: null
      }
    }
  },
  computed: {
    ...mapGetters({
      dataPenawaran: 'project/getDataDetailPenawaran'
    })
  },
  mounted () {
    this.initialSVGArrow()
  },
  watch: {
    dataPenawaran (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.initialSVGArrow()
        }, 200)
      }
    }
  },
  methods: {
    handleClickArrowBottom (e) {
      e.preventDefault()
      const duration = 2000
      const button = document.querySelector('.button-qioncaskl')
      const svg = document.querySelector('.button-qioncaskl svg')

      const getPath = (update, smoothing, pointsNew) => {
        const points = pointsNew || [
          [4, 12],
          [12, update],
          [20, 12]
        ]
        const d = points.reduce(
          (acc, point, i, a) =>
            i === 0
              ? `M ${point[0]},${point[1]}`
              : `${acc} ${this.getPoint(point, i, a, smoothing)}`,
          ''
        )
        return `<path d="${d}" />`
      }

      if (!button.classList.contains('loading')) {
        button.classList.add('loading')
        gsap.to(this.svgPath, {
          smoothing: 0.3,
          duration: (duration * 0.065) / 1000
        })

        gsap.to(this.svgPath, {
          y: 12,
          duration: (duration * 0.265) / 1000,
          delay: (duration * 0.065) / 1000,
          ease: Elastic.easeOut.config(1.12, 0.4)
        })

        setTimeout(() => {
          svg.innerHTML = getPath(0, 0, [
            [3, 14],
            [8, 19],
            [21, 6]
          ])
          this.onClick()

          setTimeout(() => {
            const svgPath = new Proxy(
              {
                y: null,
                smoothing: null
              },
              {
                set (target, key, value) {
                  target[key] = value
                  if (target.y !== null && target.smoothing !== null) {
                    svg.innerHTML = getPath(target.y, target.smoothing, null)
                  }
                  return true
                },
                get (target, key) {
                  return target[key]
                }
              }
            )

            svgPath.y = 20
            svgPath.smoothing = 0

            this.svgPath = svgPath
            button.classList.remove('loading')
            button.classList.remove('success-state')
          }, duration + 500)
        }, duration / 2)

        setTimeout(() => {
          button.classList.add('success-state')
        }, duration)
      }
    },
    getPoint (point, i, a, smoothing) {
      const cp = (current, previous, next, reverse) => {
        const p = previous || current
        const n = next || current
        const o = {
          length: Math.sqrt(Math.pow(n[0] - p[0], 2) + Math.pow(n[1] - p[1], 2)),
          angle: Math.atan2(n[1] - p[1], n[0] - p[0])
        }
        const angle = o.angle + (reverse ? Math.PI : 0)
        const length = o.length * smoothing
        return [current[0] + Math.cos(angle) * length, current[1] + Math.sin(angle) * length]
      }
      const cps = cp(a[i - 1], a[i - 2], point, false)
      const cpe = cp(point, a[i - 1], a[i + 1], true)
      return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`
    },
    initialSVGArrow () {
      const button = document.querySelector('.button-qioncaskl')
      const svg = document.querySelector('.button-qioncaskl svg')

      const getPath = (update, smoothing, pointsNew) => {
        const points = pointsNew || [
          [4, 12],
          [12, update],
          [20, 12]
        ]
        const d = points.reduce(
          (acc, point, i, a) =>
            i === 0
              ? `M ${point[0]},${point[1]}`
              : `${acc} ${this.getPoint(point, i, a, smoothing)}`,
          ''
        )
        return `<path d="${d}" />`
      }

      const duration = 2000
      const svgPath = new Proxy(
        {
          y: null,
          smoothing: null
        },
        {
          set (target, key, value) {
            target[key] = value
            if (target.y !== null && target.smoothing !== null) {
              svg.innerHTML = getPath(target.y, target.smoothing, null)
            }
            return true
          },
          get (target, key) {
            return target[key]
          }
        }
      )
      button.style.setProperty('--duration', duration)

      svgPath.y = 20
      svgPath.smoothing = 0

      this.svgPath = svgPath
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.button-qioncaskl {
  --text: #fff;
  --arrow: #fff;
  --checkmark: #fff;
  --shadow: rgba(10, 22, 50, 0.24);
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  display: flex;
  overflow: hidden;
  text-decoration: none;
  border-radius: 100px;
  box-shadow: 0 2px 8px -1px var(--shadow);
  transition: transform 0.2s ease, box-shadow 0.2s ease, background 0.2s ease;

  padding-left: 20px;
  background: $color-red-1;

  &.success-state {
    background: $color-green-4;
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 1px 4px -1px var(--shadow);
  }
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 15px;
    list-style: none;
    text-align: center;
    position: relative;
    backface-visibility: hidden;
    line-height: 28px;
    color: var(--text);
    transform: all 0.3s ease;
    li {
      &:not(:first-child) {
        top: 16px;
        left: 0;
        right: 0;
        position: absolute;
      }
      &:nth-child(2) {
        top: 76px;
      }
      &:nth-child(3) {
        top: 136px;
      }
    }
  }
  & > div {
    position: relative;
    width: 60px;
    height: 60px;
    background: $color-dark-red-4; // initial state
    border-radius: 100px;
    overflow: hidden;
    transition: background 0.15s ease;

    &:hover {
      background: $color-dark-red-3;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
    }
    &:before {
      border-radius: 1px;
      width: 2px;
      top: 50%;
      left: 50%;
      height: 17px;
      margin: -9px 0 0 -1px;
      background: var(--arrow);
    }
    &:after {
      width: 60px;
      height: 60px;
      transform-origin: 50% 0;
      border-radius: 0 0 80% 80%;
      background: $color-green-2; // success state
      top: 0;
      left: 0;
      transform: scaleY(0);
    }
    svg {
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      left: 50%;
      top: 50%;
      margin: -7px 0 0 -10px;
      fill: none;
      z-index: 1;
      stroke-width: 2px;
      stroke: var(--arrow);
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
  &.loading {
    ul {
      transform: all 0.3s ease;
      animation: text calc(var(--duration) * 1ms) linear forwards calc(var(--duration) * 0.065ms);
    }
    & > div {
      &:before {
        animation: line calc(var(--duration) * 1ms) linear forwards calc(var(--duration) * 0.065ms);
      }
      &:after {
        animation: background calc(var(--duration) * 1ms) linear forwards
          calc(var(--duration) * 0.065ms);
      }
      svg {
        animation: svg calc(var(--duration) * 1ms) linear forwards calc(var(--duration) * 0.065ms);
      }
    }
  }
}

@keyframes text {
  10%,
  85% {
    transform: translateY(-100%);
  }
  95%,
  100% {
    transform: translateY(-200%);
  }
}

@keyframes line {
  5%,
  10% {
    transform: translateY(-30px);
  }
  40% {
    transform: translateY(-20px);
  }
  65% {
    transform: translateY(0);
  }
  75%,
  100% {
    transform: translateY(30px);
  }
}

@keyframes svg {
  0%,
  20% {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
  }
  21%,
  89% {
    stroke-dasharray: 26px;
    stroke-dashoffset: 26px;
    stroke-width: 3px;
    margin: -10px 0 0 -10px;
    stroke: var(--checkmark);
  }
  100% {
    stroke-dasharray: 26px;
    stroke-dashoffset: 0;
    margin: -10px 0 0 -10px;
    stroke: var(--checkmark);
  }
  12% {
    opacity: 1;
  }
  20%,
  89% {
    opacity: 0;
  }
  90%,
  100% {
    opacity: 1;
  }
}

@keyframes background {
  10% {
    transform: scaleY(0);
  }
  40% {
    transform: scaleY(0.15);
  }
  65% {
    transform: scaleY(0.5);
    border-radius: 0 0 50% 50%;
  }
  75% {
    border-radius: 0 0 50% 50%;
  }
  90%,
  100% {
    border-radius: 0;
  }
  75%,
  100% {
    transform: scaleY(1);
  }
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;
  &:before,
  &:after {
    box-sizing: inherit;
  }
}
</style>
