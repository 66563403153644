<template>
  <svg
    :width="width || '19'"
    :height="height || '18'"
    viewBox="0 0 19 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 18L11.25 15L12.41 13.84L14 15.43L17.59 11.84L18.75 13.25M9.8 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 0.89 0.89 0 2 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V9.8C17.12 9.29 16.09 9 15 9L14 9.08V8H4V10H11.69C10.8626 10.5454 10.1836 11.2879 9.71412 12.1606C9.2446 13.0333 8.99921 14.009 9 15C9 16.09 9.29 17.12 9.8 18ZM9 12H4V14H9M14 4H4V6H14"

    />
  </svg>
</template>

<script>
export default {
  name: 'LaporanIcon',
  props: ['height', 'width']
}
</script>
