<template>
  <div class="border-radius-20 border-1 border-1-grey-2 p-4 mb-6">
    <p class="averta-bold mb-4 label-18">Atur Jadwal Pengerjaan</p>

    <div class="is-flex">
      <div class="border-radius-20 py-3 px-4 bg-lightgray flex-center-vertical is-justify-content-space-between flex-1 mr-3">
        <div class="flex-center">
          <CalendarIcon color="#3185FC" />
          <p class="averta-bold ml-2">Extend Proyek - Tambah Durasi Pengerjaan</p>
        </div>
        <ExtendProyek @handleConfirm="handleSubmitExtend($event)" />
      </div>
      <div class="border-radius-20 p-3 bg-lightgray flex-center-vertical is-justify-content-space-between flex-1">
        <div class="flex-center">
          <ClockCircle color="#3185FC" />
          <p class="averta-bold ml-2">Reschedule - Pilih Tanggal Pengerjaan</p>
        </div>
        <RescheduleProyek @handleConfirm="handleSubmitReschedule($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  CalendarIcon,
  ClockCircle
} from '@/components/icons'

import ExtendProyek from './modal/ExtendProyek.vue'
import RescheduleProyek from './modal/RescheduleProyek.vue'

export default {
  name: 'AdminTools',
  components: {
    CalendarIcon,
    ClockCircle,

    ExtendProyek,
    RescheduleProyek
  },
  data () {
    return {
      widthModal: '600px'
    }
  }
}
</script>

<style scoped>

</style>
