<template>
  <svg :width="size" :height="size" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="0.5" width="27" height="27" rx="13.5" :fill="color" />
    <rect x="1" y="0.5" width="27" height="27" rx="13.5" :stroke="color" />
    <mask
      id="mask0_7130_213788"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="7"
      y="7"
      width="15"
      height="14"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.26988 13.9984C9.26988 10.6318 12.0499 7.90509 15.4432 7.99842C18.5699 8.08509 21.1832 10.6984 21.2699 13.8251C21.3632 17.2184 18.6365 19.9984 15.2699 19.9984C13.8765 19.9984 12.6032 19.5251 11.5899 18.7251C11.2765 18.4851 11.2565 18.0118 11.5365 17.7318C11.7765 17.4918 12.1499 17.4718 12.4165 17.6784C13.2032 18.2984 14.1965 18.6651 15.2699 18.6651C17.8699 18.6651 19.9699 16.5384 19.9365 13.9318C19.9032 11.4518 17.8165 9.36509 15.3365 9.33175C12.7232 9.29842 10.6032 11.3984 10.6032 13.9984H11.7965C12.0965 13.9984 12.2432 14.3584 12.0365 14.5651L10.1765 16.4318C10.0432 16.5651 9.83654 16.5651 9.70321 16.4318L7.84321 14.5651C7.62988 14.3584 7.77654 13.9984 8.07654 13.9984H9.26988ZM14.6042 11.8327C14.6042 11.5593 14.8308 11.3327 15.1042 11.3327C15.3775 11.3327 15.6042 11.5593 15.6042 11.826V14.0927L17.5242 15.2327C17.7575 15.3727 17.8375 15.6793 17.6975 15.9193C17.5575 16.1527 17.2508 16.2327 17.0108 16.0927L14.9308 14.8593C14.7308 14.7393 14.6042 14.5193 14.6042 14.286V11.8327Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_7130_213788)">
      <rect x="6.5" y="6" width="16" height="16" fill="white" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'HistoryIcon',
  props: {
    color: {
      type: String,
      default: '#E10009'
    },
    size: {
      type: String,
      default: '29'
    }
  }
}
</script>
