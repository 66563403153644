<template>
    <ModalTemplate :showModal="isModalActive" :width="widthModal" @closeModal="closeModal">
        <template v-slot:headerModal>
            <div class="is-flex is-justify-content-space-between is-align-items-center">
                <div class="is-flex is-justify-content-space-between is-align-items-center">
                    <CheckIcon class="mr-2" />
                    <p class="averta-bold label-18" v-if="modeEdit">Atur ID Proyek Darurat</p>
                    <p class="averta-bold label-18" v-else>Daftar ID Proyek Darurat</p>
                </div>
                <div class="is-clickable" @click="closeModal">
                  <b-icon icon="close"></b-icon>
                </div>
            </div>
        </template>
        <template v-slot:bodyModal>
            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
                <SpinnerIcon />
            </b-loading>
            <div class="wrap-body-modal p-5">
                <div class="wrap-tambah mb-4" v-if="modeEdit">
                    <p class="mb-3">ID Proyek Darurat</p>
                    <div class="is-flex is-justify-content-space-between is-align-items-center">
                      <div class="input-search-2 is-flex is-align-items-center c-w-100 mr-3">
                        <p class="averta-bold px-3 border-right-2">ID</p>
                        <b-input v-model="idProyekDarurat" type="number" placeholder="Masukkan ID Proyek Darurat" class="w-100 mr-4 hide-arrow-input-number input-none"></b-input>
                      </div>
                        <ButtonAppVue :isSecondary="true" :isDisabled="!idProyekDarurat" :isBold="true" @click="addUrgentId">
                          <p class="py-2">Tambah</p>
                        </ButtonAppVue>
                    </div>
                </div>
                <p class="averta-bold label-14 mb-3">Daftar ID Proyek Darurat</p>
                <div :class="{'wrap-list-edit':modeEdit, 'wrap-list': !modeEdit, 'is-flex is-justify-content-center': dataUrgentId.length < 1}">
                    <div v-if="dataUrgentId.length > 0" class="wrap-list-card">
                        <div v-for="item, index in dataUrgentId" :key="`project-urgent-${index}`" class="card mb-3 py-2 px-4 is-flex is-justify-content-space-between is-align-items-center overflow-auto">
                            <p class="label-14 white-space-nowrap">&#x2022; ID{{item}}</p>
                            <div class="is-clickable" v-if="modeEdit" @click="deleteUrgentId(index)">
                                <TrashIcon />
                            </div>
                        </div>
                    </div>
                    <div v-else class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                        <ProyekEmptyIcon width="140" height="110" class="mb-3" />
                        <p class="averta-bold">Tidak Ada ID Proyek Darurat</p>
                    </div>
                </div>
                <div class="floating-button active is-flex is-justify-content-end is-align-items-center" v-if="isBS">
                  <ButtonAppVue :isBold="true" @click="saveData" v-if="modeEdit" :isDisabled="isInitialEmptyDataUrgent">
                    <p class="py-2 px-3">Simpan</p>
                  </ButtonAppVue>
                  <ButtonAppVue :isBold="true" @click="editMode" v-else>
                    <p class="py-2">Edit ID Proyek Darurat</p>
                  </ButtonAppVue>
                </div>
            </div>

        </template>
    </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalTemplate from './ModalTemplate.vue'
import { ProyekEmptyIcon, SpinnerIcon, CheckIcon, TrashIcon } from '@/components/icons'
import saveImage from '@/assets/img/delete-area-component.png'
import { alertErrorMessage } from '@/helpers'
import ButtonAppVue from '@/components/button/ButtonApp.vue'

export default {
  name: 'ModalProyekDarurat',
  components: { ModalTemplate, ProyekEmptyIcon, SpinnerIcon, CheckIcon, TrashIcon, ButtonAppVue },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '75vw'
    },
    dataModalUrgent: {
      type: Array,
      default: () => []
    },
    surveyId: {
      type: Number
    },
    loadData: {
      type: Function
    }
  },
  data () {
    return {
      isModalActive: false,
      idProyekDarurat: null,
      isLoading: false,
      widthModal: this.width,
      modeEdit: false,
      dataUrgentId: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    }),
    isBS () {
      if (this.user.roles[0].name === 'Account Manager' || this.user.roles[0].name === 'Business Support') {
        return true
      }
      return false
    },
    isInitialEmptyDataUrgent () {
      if ((this.dataModalUrgent && this.dataModalUrgent.length < 1) && (this.dataUrgentId && this.dataUrgentId.length < 1)) return true
      return false
    }
  },
  methods: {
    saveData () {
      if (this.isInitialEmptyDataUrgent) {
        return
      }

      this.$swal({
        imageUrl: saveImage,
        imageAlt: 'save data',
        width: '540px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Simpan Perubahan ID Proyek Darurat</p>',
        html: '<p>Pastikan kamu sudah cek kembali seluruh informasi telah benar dan sesuai</p>',
        showCancelButton: true,
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Cek Kembali',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            data: {
              survey_id: this.surveyId,
              project_urgent_id: this.dataUrgentId
            }
          }
          this.$store.dispatch('project/postProjectUrgentId', payload).then(() => {
            this.$buefy.toast.open({
              message: '<p class="averta-regular">Berhasil simpan data</p>',
              type: 'is-success'
            })
            this.closeModal()
            this.loadData()
          }).catch((error) => {
            alertErrorMessage(error)
          })
        }
      })
    },
    editMode () {
      this.modeEdit = true
    },
    closeModal () {
      this.$emit('closeModal')
      this.idProyekDarurat = null
      this.modeEdit = false
    },
    addUrgentId () {
      if (!this.idProyekDarurat) {
        return
      }
      const convertedValue = Number(this.idProyekDarurat)

      if (!isNaN(convertedValue)) {
        const includesDataModalString = this.dataUrgentId.includes(this.idProyekDarurat)
        const includesDataModalNumber = this.dataUrgentId.includes(Number(this.idProyekDarurat))

        if (includesDataModalString || includesDataModalNumber) {
          alertErrorMessage('ID Proyek telah terdaftar')
        } else {
          this.dataUrgentId.push(this.idProyekDarurat)
          this.idProyekDarurat = null
        }
      } else {
        alertErrorMessage('ID Proyek harus angka')
      }
    },
    deleteUrgentId (index) {
      this.dataUrgentId.splice(index, 1)
    },
    loadDataModal () {
      this.dataUrgentId = []
      this.modeEdit = false

      if (this.dataModalUrgent && this.dataModalUrgent.length > 0) {
        this.dataUrgentId = [...this.dataModalUrgent]
      } else {
        this.editMode()
      }
    }
  },
  mounted () {
    this.loadDataModal()
  },
  watch: {
    showModal (newValue) {
      this.isModalActive = newValue
      if (newValue) {
        this.loadDataModal()
      }
    },
    width (newValue) {
      this.widthModal = newValue
    }
  }
}
</script>

<style scoped>
.w-100 {
    width: 100%;
}

.wrap-body-modal {
    height: 70vh;
    overflow-y: auto;
    position: relative;
}

.wrap-tambah {
    padding-bottom: 24px;
    border-bottom: 2px dashed #e1e1e1;
}

.wrap-list {
    overflow-y: auto;
    height: 45vh;
}

.wrap-list-edit {
    overflow-y: auto;
    height: 30vh;
}

.floating-button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 12px;
    border-radius: 0px 0px 12px 12px;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
    z-index: 1;
    background: white;
}
</style>

<style>
.custom-modal-body-class {
  border-radius: 20px !important;
}
</style>
