<template>
  <div>
    <div class="columns">
      <div class="column is-justify-content-center">
        <div class="columns is-mobile">
          <div class="column">
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field is-expanded">
                  <div class="field has-addons">
                    <p class="control">
                      <a class="button is-static"> +62 </a>
                    </p>
                    <p class="control is-expanded">
                      <input class="input" type="tel" expanded v-model="handphone" placeholder="Masukan nomor telepon">
                    </p>
                    <p class="control">
                      <b-button class="is-info" @click="searchByPhone()"> Cari Nomor </b-button>
                    </p>
                  </div>
                  <p class="help">Do not enter the first zero</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-content">
            <div v-if="isNew && !isFirst">
              <div style="margin-bottom: 10px;">
                Tidak ditemukan PIC dengan nomor tercantum!
              </div>
              <b-field label="Buat akun baru?">
                <b-input placeholder="Masukan nama PIC baru" v-model="name"></b-input>
              </b-field>
              <div class="has-text-right">
                <b-button @click="daftarAkun()" style="width: 200px;" type="is-success">Daftar Akun</b-button>
              </div>
            </div>
            <div v-if="!isNew">
              <div class="mb-2">
                PIC dengan nomor tercantum ditemukan!
              </div>
              <h1 class="title is-5"> {{ customer.name }} </h1>
              <div class="has-text-right">
                <b-button @click="pilihCustomer()" style="width: 200px;" type="is-success">Pilih</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectInputCustomer',
  components: {},
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      name: null,
      handphone: null,
      handphoneSearch: null,
      isNew: true,
      customer: null,
      isFirst: true,
      sliceNol: ''
    }
  },
  methods: {
    lanjutkan () {
      this.$emit('nextonclick')
    },
    daftarAkun () {
      if (this.name === null) {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Nama Tidak Boleh Kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      axios.post(`${this.url}/api/v1/vendor/customer_add`, {
        phone: this.handphone,
        name: this.name
      })
        .then((cb) => {
          // this.$store.commit('project/setCustomerId', cb.data.data.id)
          // this.$store.commit('penawaran/setIdCustomer', cb.data.data.id)
          this.customer = {
            id: cb.data.data.id,
            name: this.name,
            cell_phone: this.handphone
          }
          this.isNew = false
        })
        .catch((res) => {
          this.$buefy.toast.open({
            duration: 3000,
            message: res.response.data.message,
            position: 'is-bottom',
            type: 'is-danger'
          })
          return false
        })
    },
    searchByPhone () {
      if (this.handphone === null) {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'nomor tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      const charPhone = this.handphone
      const splitPhone = charPhone.split('')

      if (splitPhone[0] === '0') {
        this.sliceNol = parseFloat(this.handphone.slice(1, 15))
      } else {
        this.sliceNol = this.handphone
      }

      axios.get(`${this.url}/api/v1/vendor/customer_find?cell_phone=${this.sliceNol}`)
        .then((cb) => {
          if (cb.data.data === null) {
            this.customer = null
            this.isNew = true
            this.isFirst = false
          } else {
            this.customer = cb.data.data
            this.isNew = false
          }
        })
        .catch(() => {
          this.customer = null
          this.isNew = true
        })
    },
    pilihCustomer () {
      if (!this.customer) {
        return
      }
      this.$emit('catchvalue', this.customer)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
