const state = () => ({
  selectedTabList: null,
  selectedSubTab: [],
  persistDataList: {
    activeTab: 0,
    scrollPosition: 0,
    detailPage: false,
    data: []
  },
  persistTabProject: null // store data from API
})

const getters = {
  getSelectedTabList: (state) => {
    return state.selectedTabList
  },
  getSelectedSubTab: (state) => {
    return state.selectedSubTab
  },
  getPersistDataList: (state) => {
    return state.persistDataList
  },
  getPersistTabProject: (state) => {
    return state.persistTabProject
  }
}

const actions = {}

const mutations = {
  setSelectedTabList (state, selected) {
    state.selectedTabList = selected
  },
  setSelectedSubTab (state, selected) {
    const foundObject = state.selectedSubTab.find(obj => obj.tab === selected.tab)
    const foundIndex = state.selectedSubTab.findIndex(obj => obj.tab === selected.tab)
    if (foundObject) {
      state.selectedSubTab[foundIndex] = selected
    } else {
      state.selectedSubTab.push(selected)
    }
  },
  resetSelectedTab (state) {
    state.selectedTabList = null
    state.selectedSubTab = []
  },
  setPersistTabProject (state, value) {
    state.persistTabProject = { ...state.persistTabProject, ...value }
  },
  resetPersistTabProject (state, value) {
    state.persistTabProject = null
  },
  setPersistDataList (state, value) {
    if (value) {
      if (value.activeTab) {
        state.persistDataList.activeTab = value.activeTab
      }
      if (value.scrollPosition || value.scrollPosition >= 0) {
        state.persistDataList.scrollPosition = value.scrollPosition
      }
      if (value.detailPage) { // prevent reload API on InputSearchGlobal | true = not reload
        state.persistDataList.detailPage = value.detailPage
      }
      if (value.surveyId) {
        state.persistDataList.surveyId = value.surveyId
      }
      if (value.data) {
        const initData = state.persistDataList.data
        const storeData = value.data

        const index = initData.findIndex(item => item.currentTab === storeData.currentTab)
        if (index !== -1) {
          initData[index] = storeData
        } else {
          initData.push(storeData)
        }
      }
    }
  },
  resetPersistDataList (state, value) {
    state.persistDataList = {
      activeTab: 0,
      scrollPosition: 0,
      detailPage: false,
      surveyId: false,
      data: []
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
