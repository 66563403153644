<template>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.0575 8.86159C4.00917 8.81451 3.8025 8.63672 3.6325 8.47111C2.56333 7.50017 0.813333 4.96729 0.279167 3.64159C0.193333 3.44026 0.0116667 2.93125 0 2.65929C0 2.39869 0.06 2.15027 0.181667 1.91322C0.351667 1.61772 0.619167 1.38067 0.935 1.25078C1.15417 1.16716 1.81 1.03727 1.82167 1.03727C2.53917 0.907378 3.705 0.835938 4.99333 0.835938C6.22083 0.835938 7.33917 0.907378 8.0675 1.01373C8.07917 1.0259 8.89417 1.15579 9.17333 1.29786C9.68333 1.55846 10 2.06747 10 2.6122V2.65929C9.9875 3.01405 9.67083 3.76011 9.65917 3.76011C9.12417 5.01438 7.46 7.4888 6.35417 8.48328C6.35417 8.48328 6.07 8.76336 5.8925 8.88513C5.6375 9.0751 5.32167 9.16927 5.00583 9.16927C4.65333 9.16927 4.325 9.06292 4.0575 8.86159Z"
            fill="#252525" />
    </svg>
</template>

<script>
export default {
  name: 'ArrowDropdown'
}
</script>
