<template>
  <SidebarClientTemplate
    :show="showSidebar"
    :disabledSubmit="isEmptyForm"
    @closeSidebar="handleCloseSidebar"
    @submit="handleSubmit($event)"
  >
    <template v-slot:bodySidebar>
      <b-loading :is-full-page="true" v-model="isLoadingSubmit" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
      <div>
        <p class="label-20 averta-black mb-5">{{typeSidebar === 'add-store' ? 'Silakan pilih sebelum isi form' : 'Edit Toko'}}</p>

        <div
          v-if="showTabButton"
          class="is-flex is-justify-content-space-between is-align-items-center mb-4"
        >
          <button
            class="button-tab averta-bold label-16"
            :class="{ active: currentTab === 'store' }"
            @click="changeTab"
          >
            Tambah {{renderTitleStore}}
          </button>
          <button
            class="button-tab averta-bold label-16"
            :class="{ active: currentTab === 'pic' }"
            @click="changeTab"
          >
            Tambah {{renderTitlePICStore}}
          </button>
        </div>

        <div v-if="typeSection === 'information'" class="flex-center-vertical">
          <ClientAndBranchInfo :detailBranch="getDetailBranch" :detailClient="getDetailClient" />
        </div>
        <div v-else>
          <ClientInfo currentTab="pic" class="mb-5" />
        </div>

        <div v-if="currentTab === 'store'">
          <Store :hideBranchAutoComplete="typeSection === 'information'" />
        </div>
        <div v-else-if="currentTab === 'pic'">
          <Store :hideBranchAutoComplete="typeSection === 'information'" typeContent="add-pic" />
        </div>
      </div>

      <!-- Change Tab Active -->
      <ModalConfirm
        :showModal="showModalChangeTab"
        imageProps="sunset.png"
        imageClass="mb-3"
        :titleProps="MODAL_TITLE_CHANGE_TAB"
        :descProps="MODAL_DESC_CHANGE_TAB"
      >
        <template v-slot:button>
          <div class="is-flex">
            <ButtonApp
              @click="handleCloseChangeTab"
              class="mr-3"
              :isSecondary="true"
              width="185px"
              height="52px"
            >
              <p class="averta-bold">Batalkan</p>
            </ButtonApp>
            <ButtonApp
              @click="handleConfirmChangeTab"
              width="185px"
              height="52px"
            >
              <p class="averta-bold">Pindah Halaman</p>
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>

      <!-- Modal Submit Registration -->
      <ModalConfirm
        :showModal="showModalConfirm"
        :imageProps="MODAL_IMAGE_CONFIRM"
        imageClass="mb-3"
        titleProps="Semua Data Yang Dimasukkan Sudah Benar?"
        :descProps="MODAL_DESC_CONFIRM"
      >
        <template v-if="listTextModalConfirm.length > 0" v-slot:additional>
          <ul class="additional-modal-confirm mb-5">
            <li class="has-text-left" v-for="item, index in listTextModalConfirm" :key="index">
              <p class="is-inline averta-bold">{{item}}</p>
            </li>
          </ul>
        </template>
        <template v-slot:button>
          <div class="is-flex">
            <ButtonApp
              width="185px"
              height="52px"
              @click="handleCloseConfirm"
              class="mr-3"
              :isSecondary="true"
            >
              <p class="averta-bold">Cek Lagi</p>
            </ButtonApp>
            <ButtonApp
              width="185px"
              height="52px"
              @click="handleConfirmCreate"
            >
              <p class="averta-bold">{{renderTextConfirm}}</p>
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>

      <!-- Modal Cancel Registration -->
      <ModalConfirm
        :showModal="showModalCancel"
        imageProps="sunset.png"
        imageClass="mb-0"
        :titleProps="`Batalkan ${typeSidebar === 'add-store' ? 'Pendaftaran' : 'Perubahan'}?`"
        descProps="Jika data sudah diisi maka akan dihapus oleh sistem"
      >
        <template v-slot:button>
          <div class="is-flex">
            <ButtonApp
              width="185px"
              height="52px"
              @click="handleCloseCancel"
              class="mr-3"
              :isSecondary="true"
            >
              <p class="averta-bold">Cek Lagi</p>
            </ButtonApp>
            <ButtonApp
              width="185px"
              height="52px"
              @click="closeSidebar"
            >
              <p class="averta-bold">Batalkan {{typeSidebar === 'edit-store' ? 'Perubahan' : ''}}</p>
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
    </template>
  </SidebarClientTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage, isObjectsSimilar, titleStore, titlePICStore } from '@/helpers'
import { SpinnerIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalConfirm from './ModalConfirm.vue'
import SidebarClientTemplate from '@/components/sidebar/SidebarClientTemplate.vue'
import Store from './store-components/Store.vue'
import ClientAndBranchInfo from './store-components/ClientAndBranchInfo.vue'
import ClientInfo from './branch-components/ClientInfo.vue'

export default {
  name: 'SidebarStore',
  components: {
    SpinnerIcon,
    ButtonApp,
    ModalConfirm,
    SidebarClientTemplate,
    Store,
    ClientAndBranchInfo,
    ClientInfo
  },
  props: {
    show: {
      type: Boolean
    },
    close: {
      type: Function
    },
    showTabButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showSidebar: false,
      showModalConfirm: false,
      showModalCancel: false,
      showModalChangeTab: false,

      isLoadingSubmit: false,
      isEmptyForm: true,
      typeSection: '',
      currentTab: 'store',
      initialForm: null,
      listTextModalConfirm: [],

      MODAL_IMAGE_CONFIRM: '',
      MODAL_DESC_CONFIRM: '',

      MODAL_TITLE_CHANGE_TAB: '',
      MODAL_DESC_CHANGE_TAB: ''
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      getDetailClient: 'client/getDetailClient',
      getDetailBranch: 'client/getDetailBranch',
      getPayloadStore: 'client/getPayloadStore',
      payloadAddPICBranch: 'client/getPayloadAddPicBranch',
      typeSidebar: 'client/getTypeSidebarStore'
    }),
    renderTextConfirm () {
      if (this.typeSidebar === 'edit-store') {
        return 'Simpan Perubahan'
      } else if (this.currentTab === 'store') {
        return `Buat ${titleStore(this.SMRCode)}`
      } else if (this.currentTab === 'pic') {
        return `Buat ${titlePICStore(this.SMRCode)}`
      }
      return 'Simpan Perubahan'
    },
    SMRCode () {
      return this.getDetailClient && this.getDetailClient.code
    },
    renderTitlePICStore () {
      return titlePICStore(this.SMRCode)
    },
    renderTitleStore () {
      return titleStore(this.SMRCode)
    }
  },
  methods: {
    resetState () {
      this.showSidebar = false
      this.showModalConfirm = false
      this.showModalChangeTab = false
      this.showModalCancel = false
      this.isLoadingSubmit = false
      this.isEmptyForm = false
      this.currentTab = 'store'
      this.$store.commit('client/setClearDataPayloadPicBranch', true)
      this.$store.commit('client/setClearPayloadStore')
    },
    closeSidebar () {
      this.resetState()
      this.close()

      // Reset Form Store & PIC
      setTimeout(() => {
        this.handleConfirmChangeTab()
        setTimeout(() => {
          this.currentTab = 'store'
        }, 50)
      }, 200)

      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]
      if (lastElement === 'vendors') {
        this.clearClientBranchSelected()
      }
    },
    changeTab () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]
      if (lastElement === 'vendors') {
        if (Object.keys(this.payloadAddPICBranch.clientSelected).length > 0) {
          this.showModalChangeTab = true
        } else {
          this.handleConfirmChangeTab()
        }
      } else {
        const { formStore, formPIC } = this.getPayloadStore

        if (this.typeSidebar === 'add-store') {
          const alreadyFilledStore = Object.values(formStore).some(value => value)
          const alreadyFilledPIC = formPIC.some(object => Object.values(object).some(value => value))

          let alreadySelectStore = false
          if (this.currentTab === 'pic') {
            alreadySelectStore = Object.keys(this.getPayloadStore.selectedStore).length > 0
          }

          if (alreadyFilledStore || alreadyFilledPIC || alreadySelectStore) {
            this.showModalChangeTab = true
          } else {
            this.handleConfirmChangeTab()
          }
        } else {
          const isSimilar = this.checkIsFormStoreSimilar()
          if (isSimilar) {
            this.handleConfirmChangeTab()
          } else {
            this.showModalChangeTab = true
          }
        }
      }
    },
    handleSubmit () {
      this.showModalConfirm = !this.showModalConfirm
    },
    handleCloseChangeTab () {
      this.showModalChangeTab = false
    },
    handleConfirmChangeTab () {
      this.isEmptyForm = true
      this.showModalChangeTab = false
      if (this.currentTab === 'store') {
        this.currentTab = 'pic'
      } else if (this.currentTab === 'pic') {
        this.currentTab = 'store'
      }

      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]
      if (lastElement !== 'store' && lastElement !== 'store-detail') {
        this.clearClientBranchSelected()
      } else {
        this.$store.commit('client/setClearPayloadStore')
      }
    },
    handleCloseConfirm () {
      this.showModalConfirm = false
    },
    handleCreateOrUpdateStore () {
      // eslint-disable-next-line camelcase
      const { id, store_name, store_phone, latitude, longitude, store_address, detail_address } = this.getPayloadStore.formStore
      const isEdit = this.typeSidebar === 'edit-store'

      const payload = { url: this.url, data: {} }
      if (isEdit) {
        // eslint-disable-next-line camelcase
        payload.store_id = id
        payload.data = {
          name: store_name,
          // eslint-disable-next-line camelcase
          phone: store_phone === '' ? null : store_phone,
          address: store_address,
          detail_address
        }

        if (latitude && longitude) {
          payload.latitude = latitude
          payload.longitude = longitude
        }
      } else {
        const transformedUserObj = this.getPayloadStore.formPIC.map(obj => ({
          name: obj.pic_name,
          email: obj.pic_email,
          phone: obj.pic_phone
        }))
        payload.data = {
          branch_id: this.payloadAddPICBranch.branchSelected.id,
          users: transformedUserObj,
          store_name,
          // eslint-disable-next-line camelcase
          store_phone: store_phone === '' ? null : store_phone,
          store_address,
          latitude,
          longitude,
          detail_address
        }
      }

      let payloadUrl = 'createStore'
      if (isEdit) {
        payloadUrl = 'updateStore'
      }

      this.$store
        .dispatch(`client/${payloadUrl}`, payload)
        .then(() => {
          this.$buefy.toast.open({
            message: `<p class="averta-bold">${this.renderTitleStore} Berhasil Di ${isEdit ? 'Ubah' : 'Buat'}</p>`,
            type: 'is-success'
          })
          this.isLoadingSubmit = false
          this.closeSidebar()
          this.$emit('refreshData')
          this.clearClientBranchSelected()
        })
        .catch(error => {
          this.isLoadingSubmit = false
          alertErrorMessage(error)
        })
    },
    handleCreatePIC () {
      const transformedUserObj = this.getPayloadStore.formPIC.map(obj => ({
        name: obj.pic_name,
        email: obj.pic_email,
        phone: obj.pic_phone
      }))
      const payload = {
        url: this.url,
        data: {
          branch_id: this.payloadAddPICBranch.branchSelected.id,
          store_id: this.getPayloadStore.selectedStore.id,
          users: transformedUserObj
        }
      }

      this.$store
        .dispatch('client/createStore', payload)
        .then(() => {
          this.$buefy.toast.open({
            message: '<p class="averta-bold">Akun PIC Berhasil Di Buat</p>',
            type: 'is-success'
          })
          this.isLoadingSubmit = false
          this.closeSidebar()
          this.$emit('refreshData')
          this.clearClientBranchSelected()
        })
        .catch(error => {
          this.isLoadingSubmit = false
          alertErrorMessage(error)
        })
    },
    handleConfirmCreate () {
      this.isLoadingSubmit = true
      this.showModalConfirm = false

      if (this.currentTab === 'store') {
        this.handleCreateOrUpdateStore()
      } else if (this.currentTab === 'pic') {
        this.handleCreatePIC()
      }
    },
    handleCloseCancel () {
      this.showModalCancel = false
    },
    handleCloseSidebar () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]
      if (lastElement === 'vendors') {
        if (Object.keys(this.payloadAddPICBranch.clientSelected).length > 0) {
          this.showModalCancel = true
        } else {
          this.closeSidebar()
        }
      } else {
        const { formStore, formPIC } = this.getPayloadStore

        if (this.typeSidebar === 'add-store') {
          const alreadyFilledStore = Object.values(formStore).some(value => value)
          const alreadyFilledPIC = formPIC.some(object => Object.values(object).some(value => value))

          let alreadySelectStore = false
          if (this.currentTab === 'pic') {
            alreadySelectStore = Object.keys(this.getPayloadStore.selectedStore).length > 0
          }

          if (alreadyFilledStore || alreadyFilledPIC || alreadySelectStore) {
            this.showModalCancel = true
          } else {
            this.closeSidebar()
          }
        } else {
          const isSimilar = this.checkIsFormStoreSimilar()
          if (isSimilar) {
            this.closeSidebar()
          } else {
            this.showModalCancel = true
          }
        }
      }
    },
    renderTitleModalSubmit () {
      if (this.currentTab === 'store') {
        this.MODAL_TITLE_CHANGE_TAB = 'Batalkan Tambah Toko?'
        this.MODAL_DESC_CHANGE_TAB = 'Jika data sudah diinput akan dihapus oleh sistem dan kamu akan masuk ke page tambah PIC'
      } else if (this.currentTab === 'pic') {
        this.MODAL_TITLE_CHANGE_TAB = `Batalkan Tambah ${this.renderTitlePICStore}?`
        this.MODAL_DESC_CHANGE_TAB = 'Jika data sudah diinput akan dihapus oleh sistem dan kamu akan masuk ke page tambah Toko'
      }
    },
    clearClientBranchSelected () {
      this.$store.commit('client/setClearDataPayloadPicBranch', false)
      setTimeout(() => {
        this.$store.commit('client/setClearDataPayloadPicBranch', true)
      }, 100)
      this.$store.commit('client/setPayloadAddPicBranch', {
        type: 'clientSelected',
        clientSelected: {}
      })
      this.$store.commit('client/setPayloadAddPicBranch', {
        type: 'branchSelected',
        branchSelected: {}
      })
    },
    checkIsEmptyFormStore () {
      // eslint-disable-next-line camelcase
      const { store_phone, ...rest } = this.getPayloadStore.formStore
      const isEmptyStore = Object.values(rest).some(value => value === '')
      return isEmptyStore
    },
    checkIsFormStoreSimilar () {
      // eslint-disable-next-line camelcase
      const { id, store_name, store_phone, store_address, detail_address } = this.getPayloadStore.formStore

      const tempFormStore = {
        address: store_address,
        detail_address,
        id,
        name: store_name,
        phone: store_phone
      }

      return isObjectsSimilar(this.initialForm, tempFormStore)
    }
  },
  mounted () {
    const path = this.$route.path.split('/')
    const lastElement = path[path.length - 1]
    if (lastElement === 'store' || lastElement === 'store-detail') {
      this.typeSection = 'information'
      this.MODAL_IMAGE_CONFIRM = 'data-not-found.png'
      if (this.typeSidebar === 'edit-store') {
        this.MODAL_IMAGE_CONFIRM = 'sunset.png'
        this.listTextModalConfirm = []
      } else {
        this.listTextModalConfirm = [
          `${this.renderTitlePICStore} akan menerima email untuk ubah password sebelum masuk ke dashboard B2B Kanggo`,
          `Penambahan ${this.renderTitlePICStore} dapat dilakukan oleh client langsung di dashboard B2B`
        ]
      }
    } else if (lastElement === 'vendors') {
      this.clearClientBranchSelected()
      this.MODAL_IMAGE_CONFIRM = 'data-not-found.png'
      this.listTextModalConfirm = [
        `${this.renderTitlePICStore} akan menerima email untuk ubah password sebelum masuk ke dashboard B2B Kanggo`,
        `Penambahan ${this.renderTitlePICStore} dapat dilakukan oleh client langsung di dashboard B2B`
      ]
    }
    this.renderTitleModalSubmit()
  },
  watch: {
    show (value) {
      this.showSidebar = value

      if (value) {
        if (this.typeSidebar === 'edit-store') {
          // eslint-disable-next-line camelcase
          const { id, name, phone, address, detail_address } = this.getPayloadStore.formStore
          const tempFormStore = {
            address,
            detail_address,
            id,
            name,
            phone
          }
          this.initialForm = JSON.parse(JSON.stringify(tempFormStore))
          this.listTextModalConfirm = []
          this.MODAL_IMAGE_CONFIRM = 'sunset.png'
          this.MODAL_DESC_CONFIRM = 'Pastikan kamu sudah melakukan pengecekkan sebelum diubah'
        }
      }
    },
    currentTab () {
      this.renderTitleModalSubmit()
    },
    getPayloadStore: {
      handler (value) {
        if (this.currentTab === 'pic') {
          const isNotSelectedStore = Object.keys(value.selectedStore).length === 0
          const isEmptyPIC = value.formPIC.some(obj => Object.values(obj).some(value => value === ''))

          if (isNotSelectedStore || isEmptyPIC) {
            this.isEmptyForm = true
          } else {
            this.isEmptyForm = false
          }
        } else {
          const isEmptyStore = this.checkIsEmptyFormStore()

          let isEmptyPIC = false
          if (this.typeSidebar !== 'edit-store') {
            isEmptyPIC = value.formPIC.some(obj => Object.values(obj).some(value => value === ''))
          }

          let isSimilar = false
          if (this.typeSidebar === 'edit-store') {
            isSimilar = this.checkIsFormStoreSimilar()
          }

          if (isEmptyStore || isEmptyPIC || isSimilar) {
            this.isEmptyForm = true
          } else {
            this.isEmptyForm = false
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

</style>
