<template>
  <b-modal :on-cancel="closeModal" v-model="isModalActive" :can-cancel="false">
    <div class="modal-template">
      <div class="modal-body w-100">
        <header class="is-flex is-align-items-center is-justify-content-space-between">
          <p class="averta-bold is-size-5">{{ renderTitle }}</p>
          <div class="is-clickable" @click="closeModal">
            <b-icon icon="close"></b-icon>
          </div>
        </header>
        <div class="mt-5">
          <div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mb-5">
            <p>Foto Material <span class="is-italic">(Opsional)</span></p>
            <div class="upload-img-wrapper mt-2">
              <div v-if="photo.preview" class="x-mark-rounded cursor-pointer" @click="handleDeletePhoto">
                  <img :src="require('@/assets/img/close-circle.png')" />
                </div>
              <img :src="getImageSrc" alt="" />
            </div>
            <label for="upload-material">
              <input id="upload-material" type="file" @change="handleFileUpload" accept="image/*" hidden />
              <p class="label-12 color-red mt-2 is-clickable">Upload Foto</p>
            </label>
          </div>
          <div class="mx-5 mb-5">
            <label class="label-14 text-red">Nama Material <span class="color-red">*</span></label>
            <input v-model="name" class="input mt-2 mb-3 input-custom" type="text" placeholder="Masukan Nama Material" :maxlength="100" />

            <div v-if="isTypeEdit" class="mb-3">
              <p class="text-red">Status Material <span class="color-red">*</span></p>
              <div class="is-flex is-align-items-center">
                <p class="averta-bold">{{ isCheckActive ? 'Aktif' : 'Tidak Aktif' }}</p>
                <SwitchToggle :scale="0.7" :active="isCheckActive" @isCheck="($event) => handleIsCheckActive($event)" />
              </div>
            </div>
          </div>
          <footer class="py-5 is-flex is-justify-content-flex-end footer-shadow">
            <b-button class="btn-disabled averta-bold px-5 is-flex is-justify-content-center mr-5" @click="handleSubmitMaterial" :class="{ 'btn-active': !isFilledWithSpaces }">
              <div class="is-flex is-align-items-center">
                <CheckIcon class="mr-3" />
                <span>Simpan</span>
              </div>
            </b-button>
          </footer>
        </div>
      </div>
    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { CheckIcon, SpinnerIcon } from '@/components/icons'
import SwitchToggle from '@/components/SwitchToggle.vue'

export default {
  name: 'ModalMaterials',
  components: {
    CheckIcon,
    SwitchToggle,
    SpinnerIcon
  },
  props: {
    typeModal: {
      type: String,
      default: 'create-material'
    },
    showModal: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number
    },
    nameProps: {
      type: String,
      default: ''
    },
    previewPhotoProps: {
      type: String,
      default: ''
    },
    activeProps: {
      type: Boolean,
      default: true
    },
    surveyId: {
      type: Number
    }
  },
  data () {
    return {
      isModalActive: false,
      isCheckActive: false,
      isLoading: false,
      name: '',
      photo: {
        preview: null,
        picture: null
      }
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    renderTitle () {
      if (this.typeModal === 'create-material' || this.typeModal === 'add-material-offering') {
        return 'Tambah Material'
      } else if (this.typeModal === 'update-material') {
        return 'Edit Material'
      } else {
        return ''
      }
    },
    isTypeEdit () {
      if (this.typeModal === 'update-material') {
        return true
      }
      return false
    },
    isTypeConfirm () {
      if (this.typeModal === 'confirm-material') {
        return true
      }
      return false
    },
    isFilledWithSpaces () {
      // Remove leading and trailing spaces and check if the input contains only spaces
      return this.name.trim() === ''
    },
    getImageSrc () {
      if (this.photo.preview) {
        return this.photo.preview
      } else {
        if ((this.isTypeEdit || this.isTypeConfirm) && this.previewPhotoProps) {
          return this.previewPhotoProps
        } else {
          return require('@/assets/img/empty-img-2.png')
        }
      }
    }
  },
  methods: {
    handleIsCheckActive (e) {
      this.isCheckActive = e
    },
    closeModal (event) {
      this.name = ''
      this.photo = {
        preview: null,
        picture: null
      }
      this.isCheckActive = false
      this.$emit('closeModal', event)
    },
    addMaterial () {
      if (!this.name) {
        return
      }
      if (this.name.length > 100) {
        return
      }

      this.isLoading = true
      const payload = {
        url: this.url,
        name: this.name,
        picture: this.photo.picture
      }

      const isOffering = this.typeModal === 'add-material-offering'

      if (isOffering) {
        payload.survey_id = this.surveyId
        payload.offering = true
      }

      this.$store
        .dispatch('product/postDataProduct', payload)
        .then((response) => {
          this.isLoading = false
          if (isOffering) {
            this.closeModal('success-add-material-offering')
          } else {
            this.closeModal('success')
          }
          this.name = ''
          this.photo = {
            preview: null,
            picture: null
          }
          this.$buefy.toast.open({
            message: 'Berhasil membuat material',
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: error.response.data.message || 'Gagal membuat material, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    updateMaterial () {
      if (!this.name) {
        return
      }
      if (this.name.length > 100) {
        return
      }

      this.$swal({
        imageUrl: require('@/assets/img/delete-area-component.png'),
        imageAlt: 'image update material',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Simpan Material?</p>',
        html: '<p>Pastikan kamu sudah cek kembali material ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true
          const payload = {
            url: this.url,
            id: this.id,
            name: this.name,
            is_active: this.isCheckActive ? 1 : 0,
            picture: this.photo.picture || null
          }

          this.$store
            .dispatch('product/updateDataProduct', payload)
            .then((response) => {
              this.isLoading = false
              this.closeModal('success')
              this.$buefy.toast.open({
                message: 'Data material berhasil diperbarui',
                type: 'is-success'
              })
              this.name = ''
              this.photo = {
                preview: null,
                picture: null
              }
            })
            .catch((error) => {
              this.isLoading = false
              console.log(error)
              this.$buefy.toast.open({
                message: error.response.data.message || 'Gagal ubah material, silahkan coba lagi',
                type: 'is-danger'
              })
            })
        }
      })
    },
    confirmMaterial () {
      if (!this.name) {
        return
      }
      if (this.name.length > 100) {
        return
      }

      this.$swal({
        imageUrl: require('@/assets/img/delete-area-component.png'),
        imageAlt: 'image update material',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22" style="margin-bottom: -10px">Konfirmasi Material?</p>',
        html: '<p class="averta-regular" style="width: 25em; margin: auto;">Pastikan seluruh informasi material yang diinginkan telah sesuai.</p>',
        showCancelButton: true,
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true
          const payload = {
            url: this.url,
            id: this.id,
            name: this.name,
            picture: this.photo.picture || null
          }

          this.$store
            .dispatch('product/confirmRequestMaterial', payload)
            .then((response) => {
              this.isLoading = false
              this.closeModal('success-confirm-material')
              this.name = ''
              this.photo = {
                preview: null,
                picture: null
              }
            })
            .catch((error) => {
              this.isLoading = false
              console.log(error)
              this.$buefy.toast.open({
                message: error.response.data.message || 'Gagal konfirmasi material, silahkan coba lagi',
                type: 'is-danger'
              })
            })
        }
      })
    },
    handleFileUpload (event) {
      const selectedFile = event.target.files[0]

      if (selectedFile) {
        const maxSizeInMB = 5
        const fileSizeInMB = selectedFile.size / (1024 * 1024)
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/JPEG', 'image/PNG', 'image/JPG']

        if (!allowedTypes.includes(selectedFile.type)) {
          this.$buefy.toast.open({
            message: 'Hanya file gambar yang diperbolehkan (JPEG, JPG, PNG)',
            type: 'is-danger'
          })
          return
        }
        if (fileSizeInMB.toFixed(2) > maxSizeInMB) {
          this.$buefy.toast.open({
            message: 'Gambar melebihi ukuran maksimum yang diizinkan',
            type: 'is-danger'
          })
        }
        this.photo = {
          preview: URL.createObjectURL(selectedFile),
          picture: selectedFile
        }
      }
    },
    handleDeletePhoto () {
      this.photo = {
        preview: null,
        picture: null
      }
    },
    handleSubmitMaterial () {
      if (this.isTypeEdit) {
        this.updateMaterial()
      } else if (this.isTypeConfirm) {
        this.confirmMaterial()
      } else {
        this.addMaterial()
      }
    }
  },
  watch: {
    showModal (newVal) {
      this.isModalActive = newVal
      if (this.isTypeEdit) {
        this.name = this.nameProps
        this.isCheckActive = this.activeProps
      } else if (this.isTypeConfirm) {
        this.name = this.nameProps
      } else {
        this.name = ''
        this.isCheckActive = true
      }
    }
  }
}
</script>

<style scoped>
.modal-template {
  width: 35em;
  margin: auto;
}

.modal-body {
  overflow: hidden;
  background: white;
  border-radius: 10px;
  height: 100%;
}

.modal-template header {
  background-color: #911a1e;
  height: 5em;
  color: white;
  padding: 0 20px;
}

.btn-active {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  color: white;
}

.btn-disabled {
  background-color: #9a9a9a;
  border-radius: 100px;
  color: white;
}

.footer-shadow {
  box-shadow: 0px -3px 30px 0px #00000012;
}

.upload-img-wrapper {
  width: 100px;
  height: 100px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.upload-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-custom {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}
</style>
