<template>
  <div class="is-flex is-align-items-center is-justify-content-space-between my-4">
    <p class="mb-sm-1 is-hidden-mobile" v-if="totalData && limitData">{{dataPerPage}} dari {{totalData || '-'}} data</p>
    <div v-else />
    <nav class="pagination pagination-wrapper has-background-white c-w-sm-100" role="navigation" aria-label="pagination">
      <ul class="pagination-list">
        <li>
          <a class="pagination-link-cs" @click="changePage(currentPage, 'prev')">
            <img :src="require('@/assets/img/arrow-left.png')" alt="" />
          </a>
        </li>
        <li v-for="page in pages" :key="page">
          <p v-if="typeof page === 'number'" class="pagination-link-cs is-clickable" :class="{ 'is-current': currentPage === page }" @click="changePage(page + 1)">{{ page }}</p>
          <p v-else class="mx-2 is-clickable">{{ page }}</p>
        </li>
        <li>
          <a class="pagination-link-cs right" :class="{ 'is-disabled': currentPage === totalPages }" @click="changePage(currentPage, 'next')">
            <img :src="require('@/assets/img/arrow-left.png')" alt="" />
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'PaginationApp',
  props: {
    totalPages: {
      type: Number,
      required: true
    },
    totalData: {
      type: Number
    },
    limitData: {
      type: Number
    },
    currentPage: {
      type: Number,
      required: true
    },
    loadPage: {
      type: Function,
      required: true
    },
    prevOffset: {
      type: Number,
      default: 1
    },
    nextOffset: {
      type: Number,
      default: 2
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    pages () {
      const range = []
      let isPrev = false

      for (var i = 1; i <= this.totalPages; i++) {
        if (this.currentPage - this.prevOffset <= 1 && i < this.currentPage) {
          range.push(i)
        } else if (i < this.currentPage - this.prevOffset && i !== 1) {
          if (!isPrev) {
            isPrev = true
            range.push('...')
          }
        } else if (i === this.currentPage) {
          range.push(i)
        } else if (i <= this.currentPage + this.nextOffset) {
          range.push(i)
        } else {
          if (this.currentPage + this.nextOffset !== this.totalPages - 1) {
            range.push('...')
          }
          range.push(this.totalPages)
          isPrev = false
          break
        }
      }

      return range
    },
    dataPerPage () {
      let result = this.currentPage * this.limitData || '-'
      if ((this.totalData && this.limitData) && this.currentPage === this.totalPages) {
        result -= result - this.totalData
      }
      return result
    }
  },
  methods: {
    changePage (page, type) {
      if (type === 'next') {
        if (page !== this.totalPages) {
          if (this.isNew) {
            this.loadPage({ pages: page + 1, isFilter: true })
          } else {
            this.loadPage(page + 1, true)
          }
          window.scrollTo({ top: 0 })
        }
      } else {
        if (page !== 1) {
          if (this.isNew) {
            this.loadPage({ pages: page - 1, isFilter: true })
          } else {
            this.loadPage(page - 1, true)
          }
          window.scrollTo({ top: 0 })
        }
      }
    }
  },
  created () {}
}
</script>

<style scoped>
.pagination-wrapper {
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  width: auto;
  padding: 5px 10px 5px 10px;
}

.pagination-link-cs {
  border-radius: 4px;
  min-width: 32px;
  height: 32px;
  padding: 0 5px;
  display: inline-block;
  margin-bottom: 0;
  line-height: 32px;
  margin-right: 10px;
  color: black;
}

.pagination-link-cs.is-current {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  color: white;
}

.pagination-link-cs.right {
  transform: rotate(180deg);
}
</style>
