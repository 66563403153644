<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8881 0.675605C14.0868 0.600682 15.269 1.01692 16.1597 1.83274C16.9755 2.72349 17.3918 3.9056 17.3252 5.11268V12.888C17.4001 14.0951 16.9755 15.2772 16.168 16.1679C15.2773 16.9837 14.0868 17.4 12.8881 17.325H5.11272C3.90562 17.4 2.7235 16.9837 1.83275 16.1679C1.01692 15.2772 0.600682 14.0951 0.675605 12.888V5.11268C0.600682 3.9056 1.01692 2.72349 1.83275 1.83274C2.7235 1.01692 3.90562 0.600682 5.11272 0.675605H12.8881ZM8.15126 13.0378L13.7538 7.41863C14.2617 6.9025 14.2617 6.07002 13.7538 5.56222L12.6716 4.48C12.1555 3.96387 11.323 3.96387 10.8069 4.48L10.2491 5.04608C10.1659 5.12933 10.1659 5.27085 10.2491 5.3541C10.2491 5.3541 11.5728 6.6694 11.5977 6.7027C11.6893 6.8026 11.7476 6.93579 11.7476 7.08564C11.7476 7.38533 11.5062 7.63507 11.1981 7.63507C11.0566 7.63507 10.9234 7.5768 10.8318 7.48523L9.44161 6.10332C9.37501 6.03673 9.25846 6.03673 9.19186 6.10332L5.22094 10.0742C4.94622 10.3489 4.78805 10.7152 4.77973 11.1065L4.72978 13.0794C4.72978 13.1877 4.76308 13.2876 4.838 13.3625C4.91292 13.4374 5.01282 13.479 5.12104 13.479H7.07737C7.47696 13.479 7.8599 13.3209 8.15126 13.0378Z"
      :fill="color ? color : '#252525'"
    />
  </svg>
</template>

<script>
export default {
  name: 'EditSquare',
  props: {
    size: {
      type: String,
      default: '18'
    },
    color: {
      type: String
    }
  }
}
</script>
