import axios from 'axios'

/* eslint-disable */
const state = () => ({
    list: null
})

const getters = {}

const actions = {
    getDataDetailComponents({ commit, state }, payload) {
        let concatUrl = ''

        if (payload.search) {
            concatUrl = `&search=${payload.search}`
        }

        return new Promise((resolve, reject) => {
            axios.get(`${payload.url}${process.env.VUE_APP_SETTING}/detail-component?${concatUrl && `${concatUrl}`}&page=${payload.page || 1}&limit=10`).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    postDataDetailComponents({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${payload.url}${process.env.VUE_APP_SETTING}/detail-component`, {
                name: payload.name
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getAllAreaComponents({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${payload.url}${process.env.VUE_APP_SETTING}/component`).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getDataAreaComponents({ commit, state }, payload) {
        let concatUrl = ''

        if (payload.search) {
            concatUrl = `?search=${payload.search}`
        } else if (payload.page) {
            concatUrl = `?page=${payload.page || 1}&limit=${payload.limit || 10}`
        }

        return new Promise((resolve, reject) => {
            axios.get(`${payload.url}${process.env.VUE_APP_SETTING}/component${concatUrl}`).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    postDataComponents({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${payload.url}${process.env.VUE_APP_SETTING}/component`, {
                name: payload.name
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getDataArea({ commit, state }, payload) {
        let concatUrl = ''

        if (payload.search) {
            concatUrl = `&search=${payload.search}`
        }

        return new Promise((resolve, reject) => {
            axios.get(`${payload.url}${process.env.VUE_APP_SETTING}/area?${concatUrl && `${concatUrl}`}&page=${payload.page || 1}&limit=10`).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    postDataArea({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${payload.url}${process.env.VUE_APP_SETTING}/area`, {
                name: payload.name,
                component_id: payload.component_id
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getDataJamSurvey({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${payload.url}${process.env.VUE_APP_SETTING}/survey`).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    postJamSurvey({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${payload.url}${process.env.VUE_APP_SETTING}/survey`, {
                start_hour: payload.start_hour,
                duration: payload.duration
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getDataKota({ commit, state }, payload) {
        let concatUrl = ''

        if (payload.search) {
            concatUrl = `&search=${payload.search}`
        }
        return new Promise((resolve, reject) => {
            axios.get(`${payload.url}${process.env.VUE_APP_SETTING}/city?${concatUrl && `${concatUrl}`}&page=${payload.page || 1}&limit=10`).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    updateDataKota({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            axios.put(`${payload.url}${process.env.VUE_APP_SETTING}/city/${payload.id}/update`, {
                max_visit: payload.max_visit
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // Dynamic API
    updateData({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            axios.put(`${payload.url}${process.env.VUE_APP_SETTING}/${payload.type}/${payload.id}/update`, payload.body).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    detailData({ commit, state }, payload) {
        let concatUrl = ''
        if (payload.onlyView) {
            concatUrl = '?only_view=true'
        } else if (payload.search) {
            concatUrl = `?search=${payload.search}`
        } else {
            concatUrl = `?page=${payload.page || 1}&limit=${payload.limit || 10}`
        }

        return new Promise((resolve, reject) => {
            axios.get(`${payload.url}${process.env.VUE_APP_SETTING}/${payload.type}/${payload.id}/detail${concatUrl}`).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    deleteData({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`${payload.url}${process.env.VUE_APP_SETTING}/${payload.type}/${payload.id}/delete`).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
