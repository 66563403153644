<template>
  <svg :width="width" :height="height" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.41602 8.91663H6.58268V5.41663H5.41602V8.91663ZM5.99935 4.24996C6.16463 4.24996 6.30327 4.19396 6.41527 4.08196C6.52688 3.97035 6.58268 3.8319 6.58268 3.66663C6.58268 3.50135 6.52688 3.36271 6.41527 3.25071C6.30327 3.1391 6.16463 3.08329 5.99935 3.08329C5.83407 3.08329 5.69563 3.1391 5.58402 3.25071C5.47202 3.36271 5.41602 3.50135 5.41602 3.66663C5.41602 3.8319 5.47202 3.97035 5.58402 4.08196C5.69563 4.19396 5.83407 4.24996 5.99935 4.24996ZM5.99935 11.8333C5.1924 11.8333 4.43407 11.6801 3.72435 11.3736C3.01463 11.0676 2.39727 10.652 1.87227 10.127C1.34727 9.60204 0.931738 8.98468 0.625682 8.27496C0.319238 7.56524 0.166016 6.8069 0.166016 5.99996C0.166016 5.19301 0.319238 4.43468 0.625682 3.72496C0.931738 3.01524 1.34727 2.39788 1.87227 1.87288C2.39727 1.34788 3.01463 0.932154 3.72435 0.625709C4.43407 0.319654 5.1924 0.166626 5.99935 0.166626C6.80629 0.166626 7.56463 0.319654 8.27435 0.625709C8.98407 0.932154 9.60143 1.34788 10.1264 1.87288C10.6514 2.39788 11.067 3.01524 11.373 3.72496C11.6795 4.43468 11.8327 5.19301 11.8327 5.99996C11.8327 6.8069 11.6795 7.56524 11.373 8.27496C11.067 8.98468 10.6514 9.60204 10.1264 10.127C9.60143 10.652 8.98407 11.0676 8.27435 11.3736C7.56463 11.6801 6.80629 11.8333 5.99935 11.8333Z"
      fill="url(#paint0_linear_206_94053)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_206_94053"
        x1="0.166016"
        y1="5.8939"
        x2="9.57086"
        y2="10.5061"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop offset="0.9999" :stop-color="color" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'InfoIcon',
  props: {
    color: {
      type: String,
      default: '#E10009'
    },
    width: {
      type: String,
      default: '12'
    },
    height: {
      type: String,
      default: '12'
    }
  }
}
</script>
