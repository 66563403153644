<template>
    <div>
      <p class="label-20 averta-black mb-5">Tambah Cabang</p>
      <div>
        <ClientInfo
          typeSection="information"
          class="mb-5"
          :name="dataClient.name"
          :companyName="dataClient.company_name"
          :logo="dataClient.logo"
        />
        <BranchInfo currentTab="branch" />
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientInfo from '../branch-components/ClientInfo.vue'
import BranchInfo from '../branch-components/BranchInfo.vue'

export default {
  name: 'AdditionalBranch',
  components: {
    ClientInfo,
    BranchInfo
  },
  computed: {
    ...mapGetters({
      getPayloadDataFormClient: 'client/getPayloadDataFormClient'
    })
  },
  data () {
    return {
      dataClient: {
        company_name: '',
        name: '',
        logo: ''
      }
    }
  },
  methods: {
    detailDataClient () {
      this.dataClient = {
        ...this.getPayloadDataFormClient
      }
    }
  },
  mounted () {
    this.detailDataClient()
  },
  watch: {
    getPayloadDataFormClient: {
      handler () {
        this.detailDataClient()
      },
      deep: true
    }
  }
}
</script>
