<template>
  <div
    class="type-client mr-3"
    :class="{ 'active': active, 'is-clickable': onClick }"
    @click="handleClickDiv"
  >
    <input
      v-if="type === 'checkbox'"
      type="checkbox"
      :value="value"
      class="checkbox-client"
      :checked="active"
    />
    <div>
      <div class="averta-black label-16">{{title}}</div>
      <p class="color-grey">{{desc}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardClientType',
  props: {
    title: {
      type: String,
      required: true
    },
    desc: {
      type: String
    },
    type: {
      type: String,
      default: null // 'checkbox'
    },
    active: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    onClick: { // use arrow function :onClick="() => function(props)"
      type: Function,
      default: null
    }
  },
  methods: {
    handleClickDiv () {
      if (this.onClick) {
        this.onClick()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.type-client {
  flex: 1;
  padding: 15px;
  border: 1px solid $color-grey-2;
  display: flex;
  align-items: center;
  border-radius: 16px;
  transition: all 0.08s ease-in-out;
  background: $color-grey-6;
}

.type-client.active {
  border: 1px solid $color-blue-1;
  background: $color-light-blue-2 !important;
}

.checkbox-client {
  transform: scale(1.7);
  margin-right: 13px;
  margin-left: 3px;
  cursor: pointer;
}
</style>
