<template>
    <svg :width="width || '24'" :height="height || '20'" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 2.6218V4.63422C0 5.3763 0.595833 5.97583 1.33333 5.97583H2H4V2.6218C4 1.51077 3.10417 0.609375 2 0.609375C0.895833 0.609375 0 1.51077 0 2.6218ZM4.66667 0.609375C5.08333 1.17118 5.33333 1.86714 5.33333 2.6218V15.3671C5.33333 16.8471 6.52917 18.0504 8 18.0504C9.47083 18.0504 10.6667 16.8471 10.6667 15.3671V15.1449C10.6667 13.7865 11.7625 12.6839 13.1125 12.6839H20V4.63422C20 2.41217 18.2083 0.609375 16 0.609375H4.66667ZM19.3333 19.392C21.9125 19.392 24 17.2915 24 14.6963C24 14.3274 23.7 14.0255 23.3333 14.0255H13.1125C12.5 14.0255 12 14.5244 12 15.1449V15.3671C12 17.5892 10.2083 19.392 8 19.392H15.3333H19.3333Z"
            :fill="color || '#D92727'" />
    </svg>
</template>

<script>
export default {
  name: 'IconMenuReport',
  props: ['color', 'height', 'width']
}
</script>
