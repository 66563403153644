<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7126_208356)">
      <path
        d="M4.54773 1.19922C3.85688 1.19922 3.18153 1.40408 2.6071 1.7879C2.03267 2.17172 1.58496 2.71726 1.32058 3.35553C1.0562 3.9938 0.987027 4.69614 1.12181 5.37372C1.25659 6.05131 1.58927 6.67371 2.07778 7.16222C2.56629 7.65073 3.18869 7.98341 3.86628 8.11819C4.54386 8.25297 5.24619 8.1838 5.88446 7.91942C6.52274 7.65504 7.06827 7.20732 7.4521 6.6329C7.83592 6.05847 8.04078 5.38312 8.04078 4.69226C8.03971 3.76618 7.67135 2.87833 7.01651 2.22349C6.36167 1.56864 5.47382 1.20029 4.54773 1.19922ZM7.50339 4.69226C7.50363 4.96484 7.46598 5.23613 7.39154 5.49835H6.09811C6.18053 4.96412 6.18053 4.42041 6.09811 3.88618H7.39154C7.46598 4.14839 7.50363 4.41968 7.50339 4.69226ZM3.67447 6.03574H5.421C5.24894 6.59954 4.95024 7.11652 4.54773 7.54716C4.14542 7.11638 3.84674 6.59944 3.67447 6.03574ZM3.54348 5.49835C3.45121 4.96492 3.45121 4.41961 3.54348 3.88618H5.55467C5.64695 4.41961 5.64695 4.96492 5.55467 5.49835H3.54348ZM1.59208 4.69226C1.59184 4.41968 1.62948 4.14839 1.70393 3.88618H2.99736C2.91494 4.42041 2.91494 4.96412 2.99736 5.49835H1.70393C1.62948 5.23613 1.59184 4.96484 1.59208 4.69226ZM5.421 3.34879H3.67447C3.84652 2.78499 4.14523 2.26801 4.54773 1.83737C4.95005 2.26815 5.24873 2.78509 5.421 3.34879ZM7.1786 3.34879H5.98223C5.83143 2.79558 5.5773 2.27592 5.23324 1.81722C5.64895 1.91708 6.03815 2.10566 6.37416 2.37001C6.71018 2.63437 6.98505 2.96826 7.17995 3.34879H7.1786ZM3.86222 1.81722C3.51817 2.27592 3.26404 2.79558 3.11323 3.34879H1.91552C2.11042 2.96826 2.38529 2.63437 2.7213 2.37001C3.05731 2.10566 3.44651 1.91708 3.86222 1.81722ZM1.91552 6.03574H3.11323C3.26404 6.58895 3.51817 7.10861 3.86222 7.56731C3.44651 7.46745 3.05731 7.27887 2.7213 7.01452C2.38529 6.75016 2.11042 6.41627 1.91552 6.03574ZM5.23324 7.56731C5.5773 7.10861 5.83143 6.58895 5.98223 6.03574H7.17995C6.98505 6.41627 6.71018 6.75016 6.37416 7.01452C6.03815 7.27887 5.64895 7.46745 5.23324 7.56731Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_7126_208356">
        <rect width="8.59827" height="8.59827" fill="white" transform="translate(0.25 0.394531)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'WebIcon',
  props: {
    color: {
      type: String,
      default: '#E10009'
    },
    size: {
      type: String,
      default: '9'
    }
  }
}
</script>
