<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M12.7142 13.0948C10.6767 15.1324 7.65977 15.5726 5.19086 14.4309C4.82639 14.2841 4.52758 14.1655 4.2435 14.1655C3.45224 14.1702 2.46736 14.9374 1.95549 14.4262C1.44362 13.9142 2.21143 12.9286 2.21143 12.1325C2.21143 11.8484 2.09753 11.555 1.9508 11.1898C0.808472 8.72126 1.24932 5.7033 3.28676 3.66631C5.88765 1.06447 10.1133 1.06447 12.7142 3.66564C15.3197 6.27151 15.3151 10.4936 12.7142 13.0948Z"
      stroke="url(#paint0_linear_1086_181681)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.6262 8.65755H10.6322" stroke="url(#paint1_linear_1086_181681)" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M7.95362 8.65755H7.95962" stroke="url(#paint2_linear_1086_181681)" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M5.28077 8.65755H5.28677" stroke="url(#paint3_linear_1086_181681)" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_1086_181681" x1="1.33325" y1="8.2603" x2="12.0816" y2="13.5313"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
      <linearGradient id="paint1_linear_1086_181681" x1="10.2959" y1="8.65149" x2="10.8333" y2="8.91504"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
      <linearGradient id="paint2_linear_1086_181681" x1="7.62329" y1="8.65149" x2="8.16071" y2="8.91504"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
      <linearGradient id="paint3_linear_1086_181681" x1="4.95044" y1="8.65149" x2="5.48786" y2="8.91504"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ChatIcon'
}
</script>
