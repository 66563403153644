<template>
  <div class="is-flex is-justify-content-center wrapper">
    <div class="card p-6 is-flex is-justify-content-space-evenly wrapper-form-profile">
      <div class="left-section has-text-centered">
        <label for="profile">
          <input type="file" id="profile" @change="profileSelected" hidden accept="image/png, image/jpeg" />
          <div v-if="preview_image" class="is-clickable">
            <img :src="preview_image" :alt="user.name" class="image-profile">
            <EditProfile class="icon-edit" />
          </div>
          <div class="wrapper-camera is-clickable" v-else>
            <CameraIcon />
          </div>
          <p class="is-clickable" v-if="!profile_picture">Upload Foto</p>
        </label>
        <p class="is-clickable" v-if="profile_picture" @click="deleteProfilePictures()">Hapus Foto</p>
      </div>
      <div class="right-section">
        <div class="columns">
          <div class="column">
            <p>Nama Lengkap <span class="has-text-danger">*</span></p>
          </div>
          <div class="column is-8">
            <b-field>
              <b-input v-model="name"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <p>Email <span class="has-text-danger">*</span></p>
          </div>
          <div class="column is-8">
            <b-field>
              <b-input v-model="email"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <p>No. Handphone/WhatsApp <span class="has-text-danger">*</span></p>
          </div>
          <div class="column is-8">
            <b-field>
              <b-input v-model="phone" ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <p>Role <span class="has-text-danger">*</span></p>
          </div>
          <div class="column is-8">
            <b-field>
              <b-input :value="roles === 'Account Manager' ? 'Business Support' : roles" disabled></b-input>
            </b-field>
          </div>
        </div>
        <div v-if="showNotif" class="is-flex notif p-3 mb-5 is-align-items-center">
          <div class="card-attention has-text-centered">
            <span class="averta-bold">!</span>
          </div>
          <p>{{ messageFailedLogin }}</p>
        </div>
        <div class="is-flex is-justify-content-end">
          <b-button class="btn-simpan" @click="updateProfile()">
            <div class="is-flex is-align-items-center has-text-white">
              <CheckIcon class="mr-3" />
              <span class="averta-bold">Simpan</span>
            </div>
          </b-button>
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="false"
    >
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CameraIcon, CheckIcon, SpinnerIcon, EditProfile } from '../../../components/icons'

export default {
  name: 'InformasiPribadi',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    })
  },
  data () {
    return {
      name: null,
      email: null,
      phone: null,
      profile_picture: null,
      preview_image: null,
      isFullPage: true,
      isLoading: false,
      showNotif: false,
      messageFailedLogin: '',
      roles: null
    }
  },
  components: {
    CameraIcon,
    CheckIcon,
    SpinnerIcon,
    EditProfile
  },
  methods: {
    profileSelected (e) {
      e.preventDefault()
      this.profile_picture = e.target.files[0]
      this.preview_image = URL.createObjectURL(e.target.files[0])
    },
    async getMyProfile () {
      await this.$store.dispatch('auth/getMyProfile', { url: this.url })
        .then(response => {
          this.$store.commit('auth/setUser', response.data.data)
          const dataLocalStorage = localStorage.getItem('webapp_shell_kanggo')
          let auth = JSON.parse(dataLocalStorage)
          auth = {
            ...auth,
            email: response.data.data.email,
            user: response.data.data
          }
          localStorage.setItem('webapp_shell_kanggo', JSON.stringify(auth))
          this.name = response.data.data.name
          this.email = response.data.data.email
          this.phone = response.data.data.phone

          const tempRoles = response.data.data.role
          let resRoles = ''

          if (tempRoles.includes('Account Manager')) {
            resRoles = tempRoles.replace('Account Manager', 'Business Support')
          } else {
            resRoles = tempRoles
          }
          this.roles = resRoles

          this.profile_picture = response.data.data.profile_picture
          this.preview_image = response.data.data.profile_picture
        })
        .catch(error => {
          console.log(error)
        })
    },
    updateProfile () {
      if (this.name === null || this.email === null || this.phone === null) {
        this.showNotif = true
        this.messageFailedLogin = 'Silakan masukkan data profile dengan benar'
        return false
      }

      this.isLoading = true

      const payload = {
        url: this.url,
        name: this.name,
        email: this.email,
        phone: this.phone
      }

      if (typeof this.profile_picture !== 'string') {
        payload.photo = this.profile_picture
      }

      this.$store.dispatch('auth/updateProfile', payload)
        .then(async (response) => {
          this.getMyProfile()
          this.$swal.fire(
            'Berhasil update profile!',
            'Anda telah memperbaharui profile',
            'success'
          )
          this.isLoading = false
          this.showNotif = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
          this.showNotif = true
          this.messageFailedLogin = error.response.data.message[0].message || error.response.data.message
          return false
        })
    },
    deleteProfilePictures () {
      const payload = {
        url: this.url,
        name: this.name,
        email: this.email,
        phone: this.phone
      }

      this.$swal({
        title: 'Hapus foto profile?',
        text: 'Anda akan menghapus foto profile',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, hapus foto profile',
        cancelButtonText: 'Batal'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoading = true
          await this.$store.dispatch('auth/deleteProfilePictures', payload)
            .then(async (response) => {
              this.isLoading = false
              this.showNotif = false
              await this.getMyProfile()
              this.$swal.fire(
                'Berhasil hapus foto profile!',
                'Anda telah menghapus foto profile',
                'success'
              )
            })
            .catch(error => {
              console.log(error)
              this.isLoading = false
              this.showNotif = true
              this.messageFailedLogin = error.response.data.message[0].message || error.response.data.message
              return false
            })
        }
      })
    }
  },
  created () {
    this.getMyProfile()
  }
  // mounted () {
  //   this.name = this.user.name
  //   this.email = this.user.email
  //   this.phone = this.user.phone
  //   this.profile_picture = this.user.profile_picture
  //   this.preview_image = this.user.profile_picture
  // }
}
</script>

<style scoped>

.wrapper {
  width: 78vw;
  margin: auto;
}
.left-section {
  color: #D9272D;
  margin-right: 40px;
}

.right-section {
  width: 60vw;
}
.wrapper-camera {
  padding: 100px;
  border: 2px #D9272D dashed;
  border-radius: 150px;
  margin-bottom: 14px;
}
.image-profile {
  object-fit: cover;
  height: 200px;
  width: 220px;
  border-radius: 100px;
}

.btn-simpan {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 90px;
}

.notif {
  background: #FFF1F1;
  border-radius: 20px;
}

.card-attention {
  width: 24px;
  height: 24px;
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 100px;
  color: white;
  margin: 0px 10px 0px 10px;
}

.icon-edit {
  margin: 150px 0px 0px -40px;
  position: absolute;
}

@media only screen and (max-width: 768px) {
  .wrapper-form-profile {
    display: block !important;
    padding: 14px !important;
    width: 80vw;
  }

  .left-section {
    margin: 12px 16px 32px 16px;
  }

  .right-section {
    width: 100%;
  }

  .image-profile {
    object-fit: cover;
    height: 200px;
    width: 200px;
    border-radius: 100px;
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    width: 100%;
  }

  .wrapper-form-profile {
    width: 100vw !important;
  }
}

</style>
