<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="18" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8137 24.6899L23.1357 15.2212C23.5336 14.7106 23.6751 14.1202 23.5424 13.5192C23.4275 12.9727 23.0914 12.4531 22.5874 12.059L21.3582 11.0826C20.2882 10.2315 18.9618 10.3211 18.2013 11.2975L17.3789 12.3645C17.2728 12.4979 17.2993 12.695 17.4319 12.8025C17.4319 12.8025 19.51 14.4687 19.5542 14.5045C19.6957 14.6389 19.8018 14.8181 19.8284 15.0331C19.8726 15.4541 19.5808 15.8483 19.1475 15.902C18.9441 15.9289 18.7495 15.8662 18.608 15.7497L16.4238 14.0119C16.3177 13.9321 16.1585 13.9491 16.0701 14.0566L10.8793 20.7752C10.5432 21.1963 10.4283 21.7427 10.5432 22.2712L11.2065 25.1468C11.2418 25.2991 11.3745 25.4066 11.5337 25.4066L14.4518 25.3707C14.9824 25.3618 15.4776 25.1199 15.8137 24.6899ZM19.8997 23.7944H24.6581C25.1224 23.7944 25.5 24.1769 25.5 24.6472C25.5 25.1184 25.1224 25.5 24.6581 25.5H19.8997C19.4355 25.5 19.0579 25.1184 19.0579 24.6472C19.0579 24.1769 19.4355 23.7944 19.8997 23.7944Z"
      fill="url(#paint0_linear_871_146614)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_871_146614"
        x1="10.5"
        y1="17.8636"
        x2="22.5919"
        y2="23.7936"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'EditProfile'
}
</script>
