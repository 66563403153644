<template>
  <div class="is-flex is-justify-content-center c-w-100">
    <div class="card p-5 card-wrapper">
      <p class="averta-bold is-size-4">Halo!</p>
      <p class="mb-5">Silakan ubah password kamu <span class="lupa-password is-clickable">Lupa Password</span></p>
      <div class="mb-5">
        <p class="mb-3">Password Lama <span class="has-text-danger">*</span></p>
        <b-field>
          <b-input
            type="password"
            password-reveal
            v-model="password_old"
            placeholder="Masukkan password lama"
          >
          </b-input>
        </b-field>
      </div>
      <div class="mb-5">
        <p class="mb-3">Password Baru <span class="has-text-danger">*</span></p>
        <b-field>
          <b-input
            type="password"
            password-reveal
            v-model="password"
            placeholder="Masukkan password baru"
          >
          </b-input>
        </b-field>
      </div>
      <div class="mb-5">
        <p class="mb-3">Konfirmasi Password Baru <span class="has-text-danger">*</span></p>
        <b-field>
          <b-input
            type="password"
            password-reveal
            v-model="password_confirm"
            placeholder="Masukkan kembali password baru"
          >
          </b-input>
        </b-field>
      </div>
      <div v-if="showNotif" class="is-flex notif p-3 mb-5 is-align-items-center">
        <div class="card-attention has-text-centered">
          <span class="averta-bold">!</span>
        </div>
        <p>{{ messageFailedLogin }}</p>
      </div>
      <div class="is-flex is-justify-content-end">
          <b-button class="btn-konfirmasi px-5" @click="changePassword()">
            <div class="is-flex is-align-items-center has-text-white">
              <span class="averta-bold">Konfirmasi</span>
            </div>
          </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'KataSandi',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      password_old: null,
      password: null,
      password_confirm: null,
      showNotif: false,
      messageFailedLogin: ''
    }
  },
  methods: {
    async changePassword () {
      if (this.password === null || this.password_confirm === null || this.password_old === null) {
        this.showNotif = true
        this.messageFailedLogin = 'Silakan masukkan password lama, password baru, dan konfirmasi password baru terlebih dahulu'
        return false
      }

      if (this.password !== this.password_confirm) {
        this.showNotif = true
        this.messageFailedLogin = 'Maaf, password dan konfirmasi password harus sama'
        return false
      }

      const payload = {
        url: this.url,
        password_old: this.password_old,
        password: this.password,
        password_confirm: this.password_confirm
      }

      this.$store.dispatch('auth/changePassword', payload)
        .then(response => {
          this.$buefy.toast.open({
            message: 'Berhasil ubah password',
            type: 'is-success'
          })
          this.password_old = null
          this.password = null
          this.password_confirm = null
          this.showNotif = false
        })
        .catch(error => {
          console.log(error)
          this.showNotif = true
          this.messageFailedLogin = error.response.data.message[0].message || error.response.data.message
          return false
        })
    }
  }
}
</script>

<style scoped>
.lupa-password {
  color: #D9272D;
  font-family: 'Averta-Bold';
}
.btn-konfirmasi {
  background: #9A9A9A;
  border-radius: 90px;
  font-family: 'Averta-Bold';
}
.notif {
  background: #FFF1F1;
  border-radius: 20px;
}
.card-attention {
  width: 24px;
  height: 24px;
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 100px;
  color: white;
  margin: 0px 10px 0px 10px;
}
.card-wrapper {
  width: 598px;
}
</style>
