<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 9.5C11.3261 9.5 12.5979 10.0268 13.5355 10.9645C14.4732 11.9021 15 13.1739 15 14.5V20.5H5V14.5C5 13.1739 5.52678 11.9021 6.46447 10.9645C7.40215 10.0268 8.67392 9.5 10 9.5ZM3.288 12.506C3.12886 13.0428 3.03485 13.5968 3.008 14.156L3 14.5V20.5H9.13169e-08V16C-0.000196807 15.1376 0.318028 14.3054 0.893635 13.6632C1.46924 13.021 2.2617 12.6139 3.119 12.52L3.289 12.506H3.288ZM16.712 12.506C17.6019 12.5602 18.4376 12.952 19.0486 13.6012C19.6596 14.2505 19.9999 15.1084 20 16V20.5H17V14.5C17 13.807 16.9 13.138 16.712 12.506ZM3.5 6.5C4.16304 6.5 4.79893 6.76339 5.26777 7.23223C5.73661 7.70107 6 8.33696 6 9C6 9.66304 5.73661 10.2989 5.26777 10.7678C4.79893 11.2366 4.16304 11.5 3.5 11.5C2.83696 11.5 2.20107 11.2366 1.73223 10.7678C1.26339 10.2989 1 9.66304 1 9C1 8.33696 1.26339 7.70107 1.73223 7.23223C2.20107 6.76339 2.83696 6.5 3.5 6.5ZM16.5 6.5C17.163 6.5 17.7989 6.76339 18.2678 7.23223C18.7366 7.70107 19 8.33696 19 9C19 9.66304 18.7366 10.2989 18.2678 10.7678C17.7989 11.2366 17.163 11.5 16.5 11.5C15.837 11.5 15.2011 11.2366 14.7322 10.7678C14.2634 10.2989 14 9.66304 14 9C14 8.33696 14.2634 7.70107 14.7322 7.23223C15.2011 6.76339 15.837 6.5 16.5 6.5ZM10 0.5C11.0609 0.5 12.0783 0.921427 12.8284 1.67157C13.5786 2.42172 14 3.43913 14 4.5C14 5.56087 13.5786 6.57828 12.8284 7.32843C12.0783 8.07857 11.0609 8.5 10 8.5C8.93913 8.5 7.92172 8.07857 7.17157 7.32843C6.42143 6.57828 6 5.56087 6 4.5C6 3.43913 6.42143 2.42172 7.17157 1.67157C7.92172 0.921427 8.93913 0.5 10 0.5Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'DaftarSurvey'
}
</script>
