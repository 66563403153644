<template>
  <svg
    :width="size || 16"
    :height="size || 16"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.875 15.8L0.275 9.19996C0.175 9.09996 0.104 8.99163 0.0619998 8.87496C0.0206665 8.75829 0 8.63329 0 8.49996C0 8.36663 0.0206665 8.24163 0.0619998 8.12496C0.104 8.00829 0.175 7.89996 0.275 7.79996L6.875 1.19996C7.05833 1.01663 7.28733 0.920627 7.562 0.911961C7.83733 0.903961 8.075 0.99996 8.275 1.19996C8.475 1.38329 8.57933 1.61229 8.588 1.88696C8.596 2.16229 8.5 2.39996 8.3 2.59996L3.4 7.49996H14.575C14.8583 7.49996 15.096 7.59563 15.288 7.78696C15.4793 7.97896 15.575 8.21663 15.575 8.49996C15.575 8.78329 15.4793 9.02063 15.288 9.21196C15.096 9.40396 14.8583 9.49996 14.575 9.49996H3.4L8.3 14.4C8.48333 14.5833 8.57933 14.8166 8.588 15.1C8.596 15.3833 8.5 15.6166 8.3 15.8C8.11667 16 7.88333 16.1 7.6 16.1C7.31667 16.1 7.075 16 6.875 15.8Z"
      :fill="color || 'black'"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowLeftIcon',
  props: ['color', 'size']
}
</script>
