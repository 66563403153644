<template>
  <svg
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_3083_152760"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="11"
      y="4"
      width="43"
      height="56"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.5013 22.6667H45.8346V17.3333C45.8346 9.97333 39.8613 4 32.5013 4C25.1413 4 19.168 9.97333 19.168 17.3333V22.6667H16.5013C13.568 22.6667 11.168 25.0667 11.168 28V54.6667C11.168 57.6 13.568 60 16.5013 60H48.5013C51.4346 60 53.8346 57.6 53.8346 54.6667V28C53.8346 25.0667 51.4346 22.6667 48.5013 22.6667ZM32.5013 46.6667C29.568 46.6667 27.168 44.2667 27.168 41.3333C27.168 38.4 29.568 36 32.5013 36C35.4346 36 37.8346 38.4 37.8346 41.3333C37.8346 44.2667 35.4346 46.6667 32.5013 46.6667ZM24.5013 17.3333V22.6667H40.5013V17.3333C40.5013 12.9067 36.928 9.33333 32.5013 9.33333C28.0746 9.33333 24.5013 12.9067 24.5013 17.3333Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_3083_152760)">
      <rect x="0.5" width="64" height="64" fill="white" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LockIcon'
}
</script>
