<template>
  <div>
    <div class="is-clickable ml-2" @click="handleModalHistory">
      <HistoryIcon />
    </div>
    <ModalTemplate :showModal="isModalActive" width="900px" @closeModal="closeModal">
      <template v-slot:headerModal>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <p class="averta-bold label-18">Riwayat Perubahan</p>
          <div class="is-clickable" @click="closeModal">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div class="wrap-body-modal p-5">
          <div v-if="isLoading">
          </div>
          <div v-else-if="!isLoading && dataModal.length > 0">
            <div class="wrap-table mb-5">
              <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                <tr class="bg-dark-grey-5 has-text-white has-text-centered">
                  <td class="py-4 averta-bold" style="width: 10%;">NO</td>
                  <td class="py-4 averta-bold" style="width: 25%;">DIUBAH OLEH</td>
                  <td class="py-4 averta-bold" style="width: 65%;">KEPADA YTH:</td>
                </tr>
                <tr
                  class="tr-wrapper"
                  v-for="(item, index) in dataModal"
                  :key="`data-history-${index}`"
                >
                  <td class="py-3 has-text-centered">{{ numberingListHistory(index) }}</td>
                  <td>
                    <div class="py-3">
                      <p class="mb-1 white-space-nowrap averta-bold">
                        {{ item.created_by }}
                      </p>
                      <p class="color-gray">{{ formatDateHistory(item.created_at) }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="label-14 note-desc-wrapper">
                      <p class="averta-bold white-space-pre-line word-wrap-break">{{ item.recipient }}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <PaginationApp
              :total-pages="totalPages"
              :current-page="currentPage"
              :isNew="true"
              :load-page="getRecipientInvoice"
              :limit-data="limitPage"
              :total-data="totalData"
            />
          </div>
          <div
            v-if="!isLoading && dataModal.length < 1"
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
          >
            <ProyekEmptyIcon class="mb-3" />
            <p class="averta-bold color-black">Tidak Ada Riwayat Perubahan</p>
          </div>
        </div>
      </template>
    </ModalTemplate>
  </div>
</template>

<script>
import moment from 'moment'
import { alertErrorMessage, numberingList } from '@/helpers'
import { ProyekEmptyIcon, HistoryIcon } from '@/components/icons'
import PaginationApp from '@/components/PaginationApp.vue'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'

export default {
  name: 'ModalHistoryCatatanProyek',
  components: {
    ModalTemplate,
    ProyekEmptyIcon,
    HistoryIcon,
    PaginationApp
  },
  data () {
    return {
      url: process.env.VUE_APP_SHELL_URL_API,
      isModalActive: false,
      widthModal: this.width,
      isLoading: false,
      dataModal: [],

      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      limitPage: 5
    }
  },
  methods: {
    closeModal () {
      this.isModalActive = false
    },
    formatDateHistory (value) {
      return moment(value).format('DD/MM/YYYY - HH:mm')
    },
    numberingListHistory (index) {
      return numberingList(this.limitPage, this.currentPage, index)
    },
    getRecipientInvoice (props = {}) {
      const { pages = 1 } = props
      this.isLoading = true

      const payload = {
        url: this.url,
        project_id: this.$route.params.projectId,
        limit: this.limitPage,
        page: pages
      }

      this.$store
        .dispatch('project/getRecipientInvoice', payload)
        .then((res) => {
          this.dataModal = res.data.data
          this.isLoading = false

          this.totalPages = res.data.total_page
          this.totalData = res.data.total_data
          this.currentPage = res.data.current_page
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    handleModalHistory () {
      this.isModalActive = !this.isModalActive
      this.getRecipientInvoice()
    }
  }
}
</script>

<style scoped>
.wrap-body-modal {
  height: 70vh;
  overflow-y: auto;
  position: relative;
}

.wrap-table {
  overflow-x: auto;
}

.table td {
  vertical-align: middle;
}

.note-desc-wrapper {
  max-height: 5em;
  overflow: auto;
  width: 100%;
}
</style>
