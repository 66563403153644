<template>
  <Skeleton v-if="isLoading" class="py-6 mb-6" />
  <div v-else class="container is-fluid pt-3 pb-6 px-6">
    <!-- Title -->
    <div class="is-flex is-justify-content-space-between pt-4">
      <div>
        <p class="averta-black label-22">Lihat Jadwal Survey dan Proyek Disini!</p>
        <p>Seluruh jadwal Survey dan pengecekan hasil pengerjaan proyek akan ditampilkan disini.</p>
      </div>
      <!-- Information -->
      <div class="card p-4 information is-flex is-align-items-center has-background-light">
        <div class="is-flex mr-6">
          <div class="label-survey mr-2"></div>
          <p class="averta-bold">Jadwal Kunjungan Survey</p>
        </div>
        <div class="is-flex">
          <div class="label-proyek mr-2"></div>
          <p class="averta-bold">Jadwal Pengecekkan Proyek</p>
        </div>
      </div>
      <!-- Information -->
    </div>
    <!-- Title -->

    <!-- Filter -->
    <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-content-center my-5 c-w-100">
      <div style="gap: 1rem" class="is-flex is-justify-content-space-left is-align-items-end c-w-100">
        <!-- Filter Date -->
        <div style="width: 25%;" class="wrapper-date">
          <b-field class="averta-black mb-1" label="Pilih Tanggal"></b-field>
          <b-datepicker
            placeholder="Pilih Tanggal"
            v-model="datesPick"
            icon="calendar-today"
            @input="validateDate"
          >
          </b-datepicker>
        </div>
        <!-- Filter Date -->

        <!-- Filter Client -->
        <div class="wrapper-client">
          <b-field class="averta-black mb-1" label="Client"></b-field>
          <b-dropdown
            aria-role="list"
            v-model="client"
            :scrollable="true"
            :max-height="450"
            class="dropdown-wrapper"
          >
            <template #trigger="{ active }">
              <b-button
                :icon-right="active ? 'menu-up' : 'menu-down'"
                class="is-flex is-justify-content-left"
              >
                <div
                  class="averta-bold is-flex is-align-items-center is-justify-content-space-between"
                  v-if="client !== null"
                >
                  <img :src="client.logo" :alt="client.name" class="wrap-image mr-4" />
                  <p>{{ client.name }}</p>
                </div>
                <p v-else>Pilih Client</p>
              </b-button>
            </template>

            <b-dropdown-item
              v-for="(dataClient, index) in listClient"
              :key="index"
              aria-role="listitem"
              :value="dataClient"
              class="wrap-list"
              @click="getListBranch('filter')"
            >
              <div class="is-flex is-align-items-center">
                <img :src="dataClient.logo" :alt="dataClient.name" class="wrap-image mr-4" />
                <p>{{ dataClient.name }}</p>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- Filter Client -->

        <!-- Filter Branch -->
        <div class="wrapper-branch">
          <b-field class="averta-black mb-1" label="Cabang"></b-field>
          <b-dropdown
            aria-role="list"
            v-model="branch"
            :scrollable="true"
            :max-height="450"
            class="dropdown-wrapper"
          >
            <template #trigger="{ active }">
              <b-button
                :icon-right="active ? 'menu-up' : 'menu-down'"
                class="is-flex is-justify-content-start"
              >
                <div
                  class="averta-bold is-flex is-align-items-center is-justify-content-space-between"
                  v-if="branch !== null"
                >
                  <p>{{ branch.name }}</p>
                </div>
                <div v-else class="is-flex">
                  <TokoIcon class="mr-3" />
                  <p>Pilih Cabang</p>
                </div>
              </b-button>
            </template>

            <b-dropdown-item
              v-for="(dataBranch, index) in listBranch"
              :key="index"
              aria-role="listitem"
              :value="dataBranch"
            >
              <p>{{ dataBranch.name }}</p>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- Filter Branch -->

        <!-- Button -->
        <button
          :disabled="this.client === null || this.branch === null || this.datesPick === null"
          @click="searchSchedule"
          class="button-filter is-flex is-align-items-center averta-bold is-clickable"
          :class="{ active: client !== null && branch !== null && datesPick.length >= 2 }"
          style="transform: translateY(-5px)"
        >
          <p class="white-space-nowrap">Tampilkan Data</p>
        </button>
        <!-- Button -->
      </div>
    </div>
    <!-- Filter -->

    <!-- Calendar and Schedule -->
    <div class="columns m-0">
      <div class="wrapper-left">
        <!-- <p class="averta-black label-18">Jadwal Proyek</p> -->
        <p v-if="data && data.survey.length > 0" class="has-text-grey">
          ~
          <span v-if="data && data.selected_date !== null">{{
            convertDate(data.selected_date)
          }}</span>
        </p>
        <!-- Kunjungan Survey -->
        <div class="mt-1">
          <p class="averta-bold label-16 mb-3">Jadwal Kunjungan Survey</p>
          <div v-if="data && data.survey.length > 0">
            <div
              v-for="(survey, index) in data.survey.slice(0, 3)"
              :key="index"
              class="card p-2 has-background-white is-flex is-justify-content-space-between is-align-items-center"
            >
              <div class="survey-wrapper is-flex">
                <div class="label-survey"></div>
                <div>
                  <p class="averta-bold">{{ survey.store }}</p>
                  <p class="has-text-grey">{{ survey.consultant_name }}</p>
                </div>
              </div>
              <div>
                <p class="averta-black">{{ survey.hours_detail }}</p>
              </div>
            </div>
            <b-collapse animation="slide" v-model="surveyCollapse">
              <div
                v-for="(survey, index) in data.survey.slice(3)"
                :key="index"
                class="card p-2 has-background-white is-flex is-justify-content-space-between is-align-items-center"
              >
                <div class="survey-wrapper is-flex">
                  <div class="label-survey"></div>
                  <div>
                    <p class="averta-bold">{{ survey.store }}</p>
                    <p class="has-text-grey">{{ survey.consultant_name }}</p>
                  </div>
                </div>
                <div>
                  <p class="averta-black">{{ survey.hours_detail }}</p>
                </div>
              </div>
            </b-collapse>
            <p
              v-if="data && data.survey.length > 3"
              @click="surveyCollapse = !surveyCollapse"
              aria-controls="collapseGroup"
              class="has-text-centered cursor-pointer averta-bold text-merah mt-4"
            >
              {{ surveyCollapse ? 'Tutup Lainnya' : 'Lihat Lainnya' }}
              <CollapseIcon :class="{ 'flip-icon': surveyCollapse === true }" />
            </p>
          </div>
          <div v-else class="has-text-centered">
            <ProyekEmptyIcon />
            <p class="averta-bold mb-2">Belum Pilih Cabang</p>
            <p class="px-3">Pilih cabang terlebih dahulu untuk menampilkan data penjadwalan</p>
          </div>
        </div>
        <!-- Kunjungan Survey -->

        <div style="border-top: 2px solid #e1e1e1" class="mt-5"></div>

        <!-- Kunjungan Proyek -->
        <div class="mt-1">
          <p class="averta-bold label-16 mb-3">Jadwal Pengecekan Proyek</p>
          <div v-if="data && data.project.length > 0">
            <div
              v-for="(project, index) in data.project.slice(0, 3)"
              :key="index"
              class="card p-2 has-background-white is-flex is-justify-content-space-between is-align-items-center"
            >
              <div class="survey-wrapper is-flex">
                <div class="label-proyek"></div>
                <div>
                  <p class="averta-bold">{{ project.store }}</p>
                  <p class="has-text-grey">{{ project.item_working + ' Item Pekerjaan' }}</p>
                </div>
              </div>
              <div>
                <p class="averta-black">{{ project.hours_detail }}</p>
              </div>
            </div>
            <b-collapse animation="slide" v-model="projectCollapse">
              <div
                v-for="(project, index) in data.project.slice(3)"
                :key="index"
                class="card p-2 has-background-white is-flex is-justify-content-space-between is-align-items-center"
              >
                <div class="survey-wrapper is-flex">
                  <div class="label-proyek"></div>
                  <div>
                    <p class="averta-bold">{{ project.store }}</p>
                    <p class="has-text-grey">{{ project.item_working + ' Item Pekerjaan' }}</p>
                  </div>
                </div>
                <div>
                  <p class="averta-black">{{ project.hours_detail }}</p>
                </div>
              </div>
            </b-collapse>
            <p
              v-if="data && data.project.length > 3"
              @click="projectCollapse = !projectCollapse"
              aria-controls="collapseGroup"
              class="has-text-centered cursor-pointer averta-bold text-merah mt-4"
            >
              {{ projectCollapse ? 'Tutup Lainnya' : 'Lihat Lainnya' }}
              <CollapseIcon :class="{ 'flip-icon': projectCollapse === true }" />
            </p>
          </div>
          <div v-else class="has-text-centered mb-5">
            <ProyekEmptyIcon />
            <p class="averta-bold mb-2">Belum Pilih Cabang</p>
            <p class="px-3">Pilih cabang terlebih dahulu untuk menampilkan data penjadwalan</p>
          </div>
        </div>
        <!-- Kunjungan Proyek -->
      </div>

      <!-- Calendar -->
      <div class="wrapper-right">
        <div class="group-date-wrapper mb-4" v-if="jadwalSchedule.length > 0">
          <div class="is-relative">
            <div class="is-flex" ref="contentJadwal">
              <div
                class="parent-date-wrapper pb-2 is-clickable"
                :class="{
                  'mr-3': indexDate + 1 === jadwalSchedule.length,
                  'ml-3': indexDate + 1 === 1
                }"
                v-for="(itemDate, indexDate) in jadwalSchedule"
                :key="`${indexDate}_date_working`"
                @click="getSelectedDate(itemDate.completeDate)"
              >
                <div class="mb-2">
                  <p class="white-space-nowrap">{{ itemDate.day }}</p>
                </div>
                <p class="averta-bold" :class="{ active: selectedDate === itemDate.completeDate }">
                  {{ itemDate.date }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <FullCalendar
          ref="fullCalendarSchedule"
          :options="calendarOptions"
          v-if="data && data.calendar.length > 0"
        />
        <div style="position: absolute; top: 10%; left: 50%; transform: translate(-50%, -10%)" v-else class="is-flex is-align-items-center is-justify-content-center ">
          <div class="has-text-centered">
            <ProyekEmptyIcon />
            <p>Tidak ada data jadwal proyek atau survey</p>
          </div>
        </div>
      </div>
      <!-- Calendar -->
    </div>
    <!-- Calendar and Schedule -->
  </div>
</template>

<script>
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/scale.css'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import FullCalendar from '@fullcalendar/vue'
import Skeleton from '@/components/skeleton/Skeleton'
import { TokoIcon, ProyekEmptyIcon, CollapseIcon } from '@/components/icons'
import moment from 'moment'
import { mapGetters } from 'vuex'
import defaultPicture from '../../../../assets/img/default-picture.jpg'

export default {
  name: 'SchedulePage',
  components: {
    Skeleton,
    TokoIcon,
    FullCalendar,
    CollapseIcon,
    ProyekEmptyIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      client: null,
      branch: null,
      surveyCollapse: false,
      projectCollapse: false,
      statisUrl: process.env.VUE_APP_URL_SHELL_FE,
      isLoading: false,
      datesPick: [],
      // datesPick: null,
      // dataForLocalStorage: {},
      dataForLocalStorage: {
        start: null,
        end: null
      },
      data: null,
      calendarOptions: {
        visibleRange: {
          start: null,
          end: null
        },
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'timeGrid',
        headerToolbar: false,
        dayHeaders: false,
        allDaySlot: false,
        navLinks: true,
        navLinkDayClick: function (date) {
          alert(moment(date).format('YYYY-MM-DD'))
        },
        eventDidMount: this.tooltipCalendar,
        events: null,
        height: 'auto',
        contentHeight: 'auto',
        width: '100%'
      },
      listClient: null,
      listBranch: null,
      selectedDate: null,
      jadwalSchedule: {}
    }
  },
  methods: {
    convertTime (data) {
      const [startTime, endTime] = data.split(' - ')

      const formattedStartTime = startTime.substring(0, 5)
      const formattedEndTime = endTime.substring(0, 5)

      return `${formattedStartTime} - ${formattedEndTime}`

      // console.log(newTimeString)
    },
    tooltipCalendar (info) {
      tippy(info.el, {
        content:
          info.event.extendedProps.type === 'survey'
            ? `
          <div class="tooltip-wrapper">
            <div class="tooltip-header p-4 mb-3">
              <p class="averta-bold is-size-5 mb-2">#ID${info.event.id} | ${info.event.title}</p>
              <p>Cabang ${info.event.extendedProps.branch}</p>
              <div class="is-flex is-align-items-center mt-2">
                <p class="label-survey" style="margin: 0 8px 0 0 !important"></p>
                <p><i>Kunjungan Survey</i></p>
              </div>
            </div>
            <div class="tooltip-body mx-3 mb-5 p-4 is-flex is-justify-content-space-between">
              <div class="is-flex is-align-items-center">
                <figure class="image is-48x48 mr-3">
                  <img class="is-rounded" src="${
                    info.event.extendedProps.worker_image
                      ? info.event.extendedProps.worker_image
                      : defaultPicture
                  }">
                </figure>
                <div>
                  <p class="text-merah averta-bold">${info.event.extendedProps.worker_name}</p>
                  <p>${info.event.extendedProps.worker_phone}</p>
                </div>
              </div>
              <div>
                <p class="tooltip-title-date has-text-right">Tanggal & Waktu Survey</p>
                <p class="averta-bold white-space-nowrap">${
                  info.event.extendedProps.schedule_detail
                } <span class="tooltip-detail-hours">(${this.convertTime(
                info.event.extendedProps.hours_detail
              )})</span></p>
              </div>
            </div>
            <div class="mx-3 wrapper-button">
              <a href="${this.statisUrl}am/project/survey/${info.event.id}/detail">
                <button class="btn-detail mb-5 p-4 is-clickable">
                  <span class="averta-bold has-text-white is-size-6">Lihat Survey</span>
                </button>
              </a>
            </div>
          </div>`
            : `
          <div class="tooltip-wrapper">
            <div class="tooltip-header p-4 mb-3">
              <p class="averta-bold is-size-5 mb-2">#ID${info.event.id} | ${info.event.title}</p>
              <p>Cabang ${info.event.extendedProps.branch}</p>
              <div class="is-flex is-align-items-center mt-2">
                <p class="label-proyek" style="margin: 0 8px 0 0 !important"></p>
                <p><i>Pengecekan Proyek</i></p>
              </div>
            </div>
            <div class="tooltip-body mx-3 mb-5 p-4 has-text-centered">
              <p class="tooltip-title-date">Tanggal Proyek Diselesaikan</p>
              <p class="averta-bold">${info.event.extendedProps.schedule_detail}</p>
            </div>
            <div class="mx-3 wrapper-button">
              <a href="${this.statisUrl}am/project/${info.event.id}/detail">
                <button class="btn-detail mb-5 p-4 is-clickable">
                  <span class="averta-bold has-text-white is-size-6">Lihat Proyek</span>
                </button>
              </a>
            </div>
          </div>`,
        allowHTML: true,
        interactive: true,
        maxWidth: 600,
        theme: 'light',
        placement: 'right',
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom', 'left', 'top']
              }
            }
          ]
        },
        appendTo: this.$refs.fullCalendarSchedule.$el,
        zIndex: 1060
      })
    },
    validateDate (selectedDate) {
      if (selectedDate) {
        // Set the start date
        const startDate = moment(selectedDate)
        // Automatically set the end date to 6 days after the start date (for a 7-day range)
        const endDate = startDate.clone().add(6, 'days')

        // Update the date picker model to show the 7-day range
        this.datesPick = [startDate.toDate(), endDate.toDate()]

        // Store the dates in the desired format
        this.dataForLocalStorage.start = startDate.format('YYYY-MM-DD')
        this.dataForLocalStorage.end = endDate.format('YYYY-MM-DD')
      }
    },
    searchSchedule () {
      if (this.client === null || this.branch === null || this.datesPick.length < 2) {
        this.$buefy.toast.open({
          message: '<p class="averta-regular">Harap masukan filter dengan benar</p>',
          type: 'is-danger'
        })
      } else {
        this.dataForLocalStorage.client = this.client
        this.dataForLocalStorage.branch = this.branch
        if (!this.dataForLocalStorage.start || !this.dataForLocalStorage.end) {
          const a = moment(this.datesPick[0])
          const b = moment(this.datesPick[1])
          this.dataForLocalStorage.start = a.format('YYYY-MM-DD')
          this.dataForLocalStorage.end = b.add(1, 'days').format('YYYY-MM-DD')
        }
        localStorage.setItem('date_pick_schedule_shell', JSON.stringify(this.dataForLocalStorage))
        window.location.reload()
      }
    },
    getListVendorFilter () {
      const payload = {
        url: this.url
      }

      this.$store
        .dispatch('client/getListVendorFilter', payload)
        .then((response) => {
          this.listClient = response.data.data
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: '<p class="averta-regular">Gagal memuat list client</p>',
            type: 'is-danger'
          })
        })
    },
    getListBranch (type) {
      if (type === 'filter') {
        this.branch = null
      }

      const payload = {
        url: this.url,
        client_id: this.client.id
      }

      this.$store
        .dispatch('client/getListBranchFilter', payload)
        .then((response) => {
          this.listBranch = response.data.data
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: '<p class="averta-regular">Gagal memuat list cabang</p>',
            type: 'is-danger'
          })
        })
    },
    getDataSchedule (startDate, endDate, selectedDate) {
      const payload = {
        url: this.url,
        branch_id: this.branch.id,
        start_date: startDate,
        end_date: endDate,
        selected_date: selectedDate || null
      }

      this.$store
        .dispatch('client/getListSchedule', payload)
        .then((response) => {
          this.data = response.data.data
          this.calendarOptions.events = response.data.data.calendar
          this.selectedDate = response.data.data.selected_date
          this.customTanggal()
          localStorage.removeItem('date_pick_schedule_shell')
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: '<p class="averta-regular">Gagal memuat list schedule</p>',
            type: 'is-danger'
          })
        })
    },
    getSelectedDate (selectedDate) {
      const payload = {
        url: this.url,
        branch_id: this.branch.id,
        start_date: this.dataForLocalStorage.start,
        end_date: this.dataForLocalStorage.end,
        selected_date: selectedDate || null
      }

      this.$store
        .dispatch('client/getListSchedule', payload)
        .then((response) => {
          this.data.survey = response.data.data.survey
          this.data.project = response.data.data.project
          this.data.selected_date = response.data.data.selected_date
          this.selectedDate = response.data.data.selected_date
        })
        .catch((error) => {
          console.log(error)
          this.$buefy.toast.open({
            message: '<p class="averta-regular">Gagal memuat schedule</p>',
            type: 'is-danger'
          })
        })
    },
    customTanggal () {
      moment.locale('id')
      const arr = []
      const defaultFormat = 'YYYY-MM-DD'
      const startDate = moment(this.dataForLocalStorage.start, defaultFormat)
      const endDate = moment(this.dataForLocalStorage.end).add(-1, 'days').format(defaultFormat)

      const formatDate = startDate.clone()

      while (formatDate.isSameOrBefore(endDate)) {
        const completeDate = formatDate.format(defaultFormat)
        const date = formatDate.format('DD')
        const day = formatDate.format('ddd')

        const tempDate = {
          completeDate,
          date,
          day
        }

        arr.push(tempDate)
        // Increment the current date by one day
        formatDate.add(1, 'days')
      }
      this.jadwalSchedule = arr
    },
    convertDate (date) {
      if (date !== null) {
        return moment(date).format('DD MMMM YYYY')
      }
      return ''
    }
  },
  created () {
    this.getListVendorFilter()

    const initialDate = JSON.parse(localStorage.getItem('date_pick_schedule_shell'))

    if (initialDate) {
      this.dataForLocalStorage = initialDate
      this.calendarOptions.visibleRange.start = initialDate.start
      this.calendarOptions.visibleRange.end = initialDate.end
      this.datesPick = [
        new Date(initialDate.start),
        new Date(moment(initialDate.end).add(-1, 'days'))
      ]
      this.client = initialDate.client
      this.branch = initialDate.branch
      this.getListBranch()
      this.getDataSchedule(
        moment(initialDate.start).format('YYYY-MM-DD'),
        moment(initialDate.end).add(-1, 'days').format('YYYY-MM-DD')
      )
    } else {
      this.datesPick = [new Date(), new Date(moment().add(6, 'days'))]
    }
  },
  beforeRouteLeave (to, from, next) {
    localStorage.removeItem('date_pick_schedule_shell')
    next()
  }
}
</script>

<style scoped>
.flip-icon {
  transform: rotate(-180deg);
}

.wrapper-right{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 75%;
  min-width: auto;
  max-width: 75%;
  height: auto;
  min-height: auto;
  max-height: auto;
  gap: 1rem;
  margin: 0;
  padding: 1rem;
}

.wrapper-left {
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: auto;
  max-width: 25%;
  height: auto;
  min-height: auto;
  max-height: auto;
  border-radius: 12px;
  gap: 1rem;
  margin: 0;
  padding: 1rem;
  background: #fafafa;
}

.wrapper-client,
.wrapper-branch {
  white-space: normal;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}

.wrapper-client button,
.wrapper-branch button {
  width: 300px;
  min-width: auto;
  max-width: 300px;
  overflow-x: auto;
  overflow-y: hidden;
  height: 50px;
}

.button-filter {
  background: #9a9a9a;
  border: none;
  color: white;
  border-radius: 100px;
  height: 30px;
  width: 160px;
  padding: 20px 30px;
}

.button-filter.active {
  background: var(
    --gradient-red-red-primary-button,
    linear-gradient(90deg, #d9272d 0%, #eb4600 99.99%)
  );
}

.information {
  border: 1px solid #e1e1e1;
}

.wrap-list {
  padding: 14px 12px;
  white-space: normal;
  width: 23rem;
}

.wrap-list:not(:last-child) {
  border-bottom: 1px #eaeaea solid;
}

.wrap-image {
  width: 130px;
  height: 28px;
  object-fit: contain;
}

.group-date-wrapper {
  margin-left: 70px;
}

.parent-date-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-right: 7em;
}

.parent-date-wrapper .active {
  color: white;
  background: #3185fc;
  padding: 5px 8px;
  border-radius: 100px;
}

.dropdown-wrapper {
  border-radius: 6px;
  border: 1px solid #e1e1e1;
}

@media only screen and (max-width: 1366px) {
  .parent-date-wrapper {
    margin-right: 5.8em;
  }
}

@media only screen and (max-width: 1280px) {
  .parent-date-wrapper {
    margin-right: 5.2em;
  }
}
</style>

<style>
.fc-scrollgrid-sync-inner {
  /* padding: 12px 24px; */
  text-align: center;
}

.label-survey {
  background-color: #3185fc;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  margin-top: 6px;
  margin-right: 8px;
}

.label-proyek {
  background-color: #10bd41;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  margin-top: 6px;
  margin-right: 8px;
}

.datepicker .dropdown-content {
  border-radius: 5px !important;
}

.wrapper-date .datepicker .dropdown .input {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  border: 1px solid #e1e1e1;
  padding-left: 40px;
  font-size: 14px;
  color: #000;
}
</style>
