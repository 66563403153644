<template>
    <div class="wrapper-detail-penawaran px-sm-0" v-if="dataDetailPenawaranRejected">

        <!-- Wrap Tab Area and Component -->
        <div class="is-flex" :class="{'wrap-area-purchasing-modal' : isDataInModal, 'wrap-area': !isDataInModal}">
            <div class="px-2 pt-5 wrap-inner-area c-w-100">
              <p class="averta-bold mb-3 label-18">Area Perbaikan</p>
              <div class="wrap-buttons white-space-nowrap my-3 is-flex">
                  <b-button v-for="tab, index in dataDetailPenawaranRejected.tab" :key="`${index}-tab`" class="mr-2 button-tabs flex-shrink-0 my-1 label-14" :class="{ 'active': currentArea === tab.area_name }" @click="changeButtonArea(tab.area_name)">
                      {{ `${tab.area_name} (${tab.components.length})` }}
                  </b-button>
              </div>
              <div class="wrap-component white-space-nowrap is-flex" v-if="dataComponent">
                  <p v-for="component, index in dataComponent.components" :key="`${index}-component`" class="p-3 text-grey component is-clickable label-14" :class="{ 'active': currentComponent === component.component_name }" @click="changeComponent(component)">
                      {{ component.component_name }}
                  </p>
              </div>
            </div>
        </div>
        <!-- Wrap Tab Area and Component -->

        <div class="mt-3">
            <div v-for="data, index in dataDetailPenawaranRejected.list" :key="`${index}-detail`" class="mb-5">

                <!-- Area dan Component -->
                <div class="is-flex averta-bold is-align-items-center" :id="`${data.area_name}-${data.component_id}${isDataInModal === true ? '-modal' : ''}`">
                    <p class="pr-3 label-16">{{ data.area_name }}</p>
                    <p class="separator label-16 text-blue">|</p>
                    <p class="pl-3 label-16 text-blue">{{ data.component_name }}</p>
                </div>
                <!-- Area dan Component -->

                <!-- Detail Component dan Note -->
                <div class="my-3">
                    <p class="averta-bold label-16">&#8226; {{ data.detail_component_name }}</p>
                    <p class="label-note label-14 p-3"><span class="text-grey averta-bold">Catatan : </span>{{ data.detail_component_note || '-' }}</p>
                </div>
                <!-- Detail Component dan Note -->

                <!-- Wrapper Item Pekerjaan -->
                <div class="wrap-item-pekerjaan mb-3 p-3" v-for="item, indexItem in data.item_components" :key="`${indexItem}-item-pekerjaan`">

                    <!-- Item Pekerjaan, Volume Satuan, dan Image Survey -->
                    <div class="is-flex is-justify-content-space-between is-align-items-center mb-4 flex-sm-column align-items-sm-start">
                        <div class="mb-sm-2">
                            <div class="mb-2">
                                <p class="label-12 text-grey">Item Pekerjaan</p>
                                <p class="averta-bold label-14">{{ item.note_item_working }}</p>
                            </div>
                            <div v-if="item.images && item.images.length > 0" class="is-flex">
                                <div v-for="itemImage, indexImage in item.images" :key="`${indexImage}-image`" @click="openModalPreviewImage(item.images)" class="wrapper-preview-image cursor-pointer is-relative mr-3">
                                    <img :src="itemImage" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="is-flex p-4 wrap-volume-satuan c-w-sm-100 flex-sm-column" v-if="isBS">
                            <div class="pr-5 border-sm-none" :class="{'border-right': item.unit_price !== 0 && item.unit_price !== null}">
                                <p class="text-grey lable-14">Volume Area Pengerjaan</p>
                                <p class="averta-bold label-16">{{ `${item.volume} ${item.unit_name}` }}</p>
                            </div>
                            <div class="border-right px-4 border-sm-none mt-sm-1 px-sm-0" v-if="item.unit_price !== null">
                                <p class="text-grey lable-14">Harga Satuan</p>
                                <p class="averta-bold label-16">{{ formatThousand(item.unit_price) }}</p>
                            </div>
                            <div class="pl-4 mt-sm-1 px-sm-0" v-if="item.total_price !== null">
                                <p class="text-grey lable-14">Total Harga</p>
                                <p class="averta-bold label-16">{{ formatThousand(item.total_price) }}</p>
                            </div>
                        </div>
                        <div class="wrap-volume-satuan is-flex p-4" v-else>
                            <div class="wrap-volume border-right pr-5">
                                <p class="text-grey lable-14">Volume Area Pengerjaan</p>
                                <p class="averta-bold label-16">{{ item.volume }}</p>
                            </div>
                            <div class="wrap-satuan pl-4">
                                <p class="text-grey lable-14">Satuan</p>
                                <p class="averta-bold label-16">{{ item.unit_name }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- Item Pekerjaan dan Volume Satuan -->

                    <!-- Daftar Keahlian Tukang -->
                    <div class="wrap-keahlian-tukang p-4 mb-4">
                        <p class="averta-bold label-14 mb-3">Daftar Keahlian Tukang</p>
                        <div class="columns wrap-item-tukang py-3" v-for="worker, indexWorker in item.workers" :key="`${indexWorker}-tukang`">
                            <div class="column is-4">
                                <p class="label-14 text-grey mb-2">Keahlian Tukang</p>
                                <div class="is-flex">
                                    <img :src="worker.skill_image || require('@/assets/img/empty-img-2.png')" class="skill-img">
                                    <p class="averta-bold label-16">{{ worker.skill_name }}</p>
                                </div>
                            </div>
                            <div class="column">
                                <p class="label-14 text-grey">Maksimum Hari Kerja</p>
                                <p class="averta-bold label-16">{{ worker.duration }} Hari</p>
                            </div>
                            <div class="column">
                                <p class="label-14 text-grey">Jumlah Tukang</p>
                                <p class="averta-bold label-16">{{ worker.total_worker }}</p>
                            </div>
                            <div class="column" v-if="isBS && worker.unit_price >= 0 && worker.unit_price !== null">
                                <p class="label-14 text-grey">Harga Tukang/Hari</p>
                                <p class="averta-bold label-16">{{ formatThousand(worker.unit_price) }}</p>
                            </div>
                            <div class="column" v-if="isBS && worker.total_price >= 0 && worker.total_price !== null">
                                <p class="label-14 text-grey">Harga Total</p>
                                <p class="averta-bold label-16">{{ formatThousand(worker.total_price) }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- Daftar Keahlian Tukang -->

                    <!-- Daftar Material -->
                    <div class="wrap-material p-4">
                      <p class="averta-bold label-14 mb-3">Daftar Material</p>
                      <div v-for="material, indexMaterial in item.materials" :key="`${indexMaterial}-material`" class="wrap-item-material py-3">
                        <div class="columns">
                            <div class="column is-5">
                                <p class="label-14 text-grey mb-2">Nama Material</p>
                                <div class="is-flex is-align-items-center flex-sm-column align-items-sm-start">
                                    <p class="averta-bold label-16 mb-1 mr-2">{{ material.product_name }}</p>
                                    <!-- <p v-if="material.is_hide === 1" class="material-hidden label-12 averta-bold mr-2">Disembunyikan</p> -->
                                    <p v-if="material.is_new === 1" class="material-new label-12 averta-bold white-space-nowrap">Material Baru</p>
                                </div>
                                <p v-if="material.is_custom === 1" class="material-custom">Material Custom</p>
                            </div>
                            <div class="column">
                                <p class="label-14 text-grey">Qty Actual</p>
                                <p class="averta-bold label-16">{{ material.total_required }} {{ material.unit_name }}</p>
                            </div>
                            <div class="column">
                                <p class="label-14 text-grey">Qty Estimasi</p>
                                <p class="averta-bold label-16">{{ material.total_offer }} {{ material.unit_name }}</p>
                            </div>
                            <div class="column" v-if="isBS && material.unit_price >= 0 && material.unit_price !== null">
                                <p class="label-14 text-grey">Harga Satuan</p>
                                <p class="averta-bold label-16">{{ formatThousand(material.unit_price) }}</p>
                            </div>
                            <div class="column" v-if="isBS && material.total_price >= 0 && material.total_price !== null">
                                <p class="label-14 text-grey">Harga Total</p>
                                <p class="averta-bold label-16">{{ formatThousand(material.total_price) }}</p>
                            </div>
                        </div>
                        <div class="wrap-note" v-if="material.note">
                          <p class="label-14 text-grey mb-2">Catatan</p>
                          <p class="label-16 averta-bold">
                            {{ material.note }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- Daftar Material -->

                </div>
                <!-- Wrapper Item Pekerjaan -->
            </div>
        </div>

        <!-- Modal Preview Image -->
        <ModalPreviewImage :isActive="modalPreviewImageActive" :dataImage="imagePreviewCarousel" title="" @closeModal="closeModalPreviewImage()" />
        <!-- Modal Preview Image -->

        <!-- Table of Content -->
        <div class="table-of-content box" v-if="floatingMenuActive">
          <div class="mb-3">
            <p class="label-18 averta-bold">Table of Content(s)</p>
            <p class="label-14 text-merah mt-1 is-clickable" @click="scrollTop">Kembali ke Atas &#11105;</p>
          </div>
          <div class="wrap-content-floating">
            <div class="wrap-area-floating mb-3 p-3" v-for="floating, indexFloating in dataDetailPenawaranRejected.tab" :key="`${indexFloating}-floating-area`">
              <p class="averta-bold label-16 mb-2">{{ floating.area_name }}</p>
              <div class="has-text-info ml-2">
                <div class="is-clickable is-flex is-justify-content-space-between is-align-items-center mb-1" v-for="floatingComponent, indexFloatingComponent in floating.components" :key="`${indexFloatingComponent}`" @click="changeComponentFloating(floating.area_name, floatingComponent)">
                  <p class="label-16"><span class="mr-1">•</span> {{ floatingComponent.component_name }}</p>
                  <p class="label-16">&#62;</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Table of Content -->

        <!-- Floating Table of Content -->
        <div class="floating-menu is-clickable" @click="floatingMenu">
          <b-icon icon="menu" size="is-medium" v-if="!floatingMenuActive"></b-icon>
          <b-icon icon="window-close" size="is-medium" v-else></b-icon>
        </div>
        <!-- Floating Table of Content -->
    </div>
    <div v-else class="is-flex is-justify-content-center">
      <div class="has-text-centered">
        <ProyekEmptyIcon />
        <p class="averta-bold">Tidak ada Riwayat Penawaran</p>
      </div>
    </div>
</template>

<script>
import moment from 'moment'
import { formatCurrency } from '@/helpers'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import ProyekEmptyIcon from '@/components/icons/ProyekEmptyIcon'

export default {
  name: 'DetailPenawaranRejected',
  props: {
    dataPenawaranRejected: {
      type: Object,
      default: null
    },
    isBS: {
      type: Boolean,
      default: false
    },
    isDataInModal: {
      type: Boolean,
      default: false
    },
    isCalledButtonArea: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ModalPreviewImage,
    ProyekEmptyIcon
  },
  data () {
    return {
      dataDetailPenawaranRejected: null,
      currentArea: null,
      dataComponent: null,
      currentComponent: null,
      imagePreviewCarousel: [],
      modalPreviewImageActive: false,
      floatingMenuActive: false
    }
  },
  methods: {
    scrollTo (area, idComponent) {
      const element = document.getElementById(`${area}-${idComponent}${this.isDataInModal === true ? '-modal' : ''}`)
      if (element) {
        const headerOffset = 265
        const elementPosition = element.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset

        if (this.isDataInModal) {
          element.scrollIntoView({
            behavior: 'smooth'
          })
        } else {
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          })
        }
      }
    },
    changeButtonArea (area) {
      this.currentArea = area

      const dataComponent = this.dataDetailPenawaranRejected.tab.find(item => item.area_name === area)
      this.dataComponent = dataComponent
      this.currentComponent = dataComponent.components[0].component_name

      const idComponent = dataComponent.components[0].component_id

      this.scrollTo(area, idComponent)
    },
    changeComponent (component) {
      this.currentComponent = component.component_name

      const idComponent = component.component_id

      this.scrollTo(this.currentArea, idComponent)
    },
    changeComponentFloating (areaName, component) {
      this.currentArea = areaName
      this.currentComponent = component.component_name

      const dataComponent = this.dataDetailPenawaranRejected.tab.find(item => item.area_name === areaName)
      this.dataComponent = dataComponent

      const idComponent = component.component_id

      this.scrollTo(areaName, idComponent)
    },
    scrollTop () {
      this.changeButtonArea(this.dataDetailPenawaranRejected.tab[0].area_name)
    },
    formatThousand (amount) {
      return formatCurrency(amount)
    },
    openModalPreviewImage (data) {
      this.imagePreviewCarousel = data
      this.modalPreviewImageActive = true
    },
    closeModalPreviewImage () {
      this.imagePreviewCarousel = null
      this.modalPreviewImageActive = false
    },
    convertDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    floatingMenu () {
      this.floatingMenuActive = !this.floatingMenuActive
    }
  },
  created () {
    if (this.dataPenawaranRejected) {
      this.dataDetailPenawaranRejected = this.dataPenawaranRejected.area_component
      this.changeButtonArea(this.dataPenawaranRejected.area_component.tab[0].area_name)
    }
  },
  watch: {
    dataPenawaranRejected (newVal) {
      this.dataDetailPenawaranRejected = newVal.area_component
      if (this.isCalledButtonArea) {
        this.changeButtonArea(newVal.area_component.tab[0].area_name)
      }
    }
  }
}

</script>

<style scoped>

.wrapper-detail-penawaran {
  width: 100%;
}

.wrap-area {
  background: #fafafa;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 1;
  width: auto;
  margin: 0px -30px;
  padding: 0px 14px;
}

.wrap-area-purchasing-modal {
  background: #fafafa;
  position: -webkit-sticky;
  position: sticky;
  top: -10px;
  z-index: 1;
  width: auto;
  margin: 0px -24px;
  padding: 0px 14px;
}

.wrap-buttons {
    overflow-x: auto;
}

.button-tabs {
    height: auto;
    border-radius: 100px;
    color: #9a9a9a;
    border: 1px solid #e1e1e1;
    overflow-x: auto;
}

.button-tabs.active {
    background: rgba(49, 133, 252, 0.1);
    color: #3185fc;
    border: 1px solid #3185fc;
    font-family: 'Averta-Bold';
}

.wrap-component {
    overflow-x: auto;
}

.component {
    border-bottom: 1px solid #e1e1e1;
}

.component.active {
    color: #3185fc;
    border-bottom: 1px solid #3185fc;
    font-family: 'Averta-Bold';
}

.text-blue {
    color: #3185fc;
}

.label-note {
    background: #fafafa;
    border-radius: 12px;
}

.wrap-item-pekerjaan {
    background: #fafafa;
    border-radius: 12px;
}

.wrap-volume-satuan {
    background: white;
    border-radius: 12px;
}

.border-right {
    border-right: 2px solid #e1e1e1;
}

.wrap-keahlian-tukang, .wrap-material {
    background: white;
    border-radius: 12px;
}

.wrap-item-tukang:not(:last-child),
.wrap-item-material:not(:last-child),
.wrap-item-variant:not(:last-child) {
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 12px;
}

.skill-img {
  width: 34px;
  height: 34px;
  margin-right: 10px;
  object-fit: contain;
}

.material-hidden {
  background: #3185FC;
  font-family: 'Averta';
  font-style: italic;
  color: white;
  padding: 0px 10px 4px 8px;
  border-radius: 14px;
}

.material-custom {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-style: italic;
}

.material-new {
  background: #911A1E;
  font-family: 'Averta';
  font-style: italic;
  color: white;
  padding: 0px 10px 4px 10px;
  border-radius: 14px;
}

.wrap-note {
  border: 2px solid #E1E1E1;
  padding: 12px;
  border-radius: 12px;
}

.floating-menu {
  position: fixed;
  bottom: 80px;
  right: 100px;
  background: var(--gradient-red-red-primary-button, linear-gradient(116deg, #D9272D 16.15%, #EB4600 83.24%));
  padding: 20px;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.table-of-content {
  position: fixed;
  bottom: 70px;
  right: 180px;
  background: white;
  width: 347px;
  height: 285px;
  padding: 15px;
  z-index: 2;
}

.wrap-content-floating {
  overflow-y: auto;
  height: 190px;
}

.wrap-area-floating {
  background: #FAFAFAFA;
  border-radius: 12px;
}

.wrap-area-floating ul{
  list-style: disc;
}

@media only screen and (max-width: 768px) {
  .wrap-area {
    top: 0px;
  }
}

@media only screen and (max-width: 600px) {

  .table-of-content {
    right: 50px;
    bottom: 0;
    transform: scale(0.9);
  }

  .floating-menu {
    transform: scale(0.6);
    right: 0px;
    bottom: 20px;
  }

}
</style>
