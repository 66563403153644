<template>
  <div>
    <ButtonApp
      :isDisabled="adminTools && !adminTools.can_reschedule"
      :isSecondary="true"
      :isTransparent="true"
      @click="openModalReschedule"
    >
      <div>
        <p class="averta-bold py-1">Reschedule</p>
      </div>
    </ButtonApp>

    <ModalTemplate
      :showModal="modalReschedule"
      :width="widthModal"
      :overflowVisible="true"
      @closeModal="closeModalReschedule"
    >
      <template v-slot:headerModal>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="flex-center">
            <ClockCircle color="#fff" />
            <p class="averta-bold label-18 ml-2">Reschedule - Pilih Tanggal Pengerjaan</p>
          </div>
          <div class="is-clickable" @click="closeModalReschedule">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div class="bg-white wrap-body-modal">
          <div class="p-4 form-body-modal-reschedule">
            <div class="mb-4">
              <p class="averta-bold mb-2">Proyek Mulai <span class="color-red">*</span></p>
              <div
                ref="refDateSchedule"
                class="is-relative c-w-55 is-clickable"
                @click="handleOpenCalender"
              >
                <div class="input label-14 input-search-1 bg-white">
                  <p v-if="!startReschedule">Pilih Tanggal Mulai</p>
                  <p v-else>{{ startReschedule }}</p>
                </div>
                <div class="icon-calender-input">
                  <CalendarIcon color="#000" />
                </div>
              </div>
              <div
                v-if="openCalenderReschedule"
                class="calender-reschedule"
                v-closable2="{
                  exclude: ['refDateSchedule'],
                  handler: 'closeCalender'
                }"
              >
                <div class="v-calendar-wrapper-normal p-2">
                  <v-date-picker
                    v-model="selectedDateReschedule"
                    is-expanded
                    color="red"
                    :min-date="minDateReschedule"
                    :is-range="isRange"
                    :from-page="fromPage"
                    @input="handleDateChange"
                  />
                </div>
                <div class="border-top-1 p-3 flex-center-vertical">
                  <div class="flex-1 mr-3">
                    <ButtonApp width="100%" :isSecondary="true" @click="handleResetDateSchedule">
                      <p class="py-1 averta-bold">Reset Filter</p>
                    </ButtonApp>
                  </div>
                  <div class="flex-1">
                    <ButtonApp width="100%" @click="handleSelectedDateSchedule">
                      <p class="py-1 averta-bold">Terapkan</p>
                    </ButtonApp>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p class="averta-bold mb-2">Proyek Selesai <span class="color-red">*</span></p>
              <div class="is-relative c-w-55">
                <div class="input label-14 input-search-1 bg-lightgray">
                  <p v-if="!endReschedule" class="color-red is-italic">Belum pilih tanggal mulai</p>
                  <p v-if="endReschedule" class="averta-bold">{{ endReschedule }}</p>
                </div>
                <div class="icon-calender-input">
                  <CalendarIcon color="#000" />
                </div>
              </div>
            </div>
          </div>
          <div class="footer-modal-reshcedule p-4">
            <ButtonApp @click="handleSubmitReschedule" :isDisabled="!startReschedule">
              <div class="flex-center py-3 px-5">
                <p class="color-white mr-4 averta-bold">Proses</p>
                <ArrowRightIconLong />
              </div>
            </ButtonApp>
          </div>
        </div>
      </template>
    </ModalTemplate>

    <!-- Modal Confirm Reschedule -->
    <ConfirmExtendReschedule
      typeModal="reschedule"
      :showModal="modalConfirm"
      :dateProject="selectedDateReschedule"
      @closeModal="closeModalConfirm"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { ArrowRightIconLong, CalendarIcon, ClockCircle } from '@/components/icons'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ConfirmExtendReschedule from './ConfirmExtendReschedule.vue'

export default {
  name: 'RescheduleProyek',
  components: {
    ArrowRightIconLong,
    CalendarIcon,
    ClockCircle,
    ButtonApp,
    ModalTemplate,
    ConfirmExtendReschedule
  },
  computed: {
    ...mapGetters({
      adminTools: 'projectV2/getAdminTools',
      detailProject: 'projectV2/getDataTimelineProject'
    }),
    startReschedule () {
      if (!this.resultSelectedReschedule) return null
      return moment(this.resultSelectedReschedule.start).format('DD MMMM YYYY')
    },
    endReschedule () {
      if (!this.resultSelectedReschedule) return null
      return moment(this.resultSelectedReschedule.end).format('DD MMMM YYYY')
    },
    minDateReschedule () {
      return new Date()
    }
  },
  data () {
    return {
      widthModal: '600px',
      modalReschedule: false,
      modalConfirm: false,
      selectedDateReschedule: null,
      resultSelectedReschedule: null,
      isRange: false,
      fromPage: null,
      openCalenderReschedule: false
    }
  },
  methods: {
    openModalReschedule () {
      this.modalReschedule = !this.modalReschedule
    },
    closeModalReschedule () {
      this.modalReschedule = false
      this.isRange = false
      this.fromPage = null
      this.resultSelectedReschedule = null
      this.openCalenderReschedule = false
      this.selectedDateReschedule = null
    },
    handleSubmitReschedule () {
      this.modalConfirm = !this.modalConfirm
    },
    closeModalConfirm (props) {
      this.modalConfirm = false

      if (props && props.type === 'close-all') {
        this.closeModalReschedule()
      }
    },
    handleDateChange (val) {
      const start = new Date(val)
      const end = moment(start)
        .add(this.detailProject.card.duration - 1, 'days')
        .toDate()

      this.isRange = true
      setTimeout(() => {
        this.selectedDateReschedule = {
          start,
          end
        }
        setTimeout(() => {
          this.isRange = false
        }, 50)
      }, 50)
    },
    handleOpenCalender () {
      this.openCalenderReschedule = !this.openCalenderReschedule
    },
    closeCalender () {
      this.openCalenderReschedule = false
    },
    handleSelectedDateSchedule () {
      this.resultSelectedReschedule = this.selectedDateReschedule
      this.closeCalender()
    },
    handleResetDateSchedule () {
      this.resultSelectedReschedule = null
      this.selectedDateReschedule = null
      this.closeCalender()
    }
  },
  watch: {
    openCalenderReschedule (val) {
      if (val) {
        this.isRange = true

        setTimeout(() => {
          this.isRange = false
        }, 50)
      }
    }
  }
}
</script>

<style scoped>
.wrap-body-modal {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.form-body-modal-reschedule {
  height: 250px;
}

.footer-modal-reshcedule {
  box-shadow: 0px -3px 30px 0px #00000012;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icon-calender-input {
  position: absolute;
  right: 10px;
  top: 8px;
}

.calender-reschedule {
  border-radius: 20px;
  box-shadow: 6px 7px 50px 0px #00000014;
  position: absolute;
  z-index: 2;
  background: white;
}

.v-calendar-wrapper-normal {
  width: 300px;
  height: 280px;
  overflow: hidden;
}
</style>

<style>
.v-calendar-wrapper-normal .vc-container {
  border: none !important;
  font-family: 'Averta' !important;
}

.v-calendar-wrapper-normal .vc-grid-cell {
  margin-bottom: 10px !important;
}

.v-calendar-wrapper-normal .vc-day-content {
  height: 25px !important;
  width: 25px !important;
  font-size: 14px !important;
}

.v-calendar-wrapper-normal .vc-weekday {
  font-size: 14px !important;
}

.v-calendar-wrapper-normal .vc-highlight.vc-highlight-base-start {
  height: 100% !important;
}

.v-calendar-wrapper-normal .vc-highlight.vc-highlight-base-end {
  height: 100% !important;
}

.v-calendar-wrapper-normal .vc-highlight.vc-highlight-base-middle {
  height: 100% !important;
  width: 100% !important;
}

.v-calendar-wrapper-normal .vc-highlight {
  width: 27px !important;
  height: 27px !important;
}
</style>
