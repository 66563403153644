<template>
  <div>
    <ModalConfirm
      :showModal="showModal"
      imageProps="delete-area-component.png"
      titleProps="Simpan Informasi"
    >
      <template v-slot:additionalDesc>
        <p v-if="typeModal === 'extend'">
          Proyek diperpanjang hingga
          <span class="color-blue-1 averta-bold">{{ durationFormated }}</span>
        </p>
        <div
          v-else-if="typeModal === 'reschedule'"
          class="flex-center is-flex-direction-column"
        >
          <p>Proyek akan reschedule ke tanggal</p>
          <span class="color-blue-1 averta-bold">{{ dateFormated }}</span>
        </div>
      </template>
      <template v-slot:button>
        <div class="c-w-100">
          <div v-if="typeModal === 'reschedule' && showConfirmReschedule" class="flex-center-vertical mb-2">
            <div class="flex-shrink-0">
              <NotificationIcon color="#E10009" width="20" height="20" />
            </div>
            <p class="label-12 has-text-left ml-2"><span class="color-red averta-bold">*</span><span class="averta-bold">Status proyek</span> akan berubah sesuai tanggal reschedule pengerjaan. <span class="averta-bold color-red">Pastikan periksa status proyek!</span></p>
          </div>
          <div class="flex-center">
            <ButtonApp
              width="100%"
              @click="closeModal"
              :isSecondary="true"
              :isTransparent="true"
            >
              <div>
                <p class="averta-bold py-2">Cek Kembali</p>
              </div>
            </ButtonApp>
            <ButtonApp width="100%" class="ml-3" @click="handleSubmit" :isDisabled="isLoadingSpinner">
              <div>
                <p class="averta-bold py-2">Simpan</p>
              </div>
            </ButtonApp>
          </div>
        </div>
      </template>
    </ModalConfirm>

    <ModalConfirm
      :showModal="modalFailedReschedule"
      imageProps="sunset.png"
      imageClass="mb-0"
      titleProps="Tidak Dapat Reschedule"
      descProps="Terdapat tukang yang sudah melakukan absen dan foto sebelum"
      descClass="mb-0"
    >
      <template v-slot:button>
        <div class="c-w-100">
          <div class="m-auto has-text-left">
            <ErrorComponent title="Hapus slot tukang terlebih dahulu untuk melanjutkan proses" />
          </div>
          <div class="flex-center-vertical c-w-100 mt-3">
            <ButtonApp width="100%" class="ml-3" @click="handleCloseModalFailed">
              <div>
                <p class="averta-bold py-2">Kembali</p>
              </div>
            </ButtonApp>
          </div>
        </div>
      </template>
    </ModalConfirm>

    <ModalConfirm
      :showModal="modalSuccess"
      imageProps="reset-password.png"
      :titleProps="typeModal === 'extend' ? 'Durasi Proyek Berhasil Ditambahkan' : 'Reschedule Proyek Berhasil Disimpan'"
      imageClass="mb-4 mt-3"
    >
      <template v-slot:additionalDesc>
        <p v-if="typeModal === 'extend'">
          Proyek diperpanjang hingga <span class="color-blue-1 averta-bold">{{durationFormated}}</span>
        </p>
      </template>
      <template v-slot:additional>
        <div class="c-w-100" :class="{ 'mb-4': typeModal === 'extend', 'mb-5': typeModal === 'reschedule' }">
          <DurationStartEnd
            :titleFrom="durationProps.titleFrom"
            :titleEnd="durationProps.titleEnd"
            :from="durationProps.from"
            :end="durationProps.end"
          />
        </div>
      </template>
      <template v-slot:button>
        <div class="c-w-100">
          <div v-if="typeModal === 'extend'" class="flex-center-vertical mb-2">
            <div class="flex-shrink-0">
              <NotificationIcon color="#E10009" width="20" height="20" />
            </div>
            <p class="label-12 has-text-left ml-2"><span class="color-red averta-bold">*</span><span class="averta-bold">Durasi proyek</span> yang ditambahkan <span class="averta-bold">belum memiliki keahlian,</span> Silakan tambahkan keahlian untuk mengisi slot.</p>
          </div>
          <div class="flex-center">
            <ButtonApp width="100%" @click="closeModalSuccess" :isSecondary="true" :isTransparent="true">
              <div>
                <p class="averta-bold py-2">Tutup</p>
              </div>
            </ButtonApp>
            <ButtonApp v-if="typeModal === 'extend'" width="100%" class="ml-3" @click="handleAddSkill">
              <div>
                <p class="averta-bold py-2">Tambah Keahlian</p>
              </div>
            </ButtonApp>
          </div>
        </div>
      </template>
    </ModalConfirm>

    <b-loading :is-full-page="true" v-model="isLoadingSpinner" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { alertErrorMessage } from '@/helpers'
import { SpinnerIcon, NotificationIcon } from '@/components/icons'
import ModalConfirm from '../../../../am/vendor/components/ModalConfirm.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ErrorComponent from '@/components/ErrorComponent.vue'
import DurationStartEnd from '../DurationStartEnd.vue'

export default {
  name: 'ConfirmExtendReschedule',
  components: {
    SpinnerIcon,
    NotificationIcon,

    ButtonApp,
    ModalConfirm,
    ErrorComponent,
    DurationStartEnd
  },
  props: {
    typeModal: {
      type: String
    },
    showModal: {
      type: Boolean,
      default: false
    },
    duration: {
      type: String
    },
    endDateProject: {
      type: String
    },
    dateProject: {
      type: [Object, Date],
      default: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      detailProject: 'projectV2/getDataTimelineProject',
      headerProject: 'project/getHeaderProject'
    }),
    durationFormated () {
      const tempDuration = this.persistDuration ? this.persistDuration : this.duration
      if (tempDuration && this.detailProject.card.estimate_finish_project) {
        return moment(this.detailProject.card.estimate_finish_project).add(Number(tempDuration), 'days').format('DD MMMM YYYY')
      }
      return '-'
    },
    dateFormated () {
      if (this.dateProject) {
        const { start, end } = this.dateProject
        const format = 'DD MMMM YYYY'
        return `${moment(start).format(format)} - ${moment(end).format(format)}`
      }
      return '-'
    }
  },
  data () {
    return {
      isLoadingSpinner: false,
      modalFailedReschedule: false,
      modalSuccess: false,
      durationProps: {
        titleFrom: '',
        titleEnd: '',
        from: '',
        end: ''
      },
      persistDuration: null,
      persistEstimate: null,
      showConfirmReschedule: false
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    handleSubmit () {
      this.isLoadingSpinner = true
      if (this.typeModal === 'extend') {
        this.extendProject()
      } else if (this.typeModal === 'reschedule') {
        this.rescheduleProject()
      }
    },
    extendProject () {
      this.persistDuration = JSON.parse(JSON.stringify(this.duration))
      this.persistEstimate = JSON.parse(JSON.stringify(this.detailProject.card.estimate_finish_project))
      const payload = {
        url: this.url,
        project_id: this.$route.params.proyekId,
        duration: Number(this.duration)
      }
      this.$store
        .dispatch('projectV2/extendProject', payload)
        .then(() => {
          this.isLoadingSpinner = false
          this.modalSuccess = true
          this.$store.commit('projectV2/setRefreshData', 'BS')
          this.$emit('closeModal', { type: 'close-all' })

          const tempFrom = this.detailProject.card.duration
          const tempEnd = Number(tempFrom) + Number(this.duration)

          this.durationProps = {
            titleFrom: 'Durasi Awal',
            titleEnd: 'Durasi Saat Ini',
            from: `${tempFrom} Hari`,
            end: `${tempEnd} Hari`
          }
        })
        .catch((error) => {
          this.isLoadingSpinner = false
          alertErrorMessage(error)
          this.$emit('closeModal', { type: 'close-all' })
        })
    },
    rescheduleProject () {
      const { start, end } = this.dateProject

      const payload = {
        url: this.url,
        project_id: this.$route.params.proyekId,
        start_date: moment(start).format('YYYY-MM-DD'),
        end_date: moment(end).format('YYYY-MM-DD')
      }
      this.$store
        .dispatch('projectV2/rescheduleProject', payload)
        .then(() => {
          this.isLoadingSpinner = false
          this.modalSuccess = true
          this.$store.commit('projectV2/setRefreshData', 'BS')
          this.$emit('closeModal', { type: 'close-all' })

          this.durationProps = {
            titleFrom: 'Tanggal Mulai',
            titleEnd: 'Tanggal Selesai',
            from: moment(start).format('DD MMMM YYYY'),
            end: moment(end).format('DD MMMM YYYY')
          }
        })
        .catch((error) => {
          this.isLoadingSpinner = false
          const err = error.response.data.message
          if (err) {
            const codeSplit = err.includes('[code=') ? err.split('[code=')[1].split(']')[0] : null
            if (codeSplit === '1') {
              this.modalFailedReschedule = true
            } else {
              alertErrorMessage(error)
            }
          } else {
            alertErrorMessage(error)
          }
          this.$emit('closeModal', { type: 'close-all' })
        })
    },
    handleCloseModalFailed () {
      this.modalFailedReschedule = false
    },
    closeModalSuccess () {
      this.modalSuccess = false
    },
    handleAddSkill () {
      const body = {
        selectedTab: 1,
        scroll: true,
        element: '#attendanceProyek',
        selectedDate: moment(this.persistEstimate).add(Number(this.persistDuration), 'days').format('YYYY-MM-DD')
      }
      this.$store.commit('project/setScrollElement', body)
      this.modalSuccess = false
      setTimeout(() => {
        this.persistDuration = null
        this.persistEstimate = null
      }, 100)
    }
  },
  watch: {
    showModal (val) {
      if (val) {
        if (this.typeModal === 'reschedule') {
          const today = moment(new Date()).format('YYYY-MM-DD')
          const startReschedule = moment(this.dateProject.start).format('YYYY-MM-DD')
          const statusName = this.headerProject.status.status_name

          const inplanning = statusName === 'Direncanakan' || statusName === 'Proyek Direncanakan' || statusName === 'Proyek In-Planning' || statusName === 'in-planning'
          const active = statusName === 'Proyek Aktif' || statusName === 'active' || statusName === 'Proyek Berjalan'

          if (inplanning && (startReschedule === today)) {
            this.showConfirmReschedule = true
          } else if (active && (startReschedule !== today)) {
            this.showConfirmReschedule = true
          } else {
            this.showConfirmReschedule = false
          }
        } else {
          this.showConfirmReschedule = false
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
