<template>
  <div>
    <div class="columns is-vcentered mt-1">
      <div class="column">
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <b-field>
                <b-input placeholder="Cari Produk"
                  v-model="search"
                  type="search"
                  icon="magnify"
                  icon-clickable
                  @keyup.native.enter="cariProduct"
                  @icon-click="cariProduct">
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="level-right">
            <div class="column">
              <b-button @click="newIsShow = true" type="is-success" icon-left="plus">Tambah Produk</b-button>
            </div>
          </div>
        </nav>
      </div>
    </div>

    <div v-if="listProduk === 0">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <template>
            <p>
              <b-icon icon="emoticon-sad" size="is-large"/>
            </p>
            <p>Data Not Found&hellip;</p>
          </template>
        </div>
      </section>
    </div>
    <div v-else>
      <b-table
        :total="paginate.total"
        :per-page="paginate.perPage"
        :data="paginate.data"
        :loading="paginate.loading"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        paginated
        backend-pagination
        scrollable>
        <b-table-column field="id" label="#" v-slot="props">
          {{ props.row.id }}
        </b-table-column>
        <b-table-column field="name" label="Name" v-slot="props">
          <div style="display: flex;">
            <div>
              <img :src="props.row.image_uri" style="width: 100px;" />
            </div>
            <div style="padding: 20px 10px 0px 10px;">
              {{ props.row.name }}
            </div>
          </div>
        </b-table-column>
        <b-table-column field="act" label="Action" v-slot="props">
          <div class="buttons">
            <b-button @click="detail(props.row)" type="is-info">Detail</b-button>
            <b-button @click="deleteProduct(props.row)" type="is-danger" icon-left="delete">Produk</b-button>
          </div>
        </b-table-column>
      </b-table>
    </div>

    <!-- Modal Tambah Produk -->
    <b-modal
      v-model="newIsShow"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <form action="">
        <div class="modal-card" style="width: 400px;">
          <header class="modal-card-head">
            <p class="modal-card-title">Tambah Produk</p>
            <button
              type="button"
              class="delete"
              @click="newIsShow = false"/>
          </header>
          <section class="modal-card-body">
            <b-field label="Nama Produk">
              <b-input
                v-model="name"
                placeholder="Nama produk"
                required>
              </b-input>
            </b-field>
            <b-field label="Foto Produk">
              <b-input
                v-model="image_uri"
                placeholder="URL Image"
                required>
              </b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button
              :loading="isLoading"
              @click="saveProduct()"
              label="Simpan"
              type="is-success" />
          </footer>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'PengaturanProduct',
  data () {
    return {
      search: null,
      newIsShow: false,
      isLoading: false,
      name: null,
      image_uri: null,
      productSelected: null,
      listProduk: null,
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 5,
        data: []
      }
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  components: {},
  watch: {
    search (newVal) {
      if (newVal === null || newVal === '') {
        this.loadData(1)
      }
    }
  },
  methods: {
    cariProduct () {
      if (this.search === null) {
        return
      }
      this.loading = true
      const url = `${this.url}/api/v1/vendor/product?page=${this.paginate.page}&limit=${this.paginate.perPage}&search_text=${this.search}`
      axios.get(url)
        .then((response) => {
          if (response.data.code === 1) {
            this.paginate.data = response.data.data.list
            this.paginate.total = (response.data.data.total_page * this.paginate.perPage)
            this.paginate.page = response.data.data.current_page
            this.paginate.loading = false
            this.listProduk = response.data.data.list.length
          }
        })
        .catch(() => {
          this.paginate.loading = false
        })
    },
    detail (row) {
      this.$router.push(`/pengaturan/product-detail/${row.id}`)
    },
    clearInput () {
      this.name = null
      this.image_uri = null
    },
    deleteProduct (val) {
      this.productSelected = val
      this.$buefy.dialog.confirm({
        message: 'Hapus produk : ' + this.productSelected.name,
        onConfirm: () => {
          axios.delete(`${this.url}/api/v1/vendor/product`, {
            data: {
              product_id: this.productSelected.id
            }
          })
            .then(() => {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Hapus product berhasil!...',
                position: 'is-bottom',
                type: 'is-success'
              })
              this.loadData(1)
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Error hapus product',
                position: 'is-bottom',
                type: 'is-danger'
              })
            })
        }
      })
    },
    saveProduct () {
      if (this.name === null || this.image_uri === null) {
        return
      }
      const data = {
        product_name: this.name,
        product_image: this.image_uri
      }
      this.isLoading = true
      axios.post(`${this.url}/api/v1/vendor/product`, data)
        .then(() => {
          this.loadData(this.paginate.page)
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Save product berhasil!...',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.clearInput()
          this.newIsShow = false
          this.isLoading = false
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Error save',
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.isLoading = false
        })
    },
    searchOnClick () {},
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    onPageChange (v) {
      this.loadData(v)
    },
    async loadData (page) {
      this.paginate.loading = true
      this.paginate.page = page
      const url = `${this.url}/api/v1/vendor/product?page=${this.paginate.page}&limit=${this.paginate.perPage}`
      await axios.get(url)
        .then((response) => {
          if (response.data.code === 1) {
            this.paginate.data = response.data.data.list
            this.paginate.total = (response.data.data.total_page * this.paginate.perPage)
            this.paginate.page = response.data.data.current_page
            this.paginate.loading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  created () {
    this.loadData(1)
  }
}
</script>

<style lang="scss" scoped>
  .project-btn {
    color: green;
    cursor: pointer;
  }
</style>
