<template>
  <div class="body-wrapper container">
    <div class="container pt-3">
      <div class="tile is-ancestor">
        <div class="tile is-parent m-2">
          <article class="tile is-child">
            <p class="averta-bold is-size-5">Informasi Penawaran</p>
            <div class="mt-4 mb-3">
              <p class="averta-reguler has-text-grey">Nomor Penawaran</p>
              <p class="averta-bold">{{ dataOffer.id }}</p>
            </div>
            <div class="mb-3">
              <p class="averta-reguler has-text-grey">Tanggal Pembuatan Penawaran</p>
              <p class="averta-bold">{{ convertDate(dataOffer.created_at) }}</p>
            </div>
            <div style="gap: 1.5rem" class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100">
              <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start">
                <p class="averta-reguler has-text-grey">PIC - No.Telp</p>
                <p class="averta-bold">
                  {{ dataOffer.pic.name }} - <span class="has-text-primary">{{ dataOffer.pic.phone }}</span>
                </p>
              </div>
              <div v-if="dataOffer.pic.extra_customer_name && dataOffer.pic.extra_customer_phone"
              class="border-left-1 py-4 c-h-100">
              </div>
               <div v-if="dataOffer.pic.extra_customer_name && dataOffer.pic.extra_customer_phone"
               class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start">
                <p class="averta-reguler has-text-grey">Informasi Customer</p>
                <p class="averta-bold">
                  {{ dataOffer.pic.extra_customer_name }} -
                  <span class="has-text-primary">{{ dataOffer.pic.extra_customer_phone }}</span>
                </p>
              </div>
            </div>
          </article>
        </div>
        <div class="tile is-parent m-2">
          <article class="tile is-child">
            <p class="averta-bold">Catatan Proyek</p>
            <br/>
            <textarea class="textarea" placeholder="Catatan Proyek" v-model="catatanProyek"></textarea>
            <br/>
            <div class="buttons">
              <b-button :disabled="isDisabled" :class="{'disabled-button averta-bold': isDisabled, 'averta-bold btn-lengkapi': !isDisabled, }" rounded expanded @click.prevent="postCatatan(dataOffer.survey_id)">
                Simpan
              </b-button>
            </div>
          </article>
        </div>
      </div>

      <div class="divider mb-5 mt-5" style="border: 1px solid #E1E1E1;"></div>
      <NewDetailPenawaran :isCalledButtonArea="false" :isPurchasing="true" :purchasingModal:="false" :hideInformasiPenawaran="true" :hideTimeline="true" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import NewDetailPenawaran from '@/components/project/NewDetailPenawaran'

export default {
  name: 'DetailOfferProject',
  components: {
    NewDetailPenawaran
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isDisabled () {
      return !this.catatanProyek
    }
  },
  props: ['dataOffer'],
  data () {
    return {
      isLoading: true,
      catatanProyek: null,
      dataPenawaran: null
    }
  },
  methods: {
    convertDate (date) {
      if (date === null) {
        return ''
      }
      return moment(date).format('dddd, DD MMMM YYYY')
    },
    postCatatan (surveyId) {
      const payload = {
        survey_id: surveyId,
        url: this.url,
        notes: this.catatanProyek
      }

      this.$store
        .dispatch('project/updatePersonalNote', payload)
        .then(() => {
          this.$buefy.toast.open({
            message: 'Berhasil update catatan',
            type: 'is-success'
          })
          this.$emit('getDetailOffer')
        })
        .catch(error => {
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal update catatan proyek, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.catatanProyek = this.dataOffer.note
  },
  watch: {
    dataOffer (newVal) {
      this.dataPenawaran = newVal
    }
  }
}
</script>

<style scoped>
  .body-wrapper {
    min-height: 90vh
  }
  .not-found-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .text-id-proyek {
    font-size: 24px;
  }
  .label-20 {
    font-size: 20px;
    line-height: 23px;
  }
  .label-18 {
    font-size: 18px;
    line-height: 16px;
  }
  .label-14 {
    font-size: 14px;
    line-height: 22px;
  }
  .tile .is-parent {
    border: 0.7px solid #D8D8D8;
    border-radius: 12px;
    box-shadow: none;
  }
  .btn-lengkapi {
    background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
    color: #ffffff;
  }
  .disabled-button {
    background: #9a9a9a;
    color: #ffffff;
  }
</style>
