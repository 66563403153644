<template>
  <div class="calendar-container">
    <div class="calendar-wrapper">
      <FullCalendar :options="calendarOptionsMonth">
        <template v-slot:eventContent="arg">
          <div class="wrapper-date">
            <p class="mb-5 has-text-grey-darker">{{ arg.event.title }}</p>
            <div class="is-flex is-justify-content-space-between wrapper-detail">
              <p>Lihat Detail</p>
              <ArrowRightIcon />
            </div>
          </div>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import { ArrowRightIcon } from '../../components/icons'

export default {
  name: 'CalendarMonth',
  props: ['calendarOptionsMonth'],
  components: {
    FullCalendar,
    ArrowRightIcon
  }
}
</script>

<style scoped>
.calendar-container {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.calendar-wrapper {
  width: auto;
  height: 750px;
}

.wrapper-date {
  background: #ffffff;
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 15px;
  border-left: 12px solid #d9272d;
  font-family: "Averta-Bold";
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  margin: 0px 6px;
}

.wrapper-date .wrapper-detail {
  color: #d9272d;
}

.wrapper-date .wrapper-detail svg {
  fill: #d9272d !important;
}

@media only screen and (max-width: 768px) {
  .calendar-wrapper {
    width: 1200px;
  }
}
</style>
