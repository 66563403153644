import axios from 'axios'

/* eslint-disable */
const state = () => ({
  list: null
})

const getters = {
  getSkills: (state, getters, rootState) => {
    return state.list
  }
}

const actions = {
  getListSkills ({ commit, state }, payload) {
    let url = `${payload.url}${process.env.VUE_APP_DATA_SKILLS}`
    if (payload.search !== '') {
      url += `?search=${payload.search}`
    }
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setDataSkills (state, val) {
    state.list = val
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
