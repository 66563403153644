<template>
  <SidebarClientTemplate
    :show="showSidebar"
    :disabledSubmit="disabledSubmit"
    @closeSidebar="showModalCancelFunc"
    @submit="handleSubmit"
  >
    <template v-slot:bodySidebar>
      <b-loading :is-full-page="true" v-model="isLoadingSubmit" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
      <div>
        <p class="averta-black label-20 mb-4">Edit {{renderTitle}}</p>

        <ClientAndBranchInfo
          :detailClient="getDetailClient"
          :detailBranch="getDetailBranch"
          :detailStore="isAdmin ? getDetailStore.selectedStore : null"
        />

        <AdminInfo v-if="isAdmin" currentTab="pic" typeSection="edit" />
        <HeadEInfo v-else-if="isHead" currentTab="pic" typeSection="edit" />
        <AreaKoordinatorInfo v-else-if="isAreaKoordinator" currentTab="pic" typeSection="edit" />
        <BranchInfo v-else currentTab="pic" typeSection="edit" />
      </div>

      <!-- Modal Cancel -->
      <ModalConfirm
        :showModal="showModalCancel"
        imageProps="sunset.png"
        :titleProps="`Batalkan Perubahan ${renderTitle}?`"
        descProps="Jika data sudah diisi maka akan dihapus oleh sistem"
        imageClass="mb-0"
      >
        <template v-slot:button>
          <div class="is-flex is-align-items-center mt-4">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="showModalCancel = false"
            >
              Kembali
            </ButtonApp>
            <ButtonApp :is-bold="true" width="185px" height="52px" @click="handleCloseSidebar">
              Batalkan Perubahan
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>

      <!-- Modal Submit -->
      <ModalConfirm
        :showModal="showModalConfirm"
        imageProps="data-not-found.png"
        titleProps="Semua Data Yang Dimasukkan Sudah Benar?"
        descProps="Pastikan kamu sudah melakukan pengecekkan sebelum diubah"
      >
        <template v-slot:button>
          <div class="is-flex is-align-items-center">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="cancelClose"
            >
              Batalkan
            </ButtonApp>
            <ButtonApp :is-bold="true" width="185px" height="52px" @click="confirmModal">
              Simpan Perubahan
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
    </template>
  </SidebarClientTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { SpinnerIcon } from '@/components/icons'
import { validationPayloadHelper, alertErrorMessage, titlePICBranch } from '@/helpers'
import { HEAD_ENGINEERING_SMRC, ADMIN_SMRC, AREA_KOORDINATOR_SMRC } from '@/helpers/constants'

import AdminInfo from '../admin-components/AdminInfo.vue'
import BranchInfo from './BranchInfo.vue'
import HeadEInfo from '../head-components/HeadEInfo.vue'
import AreaKoordinatorInfo from '../area-koordinator-components/AreaKoordinatorInfo.vue'

import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalConfirm from '../ModalConfirm.vue'
import ClientAndBranchInfo from '../store-components/ClientAndBranchInfo.vue'
import SidebarClientTemplate from '@/components/sidebar/SidebarClientTemplate.vue'

export default {
  name: 'SidebarEditPICBranch',
  components: {
    SpinnerIcon,

    AdminInfo,
    BranchInfo,
    HeadEInfo,
    AreaKoordinatorInfo,

    ButtonApp,
    ModalConfirm,
    ClientAndBranchInfo,
    SidebarClientTemplate
  },
  props: {
    type: {
      type: String // admin | head | area-koordinator
    },
    show: {
      type: Boolean
    },
    close: {
      type: Function
    },
    selectedBranch: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      payloadAddPicBranch: 'client/getPayloadAddPicBranch',
      detailClient: 'client/getPayloadClientSelectedBranch',
      getDetailClient: 'client/getDetailClient',
      getDetailBranch: 'client/getDetailBranch',
      getDetailPICBranch: 'client/getDetailPICBranch',
      getDetailStore: 'client/getPayloadStore'
    }),
    isAdmin () {
      return this.type === 'admin'
    },
    isHead () {
      return this.type === 'head'
    },
    isAreaKoordinator () {
      return this.type === 'area-koordinator'
    },
    renderTitle () {
      let temp
      switch (this.type) {
        case 'admin':
          temp = ADMIN_SMRC
          break

        case 'head':
          temp = HEAD_ENGINEERING_SMRC
          break

        case 'area-koordinator':
          temp = AREA_KOORDINATOR_SMRC
          break

        default:
          temp = titlePICBranch(this.getDetailClient.code || '')
          break
      }

      return temp
    }
  },
  data () {
    return {
      showSidebar: false,
      showModalConfirm: false,
      showModalCancel: false,
      isLoadingSubmit: false,
      disabledSubmit: true,
      typeSection: '',
      name: '',
      companyName: '',
      logo: ''
    }
  },
  methods: {
    cancelClose () {
      this.showModalConfirm = false
    },
    showModalCancelFunc () {
      if (this.getDetailPICBranch) {
        const { name, email, phone } = this.getDetailPICBranch
        const { name: nameF, email: emailF, phone: phoneF } = this.payloadAddPicBranch.dataPic[0]

        const init = {
          name,
          email,
          phone
        }
        const initForm = {
          name: nameF,
          email: emailF,
          phone: phoneF
        }

        const initString = JSON.stringify(init)
        const initFormString = JSON.stringify(initForm)

        if (initString === initFormString) {
          this.handleCloseSidebar()
        } else {
          this.showModalCancel = true
        }
      }
    },
    confirmModal () {
      this.isLoadingSubmit = true
      this.showModalConfirm = false

      const payload = {
        url: this.url,
        user_id: this.payloadAddPicBranch.dataPic[0].id,
        data: {
          name: this.payloadAddPicBranch.dataPic[0].name,
          email: this.payloadAddPicBranch.dataPic[0].email,
          phone: this.payloadAddPicBranch.dataPic[0].phone
        }
      }

      let temp
      switch (this.type) {
        case 'admin':
          temp = 'updateRoleSMRC'
          break

        case 'head':
          temp = 'updateRoleSMRC'
          break

        case 'area-koordinator':
          temp = 'updateRoleSMRC'
          break

        default:
          temp = 'updatePICBranch'
          break
      }

      this.$store
        .dispatch(`client/${temp}`, payload)
        .then(() => {
          this.isLoadingSubmit = false
          this.$buefy.toast.open({
            message: `<p class="averta-regular">${this.renderTitle} Berhasil Di Ubah</p>`,
            type: 'is-success'
          })
          this.handleCloseSidebar()
          this.$emit('updateTable')
        })
        .catch(error => {
          this.isLoadingSubmit = false
          alertErrorMessage(error)
        })
    },
    handleSubmit () {
      this.showModalConfirm = true
    },
    handleCloseSidebar () {
      this.$store.commit('client/setPayloadAddPicBranch', {
        type: 'dataPic',
        dataPic: [
          {
            name: '',
            email: '',
            phone: ''
          }
        ]
      })
      this.$store.commit('client/setDetailPICBranch', null)
      this.showSidebar = false
      this.showModalCancel = false
      this.close()
    }
  },
  watch: {
    show (value) {
      this.showSidebar = value
      if (value) {
        this.typeSection = 'information'
        if (this.detailClient) {
          this.name = this.detailClient.name
          this.companyName = this.detailClient.company_name
          this.logo = this.detailClient.logo
        }
      }
    },
    payloadAddPicBranch: {
      handler (value) {
        value.clientSelected = this.getDetailClient
        this.disabledSubmit = validationPayloadHelper(value, 'pic', this.type)
      },
      deep: true
    }
  }
}
</script>
