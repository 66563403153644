<template>
  <div class="wrap">
    <div v-if="typeSection === 'information'">
      <div class="flex-center-vertical is-justify-content-space-between">
        <div>
          <p class="averta-bold label-18">{{name}}</p>
          <p class="color-grey">{{companyName}}</p>
        </div>
        <div class="logo-client flex-center">
          <img v-if="logo" :src="logo" alt="">
        </div>
      </div>
    </div>

    <div v-else>
      <div class="mb-5">
        <p class="averta-black label-20">Informasi Perusahaan</p>
        <p>Pilih PT dan brand terlebih dahulu untuk menambahkan {{ currentTab === 'pic' ? 'PIC' : 'Cabang' }}.</p>
      </div>
      <div class="is-flex is-justify-content-space-between">
          <p class="label-14 mr-3 white-space-nowrap mt-5"><span class="averta-bold ">PT. / Brand </span>  <span class="text-merah">*</span></p>
          <ClientAutocomplete :currentTab="currentTab" />
      </div>
    </div>
  </div>
</template>

<script>
import ClientAutocomplete from './ClientAutocomplete.vue'

export default {
  name: 'ClientInfo',
  props: {
    currentTab: {
      type: String,
      default: null
    },
    typeSection: {
      type: String
    },
    name: {
      type: String
    },
    companyName: {
      type: String
    },
    logo: {
      type: String
    }
  },
  components: {
    ClientAutocomplete
  }
}
</script>

<style scoped>
.wrap {
  padding: 20px;
  background: #F8F7FA;
  border-radius: 20px;
  border: 1px solid #F0F0F0;
}
</style>
