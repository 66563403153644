export const ListDaftarVendorData = dataVendor => {
  return [
    {
      image: 'wave-card.png',
      total: 'Total Client',
      listVendor: dataVendor.card.total_client,
      index: 0
    },
    {
      image: 'wave-card.png',
      total: 'Total Cabang',
      listVendor: dataVendor.card.total_branch,
      index: 1
    },
    {
      image: 'wave-card.png',
      total: 'Total Toko',
      listVendor: dataVendor.card.total_store,
      index: 2
    }
  ]
}
