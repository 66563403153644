<template>
  <div :class="{'list-form mb-5': typeSection !== 'edit' || !typeSection}">
    <div
      class="is-flex is-justify-content-space-between is-align-items-center mb-5"
    >
      <p class="label-14 mr-3 white-space-nowrap">
        <span class="averta-bold ">Nama</span>
        <span class="text-merah">*</span>
      </p>
      <div class="flex-center-vertical is-justify-content-flex-end input-wrapper-pic">
        <input
          type="text"
          :disabled="disabledInput"
          v-model="data.name"
          class="input"
          :class="{ 'is-delete mr-5': isDelete, 'first-index': index === 0 }"
          :placeholder="`Masukkan nama ${title}`"
        />
        <div
          v-if="isDelete"
          class="is-clickable wrap-delete is-flex is-align-items-center"
          @click="showHideModalConfirm"
        >
          <TrashIcon />
        </div>
      </div>
    </div>
    <div
      class="is-flex is-justify-content-space-between is-align-items-center mb-5"
    >
      <p class="label-14 mr-3 white-space-nowrap">
        <span class="averta-bold">Email </span>
        <span class="text-merah">*</span>
      </p>
      <input
        type="text"
        :disabled="disabledInput"
        v-model="data.email"
        class="input"
        :placeholder="`Masukkan email ${title}`"
      />
    </div>
    <div
      class="is-flex is-justify-content-space-between is-align-items-center mb-5"
    >
      <p class="label-14 mr-3 white-space-nowrap">
        <span class="averta-bold">No. Handphone/WA </span>
        <span class="text-merah">*</span>
      </p>
      <input
        type="text"
        :disabled="disabledInput"
        class="input"
        maxlength="14"
        v-model="data.phone"
        :placeholder="`Masukkan no. handphone/WA ${title}`"
        @input="filterAngka"
      />
    </div>

    <!-- Modal Close Confirm -->
    <ModalConfirm
      :showModal="showModal"
      imageProps="diskon.png"
      :titleProps="`Hapus ${title}?`"
      descProps="Pastikan kamu sudah lakukan pengecekkan sebelum menghapus data ini."
    >
      <template v-slot:button>
        <div class="is-flex is-align-items-center">
          <ButtonApp
            :isSecondary="true"
            :is-bold="true"
            width="185px"
            height="52px"
            class="mr-3"
            @click="showHideModalConfirm"
          >
            Kembali
          </ButtonApp>
          <ButtonApp
            :is-bold="true"
            width="185px"
            height="52px"
            @click="handleDelete(index)"
          >
            Hapus
          </ButtonApp>
        </div>
      </template>
    </ModalConfirm>
    <!-- Modal Close Confirm -->
  </div>
</template>

<script>
import { TrashIcon } from '@/components/icons'
import { HEAD_ENGINEERING_SMRC } from '@/helpers/constants'
import ModalConfirm from '../ModalConfirm.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'FormHeadE',
  props: {
    isDelete: {
      type: Boolean,
      default: false
    },
    dataProps: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    typeSection: {
      type: String,
      default: null
    },
    disabledInput: {
      type: Boolean,
      default: true
    }
  },
  components: {
    TrashIcon,
    ModalConfirm,
    ButtonApp
  },
  data () {
    return {
      title: HEAD_ENGINEERING_SMRC,
      data: this.dataProps,
      showModal: false
    }
  },
  methods: {
    filterAngka (event) {
      this.data.phone = event.target.value.replace(/[^0-9]/g, '')
    },
    showHideModalConfirm () {
      if (this.data.name === '' && this.data.email === '' && this.data.phone === '') {
        this.handleDelete(this.index)
      } else {
        this.showModal = !this.showModal
      }
    },
    handleDelete (index) {
      this.$emit('deleteHead', index)
      this.showHideModalConfirm()
    }
  },
  mounted () {
    if (this.typeSection === 'edit') {
      this.data = this.dataProps
    }
  },
  watch: {
    dataProps (val) {
      this.data = val
    },
    data: {
      handler (val) {
        const data = {
          index: this.index,
          data: val
        }
        this.$emit('updateHeadE', data)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.list-form {
  border-bottom: 2px solid #e1e1e1;
}

.input {
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  padding: 22px 12px;
  width: 87%;
}

.input.first-index {
  width: 100%;
}

.input.is-delete {
  width: 100%;
}

.input-wrapper-pic {
  width: 87%;
}

</style>
