<template>
  <Skeleton v-if="initialLoading" class="py-6 mb-6" />
  <div class="pt-5 wrapper" v-else>
    <!-- Top -->
    <!-- Desktop -->
    <div class="is-flex is-justify-content-space-between is-hidden-mobile">
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <NotificationIcon color="#D9272D" class="mr-3" width="26" height="26" />
        <p class="averta-bold is-size-5">Notifikasi</p>
      </div>
      <div @click="handleReadNotif(null, null, null, true)" class="is-flex is-justify-content-space-between is-align-items-center read-all is-clickable">
        <NotificationIcon color="#D9272D" class="mr-3" />
        <p class="averta-bold text-merah">Tandai semua dibaca</p>
      </div>
    </div>

    <!-- Desktop -->
    <!-- Mobile -->
    <div class="is-flex is-align-items-center header-mobile mb-5 is-justify-content-space-between">
      <div @click="goBack()">
        <ArrowLeftIcon />
      </div>
      <div class="is-flex is-align-items-center is-justify-content-center">
        <NotificationIcon color="#D9272D" width="24" height="24" class="mr-2" />
        <p class="averta-bold is-size-5">Notifikasi</p>
      </div>
      <div></div>
    </div>
    <!-- Mobile -->
    <!-- Top -->

    <!-- Wrapper Notif -->
    <div class="wrapper-notif">
      <div v-if="listNotif.length > 0">
        <div v-for="(itemNotif, indexNotif) in listNotif" :key="indexNotif + '_notif'">
          <div class="divider mt-6" v-if="indexNotif !== 0"></div>
          <div class="is-flex is-justify-content-space-between mb-3">
            <p class="averta-bold">{{itemNotif.type}}</p>
            <p class="averta-bold has-text-grey-light">{{itemNotif.total}} Notifikasi</p>
          </div>
          <div>
            <!-- change for enhancement -->
            <div
              class="mb-3 is-flex is-align-items-center is-justify-content-space-between flex-sm-column align-items-sm-start"
              @click="handleNavigate(item.tag, item.entity_id, item.id)"
              v-for="(item, index) in itemNotif.list" :key="index+'_item_notif'"
              :class="[formatNotification(item.detail_tag, item.is_read)]"
            >
              <div class="is-flex is-align-items-center">
                <div
                  class="mr-4 is-flex is-align-items-center is-flex-shrink-0"
                  :class="{
                    'wrapper-notif-icon-read': item.is_read === 1 && !(['spk-offer', 'request-survey', 'approved-offer', 'rejected-offer'].includes(item.detail_tag)),
                    'wrapper-notif-icon-unread': item.is_read === 0 && !(['spk-offer', 'request-survey', 'approved-offer', 'rejected-offer'].includes(item.detail_tag))
                  }"
                >
                  <img v-if="item.detail_tag === 'spk-offer'" style="width: 32px; height: 32px; object-fit: contain;" :src="require(`@/assets/ion_notifications_sik.png`)"/>
                  <img v-else-if="item.detail_tag === 'request-survey'" style="width: 32px; height: 32px; object-fit: contain;" :src="require(`@/assets/ion_notifications_project.png`)"/>
                  <img v-else-if="item.detail_tag === 'approved-offer'" style="width: 34px; height: 31px; object-fit: contain;" :src="require(`@/assets/ion_notifications_approved.png`)"/>
                  <img v-else-if="item.detail_tag === 'rejected-offer'" style="width: 32px; height: 32px; object-fit: contain;" :src="require(`@/assets/ion_notifications_rejected.png`)"/>
                  <NotificationIcon v-else :color="item.is_read === 1 ? '#9A9A9A' : '#D9272D' " />
                </div>
                <div>
                  <p class="averta-bold">{{item.title}}</p>
                  <div v-html="item.message_raw"></div>
                </div>
              </div>
              <div>
                <p class="text-mini text-grey-4">{{renderDate(item.created_at)}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="listNotif.length < 1 && !initialLoading" class="is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
        <ProyekEmptyIcon />
        <p class="averta-bold">Tidak Ada Notifikasi Baru</p>
      </div>

      <PaginationApp
        v-if="totalPages > 1"
        :total-pages="totalPages"
        :current-page="currentPage"
        :load-page="getListNotif"
        :limit-data="limitPage"
        :total-data="totalData"
      />
    </div>
    <!-- Wrapper Notif -->

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>

    <!-- Floating Button -->
    <div class="floating-button">
      <div @click="handleReadNotif(null, null, null, true)" class="is-flex is-justify-content-center is-align-items-center read-all is-clickable">
        <NotificationIcon color="#D9272D" class="mr-3" />
        <p class="averta-bold text-merah">Tandai semua dibaca</p>
      </div>
    </div>
    <!-- Floating Button -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Skeleton from '@/components/skeleton/Skeleton'
import {
  NotificationIcon,
  ArrowLeftIcon,
  SpinnerIcon,
  ProyekEmptyIcon
} from '@/components/icons'
import moment from 'moment'
import PaginationApp from '@/components/PaginationApp.vue'
import { navigateTo } from '@/helpers'
import SocketioService from '@/services/socketio.services'

export default {
  name: 'NotificaitonPage',
  components: {
    NotificationIcon,
    Skeleton,
    ArrowLeftIcon,
    PaginationApp,
    SpinnerIcon,
    ProyekEmptyIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    })
  },
  data () {
    return {
      initialLoading: true,
      isLoading: true,
      listNotif: [],
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      limitPage: 50
    }
  },
  methods: {
    formatNotification (title, isRead) {
      // sudah dibaca = 1 , belum dibaca = 0
      const titleToResult = {
        'spk-offer': 'confirm-client',
        'request-survey': 'create-survey-project',
        'approved-offer': 'approve-offer',
        'rejected-offer': 'offer-rejected'
      }

      let result
      if (isRead === 0 && titleToResult[title]) {
        result = titleToResult[title]
      } else if (isRead === 1) {
        result = 'notif-read'
      } else {
        result = 'notif-default'
      }

      return result
    },
    goBack () {
      this.$router.go(-1)
    },
    getListNotif (page, isPurchasing) {
      if (!isPurchasing) {
        this.isLoading = true
      }
      this.$store
        .dispatch('dashboard/getListNotif', { url: this.url, page, limit: this.limitPage })
        .then(response => {
          this.listNotif = response.data.data.list
          this.initialLoading = false
          this.isLoading = false
          this.totalPages = response.data.total_page
          this.totalData = response.data.total_data
          this.currentPage = response.data.current_page
          this.$store.commit('dashboard/setNotifUnread', response.data.data.total_unread)

          if (!isPurchasing) {
            window.scrollTo(0, 0)
          }
        })
        .catch(error => {
          this.initialLoading = false
          this.isLoading = false
          console.log(error)
        })
    },
    renderDate (date) {
      moment.locale('id')
      return moment(date).format('DD MMMM YYYY, HH:mm')
    },
    handleNavigate (tag, id, idNotif) {
      this.handleReadNotif(tag, id, idNotif)
    },
    handleReadNotif (tag, id, idNotif, isReadAll) {
      const obj = {
        url: this.url
      }

      if (idNotif) {
        obj.id = idNotif
      }

      this.$store
        .dispatch('dashboard/readNotif', obj)
        .then(() => {
          if (this.user.roles[0].name === 'Purchasing' && tag === 'project') {
            this.getListNotif(this.currentPage, true)
          } else if (!isReadAll) {
            const url = navigateTo(tag, id, this.user.roles[0].name)
            this.$router.push(url).catch({})
            window.location.reload()
            window.scrollTo(0, 0)
          } else {
            this.getListNotif(this.currentPage)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  mounted () {
    this.getListNotif(1)
    SocketioService.getNotif((data) => {
      if (this.currentPage === 1) {
        this.listNotif = data.list.data.list
        this.totalPages = data.list.paginate.total_page
        this.currentPage = data.list.paginate.current_page
        this.$store.commit('dashboard/setNotifUnread', data.list.data.total_unread)
        this.getListNotif(1)
      }
    })
  }
}
</script>

<style scoped>
.wrapper {
  margin: 0px 60px;
  width: 100%;
  min-height: 80vh;
}

.divider {
  border-bottom: 1px solid #e1e1e1;
  margin: 30px 0px;
}

.text-mini {
  font-size: 14px;
  white-space: nowrap;
}

.read-all {
  background: #ffffff;
  border: 2px solid #D9272D;
  padding: 10px 20px;
  border-radius: 26px;
}

.wrapper-notif {
  width: 75%;
  margin: auto;
}

.notif-unread {
  /* background: #FFEFF0; */
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;
}

.wrapper-notif-icon-unread {
  background: #FFEFF0;
  padding: 12px;
  border-radius: 100px;
}

.notif-read {
  background: #FAFAFA;
  padding: 20px;
  border-radius: 20px;
  border: 1.5px solid #E1E1E1;
  cursor: pointer;
}

.notif-default {
  background: #FFF;
  padding: 20px;
  border-radius: 20px;
  border: 1.5px solid #E1E1E1;
  cursor: pointer;
}

.confirm-client {
  background: #FFF3E6;
  padding: 20px;
  border-radius: 20px;
  border: 1.5px solid #FFDCB4;
  cursor: pointer;
}

.create-survey-project {
  background: #E1EEFF;
  padding: 20px;
  border-radius: 20px;
  border: 1.5px solid #C1DAFE;
  cursor: pointer;
}

.approve-offer {
  background: #E7F8EC;
  padding: 20px;
  border-radius: 20px;
  border: 1.5px solid #B7EBC6;
  cursor: pointer;
}

.offer-rejected {
  background: #FCE5E6;
  padding: 20px;
  border-radius: 20px;
  border: 1.5px solid #F6B3B5;
  cursor: pointer;
}

.wrapper-notif-icon-read {
  background: #F0F0F0;
  padding: 12px;
  border-radius: 100px;
}

.header-mobile,
.floating-button {
  visibility: hidden;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    margin: 0px 0px;
  }

  .wrapper-notif {
    width: 90%;
    margin: auto !important;
    margin-bottom: 5em !important;
  }

  .header-mobile {
    visibility: visible;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 20px;
    margin: 0px 20px 0px 20px;
  }

  .floating-button {
    visibility: visible;
    position: fixed;
    bottom: 0px;
    width: 100vw;
    background: #FFFFFF;
    padding: 20px 10px 30px 10px;
    border: 1px solid #e1e1e1;
    margin: 0px !important;
    cursor: pointer;
  }

  .wrapper-notif {
    margin: 0px;
    padding: 0px;
  }

  .notif-unread {
    display: block !important;
  }

  .notif-read {
    display: block !important;
  }

  .wrapper-notif-icon-unread {
    /* margin-top: -55px; */
    align-items: center;
  }

  .wrapper-notif-icon-read {
    /* margin-top: -30px; */
    align-items: center;
  }

  .text-mini {
    margin-left: 55px;
    margin-top: 10px;
  }
}
</style>
