<template>
  <div>
    <b-progress
      :value="percent"
      format="percent"
      :max="100"
      :rounded="true"
      size="is-small"
      type="is-danger"
      show-value
    >
    </b-progress>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  data () {
    return {}
  },
  props: ['percent'],
  computed: {},
  methods: {},
  mounted () {},
  created () {
    console.log()
  }
}
</script>

<style lang="scss" scoped>
.is-danger {
  background: #eb4600 !important;
}
</style>
