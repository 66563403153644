<template>
  <div class="px-5 mb-5">
    <div class="card card-notifikasi mt-4 p-5">
      <div class="is-flex is-flex-direction-row is-justify-content-space-between">
        <div class="is-flex is-flex-direction-row">
          <div class="card-icon-notif">
            <img src="../../../../assets/icons/clock-time-icon.svg"/>
          </div>
          <div>
            <p class="is-size-5 averta-bold">Penawaran Ditolak</p>
            <p class="averta-reguler">
              Ada update penawaran dari Konsultan Survey. Silahkan periksa!
            </p>
          </div>
        </div>
        <div class="buttons">
          <b-button
            class="averta-bold has-text-danger"
            rounded
            expanded
            @click="() => { this.$router.push({path: '/purchasing/project'}) }"
          >
            Lihat Penawaran Reject
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotificationOfferRejected',
  data () {
    return {}
  },
  props: {},
  components: {},
  computed: {},
  methods: {},
  created () {}
}
</script>

<style lang="scss" scoped>
.card-notifikasi {
  background: linear-gradient(90deg, #891313 0%, #D92727 99.97%);
  border-radius: 20px;
  box-shadow: none;
  color: #ffffff;
}
.card-icon-notif {
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
</style>
