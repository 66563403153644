<template>
  <ModalTemplate :showModal="showModal" width="600px" @closeModal="closeModal">
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <p class="averta-bold label-18">Crop Foto</p>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>
    <template v-slot:bodyModal>
      <div class="body-crop-image">
        <div class="body-content flex-center">
          <Cropper ref="cropper" class="cropper-section" :src="previewImage" />
        </div>

        <div class="is-flex is-justify-content-flex-end select-wrapper mt-3">
          <div class="flex-center-vertical px-5">
            <ButtonApp @click="cropImage">
              <p class="averta-bold py-2 px-5">Crop dan Simpan</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'ModalCropImage',
  components: {
    Cropper,
    ModalTemplate,
    ButtonApp
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    previewImage: {
      type: String
    },
    closeModal: {
      type: Function
    }
  },
  methods: {
    cropImage () {
      const result = this.$refs.cropper.getResult()

      result.canvas.toBlob((blob) => {
        const body = {
          preview: result.canvas.toDataURL('image/jpg'),
          file: new File([blob], 'cropped-image.png', { type: 'image/png' })
        }
        this.$emit('cropImage', body)
      }, 'image/png')
    }
  }
}
</script>

<style scoped>
.cropper-section {
  height: 380px;
  width: 100%;
  background: black;
}

.body-crop-image {
  background: white;
  border-radius: 0px 0px 12px 12px;
}

.body-content {
  position: relative;
  height: 400px;
  overflow: hidden;
}

.select-wrapper {
  height: 70px;
  box-shadow: 0px -3px 30px 0px #00000012;
}
</style>
