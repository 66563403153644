<template>
  <div class="c-w-100 px-4 px-sm-0 min-height-project">
    <div class="columns mt-2 mb-5 is-flex wrapper-button thin-scroll" v-if="dataList.submenu && dataList.submenu.length > 0">
      <b-button v-for="tab, index in dataList.submenu" :key="`${index}-list-tab-penawaran`" class="mr-3 btn-tabs" :class="{ 'is-active': substatus === tab.query }" @click="changeIsActive(tab.query)">
        {{ wordingHelper(tab.label) }}
        <span class="tag is-danger is-rounded ml-2">{{ tab.count }}</span>
      </b-button>
    </div>

    <div>
      <div v-if="isWaitingConfirmSM" class="card-review-wrapper">
        <!-- Menunggu Konfirmasi SM -->
        <CardReviewOffering :dataPenawaranWaiting="dataPenawaranWaiting" v-if="dataPenawaranWaiting.length > 0 && substatus === 'waiting'" />
      </div>
    </div>

    <div v-if="dataList.list && dataList.list.length > 0" :class="{'is-loading-wrapper': isLoading}">
      <div v-for="data, index in dataList.list" :key="`${index}-list-penawaran`">
        <div v-if="specificLoading && listPersist.surveyId === data.survey_id">
          <CardSpecificSkeleton />
        </div>
        <NewCardProject
          v-else
          :data="data"
          @showModalProyekDarurat="showProyekDarurat($event)"
          @lihatCatatanProyek="showCatatanProyek($event)"
          @detailProject="detailPenawaran($event)"
          @reloadList="reloadList"
        />
      </div>
      <div class="flex-center">
        <p v-if="isLoadingScroll">Loading</p>
        <div v-else-if="currentPage === totalPages" style="width: 4px; height: 4px; border-radius: 4px; background: gray;"></div>
      </div>
    </div>
    <DataNotFound v-else />

    <!-- Modal Proyek Darurat -->
    <ModalProyekDarurat :dataModalUrgent="projectUrgent" :surveyId="surveyId" :showModal="modalProyekDarurat" :width="isMobile ? '95vw' : '45vw'" @closeModal="showProyekDarurat" :loadData="reloadList" />
    <!-- Modal Proyek Darurat -->

    <!-- Modal History Catatan Proyek -->
    <ModalHistoryCatatanProyek :surveyId="surveyId" :showModal="modalHistoryCatatanProyek" width="95vw" @closeModal="showCatatanProyek" />
    <!-- Modal History Catatan Proyek -->

    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import DataNotFound from '@/components/DataNotFound'
import { SpinnerIcon } from '@/components/icons'
import CardReviewOffering from '@/views/v2/sm/card/CardReviewOffering'
import NewCardProject from '../NewCardProject.vue'
import ModalProyekDarurat from '../../modal/ModalProyekDarurat.vue'
import ModalHistoryCatatanProyek from '../../modal/ModalHistoryCatatanProyek.vue'
import { wordingMenuHelper } from '@/helpers'
import CardSpecificSkeleton from '@/components/skeleton/CardSpecificSkeleton.vue'

export default {
  name: 'ListPenawaran',
  props: [
    'type',
    'selectedStatus',
    'selectedSubstatus',
    'isWaitingConfirmSM',
    'activeTab',
    'currentTab',
    'selectedTab',
    'triggerAPIFromScroll',
    'triggerTop',
    'reloadData'
  ],
  components: {
    DataNotFound,
    SpinnerIcon,
    CardReviewOffering,
    NewCardProject,
    ModalProyekDarurat,
    ModalHistoryCatatanProyek,
    CardSpecificSkeleton
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      subtab: 'list/getSelectedSubTab',
      listPersist: 'list/getPersistDataList',
      listFilter: 'project/getDataListFilter'
    }),
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingScroll: false,
      initialActiveTab: null,
      specificLoading: false,
      seamlessLoading: false,

      dataList: {
        submenu: [],
        list: []
      },
      dataPenawaranWaiting: [],
      searchBy: 'store',
      search: null,
      sort: 'desc',
      substatus: null,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      limitPage: 5,
      vendor: null,

      modalProyekDarurat: false,
      modalHistoryCatatanProyek: false,
      projectUrgent: null,
      surveyId: null
    }
  },
  methods: {
    getListPenawaran (props = {}) {
      const {
        pages = 1,
        clearVendor = false,
        loadMore = false,
        dontLoading = false,
        seamlessLoading = false
      } = props

      if (clearVendor) {
        this.vendor = null
      }

      if (loadMore) {
        this.isLoadingScroll = true
      } else if (seamlessLoading) {
        this.seamlessLoading = true
      } else if (!dontLoading && !seamlessLoading) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        sort: this.sort,
        limit: this.limitPage,
        page: pages || 1,
        type: this.type
      }

      if (this.selectedStatus) {
        payload.status = this.selectedStatus
        payload.substatus = this.substatus
      } else if (this.substatus) {
        payload.status = this.substatus
      }

      // filter by selected vendor
      if (this.vendor !== null) {
        payload.vendor = this.vendor
      }

      this.$store
        .dispatch('projectV2/getListProject', payload)
        .then(response => {
          const res = response.data
          this.currentPage = res.current_page

          if (loadMore) {
            this.isLoadingScroll = false
            this.dataList.list = [...this.dataList.list, ...res.data.list]
          } else {
            this.dataList = res.data
            this.totalPages = res.total_page
            this.totalData = res.total_data
          }
          this.isLoading = false
          this.seamlessLoading = false

          // Store Data
          this.persistDataList()
        })
        .catch(error => {
          this.isLoading = false
          this.seamlessLoading = false
          console.log(error)
        })
    },
    getListMenungguKonfirmasi () {
      this.isLoading = true
      const payload = {
        url: this.url,
        sort: 'desc',
        substatus: 'waiting-sm'
      }

      this.$store
        .dispatch('project/getListMenungguKonfirmasi', payload)
        .then(response => {
          this.dataPenawaranWaiting = response.data.data.list
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
        })
    },
    changeIsActive (data) {
      this.substatus = data
      if (this.activeTab || this.activeTab >= 0) {
        this.$store.commit('list/setSelectedSubTab', { tab: parseInt(this.activeTab), subtab: data })
      }
      this.getListPenawaran()
    },
    convertDate (value) {
      return moment(value).format('DD MMMM YYYY')
    },
    convertTime (startDate, endDate) {
      if (!startDate || !endDate) {
        return
      }
      const start = moment(startDate, 'HH:mm:ss').format('HH:mm')
      const end = moment(endDate, 'HH:mm:ss').format('HH:mm')
      const result = `(${start} - ${end})`
      return result
    },
    sendDetailAddress (data) {
      this.$emit('showDetailAddress', data)
    },
    detailPenawaran (data) {
      const currentRole = this.user.roles[0].name
      this.persistDataList({ pageDetail: true, surveyId: data.survey_id })

      switch (currentRole) {
        case 'Konsultan Survey':
          this.$router.push({ path: `/project/survey/${data.survey_id}/detail` })
          break
        case 'Site Manager':
          this.$router.push({ path: `/sm/project/survey/${data.survey_id}/detail` })
          break
        case 'Account Manager':
          this.$router.push({ path: `/am/project/survey/${data.survey_id}/detail` })
          break
        case 'Purchasing':
          this.$router.push({ path: `/purchasing/project/offering/${data.offering_id}/detail` })
          break
        default:
          this.$router.push({ path: `/project/survey/${data.survey_id}/detail` })
          break
      }
    },
    renderPhoneNumber (phone) {
      if (phone) {
        if (phone.toString().substring(0, 2) === '62') {
          return `+${phone}`
        } else {
          return `+62${phone}`
        }
      } else {
        return ''
      }
    },
    getActiveTab () {
      if (this.subtab && this.subtab.length > 0) {
        const lastMatchingObject = this.subtab.find(obj => obj.tab === this.activeTab)
        if (lastMatchingObject) {
          this.substatus = lastMatchingObject.subtab || this.selectedSubstatus
        } else {
          this.substatus = this.selectedSubstatus
        }
      } else {
        this.substatus = this.selectedSubstatus
      }

      const findDataProject = this.listPersist.data.find(item => item.currentTab === this.initialActiveTab)
      if (findDataProject) {
        this.restoreDataList(findDataProject)
      } else {
        this.getListPenawaran()
      }
    },
    showProyekDarurat (data) {
      if (data) {
        this.projectUrgent = data.projectUrgent
        this.surveyId = data.surveyId
      }
      this.modalProyekDarurat = !this.modalProyekDarurat
    },
    showCatatanProyek (data) {
      if (data) {
        this.surveyId = data
      }
      this.modalHistoryCatatanProyek = !this.modalHistoryCatatanProyek
    },
    reloadList () {
      this.getListPenawaran()
      this.modalProyekDarurat = false
      this.modalHistoryCatatanProyek = false
    },
    wordingHelper (value) {
      return wordingMenuHelper(value)
    },
    persistDataList (props = {}) {
      const { pageDetail = false, surveyId } = props

      const store = {
        data: {
          currentTab: this.initialActiveTab,
          project: this.dataList,
          currentPage: this.currentPage,
          totalPage: this.totalPages,
          totalData: this.totalData,
          sort: this.sort,
          vendor: this.vendor
        }
      }

      if (pageDetail) {
        store.scrollPosition = window.scrollY || window.pageYOffset
        store.detailPage = true
        store.surveyId = surveyId
      }

      this.$store.commit('list/setPersistDataList', store)
    },
    restoreDataList (data) {
      const { project, currentPage, totalPage, totalData, sort, vendor } = data

      this.dataList = project
      this.currentPage = currentPage
      this.totalPages = totalPage
      this.totalData = totalData
      this.sort = sort
      this.vendor = vendor
      this.isLoading = false

      setTimeout(() => {
        window.scrollTo(0, this.listPersist.scrollPosition)
      }, 50)

      if (this.initialActiveTab === this.selectedTab) {
        this.loadSpecificList()
      }
    },
    loadSpecificList () {
      this.specificLoading = true

      this.$store
        .dispatch('projectV2/getSpecificListProject', {
          url: this.url,
          surveyId: this.listPersist.surveyId
        })
        .then(response => {
          const res = response.data.data
          const findList = this.dataList.list.find(item => item.survey_id === res.survey_id)
          const indexSurveyId = this.dataList.list.findIndex(item => item.survey_id === res.survey_id)

          if (findList && indexSurveyId !== -1) {
            const isSame = JSON.stringify(findList.status) === JSON.stringify(res.status)

            if (isSame) {
              this.dataList.list[indexSurveyId] = res
            } else {
              // this.dataList.list.splice(indexSurveyId, 1)
              this.$emit('reloadData', { fromDetail: true })
            }

            setTimeout(() => {
              window.scrollTo(0, isSame ? this.listPersist.scrollPosition : 0)
              this.specificLoading = false
            }, 50)
          } else {
            this.specificLoading = false
          }
        })
        .catch((error) => {
          this.specificLoading = false
          console.log(error)
        })
    }
  },
  mounted () {
    this.getActiveTab()
  },
  created () {
    this.initialActiveTab = this.currentTab
    if (this.isWaitingConfirmSM) {
      this.getListMenungguKonfirmasi()
    }
  },
  watch: {
    search (val) {
      if (val === '') {
        this.getListPenawaran()
      }
    },
    listFilter (val) {
      if (val) {
        if (val.sort) {
          this.sort = val.sort
        } else {
          this.sort = 'desc'
        }
        if (val.vendor) {
          this.vendor = val.vendor
        } else {
          this.vendor = null
        }
        this.getListPenawaran()
      }
    },
    triggerAPIFromScroll () {
      if (this.initialActiveTab === this.selectedTab) {
        if (this.currentPage < this.totalPages && !this.isLoadingScroll) {
          this.currentPage++
          this.getListPenawaran({ pages: this.currentPage, loadMore: true })
        }
      }
    },
    selectedTab (val) {
      if (this.initialActiveTab === val) {
        this.getListPenawaran({ dontLoading: true })
      }
    },
    triggerTop (val) {
      if (this.initialActiveTab === this.selectedTab) {
        if (val === false && !this.seamlessLoading) {
          this.getListPenawaran({ seamlessLoading: true })
        }
      }
    }
  }
}
</script>

<style scoped>
.card-list {
  border-radius: 20px;
}

.header-detail {
  background: linear-gradient(90deg, #ffdddd 0%, #ffefdb 99.97%);
  border-radius: 20px 20px 0px 0px;
}

.id-survey {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.survey-text {
  color: #868686;
}

.pic-phone {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.btn-lihat-detail {
  border: 1px solid #d9272d;
  border-radius: 20px;
  padding: 12px 24px;
  font-family: "Averta-Bold";
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  cursor: pointer;
}

.total-tukang {
  font-size: 14px !important;
  color: #868686;
}

.btn-tabs {
  border-radius: 20px;
  color: #9A9A9A;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185FC;
  border: 1px solid #3185FC;
}

.wrapper-tooltip {
  padding: 10px;
  font-size: 14px;
}

.wrapper-tooltip i {
  font-size: 24px;
}

.card-review-wrapper {
  width: 107.5%;
  position: relative;
  left: -3.5%;
  background: #FAFAFA;
  padding-left: 2em;
  padding-right: 2em;
}

.wrap-logo {
  background: white;
  border-radius: 100px;
  padding: 6px 18px;
}

.wrap-logo img {
  height: 30px;
}

.wrapper-button {
  overflow-x: auto;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0 !important;
  }

  .survey-text {
    display: none;
  }

  .wrapper-filter {
    display: block !important
  }

  .filter-list {
    margin-top: 20px;
    display: flex;
    width: 100%;
  }

  .dropdown-list,
  .btn-dropdown {
    width: 100%;
  }

  .btn-dropdown div {
    padding-right: 180px !important;
  }

  .card-review-wrapper {
    width: 100%;
    position: relative;
    left: -3.5%;
    background: #FAFAFA;
    padding-left: 2em;
    padding-right: 2em;
  }
}

@media only screen and (max-width: 600px) {
  .overflow-x-sm-scroll {
    overflow-x: scroll;
  }
}
</style>
