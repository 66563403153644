<template>
    <div class="select-wrapper">
      <div class="is-flex is-align-items-center is-justify-content-space-between input-data">
        <div class="is-flex is-align-items-center wrap-input">
          <VendorFilter />
          <input class="form-input averta-regular"  ref="inputData" type="text" v-model="search" @input="handleSearch" @focus="onOpen">
        </div>
        <button type="button" v-if="search !== 'Semua Client'" class="delete" @click="clearVendor" />
      </div>
      <div
        v-if="openSelectOption"
        v-closable="{
          exclude: ['inputData'],
          handler: 'onClose'
        }"
        class="option-wrapper"
      >
        <p v-if="header" class="averta-black m-4">{{header}}</p>
        <div v-if="listData.length < 1">
          <p class="color-grey color-grey mx-4 mb-4 is-italic">Data Tidak Ditemukan</p>
        </div>
        <!-- VENDOR -->
        <div v-if="isVendor">
          <div
            class="item-data-wrapper cursor-pointer"
            v-for="(vendor, indexVendor) in listData"
            :key="`${indexVendor}-vendor`"
            @click="handleSelectItem(vendor)"
          >
            <div class="item-data" :class="{'border-bottom-none': indexVendor + 1 === listData.length}">
              <div v-if="haveImg" class="img-data-wrapper mr-3">
                <img :src="vendor[imgKey]" alt="">
              </div>
              <div class="header-wrapper">
                <p class="label-12">{{vendor.name}}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- STORE | Report Progress -->
        <div v-else-if="isStore">

          <b-collapse
            class="vendor-collapse"
            animation="slide"
            v-for="(vendor, indexVendor) of listData"
            :key="`vendor-${indexVendor}`"
            :open="isOpenCollapseStore"
          >
            <template #trigger="props">
              <div :class="{'vendor-border-bottom': indexVendor + 1 !== listData.length}">
                <div class="flex-center-vertical is-justify-content-space-between p-3" role="button" :aria-expanded="props.open">
                  <div class="flex-center-vertical">
                    <div v-if="haveImg" class="img-data-wrapper mr-3">
                      <img :src="vendor[imgKey]" alt="">
                    </div>
                    <div class="vendor-title-wrapper">
                      <p class="averta-bold">{{ vendor.vendor_name }}</p>
                    </div>
                  </div>
                  <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"></b-icon>
                </div>
              </div>
            </template>
            <div>
              <div class="store-collapse is-clickable"
              v-for="(store, indexStore) of vendor.list_stores"
              :key="`vendor-${indexStore}`"
              @click="handleSelectItem(store)">
                <div class="px-3 py-3">
                  <p>{{store.store_name}}</p>
                </div>
              </div>
            </div>
          </b-collapse>

        </div>

      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VendorFilter } from '@/components/icons'

export default {
  name: 'InputAutocomplete',
  props: {
    data: {
      type: Array,
      default: null
    },
    header: {
      type: String,
      default: ''
    },
    onSelectItem: {
      type: Function
    },
    onClearSearch: {
      type: Function
    },
    isVendor: {
      type: Boolean,
      default: false
    },
    isStore: {
      type: Boolean,
      default: true
    },
    isNew: {
      type: Boolean,
      default: false
    },
    haveImg: {
      type: Boolean,
      default: false
    },
    imgKey: {
      type: String,
      default: 'logo'
    },
    vendor: {
      type: String,
      default: null
    }
  },
  components: {
    VendorFilter
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      isLoading: false,
      search: 'Semua Client',
      openSelectOption: false,
      initListData: [],
      listData: [],
      isOpenCollapseStore: false
    }
  },
  methods: {
    handleSelectItem (props) {
      if (this.isVendor) {
        this.search = props.name
      } else if (this.isStore) {
        this.search = props.store_name
      }
      this.onClose()

      if (this.onSelectItem) {
        if (this.isNew) {
          this.onSelectItem({ dataVendor: props })
        } else {
          this.onSelectItem(props)
        }
      }
    },
    handleSearch (e) {
      if (!this.openSelectOption) {
        this.onOpen()
      }

      const query = e.target.value
      if (query === '') {
        this.onClose()

        if (this.isNew) {
          this.onClearSearch({ clearVendor: true })
        } else {
          this.onClearSearch(1)
        }
        this.search = 'Semua Client'
        return
      }

      let findObj
      if (this.isVendor) {
        findObj = this.initListData.filter(itemVendor =>
          itemVendor.name.toLowerCase().includes(query.toLowerCase())
        )
      } else if (this.isStore) {
        const filteredData = this.initListData.filter(vendor => {
          // Check if the vendor name matches the query
          if (vendor.vendor_name.toLowerCase().includes(query.toLowerCase())) {
            return true
          }
          // Check if any store name matches the query
          const matchingStores = vendor.list_stores.filter(store =>
            store.store_name.toLowerCase().includes(query.toLowerCase())
          )
          if (matchingStores.length > 0) {
            this.isOpenCollapseStore = true
            return true
          }
          return false
        })

        // Filter out stores that don't match the query
        const result = filteredData.map(vendor => ({
          ...vendor,
          list_stores: vendor.list_stores.filter(store =>
            store.store_name.toLowerCase().includes(query.toLowerCase())
          )
        })).filter(vendor => vendor.list_stores.length > 0)

        findObj = result
      }

      this.listData = findObj
    },
    onOpen () {
      if (this.search === 'Semua Client' || this.search === '') {
        this.search = ''
        this.listData = this.initListData
        this.isOpenCollapseStore = false
      }
      this.openSelectOption = true
    },
    onClose () {
      this.$refs.inputData.blur()
      this.openSelectOption = false
    },
    getListVendorFilter (type) {
      this.isLoading = true
      const actionString = type === 'store' ? 'report/getSimpleListVendor' : 'client/getListVendorFilter'

      this.$store
        .dispatch(actionString, { url: this.url })
        .then(response => {
          this.initListData = response.data.data
          this.listData = response.data.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
        })
    },
    clearVendor () {
      this.search = 'Semua Client'
      this.onClose()
      if (this.isNew) {
        this.onClearSearch({ clearVendor: true })
      } else {
        this.onClearSearch(1)
      }
    }
  },
  mounted () {
    if (this.isVendor) {
      return this.getListVendorFilter()
    }
    if (this.isStore) {
      return this.getListVendorFilter('store')
    }
    if (this.data) {
      this.initListData = this.data
      this.listData = this.data
    }
  },
  watch: {
    vendor: function (val) {
      if (val === '' || val === null) {
        this.search = 'Semua Client'
      }
    },
    '$route.query': {
      immediate: true,
      handler (newQuery, oldQuery) {
        if (Object.keys(newQuery).length > 0) {
          if (JSON.stringify(newQuery) !== JSON.stringify(oldQuery)) {
            if (newQuery.vendor) {
              this.search = newQuery.vendor
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.select-wrapper {
  position: relative;
  width: 100%;
}

.input-data {
  width: 100%;
  padding: 4px 12px;
  border: 1px solid #E1E1E1;
  border-radius: 5px;
}

.input-data input {
  padding: 8px;
  border: none;
}

.form-input {
  width: 100%;
}

.form-input:focus {
  outline: none;
}

.option-wrapper {
  position: absolute;
  top: 3em;
  right: 0;
  border-radius: 20px;
  /* padding: 10px; */
  width: 20em;
  max-height: 17em;
  overflow: auto;
  background: white;
  z-index: 5;
  box-shadow: 6px 7px 50px 0px #00000014;
}

.img-data-wrapper {
  width: 50px;
  height: 35px;
  display: flex;
  align-items: center ;
}

.img-data-wrapper img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.header-wrapper {
  width: 80%;
}

.item-data-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.item-data-wrapper:hover{
  background: #D9272D1A;
}

.item-data {
  width: 90%;
  min-height: 3em;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #E1E1E1;
}

.item-data.border-bottom-none {
  border-bottom: none;
}

.vendor-title-wrapper {
  width: 12.5em;
}

.vendor-border-bottom {
  border-bottom: 5px solid #E1E1E1;
}

.store-collapse:hover{
  background: #D9272D1A;
}

@media only screen and (max-width: 600px) {
  .wrap-input, .option-wrapper {
    width: 100% !important;
  }
}
</style>
