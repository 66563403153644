<template>
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6709 20.2125C17.3883 20.4951 17.0285 20.6364 16.5917 20.6364C16.1549 20.6364 15.7952 20.4951 15.5126 20.2125L9.50006 14.2385L3.48757 20.2125C3.20493 20.4951 2.85137 20.6426 2.4269 20.6549C2.00345 20.6683 1.63756 20.5208 1.32923 20.2125C1.04659 19.9298 0.905273 19.5701 0.905273 19.1333C0.905273 18.6965 1.04659 18.3368 1.32923 18.0541L8.4209 10.9625C8.57507 10.8083 8.74208 10.6988 8.92194 10.6341C9.1018 10.5704 9.29451 10.5385 9.50006 10.5385C9.70562 10.5385 9.89833 10.5704 10.0782 10.6341C10.2581 10.6988 10.4251 10.8083 10.5792 10.9625L17.6709 18.0541C17.9535 18.3368 18.101 18.6903 18.1134 19.1148C18.1267 19.5382 17.9792 19.9041 17.6709 20.2125ZM17.6709 10.0375C17.3883 10.3201 17.0285 10.4614 16.5917 10.4614C16.1549 10.4614 15.7952 10.3201 15.5126 10.0375L9.50007 4.06351L3.48757 10.0375C3.20493 10.3201 2.85137 10.4681 2.4269 10.4815C2.00345 10.4938 1.63756 10.3458 1.32923 10.0375C1.04659 9.75483 0.905274 9.39511 0.905274 8.95831C0.905274 8.5215 1.04659 8.16178 1.32923 7.87914L8.4209 0.787474C8.57507 0.633307 8.74208 0.524363 8.92194 0.46064C9.1018 0.395889 9.29451 0.363512 9.50007 0.363512C9.70562 0.363512 9.89833 0.395889 10.0782 0.46064C10.2581 0.524363 10.4251 0.633307 10.5792 0.787474L17.6709 7.87914C17.9535 8.16178 18.101 8.51482 18.1134 8.93826C18.1267 9.36274 17.9792 9.72914 17.6709 10.0375Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'UpIcon'
}
</script>
