<template>
  <div>
    <div class="mb-4">
      <b-skeleton height="20px" width="300px" :animated="true"></b-skeleton>
      <b-skeleton height="20px" width="200px" :animated="true"></b-skeleton>
    </div>
    <div>
      <div class="mb-5">
        <b-skeleton height="120px" :animated="true"></b-skeleton>
      </div>
      <div
        class="mb-3"
        v-for="(item, index) in totalCard"
        :key="index"
      >
        <b-skeleton :height="height" :animated="true"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalPICSkeleton',
  props: {
    showTitle: {
      type: Boolean,
      default: false
    },
    totalCard: {
      type: Number,
      default: 2
    },
    height: {
      type: String,
      default: '50px'
    }
  }
}
</script>
