<template>
  <div class="px-5 mb-5">
    <div class="columns mt-3">
      <div class="column card col-1 p-3 m-2">
        <img src="@/assets/img/wave-card.png" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="is-size-5 averta-bold">Qty Pemesanan Material</p>
          <p class="is-size-2 averta-black my-2">{{ summaryData.material_booked}}</p>
          <!-- <hr />
          <a class="bottom-card" @click="() => { this.$router.push({ path: '/purchasing/project' }) }">
            <span class="mr-2 averta-bold">Selengkapnya</span> <ArrowRightIcon />
          </a> -->
        </div>
      </div>
      <div class="column card col-2 p-3 m-2">
        <img src="@/assets/img/wave-card.png" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="is-size-5 averta-bold">Qty Pembelian Material</p>
          <p class="is-size-2 averta-black my-2">
            {{ summaryData.material_not_ready === '?' ? '0' : summaryData.material_ordered}}
          </p>
          <!-- <hr />
          <a class="bottom-card" @click="() => { this.$router.push({ path: '/purchasing/project' }) }">
            <span class="mr-2 averta-bold">Selengkapnya</span> <ArrowRightIcon />
          </a> -->
        </div>
      </div>
      <div class="column card col-3 p-3 m-2">
        <img src="@/assets/img/wave-card.png" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="is-size-5 averta-bold">Qty Material Terkirim</p>
          <p class="is-size-2 averta-black my-2">
            {{ summaryData.material_on_progress === '?' ? '0' : summaryData.material_delivered }}
          </p>
          <!-- <hr />
          <a class="bottom-card" @click="() => { this.$router.push({ path: '/purchasing/project' }) }">
            <span class="mr-2 averta-bold">Selengkapnya</span> <ArrowRightIcon />
          </a> -->
        </div>
      </div>
      <div class="column card col-4 p-3 m-2">
        <img src="@/assets/img/wave-card.png" alt="wave" class="wave" />
        <div class="card-body-proyek">
          <p class="is-size-5 averta-bold">Actual Cost</p>
          <p class="is-size-2 averta-black my-2">
            {{ summaryData.material_compelte === '?' ? '0' : formatThousand(summaryData.actual_cost) }}
          </p>
          <!-- <hr />
          <a class="bottom-card" @click="() => { this.$router.push({ path: '/purchasing/project' }) }">
            <span class="mr-2 averta-bold">Selengkapnya</span> <ArrowRightIcon />
          </a> -->
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArrowRightIcon from '../../../../components/icons/ArrowRightIcon.vue'
import { formatCurrency } from '@/helpers'

export default {
  name: 'CardSummary',
  props: {
    summaryData: {
      type: Object,
      required: true
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ArrowRightIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {}
  },
  methods: {
    formatThousand (amount) {
      return formatCurrency(amount)
    }
  },
  created () {}
}
</script>

<style lang="scss" scoped>
.card {
  box-shadow: none;
}
.card-body-proyek {
  z-index: 1;
  position: relative;
}
.bottom-card {
  display: flex;
  justify-content: end;
  align-items: center;
  color: white;
  z-index: 1;
}
.bottom-card svg {
  fill: white;
}
.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}
.col-1 {
  background: linear-gradient(90deg, #EB4600 0%, #FF8C5B 100%);
  border-radius: 20px;
  color: white;
}
.col-2 {
  background: linear-gradient(90deg, #FFAF24 0%, #FFC224 100%);
  border-radius: 20px;
  color: white;
}
.col-3 {
  background: linear-gradient(90deg, #751CE6 0%, #552BFF 100%);
  border-radius: 20px;
  color: white;
}
.col-4 {
  background: linear-gradient(90deg, #10BD41 0%, #55F883 100%);
  border-radius: 20px;
  color: white;
}
</style>
