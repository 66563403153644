<template>
  <svg
    width="41"
    height="39"
    viewBox="0 0 41 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_9996_91919"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="2"
      width="39"
      height="35"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.9987 15.4473C7.08203 15.4473 6.33203 14.7182 6.33203 13.827V10.5862H2.9987C2.08203 10.5862 1.33203 9.85706 1.33203 8.96586C1.33203 8.07465 2.08203 7.34549 2.9987 7.34549H6.33203V4.10475C6.33203 3.21354 7.08203 2.48438 7.9987 2.48438C8.91536 2.48438 9.66537 3.21354 9.66537 4.10475V7.34549H12.9987C13.9154 7.34549 14.6654 8.07465 14.6654 8.96586C14.6654 9.85706 13.9154 10.5862 12.9987 10.5862H9.66537V13.827C9.66537 14.7182 8.91536 15.4473 7.9987 15.4473ZM18.6686 21.1186C19.5617 19.6146 21.2124 18.6881 22.9987 18.6881C24.785 18.6881 26.4357 19.6146 27.3288 21.1186C28.222 22.6227 28.222 24.4757 27.3288 25.9797C26.4357 27.4838 24.785 28.4103 22.9987 28.4103C21.2124 28.4103 19.5617 27.4838 18.6686 25.9797C17.7754 24.4757 17.7754 22.6227 18.6686 21.1186ZM36.332 10.5862H31.0487L28.982 8.39873C28.3654 7.73437 27.4654 7.34549 26.532 7.34549H15.8654C16.1487 7.8316 16.332 8.36632 16.332 8.96586C16.332 10.7483 14.832 12.2066 12.9987 12.2066H11.332V13.827C11.332 15.6094 9.83203 17.0677 7.9987 17.0677C7.38203 17.0677 6.83203 16.8895 6.33203 16.614V33.2714C6.33203 35.0538 7.83203 36.5121 9.66537 36.5121H36.332C38.1654 36.5121 39.6654 35.0538 39.6654 33.2714V13.827C39.6654 12.0446 38.1654 10.5862 36.332 10.5862ZM14.6654 23.5492C14.6654 28.0214 18.3987 31.651 22.9987 31.651C27.5987 31.651 31.332 28.0214 31.332 23.5492C31.332 19.077 27.5987 15.4473 22.9987 15.4473C18.3987 15.4473 14.6654 19.077 14.6654 23.5492Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_9996_91919)">
      <rect x="0.5" y="0.0546875" width="40" height="38.8889" fill="#E10009" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AddPhotoIcon'
}
</script>
