import axios from 'axios'

/* eslint-disable */
const state = () => ({
  list: null
})

const getters = {
  getStores: (state, getters, rootState) => {
    return state.list
  }
}

const actions = {
  getListStores ({ commit, state }, payload) {
    let payloadUrl = `${process.env.VUE_APP_MATERIAL_STORES}?search=${payload.search || ''}&page=${payload.page || 1}&limit=${payload.limit || 5}`

    if (payload.offering) {
      payloadUrl += `&offering=true`
    }

    let url = `${payload.url}${payloadUrl}`
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListStoreModal ({ commit, state }, payload) {
    let payloadUrl = `${process.env.VUE_APP_MATERIAL_STORES}${payload.search ? `?search=${payload.search}` : '' }`

    let url = `${payload.url}${payloadUrl}`
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postDataStore ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_MATERIAL_STORES}`, payload.body).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateDataStore ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_MATERIAL_STORES}/${payload.id}/update`, payload.body).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  deleteDataStore ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${payload.url}${process.env.VUE_APP_MATERIAL_STORES}/${payload.id}/delete`).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
}

const mutations = {
  setDataStores (state, val) {
    state.list = val
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
