<template>
    <svg width="34" height="54" viewBox="0 0 34 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 27L33 27" stroke="#3185FC" stroke-linecap="round" />
        <path d="M1 27L33 27" stroke="black" stroke-opacity="0.2" stroke-linecap="round" />
        <rect x="8" y="18" width="18" height="18" rx="9" fill="#3185FC" />
        <rect x="8" y="18" width="18" height="18" rx="9" fill="black" fill-opacity="0.2" />
        <mask id="mask0_954_150806" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="11" y="21" width="12" height="12">
            <path
                d="M21.5 27.75H17.75V31.5C17.75 31.9125 17.4125 32.25 17 32.25C16.5875 32.25 16.25 31.9125 16.25 31.5V27.75H12.5C12.0875 27.75 11.75 27.4125 11.75 27C11.75 26.5875 12.0875 26.25 12.5 26.25H16.25V22.5C16.25 22.0875 16.5875 21.75 17 21.75C17.4125 21.75 17.75 22.0875 17.75 22.5V26.25H21.5C21.9125 26.25 22.25 26.5875 22.25 27C22.25 27.4125 21.9125 27.75 21.5 27.75Z"
                fill="black" />
        </mask>
        <g mask="url(#mask0_954_150806)">
            <rect x="8" y="18" width="18" height="18" fill="white" />
        </g>
    </svg>
</template>

<script>

export default {
  name: 'GroupingRelation'
}
</script>
