<template>
  <div class="card p-4">
    <div class="is-flex is-align-items-center mb-3">
      <img src="../../assets/img/waktunya-survey.png" alt="survey-process" class="mr-3">
      <div>
        <p class="averta-bold is-size-5">Waktunya Survey</p>
        <p class="desc is-hidden-mobile">Survey telah berlangsung. Lakukan survey dan buat penawaran untuk client sekarang. Butuh bantuan?
          <a href="#" class="averta-bold"><b-icon icon="whatsapp" custom-size="default" />Hubungi PIC Proyek</a> |
          <a href="#" class="averta-bold"><b-icon icon="whatsapp" custom-size="default" />Hubungi PIC Cabang</a>
        </p>
      </div>
    </div>
    <div class="desc-wrapper is-hidden-tablet">
      <p class="desc">Survey telah berlangsung. Lakukan survey dan buat penawaran untuk client sekarang. Butuh bantuan?
        <a href="#" class="averta-bold"><b-icon icon="whatsapp" custom-size="default" />Hubungi PIC Proyek</a> |
        <a href="#" class="averta-bold"><b-icon icon="whatsapp" custom-size="default" />Hubungi PIC Cabang</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSurveyProcess'
}
</script>

<style scoped>
.card {
  background: rgba(49, 252, 106, 0.1);
  border: 1.5px solid #26B14D;
  border-radius: 12px;
}

.desc-wrapper .desc {
  color: #4A4A4A;
}
</style>
