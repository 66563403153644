<template>
  <svg
    width="35"
    height="30"
    viewBox="0 0 35 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4997 24.1667C19.583 24.1667 21.3541 23.4378 22.813 21.98C24.2708 20.5211 24.9997 18.75 24.9997 16.6667C24.9997 14.5833 24.2708 12.8122 22.813 11.3533C21.3541 9.89556 19.583 9.16667 17.4997 9.16667C15.4163 9.16667 13.6452 9.89556 12.1863 11.3533C10.7286 12.8122 9.99967 14.5833 9.99967 16.6667C9.99967 18.75 10.7286 20.5211 12.1863 21.98C13.6452 23.4378 15.4163 24.1667 17.4997 24.1667ZM4.16634 30C3.24967 30 2.46523 29.6739 1.81301 29.0217C1.15967 28.3683 0.833008 27.5833 0.833008 26.6667V6.66667C0.833008 5.75 1.15967 4.96556 1.81301 4.31333C2.46523 3.66 3.24967 3.33333 4.16634 3.33333H9.41634L11.4997 1.08333C11.8052 0.722222 12.173 0.451111 12.603 0.27C13.0341 0.0899999 13.4858 0 13.958 0H21.0413C21.5136 0 21.9652 0.0899999 22.3963 0.27C22.8263 0.451111 23.1941 0.722222 23.4997 1.08333L25.583 3.33333H30.833C31.7497 3.33333 32.5347 3.66 33.188 4.31333C33.8402 4.96556 34.1663 5.75 34.1663 6.66667V26.6667C34.1663 27.5833 33.8402 28.3683 33.188 29.0217C32.5347 29.6739 31.7497 30 30.833 30H4.16634Z"
      fill="url(#paint0_linear_367_91633)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_367_91633"
        x1="0.833008"
        y1="14.7273"
        x2="26.5352"
        y2="28.7322"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CameraIcon'
}
</script>
