<template>
  <div class="card-deskripsi-proyek p-4 mt-4">
    <div class="is-flex is-align-items-center is-justify-content-space-between flex-sm-column align-items-sm-start">
      <div class="title-note-proyek">
        <p class="averta-bold label-20">Catatan Proyek</p>
        <p class="label-14 mb-4 mb-sm-0 text-grey">Catatan ini dapat dilihat oleh seluruh pengguna B2B Shell</p>
      </div>
      <div>
        <p>
          Terakhir Diubah : <span class="averta-bold">{{ lastProjectNoteBy || '-' }} {{ formatLastUpdate(lastProjectNoteDate) }}</span>
        </p>
      </div>
    </div>
    <b-field>
      <b-input type="textarea" class="my-4" placeholder="Tulis catatan tentang proyek ini" v-model.trim="projectNote" />
    </b-field>
    <div class="is-flex is-justify-content-space-between">
      <ButtonApp :isBold="true" :isSecondary="true" height="3em" @click="onOpenHistoryNote">
        <p>Lihat Riwayat Catatan</p>
      </ButtonApp>
      <ButtonApp :isDisabled="isEmpty" :isBold="true" height="3em" @click="onSaveNote">
        <p>Simpan</p>
      </ButtonApp>
    </div>

    <ModalHistoryCatatanProyek :surveyId="surveyId" :showModal="modalHistoryNote" :refreshData="refreshData" width="95vw" @closeModal="handleModalHistoryNote(false)" @getFirstNote="handleGetFirstNote($event)" @handleRefreshData="handleRefreshData($event)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalHistoryCatatanProyek from '@/components/modal/ModalHistoryCatatanProyek.vue'
import { alertErrorMessage, isEmptyAfterTrim } from '@/helpers'

export default {
  name: 'ProjectNote',
  props: {
    dataProjectNoteProps: {
      type: Object
    },
    surveyId: {
      type: Number
    },
    isFromProject: {
      type: Boolean,
      default: false
    },
    loadData: {
      type: Function
    }
  },
  components: {
    ButtonApp,
    ModalHistoryCatatanProyek
  },
  data () {
    return {
      projectNote: '',
      lastProjectNoteDate: null,
      lastProjectNoteBy: null,
      dataProjectNote: null,
      modalHistoryNote: false,
      noteProjectHistory: null,
      refreshData: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isEmpty () {
      return isEmptyAfterTrim(this.projectNote)
    }
  },
  methods: {
    formatLastUpdate (date) {
      if (!date) {
        return ''
      }

      return moment(date).format('DD/MM/YYYY - HH:mm')
    },
    onSaveNote () {
      if (this.isEmpty) return

      const payload = {
        url: this.url,
        survey_id: this.surveyId,
        notes: this.projectNote
      }

      this.$store
        .dispatch('project/updateProjectNote', payload)
        .then(() => {
          this.$buefy.toast.open({
            message: 'Berhasil update catatan',
            type: 'is-success'
          })
          if (this.isFromProject) {
            this.loadData()
          }
          this.refreshData = true
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
    },
    onOpenHistoryNote () {
      this.handleModalHistoryNote(true)
    },
    handleModalHistoryNote (event) {
      this.modalHistoryNote = event
    },
    handleGetFirstNote (event) {
      this.projectNote = event.note // didapat dari getNoteProjectHistory data pertama
      if (!this.isFromProject) {
        this.lastProjectNoteDate = event.created_at
        this.lastProjectNoteBy = event.user_name
      }
    },
    handleRefreshData (event) {
      this.refreshData = event
    }
  },
  mounted () {
    if (this.isFromProject) {
      this.lastProjectNoteDate = this.dataProjectNoteProps.last_project_note_date
      this.lastProjectNoteBy = this.dataProjectNoteProps.last_project_note_by
    }
  },
  watch: {
    dataProjectNoteProps (val) {
      if (this.isFromProject) {
        this.lastProjectNoteDate = val.last_project_note_date
        this.lastProjectNoteBy = val.last_project_note_by
      }
    }
  }
}
</script>

<style scoped>
.card-deskripsi-proyek {
  border: 0.7px solid #D8D8D8;
  border-radius: 12px;
  font-size: 14px;
}

.title-note-proyek {
  width: 60%;
}

@media only screen and (max-width: 600px) {
  .title-note-proyek {
    width: 100%;
  }
}
</style>
