<template>
  <svg :width="size" :height="size" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_7390_41527"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="5"
      y="3"
      width="15"
      height="18"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5 9.5H17.09C17.98 9.5 18.42 10.58 17.79 11.21L13.2 15.8C12.81 16.19 12.18 16.19 11.79 15.8L7.2 11.21C6.57 10.58 7.02 9.5 7.91 9.5H9.5V4.5C9.5 3.95 9.95 3.5 10.5 3.5H14.5C15.05 3.5 15.5 3.95 15.5 4.5V9.5ZM6.5 20.5C5.95 20.5 5.5 20.05 5.5 19.5C5.5 18.95 5.95 18.5 6.5 18.5H18.5C19.05 18.5 19.5 18.95 19.5 19.5C19.5 20.05 19.05 20.5 18.5 20.5H6.5Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_7390_41527)">
      <rect x="0.5" width="24" height="24" :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon2',
  props: {
    color: {
      type: String,
      default: '#E10009'
    },
    size: {
      type: String,
      default: '25'
    }
  }
}
</script>
