<template>
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4"
      d="M12.6165 0.0458984H5.3915C2.5665 0.0458984 0.666504 2.02923 0.666504 4.97923V11.7876C0.666504 14.7292 2.5665 16.7126 5.3915 16.7126H12.6165C15.4415 16.7126 17.3332 14.7292 17.3332 11.7876V4.97923C17.3332 2.02923 15.4415 0.0458984 12.6165 0.0458984Z"
      :fill="color ? color : '#D9272D'" />
    <path
      d="M11.5129 9.85432L10.0304 8.37266L11.5121 6.89099C11.7971 6.60682 11.7971 6.14432 11.5121 5.86016C11.2271 5.57349 10.7663 5.57432 10.4813 5.85932L8.99878 7.34099L7.51628 5.85766C7.23128 5.57266 6.76961 5.57432 6.48461 5.85766C6.20044 6.14266 6.20044 6.60516 6.48461 6.88932L7.96794 8.37266L6.48794 9.85182C6.20294 10.1368 6.20294 10.5993 6.48794 10.8827C6.63044 11.026 6.81628 11.0968 7.00294 11.0968C7.19044 11.0968 7.37628 11.026 7.51878 10.8835L8.99878 9.40349L10.4821 10.886C10.6246 11.0285 10.8104 11.0993 10.9971 11.0993C11.1838 11.0993 11.3704 11.0277 11.5129 10.886C11.7979 10.601 11.7979 10.1393 11.5129 9.85432Z"
      :fill="color ? color : '#D9272D'" />
  </svg>
</template>

<script>
export default {
  name: 'ActionIcon',
  props: ['color']
}
</script>
